/* eslint-disable react-hooks/exhaustive-deps */
import L from 'leaflet';
import { FC, useEffect } from 'react';
import { useMap } from 'react-leaflet';

export const MapScale: FC = () => {
  const map = useMap();

  useEffect(() => {
    const scale = L.control.scale();
    scale.addTo(map);

    return () => {
      scale.remove();
    };
  }, []);

  return null;
};
