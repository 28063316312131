import Menu from '@mui/material/Menu';
import FlagsList from './flags-list';
import { FlagsMenuProps } from './types';

const FlagsMenu = (props: FlagsMenuProps): JSX.Element => {
  const {
    anchorEl,
    isoCode,
    onSelectCountry,
    excludedCountries,
    onlyCountries,
    localization,
    preferredCountries,
    enableSearch,
    searchLabel,
    ...rest
  } = props;

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      id="PhoneInput-select-country"
      data-testid="phone-input-select-country"
      className="PhoneInput-Menu"
      autoFocus
      MenuListProps={{
        role: 'listbox',
        'aria-activedescendant': isoCode ? `country-${isoCode}` : '',
        'aria-labelledby': 'PhoneInput-select-country',
      }}
      {...rest}
    >
      <FlagsList
        onlyCountries={onlyCountries}
        excludedCountries={excludedCountries}
        preferredCountries={preferredCountries}
        isoCode={isoCode}
        localization={localization}
        onSelectCountry={onSelectCountry}
        enableSearch={enableSearch}
        searchLabel={searchLabel}
      />
    </Menu>
  );
};

export default FlagsMenu;
