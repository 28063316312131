import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  'aria-label'?: string;
  color?: string;
  'data-testid'?: string;
}

export const Breadcrumbs: FC<Props> = ({ children, ...props }) => {
  return <MUIBreadcrumbs aria-label={props['aria-label'] || 'breadcrumb'} data-testid={props['data-testid'] || 'breadcrumbs'} color={props.color} >{children}</MUIBreadcrumbs>;
};
