/* eslint-disable react-hooks/exhaustive-deps */
import { JsonObject, RoleFunction } from '@eagle/common';
import { Account, AccountRequest, AccountTemplate, AccountType, InitialUser, StakeholderAccountResult } from '@eagle/core-data-types';
import { Alert, Box, Button, Card, Stack, Typography, useTheme } from '@mui/material';
import { Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../../auth';
import { LOOKUP_DEBOUNCE_TIME, T_ONE } from '../../../constants';
import { useFetchAllCache, usePromise, useSwitchAwareConfig } from '../../../hooks';
import { CacheDataTypes, Undefinable } from '../../../types';
import { filterDeletedCache, testid, useHasAuthorization } from '../../../util';
import { useBoolFlag } from '../../flags';
import { Lookup } from '../../lookup';
import { MiddleSpinner } from '../../middle-spinner';
import { SelectionCheckBox } from '../../selection-check-box';
import { DataRow, PropertiesRows } from '../../static-data';
import { useStageConfirmDialogContext } from './stage-confirm-dialog-context';
import { StageConfirmDialogCreateAccount } from './stage-confirm-dialog-create-account';
import { StageConfirmDialogEditAccountRequest } from './stage-confirm-dialog-edit-account-request';
import { StakeholderState } from './stage-confirm-dialog-reducer';
import { StakeholderCardBase } from './stakeholder-card-base';

const ACCOUNT_CREATOR = [RoleFunction.ACCOUNT_CREATOR] as const;
const ACCOUNT_REQUESTER = [RoleFunction.ACCOUNT_REQUESTER] as const;

interface StakeHolderAccountCardProps {
  accountType?: AccountType;
  display: string;
  properties: JsonObject | null;
  role: string;
  initialUsers: InitialUser[] | null;
}

export const StakeHolderAccountCard: FC<StakeHolderAccountCardProps> = ({ accountType, display, properties, role, initialUsers }) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  return (
    <Card sx={{ p: 1, backgroundColor: theme.palette.grey[100] }}>
      <DataRow
        data-testid={testid`${role}-selected-account-display`}
        label={t('common:common.labels.display')}
        value={display}
      />
      {accountType && <PropertiesRows properties={properties ?? {}} propertyDefinitions={accountType.properties} />}
      {initialUsers && <Stack sx={{ py: 1 }}>
        <Typography variant="dataCardTitle" sx={{ px: 1, pb: 0 }}> {t('common:page.thing-details.update-stage.hint.initial-users')}</Typography>
        {initialUsers?.length ? <>
          {initialUsers.map((user, index) => (
            <DataRow
              key={`initial-user-${index}`}
              data-testid={testid`${role}-selected-account-initial-user-${index}`}
              description={t('common:terms.user', { count: T_ONE })}
              label={user.display}
              value={user.email}
            />
          ))}
        </>
          : <Typography variant="body1" sx={{ px: 1 }}> {t('common:page.thing-details.update-stage.hint.no-users-supplied')}</Typography>
        }
      </Stack>
      }
    </Card>
  );
};

interface EditableLookupProps extends PropsWithChildren {
  accountRequest: Undefinable<AccountRequest>;
  accountTemplates: AccountTemplate[];
  invalidateAccount: VoidFunction;
  isAccountCreator: boolean;
  isAccountRequester: boolean;
  selectedAccount: Undefinable<StakeholderAccountResult>;
  stakeholderState: StakeholderState;
}

const CREATE_NEW_ACCOUNT = 'CREATE_NEW_ACCOUNT';

const EditableLookup: FC<EditableLookupProps> = ({
  accountRequest,
  accountTemplates,
  invalidateAccount,
  isAccountCreator,
  isAccountRequester,
  selectedAccount,
  stakeholderState,
}) => {
  const { dispatch, sharedThing, state, stage } = useStageConfirmDialogContext();
  const { t } = useTranslation(['admin', 'common']);
  const role = stakeholderState.role;
  const { account } = useAuthenticated();
  const { restClient } = useAuthenticated();
  const theme = useTheme();
  const { productName } = useSwitchAwareConfig();

  const canCreateNewAccount = !state.isSimplifiedAccountSelect && accountTemplates.length > 0 && (isAccountCreator || (isAccountRequester && stakeholderState.allowAccountRequests));

  const hasInitialAccount = Boolean(stakeholderState.initialAccountId);
  const isSearchDisabled = !stakeholderState.canEdit || stakeholderState.isRemoveConfirmationChecked;

  const canEditAccountRequest = (isAccountCreator || isAccountRequester) && accountRequest && accountRequest.productName === productName;

  const handleFindAccounts = useMemo<(_: string) => Promise<(Account | typeof CREATE_NEW_ACCOUNT)[]>>(() => {
    const additionalParams = stakeholderState.allowAccountRequests ? { includeAccountRequests: true } : {};

    return async (text: string) => restClient.sharedThing.getStakeholderAccounts(sharedThing._id, stakeholderState.role,
      {
        search: text,
        ...additionalParams,
      },
    ).then((accounts) => {
      if (!accounts) {
        return [];
      }
      if (canCreateNewAccount) {
        return [...accounts, CREATE_NEW_ACCOUNT];
      }
      return accounts;
    });
  }, [restClient, sharedThing._id, stakeholderState.role, stakeholderState.allowAccountRequests, canCreateNewAccount]);

  const [accountType, , accountTypeState] = usePromise(async () => {
    if (!stakeholderState.accountId) return;
    return restClient.sharedThing.getStakeholderAccountType(sharedThing._id, role, stakeholderState.accountId);
  }, [restClient, sharedThing._id, stakeholderState.role, stakeholderState.accountId]);

  const accountLookup = (
    <>
      <Lookup<Account | typeof CREATE_NEW_ACCOUNT>
        data-testid={testid`${stakeholderState.role}-account-lookup`}
        debounceTime={LOOKUP_DEBOUNCE_TIME}
        disabled={isSearchDisabled}
        disablePortal={false}
        loadOnRender
        disableClearable={Boolean(state.removeConfirmation)}
        getOptionTestId={(option) => {
          if (option === CREATE_NEW_ACCOUNT) {
            return testid`${stakeholderState.role}-create-account-button`;
          }
          return testid`${stakeholderState.role}-account-item-${option._id}`;
        }}
        handleCompareItems={(option, value) => option === CREATE_NEW_ACCOUNT || value === CREATE_NEW_ACCOUNT ? false : option._id === value._id}
        handleFindItems={handleFindAccounts}
        handleFormatListItem={(item) => {
          if (item === CREATE_NEW_ACCOUNT) {
            return (
              <Box sx={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                {t('admin:page.thing-detail.update-stage-dialog.action.create-account')}
              </Box>
            );
          }
          return <span>{item.display}</span>;
        }}
        handleFormatSelectedItem={(item) => item === CREATE_NEW_ACCOUNT ? '' : item.display}
        isSearch
        onInputChange={(_event, inputValue, reason) => {
          if (reason === 'input') {
            dispatch({ type: 'SET_LAST_ACCOUNT_SEARCH', role: stakeholderState.role, search: inputValue });
          }
        }}
        onItemSelected={(item) => {
          if (item === CREATE_NEW_ACCOUNT) {
            dispatch({ type: 'START_STAKEHOLDER_CREATE_MODE', role: stakeholderState.role, accountTemplates, account });
          }
          else {
            dispatch({ type: 'SET_ACCOUNT', role: stakeholderState.role, account: item, accountId: item?._id ?? null });
          }
        }}
        placeholder={stakeholderState.config?.selectPlaceholder}
        required={stakeholderState.isRequired}
        selectedItem={selectedAccount ?? stakeholderState.account ?? null}
        size="small"
      />
      {state.removeConfirmation && hasInitialAccount && !stage.enterConditions?.requiredStakeholders?.includes(stakeholderState.role) && (
        <SelectionCheckBox
          checked={stakeholderState.isRemoveConfirmationChecked}
          data-testid={testid`${stakeholderState.role}-confirm-remove-checkbox`}
          handleClick={(e) => {
            dispatch({ type: 'SET_REMOVE_CONFIRMATION', role: stakeholderState.role, value: e.target.checked });
          }}
          label={state.removeConfirmation.checkboxLabel}
          sx={{ pl: 0 }}
        />
      )}
    </>
  );

  return (
    <StakeholderCardBase
      hideRole={state.isSimplifiedAccountSelect}
      role={role}
      action={state.isSimplifiedAccountSelect ? null : (hasInitialAccount ? !isSearchDisabled : Boolean(selectedAccount)) && (
        stakeholderState.isEditing ? (
          <Button
            data-testid={testid`${stakeholderState.role}-cancel-button`}
            onClick={() => {
              dispatch({ type: 'CANCEL_STAKEHOLDER_EDIT_MODE', role });
            }}
            size="small"
          >
            {t('common:common.action.cancel')}
          </Button>
        ) : (
          <Button
            data-testid={testid`${stakeholderState.role}-edit-button`}
            onClick={() => {
              dispatch({ type: 'START_STAKEHOLDER_EDIT_MODE', role });
            }}
            size="small"
          >
            {t('admin:page.thing-detail-update-stage-dialog.action.edit')}
          </Button>
        )
      )}>

      {state.isSimplifiedAccountSelect ? (
        <>
          {(stakeholderState.accountId && !selectedAccount && !stakeholderState.account) ? <MiddleSpinner /> : accountLookup}
        </>
      ) : (
        <>
          {stakeholderState.isEditing || (!hasInitialAccount && !stakeholderState.accountId) ? accountLookup : null}
          {(stakeholderState.accountId && (!selectedAccount || accountTypeState === 'pending')) && <MiddleSpinner />}
        </>
      )}

      {selectedAccount?.isAccountRequest && !stakeholderState.isEditing && (
        <Alert severity="warning">
          {t('admin:page.thing-detail-update-stage-dialog.account-request.hint')}
        </Alert>
      )}
      {selectedAccount && accountTypeState !== 'pending' && (
        (stakeholderState.isEditing && selectedAccount.isAccountRequest && canEditAccountRequest) ? (
          <StageConfirmDialogEditAccountRequest
            accountRequest={accountRequest}
            invalidateAccount={invalidateAccount}
            stakeholderState={stakeholderState}
          />
        ) : state.isSimplifiedAccountSelect ? null : (
          <StakeHolderAccountCard
            accountType={accountType}
            display={selectedAccount.display}
            properties={selectedAccount.properties}
            role={role}
            initialUsers={accountRequest?.targetAccountInfo.initialUsers ?? null}
          />
        )
      )}
    </StakeholderCardBase >
  );
};

interface StageConfirmDialogCardProps {
  stakeholderState: StakeholderState;
  setIsNextButtonDisabled: Dispatch<SetStateAction<boolean>>;
}

export const StageConfirmDialogCard: FC<StageConfirmDialogCardProps> = ({
  stakeholderState,
  setIsNextButtonDisabled,
}) => {
  const { hasAuthorization } = useHasAuthorization();
  const accountTemplatesCache = useFetchAllCache(CacheDataTypes.ACCOUNT_TEMPLATE);
  const accountTypesCache = useFetchAllCache(CacheDataTypes.ACCOUNT_TYPE);
  const isUsersMandatory = useBoolFlag('admin-life-cycle-users-are-mandatory-feature');

  const { restClient } = useAuthenticated();
  const { sharedThing } = useStageConfirmDialogContext();
  const [invalidate, setInvalidate] = useState(new Date());

  const accountCreator = hasAuthorization(ACCOUNT_CREATOR);
  const accountRequester = hasAuthorization(ACCOUNT_REQUESTER);
  const isAccountCreatorOrRequester = accountCreator || accountRequester;

  const [accountResponse] = usePromise(async () => {
    if (!stakeholderState.accountId) {
      return undefined;
    }

    const account = await restClient.sharedThing.getStakeholderAccount(sharedThing._id, stakeholderState.role, stakeholderState.accountId);
    let accountRequest: Undefinable<AccountRequest>;
    if (account.isAccountRequest) {
      accountRequest = await restClient.accountRequest.get(stakeholderState.accountId);
    }

    return {
      account,
      accountRequest,
    };
  }, [stakeholderState.accountId, stakeholderState.role, sharedThing._id, invalidate, restClient]);

  useEffect(() => {
    if (!accountResponse?.accountRequest) return;
    setIsNextButtonDisabled(!accountResponse?.accountRequest?.targetAccountInfo.initialUsers?.length && !!isUsersMandatory);
  }, [accountResponse, setIsNextButtonDisabled]);

  const [accountTemplates] = usePromise(async () => {
    if (!isAccountCreatorOrRequester) {
      return [];
    }
    return filterDeletedCache<AccountTemplate>(accountTemplatesCache);
  }, [accountTemplatesCache, isAccountCreatorOrRequester]);

  const [accountTypes] = usePromise(async () => {
    if (!isAccountCreatorOrRequester) {
      return [];
    }
    return filterDeletedCache<AccountType>(accountTypesCache);
  }, [accountTypesCache, isAccountCreatorOrRequester]);

  if (!accountTypes || !accountTemplates) {
    return <MiddleSpinner />;
  }

  const availableAccountTemplates = accountTemplates.filter((accountTemplate) => stakeholderState.accountTemplateIds.includes(accountTemplate._id)) ?? [];
  if (stakeholderState.isCreatingNewAccount) {
    return (
      <StageConfirmDialogCreateAccount
        accountTypes={accountTypes}
        accountTemplates={availableAccountTemplates}
        stakeholderState={stakeholderState}
        setIsNextButtonDisabled={setIsNextButtonDisabled}
      />
    );
  }

  return (
    <EditableLookup
      accountRequest={accountResponse?.accountRequest}
      accountTemplates={availableAccountTemplates}
      invalidateAccount={() => { setInvalidate(new Date()); }}
      isAccountCreator={accountCreator}
      isAccountRequester={accountRequester}
      stakeholderState={stakeholderState}
      selectedAccount={accountResponse?.account}
    />
  );
};
