import { DateTime } from 'luxon';

export type RangeSelectValues =
  | {
    key: string;
    hours: number;
  }
  | { key: 'pick-custom-range'; hours: 0 };

export const RangeSelectValues: Record<string, RangeSelectValues> = {
  LAST_HOUR: { key: 'last-hour', hours: 1 } as const,
  LAST_4_HOURS: { key: 'last-4-hours', hours: 4 } as const,
  LAST_8_HOURS: { key: 'last-8-hours', hours: 8 } as const,
  LAST_12_HOURS: { key: 'last-12-hours', hours: 12 } as const,
  LAST_24_HOURS: { key: 'last-24-hours', hours: 24 } as const,
  PICK_CUSTOM_RANGE: { key: 'pick-custom-range', hours: 0 } as const,
} as const;

export interface RangeSelectOption {
  endDate: () => DateTime;
  label: string;
  startDate: () => DateTime;
  value: RangeSelectValues;
}

export interface CustomRange {
  endDate?: DateTime;
  startDate?: DateTime;
}

export interface FixedStartDate {
  [key: string]: DateTime;
}

export enum DateFieldType {
  START_DATE,
  END_DATE,
}
