import { ElementType, FC } from 'react';
import { useDynamicModule } from '../../hooks';
import { PortalWidgetIcons } from '../icons';
import { MapMarker, MapMarkerProps } from './map-marker';

interface Props extends MapMarkerProps {
  icon: string;
}

export const DynamicMapMarker: FC<Props> = ({ icon, ...props }) => {
  const { module, loaded } = useDynamicModule<Record<string, ElementType<any>>>('feature-icons', PortalWidgetIcons);

  const DynamicIcon = module?.[icon];

  if (!loaded || !DynamicIcon) {
    return <MapMarker {...props} />;
  }

  return <MapMarker {...props} icon={<DynamicIcon />} />;
};
