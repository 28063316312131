import { DateTime } from 'luxon';
import { Nullable } from '../types';

export const getFileName = (fullPath: string): string => {
  return fullPath.substring(fullPath.lastIndexOf('/') + 1);
};

export const getFileExtension = (fullPath?: Nullable<string | URL>): string => {
  if (!fullPath) return '';
  if (fullPath instanceof URL) return fullPath.pathname.substring(fullPath.pathname.lastIndexOf('.') + 1);
  return fullPath.substring(fullPath.lastIndexOf('.') + 1);
};

export const stringify = (value: unknown): string => {
  if (value === undefined || value === null) return '';
  if (typeof value === 'string') return value;
  if (typeof value === 'object') {
    if (value instanceof Date) return DateTime.fromJSDate(value).toUTC().toFormat('yyyyMMdd-HHmmss');
    return JSON.stringify(value);
  }
  return String(value);
};

export const safeFilename = (strings: TemplateStringsArray, ...args: unknown[]): string => {
  const parts = strings.reduce<string[]>((acc, part, i) => {
    if (args.length > i) {
      return [...acc, part, stringify(args[i])];
    }
    return [...acc, part];
  }, []);
  return parts.join('').replace(/[^a-zA-Z0-9.]+/g, '-');
};

export const getFormattedFileName = (display: string, url: string, timeStart: Date, feature: string): string => {
  return safeFilename`${display}-${feature}-${timeStart}.${getFileExtension(url)}`;
};
