import { ThingEventSnapshot } from '@eagle/core-data-types';
import { EventRecordEventTypes, FeatureTypes, TrackingEventTypes } from '@eagle/data-function-types';
import { Theme } from '@mui/material';
import { EventDefinition, getEventsDefinition, SimpleActivityFeatures } from '../../notable-events';
import { Undefinable } from '../../types';
import { EntityItems } from './entity-journey-types';

export const getColorIndex = (items: EntityItems[]): number => {
  if (!items) return 0;

  const highestIndex = Math.max(...items.map((item) =>
    Math.max(...item.journeyList.map((journey) => journey.colorIndex)),
  ));

  return highestIndex < 0 ? 0 : highestIndex + 1;
};

export const getJourneyColor = (index: number, theme: Theme): string => {
  const numberOfColors = theme.colorMap.length;
  const i = index < 0 ? 0 : index % numberOfColors;

  return theme.colorMap[i];
};

export const getEventDictionary = ({ feature, featureTypeId, eventTypeId }: ThingEventSnapshot, theme: Theme): Undefinable<EventDefinition> => {
  const dictionary = getEventsDefinition(theme);

  if (featureTypeId === FeatureTypes.EVENT_RECORD_V0 && eventTypeId === EventRecordEventTypes.OCCURRENCE) {
    return dictionary.find(({ featureId, eventTypeId: eventId }) => feature.startsWith(featureId) && eventTypeId === eventId);
  }

  const event = dictionary.find(({ featureId, eventTypeId: eventId }) => featureTypeId === featureId && eventTypeId === eventId);

  if (event && event.styling && featureTypeId === FeatureTypes.SIMPLE_ACTIVITY_V0 && feature === SimpleActivityFeatures.SECURITY_ALARM_ALERT) {
    event.styling = { ...event?.styling, backgroundColor: theme.alerts.orange };
  }

  return event;
};

export const isValidEvent = (eventData: Undefinable<EventDefinition>, showLocationUpdates?: boolean): boolean => {
  if (!eventData) return false;
  const { enabled, notableEvent, eventTypeId } = eventData;
  return enabled && notableEvent && (showLocationUpdates || eventTypeId !== TrackingEventTypes.LOCATION_UPDATE);
};
