import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthenticated } from '../../auth';
import { Dialog } from '../../components';
import { T_ONE } from '../../constants';
import { useCustomRoutes } from '../../hooks';

interface Props {
  handleClose: () => void;
  isProfilePage: boolean;
  open: boolean;
  subscriptionId: string;
}

export const DeleteSubscriptionDialog: FC<Props> = ({ handleClose, isProfilePage, open, subscriptionId }) => {
  const { subscription: subscriptionRoute, subscriptions: subscriptionsRoute } = useCustomRoutes();
  const { restClient } = useAuthenticated();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation(['admin', 'common']);

  const handleDelete = async (): Promise<void> => {
    setDisabled(true);
    try {
      await (isProfilePage ? restClient.my.subscription.finish(subscriptionId) : restClient.subscription.finish(subscriptionId));
      handleClose();
      navigate(isProfilePage ? `/profile/${subscriptionRoute}` : `/${subscriptionsRoute}`);
      enqueueSnackbar(t('common:component.delete-entity-dialog.hint.success', { display: t('common:terms.subscription', { count: T_ONE }) }), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(t('common:component.delete-entity-dialog.hint.failure', { display: t('common:terms.subscription', { count: T_ONE }) }), { variant: 'error' });
    } finally {
      setDisabled(false);
    }
  };

  const actions = <>
    <Button
      data-testid="button-no"
      disabled={disabled}
      onClick={handleClose}
      size="medium"
      variant="outlined"
    >
      {t('common:common.action.no')}
    </Button>
    <Button
      data-testid="button-yes"
      disabled={disabled}
      onClick={handleDelete}
      size="medium"
      variant="contained"
    >
      {t('common:common.action.yes')}
    </Button>
  </>;

  return (
    <Dialog
      actions={actions}
      onClose={handleClose}
      open={open}
      title={t('admin:page.delete-entity-dialog.delete.labels')}
    >
      <Typography>
        {t('admin:page.delete-entity-dialog.dialog-body-start.labels')}
        <Box component="span" fontWeight="bold">{t('admin:page.delete-entity-dialog.dialog-body-middle.labels')}</Box>
        {t('admin:page.delete-entity-dialog.dialog-body-end.labels')}
      </Typography>
    </Dialog>
  );
};
