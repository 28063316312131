import { Geofence, GeofenceKind } from '@eagle/core-data-types';
import L, { LeafletMouseEvent, PopupEvent } from 'leaflet';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useAuthenticated } from '../../../../auth';
import { usePromise, useSmallScreen } from '../../../../hooks';
import { Undefinable } from '../../../../types';
import { FILTER_OUT, MapStorageKeys } from '../../../../util';
import { ErrorMessage } from '../../../error-message';
import { FilterTypes } from '../../../filter';
import { useBoundsUpdate } from '../../hooks/use-bounds-update';
import { useGeofenceIntersection } from '../../hooks/use-geofence-intersection';
import { useMapLayers } from '../../hooks/use-map-layers';
import { useTemporaryMap } from '../../hooks/use-temporary-map';
import { LoadingComponent } from '../../loading-component';
import { GeofencePane } from './geofence-pane';

export const GeofenceController: FC = () => {
  const { restClient } = useAuthenticated();
  const { enqueueSnackbar } = useSnackbar();
  const { geofenceFilters } = useMapLayers();
  const [hoveredGeofenceId, setHoveredGeofenceId] = useState<string>();
  const [showTooltip, setShowTooltip] = useState(true);
  const map = useMap();
  const bounds = useBoundsUpdate(map);
  const smallScreen = useSmallScreen();
  const [geofences, geofencesError, geofencesStatus] = usePromise(
    async () => {
      const filterLocalStorage = localStorage[MapStorageKeys.GEOFENCE] as Undefinable<string>;
      if (filterLocalStorage && !geofenceFilters.length) return Promise.resolve([]);
      const geofenceTypeIds = geofenceFilters
        .filter((filter) => filter.propertyPath === FilterTypes.GEOFENCE_TYPE)
        .map((filter) => filter.id);
      const result = await restClient.geofence.getAll({
        ...bounds,
        filter: {
          ...FILTER_OUT.deleted,
          geofenceTypeId: geofenceTypeIds.length ? { '$in': geofenceTypeIds } : undefined,
          kind: { $in: [GeofenceKind.POLYGON, GeofenceKind.CIRCLE] },
        },
        sort: JSON.stringify({ display: 'asc' }),
      });
      return result;
    },
    [restClient, bounds, geofenceFilters],
  );

  const geofenceMap = useTemporaryMap<Geofence>(geofences, map);
  const { intersections, setMousePosition } = useGeofenceIntersection(geofences);

  const handleMouseMove = (event: LeafletMouseEvent): void => {
    if (!showTooltip) return;
    const { lat, lng } = event.latlng;
    setMousePosition(L.latLng([lat, lng]));
  };

  const handleMouseOut = (): void => setHoveredGeofenceId(undefined);
  const handleMouseOver = (id: string): void => setHoveredGeofenceId(id);
  const handlePopupClose = (): void => setShowTooltip(true);

  const handlePopupOpen = (event: PopupEvent): void => {
    setShowTooltip(false);
    if (!event.popup) return;
    const latlng = event.popup.getLatLng();
    setMousePosition(L.latLng([latlng?.lat ?? 0, latlng?.lng ?? 0]));
  };

  if (geofencesError) enqueueSnackbar(<ErrorMessage error={geofencesError} />, { variant: 'error' });

  return <>
    {!smallScreen && <LoadingComponent isLoading={geofencesStatus === 'pending'} />}
    <GeofencePane
      geofences={Array.from(geofenceMap.values())}
      handleMouseMove={handleMouseMove}
      handleMouseOut={handleMouseOut}
      handleMouseOver={handleMouseOver}
      handlePopupClose={handlePopupClose}
      handlePopupOpen={handlePopupOpen}
      hoveredGeofenceId={hoveredGeofenceId}
      intersections={intersections}
      showTooltip={showTooltip}
    />
  </>;
};
