import { AccountTemplate, AccountType } from '@eagle/core-data-types';
import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../../auth';
import { useSwitchAwareConfig } from '../../../hooks';
import { testid } from '../../../util';
import { useBoolFlag } from '../../flags';
import { LabelsEditor } from '../../labels-editor';
import { MultipleUserBasicEditor, UserBasicType } from '../../multiple-user-basic-editor';
import { PropertiesElement } from '../../properties-element';
import { TagsEditor } from '../../tags-editor';
import { TextEditor } from '../../text-editor';
import { useStageConfirmDialogContext } from './stage-confirm-dialog-context';
import { StakeholderState } from './stage-confirm-dialog-reducer';
import { StakeholderCardBase } from './stakeholder-card-base';

interface StageConfirmDialogCreateAccountProps {
  accountTemplates: AccountTemplate[];
  accountTypes: AccountType[];
  stakeholderState: StakeholderState;
  setIsNextButtonDisabled: Dispatch<SetStateAction<boolean>>;
}

export const StageConfirmDialogCreateAccount: FC<StageConfirmDialogCreateAccountProps> = ({ accountTypes, accountTemplates, stakeholderState, setIsNextButtonDisabled }) => {
  const { account } = useAuthenticated();
  const { t } = useTranslation(['common', 'admin']);
  const { dispatch } = useStageConfirmDialogContext();
  const config = useSwitchAwareConfig();
  const isUsersMandatory = useBoolFlag('admin-life-cycle-users-are-mandatory-feature');
  const hasUserFeature = useBoolFlag('manage-account-create-invite-user-feature');
  const hasEThingsAuthProvider = Boolean(config.ethingsAuthenticationProvider);
  const hasAlreadyCreatedAccount = Boolean(stakeholderState.createAccount?.createdAccountId);
  const [userFieldsValidation, setUserFieldsValidation] = useState(false);
  const accountTemplate = accountTemplates.find(({ _id }) => _id === stakeholderState.createAccount?.accountTemplateId);
  const accountType = accountTypes.find(({ _id }) => _id === stakeholderState.createAccount?.data.accountTypeId);
  const availableAccountTypes = accountTypes.filter((accountType) => accountTemplate?.accountDefaults.accountTypeIds.includes(accountType._id));

  useEffect(() => {
    setIsNextButtonDisabled(!userFieldsValidation && !!isUsersMandatory);
  }, [userFieldsValidation]);

  return (
    <StakeholderCardBase
      role={stakeholderState.role}
      action={(
        <Button
          data-testid={testid`stage-dialog-${stakeholderState.role}-cancel-button`}
          size='small'
          onClick={() => {
            dispatch({ type: 'CANCEL_STAKEHOLDER_CREATE_MODE', role: stakeholderState.role });
          }}
        >
          {t('common:common.action.cancel')}
        </Button>
      )}
    >
      <Stack spacing={2}>
        {accountTemplates.length > 1 && (
          <TextField
            data-testid={testid`stage-dialog-${stakeholderState.role}-account-template-input`}
            disabled={hasAlreadyCreatedAccount}
            label={t('common:page.create-account.labels.account-template')}
            onChange={(e) => {
              const accountTemplate = accountTemplates.find((accountTemplate) => accountTemplate._id === e.target.value);
              if (accountTemplate) {
                dispatch({ type: 'SET_ACCOUNT_TEMPLATE', accountTemplate, role: stakeholderState.role, account });
              }
            }}
            select
            size='small'
            value={stakeholderState.createAccount?.accountTemplateId ?? ''}
          >
            {accountTemplates.map((accountTemplate) => (
              <MenuItem key={accountTemplate._id} value={accountTemplate._id}>{accountTemplate.display}</MenuItem>
            ))}
          </TextField>
        )}

        {availableAccountTypes.length > 1 && accountTemplate && (
          <TextField
            data-testid={testid`stage-dialog-${stakeholderState.role}-account-type-input`}
            disabled={hasAlreadyCreatedAccount}
            label={t('common:page.create-account.labels.account-type')}
            onChange={(e) => {
              dispatch({ type: 'SET_ACCOUNT_TYPE', accountTypeId: e.target.value, role: stakeholderState.role });
            }}
            select
            size='small'
            value={stakeholderState.createAccount?.data.accountTypeId ?? ''}
          >
            {availableAccountTypes.map((accountType) => (
              <MenuItem key={accountType._id} value={accountType._id}>{accountType.display}</MenuItem>
            ))}
          </TextField>
        )}

        {accountTemplate && accountType && stakeholderState.createAccount?.data &&
          <>
            <TextEditor
              data-testid={testid`stage-dialog-${stakeholderState.role}-display-input`}
              disabled={hasAlreadyCreatedAccount}
              label={t('common:common.labels.account-name')}
              onKeyUp={(value) => {
                dispatch({ type: 'SET_CREATE_ACCOUNT_DATA', role: stakeholderState.role, field: 'display', value });
              }}
              required
              size="small"
              value={stakeholderState.createAccount.data.display}
            />

            {accountTemplate.accountDefaults.homeDomain.type === 'custom' && (
              <TextEditor
                data-testid={testid`stage-dialog-${stakeholderState.role}-account-home-domain-input`}
                disabled={hasAlreadyCreatedAccount}
                label={t('common:page.create-account.labels.account-domain')}
                onKeyUp={(value) => {
                  dispatch({ type: 'SET_CREATE_ACCOUNT_DATA', role: stakeholderState.role, field: 'homeDomain', value });
                }}
                required
                size="small"
                value={stakeholderState.createAccount.data.homeDomain ?? ''}
              />
            )}

            <PropertiesElement
              disabled={hasAlreadyCreatedAccount}
              onChange={(value) => {
                dispatch({ type: 'SET_CREATE_ACCOUNT_DATA', role: stakeholderState.role, field: 'properties', value });
              }}
              properties={stakeholderState.createAccount.data.properties ?? {}}
              propertyDefinitions={accountType.properties}
              size="small"
            />

            <TagsEditor
              disabled={hasAlreadyCreatedAccount}
              onChange={(value) => {
                dispatch({ type: 'SET_CREATE_ACCOUNT_DATA', role: stakeholderState.role, field: 'tags', value });
                return Promise.resolve();
              }}
              label={t('common:terms.tag')}
              placeholder={t('common:common.hint.tag')}
              size="small"
              tags={stakeholderState.createAccount.data.tags}
            />

            <Stack spacing={1}>
              <Typography sx={{ fontWeight: 600 }}>{t('common:terms.label')}</Typography>
              <LabelsEditor
                disabled={hasAlreadyCreatedAccount}
                onChange={(value) => {
                  dispatch({ type: 'SET_CREATE_ACCOUNT_DATA', role: stakeholderState.role, field: 'labels', value });
                  return Promise.resolve();
                }}
                labels={stakeholderState.createAccount.data.labels}
                placeholder={t('common:common.hint.label')}
                size="small"
                textfieldLabel={t('common:terms.label')}
              />
            </Stack>

            {hasUserFeature && (
              <MultipleUserBasicEditor
                anotherUserLabel={t('common:page.create-account.add-another-user.action')}
                disabled={hasAlreadyCreatedAccount}
                fields={stakeholderState.createAccount.data.users as UserBasicType[] ?? []}
                hint={t('common:page.create-account.define-users.hint')}
                setFields={(value) => {
                  dispatch({ type: 'SET_CREATE_ACCOUNT_DATA', role: stakeholderState.role, field: 'users', value });
                }}
                onValidation={setUserFieldsValidation}
                showDisplay={hasEThingsAuthProvider}
                title={<Typography sx={{ fontWeight: 600 }}>{t('common:page.create-account.users-optional.labels')}</Typography>}
              />
            )}
          </>
        }

      </Stack>
    </StakeholderCardBase>
  );
};
