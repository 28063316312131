import { AuthenticatedState } from '../auth';
import { Cache, CacheFactory, Caches } from './use-cache.types';

export const DEFAULT_ID_SERIALIZER = (item: unknown): string => String(item);

export const createCaches = <D, C extends Cache>(authState: AuthenticatedState, definitions: Record<string, D>, factory: CacheFactory<D, C>): Caches<C> => {
  const keys = Object.keys(definitions);
  const caches = keys.reduce((acc, type) => {
    const definition = definitions[type];
    if (!definition) throw new Error(`Cache definition for ${type} is not defined`);

    return {
      ...acc,
      [type]: factory(definition, authState),
    };
  }, {} as Caches<C>);
  return caches;
};

export const compareAuthStates = (authState1: AuthenticatedState, authState2: AuthenticatedState): boolean => {
  return authState1.timestamp === authState2.timestamp;
};
