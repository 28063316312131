import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './filter-drop-down.styles';

interface Props {
  controlSx?: SxProps;
  handleChange: (event: SelectChangeEvent<string[]>) => void;
  label: string;
  options: string[];
  selected: string[];
  size?: 'small' | 'medium';
  sx?: SxProps;
}

export const FilterDropDown: FC<Props> = ({ handleChange, label, options, selected, size = 'small', controlSx = {}, sx = {} }) => {
  const { t } = useTranslation(['common']);
  const { classes } = useStyles();

  const isAllSelected = options.length > 0 && selected.length === options.length;
  const isDeterminateState = selected.length > 0 && selected.length < options.length;

  const renderSelected = (values: string[]): ReactNode => isAllSelected
    ? t('common:component.filter-dropdown.labels.all-categories')
    : values.map((option) => t(`common:component.filter-dropdown.labels.${option}`)).join(', ');

  return (
    <FormControl sx={{ width: 250, ...controlSx }}>
      {!selected.length && <InputLabel id='multiple-select-label'>{label}</InputLabel>}
      <Select
        labelId={!selected.length ? 'multiple-select-label' : ''}
        multiple
        onChange={handleChange}
        onClick={(e) => e.stopPropagation()}
        renderValue={renderSelected}
        value={selected}
        size={size}
        sx={{ ...sx }}
      >
        <MenuItem
          onClick={(e) => e.stopPropagation()}
          value="all"
        >
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              classes={{
                indeterminate: classes.indeterminate,
                checked: classes.checked,
              }}
              indeterminate={isDeterminateState}
            />
          </ListItemIcon>
          <ListItemText
            primary={<b>{t('common:component.filter-dropdown.labels.select-all')}</b>}
          />
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            classes={{ selected: classes.selected }}
            key={option}
            onClick={(e) => e.stopPropagation()}
            value={option}
          >
            <ListItemIcon>
              <Checkbox
                checked={selected.includes(option)}
                classes={{ checked: classes.checked }}
              />
            </ListItemIcon>
            <ListItemText primary={t(`common:component.filter-dropdown.labels.${option}`)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
