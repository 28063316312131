import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { testid } from '../../util';
import { NumberEditor } from '../number-editor';

interface Props {
  inputError: boolean;
  onChanged: (value: number) => void;
  placeholderText?: string;
}

export const FilterInputNumber: FC<Props> = ({
  inputError,
  onChanged,
  placeholderText,
}) => {
  const { t } = useTranslation(['common']);
  const [value, setValue] = useState('');

  const handleChange = (val: number): Promise<void> => {
    setValue(String(val));
    onChanged(val);
    return Promise.resolve();
  };

  return (
    <NumberEditor
      data-testid={testid`${placeholderText}-number-input`}
      error={inputError}
      helperText={inputError ? t('common:component.filter.hint.helper-value') : ''}
      inputLabelProps={{ 'aria-label': t('common:component.filter.hint.filter-value') }}
      label={placeholderText}
      onChange={handleChange}
      placeholder={placeholderText}
      required
      size="small"
      value={value}
    />
  );
};
