import { TableCell as MuiTableCell } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  'data-testid'?: string;
  stopClickPropagation?: boolean;
  sx?: SxProps;
}

export const TableCell: FC<Props> = ({ children, sx, stopClickPropagation, ...props }) => {
  return (
    <MuiTableCell
      component='div'
      onClick={stopClickPropagation ? (e) => { e.stopPropagation(); } : undefined}
      sx={{ fontSize: '1rem', verticalAlign: 'middle', ...sx }}
      {...props}
    >
      {children}
    </MuiTableCell>
  );
};
