import { FormHelperTextProps, InputLabelProps, OutlinedInput } from '@mui/material';
import { InputProps } from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import { SxProps } from '@mui/system';
import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';

interface Props {
  autoComplete?: string;
  blurOnEnter?: boolean;
  'data-testid'?: string;
  disabled?: boolean;
  endAdornment?: JSX.Element;
  error?: boolean;
  FormHelperTextProps?: FormHelperTextProps & { 'data-testid'?: string };
  helperText?: string;
  inputProps?: Partial<InputProps>;
  InputLabelProps?: InputLabelProps;
  label?: string;
  multiline?: boolean;
  onChange?: (value: string) => Promise<void>;
  onKeyUp?: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  size?: 'small' | 'medium';
  sx?: SxProps;
  type?: string;
  value: string;
}

export const TextEditor: FC<Props> = ({
  autoComplete,
  blurOnEnter = true,
  disabled = false,
  endAdornment,
  error: propsError,
  helperText,
  inputProps,
  label,
  multiline,
  onChange,
  onKeyUp,
  placeholder,
  required,
  rows,
  size,
  sx,
  type,
  value: initialValue,
  ...props
}) => {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [lastSavedValue, setLastSavedValue] = useState<string>(initialValue);
  const [value, setValue] = useState<string>(initialValue);

  useEffect(() => {
    setValue(initialValue);
    setLastSavedValue(initialValue);
  }, [initialValue]);

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLInputElement;
    if (blurOnEnter && event.key === 'Enter' && !multiline) {
      target.blur();
    }
  };

  const onInputKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLInputElement;
    onKeyUp?.(target.value);
  };

  const onValueInputBlur = (): void => {
    if (lastSavedValue === value || !onChange) return;

    setIsLoading(true);
    void onChange(value)
      .then(() => {
        setLastSavedValue(value);
      }).catch().finally(() => {
        setIsLoading(false);
      });
  };

  const onValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value;
    setValue(inputValue);
    if (required && !inputValue) return setError(new Error());
    setError(undefined);
  };

  if (endAdornment) {
    return (
      <OutlinedInput
        autoComplete={autoComplete}
        disabled={disabled || isLoading}
        endAdornment={endAdornment}
        error={propsError ?? !!error}
        multiline={multiline}
        onBlur={onValueInputBlur}
        onChange={onValueChange}
        onKeyDown={onInputKeyDown}
        onKeyUp={onInputKeyUp}
        placeholder={placeholder}
        required={required}
        rows={rows}
        size={size}
        type={type ?? 'text'}
        value={value}
        sx={sx}
        {...props}
      />
    );
  }

  return (
    <TextField
      autoComplete={autoComplete}
      disabled={disabled || isLoading}
      error={propsError ?? !!error}
      helperText={helperText}
      InputProps={inputProps}
      label={label}
      multiline={multiline}
      onBlur={onValueInputBlur}
      onChange={onValueChange}
      onKeyDown={onInputKeyDown}
      onKeyUp={onInputKeyUp}
      placeholder={placeholder}
      required={required}
      rows={rows}
      size={size}
      sx={sx}
      type={type ?? 'text'}
      value={value}
      {...props}
    />
  );
};
