import RoomIcon from '@mui/icons-material/Room';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary } from '../accordion';
import { useHistorySearch } from './use-history-search';

interface Props {
  entityId: string;
}

export const JourneyInstanceNoDate: FC<Props> = ({ entityId }) => {
  const theme = useTheme();
  const { openAddDateTimePicker } = useHistorySearch();
  const { t } = useTranslation(['common']);

  return (
    <Accordion
      expanded={false}
      sx={{
        mt: 1,
        boxShadow: 'none',
        p: 0,
        '& .MuiAccordionSummary-root': { minHeight: 'fit-content', p: 0 },
        '& .MuiAccordionSummary-root.Mui-expanded': { m: 0, minHeight: 'fit-content', p: 0 },
        '& .MuiAccordionSummary-root.Mui-focusVisible': { backgroundColor: 'transparent !important' },
        '& .MuiAccordionDetails-root': { p: 0 },
      }}
    >
      <AccordionSummary
        sx={{
          cursor: 'default !important',
          flexDirection: 'row-reverse',
          mb: 2,
          '& .MuiAccordionSummary-content.Mui-expanded': { m: 0 },
          '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
            m: 0,
            p: 0,
          },
        }}
      >
        <Stack direction="column" spacing={1}>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            <RoomIcon sx={{ color: theme.palette.text.secondary }} />
            <Button data-testid='history-set-date-range' onClick={() => openAddDateTimePicker(entityId)} sx={{ cursor: 'pointer', p: 0 }}>
              <Typography>{t('common:component.entity-journey.action.set-date-range')}</Typography>
            </Button>
          </Stack>
        </Stack>
      </AccordionSummary>
    </Accordion>
  );
};
