/* eslint-disable react-hooks/rules-of-hooks */
import { RoleFunction } from '@eagle/common';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { PortalIcons } from '../components';
import { Maybe, Portal, Portals, Undefinable } from '../types';
import { useHasAuthorization } from './authentication';

interface MenuItems {
  icon: JSX.Element;
  permissions: boolean;
  portal: Portal;
  portalText: string;
  sx: SxProps;
  text: (entity: string) => string;
}

const ADMIN_PORTAL_ROLES = [RoleFunction.ADMINISTRATOR] as const;
const MANAGEMENT_PORTAL_ROLES = [RoleFunction.INSTALLER, RoleFunction.SYSTEM_ADMINISTRATOR] as const;
const TRACKING_PORTAL_ROLES = [RoleFunction.DATA_VIEWER] as const;

export const switchToPortal = (selectedPortal: Maybe<Portal>, portal: Portal, URI: string): Undefinable<string> => {
  const portalFromUrl = window.location.pathname.match(Object.values(Portals).join('|'))?.[0];
  const portalCheck = selectedPortal ?? portalFromUrl;
  if (!portalCheck) return;
  const currentPortal = Portals[portalCheck.toUpperCase() as keyof typeof Portals];
  const redirectedPortal = Portals[portal.toUpperCase() as keyof typeof Portals];
  const updatedLocation = window.location.href
    .replace(currentPortal, redirectedPortal)
    .replace(window.location.hash, `#/${URI}`);
  return updatedLocation;
};

export const getMenuItems = (): MenuItems[] => {
  const { t } = useTranslation(['common']);
  const { hasAuthorization } = useHasAuthorization();
  const sxIcon = { height: 36, width: 40, minWidth: 40, mr: '0.5rem', 'g': { display: 'contents' } };
  const sxStyle = { pl: 2.5 };
  const hasAdminPortalPermissions = hasAuthorization(ADMIN_PORTAL_ROLES);
  const hasManagementPortalPermissions = hasAuthorization(MANAGEMENT_PORTAL_ROLES);
  const hasTrackingPortalPermissions = hasAuthorization(TRACKING_PORTAL_ROLES);

  return [
    {
      icon: <PortalIcons.Administration sx={sxIcon} />,
      permissions: hasAdminPortalPermissions,
      portal: Portals.ADMIN,
      portalText: t('common:common.labels.portal-name', { display: t('common:common.labels.admin') }),
      sx: { mt: 1, whiteSpace: 'nowrap', ...sxStyle },
      text: (entity: string, useEntity?: boolean) => t('common:component.portal-switching.action.edit-entity', { entity: useEntity ? entity : '' }),
    },
    {
      icon: <PortalIcons.Management sx={sxIcon} />,
      permissions: hasManagementPortalPermissions,
      portal: Portals.MANAGE,
      portalText: t('common:common.labels.portal-name', { display: t('common:common.labels.manage') }),
      sx: { mt: 1, whiteSpace: 'nowrap', ...sxStyle },
      text: (entity: string, useEntity?: boolean) => t('common:component.portal-switching.action.manage-entity', { entity: useEntity ? entity : '' }),
    },
    {
      icon: <PortalIcons.Tracking sx={sxIcon} />,
      permissions: hasTrackingPortalPermissions,
      portal: Portals.TRACK,
      portalText: t('common:common.labels.portal-name', { display: t('common:common.labels.track') }),
      sx: { mt: 1, whiteSpace: 'nowrap', ...sxStyle },
      text: (entity: string, useEntity?: boolean) => t('common:component.portal-switching.action.track-entity', { entity: useEntity ? entity : '' }),
    },
  ];
};
