import { Autocomplete, TextField, useTheme } from '@mui/material';
import { timeZonesNames } from '@vvo/tzdb';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface TimeZoneSelectProps {
  'data-testid'?: string;
  fieldDisabled?: boolean;
  selectedTimeZone: string;
  setSelectedTimeZone: (value: string) => void;
  size?: 'small' | 'medium';
}

export const getCanonicalTimeZone = (timeZone: string): string => {
  const [area, , city] = timeZone.split('/');
  if (!city) return timeZone;
  return `${area}/${city}`;
};

export const timeZones = timeZonesNames.map((name) => getCanonicalTimeZone(name)).sort();

export const TimeZoneSelect: FC<TimeZoneSelectProps> = ({
  fieldDisabled,
  selectedTimeZone,
  setSelectedTimeZone,
  size = 'small',
  ...props
}) => {
  const { t } = useTranslation(['common', 'l10n']);
  const theme = useTheme();

  const displayedTimeZonesNames = useMemo(() => timeZones, []);

  return (
    <Autocomplete
      data-testid={props['data-testid']}
      disableClearable
      disabled={fieldDisabled}
      getOptionLabel={(timeZone: string) => !timeZone.length
        ? t('common:component.locale-select.labels.use-default')
        : t(`l10n:time-zones:${timeZone}`)
      }
      onChange={(_, value: string) => setSelectedTimeZone(value)}
      options={['', ...displayedTimeZonesNames]}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid="text-input"
          label={t('common:component.events.labels.timezone')}
        />
      )}
      renderOption={(optionProps, option) => !option.length
        ? <li data-testid="default-option" {...optionProps}><i>{t('common:component.locale-select.labels.use-default')}</i></li>
        : <li data-testid={`option-${option}`} {...optionProps}>{t(`l10n:time-zones:${option}`)}</li>
      }
      size={size}
      sx={{
        '& .MuiAutocomplete-input': {
          color: !selectedTimeZone ? theme.palette.text.secondary : theme.palette.text.primary,
          fontStyle: !selectedTimeZone ? 'italic' : 'normal',
        },
      }}
      value={selectedTimeZone}
    />
  );
};
