/* eslint-disable react-hooks/exhaustive-deps */
import { MediaSegmentData } from '@eagle/core-data-types';
import { Button, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollbar } from '../../style';
import { ErrorText } from '../error-message';
import { MiddleSpinner } from '../middle-spinner';
import { renderItem } from './util';

interface Props {
  error?: Error;
  feature: string;
  handleItemClick: (currentIndex: number, feature: string) => void;
  isEvent?: boolean;
  isLoading: boolean;
  mediaItems?: MediaSegmentData[];
  hasMore?: boolean;
  handleShowMore?: (feature: string, isDaysLimit?: boolean) => void;
  mediaType: string;
}

export const ThingPageMediaRow: FC<Props> = ({
  error,
  handleItemClick,
  isEvent,
  isLoading,
  mediaItems,
  hasMore,
  handleShowMore,
  feature,
  mediaType,
}): JSX.Element => {
  const { t } = useTranslation(['common']);
  const [isDaysLimit, setIsDaysLimit] = useState(true);
  const [loading, setLoading] = useState(isLoading);

  const showMore = (): void => {
    if (handleShowMore) handleShowMore(feature, isDaysLimit);
  };

  const showAll = (): void => {
    setIsDaysLimit(false);
    setLoading(true);
    if (handleShowMore) handleShowMore(feature, false);
  };

  const renderMediaRow = (): JSX.Element => {
    if (loading && !mediaItems?.length) return <MiddleSpinner size={16} />;
    if (error) return <ErrorText error={error} />;
    if (!isDaysLimit && (!mediaItems?.length || isEvent)) {
      return (
        <Typography color="text.secondary" fontStyle="italic" variant="body2" sx={{ mx: 3, my: 2, textAlign: 'center' }}>
          {t('common:component.media-data.hint.no-result-around-time')}
        </Typography>
      );
    }

    return (
      <Stack spacing={1}>
        <Stack direction="row" data-testid="media-row-scroll" spacing={2} sx={{ ...scrollbar, m: 0, px: 3, overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
          {mediaItems?.map((item, i) => renderItem(handleItemClick, i, item))}
          {hasMore && (
            <Stack sx={{ justifyContent: 'center' }}>
              <Button
                onClick={showMore}
                variant="outlined"
                color="primary"
                size="small"
                sx={{ mb: 3 }}
              >
                {t('common:component.range-select.labels.show-more')}
              </Button>
            </Stack>
          )}
        </Stack>
        {isDaysLimit && (
          <Stack sx={{ mx: 3, justifyContent: 'center', alignItems: 'center' }}>
            {mediaItems?.length === 0 &&
              <Typography color="text.secondary" fontStyle="italic" variant="body2" sx={{ mx: 3, my: 2 }}>
                {t('common:component.media-data.hint.no-media-found', { count: 30, media: mediaType })}
              </Typography>
            }
            <Button
              onClick={showAll}
              variant="outlined"
              color="primary"
              size='small'
            >
              {t('common:component.media-data.hint.display-older-images', { media: mediaType })}
            </Button>
          </Stack>
        )}
      </Stack>
    );
  };

  const renderNearTimeHint = (): JSX.Element => {
    if (!isEvent) return <></>;
    return <Typography color="text.secondary" fontStyle="italic" variant="body2" sx={{ mx: 3 }}>
      {t('common:component.media-data.hint.media-near-time')}
    </Typography>;
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setLoading(isLoading && Boolean(mediaItems));
  }, [mediaItems]);

  return (
    <Stack>
      <Typography sx={{ mx: 3 }}>
        {t(`common:features.camera-type.${feature}`)}
      </Typography>
      {renderMediaRow()}
      {renderNearTimeHint()}
    </Stack>
  );
};
