import ZoomInIcon from '@mui/icons-material/Add';
import ZoomOutIcon from '@mui/icons-material/Remove';
import { ButtonGroup, Paper } from '@mui/material';
import { SxProps } from '@mui/system';
import { DomEvent } from 'leaflet';
import { FC, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { MapButton } from '../map-button';

interface ZoomButtonProps {
  'data-testid'?: string;
  sx?: SxProps;
}

export const ZoomButtons: FC<ZoomButtonProps> = ({ sx, ...props }) => {
  const map = useMap();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref?.current) return;

    DomEvent.disableClickPropagation(ref.current);
  }, [ref]);

  return (
    <Paper data-testid={props['data-testid']} ref={ref} elevation={3} sx={sx}>
      <ButtonGroup
        aria-label="vertical outlined button group"
        orientation="vertical"
        size="small"
      >
        <MapButton data-testid="map-zoom-in-button" onClick={() => map.zoomIn()}><ZoomInIcon /></MapButton>
        <MapButton data-testid="map-zoom-out-button" onClick={() => map.zoomOut()}><ZoomOutIcon /></MapButton>
      </ButtonGroup>
    </Paper>
  );
};
