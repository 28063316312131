/* eslint-disable react-hooks/exhaustive-deps */
import { User } from '@eagle/core-data-types';
import { Email, Notifications, Summarize } from '@mui/icons-material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useAuth, useAuthenticated } from '../../auth';
import { Alert, ErrorMessage, FlexBox, MiddleSpinner, SignOutIcon } from '../../components';
import { useConfig, useCustomRoutes, useFetchOneCache, usePromise } from '../../hooks';
import { CacheDataTypes, PageAction } from '../../types';
import { NotFound } from '../errors';
import { ProfilePageController } from './profile-page-controller';
import { ProfileTabTypes } from './profile-page-types';

export const ProfilePage: FC = () => {
  const location = useLocation();
  const { signOut } = useAuth();
  const { subscription } = useCustomRoutes();
  const navigate = useNavigate();
  const { user } = useAuthenticated();
  const { t } = useTranslation(['common']);
  const myUserCache = useFetchOneCache(CacheDataTypes.MY_USER);
  const [refresh, setRefresh] = useState(new Date());
  const [userData, userDataError, userDataStatus] = usePromise(() => myUserCache.one<User>(user._id), [refresh]);
  const { termsOfService } = useConfig();
  const documentId = termsOfService?.documentId;

  const actions: PageAction[] = [
    {
      icon: <Email />,
      label: t('common:page.profile.notification-settings.labels'),
      onClick: () => navigate('/profile'),
    },
    {
      icon: <Notifications />,
      label: t('common:page.profile.my-subscriptions.labels'),
      onClick: () => navigate(`/profile/${subscription}`),
    },
    ...(documentId
      ? [
        {
          icon: <Summarize />,
          label: t('common:page.profile.terms-and-conditions.labels'),
          onClick: () => {
            window.open(`/web-asset/${documentId}`, '_blank');
          },
        },
      ]
      : []
    ),
    {
      icon: <SignOutIcon />,
      label: t('common:component.auth.labels.sign-out'),
      onClick: () => signOut(),
    },
  ];

  if (userDataStatus === 'pending') {
    return (
      <FlexBox sx={{ height: '200px', justifyContent: 'center', width: '100vw' }}>
        <MiddleSpinner />
      </FlexBox>
    );
  }

  if (userDataError) {
    return (
      <Alert severity="error" sx={{ margin: '1rem' }}>
        <ErrorMessage error={userDataError} />
      </Alert>
    );
  }

  if (!userData) {
    return (
      <FlexBox>
        <NotFound />
      </FlexBox>
    );
  }

  return (
    <ProfilePageController
      actions={actions}
      setRefresh={setRefresh}
      tab={location.pathname.includes('subscription') ? ProfileTabTypes.SUBSCRIPTIONS : ProfileTabTypes.NOTIFICATIONS}
      userData={userData}
    />
  );
};
