/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useLocalStorage } from '../util/local-storage';
import { asNumberOrUndefined } from '../util/maps';

interface GeoLocation {
  city?: string;
  country_code?: string;
  country_name?: string;
  expiry?: number;
  IPv4?: string;
  IPv6?: string;
  latitude?: number;
  longitude?: number;
  postal?: string;
  state?: string;
  loading: boolean;
  error?: string;
}

interface IpInfoResponse {
  ip: string;
  city: string;
  region: string;
  country: string;
  loc: string;
  postal: string;
  timezone: string;
}

interface IpApiResponse {
  ip: string;
  version: string;
  city: string;
  region: string;
  country_code: string;
  country_name: string;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
}

const mapIpInfoResponse = (data: IpInfoResponse): GeoLocation => {
  const [latitude, longitude] = data.loc.split(',').map(Number);
  return {
    city: data.city,
    'country_code': data.country,
    'country_name': undefined, // IpInfo doesn't provide country name
    IPv4: data.ip.includes(':') ? undefined : data.ip,
    IPv6: data.ip.includes(':') ? data.ip : undefined,
    latitude,
    longitude,
    postal: data.postal,
    state: data.region,
    loading: false,
  };
};

const mapIpApiResponse = (data: IpApiResponse): GeoLocation => {
  return {
    city: data.city,
    'country_code': data.country_code,
    'country_name': data.country_name,
    IPv4: data.version === 'IPv4' ? data.ip : undefined,
    IPv6: data.version === 'IPv6' ? data.ip : undefined,
    latitude: data.latitude,
    longitude: data.longitude,
    postal: data.postal,
    state: data.region,
    loading: false,
  };
};

interface ApiEndpoint {
  url: string;
  mapper: (data: IpInfoResponse | IpApiResponse) => GeoLocation;
}

const API_ENDPOINTS: ApiEndpoint[] = [
  { url: 'https://ipinfo.io/json', mapper: mapIpInfoResponse as (data: IpInfoResponse | IpApiResponse) => GeoLocation },
  { url: 'https://ipapi.co/json', mapper: mapIpApiResponse as (data: IpInfoResponse | IpApiResponse) => GeoLocation },
];

const cleanGeoData = (geoData: GeoLocation): GeoLocation => {
  const { latitude, longitude } = geoData;
  return { ...geoData, latitude: asNumberOrUndefined(latitude), longitude: asNumberOrUndefined(longitude) };
};

/**
 * A React hook that retrieves and manages the user's geolocation data using IP Address.
 *
 * @return {GeoLocation} The current geolocation data state.
 *
 * The hook behaves as follows:
 * - If valid data is already available in local storage and has not expired, it is returned immediately.
 * - Otherwise, the hook attempts to fetch the data sequentially from each API endpoint in the API_ENDPOINTS array.
 * - If successful, the fetched data is stored in local storage with a 12-hour expiry and returned.
 * - If all API endpoints fail, an error message is set in the returned GeoLocation object.
 *
 * Note: The hook initiates data fetching as a side effect, so the returned data may not be immediately available.
 * Check the `loading` and `error` fields of the returned object to determine the current state.
 */
export const useGeoLocation = (): GeoLocation => {
  const [geoData, setGeoData] = useLocalStorage<GeoLocation>('GEOLOCATION', { loading: true });
  const currentTime = DateTime.now();

  useEffect(() => {
    if (geoData && geoData.expiry && currentTime.toMillis() < geoData.expiry) return;
    void loadGeoData();
  }, []);

  const loadGeoData = async (): Promise<void> => {
    setGeoData((prevData) => ({ ...prevData, loading: true, error: undefined }));

    const tryNextEndpoint = async (index: number): Promise<void> => {
      if (index >= API_ENDPOINTS.length) {
        setGeoData((prevData) => ({
          ...prevData,
          loading: false,
          error: 'Failed to fetch geolocation data from all available APIs',
        }));
        return;
      }

      const endpoint = API_ENDPOINTS[index];

      try {
        const response = await axios.get<IpInfoResponse | IpApiResponse>(endpoint.url, { timeout: 5000 });
        const mappedData = endpoint.mapper(response.data);

        setGeoData((prevData) => ({
          ...prevData,
          ...mappedData,
          expiry: currentTime.plus({ hours: 12 }).toMillis(),
          loading: false,
        }));
      } catch (error) {
        console.error(`Error fetching geolocation data from ${endpoint.url}:`, error);
        await tryNextEndpoint(index + 1);
      }
    };

    await tryNextEndpoint(0);
  };

  return cleanGeoData(geoData);
};
