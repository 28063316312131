import { Collapse, Divider, List, ListItem, ListItemButton, ListItemText, Slide, Stack, Typography, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { T_MANY } from '../../../../constants';
import { useBoolFlag } from '../../../flags';
import { useMapLayers } from '../../hooks';
import { SLIDE_DURATION } from '../layer-selection';
import { LayerSelectionCheckBox } from '../layer-selection-check-box';
import { BaseLayers, MapImageButton, MapLayerImage, MapLayerToggle, ToggleLayers } from '../layer-selection.types';

interface Props {
  handleBaseChange: (label: BaseLayers) => void;
  handlePreviewLayerClick: () => void;
  handleToggleChange: (layer: ToggleLayers) => void;
  isBottomSectionIn: boolean;
  isLayerActive: (label: ToggleLayers) => boolean;
  isLayerChecked: (label: ToggleLayers) => boolean;
  mapToggleLayers: MapLayerToggle[];
  openSubMenu: (label?: ToggleLayers) => void;
  otherLayers: MapLayerImage[];
  previewLayer: MapLayerImage;
  setSubMenuHeight: (height: number) => void;
  setSubMenuWidth: (height: number) => void;
  slideIn: boolean;
}

const MapImage: FC<MapImageButton> = ({ image, label, onClick }) => {
  const theme = useTheme();

  return (
    <ListItem sx={{ height: '5rem', overflow: 'hidden', p: 0, width: 1 }}>
      <ListItemButton data-testid="map-layer-selection" onClick={onClick} sx={{ height: 1, m: 0 }}>
        <ListItemText sx={{ m: 0, p: 0 }}>
          <Typography
            sx={{
              backgroundColor: chroma(theme.palette.background.default).alpha(0.8).css(),
              left: 0,
              padding: '1px',
              position: 'absolute',
              top: 0,
              width: 1,
              zIndex: 501,
            }}
            variant="caption"
          >
            {label}
          </Typography>
          {image}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export const MainMenu: FC<Props> = ({
  handleBaseChange,
  handlePreviewLayerClick,
  handleToggleChange,
  isBottomSectionIn,
  isLayerActive,
  isLayerChecked,
  mapToggleLayers,
  openSubMenu,
  otherLayers,
  previewLayer,
  setSubMenuHeight,
  setSubMenuWidth,
  slideIn,
}) => {
  const { t } = useTranslation(['common']);
  const ref = useRef<HTMLDivElement>(null);
  const { displayThingsLayerSelection, isThingsLayerSelected, setIsThingsLayerSelected } = useMapLayers();
  const displayThingsLayerSelectionFlag = useBoolFlag('portals-things-map-layer');

  const calculateBounds = (): void => {
    setSubMenuHeight(ref.current?.offsetHeight ?? 0);
    setSubMenuWidth(ref.current?.offsetWidth ?? 0);
  };

  return (
    <Slide ref={ref} direction="right" in={slideIn} timeout={isBottomSectionIn ? SLIDE_DURATION : 0}>
      <List disablePadding={true}>
        {displayThingsLayerSelection && displayThingsLayerSelectionFlag && (
          <Collapse in={isBottomSectionIn}>
            <LayerSelectionCheckBox
              checked={isThingsLayerSelected}
              handleClick={() => { setIsThingsLayerSelected(!isThingsLayerSelected); }}
              label={t('terms:thing', { count: T_MANY })}
            />
          </Collapse>
        )}
        {mapToggleLayers.map(({ label, showSubMenuButton }, i) => {
          const isActive = isLayerActive(label);
          return (
            <Collapse
              key={`map-toggle-layer-selection-button-${i}`}
              data-testid="map-layer-selection-collapse"
              in={isBottomSectionIn}
              onEntered={calculateBounds}
            >
              <LayerSelectionCheckBox
                key={`map-toggle-layer-selection-button-${i}`}
                checked={isActive ? isLayerChecked(label) : false}
                disabled={!isActive}
                handleClick={() => handleToggleChange(label)}
                handleSubMenuButtonClick={() => openSubMenu(label)}
                label={t(`common:component.map.layers.labels.${label}`)}
                showSubMenuButton={showSubMenuButton}
              />
              {i === mapToggleLayers.length - 1 && <Divider />}
            </Collapse>
          );
        })}
        <Stack spacing={isBottomSectionIn ? 1 : 0} sx={{ mt: isBottomSectionIn ? 1 : 0 }}>
          {otherLayers.map((layer, i) => (
            <Collapse
              key={`map-base-layer-selection-button-${i}`}
              in={isBottomSectionIn}
              onEntered={calculateBounds}
            >
              <MapImage
                image={layer.image}
                label={t(`common:component.map.layers.labels.${layer.label.toLowerCase()}`)}
                onClick={() => handleBaseChange(layer.label)}
              />
            </Collapse>
          ))}
          <MapImage
            image={previewLayer.image}
            label={t(`common:component.map.layers.labels.${previewLayer.label.toLowerCase()}`)}
            onClick={handlePreviewLayerClick}
          />
        </Stack>
      </List>
    </Slide>
  );
};
