import { DateTime } from 'luxon';
import { FC } from 'react';

export interface FormatDateProps {
  format?: string;
  value: string | Date;
}

export const FormatDate: FC<FormatDateProps> = ({ value, format }) => {
  const date = typeof value === 'string' ? DateTime.fromISO(value) : DateTime.fromJSDate(value);
  let formattedDate;
  switch (format) {
    case 'short':
      formattedDate = date.toLocaleString(DateTime.DATE_SHORT);
      break;
    case 'long':
      formattedDate = date.toLocaleString(DateTime.DATE_FULL);
      break;
    // TODO: #5105 Need clarity on how relative date would be used and data required for implementation.
    // case 'relative':
    //   formattedDate = <RelativeDate date={date} />;
    //   break;
    default:
      formattedDate = date.toLocaleString(DateTime.DATE_MED);
  }
  return <>{formattedDate}</>;
};

export default FormatDate;
