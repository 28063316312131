import { useTranslation } from 'react-i18next';

export const defaultRouteDefinitions: Record<string, string> = {
  account: 'account',
  accounts: 'accounts',
  alert: 'alert',
  alertCondition: 'alert-condition',
  alertConditions: 'alert-conditions',
  alerts: 'alerts',
  analytics: 'analytics',
  authorizedApplication: 'authorized-application',
  authorizedApplications: 'authorized-applications',
  dashboards: 'dashboards',
  device: 'device',
  devices: 'devices',
  deviceType: 'device-type',
  deviceTypes: 'device-types',
  featureMatrix: 'feature-matrix',
  geofence: 'geofence',
  geofences: 'geofences',
  group: 'group',
  groups: 'groups',
  history: 'history',
  installation: 'installation',
  installations: 'installations',
  installationTemplate: 'installation-template',
  installationTemplates: 'installation-templates',
  people: 'people',
  person: 'person',
  pointOfInterest: 'point-of-interest',
  reports: 'reports',
  oidcGroupMapping: 'oidc-group-mapping',
  sticker: 'sticker',
  stickers: 'stickers',
  subscription: 'subscription',
  subscriptions: 'subscriptions',
  thing: 'thing',
  things: 'things',
  transfers: 'transfers',
};

export const useCustomRoutes = (): Record<string, string> => {
  const { i18n } = useTranslation('terms');

  const thing:string = i18n?.options?.resources?.en.translation?.['terms:thing_one'] as string || defaultRouteDefinitions.thing;
  const things:string = i18n?.options?.resources?.en.translation?.['terms:thing_other'] as string || defaultRouteDefinitions.things;
  const person:string = i18n?.options?.resources?.en.translation?.['terms:person_one'] as string || defaultRouteDefinitions.person;
  const people:string = i18n?.options?.resources?.en.translation?.['terms:person_other'] as string || defaultRouteDefinitions.people;

  const customRoutes = {
    things: things.toLocaleLowerCase().replace(/\s+/g, '-'),
    thing: thing.toLocaleLowerCase().replace(/\s+/g, '-'),
    person: person.toLocaleLowerCase().replace(/\s+/g, '-'),
    people: people.toLocaleLowerCase().replace(/\s+/g, '-'),
  };

  return {
    ...defaultRouteDefinitions,
    ...customRoutes,
  };
};
