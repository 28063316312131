import { Stack } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { Maybe } from '../../types';
import { ThingPersonDialogOperation } from '../assign-multiple-dialog/add-thing-person-dialog';
import { EventLocationData } from '../map';
import { EntityJourneyItem } from './entity-journey-item';
import { EntityTypeCacheType } from './entity-journey-types';
import { useHistorySearch } from './use-history-search';

interface EntityJourneyListControllerProps {
  handleDrawerToggle?: (value: boolean) => void;
  hoveredEventId: Maybe<string>;
  isDrawerOpen?: boolean;
  selectedEvent: Maybe<EventLocationData>;
  setHoveredEventId: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
}

export const EntityJourneyListController: FC<EntityJourneyListControllerProps> = ({
  handleDrawerToggle,
  hoveredEventId,
  isDrawerOpen,
  selectedEvent,
  setHoveredEventId,
  setSelectedEvent,
}) => {
  const { entityItems, setCurrentEntityId, setEntityDialogOperationType, setEntityTypeTab, setIsEntityDialogOpen } = useHistorySearch();

  return (
    <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
      {entityItems.map((entityItem, index) => (
        <EntityJourneyItem
          key={entityItem.id}
          data-testid={`entity-journey-${index}`}
          entityItem={entityItem}
          handleDrawerToggle={handleDrawerToggle}
          hoveredEventId={hoveredEventId}
          isDrawerOpen={isDrawerOpen}
          openDialog={(newOperationType: ThingPersonDialogOperation, id: string, entityType: EntityTypeCacheType) => {
            setEntityTypeTab(entityType);
            setCurrentEntityId(id);
            setEntityDialogOperationType(newOperationType);
            setIsEntityDialogOpen(true);
          }}
          selectedEvent={selectedEvent}
          setHoveredEventId={setHoveredEventId}
          setSelectedEvent={setSelectedEvent}
        />
      ))}
    </Stack>
  );
};
