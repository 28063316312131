import { Person, PersonType, SharedThingType, Thing, ThingType } from '@eagle/core-data-types';
import { Card, CardContent, Stack } from '@mui/material';
import { FC } from 'react';
import { CacheDataTypes, Portal, PortalTypes } from '../../types';
import { PortalSwitchingDropdown } from '../portal-switching';
import { StaticData } from '../static-data';

interface Props<T extends Person | Thing, U extends PersonType | ThingType, V extends (SharedThingType | undefined) = undefined> {
  'data-testid'?: string;
  entity: T;
  entityType: U;
  excludePortals?: Portal[];
  portal: Portal;
  sharedEntityType?: V;
  type: T extends Person ? typeof PortalTypes.PERSON_TYPE : typeof PortalTypes.THING_TYPE;
}

export const BasicEntityCard: FC<Props<Thing | Person, ThingType | PersonType, SharedThingType>> = ({ entityType, sharedEntityType, ...props }) => (
  <Card data-testid={props['data-testid']}>
    {props.type === CacheDataTypes.PERSON_TYPE
      ? <PortalSwitchingDropdown {...props as Props<Person, PersonType>} />
      : <PortalSwitchingDropdown {...props as Props<Thing, ThingType>} />
    }
    <CardContent>
      <Stack spacing={2}>
        <StaticData data={props.entity} dataType={entityType} sharedDataType={sharedEntityType} />
      </Stack>
    </CardContent>
  </Card>
);
