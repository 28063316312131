import { PaginatedResponse } from '@eagle/api-types';
import { Dashboard, DashboardType } from '@eagle/core-data-types';
import { Box, Link, ListItemButton, Popover, Stack, SvgIcon, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useNavigate, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useAuthenticated } from '../../auth';
import { useCustomRoutes, usePromise } from '../../hooks';
import { scrollbar } from '../../style';
import { Nullable } from '../../types';
import { testid } from '../../util';
import { MiddleSpinner } from '../middle-spinner';

interface DashboardSwitcherProps {
  children: ReactNode;
  dashboardId?: string;
  type: DashboardType;
  sx?: SxProps;
}

export const DashboardSwitcher: FC<DashboardSwitcherProps> = ({ children, dashboardId, type, sx = {} }) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);

  const open = Boolean(anchorEl);

  const clickHandler = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onClose = (): void => {
    setAnchorEl(null);
  };

  return <>
    <Link
      data-testid="dropdown-button"
      color='inherit'
      component='button'
      onClick={clickHandler}
      sx={{ lineHeight: 'inherit', textAlign: 'left', ...sx }}
      underline='none'
      variant='caption'
    >
      <Stack alignItems="center" direction="row">
        <Box sx={{overflow: 'hidden'}}>{children}</Box>
        <SvgIcon sx={{ height: '12px', ml: 0.5, width: '12px' }}><path d="m1 8 10 10 10-10z"></path></SvgIcon>
      </Stack>
    </Link>
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1400, mt: 1 }}
      onClick={(e) => { e.stopPropagation(); }}
    >
      <DashboardSwitcherInner
        dashboardId={dashboardId}
        onSelect={onClose}
        type={type}
      />
    </Popover>
  </>;
};

interface DashboardSwitcherInnerProps {
  dashboardId?: string;
  onSelect: (value: string) => void;
  type: DashboardType;
}

interface RequestParams {
  filter: Record<string, any>;
  limit?: number;
  search?: string;
  sort: Record<string, string>;
}

const DashboardSwitcherInner: FC<DashboardSwitcherInnerProps> = ({ dashboardId, onSelect, type }) => {
  const { t } = useTranslation(['common']);
  const { axios } = useAuthenticated();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const searchRef = useRef<HTMLInputElement>();
  const customRoutes = useCustomRoutes();

  const [dashboards, , dashboardStatus] = usePromise<Nullable<Dashboard[]>>(async () => {
    const params: RequestParams = {
      sort: { display: 'asc' },
      filter: { type, ...(dashboardId ? { _id: { $ne: dashboardId } } : {}) },
    };

    const response = await axios.get<PaginatedResponse<Dashboard>>('/api/v2/my/dashboards', {
      params,
    });

    return response.data.items;
  }, [type, axios, dashboardId]);

  useEffect(() => {
    if (!isSmallScreen && searchRef.current) {
      searchRef.current.focus();
    }
  }, [isSmallScreen]);

  const isLoading = dashboardStatus !== 'resolved';
  const isReport = type === DashboardType.REPORT;

  return (
    <Stack sx={{ width: 250, maxWidth: '100%' }}>
      <Box sx={{ px: 2, py: 1 }}>
        <Typography variant="caption">{t('common:component.navigation.switch-to.labels')}</Typography>
      </Box>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', ...scrollbar }}>
        {!isLoading
          ? <div>
            {dashboards?.length === 0
              && <Typography data-testid="dashboard-switcher-no-result" variant="body2" sx={{ px: 2, pt: 0, pb: 1 }}>
                {t('common.hint.list.no-results')}
              </Typography>
            }
            {dashboards?.map((dashboard) => (
              <ListItemButton
                data-testid={testid`dashboard-switcher-item-${dashboard._id}`}
                key={dashboard._id}
                onClick={() => {
                  onSelect(dashboard._id);
                  navigate(`/${isReport ? customRoutes.reports : customRoutes.dashboards}/${dashboard._id}`);
                }}
                sx={{ px: 2 }}
              >
                {dashboard.display}
              </ListItemButton>
            ))}
          </div>
          : <MiddleSpinner sx={{ py: 2 }} size={20} />
        }
      </Box>
    </Stack>
  );
};
