import { Box, Card, CardContent, Table as MuiTable, TableBody, TableContainer, TableHead, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, ReactNode } from 'react';

interface Props {
  'data-testid'?: string;
  fixedLayout?: boolean;
  headers?: ReactNode;
  rows: ReactNode;
  pagination?: ReactNode;
  small?: boolean;
  sticky?: boolean;
  sx?: SxProps;
  title?: string;
}

export const Table: FC<Props> = ({ headers, fixedLayout, rows, pagination, small, sticky, sx, title, ...props }) => {
  const theme = useTheme();

  const smallStyles = {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '& .MuiTableCell-sizeSmall:first-of-type': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
    '& .MuiTableCell-sizeSmall:last-child': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
    },
  };

  const styles: SxProps = {
    tableLayout: fixedLayout ? 'fixed' : 'inherit',
    ...(typeof sx === 'object' ? sx : {}),
  };

  return <Card data-testid={props['data-testid']} sx={{ padding: 0 }}>
    <CardContent sx={{ padding: '0 !important', overflowX: 'auto' }}>
      {title ? <Typography variant='h5' sx={{ p: 3 }}>{title}</Typography> : <></>}
      <TableContainer
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: 1,
          justifyContent: 'center',
          minWidth: 'min-content',
          width: 1,
        }}
      >
        <MuiTable
          stickyHeader={sticky}
          sx={small ? { ...styles, ...smallStyles } : styles}
          component='div'
          size={small ? 'small' : 'medium'}
        >
          <TableHead component="div">
            {headers}
          </TableHead>
          <TableBody component="div">
            {rows}
          </TableBody>
        </MuiTable>
        {pagination && <Box display='flex' justifyContent='center' marginY={1}>{pagination}</Box>}
      </TableContainer>
    </CardContent>
  </Card>;
};
