/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { Person, PersonThingRangeResponse, PersonType, Thing } from '@eagle/core-data-types';
import LinkIcon from '@mui/icons-material/Link';
import { Alert, Button, Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { T_ONE } from '../../constants';
import { usePromise } from '../../hooks';
import { CacheDataTypes, Portal, Portals, PortalTypes } from '../../types';
import { testid, useHasAuthorization } from '../../util';
import { AssignPersonDialog } from '../assign-dialog';
import { BasicEntityCard } from '../basic-card/basic-card';
import { EntityCardProps, EntityDefaultCardProps, EntityEditableCard } from '../basic-card/types';
import { ErrorMessage } from '../error-message';
import { FetchOneOfAll } from '../fetch';
import { LabelsEditor } from '../labels-editor';
import { LoadingButton } from '../loading-button';
import { MiddleSpinner } from '../middle-spinner';
import { PortalSwitchingDropdown } from '../portal-switching';
import { PropertiesElement } from '../properties-element';
import { TagsEditor } from '../tags-editor';
import { TextEditor } from '../text-editor';
import { ThingPersonDetail } from '../thing-card/thing-person-detail';

interface ThingPersonProps {
  portal: Portal;
  readonly?: boolean;
  thing: Thing;
  title?: string;
}

const PERSON_ADMIN = [RoleFunction.PERSON_ADMINISTRATOR] as const;
const ASSIGN_PERSON_ROLES = [RoleFunction.LINKING_ADMINISTRATOR] as const;

export const ThingPersonCard: FC<ThingPersonProps> = ({ thing, portal, readonly, title }) => {
  const { axios, restClient } = useAuthenticated();
  const { t } = useTranslation(['common', 'terms']);
  const { hasAuthorization } = useHasAuthorization();
  const assignThingsPermissions = hasAuthorization(ASSIGN_PERSON_ROLES);

  const [currentThingPersons, setCurrentThingPersons] = useState<PersonThingRangeResponse[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAssignPersonDialog, setShowAssignPersonDialog] = useState(false);
  const [updatePersons, setUpdatePersons] = useState(false);

  const [thingPersons, thingPersonsError, thingPersonsState] = usePromise<PersonThingRangeResponse[]>(
    async () => {
      if (!thing) return Promise.resolve([]);
      return restClient.personThing.getCurrentRangesByThing(thing._id);
    },
    [axios, thing, updatePersons],
  );

  useEffect(() => {
    if (thingPersons) setCurrentThingPersons(thingPersons);
  }, [thingPersons]);

  const updateCurrentPersons = (i: number): void => {
    const temporaryPersons = [...currentThingPersons];
    temporaryPersons.splice(i, 1);
    setCurrentThingPersons(temporaryPersons);
  };

  if (thingPersonsError) return <ErrorMessage error={thingPersonsError} />;
  if (thingPersonsState === 'pending') return <MiddleSpinner />;
  if (!thing) return <></>;

  return (
    <Card sx={{ height: 'min-content' }}>
      <PortalSwitchingDropdown
        actions={
          <>
            {assignThingsPermissions
              && <Button
                disabled={readonly}
                onClick={() => setShowAssignPersonDialog(true)}
                size="small"
                startIcon={<LinkIcon />}
                sx={{ p: 0 }}
              >
                {!currentThingPersons.length ? t('common:page.thing-detail.person-list.assign.action') : t('common:page.thing-detail.person-list.assign-another.action')}
              </Button>
            }
          </>
        }
        entity={currentThingPersons[0]}
        excludePortals={[Portals.MANAGE]}
        portal={portal}
        title={title ?? t('terms:person', { count: !currentThingPersons.length ? T_ONE : currentThingPersons.length })}
        type={PortalTypes.THING_PERSON_TYPE}
      />
      <CardContent>
        {!currentThingPersons.length
          ? <>
            <Typography sx={{ mb: 1.5 }} color="text.secondary" fontStyle="italic" variant="body2">
              {t('common:page.thing-detail.person-list.hint.none')}
            </Typography>
            {assignThingsPermissions
              && <Button
                disabled={readonly}
                onClick={() => setShowAssignPersonDialog(true)}
                size="small"
                startIcon={<LinkIcon />}
                sx={{ p: 0 }}
              >
                {t('common:page.thing-detail.person-list.assign.action')}
              </Button>
            }
          </>
          : <>
            {currentThingPersons.map((thingPerson, i) => {
              return (i === 0 || isExpanded)
                && <Fragment key={i}>
                  <ThingPersonDetail
                    canEditAssignment={assignThingsPermissions}
                    finishPerson={() => updateCurrentPersons(i)}
                    readonly={readonly}
                    thing={thing}
                    thingPerson={thingPerson}
                  />
                </Fragment>;
            })}
            {(currentThingPersons.length > 1)
              && <Button
                onClick={() => setIsExpanded(!isExpanded)}
                size="small"
                sx={{ p: 0 }}
              >
                {isExpanded ? t('common:common.action.hideOthers') : t('common:common.labels.othersWithCount', { count: currentThingPersons.length - 1 })}
              </Button>
            }
          </>
        }
      </CardContent>
      <AssignPersonDialog
        assignedPersons={currentThingPersons.map((thingPerson) => ({ personId: thingPerson.personId, role: thingPerson.role }))}
        handleClose={() => {
          setShowAssignPersonDialog(false);
          setUpdatePersons(!updatePersons);
        }}
        open={showAssignPersonDialog}
        tFunction={t}
        thing={thing}
      />
    </Card>
  );
};

const NormalCard: FC<EntityDefaultCardProps<Person, PersonType>> = ({ entity, entityType, portal }) => {
  if (entityType) {
    return <BasicEntityCard
      entity={entity}
      entityType={entityType}
      excludePortals={[Portals.MANAGE]}
      portal={portal}
      type={PortalTypes.PERSON_TYPE}
    />;
  }

  return (
    <FetchOneOfAll
      dataType={CacheDataTypes.PERSON_TYPE}
      id={entity.personTypeId}
      renderFactory={(data: PersonType) => (
        <BasicEntityCard
          entity={entity}
          entityType={data}
          excludePortals={[Portals.MANAGE]}
          portal={portal}
          type={PortalTypes.PERSON_TYPE}
        />
      )}
    />
  );
};

const EditableCard: FC<EntityEditableCard<Person, PersonType>> = ({
  entityState,
  entityType,
  error,
  handlePropertyChange,
  loadingButtonClass,
  readonly,
  saveInProgress,
  updateEntity,
}) => {
  const { t } = useTranslation(['common']);
  const { hasAuthorization } = useHasAuthorization();
  const hasEditPermissions = hasAuthorization(PERSON_ADMIN);

  return (
    <Stack spacing={2.5}>
      <TextField
        disabled
        label={t('common:common.labels.id')}
        size="small"
        value={entityState._id}
        data-testid={testid`person-id-${entityState._id}`}
      />
      <TextEditor
        disabled={!hasEditPermissions || saveInProgress || readonly}
        label={t('common:common.labels.display')}
        onChange={handlePropertyChange('display')}
        required
        size="small"
        value={entityState.display}
        data-testid="person-display-input"
      />
      {entityType.properties
        && <PropertiesElement
          disabled={!hasEditPermissions || saveInProgress || readonly}
          onChange={handlePropertyChange('properties')}
          properties={entityState.properties}
          propertyDefinitions={entityType.properties}
          size="small"
        />
      }
      <TagsEditor
        disabled={!hasEditPermissions || saveInProgress || readonly}
        label={t('common:terms.tag')}
        onChange={handlePropertyChange('tags')}
        placeholder={t('common:common.hint.tag')}
        size="small"
        tags={entityState.tags}
      />
      <Typography variant="h6">{t('common:terms.label')}</Typography>
      <LabelsEditor
        disabled={!hasEditPermissions || saveInProgress || readonly}
        labels={entityState.labels}
        onChange={handlePropertyChange('labels')}
        placeholder={t('common:common.hint.label')}
        size="small"
        textfieldLabel={t('common:terms.label')}
      />
      {error
        && <Alert severity="error">
          {error}
        </Alert>
      }
      {hasEditPermissions
        && <LoadingButton
          className={loadingButtonClass}
          disabled={readonly}
          loadingCaption={t('common:common.hint.saving')}
          task={updateEntity}
          sx={{ alignSelf: 'end', minWidth: 120 }}
          data-testid="save-button"
        >
          {t('common:common.action.save')}
        </LoadingButton>
      }
    </Stack>
  );
};

const AdminCard: FC<EntityCardProps<Person, PersonType>> = ({ entityType, ...props }) => {
  if (!entityType) {
    return (
      <FetchOneOfAll
        dataType={CacheDataTypes.PERSON_TYPE}
        id={props.entity.personTypeId}
        renderFactory={(data: PersonType) => <EditableCard {...props as Required<EntityEditableCard<Person, PersonType>>} entityType={data} />}
      />
    );
  }
  return <EditableCard {...props as Required<EntityEditableCard<Person, PersonType>>} entityType={entityType} />;
};

export const PersonCard: FC<EntityCardProps<Person, PersonType>> = ({ portal, editable, ...props }) => {
  if (!editable) return <NormalCard {...props} portal={portal} />;
  return (
    <Card>
      <PortalSwitchingDropdown
        entity={props.entity}
        excludePortals={[Portals.MANAGE]}
        portal={portal}
        type={PortalTypes.PERSON_TYPE}
        data-testid={'person-title'}
      />
      <CardContent>
        <AdminCard {...props as EntityEditableCard<Person, PersonType>} />
      </CardContent>
    </Card>
  );
};
