import { makeStyles } from '../../theme';

export const imageModalStyles = makeStyles()(() => ({
  imageModal: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  imageBox: {
    objectFit: 'cover',
    maxHeight: '100vh',
    maxWidth: '100vw',
  },
  actionCard: {
    backgroundColor: '#25252585',
    padding: 8,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
  },
  actionButton: {
    color: 'white',
  },
}));
