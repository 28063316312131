import { Pagination, PaginationItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSmallScreen } from '../../hooks';
import { Alert } from '../alert';
import { Pagination as PaginationData } from '../entity-search/types';
import { FlexBox } from '../flex-box';
import { MiddleSpinner } from '../middle-spinner';

type ContentRenderFunction<T> = (params: { items: T[]; paginationElement: JSX.Element | null }) => JSX.Element

interface GeneralPaginatedListViewProps<T> {
  hasError: boolean;
  isLoading: boolean;
  items?: T[];
  matchCount: number;
  pagination: PaginationData;
  renderDesktopContent: ContentRenderFunction<T>;
  renderMobileContent: ContentRenderFunction<T>;
  setPagination: (pagination: PaginationData) => void;
}

/**
  * NATIVE FUNCTION: General paginated list view component
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function GeneralPaginatedListView<T>({
  isLoading,
  items,
  matchCount,
  pagination,
  setPagination,
  renderDesktopContent,
  hasError,
  renderMobileContent }: GeneralPaginatedListViewProps<T>,
): JSX.Element {
  const smallScreen = useSmallScreen();
  const { t } = useTranslation();

  const paginationElement = matchCount > pagination.limit ? (
    <FlexBox sx={{ my: 1, justifyContent: 'center' }}>
      <Pagination
        count={Math.ceil(matchCount / pagination.limit)}
        disabled={isLoading}
        onChange={(_, page) => {
          setPagination({ limit: pagination.limit, skip: pagination.limit * (page - 1) });
        }}
        renderItem={(item) => (
          <PaginationItem {...item} />
        )}
        page={Math.floor(pagination.skip / pagination.limit) + 1}
        showFirstButton={!smallScreen}
        showLastButton={!smallScreen}
        size={smallScreen ? 'small' : 'large'}
      />
    </FlexBox>
  ) : null;

  if (hasError) {
    return <Alert severity="error">{t('common:component.search.hint.unexpected-error-message')}</Alert>;
  }

  if ((isLoading && matchCount === 0) || items === undefined) {
    return <MiddleSpinner />;
  }

  if (smallScreen) {
    return renderMobileContent({ paginationElement, items });
  }

  return renderDesktopContent({ paginationElement, items });
}
