/* eslint-disable react-hooks/exhaustive-deps */
import { Id } from '@eagle/api-types';
import { Pagination, PaginationItem, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { from, of, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { API_CALL_TEXT_LENGTH } from '../../constants';
import { useObservable, useSmallScreen } from '../../hooks';
import { FindItemsDeferredResult, Query } from '../../pages';
import { SearchProvider, useSearch } from '../../pages/list/use-search';
import { Undefinable } from '../../types';
import { testid } from '../../util';
import { ErrorMessage } from '../error-message';

interface Props<T> {
  actions?: JSX.Element[];
  limit?: number;
  hidePagination?: boolean;
  onQueryChanged: (query: Query) => Undefinable<FindItemsDeferredResult<T>>;
  renderContent: (items: Undefinable<T[]>, isLoading: boolean, text?: string) => JSX.Element;
}

const InternalBasicListController = <T extends Id<I>, I = string>({
  actions,
  limit,
  hidePagination,
  onQueryChanged,
  renderContent,
}: Props<T>): JSX.Element => {
  const smallScreen = useSmallScreen();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isLoading,
    pagination,
    setIsLoading,
    setPagination,
    setResult,
    text,
  } = useSearch();

  const onError = (error: Error): void => {
    enqueueSnackbar(<ErrorMessage error={error} />, { variant: 'error' });
  };

  const { handleQueryChanged, observable } = useMemo(() => {
    const subject = new Subject<Query>();

    return {
      handleQueryChanged: (query: Query): void => subject.next(query),
      observable: subject.pipe(
        tap(() => setIsLoading(true)),
        debounceTime(350),
        switchMap((query: Query) => {
          if (query.search.length < API_CALL_TEXT_LENGTH && query.search.length !== 0) {
            return from([{ result: { results: [], itemCount: 0 }, resultDescription: '' }])
              .pipe(
                tap(({ result }) => {
                  setIsLoading(false);
                  setResult({ itemCount: result.results.length, matchCount: result.itemCount });
                }),
                finalize(() => true),
              );
          }

          const deferred = onQueryChanged(query);
          if (!deferred) return of(undefined);

          let complete = false;

          return from(deferred.promise).pipe(
            tap(({ result }) => {
              setResult({ itemCount: result.results.length, matchCount: result.itemCount });
              complete = true;
            }),
            catchError((err: Error) => {
              onError(err);
              return of(undefined);
            }),
            finalize(() => complete || deferred.cancel()),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [onQueryChanged]);
  const data = useObservable(observable, onError);

  useEffect(() => {
    if (data?.result.results.length === 0 && pagination.skip > pagination.limit) {
      setPagination({
        limit: pagination.limit,
        skip: pagination.skip - pagination.limit,
      });
    }
  }, [data]);

  useEffect(() => {
    const paginationQuery = limit ? { pagination: { limit, skip: pagination.skip } } : {};
    handleQueryChanged({ ...paginationQuery, search: text, filters: [] });
  }, [pagination, text, handleQueryChanged]);

  const matchCount = data?.result.itemCount ?? 0;
  const paginationComponent = useMemo(
    () => limit && !hidePagination && matchCount > limit
      ? <Pagination
        count={Math.ceil(matchCount / limit)}
        data-testid="pagination-navigation"
        disabled={isLoading}
        onChange={(_, page) => setPagination({ limit, skip: limit * (page - 1) })}
        renderItem={(item) => (
          <PaginationItem data-testid={testid`pagination-item-${item.type}${item.type === 'page' ? `-${item.page ?? ''}` : ''}`} {...item} />
        )}
        page={Math.floor(pagination.skip / limit) + 1}
        showFirstButton={!smallScreen}
        showLastButton={!smallScreen}
        size={smallScreen ? 'small' : 'small'}
        sx={{ marginBottom: '-10px', alignSelf: 'center' }}
      />
      : undefined,
    [hidePagination, isLoading, matchCount, pagination, smallScreen],
  );

  return (
    <Stack spacing={2}>
      {actions}
      {data?.resultDescription && data.result.itemCount > 0 && <Typography color="text.secondary" fontStyle="italic" variant='subtitle2' >{data.resultDescription}</Typography>}
      {renderContent(data?.result.results, isLoading, text)}
      {paginationComponent}
    </Stack>
  );
};

export const BasicList = <T extends Id<I>, I = string>(props: Props<T>): JSX.Element => {
  return (
    <SearchProvider dataKey="basic-list">
      <InternalBasicListController<T, I> {...props} />
    </SearchProvider>
  );
};
