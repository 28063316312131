import { Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { ChangeEvent, FC, KeyboardEvent, ReactNode, useEffect, useState } from 'react';
import { ulid } from 'ulid';
import { FlexBox } from '../flex-box';

interface Props {
  'data-testid'?: string;
  disabled?: boolean;
  edge?: 'start' | 'end' | false;
  icon?: ReactNode;
  id?: string;
  isChecked: boolean;
  label?: string;
  onChange?: (checked: boolean) => Promise<void>;
  required?: boolean;
  size?: 'small' | 'medium';
}

export const SwitchEditor: FC<Props> = ({
  disabled = false,
  edge,
  icon,
  id = ulid(),
  isChecked: initialIsChecked,
  label,
  onChange,
  required,
  size,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(initialIsChecked);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsChecked(initialIsChecked);
  }, [initialIsChecked]);

  const onInputKeyDown = (event: KeyboardEvent<HTMLButtonElement>): void => {
    if (event.key === 'Enter') {
      (event.target as HTMLButtonElement).blur();
    }
  };

  const onValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsChecked(event.target.checked);
    if (!onChange) return;
    setIsLoading(true);
    void onChange(event.target.checked)
      .then(() => {
        setIsLoading(false);
        event.target.focus();
      });
  };

  const switchComponent = (
    <Switch
      id={id}
      checked={isChecked}
      color="primary"
      data-testid="switch-input"
      disabled={disabled || isLoading}
      edge={edge}
      inputProps={{ 'aria-label': 'Switch' }}
      onChange={onValueChange}
      onKeyDown={onInputKeyDown}
      required={required}
      size={size}
    />
  );

  if (label) {
    return (
      <FlexBox data-testid={props['data-testid']} flexDirection="row" gap={1} alignItems="center">
        <Typography>{label}</Typography>
        {switchComponent}
        {icon}
      </FlexBox>
    );
  }

  return switchComponent;
};
