import { Card, CardContent, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NoPermissionsCardBody } from '../permissions';

interface Props {
  sx?: SxProps;
}

export const NoPermission: FC<Props> = ({ sx }): JSX.Element => {
  const { t } = useTranslation(['common']);

  return (
    <Card sx={sx}>
      <CardContent sx={{ p: 0 }}>
        <Typography variant="h5">
          {t('common:features.media')}
        </Typography>
      </CardContent>
      <NoPermissionsCardBody data-testid='no-permissions-media' />
    </Card>
  );
};
