import { stringify } from '../../util';

export const safeKey = (strings: TemplateStringsArray, ...args: unknown[]): string => {
  const parts = strings.reduce<string[]>((acc, part, i) => {
    if (args.length > i) {
      return [...acc, part, stringify(args[i])];
    }
    return [...acc, part];
  }, []);
  return parts.join('').replace(/[^a-zA-Z0-9.]+/g, '_').toLowerCase();
};

export const flattenTags = (tags: string[], prefix = ''): Record<string, true> => tags.reduce<Record<string, true>>(
  (acc, tag) => ({
    ...acc,
    [safeKey`${prefix} ${tag}`]: true,
  }),
  {},
);

export const prefixLabels = (labels: Record<string, unknown>, prefix: string): Record<string, unknown> => Object.entries(labels).reduce<Record<string, unknown>>(
  (acc, [key, value]) => ({
    ...acc,
    [safeKey`${prefix} ${key}`]: value,
  }),
  {},
);
