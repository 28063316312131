import { Box, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from '../../types';
import { testid } from '../../util';
import ConfirmDialogMenu from '../confirm-dialog/confirm-dialog-menu';
import { CardAction } from './entity-journey-types';

interface ConfirmActionItemProps {
  action: CardAction;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface Props {
  actions: CardAction[];
  anchorEl: Nullable<HTMLElement>;
  'data-testid'?: string;
  handleClose: () => void;
  open: boolean;
}

const ConfirmActionItem: FC<ConfirmActionItemProps> = ({ action, open, setOpen }) => {
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  return (
    <Box sx={{ ...open && { pt: theme.spacing(1) } }}>
      <ConfirmDialogMenu
        confirmPrompt={
          <Typography>
            {action.confirmPrompt}
          </Typography>
        }
        onConfirm={async () => {
          action.onClick();
          return Promise.resolve();
        }}
        onFailure={() => {
          enqueueSnackbar(t('common:component.entity-journey.hint.action-failure', { action: action.label }), { variant: 'error' });
        }}
        onSuccess={() => {
          enqueueSnackbar(t('common:component.entity-journey.hint.action-success', { action: action.label }), { variant: 'success' });
        }}
        open={open}
        setOpen={setOpen}
      >
        <ListItemIcon>{action.icon}</ListItemIcon>
        <ListItemText>{action.label}</ListItemText>
      </ConfirmDialogMenu>
    </Box>
  );
};

export const EntityCardActions: FC<Props> = ({ actions, anchorEl, handleClose, open, ...props }) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      data-testid={props['data-testid']}
      onClose={handleClose}
      open={open}
    >
      <MenuList>
        {actions.map((action, i) => (
          <Box key={i}>
            {action.isConfirm
              ? <ConfirmActionItem key={i} action={action} open={isConfirmationOpen} setOpen={setIsConfirmationOpen} />
              : <MenuItem data-testid={testid`menu-item-${action.name}`} key={i} disabled={isConfirmationOpen} onClick={(e) => {
                e.stopPropagation();
                action.onClick();
              }}>
                <ListItemIcon>{action.icon}</ListItemIcon>
                <ListItemText>{action.label}</ListItemText>
              </MenuItem>
            }
          </Box>
        ))}
      </MenuList>
    </Menu>
  );
};
