import { AccordionSummary as MUIAccordionSummary, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface Props extends PropsWithChildren {
  'aria-controls'?: string;
  'data-testid'?: string;
  expandIcon?: ReactNode;
  id?: string;
  sx?: SxProps<Theme>;
}

export const AccordionSummary: FC<Props> = ({ children, ...props }) => {
  return <MUIAccordionSummary {...props}>{children}</MUIAccordionSummary>;
};
