import { useDomainTheme } from '../components';
import { LinkResource } from '../types';

export const useResourceLoader = (resources: LinkResource[]): void => {
  const { domainTheme } = useDomainTheme();

  resources.map((res) => {
    const resource  = document.querySelector(`#${res.id}`) as HTMLLinkElement;
    if (resource) {
      resource.href = new URL(`${new URL(import.meta.url).origin}/${res.href}${`?domain=${domainTheme}`}`).toString();
    } else {
      const el:HTMLLinkElement = document.createElement('link');
      el.rel = res.rel;
      el.href = new URL(`${new URL(import.meta.url).origin}/${res.href}${`?domain=${domainTheme}`}`).toString();
      el.id = res.id;
      document.head.appendChild(el);
    }
  });
};
