import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { DomainCustomConfig, useAuthenticated } from '../auth';
import { Undefinable } from '../types';
import { applyDefaultConfig, loadConfig } from '../util';
import { usePromise } from './use-promise';

export const configContext = createContext<Partial<DomainCustomConfig>>({});

export const ProvideConfig: FC<PropsWithChildren> = ({ children }) => {
  const config = useProvideConfig();

  return <configContext.Provider value={config}>{children}</configContext.Provider>;
};

export const ConfigProvider: FC<PropsWithChildren & { config: Partial<DomainCustomConfig> }> = ({ config, children }) => {
  return <configContext.Provider value={applyDefaultConfig(config)}>{children}</configContext.Provider>;
};

const useProvideConfig = (): Partial<DomainCustomConfig> => {
  const [config] = usePromise<Undefinable<Partial<DomainCustomConfig>>>(() => loadConfig(), []);
  return config || {};
};

export const useConfig = (): Partial<DomainCustomConfig> => {
  return useContext(configContext);
};

export const useSwitchAwareConfig = (): Partial<DomainCustomConfig> => {
  const config = useConfig();
  const { switchedConfig } = useAuthenticated();

  return switchedConfig ?? config;
};
