import { Card, CardContent, Container, Divider, Stack } from '@mui/material';
import { FC } from 'react';
import { FlexBox } from '../../components';

interface Props {
  avatarComponent: JSX.Element;
  content: JSX.Element;
  pageActions: JSX.Element;
  pageTitle: JSX.Element;
}

export const ProfilePageMobileLayout: FC<Props> = ({ avatarComponent, content, pageActions, pageTitle }) => (
  <FlexBox flexDirection="column" overflow="auto">
    <Container sx={{ flexGrow: 1, py: 3 }} id="detail-page">
      <Stack id="page-header" className="detail-header" direction="column">
        {pageTitle}
      </Stack>

      <Divider sx={{ my: 2 }} />

      <Stack id="content" className="detail-content" direction="column" display="flex" spacing={4}>
        <Stack
          direction="column"
          flex={[2, 2]}
          spacing={2}
          sx={{ minWidth: 0 }}
        >
          <Card>
            <CardContent>
              {avatarComponent}
            </CardContent>
          </Card>
        </Stack>

        <Stack
          direction="column"
          flex={[3, 3]}
          spacing={2}
          sx={{ minWidth: 0 }}
        >
          {content}
        </Stack>
      </Stack>
    </Container>
    {pageActions}
  </FlexBox>
);
