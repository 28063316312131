export const DEFAULT_MAP_MARKER = {
  op: 'if-feature-data',
  then: {
    op: 'ui',
    name: 'MapMarker',
    markerType: 'pin',
    background: {
      op: 'if',
      condition: {
        op: 'before-past',
        timestamp: {
          op: 'feature-latest-occurred',
          feature: 'tracking',
          havingPath: 'data.location',
        },
        duration: 'P7D',
      },
      then: {
        op: 'theme-property',
        path: 'states.uncertain',
      },
    },
    indicator: {
      op: 'if',
      condition: {
        op: 'before-past',
        timestamp: {
          op: 'feature-latest-occurred',
          feature: 'tracking',
          havingPath: 'data.location',
        },
        duration: 'P7D',
      },
      then: null,
      else: {
        op: 'if',
        condition: { op: 'macro:is-power-on' },
        then: {
          op: 'if',
          condition: { op: 'macro:is-moving' },
          then: {
            op: 'theme-property',
            path: 'states.on',
          },
          else: {
            op: 'theme-property',
            path: 'states.paused',
          },
        },
        else: {
          op: 'if',
          condition: { op: 'macro:is-moving' },
          then: {
            op: 'theme-property',
            path: 'states.on',
          },
          else: {
            op: 'theme-property',
            path: 'states.off',
          },
        },
      },
    },
    cacheDataType: {
      op: 'context',
      path: 'dataType',
    },
    disableMarkerClick: {
      op: 'context',
      path: 'disableMarkerClick',
    },
    position: {
      op: 'context',
      path: 'position',
    },
    entityId: {
      op: 'context',
      path: 'entityId',
    },
    key: {
      op: 'context',
      path: 'entityId',
    },
  },
  else: {
    op: 'ui',
    name: 'MapMarker',
    background: {
      op: 'theme-property',
      path: 'states.unknown',
    },
    cacheDataType: {
      op: 'context',
      path: 'dataType',
    },
    disableMarkerClick: {
      op: 'context',
      path: 'disableMarkerClick',
    },
    position: {
      op: 'context',
      path: 'position',
    },
    entityId: {
      op: 'context',
      path: 'entityId',
    },
    key: {
      op: 'context',
      path: 'entityId',
    },
  },
};
