import { Button, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, useState } from 'react';
import { testid } from '../../util/test-id';
import { ConfirmDialogConfirmation } from './confirm-dialog-confirmation';

interface Props {
  buttonColor?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  buttonDisabled?: boolean;
  buttonFullWidth?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
  buttonVariant?: 'text' | 'contained' | 'outlined';
  confirmPrompt: JSX.Element;
  hasCancel?: boolean;
  initialPrompt: string;
  initialPromptIcon?: JSX.Element;
  okText?: string;
  onConfirm: () => Promise<unknown>;
  onFailure?: () => void;
  onSuccess?: () => void;
  stackDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  sx?: SxProps<Theme>;
}

export const ConfirmDialog: FC<Props> = ({
  buttonColor = 'primary',
  buttonDisabled = false,
  buttonFullWidth = false,
  buttonSize = 'small',
  buttonVariant = 'text',
  confirmPrompt,
  hasCancel,
  initialPrompt,
  initialPromptIcon,
  okText,
  onConfirm,
  onFailure,
  onSuccess,
  stackDirection = 'row',
  sx,
}): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {!open
        ? <Button
          disabled={buttonDisabled}
          fullWidth={buttonFullWidth}
          onClick={(e) => { e.preventDefault(); setOpen(true); }}
          onMouseDown={(e) => e.stopPropagation()}
          size={buttonSize}
          startIcon={initialPromptIcon}
          sx={sx}
          variant={buttonVariant}
          color={buttonColor}
          data-testid={testid`action-button-${initialPrompt}`}
        >
          {initialPrompt}
        </Button>
        : <ConfirmDialogConfirmation
          confirmPrompt={confirmPrompt}
          hasCancel={hasCancel}
          okText={okText}
          onConfirm={onConfirm}
          onFailure={onFailure}
          onSuccess={onSuccess}
          setOpen={setOpen}
          stackDirection={stackDirection}
        />
      }
    </Stack>
  );
};

export default ConfirmDialog;
