import { Button, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { T_ONE } from '../../constants';
import { FlexBox } from '../flex-box';
import { PhoneInput } from '../phone-input';
import { TextEditor } from '../text-editor';
import { UserBasicType } from './types';

interface Props {
  disabled?: boolean;
  handleChange: (value: string, index: number, key: keyof UserBasicType) => Promise<void>;
  handleRemove: (index: number) => Promise<void>;
  index: number;
  showDisplay?: boolean;
  showNotification?: boolean;
  showPhone?: boolean;
  showRemove?: boolean;
  skipValidateEmailEmpty?: boolean;
  userItem: UserBasicType;
}

export const UserBasicItem: FC<Props> = ({
  disabled,
  handleChange,
  handleRemove,
  index,
  showDisplay,
  showNotification,
  showPhone,
  showRemove,
  skipValidateEmailEmpty,
  userItem,
}) => {
  const { t } = useTranslation(['common']);
  const [emailError, setEmailError] = useState(false);
  const [notificationEmailError, setNotificationEmailError] = useState(false);

  const handleEmailChange = async (value: string): Promise<void> => {
    await handleChange(value, index, 'email');
    const emailCheck = skipValidateEmailEmpty
      ? value.length === 0 || validator.isEmail(value)
      : validator.isEmail(value);
    if (!emailCheck) {
      setEmailError(true);
      return Promise.resolve();
    }
    setEmailError(false);
  };

  const handleDisplayChange = async (value: string): Promise<void> => {
    await handleChange(value, index, 'display');
  };

  const handleNotificationEmailChange = async (value: string): Promise<void> => {
    await handleChange(value, index, 'notificationEmail');
    const emailCheck = validator.isEmail(value);
    if (!emailCheck) {
      setNotificationEmailError(true);
      return Promise.resolve();
    }

    setNotificationEmailError(false);

    return Promise.resolve();
  };

  const handlePhoneChange = async (value: string): Promise<void> => {
    await handleChange(value, index, 'notificationPhone');
  };

  return (
    <Stack spacing={2} key={`details-${index}`}>
      <TextEditor
        data-testid={`email-input-${index}`}
        disabled={disabled}
        error={emailError}
        helperText={emailError ? t('common:common.hint.email') : undefined}
        label={t('common:common.labels.email')}
        onKeyUp={handleEmailChange}
        size="small"
        value={userItem.email}
      />
      {showDisplay
        && <TextEditor
          data-testid={`display-input-${index}`}
          disabled={disabled}
          label={t('common:common.labels.display')}
          onKeyUp={handleDisplayChange}
          size="small"
          value={userItem.display}
        />
      }
      {showNotification
        && <TextEditor
          data-testid="notification-email-input"
          error={notificationEmailError}
          helperText={notificationEmailError ? t('common:page.profile.notification-email.hint') : undefined}
          label={t('common:page.profile.notification-email.labels')}
          onChange={handleNotificationEmailChange}
          placeholder={userItem.email ?? t('common:page.profile.notification-email.hint')}
          size="small"
          value={userItem.notificationEmail ?? ''}
        />
      }
      {showPhone
        && <PhoneInput
          data-testid="phone-input"
          disableFormatting
          label={t('common:component.properties-element.labels.sms')}
          onChange={handlePhoneChange}
          size="small"
          value={userItem.notificationPhone ?? ''}
        />}
      {showRemove
        && <FlexBox>
          <Button
            data-testid="remove-user-button"
            onClick={() => handleRemove(index)}
            variant="outlined"
          >
            {t('common:common.action.remove-entity', { entity: t('common:terms.user', { count: T_ONE }) })}
          </Button>
        </FlexBox>
      }
    </Stack>
  );
};
