import { useEffect, useState } from 'react';

export interface ScriptState {
  error: boolean;
  loaded: boolean;
}

export const useScript = (src: string): ScriptState => {
  const [state, setState] = useState<ScriptState>({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    const onScriptLoad = (event: Event): void => {
      const eventTarget = event.target as HTMLScriptElement;
      eventTarget.dataset.loaded = 'true';
      setState({ loaded: true, error: false });
    };

    const onScriptError = (event: Event): void => {
      const eventTarget = event.target as HTMLScriptElement;
      console.error(`onScriptError: ${src}`);
      eventTarget.remove();
      setState({ loaded: true, error: true });
    };

    const removeListeners = (scriptElement: HTMLScriptElement): void => {
      scriptElement.removeEventListener('load', onScriptLoad);
      scriptElement.removeEventListener('error', onScriptError);
    };

    const getCleanup = (scriptElement: HTMLScriptElement) => () => {
      removeListeners(scriptElement);
      scriptElement.dispatchEvent(new Event('cleanup'));
      scriptElement.remove();
    };

    const existingScript = document.querySelector<HTMLScriptElement>(`script[src="${src.replace(/"/g, '\\\\"')}"]`);
    if (existingScript) {
      if (existingScript.dataset.loaded !== 'true') {
        existingScript.addEventListener('load', onScriptLoad);
        existingScript.addEventListener('error', onScriptError);
        existingScript.addEventListener('cleanup', () => removeListeners(existingScript));
        return;
      }

      setState({ loaded: true, error: false });
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = false;

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    document.body.appendChild(script);

    return getCleanup(script);
  }, [src]);

  return state;
};
