import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from '../../theme';
import { EthingsLogo } from '../logo';

interface Props {
  collapsed: boolean;
}

export namespace PortalHeader {

  export const Generic: FC<Props> = ({ collapsed }) => {
    const theme = useTheme();

    const useStyles = makeStyles()(() => ({
      svg: {
        transition: 'margin-left 200ms ease-in-out',
        marginLeft: collapsed ? '2px' : '50px',
        width: '150px',
        height: '60px',
      },
      text: {
        transition: 'opacity 200ms ease-in-out',
        opacity: collapsed ? 0 : 1,
      },
    }));

    const { classes } = useStyles();

    return <Box sx={{ mt: 2, mx: 2, width: '100%' }}>
      <EthingsLogo logo={theme.sidebar.logo} classes={classes} />
    </Box>;
  };

}
