/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { Feature, FeatureType } from '@eagle/core-data-types';
import { Contract, isArray, isObject, isString } from 'validata';
import { useAuthenticated } from '../auth';
import { useBoolFlag, useObjectFlag } from '../components';
import { useFetchAllCache, usePromise } from '../hooks';
import { CacheDataTypes, Nullable, Undefinable } from '../types';
import { filterDeletedCache } from './filter';

export const getFeatureType = (alertTypeId: Nullable<string>): Nullable<string> => {
  const featureTypesCache = useFetchAllCache(CacheDataTypes.FEATURE_TYPE);

  const [featureTypes] = usePromise(
    Promise.resolve(featureTypesCache.all<FeatureType>()),
    [featureTypesCache],
  );

  if (!alertTypeId) {
    return null;
  }

  return featureTypes?.find((featureType) => alertTypeId in featureType.alerts)?._id ?? null;
};

interface AlertableFeatures {
  alertableFeatures: Feature[];
  alertableFeaturesError: Error;
  alertableFeatureTypes: FeatureType[];
  alertableFeatureTypesError: Error;
  excludedFeatures: ExcludedFeature[];
}

interface ExcludedFeature {
  featureId: string;
  eventTypeIds: string[];
}

const excludedFeatureContract: Contract<ExcludedFeature> = {
  featureId: isString(),
  eventTypeIds: isArray(isString()),
};

const checkExcludedFeatures = isArray(isObject(excludedFeatureContract));

export const useAlertableFeatures = (): Partial<AlertableFeatures> => {
  const { axios } = useAuthenticated();
  const featureCache = useFetchAllCache(CacheDataTypes.FEATURE);
  const featureTypeCache = useFetchAllCache(CacheDataTypes.FEATURE_TYPE);
  const isExcludingEnabled = useBoolFlag('admin-alert-conditions-v2-use-exclusionary-list-temporary-feature');
  const excludedFeatures = useObjectFlag('admin-alert-conditions-v2-excluded-features-and-events', checkExcludedFeatures);

  const [alertableFeatureTypes, alertableFeatureTypesError] = usePromise<FeatureType[]>(
    async () => {
      const featureTypes = await filterDeletedCache<FeatureType>(featureTypeCache);
      return featureTypes.filter(({ alerts }) => Object.keys(alerts).length > 0);
    },
    [axios, featureTypeCache],
  );

  const [alertableFeatures, alertableFeaturesError] = usePromise<Feature[]>(
    async () => {
      if (!alertableFeatureTypes) return Promise.resolve([]);
      const featureTypes = await filterDeletedCache<Feature>(featureCache);
      return featureTypes.filter(
        ({ featureTypeId }) => alertableFeatureTypes?.some(({ _id }) => featureTypeId === _id),
      );
    },
    [axios, alertableFeatureTypes, featureCache],
  );

  return {
    alertableFeatures,
    alertableFeaturesError,
    alertableFeatureTypes,
    alertableFeatureTypesError,
    excludedFeatures: isExcludingEnabled ? excludedFeatures : undefined,
  };
};

export const getAvailableEventTypes = (feature: Undefinable<string>, featureType: Undefinable<FeatureType>, excludedFeatures: Undefinable<ExcludedFeature[]>): string[] => {
  if (!feature || !featureType) return [];

  const alerts = Object.keys(featureType.alerts);
  const excludedFeature = excludedFeatures?.find(({ featureId }) => featureId === feature);
  if (!excludedFeature) return alerts;

  return alerts.filter((alert) => !excludedFeature.eventTypeIds.includes(alert));
};
