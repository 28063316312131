import DateRangeIcon from '@mui/icons-material/DateRange';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Container, Dialog, IconButton, InputAdornment, Popover, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeRangeViewProps } from './date-time-range.types';
import { DateTimeRangeDesktop } from './layout-desktop';
import { DateTimeRangeMobile } from './layout-mobile';

export const DateTimeRangeView: FC<DateTimeRangeViewProps> = ({
  anchorElement,
  buttonHandler,
  classes,
  closeMobileTime,
  date,
  dateOptions,
  disabled,
  end,
  error,
  formattedDate,
  handleBack,
  handleCalendar,
  handleClose,
  handleEnd,
  handleError,
  handleNext,
  handleOpen,
  handleStart,
  handleTab,
  hourCycle,
  is12AM,
  layout,
  maxDate,
  maxTime,
  minDate,
  minTime,
  mobileTabs,
  onlyIcon,
  open,
  openMobileTime,
  origin,
  pickerWidth,
  placeholderText,
  popoverWidth,
  popperPlacement,
  renderInput,
  required,
  saveDate,
  size,
  smallScreen,
  start,
  sx,
  tab,
  variant,
  views,
  ...props
}) => {
  const { t } = useTranslation(['common']);

  const DesktopMemo = DateTimeRangeDesktop({
    buttonHandler,
    classes,
    date,
    dateOptions,
    end,
    error,
    handleCalendar,
    handleEnd,
    handleError,
    handleStart,
    hourCycle,
    is12AM,
    layout,
    maxDate,
    maxTime,
    minDate,
    minTime,
    openMobileTime,
    popperPlacement,
    renderInput,
    saveDate,
    smallScreen,
    start,
    sx,
  });

  const MobileMemo = DateTimeRangeMobile({
    classes,
    closeMobileTime,
    end,
    error,
    handleBack,
    handleEnd,
    handleError,
    handleNext,
    handleStart,
    handleTab,
    is12AM,
    maxTime,
    minTime,
    renderInput,
    start,
    tab,
    views,
  });

  return (
    <Box data-testid={props['data-testid']} sx={{ ...sx, width: pickerWidth }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {smallScreen
          ? <IconButton
            aria-label={placeholderText ?? t('common:component.date-time-range.hint.placeholder')}
            onClick={handleOpen}
            sx={{ padding: '0px' }}
          >
            <DateRangeIcon />
            {!onlyIcon && <Typography variant='body2' sx={{ ml: 0.5 }}>{(error === null) ? formattedDate : placeholderText}</Typography>}
          </IconButton>
          : <TextField
            className={classes.desktopTimeTextField}
            disabled={disabled}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleOpen}>
                    <DateRangeIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={placeholderText ?? t('common:component.date-time-range.hint.placeholder')}
            onClick={handleOpen}
            onKeyDown={handleOpen}
            required={required}
            size={size}
            value={(error === null) ? formattedDate : placeholderText}
            variant={variant}
            data-testid="date-time-input-date-time-range-placeholder"
          />
        }
        {!smallScreen
          ? <Popover
            anchorEl={anchorElement}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: origin,
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{ style: { width: popoverWidth } }}
            transformOrigin={{
              vertical: 'top',
              horizontal: origin,
            }}
          >
            <Box>
              <Container className={classes.container}>
                {mobileTabs ? MobileMemo : DesktopMemo}
              </Container>
            </Box>
          </Popover>
          : <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: { margin: '0%', position: 'absolute', top: '3em' },
              className: `${classes.smallMobile}`,
            }}
            style={smallScreen ? { width: '100%', borderRadius: '0' } : {}}
          >
            <Box>
              <Container className={classes.containerMobile}>
                {mobileTabs ? MobileMemo : DesktopMemo}
              </Container>
            </Box>
          </Dialog>
        }
      </LocalizationProvider>
    </Box>
  );
};
