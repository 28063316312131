import { Box, Button, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import chroma from 'chroma-js';
import { FC, useEffect, useState } from 'react';

interface Props {
  actionLabel?: string;
  background?: string;
  'data-testid'?: string;
  label?: string;
  position?: 'static' | 'relative' | 'absolute';
  onAction?: () => void;
}

export const AppBanner: FC<Props> = ({
  actionLabel,
  background,
  label,
  onAction,
  position = 'relative',
  ...props
}) => {
  const theme = useTheme();
  const [positionStyles, setPositionStyles] = useState<SxProps>({ top: '0', left: '0' });

  useEffect(() => {
    setPositionStyles(position === 'relative' ? { top: '0', left: '0' } : {});
  }, [position]);

  if (!background) {
    background = theme.palette.primary.main;
  }

  const textColor = (chroma(background).luminance() < 0.5) ? theme.palette.common.white : theme.palette.common.black;

  return (
    <Box
      {...props}
      sx={{
        width: 1,
        backgroundColor: background,
        color: textColor,
        position,
        textAlign: 'center',
        py: actionLabel ? 0 : 1,
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '20',
        ...positionStyles,
      }}
    >
      <Typography component='span' data-testid='banner-label' variant='body2' sx={{ mr: 1 }}>
        {label}
      </Typography>
      {actionLabel && <Button data-testid='banner-action' variant='text' onClick={onAction} sx={{ textTransform: 'none', fontWeight: 'bold', color: 'inherit' }}>{actionLabel}</Button>}
    </Box>
  );
};
