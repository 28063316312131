import { Button, ButtonProps } from '@mui/material';
import cx from 'classnames';
import { FC } from 'react';

export const MenuButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      className={cx('MenuButton-root', props.className)}
      style={{ color: 'unset', textDecoration: 'unset', margin: 0, padding: 0, textTransform: 'unset', fontSize: 'unset' }}
    />
  );
};
