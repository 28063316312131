/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, MenuItem, TextField } from '@mui/material';
import { CountryCode } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { filterCountries, getDisplayNames } from '../../util/phone-input';
import { ISO_CODES } from './constants';
import FlagMenuItem from './flag-menu-item';
import { FlagsListProps } from './types';

const FlagsList = (props: FlagsListProps): JSX.Element => {
  const {
    isoCode,
    onSelectCountry,
    excludedCountries,
    onlyCountries,
    localization,
    preferredCountries,
    enableSearch,
    searchLabel,
  } = props;
  const [searchCountry, setSearchCountry] = useState<string>();
  const [matchedCountries, setMatchedCountries] = useState<CountryCode[]>();
  const [countriesFiltered] = useState(() => {
    return filterCountries(ISO_CODES, {
      onlyCountries,
      excludedCountries,
      preferredCountries,
    });
  });
  const [onSelectCountryMemo] = useState(() => { return onSelectCountry; });
  const [displayNames] = useState(() => { return getDisplayNames(localization); });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLLIElement>): void => {
    if (e.key !== 'Enter' && e.key !== 'ArrowUp' && e.key !== 'ArrowDown' && e.key !== 'Tab') {
      return e.stopPropagation();
    }
  };

  useEffect(() => {
    if (searchCountry) {
      setMatchedCountries(
        countriesFiltered.filter((query) => {
          const displayName = displayNames.of(query);
          return displayName?.toLowerCase().includes(searchCountry.toLowerCase());
        }),
      );
    }
  }, [searchCountry]);

  const SearchMenu = (): JSX.Element => {
    if (!enableSearch) return <></>;
    return <>
      <MenuItem
        role="option"
        className="PhoneInput-MenuItem"
        onKeyDown={onSearchKeyDown}
        sx={{
          backgroundColor: 'transparent',
          ':hover, :focus': { backgroundColor: 'transparent' },
        }}
      >
        <TextField
          id="PhoneInput-search"
          data-testid="phone-input-search"
          label={searchLabel}
          type="search"
          variant="standard"
          sx={{ width: '100%' }}
          autoFocus
          value={searchCountry}
          onChange={(search) => {
            search.preventDefault();
            return setSearchCountry(search.target.value);
          }}
        />
      </MenuItem>
      {matchedCountries &&
        matchedCountries.map((isoCodeItem) => {
          return (
            <FlagMenuItem
              onSelectCountry={onSelectCountryMemo}
              key={isoCodeItem}
              isoCode={isoCodeItem}
              displayNames={displayNames}
              selected={isoCodeItem === isoCode}
              id={`country-${isoCodeItem}`}
            />
          );
        })}
      <Divider />
    </>;
  };

  return (
    <>
      <SearchMenu />
      {countriesFiltered.map((isoCodeItem) => {
        return (
          <FlagMenuItem
            onSelectCountry={onSelectCountryMemo}
            key={isoCodeItem}
            isoCode={isoCodeItem}
            displayNames={displayNames}
            selected={isoCodeItem === isoCode}
            id={`country-${isoCodeItem}`}
          />
        );
      })}
    </>
  );
};

export default FlagsList;
