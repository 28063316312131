import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput';
import { TimePickerView } from '@mui/lab/TimePicker/shared';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, KeyboardEvent, MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useSmallScreen } from '../../hooks/use-small-screen';
import { Nullable, Undefinable } from '../../types';
import { formatDateTimeRange, formatShortDate, hourCycle, isMatchingDay, mergeDateAndTime } from '../../util';
import { modalStyles } from './date-time-range.styles';
import { AnchorElement, DateError, DateFilterField, DateState, DateTimeRangeProps, TABS, TimeInputType } from './date-time-range.types';
import { DateTimeRangeView } from './view';

const DEFAULT_MAX_DATETIME = DateTime.now().plus({ day: 1 }).startOf('day');
const DEFAULT_PICKER_WIDTH = '20em';
const DEFAULT_POPOVER_WIDTH = '23em';
const DEFAULT_POPOVER_WIDTH_HORIZONTAL = '32em';
const DEFAULT_PICKER_VIEWS: TimePickerView[] = ['hours', 'minutes'];

export const DateTimeRange: FC<DateTimeRangeProps> = ({
  dateOptions,
  defaultEnd,
  defaultLabel,
  defaultStart,
  disabled = false,
  handleClose: handleCloseProp,
  layout = 'vertical',
  maxDateTime = DEFAULT_MAX_DATETIME,
  minDateTime,
  onChange,
  onlyIcon = true,
  open: inputOpen = false,
  origin = 'right',
  pickerWidth = DEFAULT_PICKER_WIDTH,
  placeholderText,
  popoverWidth = DEFAULT_POPOVER_WIDTH,
  popperPlacement = 'bottom-start',
  required = false,
  size = 'medium',
  sx,
  variant = 'outlined',
  views = DEFAULT_PICKER_VIEWS,
  ...props
}) => {
  const minDate: Undefinable<DateTime> = minDateTime;
  const minTime: Undefinable<DateTime> = minDateTime ? minDateTime : DateTime.now().startOf('day');
  const maxDate: DateTime = maxDateTime;
  const maxTime: DateTime = maxDateTime;
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Undefinable<DateTime>>(defaultStart?.startOf('day'));
  const [start, setStart] = useState<Undefinable<DateTime>>(defaultStart ?? minTime);
  const [end, setEnd] = useState<Undefinable<DateTime>>(defaultEnd ?? maxTime);
  const [error, setError] = useState<DateError>(null);
  const [anchorElement, setAnchorElement] = useState<AnchorElement>(null);
  const [savedTime, setSavedTime] = useState<DateState>({
    startTime: defaultStart,
    endTime: defaultEnd,
    day: date,
  });
  const [mobileTabs, setMobileTabs] = useState(false);
  const [tab, setTab] = useState<string>(TABS.startDate);
  const smallScreen = useSmallScreen();
  const { classes } = modalStyles();

  const [optionSelected, setOptionSelected] = useState(true);
  const [formattedDate, setFormattedDate] = useState<string>(defaultLabel ?? '');

  useEffect(() => setOpen(inputOpen), [inputOpen]);

  useEffect(() => {
    if (!optionSelected || !defaultLabel) {
      setFormattedDate(savedTime.startTime
        ? formatDateTimeRange(
          savedTime.startTime,
          savedTime.endTime ? savedTime.endTime : mergeDateAndTime(DateTime.now(), maxTime),
        )
        : '',
      );
    }
  }, [defaultLabel, maxTime, optionSelected, savedTime]);

  const is12AM = (time: DateTime): boolean => mergeDateAndTime(DateTime.now(), time).equals(DateTime.now().startOf('day'));

  const isSameOrLessTime = (startCompare: DateTime, endCompare: DateTime): boolean => {
    const timeDiff = startCompare.set({ second: 0, millisecond: 0 }).diff(endCompare.set({ second: 0, millisecond: 0, day: startCompare.day })).toObject();
    return (timeDiff.milliseconds as number) >= 0;
  };

  const updateSaved = (
    starting: Undefinable<DateTime> = minTime,
    ending: Undefinable<DateTime> = maxTime,
    dateDay: Undefinable<DateTime> = date,
  ): void => {
    const startToDate = mergeDateAndTime(dateDay, starting);
    const endToDate = !isMatchingDay(starting, ending)
      ? ending
      : mergeDateAndTime(dateDay, ending);

    onChange(startToDate.toJSDate(), endToDate.toJSDate());
    setSavedTime({
      startTime: starting,
      endTime: ending,
      day: dateDay,
    });
  };

  const buttonHandler = (id: string): void => {
    setOpen(false);
    setOptionSelected(true);
    const filtered = dateOptions.find((options: DateFilterField) => options.id === id);
    if (!filtered) return;

    const filteredStartTime = filtered.data.startTime(DateTime.now());
    const filteredEndTime = filtered.data.endTime(DateTime.now());

    setStart(filteredStartTime);
    setEnd(filteredEndTime);
    setDate(filteredStartTime);
    updateSaved(filteredStartTime, filteredEndTime, filteredStartTime);
    setFormattedDate(filtered.display);
  };

  const saveDate = (): void => {
    setOpen(false);
    setOptionSelected(false);
    updateSaved(start, end, date);
  };

  const handleOpen = (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>): void => {
    setAnchorElement(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (): void => {
    handleCloseProp?.();
    setOpen(false);
    setStart(savedTime.startTime);
    setEnd(savedTime.endTime);
    setDate(savedTime.day);
    setMobileTabs(false);
  };

  const updateChanges = (newDate: Nullable<Date>, goToNext = true): void => {
    if (!newDate || !start || !end) return;
    setError(null);

    if (isNaN(start.valueOf()) || isNaN(end.valueOf())) return setError('invalidDate');
    const luxonDate = DateTime.fromJSDate(newDate);
    const savedStart = start ?? minTime;
    const savedEnd = end ?? maxTime;
    const lastField = views[views.length - 1].slice(0, -1) as keyof DateTime;

    if (goToNext && luxonDate.get(lastField) !== savedStart.get(lastField) && tab === TABS.startDate) handleNext();
    if (goToNext && luxonDate.get(lastField) !== savedEnd.get(lastField) && tab === TABS.endDate) handleNext();
  };

  const handleDateStates = (newDate: Nullable<Date>, state: TimeInputType.start | TimeInputType.end, keyboardInput?: string): void => {
    const newDateLuxon = newDate ? DateTime.fromJSDate(newDate).set({ second: 0, millisecond: 0 }) : undefined;
    if (!newDateLuxon || isNaN(newDateLuxon.valueOf())) return setError('invalidDate');
    if (state === TimeInputType.start) {
      if (end && isSameOrLessTime(newDateLuxon, end)) {
        setStart(newDateLuxon);
        setEnd(end?.set({ day: newDateLuxon.day + 1 }));
      }
      else {
        setStart(newDateLuxon);
        setEnd(end?.set({ day: newDateLuxon.day }));
      }
    }
    if (state === TimeInputType.end) {
      if (start && isSameOrLessTime(start, newDateLuxon)) {
        setEnd(newDateLuxon.set({ day: start?.day + 1 }));
      }
      else {
        setEnd(newDateLuxon.set({ day: start?.day }));
      }
    }
    updateChanges(newDate, keyboardInput === undefined);
  };

  const handleStart = (newDate: Nullable<Date>, keyboardInput?: string): void => {
    return handleDateStates(newDate, TimeInputType.start, keyboardInput);
  };

  const handleEnd = (newDate: Nullable<Date>, keyboardInput?: string): void => {
    return handleDateStates(newDate, TimeInputType.end, keyboardInput);
  };

  const handleError = (err: DateError): void => setError(err);

  const renderInput = (params: MuiTextFieldProps, inputType: string): React.ReactElement => {
    const newDayText = (start && end && inputType === TimeInputType.end && !isMatchingDay(start, end))
      ? `(${formatShortDate(end)})`
      : '';

    return (
      <TextField
        {...params}
        className={classes.desktopTimeTextField}
        helperText={newDayText}
      />
    );
  };

  const handleCalendar = (newDate: Nullable<Date>): void => {
    if (!newDate || !start || !end) return;
    if (isNaN(start.valueOf()) || isNaN(end?.valueOf())) return;
    const newDateLuxon = newDate ? DateTime.fromJSDate(newDate) : undefined;
    const dummyStart = mergeDateAndTime(newDateLuxon, start);
    const dummyEnd = mergeDateAndTime(!isMatchingDay(start, end) ? newDateLuxon?.plus({ day: 1 }) : newDateLuxon, end);
    setStart(dummyStart);
    setEnd(dummyEnd);
    setDate(newDateLuxon);
  };

  const handleTab = (_: SyntheticEvent, newTab: string): void => setTab(newTab);

  const openMobileTime = (tabValue: string): void => {
    setTab(tabValue);
    setMobileTabs(true);
    setSavedTime({ startTime: start, endTime: end });
  };

  const closeMobileTime = (): void => {
    setMobileTabs(false);
    setStart(savedTime.startTime ?? minTime);
    setEnd(savedTime.endTime ?? maxTime);
    setTab(TABS.startDate);
  };

  const handleNext = (): void => tab === TABS.startDate ? setTab(TABS.endDate) : setMobileTabs(false);

  const handleBack = (): void => setTab(TABS.startDate);

  useEffect(() => {
    setSavedTime({
      startTime: defaultStart,
      endTime: defaultEnd,
      day: defaultStart?.startOf('day'),
    });
    setStart(defaultStart);
    setEnd(defaultEnd);
    setDate(defaultStart?.startOf('day'));
  }, [defaultStart, defaultEnd]);

  return DateTimeRangeView({
    anchorElement,
    buttonHandler,
    classes,
    closeMobileTime,
    date,
    dateOptions,
    disabled,
    end,
    error,
    formattedDate,
    handleBack,
    handleCalendar,
    handleClose,
    handleEnd,
    handleError,
    handleNext,
    handleOpen,
    handleStart,
    handleTab,
    hourCycle,
    is12AM,
    layout,
    maxDate,
    maxTime,
    minDate,
    minTime,
    mobileTabs,
    onlyIcon,
    open,
    openMobileTime,
    origin,
    pickerWidth,
    placeholderText,
    popoverWidth: layout === 'horizontal' ? DEFAULT_POPOVER_WIDTH_HORIZONTAL : popoverWidth,
    popperPlacement,
    renderInput,
    required,
    saveDate,
    size,
    smallScreen,
    start,
    sx,
    tab,
    variant,
    views,
    ...props,
  });
};
