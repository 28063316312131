import { FC, useMemo, useState } from 'react';
import { FILTER_SELECT_ALL_FLAG } from '../../util';
import { EntityField, SelectedFilterType } from '../entity-search/types';
import { useBoolFlag } from '../flags';
import { GroupsEditor } from '../groups-editor/groups-editor';

interface Props {
  isApplied: (value: EntityField) => boolean;
  label?: string;
  onChanged: (value: SelectedFilterType) => void;
  values?: EntityField[];
}

export const FilterInputAutocompleteMultiple: FC<Props> = ({ isApplied, label, onChanged, values = [] }) => {
  const [options] = useState<string[]>(values.map(({ display }) => display));
  const hasFilterSelectAllFlag = useBoolFlag(FILTER_SELECT_ALL_FLAG);

  const defaultValues = useMemo((): string[] => {
    const filter = values.filter(isApplied);
    return filter.map(({ display }) => display);
  }, [isApplied, values]);

  const handleChange = async (_: string, updatedChips?: string[]): Promise<void> => {
    if (!updatedChips) return;
    const filter = values.filter(({ display }) => updatedChips.find((chip) => display === chip));
    onChanged(filter);
    await Promise.resolve();
  };

  const handleSelectAll = async (value: string[]): Promise<void> => {
    const filter = values.filter(({ display }) => value.find((chip) => display === chip));
    onChanged(filter);
    await Promise.resolve();
  };

  return (
    <GroupsEditor
      defaultValues={defaultValues}
      onChange={async () => { }}
      onSelectAll={hasFilterSelectAllFlag ? handleSelectAll : undefined}
      optionOnClick={handleChange}
      optionOnDelete={handleChange}
      options={options}
      label={label}
      size="small"
    />
  );
};
