import { Box, Collapse, duration, Stack } from '@mui/material';
import { FC } from 'react';
import { DrawerSize } from '../event-history.types';
import { DrawerHeader } from './drawer-header';

interface Props {
  bottomDrawerContent: JSX.Element;
  bottomDrawerExited: boolean;
  bottomDrawerHeaderIcon?: JSX.Element;
  bottomDrawerHeaderTitle: JSX.Element;
  bottomDrawerHeight: DrawerSize;
  fullScreenIcon?: JSX.Element;
  handleBottomDrawerEnter: () => void;
  handleBottomDrawerExit: () => void;
  setBottomDrawerHeight: (height: DrawerSize) => void;
  topDrawerContent: JSX.Element;
  topDrawerHeaderIcon?: JSX.Element;
  topDrawerHeaderTitle: JSX.Element;
}

export const TwoWayCollapseLayoutDesktop: FC<Props> = ({
  bottomDrawerContent,
  bottomDrawerExited,
  bottomDrawerHeaderIcon,
  bottomDrawerHeaderTitle,
  bottomDrawerHeight,
  fullScreenIcon,
  handleBottomDrawerEnter,
  handleBottomDrawerExit,
  setBottomDrawerHeight,
  topDrawerContent,
  topDrawerHeaderIcon,
  topDrawerHeaderTitle,
}): JSX.Element => {
  return (
    <Stack sx={{ height: 'inherit', flexGrow: 1 }}>
      <Stack className="map-resize-listener" sx={{ flexGrow: 1 }}>
        <DrawerHeader
          fullScreenDisabled={bottomDrawerHeight === DrawerSize.CLOSED}
          fullScreenIcon={fullScreenIcon}
          handleClose={() => setBottomDrawerHeight(DrawerSize.FULL_SCREEN)}
          handleExpand={() => setBottomDrawerHeight(window.innerHeight / 2)}
          handleFullScreen={() => setBottomDrawerHeight(DrawerSize.CLOSED)}
          renderRemoveIcon={bottomDrawerHeight !== DrawerSize.FULL_SCREEN}
          title={topDrawerHeaderTitle}
          titleIcon={topDrawerHeaderIcon}
        />
        <Box
          sx={{
            '&': {
              transition: `height ${duration.standard}ms linear`,
            },
            height: bottomDrawerHeight !== DrawerSize.FULL_SCREEN ? '100% !important' : 0,
          }}
        >
          {topDrawerContent}
        </Box>
      </Stack>
      <Collapse
        collapsedSize={DrawerSize.CLOSED}
        in={bottomDrawerHeight !== DrawerSize.CLOSED}
        onEnter={handleBottomDrawerEnter}
        onExiting={handleBottomDrawerExit}
        orientation="vertical"
        sx={{
          display: 'none',
          height: bottomDrawerExited ? DrawerSize.CLOSED : `${bottomDrawerHeight}px !important`,
          overflowY: bottomDrawerExited ? 'visible' : 'auto',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <DrawerHeader
          fullScreenDisabled={bottomDrawerHeight === DrawerSize.FULL_SCREEN}
          fullScreenIcon={fullScreenIcon}
          handleClose={() => setBottomDrawerHeight(DrawerSize.CLOSED)}
          handleExpand={() => setBottomDrawerHeight(window.innerHeight / 2)}
          handleFullScreen={() => setBottomDrawerHeight(DrawerSize.FULL_SCREEN)}
          isTopDrawer={false}
          renderRemoveIcon={bottomDrawerHeight !== DrawerSize.CLOSED}
          title={bottomDrawerHeaderTitle}
          titleIcon={bottomDrawerHeaderIcon}
        />
        <Stack sx={{ px: 3, py: bottomDrawerHeight === DrawerSize.CLOSED ? 0 : 3 }}>
          {!bottomDrawerExited && bottomDrawerContent}
        </Stack>
      </Collapse>
    </Stack>
  );
};
