import { Geofence, Thing } from '@eagle/core-data-types';
import { from, Observable, of } from 'rxjs';
import { catchError, finalize, startWith, tap } from 'rxjs/operators';
import { FindItemsResult } from '../../pages/list/types';
import { Undefinable } from '../../types';
import { MapDiscoverItem } from './search-map.types';

export const getThingObservable = (
  data: { promise: Promise<FindItemsResult<Thing>>; cancel: () => unknown },
  onError: (error: Error) => void,
): Observable<Undefinable<FindItemsResult<Thing>>> => {
  let thingComplete = false;
  return from(data.promise).pipe(
    startWith(undefined),
    tap(() => {
      thingComplete = true;
    }),
    catchError((err: Error) => {
      onError(err);
      return of(undefined);
    }),
    finalize(() => {
      return thingComplete || data.cancel();
    }),
  );
};

export const getGeofenceObservable = (
  data: { promise: Promise<FindItemsResult<Geofence>>; cancel: () => unknown },
  onError: (error: Error) => void,
): Observable<Undefinable<FindItemsResult<Geofence>>> => {
  let geofenceComplete = false;
  return from(data.promise).pipe(
    startWith(undefined),
    tap(() => {
      geofenceComplete = true;
    }),
    catchError((err: Error) => {
      onError(err);
      return of(undefined);
    }),
    finalize(() => {
      return geofenceComplete || data.cancel();
    }),
  );
};

export const getAddressObservable = (
  data: { promise: Promise<FindItemsResult<MapDiscoverItem>>; cancel: () => unknown },
  onError: (error: Error) => void,
): Observable<Undefinable<FindItemsResult<MapDiscoverItem>>> => {
  let addressComplete = false;
  return from(data.promise).pipe(
    startWith(undefined),
    tap(() => {
      addressComplete = true;
    }),
    catchError((err: Error) => {
      onError(err);
      return of(undefined);
    }),
    finalize(() => {
      return addressComplete || data.cancel();
    }),
  );
};
