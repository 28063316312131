/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ulid } from 'ulid';
import { isAppliedFilterArrayEqual } from '../../util/filter';
import { useLocalStorage } from '../../util/local-storage';
import { AppliedFilter, EntityField, SavedFilterOptions } from '../entity-search/types';
import { SavedFilterElement } from './saved-filter-element';
import { useSavedFilterStyles } from './styles';

interface Props {
  filterOptions: SavedFilterOptions;
  filters: AppliedFilter[];
  onFiltersChanged: (filters: AppliedFilter[]) => unknown;
  savedFilterKey?: string;
  storageKey: string;
}

export interface SavedFilter {
  created: number;
  filters: AppliedFilter[];
  id: string;
  name: string;
}

interface FilterStore {
  add(filters: AppliedFilter[]): void;
  all: SavedFilter[];
  removeById(id: string): unknown;
  renameById(id: string, name: string): boolean;
}

interface ViewProps {
  filterOptions: SavedFilterOptions;
  filters: AppliedFilter[];
  hasFilterChanged: boolean;
  onFiltersChanged: (filters: AppliedFilter[]) => unknown;
  store: FilterStore;
}

export const SAVED_FILTER_KEY = 'saved-filters' as const;

export const SavedFilterView: FC<ViewProps> = ({ filterOptions, filters, hasFilterChanged, onFiltersChanged, store }) => {
  const classes = useSavedFilterStyles();

  return (
    <Stack spacing={2}>
      <Typography variant="body1">{filterOptions.caption}</Typography>

      <Button
        variant="contained"
        color="primary"
        className={classes.saveFilterButton}
        onClick={() => store.add(filters)}
        disabled={!hasFilterChanged}
        data-testid="save-filter-button"
      >
        {filterOptions.saveButtonText}
      </Button>

      <Stack spacing={1}>
        {store.all.map((item) => (
          <SavedFilterElement
            key={item.id}
            id={item.id}
            name={item.name}
            onLoadFilter={() => onFiltersChanged(item.filters)}
            onRemoveFilter={() => store.removeById(item.id)}
            onRenameFilter={(newName: string) => store.renameById(item.id, newName)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export const SavedFilterController: FC<Props> = ({ filterOptions, filters, onFiltersChanged, savedFilterKey = SAVED_FILTER_KEY, storageKey }) => {
  const { t } = useTranslation(['common']);
  const [savedFiltersInLocalStorage, setSavedFiltersInLocalStorage] = useLocalStorage<Record<string, SavedFilter[]>>(savedFilterKey, { [storageKey]: [] });
  const [savedFilter, setSavedFilter] = useState<SavedFilter[]>(savedFiltersInLocalStorage[storageKey] ?? []);
  const { enqueueSnackbar } = useSnackbar();

  const [hasFilterChanged, setHasFilterChanged] = useState(false);

  const sortedFilters = useMemo(
    () => savedFilter ? savedFilter.sort((a, b) => b.created - a.created) : [],
    [savedFilter],
  );

  const saveFilter = (filtersToSave: AppliedFilter[]): void => {
    setHasFilterChanged(false);

    if (isCurrentFilterAlreadySaved()) {
      enqueueSnackbar(t('common:component.filter.hint.filter-already-saved'), { variant: 'warning' });
      return;
    }

    setSavedFilter([
      ...savedFilter,
      {
        id: ulid(),
        name: stringifyFilter(),
        filters: filtersToSave,
        created: Date.now(),
      },
    ]);
  };

  const removeById = (id: string): void => {
    setSavedFilter(savedFilter.filter((item) => item.id !== id));
  };

  const renameById = (id: string, name: string): boolean => {
    const duplicate = savedFilter.some((item) => item.id !== id && item.name === name);
    if (duplicate) {
      enqueueSnackbar(t('common:component.filter.hint.duplicate-name'), { variant: 'warning' });
      return false;
    }
    setSavedFilter(savedFilter.map((item) => item.id !== id ? item : { ...item, name }));
    return true;
  };

  const stringifyFilter = (): string => {
    const resultGroupedByFilterName = filters.reduce<Record<string, string[]>>((acc, filter) => {
      if (!acc[filter.definition.label]) {
        acc[filter.definition.label] = [];
      }
      const filterValue =
        filter.definition.type === 'entity'
          ? (filter.value as EntityField).display
          : (filter.value as string);
      acc[filter.definition.label].push(filterValue);
      return acc;
    }, {});

    const result = Object.entries(resultGroupedByFilterName)
      .map(([filterName, filterValues]) => {
        return t('common:component.filter.labels.is', {
          field: filterName,
          value: filterValues.join(t('common:component.filter.labels.or')),
        });
      })
      .join(t('common:component.filter.labels.and'));
    return result;
  };

  const isCurrentFilterAlreadySaved = (): boolean => {
    return savedFilter.some((savedFilterElements) => {
      return isAppliedFilterArrayEqual(savedFilterElements.filters, filters);
    });
  };

  useEffect(() => {
    if (!filters.length) return setHasFilterChanged(false);
    setHasFilterChanged(true);
  }, [filters]);

  useEffect(() => {
    if (!savedFilter.length && filters.length > 0) {
      setHasFilterChanged(true);
    }
    setSavedFiltersInLocalStorage({ ...savedFiltersInLocalStorage, [storageKey]: savedFilter });
  }, [savedFilter]);

  return (
    <SavedFilterView
      filterOptions={filterOptions}
      filters={filters}
      hasFilterChanged={hasFilterChanged}
      onFiltersChanged={onFiltersChanged}
      store={{
        add: saveFilter,
        all: sortedFilters,
        removeById,
        renameById,
      }}
    />
  );
};
