import { makeStyles } from '../../theme';

export const useSearchStyles = makeStyles()(() => ({
  outlinedInput: {
    '&.MuiOutlinedInput-notchedOutline': {
      top: 0,
    },
    '& legend': {
      display: 'none',
    },
  },
}));
