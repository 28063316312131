import { SxProps } from '@mui/system';

export const portalIconStyle: SxProps = {
  width: '72px',
  height: '42px',
};

export const menuItemStyle: SxProps = {
  m: 0,
  'svg': {
    width: '72px',
  },
};
