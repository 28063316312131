import { Stack, SwipeableDrawer, Toolbar, useTheme } from '@mui/material';
import { FC, PropsWithChildren, RefObject } from 'react';
import { Nullable } from '../../types';
import { AppBar } from '../app-bar';
import { FlexBox } from '../flex-box';

interface Props extends PropsWithChildren {
  drawerContent: JSX.Element;
  drawerOpen: boolean;
  drawerRef: Nullable<RefObject<HTMLDivElement>>;
  drawerWidth?: number;
  onDrawerClose: () => void;
  onDrawerOpen: () => void;
  toolbar: JSX.Element;
}

export const AppMobileLayout: FC<Props> = ({ children, drawerContent, drawerOpen, drawerRef, drawerWidth = '100%', onDrawerClose, onDrawerOpen, toolbar }) => {
  const theme = useTheme();

  return (
    <Stack className="App-root mobile" data-testid="app-page" direction="column" sx={{ flexGrow: 1, width: '100%' }}>
      <AppBar position="static" sx={{ zIndex: theme.zIndex.drawer }}>{toolbar}</AppBar>

      <Stack direction="row" sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <SwipeableDrawer
          data-testid="app-drawer"
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            ref: drawerRef,
            sx: {
              display: 'flex',
              width: drawerWidth,
              backgroundColor: theme.sidebar.background,
              color: theme.sidebar.contrastText,
            },
          }}
          onClick={onDrawerClose}
          onClose={onDrawerClose}
          onOpen={onDrawerOpen}
          open={drawerOpen}
          sx={{ display: 'flex' }}
        >
          <Toolbar />
          {drawerContent}
        </SwipeableDrawer>
        <FlexBox data-testid="app-body-wrapper" sx={{ minWidth: 0, flexDirection: 'column' }} >
          {children}
        </FlexBox>
      </Stack>
    </Stack >
  );
};
