/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDomainTheme } from '../components';
import { Nullable } from '../types';

interface DynamicModuleResponse<T> {
  module: T | undefined;
  loaded: boolean;
  error: Nullable<string>;
}

export const useDynamicModule = <T>(name: string, defaults: T): DynamicModuleResponse<T> => {
  const [module, setModule] = useState<T>();
  const [error, setError] = useState<string | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { domainTheme } = useDomainTheme();

  useEffect(() => {
    const moduleURL = `${new URL(import.meta.url).origin}/js/module/${name}?domain=${domainTheme}`;

    import(/* @vite-ignore */ moduleURL)
      .then((moduleResult: T) => {
        if (typeof moduleResult === 'object') {
          setModule({ ...defaults, ...moduleResult });
          setLoaded(true);
        } else {
          setModule({ ...defaults });
          setError('Imported module is not an object');
          setLoaded(true);
        }
      })
      .catch((errorResult: string) => {
        setModule({ ...defaults });
        setError(errorResult);
        setLoaded(true);
      });
  }, [defaults, domainTheme]);

  return { module, error, loaded };
};
