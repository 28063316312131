/* eslint-disable react-hooks/exhaustive-deps */
import { TrackingEventTypes } from '@eagle/data-function-types';
import { useTheme } from '@mui/material';
import L from 'leaflet';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Marker, useMap } from 'react-leaflet';
import { MapPanes } from '../../../../util/maps';
import { useHistorySearch } from '../../../entity-journey';
import { ClusterTooltip } from './cluster-tooltip';
import { ClusterProps } from './clustering.types';
import { createBoundingMarker, createClusterMarker, getClusterMarkerSize, getMarkerSize, getNotableEventColor, getNotableEventsInformation } from './clustering.utils';
import { FlowerClusterPoint } from './flower-cluster-point';

export const FLOWER_CLOSED_OFFSET = 0.4;
export const FLOWER_OPEN_OFFSET = 1.5;
export const BOUNDING_RADIUS = 30.0;

export const FlowerCluster: FC<ClusterProps> = ({
  calculatedHeading,
  center,
  events,
  eventsLength,
  hoveredEventId,
  onClick,
  setHoveredEventId,
  setSelectedEvent,
}: ClusterProps): JSX.Element => {
  const [hover, setHover] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const markerRef = useRef<L.Marker>(null);
  const map = useMap();
  const boundingMarker = createBoundingMarker(BOUNDING_RADIUS);
  const theme = useTheme();
  const {
    containsFirstEvent,
    containsLastEvent,
    notableEventData = [],
  } = useMemo(() => getNotableEventsInformation(events, eventsLength, theme), [events, eventsLength, theme]);

  const updatedNotableEventData = useMemo(() => notableEventData.sort((item1, item2) => item1.priority - item2.priority), [notableEventData]);
  const { clickedEventId, setClickedEventId } = useHistorySearch();

  const clickedEvent = notableEventData.find(({ eventData }) => eventData.eventId === clickedEventId)?.eventData;
  const locationUpdatesCheck = notableEventData.every(({ eventData }) => hoveredEventId !== eventData.eventId
    && clickedEventId !== eventData.eventId
    && eventData.eventTypeId === TrackingEventTypes.LOCATION_UPDATE,
  );
  const firstEvent = updatedNotableEventData[0];
  const firstEventData = firstEvent?.eventData;
  const iconColor = events.find(({ eventData }) => firstEventData?.eventId === eventData.eventId)?.iconColor ?? events[0].iconColor;
  const color = useMemo(() => firstEventData && getNotableEventColor(firstEventData, theme), [events]);
  const opacityCheck = !locationUpdatesCheck || containsFirstEvent || containsLastEvent || hover;
  const notableEventDataNoLocation = updatedNotableEventData.filter((item) => !(item.eventData.eventTypeId === TrackingEventTypes.LOCATION_UPDATE));

  const eventHandlers = {
    mouseover: () => {
      setHoveredEventId?.(notableEventData[0].eventData.eventId);
      setHover(true);
    },
    mouseout: () => {
      setHoveredEventId?.(null);
      setHover(false);
    },
    click: (e: L.LeafletMouseEvent) => {
      if (locationUpdatesCheck) return;
      e.originalEvent.stopPropagation();
      notableEventData.length > 1 && setExpanded?.(!expanded);
      if (expanded || notableEventData.length === 1) {
        setSelectedEvent?.(firstEventData);
        setClickedEventId(firstEventData?.eventId);
        onClick?.(0, updatedNotableEventData.map((item) => item.eventData));
      }
    },
  };

  const marker = L.marker(center, { icon: boundingMarker });
  const markerLatLng = marker.getLatLng();
  const isFirstMarkerInView = map.getBounds().contains(markerLatLng);

  useEffect(() => {
    const reset = (): void => {
      setExpanded?.(false);
      setClickedEventId(null);
      markerRef.current?.closeTooltip();
    };

    map.on('zoomend click moveend', reset);
    return () => {
      map.off('zoomend click moveend', reset);
    };
  }, []);

  useEffect(() => {
    if (clickedEvent && notableEventData.length > 1) setExpanded?.(true);
  }, [clickedEvent, notableEventData]);

  useEffect(() => {
    if (firstEventData?.eventId === hoveredEventId) {
      markerRef.current?.openTooltip();
      return;
    }
    markerRef.current?.closeTooltip();
  }, [firstEventData, hoveredEventId, markerRef.current]);

  return <>
    {expanded || !isFirstMarkerInView
      ? null
      : <Marker
        eventHandlers={eventHandlers}
        icon={boundingMarker}
        pane={(containsFirstEvent || containsLastEvent) ? MapPanes.START_END_MARKERS : undefined}
        position={center}
        zIndexOffset={1000}
      />
    }
    {updatedNotableEventData
      .slice(1)
      .filter((item) => !(item.eventData.eventTypeId === TrackingEventTypes.LOCATION_UPDATE && !expanded))
      .map((event, index) =>
        <FlowerClusterPoint
          key={index}
          index={index}
          calculatedHeading={calculatedHeading}
          center={center}
          event={event.eventData}
          eventsLength={!expanded ? notableEventDataNoLocation.length : notableEventData.length}
          expanded={expanded}
          firstPoint={event.index === 0}
          hoveredEventId={hoveredEventId}
          iconColor={iconColor}
          lastPoint={event.index === (eventsLength - 1)}
          markerSize={getMarkerSize(event.eventData.eventId, hoveredEventId, expanded)}
          onClick={() => onClick?.(index + 1, updatedNotableEventData.map((item) => item.eventData))}
          opacity={opacityCheck || expanded}
          setHoveredEventId={setHoveredEventId}
          setSelectedEvent={setSelectedEvent}
        />,
      )
    }
    <Marker
      ref={markerRef}
      eventHandlers={eventHandlers}
      icon={
        createClusterMarker(
          color,
          getClusterMarkerSize(updatedNotableEventData.map((item) => item.eventData.eventId), hoveredEventId, expanded),
          (containsFirstEvent && !expanded) || firstEvent?.index === 0,
          (containsLastEvent && !expanded || firstEvent?.index === (eventsLength - 1)),
          iconColor,
          firstEvent?.eventData.eventTypeId === TrackingEventTypes.LOCATION_UPDATE,
          theme,
          firstEventData?.data.velocity?.heading ?? calculatedHeading,
        )
      }
      opacity={(opacityCheck || expanded) ? 1 : 0}
      pane={(containsFirstEvent || containsLastEvent) ? MapPanes.START_END_MARKERS : undefined}
      position={center}
      zIndexOffset={locationUpdatesCheck ? -1 : 290}
    >
      <ClusterTooltip
        direction="bottom"
        expanded={expanded}
        notableData={notableEventData.map((item) => item.eventData)}
      />
    </Marker>
  </>;
};

export default React.memo(FlowerCluster);
