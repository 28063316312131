import { TabContext, TabPanel } from '@mui/lab';
import { Box, SxProps } from '@mui/material';
import { FC, SyntheticEvent, useState } from 'react';
import { Tabs } from '../tabs';

interface Props {
  'data-testid'?: string;
  content: JSX.Element[];
  tabs: JSX.Element[];
  sx?: SxProps;
}

export const TabsGroup: FC<Props> = ({ content, tabs, sx, ...props }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (_: SyntheticEvent, newValue: number): void => {
    setSelectedTab(newValue);
  };

  return <Box data-testid={props['data-testid']} sx={sx}><TabContext value={String(selectedTab)}>
    <Tabs
      variant='fullWidth'
      onChange={handleChange}
      value={selectedTab}
    >
      {tabs}
    </Tabs>
    <>
      {content.map((node, i) => <TabPanel sx={{ p: 0 }} value={String(i)} key={i}>{node}</TabPanel>)}
    </>

  </TabContext >
  </Box>;
};
