/* eslint-disable react-hooks/exhaustive-deps */
import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useSidebarPinContext, useSmallScreen } from '../../hooks';
import { AppView } from './view';

interface Props extends PropsWithChildren {
  renderDrawerContent: (open: boolean) => JSX.Element;
  siteTitle: string;
}

export const AppController: FC<Props> = ({ children, renderDrawerContent, siteTitle }) => {
  const smallScreen = useSmallScreen();
  const drawerRef = useRef<HTMLDivElement>(null);
  const { isPinned } = useSidebarPinContext();
  const [drawerFocused, setDrawerFocused] = useState(false);
  const open = smallScreen ? drawerFocused : (drawerFocused || isPinned);

  useEffect(() => {
    setDrawerFocused(false);
  }, [useSmallScreen()]);

  const keyDownHandler = useCallback((event: KeyboardEvent): void => {
    switch (event.key) {
      case 'N':
        if (event.shiftKey && event.altKey) {
          setDrawerFocused(!drawerFocused);

          if (drawerRef.current) {
            drawerRef.current.querySelector<HTMLElement>('a, button, [role="button"]')?.focus();
          }
        }
        break;
    }
  }, [drawerFocused, drawerRef]);

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);

  useEffect(() => {
    document.body.classList.toggle('small-screen', smallScreen);
  }, [smallScreen]);

  return (
    <AppView
      drawerContent={renderDrawerContent(open)}
      drawerOpen={open}
      drawerRef={drawerRef}
      onDrawerClose={() => { setDrawerFocused(false); }}
      onDrawerOpen={() => setDrawerFocused(true)}
      siteTitle={siteTitle}
    >
      {children}
    </AppView>
  );
};
