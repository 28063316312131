import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export const SunIcon: FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} sx={{ fill: 'none' }} >
    <path d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 1V3" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 21V23" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.21997 4.21997L5.63997 5.63997" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.36 18.36L19.78 19.78" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 12H3" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21 12H23" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.21997 19.78L5.63997 18.36" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.36 5.63997L19.78 4.21997" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>;
};

export const CloudIcon: FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} sx={{ fill: 'none' }} >
    <path d="M18 10H16.74C16.3659 8.551 15.5928 7.23599 14.5086 6.2044C13.4245 5.1728 12.0727 4.46599 10.6069 4.16428C9.14112 3.86256 7.62008 3.97804 6.21664 4.49759C4.8132 5.01714 3.58363 5.91993 2.66764 7.10338C1.75165 8.28683 1.18598 9.70348 1.0349 11.1924C0.883813 12.6812 1.15338 14.1826 1.81296 15.526C2.47255 16.8693 3.4957 18.0007 4.76617 18.7916C6.03663 19.5824 7.50347 20.0011 8.99999 20H18C19.3261 20 20.5978 19.4732 21.5355 18.5355C22.4732 17.5979 23 16.3261 23 15C23 13.6739 22.4732 12.4021 21.5355 11.4645C20.5978 10.5268 19.3261 10 18 10Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>;
};

export const HazeIcon: FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} sx={{ fill: 'none' }} >
    <path d="M1 6.68293C1 6.68293 5.0203 8.46973 12 6.68293C18.9797 4.89613 23 6.68293 23 6.68293" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1 11.353C1 11.353 5.0203 13.1398 12 11.353C18.9797 9.56617 23 11.353 23 11.353" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1 16.3581C1 16.3581 5.0203 18.1449 12 16.3581C18.9797 14.5713 23 16.3581 23 16.3581" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" />
  </SvgIcon>;
};

export const NightIcon: FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} sx={{ fill: 'none' }} >
    <path d="M21 12.79C20.8427 14.4922 20.2039 16.1144 19.1583 17.4668C18.1127 18.8192 16.7035 19.8458 15.0957 20.4265C13.4879 21.0073 11.748 21.1181 10.0795 20.7461C8.41104 20.3741 6.88302 19.5345 5.67425 18.3258C4.46548 17.117 3.62596 15.589 3.25393 13.9205C2.8819 12.252 2.99274 10.5121 3.57348 8.9043C4.15423 7.29651 5.18085 5.88737 6.53324 4.84175C7.88562 3.79614 9.50782 3.15731 11.21 3C10.2134 4.34827 9.73387 6.00945 9.85856 7.68141C9.98324 9.35338 10.7039 10.9251 11.8894 12.1106C13.075 13.2961 14.6466 14.0168 16.3186 14.1415C17.9906 14.2662 19.6518 13.7866 21 12.79Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>;
};

export const DrizzleIcon: FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} sx={{ fill: 'none' }} >
    <path d="M8 19V21" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 13V15" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 19V21" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 13V15" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 21V23" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 15V17" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 16.58C21.0512 16.1196 21.9121 15.3116 22.4381 14.2916C22.964 13.2715 23.1231 12.1016 22.8886 10.9782C22.654 9.85478 22.0402 8.84623 21.15 8.12185C20.2599 7.39748 19.1476 7.00137 18 7.00002H16.74C16.423 5.77254 15.8188 4.63797 14.9773 3.68982C14.1357 2.74167 13.0809 2.00709 11.8997 1.54664C10.7186 1.08619 9.44488 0.913035 8.18365 1.04146C6.92243 1.16989 5.70978 1.59622 4.64567 2.2853C3.58155 2.97439 2.69644 3.9065 2.06328 5.00482C1.43012 6.10313 1.06704 7.33619 1.00398 8.60237C0.940923 9.86855 1.17968 11.1316 1.70058 12.2874C2.22147 13.4432 3.00959 14.4586 3.99997 15.25" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>;
};

export const RainIcon: FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} sx={{ fill: 'none' }} >
    <path d="M16 13V21" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 13V21" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 15V23" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 16.58C21.0512 16.1196 21.9121 15.3116 22.4381 14.2916C22.964 13.2715 23.1231 12.1016 22.8886 10.9782C22.654 9.85478 22.0402 8.84623 21.15 8.12185C20.2599 7.39748 19.1476 7.00137 18 7.00002H16.74C16.423 5.77254 15.8188 4.63797 14.9773 3.68982C14.1357 2.74167 13.0809 2.00709 11.8997 1.54664C10.7186 1.08619 9.44488 0.913035 8.18365 1.04146C6.92243 1.16989 5.70978 1.59622 4.64567 2.2853C3.58155 2.97439 2.69644 3.9065 2.06328 5.00482C1.43012 6.10313 1.06704 7.33619 1.00398 8.60237C0.940923 9.86855 1.17968 11.1316 1.70058 12.2874C2.22147 13.4432 3.00959 14.4586 3.99997 15.25" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>;
};

export const ThunderIcon: FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} sx={{ fill: 'none' }} >
    <path d="M19 16.9C20.2151 16.6532 21.2953 15.9638 22.0307 14.9655C22.7661 13.9671 23.1043 12.7312 22.9797 11.4975C22.8551 10.2638 22.2765 9.12046 21.3563 8.28937C20.4361 7.45828 19.2399 6.99875 18 6.99999H16.74C16.4087 5.71731 15.764 4.53698 14.8639 3.56498C13.9638 2.59298 12.8363 1.85972 11.5828 1.43106C10.3293 1.00239 8.98897 0.891726 7.68213 1.109C6.37529 1.32628 5.14287 1.86469 4.0955 2.6759C3.04814 3.48711 2.21862 4.54573 1.68143 5.75671C1.14424 6.96768 0.916185 8.29311 1.01775 9.61399C1.11931 10.9349 1.54731 12.2098 2.26332 13.3245C2.97932 14.4391 3.96093 15.3584 5.12 16" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 11L9 17H15L11 23" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>;
};

export const TornadoIcon: FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} sx={{ fill: 'none' }} >
    <path d="M20.7866 5.12195C20.7866 5.2281 20.7237 5.44573 20.341 5.75047C19.9659 6.04917 19.377 6.35114 18.5826 6.61874C17.0013 7.15138 14.7688 7.4939 12.2683 7.4939C9.76776 7.4939 7.5353 7.15138 5.95403 6.61874C5.15958 6.35114 4.57064 6.04917 4.19557 5.75047C3.81291 5.44573 3.75 5.2281 3.75 5.12195C3.75 5.01581 3.81291 4.79818 4.19557 4.49343C4.57064 4.19473 5.15958 3.89277 5.95403 3.62516C7.5353 3.09252 9.76776 2.75 12.2683 2.75C14.7688 2.75 17.0013 3.09252 18.5826 3.62516C19.377 3.89277 19.9659 4.19473 20.341 4.49343C20.7237 4.79818 20.7866 5.01581 20.7866 5.12195Z" stroke="currentcolor" strokeWidth="1.5" />
    <path d="M6.26829 7.51221C3.14634 9.90245 10.2195 13.7561 18.5122 10.3903" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7.80176 10.9756C4.43593 14.3414 11.2439 17.0244 17.3414 14.3414" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M11.9024 15.7805C11.8374 16.5122 12.1463 18.1903 13.9024 19.0488C14.5854 19.3827 15.2846 20.7073 14.8781 21" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" />
  </SvgIcon>;
};

export const SnowIcon: FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} sx={{ fill: 'none' }} >
    <path d="M20 17.58C21.0512 17.1196 21.9121 16.3116 22.4381 15.2916C22.964 14.2715 23.1231 13.1016 22.8886 11.9782C22.654 10.8548 22.0402 9.84623 21.15 9.12185C20.2599 8.39748 19.1476 8.00137 18 8.00002H16.74C16.423 6.77254 15.8188 5.63797 14.9773 4.68982C14.1357 3.74167 13.0809 3.00709 11.8997 2.54664C10.7186 2.08618 9.44488 1.91304 8.18365 2.04146C6.92243 2.16989 5.70978 2.59622 4.64567 3.2853C3.58155 3.97439 2.69644 4.9065 2.06328 6.00482C1.43012 7.10313 1.06704 8.33619 1.00398 9.60237C0.940923 10.8685 1.17968 12.1316 1.70058 13.2874C2.22147 14.4432 3.00959 15.4586 3.99997 16.25" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 16H8.01" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 20H8.01" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 18H12.01" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 22H12.01" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 16H16.01" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 20H16.01" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>;
};

export const UnknownWeatherIcon: FC<SvgIconProps> = (props) => {
  return <SvgIcon {...props} sx={{ fill: 'none' }} >
    <path d="M18 10H16.74C16.3659 8.551 15.5928 7.23599 14.5086 6.2044C13.4245 5.1728 12.0727 4.46599 10.6069 4.16428C9.14112 3.86256 7.62008 3.97804 6.21664 4.49759C4.8132 5.01714 3.58363 5.91993 2.66764 7.10338C1.75165 8.28683 1.18598 9.70348 1.0349 11.1924C0.883813 12.6812 1.15338 14.1826 1.81296 15.526C2.47255 16.8693 3.4957 18.0007 4.76617 18.7916C6.03663 19.5824 7.50347 20.0011 8.99999 20H18C19.3261 20 20.5978 19.4732 21.5355 18.5355C22.4732 17.5979 23 16.3261 23 15C23 13.6739 22.4732 12.4021 21.5355 11.4645C20.5978 10.5268 19.3261 10 18 10Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.09009 10.3308C8.24671 9.88561 8.55584 9.51019 8.96274 9.27105C9.36964 9.03191 9.84804 8.94449 10.3132 9.02428C10.7784 9.10407 11.2003 9.34592 11.5043 9.70699C11.8082 10.0681 11.9746 10.525 11.9739 10.997C11.9739 12.3293 9.97535 12.9955 9.97535 12.9955" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 16H10.01" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>;
};
