import { useTheme } from '@mui/material';
import { forwardRef, Ref } from 'react';
import { Marker } from 'react-leaflet';
import { Undefinable } from '../../types';
import { getMarkerTemplate } from '../../util';
import { CustomMarkerProps } from './custom-marker-types';

export const CustomMarker = forwardRef(({
  children,
  color,
  drag = false,
  eventHandlers,
  icon,
  image,
  pinPosition,
  type,
  indicator,
  iconColor = 'currentcolor',
}: CustomMarkerProps, ref: Undefinable<Ref<L.Marker<any>>>) => {
  const theme = useTheme();
  const background = color || theme.palette.primary.main;

  return (
    <Marker
      ref={ref}
      draggable={drag}
      eventHandlers={eventHandlers}
      position={pinPosition}
      icon={getMarkerTemplate(type, icon, image, background, iconColor, indicator)}
    >
      {children}
    </Marker>
  );
});

CustomMarker.displayName = 'CustomMarker';
