import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ListPaperColumn } from '../list-item';

interface StageTransitionColumnProps {
  who: string | null;
  when: JSX.Element;
  label?: string;
}

export const StageTransitionColumn: FC<StageTransitionColumnProps> = ({ who, when, label }) => {
  const { t } = useTranslation();

  return (
    <ListPaperColumn
      data-testid="transfers-list-column-stage-transition"
      label={label}
      sx={{
        overflow: 'visible',
        '& > label': {
          textTransform: 'capitalize',
        },
        '& > div': {
          wordBreak: 'break-word',
          whiteSpace: 'normal',
          overflow: 'visible',
          textOverflow: 'unset',
          width: '100%',
        },
      }}
    >
      <span>
        {who || t('common:terms.system')}
        <span style={{ marginLeft: '8px' }}>
          ({when})
        </span>
      </span>
    </ListPaperColumn>
  );
};
