import { Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  spacing?: number;
  sx?: SxProps<Theme>;
}

export const InlineLayout: FC<Props> = ({ children, spacing = 2, sx }): JSX.Element => (
  <Stack
    spacing={spacing}
    sx={{
      height: 'fit-content',
      padding: { xs: 1, sm: 2 },
      ...sx,
    }}
  >
    {children}
  </Stack >
);
