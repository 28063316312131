import { Change } from '@eagle/api-types';
import { TypeDefinitionTypes } from '@eagle/common';
import { Feature, FeatureType } from '@eagle/core-data-types';
import { DriverBehaviorEventTypes, DuressEventTypes, EventRecordEventTypes, FeatureTypes, InertiaEventTypes, TrackingEventTypes, VehicleSeatEventTypes } from '@eagle/data-function-types';
import _, { isEmpty, isEqual, xorWith } from 'lodash';
import { FilterPathIdentifiers } from '../components';
import { AppliedFilter, AppliedFilterType } from '../components/entity-search/types';
import { FILTERS_KEY } from '../components/new-filter/new-filter-builder';
import { FetchAllCache } from '../hooks';
import { EntityType } from '../pages';
import { Nullable, Undefinable } from '../types';
import { getEventLabelWithFlag, getFeatureLabelWithFlag } from './events';
import { getLocalStorageItem, setLocalStorageItem } from './local-storage';

export interface ReplacePath {
  new: string;
  old: string;
}

export interface DeletedProps {
  deleted?: Nullable<Change>;
  finish?: Nullable<Change>;
}

export const FILTER_OUT = {
  deleted: { deleted: null },
  finish: { finish: null },
  fixedId: { fixedAccountId: { '$exists': true } },
} as const;

export enum EventOccurrenceTypes {
  AIRBAG_DEPLOYED = 'airbag-deployed',
  VSC_ACTIVATED = 'vsc-activated',
}

export const NEW_ALERT_FILTER_FLAG = 'track-alert-logic-data-driven-enhancements-temporary-20230907';
export const NEW_FILTER_FLAG = 'portals-global-filtering-component-v2-temporary-20230420';
export const FILTER_SELECT_ALL_FLAG = 'filter-panel-select-all-deselect-all';
export const FILTER_SHOW_DELETED_FLAG = 'portals-filter-drawer-show-deleted-entities-feature';

export const THING_FILTER_STORAGE_KEY = 'things';

export const isDeleted = <T extends unknown & DeletedProps>({ deleted, finish }: T): boolean => {
  return !!deleted || !!finish;
};

export const filterDeleted = <T extends unknown & DeletedProps>(items: T[]): T[] => {
  return items.filter((item) => !isDeleted(item));
};

export const filterDeletedCache = async <T extends unknown & DeletedProps & { _id: string }>(cache: FetchAllCache): Promise<T[]> => {
  const items = await cache.all<T>();
  return filterDeleted(items);
};

export const includeDeletedFilters = (includeDeleted: Undefinable<boolean>, filterFinish?: boolean): Record<string, unknown> => {
  if (filterFinish) return !includeDeleted ? { ...FILTER_OUT.finish } : {};
  return !includeDeleted ? { ...FILTER_OUT.deleted } : {};
};

export const isAppliedFilterArrayEqual = (filter1: AppliedFilter<AppliedFilterType>[], filter2: AppliedFilter<AppliedFilterType>[]): boolean => {
  const filter1WithoutId = filter1.map((item) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = item;
    return { ids: undefined, ...rest };
  });
  const filter2WithoutId = filter2.map((item) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = item;
    return { ids: undefined, ...rest };
  });
  return isEmpty(xorWith(filter1WithoutId, filter2WithoutId, isEqual));
};

const getFilterValue = (filter: AppliedFilter): unknown => {
  return (filter.definition.type === 'entity' || filter.definition.type === TypeDefinitionTypes.REFERENCE) && 'id' in (filter.value as { id: unknown })
    ? (filter.value as { id: unknown }).id
    : filter.value;
};

export const alertFilterToQuery = (filters: AppliedFilter[], prefix?: string): Record<string, unknown> => {
  const simpleFilters: AppliedFilter<AppliedFilterType>[] = [];
  const alertTypeFilters: { feature: string; alertTypeId: string | { '$in': string[] } }[] = [];

  for (const filter of filters) {
    if (typeof filter.value === 'object' && filter.value.alertProperties) {
      const { feature, alert } = filter.value.alertProperties;

      const existing = alertTypeFilters.find((data) => data.feature === feature);
      if (existing) {
        if (typeof existing.alertTypeId === 'string') {
          existing.alertTypeId = { '$in': [existing.alertTypeId, alert] };
        }
        else {
          existing.alertTypeId.$in.push(alert);
        }
      }
      else {
        alertTypeFilters.push({ feature, alertTypeId: alert });
      }
    }
    else {
      simpleFilters.push(filter);
    }
  }

  const result: { '$and': Record<string, unknown>[] } = { '$and': [] };

  const prefixedAlertFilters = prefix
    ? alertTypeFilters.map(({ feature, alertTypeId }) => ({
      [`${prefix}.feature`]: feature,
      [`${prefix}.alertTypeId`]: alertTypeId,
    }))
    : alertTypeFilters;

  if (alertTypeFilters.length) {
    result.$and.push({ '$or': prefixedAlertFilters });
  }
  if (simpleFilters.length) {
    result.$and.push(filterToQuery(simpleFilters));
  }

  return result.$and.length ? result : {};
};

export const filterToQuery = (filters: AppliedFilter[], replacePaths?: ReplacePath[] | readonly ReplacePath[]): Record<string, unknown> => {
  const groupedFilters = filters.reduce((acc, filter) => {
    if (!acc[filter.propertyPath]) {
      acc[filter.propertyPath] = [];
    }

    acc[filter.propertyPath].push(filter);

    return acc;
  }, {} as Record<string, AppliedFilter[]>);

  const getNewFilterName = (filterName: string): string => {
    const newFilterName = replacePaths?.find((item) => item.old === filterName)?.new;
    return newFilterName ?? filterName;
  };

  const mongoFilters = Object.entries(groupedFilters).reduce((acc, [filterName, appliedFilters]) => {
    const newFilterName = getNewFilterName(filterName);

    if (newFilterName.startsWith('properties.') || newFilterName.startsWith('sharedProperties.')) {
      const propertyFilters = [];
      const values = appliedFilters.map((filter) => getFilterValue(filter));
      const filter = values.length === 1 ? values[0] : { '$in': values };
      propertyFilters.push({ [newFilterName]: filter }, { [`${newFilterName}.value`]: filter });
      acc.$and.push({ '$or': propertyFilters });
    }
    else if (appliedFilters.length === 1) {
      acc.$and.push({ [newFilterName]: getFilterValue(appliedFilters[0]) });
    } else {
      acc.$and.push({ [newFilterName]: { '$in': appliedFilters.map((filter) => getFilterValue(filter)) } });
    }
    return acc;
  }, { '$and': [] } as { '$and': Record<string, unknown>[] });

  return !isEqual(mongoFilters, { '$and': [] }) ? mongoFilters : {};
};

export const getFilterPersisted = (storageKey: string, pathIdentifiers: FilterPathIdentifiers[]): AppliedFilter<AppliedFilterType>[] => {
  const storageFilters: Record<string, AppliedFilter<AppliedFilterType>[]> =
    getLocalStorageItem(FILTERS_KEY, { [storageKey]: [] });
  const persistedFilters = Object.keys(storageFilters).reduce<AppliedFilter<AppliedFilterType>[]>(
    (arr, key) =>
      key === storageKey
        ? arr.concat(storageFilters[key])
        : arr.concat(
          storageFilters[key].filter(
            ({ pathIdentifier }) =>
              pathIdentifier && pathIdentifiers.includes(pathIdentifier),
          ),
        ),
    [],
  );
  return _.uniqBy(persistedFilters, 'id');
};

export const removeFilterItems = (filterToRemoves: AppliedFilter[]): void => {
  const filterToRemoveIds = filterToRemoves.map((filterToRemove) => filterToRemove.id);
  const localStorageFilters: Record<string, AppliedFilter<AppliedFilterType>[]> = getLocalStorageItem(FILTERS_KEY);
  const newFilters = Object.keys(localStorageFilters).reduce<Record<string, AppliedFilter<AppliedFilterType>[]>>(
    (obj, filterType) => ({
      ...obj,
      [filterType]: localStorageFilters[filterType].filter(
        (item) => !filterToRemoveIds.includes(item.id),
      ),
    }),
    {},
  );

  setLocalStorageItem(FILTERS_KEY, newFilters);
};

export const getAlertTypeFilters = (
  featureTypes: Undefinable<FeatureType[]>,
  myFeatures: Undefinable<Feature[]>,
  formatFilter: (_id: string, event: string, customKey?: string) => EntityType,
  enableNewAlertFilter: Undefinable<boolean>,
  useEventV3: Undefinable<boolean>,
): EntityType[] => {
  if (enableNewAlertFilter && featureTypes && myFeatures) {
    const newAlertTypes = myFeatures.flatMap((feature) => {
      const selectedFeature = featureTypes.find((item) => item._id === feature.featureTypeId);
      const alertKeys = selectedFeature?.alerts ? Object.keys(selectedFeature.alerts) : [];

      return alertKeys.map((alert) => ({
        featureId: feature._id,
        featureTypeId: feature.featureTypeId,
        alert,
      }));
    });

    return newAlertTypes.map(({ alert, featureId, featureTypeId }) => {
      const featureDisplay = getFeatureLabelWithFlag(featureId, useEventV3);
      const alertDisplay = getEventLabelWithFlag(featureTypeId, alert, featureId, useEventV3);

      return {
        _id: alert,
        alertProperties: {
          feature: featureId,
          featureType: featureTypeId,
          alert,
        },
        display: featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? `${featureDisplay} - ${alertDisplay}` : `${alertDisplay} - ${featureDisplay}`,
        properties: {
          definitions: {},
          order: [],
        },
      };
    });
  }

  return ([
    formatFilter(DuressEventTypes.DURESS_BUTTON_ACTIVATED, FeatureTypes.DURESS_V0, 'common:features.alerts.duress-button-activated'),
    ...Object.values(InertiaEventTypes).map((value) => formatFilter(value, FeatureTypes.INERTIA_V0)),
    ...Object.values(VehicleSeatEventTypes).map((value) => formatFilter(value, FeatureTypes.VEHICLE_SEAT_V0)),
    ...Object.values({ start: TrackingEventTypes.SPEEDING_START, finish: TrackingEventTypes.SPEEDING_FINISH }).map((value) => formatFilter(value, FeatureTypes.TRACKING_V0)),
    ...Object.values(DriverBehaviorEventTypes).map((value) => formatFilter(value, FeatureTypes.DRIVER_BEHAVIOR_V0)),
    ...Object.values(EventRecordEventTypes).map((value) => formatFilter(value, FeatureTypes.EVENT_RECORD_V0)),
    ...Object.values(EventOccurrenceTypes).map((value) => formatFilter(`${value}/${EventRecordEventTypes.OCCURRENCE}`, FeatureTypes.EVENT_RECORD_V0, `common:features.${value}`)),
  ]);
};
