import { FC, useState } from 'react';
import { EventHistoryView } from './event-history-view';
import { MobileTabSelection } from './event-history.types';

interface Props {
  isDrawerOpen: boolean;
  renderBottomDrawerContent: () => JSX.Element;
  renderHistoryContent: (mobileTabSelection: MobileTabSelection) => JSX.Element;
  renderTopDrawerContent: () => JSX.Element;
  setIsTransitioning: (isTransitioning: boolean) => void;
}

export const EventHistory: FC<Props> = ({
  isDrawerOpen,
  renderBottomDrawerContent,
  renderHistoryContent,
  renderTopDrawerContent,
  setIsTransitioning,
}): JSX.Element => {
  const [mobileTabSelection, setMobileTabSelection] = useState<MobileTabSelection>(MobileTabSelection.TOP);

  return (
    <EventHistoryView
      isDrawerOpen={isDrawerOpen}
      mobileTabSelection={mobileTabSelection}
      renderBottomDrawerContent={renderBottomDrawerContent}
      renderHistoryContent={renderHistoryContent}
      renderTopDrawerContent={renderTopDrawerContent}
      setIsTransitioning={setIsTransitioning}
      setMobileTabSelection={setMobileTabSelection}
    />
  );
};
