import { Operator } from '@jexop/core';
import { get, isObject } from 'lodash';
import { Nullable } from '../types';

export const getTimezoneFromLocation: Operator<unknown> = ({ location }): Nullable<string>  => {
  if (!location || (!isObject(location))) {
    return null;
  }

  return get(location, 'timeZone.id', null);
};
