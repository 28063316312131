export const ALERT_LIST_RECENT_HEADERS = {
  op: 'ui',
  name: 'TableRow',
  children: [{
    op: 'ui',
    name: 'TableCell',
    children: {
      op: 'translate',
      translation: 'common:terms.date-time',
    },
  },
  {
    op: 'ui',
    name: 'TableCell',
    children: {
      op: 'translate',
      translation: 'common:terms.alert-type',
    },
  },
  {
    op: 'ui',
    name: 'TableCell',
    children: {
      op: 'translate',
      translation: 'common:terms.location',
    },
  }],
};

export const ALERT_LIST_RECENT_ITEM = [{
  op: 'ui',
  name: 'TableCell',
  children: {
    op: 'ui',
    name: 'DateDisplay',
    date: {
      op: 'context',
      path: 'occurred',
    },
    timezone: {
      op: 'context',
      path: 'data.location.timeZone.id',
    },
  },
},
{
  op: 'ui',
  name: 'TableCell',
  children: {
    op: 'ui',
    name: 'AlertLabel',
    alert: {
      op: 'context',
    },
  },
},
{
  op: 'ui',
  name: 'TableCell',
  children: {
    op: 'ui',
    name: 'FormatAddress',
    sx: {
      maxWidth: '300px',
    },
    value: {
      op: 'context',
      path: 'data.location.address',
    },
    addressUnavailableText: {
      op: 'translate',
      translation: 'common:page.thing-map.address-unavailable.hint',
    },
  },
}];
