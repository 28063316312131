import { VideoSegmentStatus } from '@eagle/data-function-types';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SdCardIcon from '@mui/icons-material/SdCard';
import UploadIcon from '@mui/icons-material/Upload';
import WarningIcon from '@mui/icons-material/Warning';
import { FC } from 'react';

interface Props {
  color: string;
  videoSegmentStatus: VideoSegmentStatus;
}

export const VideoCellIcon: FC<Props> = ({ color, videoSegmentStatus }) => {
  switch (videoSegmentStatus) {
    case VideoSegmentStatus.UPLOAD_COMPLETE:
      return <PlayCircleIcon fontSize="inherit" sx={{ color, fontSize: '1rem' }} />;
    case VideoSegmentStatus.REQUEST_FAILED:
    case VideoSegmentStatus.UPLOAD_FAILED:
      return <WarningIcon fontSize="inherit" sx={{ color, fontSize: '1rem' }} />;
    case VideoSegmentStatus.RECORDED:
      return <SdCardIcon fontSize="inherit" sx={{ color, fontSize: '1rem' }} />;
    case VideoSegmentStatus.UPLOAD_STARTED:
      return <UploadIcon fontSize="inherit" sx={{ color, fontSize: '1rem' }} />;
    case VideoSegmentStatus.REQUEST_ACKED:
    case VideoSegmentStatus.REQUESTED:
      return <DoneIcon fontSize="inherit" sx={{ color, fontSize: '1rem' }} />;
    case VideoSegmentStatus.NO_LONGER_AVAILABLE:
    case VideoSegmentStatus.NOT_AVAILABLE:
      return <CloseIcon fontSize="inherit" sx={{ color, fontSize: '1rem' }} />;
    default:
      return <></>;
  }
};
