import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/system';
import { FC, ReactNode, RefObject } from 'react';
import { FlexBox } from '../../components/flex-box';
import { PageTitle } from '../../components/page-title';
import { SIDEBAR_FULL_WIDTH, SIDEBAR_WIDTH } from '../../constants';
import { useSidebarPinContext } from '../../hooks';

interface Props {
  actions?: ReactNode;
  clearAllFiltersButton: JSX.Element;
  content: ReactNode;
  'data-testid'?: string;
  datePicker?: JSX.Element;
  filterButton: JSX.Element;
  filterContent: JSX.Element;
  filters?: ReactNode;
  icon?: JSX.Element;
  pagination?: JSX.Element;
  scrollRef?: RefObject<HTMLDivElement>;
  search?: JSX.Element;
  setFilterOpen: (value: boolean) => void;
  showFilterButton?: boolean;
  showFilters?: boolean;
  subtitle?: ReactNode;
  title: ReactNode;
}

const StyledDrawer = styled(Drawer)({
  width: '350px',
  '& .MuiPaper-root': {
    width: 'inherit',
  },
});

const Row = styled((props: StackProps) => (
  <Stack direction="row" spacing={2} useFlexGap {...props} />
))({
  alignItems: 'center',
});

export const LayoutDesktop: FC<Props> = ({
  actions,
  clearAllFiltersButton,
  content,
  datePicker,
  filterButton,
  filterContent,
  filters,
  icon,
  pagination,
  scrollRef,
  search,
  setFilterOpen,
  showFilterButton,
  showFilters,
  subtitle,
  title,
  ...props
}) => {
  const { isPinned } = useSidebarPinContext();

  return (
    <FlexBox data-testid={props['data-testid']} id="list-page" className="ListPage-root">
      <Container id="list-page-scroll" ref={scrollRef}>
        <StyledDrawer
          anchor="left"
          open={showFilters}
          onClose={() => setFilterOpen(false)}
          sx={{
            zIndex: 1300,
            '& .MuiPaper-root': {
              left: isPinned ? SIDEBAR_FULL_WIDTH : SIDEBAR_WIDTH,
            },
          }}
        >
          {filterContent}
        </StyledDrawer>
        <Stack direction="column" spacing={1} sx={{ py: 3, height: 1 }}>
          <Row id="page-header" sx={{ lineHeight: '2rem', alignItems: 'start' }}>
            <PageTitle data-testid="page-title" title={title} icon={icon} />
            <Box>
              {search}
            </Box>
            {showFilterButton
              && <Stack direction="row" spacing={2}>
                {filterButton}
              </Stack>
            }
          </Row>
          <Row sx={{ justifyContent: 'end', flexWrap: 'wrap' }}>
            <Box sx={{ my: 1, mr: 'auto' }}>{subtitle}</Box>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>{actions}</Stack>
          </Row>
          {filters
            && <Row sx={{ flexWrap: 'wrap', mt: '4px !important' }} spacing={0}>
              {clearAllFiltersButton}
              {filters}
            </Row>
          }
          <Stack id="content" spacing={2} sx={{ py: 1, flexGrow: 1 }}>
            <Box flexGrow={1}>
              {content}
            </Box>
            <Box display="flex" sx={{ justifyContent: 'center', pb: 2 }}>
              {pagination}
            </Box>
          </Stack>
        </Stack>
      </Container>
      {datePicker}
    </FlexBox >
  );
};
