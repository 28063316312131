import { Box, Container, Divider, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';
import { FlexBox } from '../../components/flex-box';
import { PageTitle } from '../../components/page-title';
import { useSidebarPinContext } from '../../hooks';
import { useMaxScreen } from '../../hooks/use-max-screen';

interface LayoutProps {
  actions: ReactNode;
  children: ReactNode;
  'data-testid'?: string;
  deletedBanner?: JSX.Element;
  pageAlert?: JSX.Element;
  pageIcon?: JSX.Element;
  pageSubtitle?: JSX.Element;
  pageTitle: ReactNode;
  id?: string;
}

const LayoutDesktop: FC<LayoutProps> = ({ actions, children, deletedBanner, pageAlert, pageIcon, pageSubtitle, pageTitle, id = 'detail-page', ...props }) => {
  const { isPinned } = useSidebarPinContext();
  const mediumScreen = useMaxScreen('md');
  const largeScreen = useMaxScreen('lg');
  const breakpoint = isPinned ? largeScreen : mediumScreen;
  return (
    <FlexBox data-testid={props['data-testid']} flexDirection="column" overflow="auto">
      <Container sx={{ py: 3 }} id={id}>
        <Stack id="page-header" className="detail-header" direction="column">
          <PageTitle icon={pageIcon} title={pageTitle} data-testid="page-title" />
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Box>
              {pageSubtitle}
            </Box>
            <Box>
              <Stack direction="row" spacing={2}>
                {actions}
              </Stack>
            </Box>
          </Stack>
        </Stack>

        <Divider sx={{ my: 2 }} />

        {pageAlert && <Box sx={{ mb: 2 }}>{pageAlert}</Box>}
        {deletedBanner && <Box sx={{ mb: 2 }}>{deletedBanner}</Box>}

        <Stack id="content" className="detail-content" display="flex" direction={breakpoint ? 'column' : 'row'} spacing={4}>
          {children}
        </Stack>
      </Container>
    </FlexBox>
  );
};

export default LayoutDesktop;
