import { Paper, Stack } from '@mui/material';
import { FC } from 'react';
import { BottomNavigation } from '../../components';
import { MobileTabSelection } from './event-history.types';

interface Props {
  bottomActions: JSX.Element[];
  content: JSX.Element;
  value: MobileTabSelection;
}

export const EventHistoryMobile: FC<Props> = ({ bottomActions, content, value }) => {
  return (
    <Stack sx={{ height: '100%', width: '100%' }}>
      <Stack
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {content}
      </Stack>
      <Paper elevation={3} sx={{ zIndex: 1100 }}>
        <BottomNavigation<MobileTabSelection> value={value}>
          {bottomActions}
        </BottomNavigation>
      </Paper>
    </Stack>
  );
};
