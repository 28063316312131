import { Circle } from '@eagle/core-data-types';
import { TFunction } from 'i18next';
import { Undefinable } from '../../../../types';

export const getTooltipDisplay = (displays: string[], t: TFunction): Undefinable<string> => {
  switch (displays.length) {
    case 0:
      return undefined;
    case 1:
      return displays[0];
    case 2:
      return displays.join(` ${t('common:component.filter.labels.and')} `);
    default: return displays
      .slice(0, 2)
      .join(', ')
      .concat(` ${t('common:component.geofence.hint.tooltip', { count: displays.length - 2 })}`);
  }
};

export const parseCircleGeometry = (geometry: Circle): { center: [number, number]; radius: number } => {
  return {
    center: [geometry.coordinates[0][0], geometry.coordinates[0][1]],
    radius: geometry.coordinates[0][2],
  };
};
