import metadatas from 'libphonenumber-js/metadata.min.json';
import { getKeys } from '../../util/array';
import { PhoneInputCountry } from './types';

export const DISPLAY_NAMES_OPTIONS: Intl.DisplayNamesOptions = {
  type: 'region',
};

const decodeNavigatorLanguage = (): [string, PhoneInputCountry] | [undefined, undefined] => {
  if (!window.navigator.language) return [undefined, undefined];
  const parts = window.navigator.language.split('-');
  if (parts.length !== 2) return [undefined, undefined];
  return parts as [string, PhoneInputCountry];
};

export const [DEFAULT_LANG, DEFAULT_COUNTRY_ISO] = decodeNavigatorLanguage();

export const COUNTRIES = metadatas.countries;
export const ISO_CODES = getKeys(COUNTRIES);
