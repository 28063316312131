export const unregisterServiceWorker = (): void => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) =>
        registration.unregister(),
      )
      .catch((error: Error) => {
        console.error(error.message);
      });
  }
};
