import { Card, CardContent, Container, Divider, Stack } from '@mui/material';
import { FC } from 'react';
import { FlexBox } from '../../components';
import { useMaxScreen, useSidebarPinContext } from '../../hooks';

interface Props {
  avatarComponent: JSX.Element;
  content: JSX.Element;
  pageActions: JSX.Element;
  pageTitle: JSX.Element;
}

export const ProfilePageDesktopLayout: FC<Props> = ({ avatarComponent, content, pageActions, pageTitle }) => {
  const { isPinned } = useSidebarPinContext();
  const mediumScreen = useMaxScreen('md');
  const largeScreen = useMaxScreen('lg');
  const breakpoint = isPinned ? largeScreen : mediumScreen;
  return (
    <FlexBox flexDirection="column" overflow="auto">
      <Container sx={{ py: 3 }} id="detail-page">
        <Stack id="page-header" className="detail-header" direction="column">
          {pageTitle}
        </Stack>

        <Divider sx={{ my: 2 }} />
        <Stack id="content" className="detail-content" display="flex" direction={breakpoint ? 'column' : 'row'} spacing={4}>
          <Stack
            direction="column"
            flex={[2, 2]}
            spacing={2}
            sx={{ minWidth: 0 }}
          >
            <Card>
              <CardContent>
                {avatarComponent}
                <Divider sx={{ my: 1 }} />
                {pageActions}
              </CardContent>
            </Card>
          </Stack>

          <Stack
            direction="column"
            flex={[3, 3]}
            spacing={2}
            sx={{ minWidth: 0 }}
          >
            {content}
          </Stack>
        </Stack>
      </Container>
    </FlexBox>
  );
};
