import { useEffect, useLayoutEffect, useRef } from 'react';
import { Nullable } from '../types';

export const useInterval = (callback: () => void, delayTime: Nullable<number>): void => {
  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delayTime && delayTime !== 0) {
      return;
    }

    const intervalId = setInterval(() => savedCallback.current(), delayTime);

    return () => clearInterval(intervalId);
  }, [delayTime]);
};
