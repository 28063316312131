/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, FC, PropsWithChildren, ReactNode, useContext, useEffect, useState } from 'react';
import { Undefinable } from '../types';
import { innerText } from '../util';

export interface PageInfo {
  replace?: boolean;
  title: ReactNode;
}

interface Props {
  info?: PageInfo;
  setInfo: (value: Undefinable<PageInfo>) => void;
}

interface UseGetTitle {
  replace?: boolean;
  title?: ReactNode;
}

const TitleContext = createContext<Undefinable<Props>>(undefined);

export const TitleProvider: FC<PropsWithChildren> = ({ children }) => {
  const [info, setInfo] = useState<PageInfo>();
  return (
    <TitleContext.Provider value={{ info, setInfo }}>
      {children}
    </TitleContext.Provider>
  );
};

export const useGetTitle = (): UseGetTitle => ({replace: useContext(TitleContext)?.info?.replace, title: useContext(TitleContext)?.info?.title});

export const useTitle = (title: ReactNode, replace?: boolean): void => {
  const props = useContext(TitleContext);
  const text = innerText(title);
  useEffect(() => {
    document.title = text;
    props?.setInfo({ replace, title });
  }, [text, replace]);
};

export const UseTitle: FC<PageInfo> = ({ title }) => {
  useTitle(title);
  return (null);
};
