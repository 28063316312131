import { isEmpty } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { FetchOne, MiddleSpinner } from '../../components';
import { evaluate } from '../../evaluator';
import { useUiTemplate } from '../../hooks';
import { CommonEntity } from '../../types';
import { ErrorPage } from '../errors';

export const Templated: FC = (): JSX.Element => {
  const { templateId, entityId, entityType } = useParams();
  const { template, loaded: templateLoaded } = useUiTemplate(templateId || '', {});
  const { t } = useTranslation(['common']);

  if (isEmpty(template) || !entityId || !entityType) {
    return <ErrorPage error={{ name: t('common:page.errors.not-found.title'), message: '' }} />;
  }

  if (!templateLoaded) {
    return <MiddleSpinner />;
  }

  return <FetchOne dataType={entityType} id={entityId} notFoundFactory={() => <ErrorPage error={{ name: t('common:page.errors.not-found.title'), message: '' }} />} renderFactory={(entity: CommonEntity) => <>{evaluate(template, { ...entity })}</>} />;
};
