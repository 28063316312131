import { Divider, Stack, Tab } from '@mui/material';
import { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterToolbar } from '../filter';
import { Tabs } from '../tabs';

interface Props {
  'data-testid'?: string;
  actions: JSX.Element;
  filterComponent: JSX.Element;
  onCloseClicked?: () => unknown;
  savedFiltersComponent: JSX.Element;
  savedFiltersLength: number;
}

enum FilterTabs {
  FILTERS,
  SAVED_FILTERS,
}

export const Layout: FC<Props> = ({ actions, onCloseClicked, filterComponent, savedFiltersComponent, savedFiltersLength, ...props }) => {
  const [selectedTab, setSelectedTab] = useState(FilterTabs.FILTERS);
  const { t } = useTranslation(['common']);

  const handleChange = (_event: SyntheticEvent, newValue: FilterTabs): void => {
    setSelectedTab(newValue);
  };

  return (
    <Stack data-testid={props['data-testid']} spacing={2} sx={{ flexDirection: 'column', height: '100%' }}>
      <FilterToolbar onCloseClicked={onCloseClicked} />
      <Tabs centered onChange={handleChange} value={selectedTab} variant="fullWidth">
        <Tab label={t('common:component.filter.heading')} data-testid="filter-tab-filters" />
        <Tab label={t('common:component.filter.labels.saved-caption-count', { count: savedFiltersLength })} data-testid="filter-tab-saved" />
      </Tabs>
      <Stack spacing={2} sx={{ flexGrow: 1, overflowY: 'auto', px: 3 }}>
        {selectedTab === FilterTabs.FILTERS && filterComponent}
        {selectedTab === FilterTabs.SAVED_FILTERS && savedFiltersComponent}
      </Stack>
      <Divider />
      <Stack direction="row" sx={{ justifyContent: 'space-around', mt: 0, pb: 3, px: 2 }}>
        {actions}
      </Stack>
    </Stack>
  );
};
