import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { PropsWithChildren, ReactElement } from 'react';
import Supercluster from 'supercluster';
import { MapStorageKeys } from '../../util';

export const ZOOM: Record<string, number> = {
  city: 10,
  country: 3,
  earth: 2.5,
  locality: 14,
  place: 15,
  state: 5,
  street: 15,
};

export type BBox = [number, number, number, number] | [number, number, number, number, number, number];

export interface BoundingBox {
  east: number;
  north: number;
  south: number;
  west: number;
}

export interface LocationPinProps extends PropsWithChildren {
  position: L.LatLng;
  sx?: SxProps<Theme>;
}

export interface ChangeViewProps {
  bounds?: BoundingBox;
  center: L.LatLng;
  zoom: number;
}

export interface PageMapProps extends PropsWithChildren {
  bounds?: BoundingBox;
  center: L.LatLng;
  'data-testid'?: string;
  dim?: boolean;
  storageKey?: MapStorageKeys;
  zoom?: number;
}

export interface MapIconProps {
  iconAnchor?: L.PointTuple;
  iconSize?: L.PointTuple;
  popupAnchor?: L.PointTuple;
  renderPin: ReactElement | string;
  tooltipAnchor?: L.PointTuple;
}

export interface MapMarkerProps {
  children?: ReactElement | JSX.Element | string;
  icon: L.Icon | L.DivIcon;
  position: L.LatLng;
}

export interface PointProperties {
  cluster: boolean;
  markerThingId: string;
  updated: string;
}

export type MarkerCluster = Supercluster.PointFeature<Supercluster.ClusterProperties & PointProperties>
export type MarkerPoint = Supercluster.PointFeature<PointProperties>;
