import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeRangeChangeHandler } from '../../components/date-time-range';
import { useSearch } from '../../pages/list/use-search';
import { CustomRange } from '../date-time-range-picker';

interface Props {
  onDateRangeChanged: DateTimeRangeChangeHandler;
  renderControl: boolean;
  savedRange: CustomRange;
  setSavedRange: (range: CustomRange) => void;
}

export const RangeControl: FC<Props> = ({
  onDateRangeChanged,
  renderControl,
  savedRange,
  setSavedRange,
}): JSX.Element => {
  const { startDate: savedStartDate, endDate: savedEndDate } = savedRange;
  const { t } = useTranslation(['common']);
  const { isLoading } = useSearch();
  const [forwardRange, setForwardRange] = useState<CustomRange>({
    startDate: undefined,
    endDate: undefined,
  });
  const [backwardRange, setBackwardRange] = useState<CustomRange>({
    startDate: undefined,
    endDate: undefined,
  });

  const getTooltipText = (dateRange: CustomRange): string => {
    const { startDate, endDate } = dateRange;
    if (startDate && endDate) {
      return t('common:component.range-select.labels.time-range', {
        start: startDate.toLocaleString({ ...DateTime.DATETIME_MED, year: undefined }),
        end: endDate.toLocaleString({ ...DateTime.DATETIME_MED, year: undefined }),
      });
    }
    return '';
  };

  const updateRange = (dateRange: CustomRange): void => {
    const { startDate, endDate } = dateRange;
    if (!startDate || !endDate) return;
    setSavedRange({ startDate, endDate });
    onDateRangeChanged(startDate.toJSDate(), endDate.toJSDate());
  };

  useEffect(() => {
    if (!savedStartDate || !savedEndDate) return;
    const diffInSeconds = savedEndDate.plus({ seconds: 1 }).diff(savedStartDate, 'seconds').seconds;
    setForwardRange({
      startDate: savedStartDate.plus({ seconds: diffInSeconds }),
      endDate: savedEndDate.plus({ seconds: diffInSeconds }),
    });
    setBackwardRange({
      startDate: savedStartDate.minus({ seconds: diffInSeconds }),
      endDate: savedEndDate.minus({ seconds: diffInSeconds }),
    });
  }, [savedStartDate, savedEndDate]);

  if (!renderControl) return <></>;

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <Tooltip arrow title={getTooltipText(backwardRange)}>
        <Box>
          <IconButton
            disabled={isLoading}
            onClick={() => updateRange(backwardRange)}
          >
            <ChevronLeft />
          </IconButton>
        </Box>
      </Tooltip>
      <Typography align="center" sx={{ whiteSpace: 'nowrap' }}>{t('common:component.range-select.labels.show-more')}</Typography>
      <Tooltip arrow title={getTooltipText(forwardRange)}>
        <Box>
          <IconButton
            disabled={isLoading}
            onClick={() => updateRange(forwardRange)}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Tooltip>
    </Stack>
  );
};
