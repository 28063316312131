import { SxProps } from '@mui/system';

export const fadeIn: SxProps = {
  animation: 'opacity 50ms normal forwards',
  opacity: 0,
  animationIterationCount: 1,
  '@keyframes opacity': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
};
