import { captureException } from '@sentry/react';
import { MutableRefObject, RefCallback } from 'react';
import { FilterCountriesOptions, PhoneInputCountry } from '../components/phone-input';
import { COUNTRIES, DEFAULT_LANG, DISPLAY_NAMES_OPTIONS } from '../components/phone-input/constants';
import { matchIsArray, matchIsObject } from './array';

export const getCallingCodeOfCountry = (isoCode: PhoneInputCountry): string => {
  return COUNTRIES[isoCode]?.[0] as string;
};

export const sortedPreferredCountries = (
  countries: readonly PhoneInputCountry[],
  preferredCountries: readonly PhoneInputCountry[],
): readonly PhoneInputCountry[] => {
  return preferredCountries.concat(countries);
};

export const getOnlyCountries = (
  countries: readonly PhoneInputCountry[],
  onlyCountries: readonly PhoneInputCountry[],
): readonly PhoneInputCountry[] => {
  return countries.filter((isoCode) => {
    return onlyCountries.includes(isoCode);
  });
};

export const excludeCountries = (
  countries: readonly PhoneInputCountry[],
  excludedCountries?: readonly PhoneInputCountry[],
): readonly PhoneInputCountry[] => {
  if (matchIsArray(excludedCountries, true)) {
    return countries.filter((isoCode) => {
      return !excludedCountries.includes(isoCode);
    });
  }
  return countries;
};

export const filterCountries = (
  countries: readonly PhoneInputCountry[],
  options: FilterCountriesOptions,
): readonly PhoneInputCountry[] => {
  const { onlyCountries, excludedCountries, preferredCountries } = options;

  if (matchIsArray(onlyCountries, true)) {
    const filteredCountries = getOnlyCountries(countries, onlyCountries);
    return matchIsArray(preferredCountries, true)
      ? sortedPreferredCountries(filteredCountries, preferredCountries)
      : filteredCountries;
  }

  const sortedCountries = matchIsArray(preferredCountries, true)
    ? sortedPreferredCountries(countries, preferredCountries)
    : countries;

  return matchIsArray(excludedCountries, true)
    ? excludeCountries(sortedCountries, excludedCountries)
    : sortedCountries;
};

export const assocRefToPropRef = (
  ref: unknown,
  propRef?: RefCallback<unknown> | MutableRefObject<unknown>,
): void => {
  if (typeof propRef === 'function') {
    propRef(ref);
  } else if (propRef && matchIsObject(propRef) && 'current' in propRef) {
    propRef.current = ref;
  }
};

export const getDisplayNames = (lang?: string): Intl.DisplayNames => {
  try {
    return new Intl.DisplayNames(lang, DISPLAY_NAMES_OPTIONS);
  } catch (error) {
    console.log(error);
    captureException(error);
    return new Intl.DisplayNames(DEFAULT_LANG, DISPLAY_NAMES_OPTIONS);
  }
};
