/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { PointOfInterestSet } from '@eagle/core-data-types';
import { Link, Stack, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHref, useNavigate, useParams } from 'react-router';
import { useAuthenticated } from '../../../auth';
import { Breadcrumbs, DeleteEntityDialog, DeleteIcon, MarkerProvider, MiddleSpinner, PointOfInterestIcon, PortalFeatureIcons } from '../../../components';
import { PointOfInterestGroupInlayMap } from '../../../components/point-of-interest-picker-tool';
import { PointOfInterestSharingCard } from '../../../components/point-of-interest-sharing-card/point-of-interest-sharing-card';
import { T_MANY, T_ONE } from '../../../constants';
import { useDynamicModule, useFetchAllCache } from '../../../hooks';
import { CacheDataTypes, FeatureIcons, PageAction, Portal, Portals } from '../../../types';
import { useHasAuthorization } from '../../../util';
import { DetailPage } from '../../detail';
import { PointOfInterestDetailList } from './point-of-interest-detail-list';
import { PointOfInterestSetDetailCard } from './point-of-interest-set-detail-card';

interface Props {
  pointOfInterestSet?: PointOfInterestSet;
  portal: Portal;
}

const POINT_OF_INTEREST_ADMIN = [RoleFunction.POINT_OF_INTEREST_ADMINISTRATOR] as const;

export const PointOfInterestSetDetail: FC<Props> = ({ pointOfInterestSet, portal }) => {
  const { t } = useTranslation(['common']);
  const { pointOfInterestSetId } = useParams();
  const href = useHref('/point-of-interest/sets');
  const poiSetCache = useFetchAllCache(CacheDataTypes.POINT_OF_INTEREST_SET);
  const { restClient, userInfo } = useAuthenticated();
  const { hasAuthorization } = useHasAuthorization();
  const navigate = useNavigate();
  const theme = useTheme();
  const [poiSetDisplay, setPoiSetDisplay] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [refresh, setRefresh] = useState(new Date());
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Admin);
  const isPoiAdmin = hasAuthorization(POINT_OF_INTEREST_ADMIN);

  const hasEditPermissions = portal !== Portals.ADMIN || isPoiAdmin;

  const getDeleteDialogPoints = ({ accountId, shared }: PointOfInterestSet): string[] => {
    if (accountId !== userInfo.accountId) {
      return [
        'common:page.point-of-interest-set-details.delete.hint.point-four',
        'common:page.point-of-interest-set-details.delete.hint.point-three',
      ];
    }
    if (!shared) return ['common:page.point-of-interest-set-details.delete.hint.point-one'];
    return [
      'common:page.point-of-interest-set-details.delete.hint.point-one',
      'common:page.point-of-interest-set-details.delete.hint.point-two',
    ];
  };

  const renderPageContent = (data: PointOfInterestSet): JSX.Element => {
    setPoiSetDisplay(data.display);
    return (
      <MarkerProvider>
        <Stack direction="column" flex={[2, 2]}>
          <PointOfInterestSetDetailCard pointOfInterestSet={data} invalidateCache={() => { poiSetCache.invalidate(); return Promise.resolve(); }} />
        </Stack>
        <Stack direction="column" flex={[3, 3]} spacing={3}>
          <PointOfInterestGroupInlayMap baseUrlLoadImage="/api/v1/point-of-interest-set" data={data} shouldUseLocalStorage={false} />
          {data.shared
            && <PointOfInterestSharingCard
              addAndRemove
              data={data}
              entityType={t('common:terms.point-of-interest-set', { count: T_ONE })}
              invalidateCache={() => { poiSetCache.invalidate(); return Promise.resolve(); }}
              setRefresh={setRefresh}
            />
          }
          <PointOfInterestDetailList pointOfInterestSet={data} />
        </Stack>

        <DeleteEntityDialog
          display={data.display}
          handleClose={() => setDeleteDialogOpen(false)}
          onDelete={() => restClient.pointOfInterestSet.delete(data._id)}
          onSuccess={() => navigate('/point-of-interest/sets')}
          open={deleteDialogOpen}
        >
          {t('common:component.delete-entity-dialog.hint.delete-warning-short')}
          <ul>
            {getDeleteDialogPoints(data).map((point, i) => <li key={i}><Trans components={[<strong key={i} />]}>{point}</Trans></li>)}
          </ul>
        </DeleteEntityDialog>
      </MarkerProvider>
    );
  };

  const breadcrumbs = (
    <Breadcrumbs>
      <Link
        color="inherit"
        href={href}
        underline="hover"
      >
        {t('common:terms.point-of-interest-set', { count: T_MANY })}
      </Link>
      <Typography color="text.primary">{t('common:page.point-of-interest-set-details.sub-title')}</Typography>
    </Breadcrumbs>
  );

  const loadData = useCallback(
    () => {
      if (!pointOfInterestSet) return poiSetCache.one<PointOfInterestSet>(pointOfInterestSetId);
      return Promise.resolve(pointOfInterestSet);
    },
    [pointOfInterestSetId, refresh, poiSetCache, pointOfInterestSet],
  );

  const handleCreatePoiNavigation = (): void => {
    navigate('/point-of-interest/create', {
      state: {
        poiSetDisplay,
        poiSetId: pointOfInterestSetId,
      },
    });
  };

  const actions = (data: PointOfInterestSet): PageAction[] => {
    const pageActions: PageAction[] = [];

    if (hasEditPermissions && !data.deleted) {
      pageActions.push({
        icon: <PointOfInterestIcon />,
        label: t('common:common.action.add-entity', { entity: t('common:terms.point-of-interest', { count: T_ONE }) }),
        onClick: handleCreatePoiNavigation,
      });

      pageActions.push({
        icon: <DeleteIcon />,
        label: t('common:common.action.delete-entity', { entity: t('common:terms.point-of-interest-set', { count: T_ONE }) }),
        onClick: () => setDeleteDialogOpen(true),
        sx: { color: theme.palette.error.main },
      });
    }

    return pageActions;
  };

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <DetailPage
      key={pointOfInterestSetId}
      actions={actions}
      breadcrumbs={breadcrumbs}
      data-testid="point-of-interest-set-detail-page"
      entityLabel={t('common:terms.point-of-interest-set', { count: T_ONE })}
      loadData={loadData}
      pageIcon={module?.PointOfInterestIcon && <module.PointOfInterestIcon />}
      renderDisplay={({ display }) => display}
      renderPageContent={renderPageContent}
      renderPageTitle={({ display }) => display}
      restoreEntityApi="/api/v1/point-of-interest-set"
      restoreEntityPermissions={isPoiAdmin}
    />
  );
};
