import { Box, Chip } from '@mui/material';
import { FC } from 'react';

interface Props {
  label: string;
  size?: 'small' | 'medium';
  value?: string;
}

export const Label: FC<Props> = ({ label, size = 'small', value }) => {
  return <Box sx={{ whiteSpace: 'nowrap' }}>
    <Chip
      data-testid="label-key"
      label={label}
      size={size}
      sx={{
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
      }}
    />
    <Chip
      data-testid="label-value"
      label={value}
      size={size}
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        borderBottomLeftRadius: 0,
        borderColor: (theme) => theme.palette.action.selected,
        borderTopLeftRadius: 0,
      }}
      variant="outlined"
    />
  </Box>;
};
