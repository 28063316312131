import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { FlexBox } from '../flex-box';
import { MiddleSpinner } from '../middle-spinner';

interface LinkPersonProps {
  assignPerson: (personId: string, role: string) => void;
  isLinked: boolean;
  personId: string;
  thingId: string;
}

export const LinkPerson = ({ assignPerson, isLinked, personId, thingId }: LinkPersonProps): JSX.Element => {
  const { restClient } = useAuthenticated();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const handleClick = (): void => {
    // TODO TP-????: handle personThing roles
    const role = 'driver';
    setLoading(true);
    restClient.personThing.start(personId, thingId, {
      role,
    }).then((response) => {
      assignPerson(response.personId, role);
    }).catch(() => {
      enqueueSnackbar(t('common:component.assign-dialog.hint.unable-to-assign-person'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }).finally(() => setLoading(false));
  };

  if (loading) return <MiddleSpinner size={20} sx={{ justifyContent: 'flex-end', alignSelf: 'center' }} />;

  return (
    <FlexBox sx={{ alignItems: 'center', justifyContent: 'flex-end', width: '20%' }}>
      {isLinked
        ? <CheckCircleIcon sx={{ color: theme.palette.success.light }} />
        : <Button onClick={handleClick} sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>{t('common:common.action.assign')}</Button>
      }
    </FlexBox>
  );
};
