import { Box, Modal } from '@mui/material';
import { FC } from 'react';
import { VideoPlayer } from '../video-player/video-player';
import { videoModalStyles } from './video-modal.styles';

interface Props {
  fileName?: string;
  handleClose: () => void;
  open: boolean;
  url: string;
  'data-testid'?: string;
}

export const VideoModal: FC<Props> = ({ url, handleClose, open, fileName, ...props }) => {
  const { classes } = videoModalStyles();

  return (
    <Modal
      disablePortal
      onClose={handleClose}
      open={open}
      sx={{ zIndex: 1400 }}
      {...props}
    >
      <Box className={classes.videoModal}>
        <VideoPlayer
          controls={{
            play: true,
            pause: true,
            seek: true,
            volume: true,
            fullScreen: true,
            export: true,
            close: true,
          }}
          onModalClick={handleClose}
          url={url}
          fileName={fileName}
          data-testid='video-player-overlay'
        />
      </Box>
    </Modal>
  );
};
