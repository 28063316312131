/* eslint-disable react-hooks/rules-of-hooks */
import { Change } from '@eagle/api-types';
import { RoleFunction } from '@eagle/common';
import { User } from '@eagle/core-data-types';
import { Replay } from '@mui/icons-material';
import { Button, Link, Stack, Typography, useTheme } from '@mui/material';
import Axios from 'axios';
import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Issue } from 'validata';
import { useAuthenticated } from '../../auth';
import { Alert, BottomNavigation, BottomNavigationAction, ConfirmDialog, FetchOne, FormatTimestamp, LoadingButton, MiddleSpinner, useBoolFlag } from '../../components';
import { ErrorMessage, ErrorTitle } from '../../components/error-message';
import { FlexBox } from '../../components/flex-box';
import { useSmallScreen } from '../../hooks/use-small-screen';
import { useTitle } from '../../hooks/use-title';
import { CacheDataTypes, Nullable, Portals, Undefinable } from '../../types';
import { switchToPortal, useHasAuthorization } from '../../util';
import { testid } from '../../util/test-id';
import { NotFound } from '../errors';
import LayoutDesktop from './layout-desktop';
import LayoutMobile from './layout-mobile';
import { ViewProps } from './view.props';

interface DataTyped {
  _id?: string;
  deleted?: Nullable<Change>;
  finish?: Nullable<Change>;
}

const ADMINISTRATOR = [RoleFunction.ADMINISTRATOR] as const;
const IAM_ADMINISTRATOR = [RoleFunction.IAM_ADMINISTRATOR] as const;

/**
  * NATIVE FUNCTION: View component of detail page
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function DetailView<T>({
  actions,
  breadcrumbs,
  data: [data, error, state],
  disabled,
  entityLabel,
  pageAlert,
  pageIcon,
  renderDeletedBannerContent,
  renderDisplay,
  renderPageContent,
  renderPageTitle,
  restoreEntityApi,
  restoreEntityPermissions,
  ...props
}: ViewProps<T>): JSX.Element {
  const { t } = useTranslation(['common']);
  const { axios } = useAuthenticated();
  const smallScreen = useSmallScreen();
  const showRestoreEntityButton = useBoolFlag('portals-restore-entity-feature');
  const theme = useTheme();
  const dataTyped = data as DataTyped;
  const { hasAuthorization } = useHasAuthorization();
  const hasViewUserPermissions = hasAuthorization(ADMINISTRATOR) && hasAuthorization(IAM_ADMINISTRATOR);

  const restoreEntityButton = showRestoreEntityButton && restoreEntityApi && !!dataTyped?._id && restoreEntityPermissions !== false
    ? (
      <ConfirmDialog
        confirmPrompt={<Typography>{t('common:common.hint.confirm')}</Typography>}
        onConfirm={async () => {
          await axios.patch<T>(`${restoreEntityApi}/${dataTyped._id as string}`);
          window.location.reload();
        }}
        initialPrompt={t('common:common.action.restore-entity', { entity: entityLabel })}
        initialPromptIcon={<Replay />}
        sx={{ color: theme.palette.error.main }}
      />
    )
    : undefined;

  const pageActions = (): JSX.Element => {
    const computedActions = typeof actions === 'function' ? data === undefined ? [] : actions(data) : actions;

    if (restoreEntityButton && dataTyped?.deleted) {
      computedActions.push({
        buttonNode: restoreEntityButton,
      });
    }

    if (computedActions.length < 1) return <></>;

    if (!smallScreen) {
      return <>
        {computedActions.map((action, i) => {
          return action.onPromiseClick
            ? <LoadingButton
              key={i}
              data-testid={testid`action-button-${action.label}`}
              disabled={disabled || action.disabled}
              href={action.href}
              startIcon={action.icon}
              sx={{ minHeight: '36px', minWidth: '168px', ...action.sx }}
              task={action.onPromiseClick}
              variant="text"
            >
              {action.label}
            </LoadingButton>
            : action.buttonNode
              ? <Fragment key={i}>{action.buttonNode}</Fragment>
              : <Button
                key={i}
                data-testid={testid`action-button-${action.label}`}
                disabled={disabled || action.disabled}
                href={action.href}
                onClick={action.onClick}
                startIcon={action.icon}
                sx={action.sx}
              >
                {action.label}
              </Button>;
        })}
      </>;
    }
    return (
      <BottomNavigation>
        {computedActions.map((action, i) => {
          if (action.buttonNode) {
            return <Fragment key={i}>{action.buttonNode}</Fragment>;
          }
          return (
            <BottomNavigationAction
              key={i}
              data-testid={testid`action-button-${action.label}`}
              disabled={disabled || action.disabled}
              href={action.href}
              icon={action.icon}
              label={action.label}
              onClick={action?.onPromiseClick ?? action.onClick}
            />
          );
        })}
      </BottomNavigation>
    );
  };

  const renderPageAlert = (): JSX.Element => {
    if (!pageAlert) return <></>;
    if (typeof pageAlert === 'function') return data ? pageAlert(data) : <></>;
    return pageAlert;
  };

  if (state === 'pending') {
    useTitle(t('common:common.labels.loading'));
    return (
      <FlexBox sx={{ height: '200px', justifyContent: 'center', width: '100vw' }}>
        <MiddleSpinner />
      </FlexBox>
    );
  }

  const isIncorrectIdFormat = Boolean(error && isIncorrectIdFormatError(error));

  if (error && !isIncorrectIdFormat) {
    useTitle(<ErrorTitle error={error} />);
    return (
      <Alert severity="error" sx={{ margin: '1rem' }}>
        <ErrorMessage error={error} />
      </Alert>
    );
  }

  if (data === undefined || isIncorrectIdFormat) {
    useTitle(t('common:page.errors.not-found.title'));
    return (
      <FlexBox>
        <NotFound />
      </FlexBox>
    );
  }

  useTitle(renderPageTitle(data));

  const getDeletedByTranslation = (deleted: Change, deletedType: 'deleted' | 'finished'): JSX.Element => {
    const timestamp = <FormatTimestamp key="timestamp" value={deleted.when} format="relative" />;
    switch (deleted.who.type) {
      case 'user':
        return (
          <FetchOne
            dataType={CacheDataTypes.USER}
            id={deleted.who._id}
            renderFactory={(user: User) => (
              <Trans
                components={[
                  hasViewUserPermissions
                    ? <Link
                      key="link"
                      href={switchToPortal(null, Portals.ADMIN, `user/${deleted.who._id}`)}
                    />
                    : <></>,
                  timestamp,
                ]}
                i18nKey={`common:page.details.${deletedType}-by-user.hint`}
                values={{ display: deleted.who.display ?? user.display }}
              />
            )}
          />
        );
      default:
        return (
          <Trans
            components={[timestamp]}
            i18nKey={`common:page.details.${deletedType}-by-${deleted.who.type}.hint`}
          />
        );
    }
  };

  const renderDeletedBanner = (): Undefinable<JSX.Element> => {
    const dataTyped = data as DataTyped;
    if (!dataTyped?.deleted && !dataTyped?.finish) return undefined;
    const deleted = (dataTyped?.deleted ?? dataTyped?.finish) as Change;
    const deletedType = dataTyped?.finish ? 'finished' : 'deleted';

    return (
      <Alert severity="info" sx={{ width: '100%' }} data-testid="deleted-alert-banner">
        <Stack spacing={1}>
          <Typography>
            {renderDeletedBannerContent?.(data)
              ?? <Trans
                components={[<b key={renderDisplay?.(data)} />, <i key="read-only" />]}
                i18nKey={`common:page.details.${deletedType}.hint`}
                values={{ display: renderDisplay?.(data) }}
              />
            }
          </Typography>
          <Typography>
            {getDeletedByTranslation(deleted, deletedType)}
          </Typography>
        </Stack>
      </Alert >
    );
  };

  if (smallScreen) {
    return (
      <LayoutMobile
        data-testid={props['data-testid']}
        actions={pageActions()}
        deletedBanner={renderDeletedBanner()}
      >
        {renderPageAlert()}
        {renderPageContent(data)}
      </LayoutMobile>
    );
  }

  return (
    <LayoutDesktop
      actions={pageActions()}
      data-testid={props['data-testid']}
      deletedBanner={renderDeletedBanner()}
      pageAlert={renderPageAlert()}
      pageIcon={pageIcon}
      pageSubtitle={breadcrumbs}
      pageTitle={renderPageTitle(data)}
    >
      {renderPageContent(data)}
    </LayoutDesktop>
  );
}

const isIncorrectIdFormatError = (error: Error): boolean => {
  if (!Axios.isAxiosError(error)) {
    return false;
  }
  const issue = (error.response?.data as { issues?: Issue[] }).issues?.[0];
  if (!issue || issue.path[0] !== ':') {
    return false;
  }
  return issue.reason === 'incorrect-format';
};
