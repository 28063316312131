import { InputProps as MuiInputProps } from '@mui/material';
import { InputLabelProps } from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';

interface Props {
  'data-testid'?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  inputLabelProps?: Partial<InputLabelProps>;
  InputProps?: MuiInputProps;
  label?: string;
  max?: number;
  min?: number;
  onChange: (value: number) => Promise<void>;
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  step?: number;
  value: string | number;
}

export const NumberEditor: FC<Props> = ({
  disabled = false,
  error,
  helperText,
  inputLabelProps,
  InputProps,
  label,
  max,
  min,
  onChange,
  placeholder,
  required,
  size,
  step,
  value: initialValue,
  ...props
}) => {
  const [value, setValue] = useState<string>(String(initialValue));
  const [lastSavedValue, setLastSavedValue] = useState<string>(String(initialValue));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue(String(initialValue));
    setLastSavedValue(String(initialValue));
  }, [initialValue]);

  const onValueInputBlur = (): void => {
    if (lastSavedValue === value || value === '') {
      setValue(lastSavedValue);
      return;
    }

    setIsLoading(true);
    void onChange(Number(value))
      .then(() => {
        setLastSavedValue(value);
      }).catch().finally(() => {
        setIsLoading(false);
      });
  };

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLInputElement;
    if (event.key === 'Enter') {
      target.blur();
    }
  };

  const onValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  return (
    <TextField
      data-testid={props['data-testid'] ?? 'number-input'}
      disabled={disabled || isLoading}
      error={error}
      helperText={helperText}
      InputLabelProps={inputLabelProps}
      InputProps={InputProps}
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', max, min, step }}
      label={label}
      onBlur={onValueInputBlur}
      onChange={onValueChange}
      onKeyDown={onInputKeyDown}
      placeholder={placeholder}
      required={required}
      size={size}
      type="number"
      value={value}
    />
  );
};
