import { AlertColor, AlertTitle, Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { Alert } from '../../components/alert';
import { useHistorySearch } from '../../components/entity-journey/use-history-search';
import useSmallScreen from '../../hooks/use-small-screen';

interface Props {
  elevation?: number;
  message: JSX.Element | string;
  severity?: AlertColor;
  sx?: SxProps;
  title: JSX.Element | string;
}

export const EventHistoryAlert: FC<Props> = ({ elevation = 3, message, severity = 'info', sx, title }) => {
  const smallScreen = useSmallScreen();
  const { entityItems } = useHistorySearch();

  if (!smallScreen || entityItems.length) return <></>;

  return (
    <Box sx={sx}>
      <Alert elevation={elevation} severity={severity}>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Box>
  );
};
