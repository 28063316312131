import { Box, ClickAwayListener, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { useSmallScreen } from '../../hooks';
import { Nullable } from '../../types';

interface DataRowProps {
  action?: JSX.Element;
  'data-testid'?: string;
  description?: Nullable<string>;
  label?: ReactNode;
  value: ReactNode;
}

export const DataRow: FC<DataRowProps> = ({ action, description, label, value, ...props }): JSX.Element => {
  const smallScreen = useSmallScreen();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleTooltipClose = (): void => setOpen(false);
  const handleTooltipOpen = (): void => setOpen(true);

  const desktopLabel = <>
    {description
      ? <Tooltip title={description} placement={'right'}>
        <Typography color="text.secondary" variant="caption" sx={{ lineHeight: 1, verticalAlign: 'top' }}>{label}</Typography>
      </Tooltip>
      : <Typography color="text.secondary" variant="caption" sx={{ lineHeight: 1, verticalAlign: 'top' }}>{label}</Typography>
    }
  </>;

  const mobileLabel = (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div onClick={handleTooltipOpen}>
        {description
          ? <Tooltip
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={handleTooltipClose}
            open={open}
            placement={'right'}
            PopperProps={{
              disablePortal: true,
            }}
            title={description}
          >
            <Typography color="text.secondary" variant="caption" sx={{ lineHeight: 1, verticalAlign: 'top' }}>{label}</Typography>
          </Tooltip>
          : <Typography color="text.secondary" variant="caption" sx={{ lineHeight: 1, verticalAlign: 'top' }}>{label}</Typography>
        }
      </div>
    </ClickAwayListener>
  );

  return <>
    <Paper
      data-testid={props['data-testid']}
      elevation={0}
      sx={{
        alignItems: 'center',
        backgroundColor: theme.palette.grey[100],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        overflowX: 'hidden',
        p: 1,
        width: '100%',
      }}
    >
      <Stack sx={{ '&': { width: '100%' } }}>
        <Box data-testid='row-label' sx={{ lineHeight: 1, mb: 0.5 }}>
          {smallScreen ? mobileLabel : desktopLabel}
        </Box>
        <Box data-testid='row-value' sx={{ lineHeight: 1.2, overflowX: 'auto', overflowY: 'hidden' }}>
          {value}
        </Box>
      </Stack>
      {action}
    </Paper>
  </>;
};
