import { Stack } from '@mui/material';
import { FC } from 'react';
import { useSmallScreen } from '../../hooks';

export const FormatStack: FC<{ left: JSX.Element; right: JSX.Element }> = ({ left, right }) => {
  const smallScreen = useSmallScreen();

  if (smallScreen) {
    return (
      <Stack sx={{ '& > div': { my: 0.5 }, m: 1, width: '100%' }}>
        {left}
        {right}
      </Stack>
    );
  }

  return (
    <>
      <Stack sx={{ '& > div': { my: 0.5 }, m: 1, width: '100%' }}>{left}</Stack>
      <Stack sx={{ '& > div': { my: 0.5 }, m: 1, width: '100%' }}>{right}</Stack>
    </>
  );
};
