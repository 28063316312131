export const getMapLanguageCode = (userLang: string, isSouthKorea?: boolean): string => {
  const langMap: { [key: string]: string } = {
    'ar': 'ara',
    'eu': 'baq',
    'ca': 'cat',
    'zh': 'zh', // Assuming 'zh' for both simplified and traditional Chinese
    'cs': 'cze',
    'da': 'dan',
    'nl': 'dut',
    'en': 'eng',
    'fi': 'fin',
    'fr': 'fre',
    'de': 'ger',
    'ga': 'gle',
    'el': 'gre',
    'he': 'heb',
    'hi': 'hin',
    'id': 'ind',
    'it': 'ita',
    'ko': 'kor',
    'no': 'nor',
    'fa': 'per',
    'pl': 'pol',
    'pt': 'por',
    'ru': 'rus',
    'si': 'sin',
    'es': 'spa',
    'sv': 'swe',
    'tr': 'tur',
    'uk': 'ukr',
    'ur': 'urd',
    'vi': 'vie',
    'cy': 'wel',
  };

  // Convert userLang to lowercase and split in case of regional variations like en-US
  const baseLang = userLang.toLowerCase().split('-')[0];

  if (isSouthKorea) {
    return langMap[baseLang] || baseLang; // Use mapped language or base language if not found
  }
  return baseLang; // Use base language directly
};

export const addMapLangParams = (url: string, userLang: string, isSouthKorea = false): string => {
  const langCode = getMapLanguageCode(userLang, isSouthKorea);
  return `${url}&${isSouthKorea ? 'lg' : 'lang'}=${langCode}`;
};
