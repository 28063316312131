import { ThingEventSnapshot } from '@eagle/core-data-types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Collapse, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, FormatTimestamp, MiddleSpinner } from '../../components';
import { COLLAPSE_DEFAULT_TIME, T_ONE } from '../../constants';
import { useSearch } from '../../pages/list/use-search';
import { EntryDataBody, EventTableCategory, EventTableEventIcon } from './util';

interface LayoutDesktopProps {
  events: ThingEventSnapshot[];
  hideCategory: boolean;
  isLoading: boolean;
  paginationComponent?: JSX.Element;
  rowChildren?: (event: ThingEventSnapshot) => JSX.Element;
  tableHeight: string;
}

const Row: FC<{ event: ThingEventSnapshot; hideCategory: boolean; rowChildren?: (event: ThingEventSnapshot) => JSX.Element }> = ({ event, hideCategory, rowChildren }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return <>
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell sx={{ width: '5%', py: 0, pr: 0, pl: 1, verticalAlign: 'center' }} >
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row" sx={{ verticalAlign: 'top' }}>
        <EventTableEventIcon event={event} />
      </TableCell>
      {!hideCategory && <TableCell sx={{ p: 0 }}> <EventTableCategory eventTypeId={event.eventTypeId} feature={event.feature} /> </TableCell>}
      <TableCell sx={{ p: 0 }}> {<FormatTimestamp value={event.occurred} format="shortWithSeconds" />} </TableCell>
    </TableRow>
    <TableRow sx={{ transition: `borderBottom ${COLLAPSE_DEFAULT_TIME}ms linear` }}>
      <TableCell colSpan={6} sx={{ '&': { borderBottom: open ? `1px solid ${chroma(theme.palette.common.black).alpha(0.12).css()}` : 'none' }, py: 0, px: 1 }}>
        <Collapse in={open} unmountOnExit sx={{ '.MuiCollapse-wrapperInner': { display: 'flex' } }}>
          <Stack direction="column" sx={{ width: '100%' }}>
            <Stack direction="row">
              <EntryDataBody event={event} />
            </Stack>
            {rowChildren && rowChildren(event)}
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  </>;
};

export const EventsTableDesktopView: FC<LayoutDesktopProps> = ({ events, hideCategory, isLoading, paginationComponent, rowChildren, tableHeight }) => {
  const { t } = useTranslation(['common']);
  const { filters } = useSearch();

  if (!events || !events.length) {
    if (isLoading) {
      return (
        <Stack sx={{ minHeight: tableHeight }}>
          <MiddleSpinner sx={{ height: 'auto', pt: '140px' }} />
        </Stack>
      );
    }
    return (
      <Stack sx={{ minHeight: tableHeight }}>
        {filters.length
          ? <Alert severity="info" sx={{ m: 3 }} >{t('common:component.events.thing-event.labels.table.filter-no-results')}</Alert>
          : <Alert severity="info" sx={{ m: 3 }} >{t('common:component.events.thing-event.labels.table.filter-no-selection')}</Alert>
        }
      </Stack>
    );
  }

  return (
    <TableContainer sx={{ minHeight: tableHeight }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ pr: 0, py: 1 }}>{t('common:component.events-table.labels.event-type', { count: T_ONE })}</TableCell>
            {!hideCategory && <TableCell sx={{ px: 0, py: 1 }}>{t('common:component.events.labels.category')}</TableCell>}
            <TableCell sx={{ px: 0, py: 1 }}>{t('common:terms.date-time')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
          {events.map((event) => <Row key={event.eventId} event={event} hideCategory={hideCategory} rowChildren={rowChildren} />)}
        </TableBody>
      </Table>
      {paginationComponent
        ? <Box display="flex" justifyContent="center" marginY={1}>
          {paginationComponent}
        </Box>
        : null
      }
    </TableContainer>
  );
};
