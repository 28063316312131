import { Undefinable } from '../../types';
import { CommonEntityWithDeleted } from '../../types/entity';
import { EntityField, SelectedFilterType } from '../entity-search/types';
import { FilterInputLookupMultiple } from './filter-input-lookup-multiple';
import { FilterInputLookupMultipleNew } from './filter-input-lookup-multiple-new';
import { FilterInputLookupSingle } from './filter-input-lookup-single';

interface Props<T> {
  appliedFilters: EntityField[];
  'data-testid'?: string;
  findEntity: Undefinable<(search: string) => Promise<T[]>>;
  isApplied: (value: EntityField) => boolean;
  isSearch?: boolean;
  label?: string;
  noResults?: string;
  onChanged: (value: SelectedFilterType) => void;
  selectedValue?: SelectedFilterType;
  selectMultiple: boolean;
  useLookup?: boolean;
}

/**
  * NATIVE FUNCTION: Filter lookup component
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function FilterInputLookup<T extends CommonEntityWithDeleted>({
  appliedFilters,
  findEntity,
  isSearch,
  label,
  noResults,
  onChanged,
  selectedValue,
  selectMultiple,
  useLookup,
  ...props
}: Props<T>): JSX.Element {
  if (!findEntity) return <></>;

  if (selectMultiple) {
    if (useLookup) {
      return (
        <FilterInputLookupMultipleNew<T>
          appliedFilters={appliedFilters}
          data-testid={props['data-testid']}
          findEntity={findEntity}
          isSearch={isSearch}
          label={label}
          noResults={noResults}
          onChanged={onChanged}
        />
      );
    }
    return (
      <FilterInputLookupMultiple<T>
        appliedFilters={appliedFilters}
        data-testid={props['data-testid']}
        findEntity={findEntity}
        label={label}
        onChanged={onChanged}
        selectedValue={selectedValue}
      />
    );
  }

  return (
    <FilterInputLookupSingle<T>
      appliedFilters={appliedFilters}
      data-testid={props['data-testid']}
      findEntity={findEntity}
      isSearch={isSearch}
      label={label}
      noResults={noResults}
      onChanged={onChanged}
    />
  );
}
