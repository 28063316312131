import { Box, useTheme } from '@mui/material';
import cx from 'classnames';
import { FC, PropsWithChildren, RefObject } from 'react';
import { createUseStyles } from 'react-jss';
import { SIDEBAR_FULL_WIDTH, SIDEBAR_WIDTH } from '../../constants';
import { Nullable } from '../../types';
import { FocusWithin } from '../../util/focus-within';

const useStyles = createUseStyles({
  footer: {
    display: 'flex',
    bottom: 0,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.69)',
    zIndex: 10,
  },
  header: {
    display: 'flex',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.69)',
    top: 0,
    zIndex: 10,
  },
  root: {
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
    '& .MenuItem-root .text': {
      minWidth: 275 - 72,
      width: 275 - 72,
    },
    '& .MuiBox-root .avatar': {
      minWidth: 275 - 72,
      width: 275 - 72,
    },
    bottom: 0,
    boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.35)',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    overflowX: 'hidden',
    overflowY: 'visible',
    position: 'absolute',
    top: 0,
    transition: [['opacity', '250ms'], ['transform', '250ms'], ['width', '250ms', 'ease']],
  },
  static: {
    height: '100%',
    '& >.MuiBox-root': {
      height: '100%',
    },
    '& .sidebar': {
      height: '100%',
      position: 'relative',
      boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.35)',
    },
  },
});

interface Props extends PropsWithChildren {
  className?: string;
  drawerOpen: boolean;
  drawerRef: Nullable<RefObject<HTMLDivElement>>;
  drawerWidthCollapsed?: number;
  drawerWidthExpanded?: number;
  onDrawerClose?: () => void;
  onDrawerOpen?: () => void;
  pinSidebar: boolean;
}

export const Sidebar: FC<Props> = ({
  children,
  className,
  drawerOpen,
  drawerRef,
  drawerWidthCollapsed = SIDEBAR_WIDTH,
  drawerWidthExpanded = SIDEBAR_FULL_WIDTH,
  onDrawerClose,
  onDrawerOpen,
  pinSidebar,
}) => {
  const classes = useStyles();
  const rootClassName = cx('sidebar', className, classes.root);
  const sidebarClassName = cx('sidebar', className, classes.static);
  const theme = useTheme();
  const sidebarWidth = pinSidebar ? drawerWidthExpanded : drawerOpen ? drawerWidthExpanded : drawerWidthCollapsed;

  return (
    <FocusWithin
      onBlur={onDrawerClose}
      onFocus={onDrawerOpen}
    >
      <Box id='domain-sidebar-container' className={pinSidebar ? sidebarClassName : undefined}>
        <Box id='domain-sidebar-content'>
          <Box
            data-testid="sidebar"
            ref={drawerRef}
            className={rootClassName}
            display="flex"
            onMouseEnter={onDrawerOpen}
            onMouseLeave={onDrawerClose}
            sx={{
              backgroundColor: theme.sidebar.background,
              color: theme.sidebar.contrastText,
              width: sidebarWidth,
              zIndex: theme.zIndex.drawer + 101,
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </FocusWithin>
  );
};
