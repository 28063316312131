import { AlertResponse } from '@eagle/core-data-types';
import { ThingEvent } from '@eagle/data-function-types';
import { Operator } from '@jexop/core';
import { featureActivityIsStartedOperator, latestEventOperator } from './data-feature';
import { translate } from './translate';

export const boolMacros: Record<string, Operator<boolean>> = {
  /**
   * Macro for consistently calculating if the thing has power on.
   * @example
   * { "op": "macro:is-power-on" }
   *
   * @returns A boolean indicating power on.
   */
  ['macro:is-power-on']: (_, context) =>
    featureActivityIsStartedOperator({
      feature: 'tracking',
      startEventTypeId: 'power-on',
      finishEventTypeId: 'power-off',
    }, context),

  /**
   * Macro for consistently calculating if the thing is moving.
   * @example
   * { "op": "macro:is-moving" }
   *
   * @returns A boolean indicating moving.
   */
  ['macro:is-moving']: (_, context) => {
    const result = latestEventOperator({
      feature: 'tracking',
      havingPath: 'data.moving',
      path: 'data.moving',
    }, context);
    return result === null ? null : !!result;
  },
};

export const stringMacros: Record<string, Operator<string>> = {
  /**
   * Macro for fetching the alert event translation label
   * @example
   * { "op": "macro:alert-event-description" }
   *
   * @returns A string of the alerts event description.
   */
  ['macro:alert-event-description']: (_, context: AlertResponse) => {
    const translation = `common:event-descriptions-v2.${context.featureTypeId}.${context.alertTypeId}.label`;
    const replace = { features: translate({ translation: `common:features.${context.feature}` }) };
    return translate({ translation, replace }, context);
  },
  /**
   * Macro for fetching the events translation label
   * @example
   * { "op": "macro:event-description" }
   *
   * @returns A string of the event description.
   */
  ['macro:event-description']: (props, context: ThingEvent) => {
    const lastEvent = props.lastEvent as ThingEvent ?? context;
    const translation = `common:event-descriptions-v2.${lastEvent.featureTypeId}.${lastEvent.eventTypeId}.label`;
    return translate({ translation }, lastEvent);
  },

};
