/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material';
import axios from 'axios';
import L from 'leaflet';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';
import Tangram from 'tangram';
import { HERE_MAP_API_KEY } from '../../constants';
import { useConfig, usePromise } from '../../hooks';

interface CopyrightDataItem {
  alt: string;
  boxes: number[][];
  label: string;
  maxLevel: number;
  minLevel: number;
}

interface CopyrightResponse {
  base: CopyrightDataItem[];
  core: CopyrightDataItem[];
}

const COPYRIGHT_API_URL = 'https://vector.hereapi.com/v2/vectortiles/copyright';
const TERMS_OF_SERVICE_URL = 'https://legal.here.com/en-gb/terms/general-content-supplier-terms-and-notices';

export const VectorBaseMap: FC<{ mapStyle: Record<string, unknown> }> = ({ mapStyle: scene }) => {
  const { t } = useTranslation(['common']);
  const config = useConfig();
  const theme = useTheme();
  const apiKey = config?.hereMaps?.apiKey ?? HERE_MAP_API_KEY;
  const [labels, setLabels] = useState<string[]>([]);
  const [copyrightDataToDisplay, setCopyrightDataToDisplay] = useState<CopyrightDataItem[]>([]);
  const [refresh, setRefresh] = useState(new Date());
  const termsString = `<a href=${TERMS_OF_SERVICE_URL} rel="noopener" style="color: ${theme.palette.primary.main}" target="blank">${t('common:component.map.hint.terms')}</a>`;
  const baseCopyrightString = t('common:component.map.labels.copyright-vector-base-map', { year: new Date().getFullYear() });
  const map = useMap();

  useEffect(() => {
    const tangram = Tangram.leafletLayer({ scene, pane: 'overlayPane' });
    tangram.addTo(map);
    return () => {
      tangram.remove();
    };
  }, [map, scene]);

  const [copyrightData] = usePromise<CopyrightResponse>(
    async () => {
      const result = await axios.get<CopyrightResponse>(COPYRIGHT_API_URL, {
        params: {
          apiKey,
        },
      });
      return result.data;
    },
    [axios],
  );

  const copyrightDataForZoomLevel = (zoom: number): CopyrightDataItem[] => {
    if (!copyrightData?.base) return [];
    return copyrightData.base.filter(({ minLevel, maxLevel }) => zoom >= minLevel && zoom <= maxLevel);
  };

  const parseBox = (box: number[]): L.LatLngBounds => {
    const southWest = new L.LatLng(box[0], box[1]);
    const northEast = new L.LatLng(box[2], box[3]);
    return new L.LatLngBounds(southWest, northEast);
  };

  const handleUpdate = (): void => {
    setRefresh(new Date());
  };

  useEffect(() => {
    const data = copyrightDataForZoomLevel(map.getZoom());
    const bounds = map.getBounds();
    setCopyrightDataToDisplay(
      data.filter((item) =>
        item.boxes.some((box) => bounds.contains(parseBox(box)) || bounds.overlaps(parseBox(box))),
      ),
    );
  }, [refresh]);

  useEffect(() => {
    labels.forEach((label) => map.attributionControl.removeAttribution(label));
    const newLabels: string[] = [];
    copyrightDataToDisplay.forEach(({ label }) => {
      newLabels.push(label);
      map.attributionControl.addAttribution(label);
    });
    setLabels(newLabels);
  }, [copyrightDataToDisplay]);

  useEffect(() => {
    map.attributionControl.setPrefix(termsString);
    map.attributionControl.addAttribution(baseCopyrightString);
    handleUpdate();
  }, [scene]);

  useEffect(() => {
    handleUpdate();
  }, [copyrightData]);

  useEffect(() => {
    map.on('moveend', handleUpdate);
  }, []);

  return null;
};
