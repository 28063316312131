import { Box, Container, Stack } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface Props extends PropsWithChildren {
  actions: ReactNode;
  deletedBanner?: JSX.Element;
  pageSubtitle?: JSX.Element;
  'data-testid'?: string;
}

const LayoutMobile: FC<Props> = ({ actions, children, deletedBanner, ...props }) => {
  return (
    <Box display="flex" flexDirection="column" flexWrap="wrap" sx={{ width: '100%', height: 1 }}>
      <Container id="detail-page" sx={{ flexGrow: 1, flexBasis: 0, overflowX: 'hidden', overflowY: 'auto' }}>
        <Stack id="content" className="detail-content" display="flex" flexDirection="column" flexShrink={1} spacing={2} sx={{ my: 2 }} data-testid={props['data-testid']}>
          {deletedBanner}
          {children}
        </Stack>
      </Container>
      {actions}
    </Box>
  );
};

export default LayoutMobile;
