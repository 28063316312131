import { makeStyles } from '../../theme';

export const modalStyles = makeStyles()(() => ({
  betweenTime: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 0.5em 0 0.5em',
  },
  calendarPicker: {
    overflow: 'hidden',
    marginTop: '-0.5rem',
    maxHeight: '330px',
    '& .MuiCalendarPicker-viewTransitionContainer': {
      overflowY: 'hidden',
    },
  },
  center: {
    textAlign: 'center',
  },
  container: {
    padding: '0em 1em 1em 1em',
  },
  containerMobile: {
    padding: '0em 1em 1em 1em',
  },
  desktopTimeTextField: {
    '& .MuiOutlinedInput-input': {
      paddingRight: 0,
    },
    '& .MuiInputBase-root': {
      paddingRight: '0.5em',
      '& .MuiInputAdornment-root': {
        marginLeft: 0,
      },
    },
  },
  fixedWidth: {
    width: '20em',
  },
  headings: {
    paddingTop: '1em',
    display: 'flex',
    justifyContent: 'space-between',
  },
  justifyLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  mobileTimeTextField: {
    '& .MuiInputBase-root': {
      paddingRight: '0em',
      '& .MuiInputAdornment-root': {
        marginLeft: 0,
      },
    },
  },
  paddingButton: {
    padding: '0em 0 0.5em 0',
  },
  smallMobile: {
    overflow: 'hidden',
  },
  timePicker: {
    padding: '0em',
    position: 'relative',
  },
  timeSpacing: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '0.5em',
  },
}));
