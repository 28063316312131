
const stringify = (value: unknown): string => {
  if (value === undefined || value === null) return '';
  if (typeof value === 'string') return value;
  // TODO handle other cases
  if (typeof value === 'object') {
    if (value instanceof Date) return value.toISOString();
    return JSON.stringify(value);
  }
  return String(value);
};

export const testid = (strings: TemplateStringsArray, ...args: unknown[]): string => {
  const parts = strings.reduce<string[]>((acc, part, i) => {
    if (args.length > i) {
      return [...acc, part, stringify(args[i])];
    }
    return [...acc, part];
  }, []);
  return parts.join('').toLowerCase().replace(/[^a-z0-9]+/g, '-');
};
