import { RoleFunction } from '@eagle/common';
import { TabContext } from '@mui/lab';
import { Box, Card, CardContent, Divider, Stack } from '@mui/material';
import { FC } from 'react';
import { useAuthenticated } from '../../auth';
import { MediaTabs } from './media-data.types';
import { NoPermission } from './no-permission';

interface Props {
  'data-testid'?: string;
  displayOnCard: boolean;
  mediaOverlay: JSX.Element;
  selectedTab: MediaTabs;
  tabPanels: JSX.Element;
  tabs: JSX.Element;
  viewButton: JSX.Element;
}

export const MediaCardLayoutMobile: FC<Props> = ({
  displayOnCard,
  mediaOverlay,
  selectedTab,
  tabPanels,
  tabs,
  viewButton,
  ...props
}) => {
  const { userInfo } = useAuthenticated();
  const hasMediaPermissions = userInfo.hasRoleFunction(RoleFunction.MEDIA_VIEWER);
  const hideCardProperties = !displayOnCard ? { boxShadow: 'none' } : {};

  if (!hasMediaPermissions) return <NoPermission sx={hideCardProperties} />;

  return (
    <Card data-testid={props['data-testid']} sx={hideCardProperties}>
      <CardContent data-testid="card-content" sx={{ p: 0 }}>
        <TabContext value={selectedTab}>
          <Stack>
            {tabs}
            <Divider />
          </Stack>
          {tabPanels}
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: 1 }}>
            {viewButton}
          </Box>
          {mediaOverlay}
        </TabContext>
      </CardContent>
    </Card>
  );
};
