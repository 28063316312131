/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { extension, types } from 'mime-types';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe, Nullable, Undefinable } from '../../types';
import { FileUpload } from './upload';

interface Props {
  disablePreview?: boolean;
  onChange?: (image: Undefinable<File>, previewImage: Maybe<string | ArrayBuffer>) => void;
}

const IMAGES = [types.gif, types.png, types.jpeg, types.svg] as const;
const IMAGE_TYPES = IMAGES.map((value) => value.split('/')[1]);

export const extractImageBase64String = (image: Maybe<string | ArrayBuffer>): Nullable<string> => {
  const imageString = image as string;
  if (!image || !imageString.includes('base64')) return null;
  return imageString.split('base64,')[1];
};

export const MediaUpload: FC<Props> = ({ disablePreview = false, onChange }) => {
  const { t } = useTranslation(['common']);
  const fileReader = new FileReader();
  const [file, setFile] = useState<Undefinable<File>>();
  const [image, setImage] = useState<Maybe<string | ArrayBuffer>>();

  useEffect(() => {
    if (file) return fileReader.readAsDataURL(file);
    setImage(undefined);
    onChange?.(undefined, undefined);
  }, [file]);

  useEffect(() => {
    fileReader.addEventListener('load', () => {
      setImage(fileReader.result);
      onChange?.(file, fileReader.result);
    }, { once: true });

    return () => fileReader.removeEventListener('load', () => { });
  }, [fileReader]);

  const listOfTypes = IMAGE_TYPES.slice(0, -1).join(', .');
  const lastType = IMAGE_TYPES[IMAGE_TYPES.length - 1];

  const helperText = t('common:component.file-uploader.hint.supported-types',
    { mimeTypes: `.${listOfTypes}${t('common:component.filter.labels.and')}.${lastType}` },
  );

  return (
    <Stack direction="column">
      <FileUpload
        errorText={(mimeType) => t('common:component.file-uploader.hint.error', { mimeType: extension(mimeType ?? '') })}
        helperText={helperText}
        onChange={(userFile) => setFile(userFile)}
        mime={IMAGES}
      />
      {typeof image === 'string' && !disablePreview && <img src={image} />}
    </Stack>
  );
};
