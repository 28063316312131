/* eslint-disable react-hooks/rules-of-hooks */
import { FeatureTypes, ThingEvent } from '@eagle/data-function-types';
import CircleIcon from '@mui/icons-material/Circle';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BottomNavigationAction, Button, Chip, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEventFeatureV2, getEventHint, getEventLabelV2, getEventTranslation } from '../../util/events';
import { getEventDictionary } from '../entity-journey';
import { EntryDataBody } from '../events-table';
import { useBoolFlag } from '../flags';
import { InfoIcon } from '../icons';
import { EventDialogTabs } from './event-dialog-mobile';
import { EventDialogView } from './event-dialog-view';

interface Props<T> {
  closeDialog: () => void;
  'data-testid'?: string;
  eventIndex: number;
  events: T[];
  open: boolean;
  renderMediaComponent?: (data: T) => JSX.Element;
  setEventIndex: (index: number) => void;
}

/**
  * NATIVE FUNCTION: Controller component of event dialog
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function EventDialogController<T extends Omit<ThingEvent, 'schema'>>({
  closeDialog,
  eventIndex,
  events,
  open,
  renderMediaComponent,
  setEventIndex,
  ...props
}: Props<T>): JSX.Element {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(EventDialogTabs.EVENT);
  const isHintEnabled = useBoolFlag('portals-event-translation-hints-feature');
  const flagUseEventV3 = useBoolFlag('portals-translation-retrieval-hook-for-event-and-alert-descriptions-feature');

  const navigatePrevious = (): void => {
    if (eventIndex <= 0) return;
    setEventIndex(eventIndex - 1);
  };

  const navigateNext = (): void => {
    if (eventIndex >= events.length - 1) return;
    setEventIndex(eventIndex + 1);
  };

  const renderTitle = (event: T): JSX.Element => {
    const { eventTypeId, feature, featureTypeId } = event;
    const eventStyles = getEventDictionary({ ...event, inserted: DateTime.now().toJSDate() }, theme);
    const { label, hint, featureInstance, featureLabel } = flagUseEventV3 ? getEventTranslation(feature, eventTypeId) : { label: getEventLabelV2(featureTypeId, eventTypeId), hint: getEventHint(feature, eventTypeId), featureInstance: undefined, featureLabel: getEventFeatureV2(feature) };
    const primary = featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? featureLabel : label;
    const secondary = featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? label : featureLabel;

    return (
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', ml: 1 }}>
        <CircleIcon sx={{ color: eventStyles?.styling.backgroundColor }} />
        <Stack>
          <Typography variant="body2">{primary}</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography data-testid="event-type-description">
              {secondary}
            </Typography>
            {featureInstance && <Chip label={featureInstance} variant="outlined" size="small" />}
            {isHintEnabled && hint && (
              <Tooltip title={hint} arrow data-testid="feature-instance-tooltip">
                <IconButton size="small" aria-label="info">
                  <InfoIcon fontSize="small" color="action" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const renderEventData = (event: T): JSX.Element => (
    <Stack direction="row">
      <EntryDataBody event={event} />
    </Stack>
  );

  const renderActions = (smallScreen: boolean): JSX.Element[] => {
    const previousDisabled = eventIndex <= 0;
    const nextDisabled = eventIndex >= events.length - 1;

    if (smallScreen) {
      return [
        <BottomNavigationAction
          data-testid="previous-button"
          key="navigatePrevious"
          disabled={previousDisabled}
          icon={<NavigateBeforeIcon color={previousDisabled ? 'inherit' : 'primary'} />}
          onClick={navigatePrevious}
          sx={{ maxWidth: 'none' }}
        />,
        <BottomNavigationAction
          data-testid="next-button"
          key="navigateNext"
          disabled={nextDisabled}
          icon={<NavigateNextIcon color={nextDisabled ? 'inherit' : 'primary'} />}
          onClick={navigateNext}
          sx={{ maxWidth: 'none' }}
        />,
      ];
    }

    return [
      <Button
        data-testid="previous-button"
        key="navigatePrevious"
        disabled={previousDisabled}
        onClick={navigatePrevious}
        startIcon={<NavigateBeforeIcon />}
      >
        {t('common:common.action.previous')}
      </Button>,
      <Button
        data-testid="next-button"
        key="navigateNext"
        disabled={nextDisabled}
        endIcon={<NavigateNextIcon />}
        onClick={navigateNext}
      >
        {t('common:common.action.next')}
      </Button>,
    ];
  };

  return (
    <EventDialogView
      data-testid={props['data-testid']}
      closeDialog={closeDialog}
      data={events}
      eventIndex={eventIndex}
      open={open}
      renderActions={renderActions}
      renderEventData={renderEventData}
      renderMediaComponent={renderMediaComponent}
      renderTitle={renderTitle}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    />
  );
}
