import { Button, Collapse, Stack } from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StageConfirmDialogCard } from './stage-confirm-dialog-card';
import { useStageConfirmDialogContext } from './stage-confirm-dialog-context';

interface Props {
  setIsNextButtonDisabled: Dispatch<SetStateAction<boolean>>;
}

export const StageConfirmDialogEditStep: FC<Props> = ({ setIsNextButtonDisabled }) => {
  const { t } = useTranslation(['admin']);
  const [showMore, setShowMore] = useState(false);
  const {
    state,
  } = useStageConfirmDialogContext();

  const stakeholdersWithoutInitialValue = state.stakeholders.filter((stakeholder) => !stakeholder.initialAccountId);
  const stakeholdersWithInitialValue = state.stakeholders.filter((stakeholder) => Boolean(stakeholder.initialAccountId));

  const hasNoStakeholderWithoutInitialValue = stakeholdersWithoutInitialValue.length === 0;

  return (
    <Stack spacing={2} >
      {stakeholdersWithoutInitialValue.map((stakeholder) => (
        <StageConfirmDialogCard
          key={stakeholder.role}
          stakeholderState={stakeholder}
          setIsNextButtonDisabled={setIsNextButtonDisabled}
        />
      ))}

      {stakeholdersWithInitialValue.length > 0 && (
        <>
          {!hasNoStakeholderWithoutInitialValue && (
            <Button
              data-testid="stage-dialog-show-more-button"
              color="primary"
              onClick={() => setShowMore((val) => !val)}
              size="small"
            >
              {t(showMore ? 'admin:page.thing-detail-update-stage-dialog.show-less.labels' : 'admin:page.thing-detail-update-stage-dialog.show-more.labels')}
            </Button>
          )}
          <Collapse in={showMore || hasNoStakeholderWithoutInitialValue}>
            <Stack spacing={2}>
              {stakeholdersWithInitialValue.map((stakeholder) => (
                <StageConfirmDialogCard
                  key={stakeholder.role}
                  stakeholderState={stakeholder}
                  setIsNextButtonDisabled={setIsNextButtonDisabled}
                />
              ))}
            </Stack>
          </Collapse>
        </>
      )}

    </Stack>
  );
};
