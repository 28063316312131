import ClearIcon from '@mui/icons-material/Clear';
import FilterIcon from '@mui/icons-material/FilterAltRounded';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Card, Collapse, Divider, Drawer, Fade, IconButton, InputAdornment, InputBase, Paper, Stack } from '@mui/material';
import { TFunction } from 'i18next';
import { DomEvent } from 'leaflet';
import { FC, KeyboardEvent as ReactKeyboardEvent, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import { SIDEBAR_FULL_WIDTH, SIDEBAR_WIDTH } from '../../constants';
import { useCustomRoutes, useSidebarPinContext, useSmallScreen } from '../../hooks';
import { useSearch } from '../../pages/list/use-search';
import { Badge } from '../badge';

interface Props {
  disableFilter: boolean;
  filterDrawerContent: JSX.Element;
  filtersDesktop?: JSX.Element;
  filtersLength: number;
  filtersMobile?: JSX.Element;
  handleInputFocus?: () => void;
  isInlayMap?: boolean;
  listContent?: JSX.Element;
  onKeyDown?: (event: ReactKeyboardEvent) => void;
  onKeyUp?: (event: ReactKeyboardEvent) => void;
  openFilter: boolean;
  setFilterOpen: (value: boolean) => void;
  tFunction: TFunction;
}

const Layout: FC<Props> = ({
  disableFilter,
  filterDrawerContent,
  filtersDesktop,
  filtersLength,
  filtersMobile,
  handleInputFocus,
  isInlayMap,
  listContent,
  onKeyDown,
  onKeyUp,
  openFilter,
  setFilterOpen,
  tFunction,
}) => {
  const { text, setText } = useSearch();
  const { isPinned } = useSidebarPinContext();
  const ref = useRef<HTMLDivElement>(null);
  const smallScreen = useSmallScreen();
  const navigate = useNavigate();
  const map = useMap();
  const { things } = useCustomRoutes();
  const handleClear = (): void => {
    setText('');
  };

  useEffect(() => {
    if (!ref.current) return;
    DomEvent.disableClickPropagation(ref.current);
  }, [ref]);

  return <>
    <Drawer
      anchor="left"
      onClose={() => setFilterOpen(false)}
      open={openFilter}
      sx={{
        width: smallScreen ? '100vw' : '350px',
        zIndex: 1300,
        '& > .MuiPaper-root': {
          height: '100vh',
          marginLeft: smallScreen ? 0 : isPinned ? `${SIDEBAR_FULL_WIDTH}px` : `${SIDEBAR_WIDTH}px`, // TODO: TP-7045 get width from theme
          overflow: 'auto',
          width: 'inherit',
        },
      }}
    >
      {filterDrawerContent}
    </Drawer>
    <Stack
      id="search"
      direction="row-reverse"
      sx={{ justifyContent: 'space-between', width: '100%' }}
    >
      <Paper
        component="div"
        elevation={isInlayMap ? 0 : 3}
        sx={(theme) => ({
          alignItems: 'center',
          alignSelf: 'flex-start',
          borderRadius: '4px',
          display: 'flex',
          m: (smallScreen || isInlayMap) ? 0 : 3,
          p: 0,
          position: 'inherit',
          width: smallScreen
            ? 200
            : isInlayMap
              ? '100%'
              : 400,
          zIndex: 1000,
          [theme.breakpoints.down('sm')]: {
            flex: 1,
            maxWidth: '100%',
            minWidth: 'unset',
          },
        })}
      >
        <Stack ref={ref} flex={[1, 1]} sx={{ cursor: 'default' }} width="100%">
          <Card variant={isInlayMap ? 'outlined' : 'elevation'} elevation={isInlayMap ? 0 : 1} sx={{ display: 'flex', flexDirection: 'column', m: (smallScreen && !isInlayMap) ? '1rem' : 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <InputBase
                data-testid="map-search-input-string"
                inputProps={{ 'aria-label': 'search maps' }}
                onChange={(e) => setText?.(e.target.value)}
                onFocus={() => handleInputFocus?.()}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onTouchEnd={() => smallScreen && handleInputFocus?.()}
                placeholder={tFunction('common:component.search.labels.maps')}
                sx={{ flex: 1, ml: 2 }}
                value={text}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              {text
                && <IconButton aria-label="clear" onClick={handleClear} sx={{ m: '1.5px', p: '10px' }}>
                  <ClearIcon />
                </IconButton>
              }
              <Collapse
                in={!disableFilter}
                orientation="horizontal"
                sx={{ '.MuiCollapse-wrapperInner': { alignItems: 'center', display: 'flex' } }}
              >
                <Divider orientation="vertical" sx={{ height: 28, m: 0.5 }} />
                <IconButton
                  data-testid="map-filters-button"
                  aria-label="filters"
                  onClick={() => {
                    setFilterOpen(true);
                    map.fire('spiderfy');
                    navigate(`/map/${things}`, {
                      state: {
                        shouldFly: false,
                      },
                    });
                  }}
                  sx={{ p: '10px' }}
                >
                  <Badge badgeContent={filtersLength} sx={{ m: '1.5px' }}>
                    <FilterIcon />
                  </Badge>
                </IconButton>
              </Collapse>
            </Box>
          </Card>
          <Fade in={!disableFilter} unmountOnExit>
            <div>{filtersMobile}</div>
          </Fade>
          {listContent}
        </Stack>
      </Paper>
      {!smallScreen
        && <Fade in={!disableFilter} unmountOnExit>
          <Box sx={{ height: 'fit-content', zIndex: 400 }}>
            {filtersDesktop}
          </Box>
        </Fade>
      }
    </Stack>
  </>;
};

export default Layout;
