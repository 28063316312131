import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export namespace IncidentIcons {
  export const Accident: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y=".71" width="15.97" height="15.97" rx=".5" transform="rotate(45 12 .7)" fill="#FF6B00" stroke="#000" />
        <path d="m8.46 10.88-.21.82a1.7 1.7 0 0 0-.04.6l-.6.51c-.12.1-.29 0-.26-.15l.27-1.35a.16.16 0 0 0-.14-.2L6.14 11c-.15-.02-.2-.21-.07-.3l1.12-.76a.16.16 0 0 0 .03-.23l-.85-1.06c-.1-.12 0-.29.15-.26l1.32.27a.16.16 0 0 0 .18-.14l.13-1.37c.02-.15.2-.2.29-.07l.74 1.15a.15.15 0 0 0 .23.03l1.04-.87c.11-.1.28 0 .25.15l-.2.99-.13.16-.55.76-.19.25a1.68 1.68 0 0 0-1.17 1.2Zm8.5 2.33-.21.82a1.1 1.1 0 0 1-.45.64l-.24.91a.57.57 0 0 1-.1.2.54.54 0 0 1-.58.2l-.53-.15a.56.56 0 0 1-.39-.68l.14-.55-4.25-1.16-.14.54a.55.55 0 0 1-.67.4L9 14.23a.56.56 0 0 1-.4-.46.57.57 0 0 1 0-.22l.25-.92a1.13 1.13 0 0 1-.08-.79l.21-.81a1.11 1.11 0 0 1 .95-.83l.32-.42.55-.76a1.96 1.96 0 0 1 .91-.68 1.87 1.87 0 0 1 1.13-.04l2.1.58a1.92 1.92 0 0 1 1.13.84 2 2 0 0 1 .3.83l.1.93.06.53a1.13 1.13 0 0 1 .42 1.2Zm-6.57-2.1c-.32-.08-.59.08-.67.4-.09.33.07.6.39.7.32.08.78.27.87-.06.08-.33-.27-.94-.59-1.03Zm5 .5-.11-.93a.83.83 0 0 0-.6-.72l-2.12-.58a.8.8 0 0 0-.48.02.83.83 0 0 0-.39.3l-.55.75 2.95.81 1.3.35Zm.31.96c-.31-.08-.92.27-1 .6-.09.32.4.4.72.49.32.09.59-.07.68-.4.08-.32-.08-.6-.4-.69Z" fill="#000" />
      </svg>
    </SvgIcon>
  );

  export const Congestion: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y=".71" width="15.97" height="15.97" rx=".5" transform="rotate(45 12 .7)" fill="#FF6B00" stroke="#000" />
        <path fillRule="evenodd" clipRule="evenodd" d="m16.15 7.96.32.81c.31.16.53.48.53.86v.55c0 .22-.07.41-.18.57v.54a.6.6 0 0 1-.6.6h-.37a.6.6 0 0 1-.6-.6v-.14h-2.5v.14a.6.6 0 0 1-.6.6h-.37a.6.6 0 0 1-.6-.6v-.54a.96.96 0 0 1-.18-.57v-.55c0-.38.22-.7.53-.86l.32-.81c.24-.58.8-.96 1.42-.96h1.46c.63 0 1.18.38 1.42.96Z" fill="#FF6B00" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.93 8.04c-.2-.49-.67-.8-1.2-.8h-1.46c-.53 0-1 .31-1.2.8l-.36.9a.74.74 0 0 0-.48.7v.54c0 .2.07.36.19.49v.62c0 .2.16.37.36.37h.37c.2 0 .37-.16.37-.37v-.37h2.96v.37c0 .2.16.37.37.37h.37c.2 0 .36-.16.36-.37v-.62c.12-.13.19-.3.19-.49v-.55c0-.31-.2-.58-.48-.69l-.36-.9Zm-3.18.28a.55.55 0 0 1 .52-.35h1.46c.23 0 .43.14.52.35l.23.57h-2.96l.23-.57Zm-.97 1.5c0 .22.15.36.37.36h.09c.21 0 .47.01.47-.18 0-.22-.33-.55-.56-.55-.22 0-.37.14-.37.36Zm3.98.36c-.21 0-.47.01-.47-.18 0-.22.33-.55.56-.55.22 0 .37.14.37.36 0 .23-.15.37-.37.37h-.09Z" fill="#000" />
        <path fillRule="evenodd" clipRule="evenodd" d="m11.81 9.08.37.92c.35.18.6.54.6.97v.63c0 .24-.09.45-.21.63v.62c0 .37-.3.67-.68.67h-.42a.68.68 0 0 1-.68-.67v-.16H7.98v.16c0 .37-.3.67-.68.67H6.9a.68.68 0 0 1-.68-.67v-.62c-.13-.18-.21-.4-.21-.63v-.63c0-.43.24-.8.6-.97l.36-.92c.27-.66.9-1.08 1.6-1.08h1.65c.71 0 1.34.42 1.6 1.08Z" fill="#FF6B00" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.57 9.18a1.45 1.45 0 0 0-1.36-.92H8.56c-.6 0-1.13.36-1.35.92l-.41 1.01a.83.83 0 0 0-.54.78v.63c0 .2.08.4.2.54v.7c0 .24.2.42.43.42h.41c.23 0 .42-.18.42-.41v-.42h3.33v.42c0 .23.2.41.42.41h.42c.23 0 .41-.18.41-.41v-.7c.13-.16.21-.34.21-.55v-.63a.83.83 0 0 0-.54-.78l-.4-1.01Zm-3.6.3c.1-.23.33-.39.59-.39h1.65c.26 0 .49.16.58.4l.26.65H7.72l.26-.65Zm-1.08 1.7c0 .25.16.41.41.41h.1c.24 0 .53.01.53-.2 0-.25-.38-.63-.63-.63s-.41.17-.41.42Zm4.49.41c-.24 0-.53.01-.53-.2 0-.25.37-.63.62-.63s.42.17.42.42c0 .25-.17.41-.42.41h-.1Z" fill="#000" />
        <path fillRule="evenodd" clipRule="evenodd" d="m15.86 11.28.44 1.08c.41.21.7.64.7 1.15v.74c0 .28-.1.54-.25.75v.72a.8.8 0 0 1-.8.8h-.49a.8.8 0 0 1-.8-.8v-.18h-3.32v.18a.8.8 0 0 1-.8.8h-.5a.8.8 0 0 1-.8-.8V15a1.28 1.28 0 0 1-.24-.75v-.74c0-.5.29-.94.7-1.15l.44-1.08A2.02 2.02 0 0 1 12.02 10h1.96c.83 0 1.57.5 1.88 1.28Z" fill="#FF6B00" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.58 11.4a1.71 1.71 0 0 0-1.6-1.1h-1.96c-.7 0-1.33.43-1.6 1.1l-.48 1.19a.98.98 0 0 0-.63.92v.74c0 .24.1.47.24.64v.83c0 .27.22.5.5.5h.49a.5.5 0 0 0 .5-.5v-.49h3.93v.5c0 .26.22.49.5.49h.48a.5.5 0 0 0 .5-.5v-.83c.15-.17.24-.4.24-.64v-.74a.98.98 0 0 0-.63-.92l-.48-1.2Zm-4.24.36a.74.74 0 0 1 .68-.47h1.96c.3 0 .57.19.68.47l.3.76h-3.93l.3-.76Zm-1.3 2c0 .29.2.48.5.48h.11c.28.01.63.02.63-.24 0-.3-.45-.74-.74-.74-.3 0-.5.2-.5.5Zm5.3.48c-.27.01-.62.02-.62-.24 0-.3.45-.74.74-.74.3 0 .5.2.5.5s-.2.48-.5.48h-.11Z" fill="#000" />
      </svg>
    </SvgIcon>
  );

  export const Construction: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y=".71" width="15.97" height="15.97" rx=".5" transform="rotate(45 12 .7)" fill="#FF6B00" stroke="#000" />
        <path d="M8.75 9.73c.04.21.16.4.4.16l.64-.61a.27.27 0 0 0 .07-.1.25.25 0 0 0 .02-.1c0-.15-.1-.15-.26-.15-.15-.02-.74-.13-.85-.11a.3.3 0 0 0-.16.08.26.26 0 0 0-.07.15c-.01.1.17.55.21.68Zm1.9 1.49.28-.27.21-.19.2.98-.7-.52ZM12.2 9.3a1.61 1.61 0 0 0-.13-.39 1.24 1.24 0 0 0 1.35.15c.21-.1.38-.27.5-.48a1.04 1.04 0 0 0 .1-.66 1.1 1.1 0 0 0-.24-.5 1.2 1.2 0 0 0-.47-.34 1.26 1.26 0 0 0-1.09.1 1.07 1.07 0 0 0-.53.9c0 .17.04.34.12.5a1.79 1.79 0 0 0-.64-.37 3.22 3.22 0 0 0-.44-.1A12.19 12.19 0 0 0 9.95 8a18.22 18.22 0 0 0-1.2-.1 1.42 1.42 0 0 0-.66.14.78.78 0 0 0-.28.23c-.33.46-.18 1.26.01 1.75.06.14.14.28.24.4l.1.1-.14.12a1.13 1.13 0 0 0-.37.65c-.11.45-.06.94-.01 1.39.02.21.04.44.04.66 0 .26-.12.54-.24.77l-.24.47c-.05.1-.12.22-.15.32-.07.26-.07.58.13.8a.55.55 0 0 0 .26.15c.1.02.2.03.3 0 .29-.07.47-.33.57-.57l.6-1.5.16-1.38c.19.13.7.41.75.63.04.15-.05.34-.12.47L9 14.94c-.15.3-.26.63.05.9a.7.7 0 0 0 .89.01.63.63 0 0 0 .16-.2l1.13-2.09c.16-.3.17-.8-.08-1.07a18.1 18.1 0 0 0-.78-.8l-.1-.11.1-.1.03-.02 1.99 1.53-.15.19-.2.23.26.18.32.24.03.02a.74.74 0 0 0 .03.01l.17.11-.2.07c-.2.06-.48.16-.63.48a3.21 3.21 0 0 1-.27.47l-.06.12-.1.34H17l-.03-.29c-.05-.58-.31-.68-.63-.8-.15-.05-.32-.11-.46-.36-.27-.5-.4-.52-.66-.54-.1 0-.26-.02-.6-.23a1.55 1.55 0 0 0-.42-.1.58.58 0 0 0-.32.1l-.03-.13a.73.73 0 0 0-.3-.43l-.32-.23-.25-.18-.2.23-.17.22-.38-.28a.62.62 0 0 0 .33-.26.56.56 0 0 0 .08-.39c-.15-.82-.27-1.68-.44-2.48Z" fill="#000" />
      </svg>
    </SvgIcon>
  );

  export const DisabledVehicle: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y=".71" width="15.97" height="15.97" rx=".5" transform="rotate(45 12 .7)" fill="#FF6B00" stroke="#000" />
        <path d="M16.13 13.48a.91.91 0 1 0 0 1.82.91.91 0 0 0 0-1.82Zm0 1.28a.37.37 0 1 1 0-.74.37.37 0 0 1 0 .74Zm-5.09-1.28a.91.91 0 1 0 0 1.82.91.91 0 0 0 0-1.82Zm0 1.28a.37.37 0 1 1 0-.74.37.37 0 0 1 0 .74Zm1.4-7.1a.58.58 0 0 0-.45-.94.55.55 0 1 0-1.03.37.82.82 0 0 0 .2 1.61h.02a.53.53 0 0 0-.01 1.06.46.46 0 1 0 .64-.42.53.53 0 0 0 .36-.28.75.75 0 0 0 .28-1.4Zm-2.36.95a.18.18 0 0 0-.22.13l-.3 1.16-.87.43-1.2-.71.88.23a.47.47 0 0 0-.43-.52l-.84-.07a.47.47 0 0 0-.51.43l-.19 2.42.23 1.46-.6 1.61a.38.38 0 1 0 .7.27l.65-1.7a.38.38 0 0 0 .02-.2l-.22-1.37.2.02.61 1.34.42 1.86a.38.38 0 1 0 .75-.17l-.43-1.9a.38.38 0 0 0-.03-.07l-.52-1.16.07-.87a81.9 81.9 0 0 1-1.07-1.06l1.33.79a.32.32 0 0 0 .3.01l1.14-.55a.32.32 0 0 0 0-.57l.26-1.02a.18.18 0 0 0-.13-.22Z" fill="#000" />
        <path d="M7.72 9.06a.7.7 0 1 0 0-1.4.7.7 0 0 0 0 1.4Zm9.75.55a.52.52 0 0 0-.5-.4h-3.68a.52.52 0 0 0-.46.26l-1.1 1.96-1.23-1.3a.68.68 0 0 1-.29.56l1.21 1.3h-.62a.2.2 0 0 0-.2.24l.04.17a.13.13 0 0 1-.16.15l-.29-.07a.13.13 0 0 0-.15.13v.37a.13.13 0 0 1-.09.12l-.2.06a.13.13 0 0 0-.08.14l.07.62a1.39 1.39 0 0 1 2.69.47h2.31a1.38 1.38 0 0 1 2.77 0h.28a.2.2 0 0 0 .21-.21V12l-.53-2.4Zm-2.92 2.92h-.62a.24.24 0 1 1 0-.47h.62a.24.24 0 1 1 0 .47Zm.49-1.03h-2.69l.96-1.71.44.52-.17.33.14.28a.03.03 0 0 0 .06-.01l-.04-.24.37-.34-.22-.55h1.15v1.72Zm.61 0V9.78h1.27l.38 1.72h-1.65Z" fill="#000" />
      </svg>
    </SvgIcon>
  );

  export const LaneRestriction: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y=".71" width="15.97" height="15.97" rx=".5" transform="rotate(45 12 .7)" fill="#FF6B00" stroke="#000" />
        <path fill="#000" d="M14 12h2v5h-2zm-1-3h4v2h-4zm-1.5-.13L9.25 6 7 8.87l1.21-.26V17h2.08V8.61l1.21.26Zm-2.25-.48Z" />
      </svg>
    </SvgIcon>
  );

  export const MassTransit: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y=".71" width="15.97" height="15.97" rx=".5" transform="rotate(45 12 .7)" fill="#FF6B00" stroke="#000" />
        <path d="M11.23 6.85c-2.34.46-4.18 1.1-3.8 2.96l1 5.02a1.89 1.89 0 0 0 2.22 1.48l.53-.1.8-.17 2.1-.42.8-.16.53-.1a1.89 1.89 0 0 0 1.48-2.22l-1-5.03c-.38-1.85-2.32-1.73-4.66-1.26Zm-.8 8.4a.8.8 0 1 1-.31-1.58.8.8 0 1 1 .32 1.58Zm1.22-3.54-2.64.53-.53-2.64 2.64-.53.53 2.64Zm3.54 2.59a.8.8 0 1 1-.31-1.59.8.8 0 1 1 .31 1.59Zm.16-3.33-2.64.53-.53-2.65 2.64-.52.53 2.64Z" fill="#000" />
        <path d="M12.07 15.92c.2 0-.27 0 0 0H9.7l-.62.62a.27.27 0 0 0 .2.46h5.7a.27.27 0 0 0 .2-.46l-.63-.62h-2.48c.27 0-.27 0 0 0Z" fill="#000" />
      </svg>
    </SvgIcon>
  );

  export const Other: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y=".71" width="15.97" height="15.97" rx=".5" transform="rotate(45 12 .7)" fill="#FF6B00" stroke="#000" />
        <path d="M10.58 19.9a1.92 1.92 0 0 1-.58-1.41 1.9 1.9 0 0 1 .58-1.4A1.94 1.94 0 0 1 12 16.5a1.92 1.92 0 0 1 1.41.58c.4.39.59.86.59 1.4 0 .55-.2 1.02-.59 1.41a1.93 1.93 0 0 1-1.41.58 1.95 1.95 0 0 1-1.42-.58ZM12 4c-.7 0-1.28.23-1.77.68-.49.44-.73.98-.73 1.62 0 1.02.2 2.44.59 4.26.39 1.82.8 3.47 1.25 4.94h1.32a60.89 60.89 0 0 0 1.25-4.94c.4-1.82.59-3.24.59-4.26 0-.64-.24-1.18-.73-1.62A2.5 2.5 0 0 0 12 4Z" fill="#000" />
      </svg>
    </SvgIcon>
  );

  export const PlannedEvent: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y=".71" width="15.97" height="15.97" rx=".5" transform="rotate(45 12 .7)" fill="#FF6B00" stroke="#000" />
        <path d="M13.78 11.95h-1.34a.45.45 0 0 0-.44.45v1.35c0 .25.2.45.44.45h1.34a.45.45 0 0 0 .44-.45V12.4c0-.25-.2-.45-.44-.45Zm0-4.5v.45h-3.56v-.45c0-.25-.2-.45-.44-.45a.45.45 0 0 0-.45.45v.45H8.9a.89.89 0 0 0-.89.9v6.3c0 .5.4.9.89.9h6.22a.9.9 0 0 0 .89-.9V8.8c0-.5-.4-.9-.89-.9h-.44v-.45c0-.25-.2-.45-.45-.45a.45.45 0 0 0-.44.45Zm.89 7.65H9.33a.45.45 0 0 1-.44-.45v-4.5h6.22v4.5c0 .25-.2.45-.44.45Z" fill="#000" />
      </svg>
    </SvgIcon>
  );

  export const RoadClosed: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y=".71" width="15.97" height="15.97" rx=".5" transform="rotate(45 12 .7)" fill="#FF6B00" stroke="#000" />
        <path d="M12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm3.44-4.38H8.56v-1.24h6.88v1.24Z" fill="#000" />
      </svg>
    </SvgIcon>
  );

  export const Weather: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y=".71" width="15.97" height="15.97" rx=".5" transform="rotate(45 12 .7)" fill="#FF6B00" stroke="#000" />
        <path d="M9.54 15.43c-.22.28-.38.6-.46.95a.63.63 0 1 0 1.25 0 2.46 2.46 0 0 0-.45-.95.22.22 0 0 0-.34 0Zm7.25-5.51h-.62a2.28 2.28 0 0 0-.52-1.25l.43-.46a.2.2 0 0 0 0-.3.2.2 0 0 0-.3 0l-.45.46a2.28 2.28 0 0 0-1.25-.54v-.62a.2.2 0 1 0-.41 0v.62a2.27 2.27 0 0 0-1.25.55l-.46-.46a.21.21 0 1 0-.3.3l.48.45a.2.2 0 0 0 0 .1.2.2 0 0 0 .12.15 3.33 3.33 0 0 1 1.53 1.3.2.2 0 0 0 .19.1 2.5 2.5 0 0 1 1.73.62c.04.03.09.05.14.05h.05a.2.2 0 0 0 .14-.14c.06-.16.1-.34.13-.52h.62a.2.2 0 1 0 0-.41Z" fill="#000" />
        <path d="M14.08 10.75c-.41 0-.82.12-1.16.35a.21.21 0 0 1-.3-.05.2.2 0 0 1 .07-.3 2.48 2.48 0 0 1 .47-.24.2.2 0 0 0 .09-.31 2.92 2.92 0 0 0-5 .69.2.2 0 0 0 .21.28h.2a.2.2 0 1 1 0 .41 1.67 1.67 0 1 0 0 3.34h2.57a4 4 0 0 0-.48 1.25.83.83 0 0 0 1.67 0 4.04 4.04 0 0 0-.49-1.25h1.15a1.53 1.53 0 0 0-.25.62.63.63 0 0 0 1.25 0 1.53 1.53 0 0 0-.24-.62h.24a2.08 2.08 0 1 0 0-4.17Z" fill="#000" />
      </svg>
    </SvgIcon>
  );
}
