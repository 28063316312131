import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { RadioButtonSet } from '../../components';
import { RadioButtonOption } from './subscription-detail.types';

interface Props {
  'data-testid'?: string;
  fieldDisabled?: boolean;
  heading?: string;
  options: RadioButtonOption[];
  selected: string;
  setSelected: (value: string) => unknown;
}

export const NotificationRecipientRadioButtons: FC<Props> = ({ fieldDisabled, heading, options, selected, setSelected, ...props }) => (
  <Stack>
    <Typography variant="subtitle2">{heading}</Typography>
    <RadioButtonSet
      data-testid={props['data-testid'] ?? 'radio-dialog'}
      disabled={fieldDisabled}
      options={options}
      selected={selected}
      setSelected={setSelected}
    />
  </Stack>
);
