import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, IconButton, Stack } from '@mui/material';
import { FC } from 'react';

interface Props {
  actions: JSX.Element[];
  closeDialog: () => void;
  'data-testid'?: string;
  eventData: JSX.Element;
  mediaComponent?: JSX.Element;
  open: boolean;
  pageTitle: JSX.Element;
}

export const EventDialogDesktop: FC<Props> = ({
  actions,
  closeDialog,
  eventData,
  mediaComponent,
  open,
  pageTitle,
  ...props
}) => {
  return (
    <Dialog fullWidth maxWidth="md" data-testid={props['data-testid']} onClose={closeDialog} open={open}>
      <Stack>
        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
          {pageTitle}
          <Box>
            {actions}
            <IconButton data-testid="close-button" onClick={closeDialog} sx={{ ml: 3 }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Stack>
        <Stack sx={{ p: 2, pt: 1 }}>
          {eventData}
        </Stack>
        {mediaComponent}
      </Stack>
    </Dialog>
  );
};
