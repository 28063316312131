import { Recipient } from '@eagle/core-data-types';
import { FC } from 'react';
import { CreateSubscriptionFeatureAlertSettings } from './create-subscription-feature-alert-settings';
import { CreateSubscriptionLayout } from './create-subscription-layout';
import { CreateSubscriptionNotificationRecipient } from './create-subscription-notification-recipient';
import { CreateSubscriptionThingPeopleFilters } from './create-subscription-thing-people-filters';
import { FeatureAlertSettings, ThingPersonFilterIds } from './create-subscription.types';

interface Props {
  activeStep: number;
  createInProgress: boolean;
  createSubscription: () => Promise<void>;
  isProfilePage: boolean;
  onActiveBack: () => void;
  onActiveNext: () => void;
  updateAlertFilters: (values: ThingPersonFilterIds | FeatureAlertSettings) => void;
  updateRecipient: (recipient: Recipient) => void;
}

export const CreateSubscriptionView: FC<Props> = ({
  activeStep,
  createInProgress,
  createSubscription,
  isProfilePage,
  onActiveBack,
  onActiveNext,
  updateAlertFilters,
  updateRecipient,
}) => {
  return (
    <CreateSubscriptionLayout
      activeStep={activeStep}
      featureAlertSettings={
        <CreateSubscriptionFeatureAlertSettings
          onActiveBack={onActiveBack}
          onActiveNext={onActiveNext}
          updateFeatureAlertSettings={updateAlertFilters}
        />
      }
      notificationRecipient={
        <CreateSubscriptionNotificationRecipient
          createInProgress={createInProgress}
          createSubscription={createSubscription}
          isProfilePage={isProfilePage}
          onActiveBack={onActiveBack}
          updateNotificationRecipient={updateRecipient}
        />
      }
      thingPeopleFilters={
        <CreateSubscriptionThingPeopleFilters
          onActiveNext={onActiveNext}
          updateThingPeopleFilters={updateAlertFilters}
        />
      }
    />
  );
};
