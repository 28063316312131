import { i18n } from '../providers';

const SISENSE_SUPPORTED_LANGUAGES: Set<string> = new Set([
  'de-DE',  // German
  'en-US',  // English (United States)
  'es-AR',  // Spanish (Argentina)
  'es-ES',  // Spanish (Spain)
  'fr-FR',  // French
  'it-IT',  // Italian
  'ja-JP',  // Japanese
  'ko-KR',  // Korean
  'nl-NL',  // Dutch (Netherlands)
  'pt-BR',  // Portuguese
  'ru-RU',  // Russian
  'tr-TR',  // Turkish
  'zh-CN',  // Chinese
]);

const SPECIAL_CASES: Record<string, string> = {
  'es-MX': 'es-ES', // Spanish (Mexico) --> Spanish (Spain)
};

// Returns a supported language code for sisense EmbedSDK
// The language returned is dependent on the user-selected locale, falling back to browser language if necessary
export const getSisenseLanguageCode = (): string => {
  const userLocale = i18n.language?.replace('_', '-') ?? 'en-US';

  // Apply Special Cases if it exists
  const languageCode = SPECIAL_CASES[userLocale] || userLocale;

  // Return the supported language if it exists, else fall back to 'en-US'
  return SISENSE_SUPPORTED_LANGUAGES.has(languageCode) ? languageCode : 'en-US';
};
