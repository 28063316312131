import { LastThingEvent } from '@eagle/core-data-types';
import { DriverBehaviorEventTypes, DuressEventTypes, FeatureTypes, InertiaEventTypes, TrackingEventTypes, VehicleSeatEventTypes } from '@eagle/data-function-types';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export interface EventCategory {
  eventTypeIds: string[];
  label: string;
}

export const getIconStyle = (theme: Theme, eventTypeId: string, feature: string, featureTypeId: string): SxProps => {
  switch (feature) {
    case 'inertia':
      if (featureTypeId !== FeatureTypes.INERTIA_V0) return { color: theme.palette.grey[600] };
      switch (eventTypeId) {
        case (InertiaEventTypes.ROLLOVER):
          return { color: theme.alerts.red };
        case (InertiaEventTypes.RAPID_ACCELERATION): // intentional fallthrough
        case (InertiaEventTypes.RAPID_DECELERATION):
          return { color: theme.alerts.blue };
      }
      break;
    case 'duress':
      if (featureTypeId !== FeatureTypes.DURESS_V0) return { color: theme.palette.grey[600] };
      switch (eventTypeId) {
        case DuressEventTypes.DURESS_BUTTON_ACTIVATED: // intentional fallthrough
        case DuressEventTypes.SUPPRESSION_CANCEL: // intentional fallthrough
        case DuressEventTypes.SUPPRESSION_START:
          return { color: theme.alerts.amber };
      }
      break;
    case 'driver-behavior':
      switch (eventTypeId) {
        case DriverBehaviorEventTypes.EYES_CLOSED: // intentional fallthrough
        case DriverBehaviorEventTypes.FATIGUE_RISK_DRIVING: // intentional fallthrough
        case DriverBehaviorEventTypes.LOOK_DOWN: // intentional fallthrough
        case DriverBehaviorEventTypes.MISSING_FACE: // intentional fallthrough
        case DriverBehaviorEventTypes.OTHER: // intentional fallthrough
        case DriverBehaviorEventTypes.PHONE_USAGE: // intentional fallthrough
        case DriverBehaviorEventTypes.SMOKING: // intentional fallthrough
        case DriverBehaviorEventTypes.YAWN:
          return { color: theme.alerts.amber };
      }
      break;
    case 'tracking':
      switch (eventTypeId) {
        case TrackingEventTypes.SPEEDING_START:
          return { color: theme.alerts.amber };
        default:
          break;
      }
      break;
    case 'abs-activated':
      return { color: theme.alerts.amber };
    case 'vsc-activated':
      return { color: theme.alerts.amber };
    case 'airbag-deployed':
      return { color: theme.alerts.red };
    case 'vehicle-seat':
      switch (eventTypeId) {
        case VehicleSeatEventTypes.MOVEMENT_WITHOUT_RESTRAINT_START:
          return { color: theme.alerts.amber };
        default:
          break;
      }
      break;
    default:
      return { color: theme.palette.grey[600] };
  }
  return { color: theme.palette.grey[600] };
};

export const getThingEventServerTime = ({ occurred, timing }: LastThingEvent): Date => {
  return timing.received
    ?? timing['event-mapper']
    ?? timing['thing-event-inline-processor']
    ?? occurred;
};
