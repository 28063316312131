/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Drawer, Stack, StackProps, Theme } from '@mui/material';
import { styled } from '@mui/system';
import { FC, ReactNode, RefObject, useMemo } from 'react';
import { BottomNavigation } from '../../components/bottom-navigation';
import { FlexBox } from '../../components/flex-box';

interface Props {
  actions?: ReactNode;
  clearAllFiltersButton: JSX.Element;
  content: ReactNode;
  'data-testid'?: string;
  filterContent: JSX.Element;
  filters?: ReactNode;
  pagination?: JSX.Element;
  primaryActions?: ReactNode;
  scrollRef?: RefObject<HTMLDivElement>;
  search: JSX.Element;
  searchOpen?: boolean;
  setSearchOpen?: (val: boolean) => void;
  showFilters?: boolean;
  showSearch?: boolean;
  subtitle?: ReactNode;
}

const Row = styled((props: StackProps) => (
  <Stack direction="row" spacing={2} {...props} />
))({
  alignItems: 'center',
});

const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiPaper-root': {
    width: 'inherit',
  },
}));

export const LayoutMobile: FC<Props> = ({
  actions,
  content,
  filterContent,
  filters,
  pagination,
  scrollRef,
  search,
  setSearchOpen,
  searchOpen,
  showFilters,
  subtitle,
  primaryActions,
  clearAllFiltersButton,
  ...props
}) => {
  const searchDrawer = useMemo(() => (
    <Drawer
      anchor="top"
      onClose={() => setSearchOpen?.(false)}
      open={searchOpen}
    >
      {search}
    </Drawer>
  ), [searchOpen, setSearchOpen]);

  const actionBar = useMemo(() => (
    actions
    && <BottomNavigation
      sx={{
        width: '100vw',
        boxShadow: (theme: Theme) => theme.shadows[4],
        justifyContent: 'space-around',
      }}
    >
      {actions}
    </BottomNavigation>
  ), [actions]);

  return (
    <FlexBox data-testid={props['data-testid']} id="list-page" className="ListPage-root" flexDirection="column">
      <StyledDrawer
        anchor="left"
        open={showFilters}
        sx={{
          width: showFilters ? '100vw' : 0,
          zIndex: 1300,
        }}
      >
        {filterContent}
      </StyledDrawer>
      {searchDrawer}
      <FlexBox id="content" sx={{ display: 'flex', flexBasis: 0, overflowX: 'hidden', overflowY: 'auto', width: '100vw' }}>
        <Container id="list-page-scroll" ref={scrollRef}>
          <Stack direction="column" spacing={0} sx={{ py: 1, height: '100%' }}>
            {subtitle
              && <Row sx={{ my: 1 }}>
                {subtitle}
              </Row>
            }
            {filters
              && <Row spacing={0} flexWrap='wrap'>
                {clearAllFiltersButton}
                {filters}
              </Row>
            }
            {primaryActions}
            <Stack spacing={2} sx={{ py: 1, flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1 }}>
                {content}
              </Box>
              {pagination
                && <Box display="flex" sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
                  {pagination}
                </Box>
              }
            </Stack>
          </Stack>
        </Container>
      </FlexBox>
      <Box display="flex" sx={{ alignSelf: 'stretch' }}>
        {actionBar}
      </Box>
    </FlexBox>
  );
};
