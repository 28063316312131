/* eslint-disable react-hooks/exhaustive-deps */
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateTime, DurationObjectUnits } from 'luxon';
import { FC, useEffect } from 'react';
import { useSearch } from '../../pages/list/use-search';
import { DateTimeRangeError, mergeDateAndTime } from '../../util';
import { useDateTimeRangePickerContext } from '../../util/data-time-range-picker';
import { DateTimeRangeChangeHandler } from '../date-time-range';
import { CustomRange, DateFieldType, RangeSelectValues } from './date-time-range-picker.types';
import { CustomDateRangeView } from './view';

interface Props {
  'data-testid'?: string;
  closeDateTimePicker: () => void;
  defaultEndTime?: DateTime;
  defaultStartTime?: DateTime;
  defaultRange?: RangeSelectValues;
  isDateTimePickerOpen: boolean;
  maxDateRange?: number;
  maxRangeUnit?: keyof DurationObjectUnits;
  onDateRangeChanged: DateTimeRangeChangeHandler;
  savedRange: CustomRange;
  setRangeSelection: (range: RangeSelectValues['key']) => void;
  setSavedRange: (range: CustomRange) => void;
}

export const DateTimeRangePicker: FC<Props> = ({
  closeDateTimePicker,
  defaultEndTime = DateTime.now().set({ hour: 23, minute: 59, second: 59 }),
  defaultStartTime = DateTime.now().set({ hour: 0, minute: 0, second: 0 }),
  defaultRange = RangeSelectValues.LAST_12_HOURS,
  isDateTimePickerOpen,
  maxDateRange = 7,
  maxRangeUnit = 'days',
  onDateRangeChanged,
  savedRange,
  setRangeSelection,
  setSavedRange,
  ...props
}): JSX.Element => {
  const { startDate: savedStartDate, endDate: savedEndDate } = savedRange;
  const {
    clearAll,
    dateTimeRangeError,
    endDate,
    endDateError,
    endTime,
    endTimeError,
    setDateTimeRangeError,
    setEndDate,
    setEndDateError,
    setEndTime,
    setEndTimeError,
    setStartDate,
    setStartDateError,
    setStartTime,
    setStartTimeError,
    startDate,
    startDateError,
    startTime,
    startTimeError,
  } = useDateTimeRangePickerContext();
  const { savedSelection, setSavedSelection } = useSearch();
  const dateTimes = [startDate, endDate, startTime, endTime];
  const isDateTimeDefined = dateTimes.every((dateTime) => dateTime);

  const onCancel = (): void => {
    if (savedSelection !== RangeSelectValues.PICK_CUSTOM_RANGE.key) {
      setRangeSelection(savedSelection);
    } else {
      if (savedStartDate && savedEndDate) {
        onDateRangeChanged(savedStartDate.toJSDate(), savedEndDate.toJSDate());
      } else {
        setRangeSelection(defaultRange.key);
      }
    }

    clearAll();
    closeDateTimePicker();
  };

  const onSetRange = (): void => {
    if (!isDateTimeDefined) return;
    const startDateTime = mergeDateAndTime(startDate, startTime);
    const endDateTime = mergeDateAndTime(endDate, endTime);
    setSavedRange({ startDate: startDateTime, endDate: endDateTime });
    setSavedSelection(RangeSelectValues.PICK_CUSTOM_RANGE.key);
    onDateRangeChanged(startDateTime.toJSDate(), endDateTime.toJSDate());
    closeDateTimePicker();
  };

  const onCalendarSelect = (
    date: Date,
    defaultTime: DateTime,
    dateFieldType: DateFieldType,
  ): void => {
    const { hour, minute, second } = defaultTime;
    const dateTime = DateTime.fromJSDate(date);

    if (dateFieldType === DateFieldType.START_DATE) {
      setStartDate(dateTime);
      setStartTime(dateTime.set({ hour, minute, second }));
      setStartDateError(undefined);
      setStartTimeError(undefined);
      if (maxDateRange === 1) {
        setEndDate(dateTime);
        setEndTime(defaultEndTime);
      }
    }

    if (dateFieldType === DateFieldType.END_DATE) {
      setEndDate(dateTime);
      setEndTime(dateTime.set({ hour, minute, second }));
      setEndDateError(undefined);
      setEndTimeError(undefined);
    }
  };

  const shouldDisableDate = (date: DateTime, maxRange: number): boolean => {
    if (startDate && endDate) return true;

    const timeDifference = startDate
      ? date.diff(startDate, maxRangeUnit).toObject()[maxRangeUnit]
      : endDate && endDate.diff(date, maxRangeUnit).toObject()[maxRangeUnit];

    return !!(timeDifference && (timeDifference >= maxRange || timeDifference < 0));
  };

  const shouldDisableSetRange = (): boolean => !isDateTimeDefined || !!startDateError || !!endDateError || !!startTimeError || !!endTimeError || !!dateTimeRangeError;

  useEffect(() => {
    if (!savedStartDate || !savedEndDate) return;
    setStartDate(savedStartDate);
    setStartTime(savedStartDate);
    setEndDate(savedEndDate);
    setEndTime(savedEndDate);
  }, [isDateTimePickerOpen]);

  useEffect(() => {
    if (!isDateTimeDefined) return setDateTimeRangeError(undefined);

    const startDateTime = mergeDateAndTime(startDate, startTime);
    const endDateTime = mergeDateAndTime(endDate, endTime);
    const timeDifference = endDateTime.diff(startDateTime, maxRangeUnit).toObject()[maxRangeUnit];

    if (!timeDifference) return;
    if (timeDifference <= 0) return setDateTimeRangeError(DateTimeRangeError.INVALID_RANGE);
    if (timeDifference > maxDateRange) return setDateTimeRangeError(DateTimeRangeError.INVALID_RANGE_OVER);
    return setDateTimeRangeError(undefined);
  }, [dateTimes, maxRangeUnit, maxDateRange]);

  useEffect(() => {
    if (!startDateError && !endDateError) return;
    if (startDateError) setStartDate(undefined);
    if (endDateError) setEndDate(undefined);
  }, [startDateError, endDateError]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustomDateRangeView
        data-testid={props['data-testid']}
        defaultEndTime={defaultEndTime}
        defaultStartTime={defaultStartTime}
        isDateTimePickerOpen={isDateTimePickerOpen}
        maxDateRange={maxDateRange}
        maxRangeUnit={maxRangeUnit}
        onCalendarSelect={onCalendarSelect}
        onCancel={onCancel}
        onSetRange={onSetRange}
        shouldDisableDate={shouldDisableDate}
        shouldDisableSetRange={shouldDisableSetRange}
      />
    </LocalizationProvider>
  );
};
