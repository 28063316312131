import RoomIcon from '@mui/icons-material/Room';
import { IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { CacheDataTypes, Display } from '../../types';
import { FetchOne } from '../fetch';
import { FormatTimestamp } from '../format';
import { JourneyItem } from './entity-journey-types';
import { useHistorySearch } from './use-history-search';
import { getJourneyColor } from './util';

interface Props {
  dataType: CacheDataTypes.THING | CacheDataTypes.PERSON;
  handleDrawerToggle?: (value: boolean) => void;
  journey: JourneyItem;
}

const COLLAPSED_JOURNEY_ITEM_LINES_DISPLAYED = 4;

export const MinimizedJourney: FC<Props> = ({
  dataType,
  handleDrawerToggle,
  journey,
}) => {
  const { handleExpand } = useHistorySearch();
  const theme = useTheme();

  return <>
    <Tooltip
      arrow
      placement="right"
      title={
        <Typography variant="caption">
          <FormatTimestamp format="short" value={journey.dateRange.startTime} /> - <FormatTimestamp format="short" value={journey.dateRange.endTime} />
        </Typography>
      }
    >
      <IconButton
        onClick={() => {
          handleDrawerToggle?.(true);
          handleExpand(journey.entityId, journey.id);
        }}
        sx={{ borderRadius: 0, width: '100%' }}
      >
        <Stack sx={{ alignItems: 'center' }}>
          <RoomIcon fontSize="large" sx={{ color: getJourneyColor(journey.colorIndex, theme) }} />
          <FetchOne
            id={journey.entityId}
            dataType={dataType}
            renderFactory={(data: Display) => (
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: COLLAPSED_JOURNEY_ITEM_LINES_DISPLAYED,
                }}
                variant="caption"
              >
                {data.display}
              </Typography>
            )}
          />
        </Stack>
      </IconButton>
    </Tooltip>
  </>;
};
