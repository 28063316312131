import { FC } from 'react';
import { Player } from '../../components';
import { useConfig, useSmallScreen, useTitle } from '../../hooks';
import { WelcomePageLayout } from './welcome-page-layout';

interface Props {
  portal: string;
  renderLogo: (smallScreen: boolean) => JSX.Element;
  renderText: (smallScreen: boolean) => JSX.Element;
  renderTitle: (smallScreen: boolean) => JSX.Element;
  title: string;
}

export const WELCOME_PAGE_ANIMATED_GRAPHIC = 'https://assets3.lottiefiles.com/packages/lf20_sphib0da.json';

export const WelcomePageView: FC<Props> = ({ portal, renderLogo, renderText, renderTitle, title }) => {
  const smallScreen = useSmallScreen();
  const config = useConfig();
  useTitle(title);

  const graphic = (
    <Player
      loop={false}
      src={config.portals?.[portal]?.welcome?.lottieFile ?? WELCOME_PAGE_ANIMATED_GRAPHIC}
      style={{
        marginTop: '5vh',
        maxHeight: 500,
        maxWidth: 500,
      }}
    />
  );

  return <WelcomePageLayout
    graphic={graphic}
    logo={renderLogo(smallScreen)}
    text={renderText(smallScreen)}
    title={renderTitle(smallScreen)}
  />;
};
