/* eslint-disable react-hooks/exhaustive-deps */
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateTime, DurationObjectUnits } from 'luxon';
import { FC, useEffect } from 'react';
import { DateTimeRangeError, mergeDateAndTime } from '../../util';
import { useDateTimeRangePickerContext } from '../../util/data-time-range-picker';
import { DateFieldType } from '../date-time-range-picker';
import { InlineDateTimeRangePickerView } from './view';

const MAX_RANGE_IN_DAYS = 7;

interface Props {
  closeDateTimePicker?: () => void;
  currentEndDateTime?: DateTime;
  currentStartDateTime?: DateTime;
  'data-testid'?: string;
  defaultEndDate?: DateTime;
  defaultStartDate?: DateTime;
  hideSeconds?: boolean;
  maxDateRange?: number;
  maxRangeUnit?: keyof DurationObjectUnits;
  onDateRangeChanged: (startDate: Date, endDate: Date) => void;
  singleDate?: boolean;
}

export const InlineDateTimeRangePicker: FC<Props> = ({
  currentEndDateTime,
  currentStartDateTime,
  defaultEndDate,
  defaultStartDate,
  hideSeconds,
  maxDateRange = MAX_RANGE_IN_DAYS,
  maxRangeUnit = 'days',
  onDateRangeChanged,
  singleDate,
  ...props
}): JSX.Element => {
  const {
    clearAll,
    dateTimeRangeError,
    endDate,
    endDateError,
    endTime,
    endTimeError,
    setEndDate,
    setEndDateError,
    setEndTime,
    setEndTimeError,
    setStartDate,
    setStartDateError,
    setStartTime,
    setStartTimeError,
    startDate,
    startDateError,
    startTime,
    startTimeError,
  } = useDateTimeRangePickerContext();
  const dateTimeExists = (startDate && endDate && startTime && endTime);
  const shouldBeSingleDate = maxDateRange === 24 && maxRangeUnit === 'hours';

  useEffect(() => {
    setStartDate(currentStartDateTime);
    setStartTime(currentStartDateTime);
    setEndDate(currentEndDateTime);
    setEndTime(currentEndDateTime);
  }, [currentStartDateTime, currentEndDateTime]);

  const onSetRange = (): void => {
    if (!dateTimeExists) return;
    const startDateTime = mergeDateAndTime(startDate, startTime);
    const endDateTime = mergeDateAndTime(endDate, endTime);
    onDateRangeChanged(startDateTime.toJSDate(), endDateTime.toJSDate());
  };

  const onCalendarSelect = (
    date: Date,
    defaultTime: DateTime,
    dateFieldType: DateFieldType,
  ): void => {
    const { hour, minute, second } = defaultTime;
    const dateTime = DateTime.fromJSDate(date);

    if (dateFieldType === DateFieldType.START_DATE) {
      setStartDate(dateTime);
      setStartTime(dateTime.set({ hour, minute, second }));
      setStartDateError(undefined);
      setStartTimeError(undefined);
    }

    if (dateFieldType === DateFieldType.END_DATE) {
      setEndDate(dateTime);
      setEndTime(dateTime.set({ hour, minute, second }));
      setEndDateError(undefined);
      setEndTimeError(undefined);
    }
  };

  const shouldDisableDate = (date: DateTime, maxRange: number): boolean => {
    if (startDate && endDate) return true;

    const timeDifference = startDate
      ? date.diff(startDate, maxRangeUnit).toObject()[maxRangeUnit]
      : endDate && endDate.diff(date, maxRangeUnit).toObject()[maxRangeUnit];

    return !!(timeDifference && (timeDifference > maxRange || timeDifference < 0));
  };

  const shouldDisableSetRange = (): boolean => !dateTimeExists || !!startDateError || !!endDateError || !!startTimeError || !!endTimeError || !!dateTimeRangeError;

  useEffect(() => {
    if (!dateTimeExists) return setEndTimeError(undefined);
    const startDateTime = mergeDateAndTime(startDate, startTime);
    const endDateTime = mergeDateAndTime(endDate, endTime);
    const timeDifference = Number(endDateTime.diff(startDateTime, maxRangeUnit).toObject()[maxRangeUnit]?.toFixed(3));

    if (singleDate || shouldBeSingleDate) {
      if (!timeDifference || timeDifference < 0) {
        setEndDate(endDate.plus({ day: 1 }));
      }

      if (timeDifference > maxDateRange) {
        setEndDate(endDate.minus({ day: 1 }));
        return;
      }
    }

    if (!timeDifference || timeDifference < 0) return setEndTimeError(DateTimeRangeError.INVALID_RANGE);
    if (timeDifference > maxDateRange) return setEndTimeError(DateTimeRangeError.INVALID_RANGE_OVER);
  }, [endDate, endTime, startDate, startTime, maxRangeUnit, maxDateRange]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <InlineDateTimeRangePickerView
        data-testid={props['data-testid']}
        defaultEndDate={defaultEndDate}
        defaultStartDate={defaultStartDate}
        maxDateRange={maxDateRange}
        maxRangeUnit={maxRangeUnit}
        onCalendarSelect={onCalendarSelect}
        onReset={clearAll}
        onSetRange={onSetRange}
        shouldDisableDate={shouldDisableDate}
        shouldDisableSetRange={shouldDisableSetRange}
        hideSeconds={hideSeconds}
        singleDate={singleDate || shouldBeSingleDate}
      />
    </LocalizationProvider>
  );
};
