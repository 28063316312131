import { ListItemButton } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';

interface Props {
  content: JSX.Element;
  'data-testid'?: string;
  href?: string;
  sx?: SxProps;
  deleted?: boolean;
}

export const ResultsDetailCard: FC<Props> = ({ content, href, sx, deleted = false, ...props }) => {
  const hasLink = href !== undefined;

  return (
    <ListItemButton
      data-testid={props['data-testid']}
      component={(!hasLink) ? 'div' : 'a'}
      href={href}
      role="button"
      disableRipple={!hasLink}
      sx={{
        ...sx,
        alignItems: 'start',
        color: 'inherit',
        cursor: (!hasLink) ? 'default' : 'pointer',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        textDecoration: deleted ? 'line-through!important' : 'none',
      }}
    >
      {content}
    </ListItemButton>
  );
};
