import { Image } from '@eagle/api-types';
import { FC } from 'react';
import { Avatar } from '../avatar';

interface Props {
  'data-testid'?: string;
  inlineImage?: string;
  data: {
    _id: string;
    thingTypeId?: string;
    personTypeId?: string;
    image?: Image;
  };
  badge?: string;
}

export const EntityAvatar: FC<Props> = ({ data, badge, inlineImage, ...props }) => {
  const dataType = data.thingTypeId ? 'thing' : (data.personTypeId ? 'person' : null);

  if (!dataType) {
    return <Avatar badgeColor={badge} data-testid={props['data-testid']} />;
  }

  const src = inlineImage ? inlineImage : (data.image ? `/api/v1/${dataType}/${data._id}/image/${data.image.filename}` : null);

  if (src) {
    return <Avatar badgeColor={badge} data-testid={props['data-testid']} image={src} />;
  }

  return <Avatar badgeColor={badge} data-testid={props['data-testid']} />;
};
