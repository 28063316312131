import { makeStyles } from '../../theme';

export const useStyles = makeStyles()((theme) => ({
  formControl: {
    width: 250,
  },
  selected: {
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  checked: {
    color: `${theme.palette.primary.main} !important`,
  },
  indeterminate: {
    color: `${theme.palette.primary.main} !important`,
  },
}));
