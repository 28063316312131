import { DateTime } from 'luxon';
import { Component } from 'react';
import { Undefinable } from '../../types';

interface Props {
  disableTooltip?: boolean;
  date: DateTime;
}

const refreshInterval = (date: DateTime): number => {
  const seconds = DateTime.utc().diff(date, 'seconds').seconds;
  if (seconds / 2 <= 60) return 1000;
  if (seconds / 2 <= 3600) return 60 * 1000 / 2;
  return 3600 * 1000 / 4;
};

class RelativeDateController extends Component<Props> {
  private refreshHandle: Undefinable<number>;

  public componentWillUnmount(): void {
    if (this.refreshHandle !== undefined) {
      clearInterval(this.refreshHandle);
    }
  }

  public render(): JSX.Element {
    if (this.refreshHandle !== undefined) {
      clearInterval(this.refreshHandle);
    }
    const { date, disableTooltip } = this.props;
    const dateString = date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);

    this.refreshHandle = window.setInterval(
      () => {
        this.forceUpdate();
      },
      refreshInterval(date),
    );
    return (
      <time data-chromatic="ignore" dateTime={date.toISO() ?? ''} title={(!disableTooltip && dateString) ? dateString : ''}>
        {date.toRelative()}
      </time>
    );
  }
}

export const RelativeDate = RelativeDateController;
export const RelativeTimestamp = RelativeDateController;
