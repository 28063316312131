import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { FC, useCallback } from 'react';
import { BottomNavigation, BottomNavigationAction } from '../../components';
import { useSmallScreen } from '../../hooks';
import { PageAction } from '../../types';
import { ProfilePageDesktopLayout } from './profile-page-desktop-layout';
import { ProfilePageMobileLayout } from './profile-page-mobile-layout';

interface Props {
  actions: PageAction[];
  renderAvatarComponent: () => JSX.Element;
  renderContent: () => JSX.Element;
  renderPageTitle: () => JSX.Element;
}

export const ProfilePageView: FC<Props> = ({ actions, renderAvatarComponent, renderContent, renderPageTitle }) => {
  const smallScreen = useSmallScreen();

  const pageActions = useCallback((): JSX.Element => {
    if (!actions.length) return <></>;
    if (!smallScreen) {
      return (
        <MenuList>
          {actions.map((action, ind) => (
            <MenuItem
              key={ind}
              onClick={action.onClick}
            >
              <ListItemIcon>
                {action.icon}
              </ListItemIcon>
              <ListItemText>
                {action.label}
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      );
    }

    return (
      <BottomNavigation>
        {actions.map((action, ind) => (
          <BottomNavigationAction
            key={ind}
            icon={action.icon}
            label={action.label}
            onClick={action.onClick}
          />
        ))}
      </BottomNavigation>
    );
  }, [smallScreen, actions]);

  if (smallScreen) {
    return (
      <ProfilePageMobileLayout
        avatarComponent={renderAvatarComponent()}
        content={renderContent()}
        pageActions={pageActions()}
        pageTitle={renderPageTitle()}
      />
    );
  }

  return (
    <ProfilePageDesktopLayout
      avatarComponent={renderAvatarComponent()}
      content={renderContent()}
      pageActions={pageActions()}
      pageTitle={renderPageTitle()}
    />
  );
};
