/* eslint-disable react-hooks/exhaustive-deps */
import { Id } from '@eagle/api-types';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef } from 'react';
import { from, of, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { ErrorMessage } from '../../components';
import { Search } from '../../components/search/search';
import { API_CALL_TEXT_LENGTH } from '../../constants';
import { useObservable } from '../../hooks';
import { FindItemsDeferredResult, Query } from '../../pages/list/types';
import { SearchProvider, useSearch } from '../../pages/list/use-search';
import { Undefinable } from '../../types';
import { DialogListView } from './view';

interface Props<T> {
  id: string;
  limit: number;
  onQueryChanged: (query: Query) => Undefinable<FindItemsDeferredResult<T>>;
  renderContent: (items: Undefinable<T[]>, isLoading: boolean, query?: string) => JSX.Element;
  triggerClearText?: number;
}

const InternalListPageController = <T extends Id<I>, I = string>({
  limit,
  onQueryChanged,
  renderContent,
  triggerClearText,
  id,
}: Props<T>): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    isLoading,
    pagination,
    setIsLoading,
    setPagination,
    setResult,
    setText,
    text,
  } = useSearch();

  const onError = (error: Error): void => {
    enqueueSnackbar(<ErrorMessage error={error} />, { variant: 'error' });
  };

  const { handleQueryChanged, observable } = useMemo(() => {
    const subject = new Subject<Query>();

    return {
      handleQueryChanged: (query: Query): void => subject.next(query),
      observable: subject.pipe(
        tap(() => setIsLoading(true)),
        debounceTime(350),
        switchMap((query: Query) => {
          if (query.search.length < API_CALL_TEXT_LENGTH) {
            return from([{ result: { results: [], itemCount: 0 } }])
              .pipe(
                tap(({ result }) => {
                  setIsLoading(false);
                  setResult({ itemCount: result.results.length, matchCount: result.itemCount });
                }),
                finalize(() => true),
              );
          }
          const deferred = onQueryChanged(query);
          if (!deferred) return of(undefined);

          let complete = false;

          return from(deferred.promise).pipe(
            tap(({ result }) => {
              setResult({ itemCount: result.results.length, matchCount: result.itemCount });
              complete = true;
            }),
            catchError((err: Error) => {
              onError(err);
              return of(undefined);
            }),
            finalize(() => complete || deferred.cancel()),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [onQueryChanged]);
  const data = useObservable(observable, onError);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      handleQueryChanged({ pagination: { limit, skip: pagination.skip }, search: text, filters: [] });
    }
  }, [pagination, text]);

  useEffect(() => {
    if (!triggerClearText) return;
    setText('');
  }, [triggerClearText]);

  const searchComponent = <Search id={id} focus={true} />;

  return (
    <DialogListView
      id={id}
      isLoading={isLoading}
      limit={limit}
      listContent={renderContent(data?.result.results, isLoading, text)}
      matchCount={data?.result.itemCount ?? 0}
      pagination={pagination}
      search={searchComponent}
      setPagination={setPagination}
    />
  );
};

export const DialogList = <T extends Id<I>, I = string>(props: Props<T>): JSX.Element => {
  return (
    <SearchProvider dataKey="dialog-list">
      <InternalListPageController<T, I> {...props} />
    </SearchProvider>
  );
};
