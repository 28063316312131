import { Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface StakeholderCardBaseProps extends PropsWithChildren {
  action?: ReactNode;
  hideRole?: boolean;
  role: string;
}

export const StakeholderCardBase: FC<StakeholderCardBaseProps> = ({ action, children, hideRole, role }) => {
  return (
    <Stack spacing={1}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {!hideRole && <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>{role}</Typography>}
        {action}
      </Stack>
      {children}
    </Stack>
  );
};
