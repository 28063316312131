import { Card, CardContent, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  activeStep: number;
  featureAlertSettings: JSX.Element;
  notificationRecipient: JSX.Element;
  thingPeopleFilters: JSX.Element;
}

export const CreateSubscriptionLayout: FC<Props> = ({ activeStep, featureAlertSettings, notificationRecipient, thingPeopleFilters }) => {
  const { t } = useTranslation(['admin']);

  return (
    <Card>
      <CardContent>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
        >
          <Step>
            <StepLabel>{t('admin:page.create-subscription.thing-people.labels')}</StepLabel>
            <StepContent TransitionProps={{ unmountOnExit: false }}>
              {thingPeopleFilters}
            </StepContent>
          </Step>
          <Step>
            <StepLabel>{t('admin:page.create-subscription.feature-alert-setting.labels')}</StepLabel>
            <StepContent TransitionProps={{ unmountOnExit: false }}>
              {featureAlertSettings}
            </StepContent>
          </Step>
          <Step>
            <StepLabel>{t('admin:page.create-subscription.notification-recipient.labels.heading')}</StepLabel>
            <StepContent TransitionProps={{ unmountOnExit: false }}>
              {notificationRecipient}
            </StepContent>
          </Step>
        </Stepper>
      </CardContent>
    </Card>
  );
};
