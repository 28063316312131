/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, PaginationItem } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { API_CALL_TEXT_LENGTH } from '../../constants';
import { useSmallScreen } from '../../hooks/use-small-screen';
import { testid } from '../../util';
import { Alert } from '../alert';
import { Pagination as PaginationData } from '../entity-search/types';
import { MiddleSpinner } from '../middle-spinner';
import { SearchResultsTableLayoutDesktop } from './layout-desktop';
import { SearchResultsTableLayoutMobile } from './layout-mobile';

interface Props<T> {
  disablePagination?: boolean;
  'data-testid'?: string;
  displayOnCard?: boolean;
  error?: Error;
  isLoading: boolean;
  items?: T[];
  matchCount: number;
  noResultsInstructions?: string | JSX.Element;
  pagination: PaginationData;
  renderDesktopContent: (isLoading: boolean, items: T[]) => JSX.Element;
  renderMobileContent: (displayOnCard: boolean, isLoading: boolean, items: T[]) => JSX.Element;
  setPagination: (value: PaginationData) => void;
  stickyHeader?: boolean;
  text?: string;
}

/**
  * NATIVE FUNCTION: View component of detail page
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function SearchResultsTableView<T>({
  disablePagination = false,
  displayOnCard = true,
  error,
  isLoading,
  items,
  matchCount,
  noResultsInstructions,
  pagination,
  renderDesktopContent,
  renderMobileContent,
  setPagination,
  stickyHeader = false,
  text,
  ...props
}: Props<T>): JSX.Element {
  const listPageScroll = document.getElementById('list-page-scroll') as HTMLDivElement;
  const smallScreen = useSmallScreen();
  const { t } = useTranslation(['common']);

  const paginationComponent = useMemo(
    () => matchCount > pagination.limit
      ? <Pagination
        count={Math.ceil(matchCount / pagination.limit)}
        disabled={isLoading}
        onChange={(_, page) => {
          setPagination({ limit: pagination.limit, skip: pagination.limit * (page - 1) });
          scrollIntoView(listPageScroll, { behavior: 'smooth', block: 'start' });
        }}
        renderItem={(item) => (
          <PaginationItem data-testid={testid`pagination-item-${item.type}${item.type === 'page' ? `-${item.page ?? ''}` : ''}`}
            {...item}
          />
        )}
        page={Math.floor(pagination.skip / pagination.limit) + 1}
        showFirstButton={!smallScreen}
        showLastButton={!smallScreen}
        size={smallScreen ? 'small' : 'large'}
      />
      : undefined,
    [isLoading, pagination, smallScreen, matchCount],
  );

  if (isLoading) return <MiddleSpinner />;
  if (error) return <Alert data-testid="error-results" severity="error" sx={{ my: 0 }}>{t('common:component.search.hint.unexpected-error-message')}</Alert>;
  if (text && text.length < API_CALL_TEXT_LENGTH) return <Alert data-testid="less-than-length-results" severity="info" sx={{ my: 0 }}>{t('common:component.search.hint.less-than-count', { count: API_CALL_TEXT_LENGTH })}</Alert>;
  if (!items?.length) return <Alert data-testid="no-search-results" severity="info" sx={{ my: 0 }}>{noResultsInstructions}</Alert>;

  if (smallScreen) {
    return (
      <SearchResultsTableLayoutMobile
        data-testid={props['data-testid']}
        content={renderMobileContent(displayOnCard, isLoading, items)}
        paginationComponent={disablePagination ? <></> : paginationComponent}
      />
    );
  }

  return (
    <SearchResultsTableLayoutDesktop
      data-testid={props['data-testid']}
      content={renderDesktopContent(isLoading, items)}
      paginationComponent={disablePagination ? <></> : paginationComponent}
      stickyHeader={stickyHeader}
    />
  );
}
