/* eslint-disable react-hooks/exhaustive-deps */
import { Time } from '@eagle/common';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeRangeChangeHandler } from '../../components/date-time-range';
import { useSearch } from '../../pages/list/use-search';
import { Undefinable } from '../../types';
import { hourCycle, testid } from '../../util';
import { CustomRange, FixedStartDate, RangeSelectOption, RangeSelectValues } from '../date-time-range-picker';

interface Props {
  'data-testid'?: string;
  isDateTimePickerOpen: boolean;
  onDateRangeChanged: DateTimeRangeChangeHandler;
  openDateTimePicker: () => void;
  rangeSelection: RangeSelectValues['key'];
  rangeSelectOptions: RangeSelectOption[];
  savedRange: CustomRange;
  setRangeSelection: (value: RangeSelectValues['key']) => void;
}

export const RangeSelect = forwardRef<HTMLDivElement, Props>(({
  isDateTimePickerOpen,
  onDateRangeChanged,
  openDateTimePicker,
  rangeSelection,
  rangeSelectOptions,
  savedRange,
  setRangeSelection,
  ...props
}, ref): JSX.Element => {
  const theme = useTheme();
  const hour12 = hourCycle();
  const { t } = useTranslation(['common']);
  const { startDate: savedStartDate, endDate: savedEndDate } = savedRange;
  const { setSavedSelection } = useSearch();
  const isCustomRangeSet = !!(savedStartDate && savedEndDate);
  const [fixedStartDate, setFixedStartDate] = useState<FixedStartDate>();
  const [fixedEndDate, setFixedEndDate] = useState(DateTime.now());

  const subTextNow = t('common:component.range-select.labels.now');
  const textChangeRange = t('common:component.range-select.action.change-custom-range');
  const textLabel = t('common:component.range-select.labels.displaying');
  const textPickRange = t('common:component.range-select.labels.pick-custom-range');

  const renderSubText = (start: Undefinable<DateTime>, end?: DateTime): string => (
    t('common:component.range-select.labels.time-range', {
      start: start?.toLocaleString({ ...DateTime.DATETIME_MED, year: undefined }),
      end: end?.toLocaleString({ ...DateTime.DATETIME_MED, year: undefined }) ?? subTextNow,
    })
  );

  const renderTitle = (option: string): JSX.Element => {
    if (isCustomRangeSet && rangeSelection === RangeSelectValues.PICK_CUSTOM_RANGE.key) return <Box>{isDateTimePickerOpen ? textChangeRange : renderSubText(savedStartDate, savedEndDate)}</Box>;

    const rangeSelectOption = rangeSelectOptions.filter(({ value }) => value.key === option).shift();
    return <Box>{rangeSelectOption?.label ?? textPickRange}</Box>;
  };

  useEffect(() => {
    const updateTime = setInterval(() => {
      setFixedEndDate(DateTime.now());
      setFixedStartDate(() => {
        const newFixedStartDates: FixedStartDate = {};
        rangeSelectOptions.map(({ value, startDate }) => Object.assign(newFixedStartDates, { [value.key]: startDate() }));
        return newFixedStartDates;
      });
    }, Time.seconds(1));
    return () => clearInterval(updateTime);
  }, []);

  useEffect(() => {
    if (rangeSelection === RangeSelectValues.PICK_CUSTOM_RANGE.key) return;

    rangeSelectOptions.map((option: RangeSelectOption) => {
      if (option.value.key !== rangeSelection) return;
      onDateRangeChanged(option.startDate().toJSDate(), option.endDate().toJSDate());
      setSavedSelection(rangeSelection);
    });
  }, [rangeSelection]); // adding rangeSelectOptions would cause infinite re-render because startDate() and endDate() would return new time

  return (
    <FormControl sx={{ width: hour12 ? '330px' : '280px' }} size="small" ref={ref}>
      <InputLabel data-chromatic="ignore" id="datepicker-label">{textLabel}</InputLabel>
      <Select
        label={textLabel}
        labelId="datepicker-label"
        onChange={(e: SelectChangeEvent<RangeSelectValues['key']>) => setRangeSelection(e.target.value)}
        renderValue={renderTitle}
        sx={{ width: 'inherit' }}
        value={rangeSelection}
        data-testid={props['data-testid']}
      >
        {rangeSelectOptions.map((option: RangeSelectOption, index: number) => {
          return (
            <MenuItem key={index} value={option.value.key} data-testid={testid`item-${option.value.key}`}>
              <Stack>
                <Typography>{option.label}</Typography>
                <Typography
                  sx={{ color: theme.palette.common.black, opacity: 0.54 }}
                  variant="caption"
                >
                  {renderSubText(fixedStartDate?.[option.value.key] ?? option.startDate(), fixedEndDate)}
                </Typography>
              </Stack>
            </MenuItem>
          );
        })}
        <MenuItem
          onClick={() => openDateTimePicker()}
          sx={{ borderTop: `solid 1px ${theme.palette.divider}` }}
          value="pick-custom-range"
          data-testid="item-custom"
        >
          <Stack direction="row" spacing={2} sx={{ py: isCustomRangeSet ? 0 : 1 }}>
            <DateRangeIcon sx={{ alignSelf: 'center' }} />
            <Stack>
              <Typography>
                {isCustomRangeSet ? textChangeRange : textPickRange}
              </Typography>
              {isCustomRangeSet &&
                <Typography
                  sx={{ color: theme.palette.common.black, opacity: 0.54 }}
                  variant="caption"
                >
                  {renderSubText(savedStartDate, savedEndDate)}
                </Typography>
              }
            </Stack>
          </Stack>
        </MenuItem>
      </Select>
    </FormControl>
  );
});

RangeSelect.displayName = 'RangeSelect';
