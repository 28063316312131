/* eslint-disable react-hooks/exhaustive-deps */
import { FormHelperTextProps, InputLabelProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { debounce } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { TextEditor } from '../text-editor';

interface Props {
  'data-testid'?: string;
  blurOnEnter?: boolean;
  disabled?: boolean;
  emailError?: string;
  endAdornment?: JSX.Element;
  FormHelperTextProps?: FormHelperTextProps & { 'data-testid'?: string };
  fullWidth?: boolean;
  InputLabelProps?: InputLabelProps;
  label?: string;
  onChange?: (value: string) => Promise<void>;
  onValidation?: (value: boolean) => void;
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  sx?: SxProps;
  type?: string;
  value?: string;
}

export const EmailEditor: FC<Props> = ({ emailError, onChange, onValidation, required, value: initialValue, sx, ...props }) => {
  const [showError, setShowError] = useState(false);
  const [value, setValue] = useState(initialValue ?? '');
  const { t } = useTranslation(['common']);

  const handleEmailChange = (val: string): void => {
    const valid = validator.isEmail(val) || (required ? false : !val.length);
    setShowError(!valid);
    onValidation?.(valid);
    void onChange?.(val);
  };

  const handleEmailChangeDebounced = useCallback(debounce(handleEmailChange, 100), []);

  const handleChange = (val: string): Promise<void> => {
    setValue(val);
    handleEmailChange(val);
    return Promise.resolve();
  };

  const handleKeyUp = (val: string): void => {
    setValue(val);
    handleEmailChangeDebounced(val);
  };

  const helperText = useMemo(() => {
    if (showError) return t('common:common.hint.email');
    return emailError;
  }, [showError, emailError, t]);

  return (
    <TextEditor
      {...props}
      error={showError || !!emailError}
      helperText={helperText}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      required={required}
      value={value}
      sx={sx}
    />
  );
};
