import { Box, Grid, Typography } from '@mui/material';
import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/system';
import { FC } from 'react';
import { FlexBox } from '../flex-box';
import { InternalProps } from './types';

const Row = styled((props: StackProps) => (
  <Stack direction="row" spacing={2} useFlexGap {...props} />
))({
  alignItems: 'center',
});

export const Layout: FC<InternalProps> = ({ listContent, paginationComponent, search, title, subtitle, ...props }) => (
  <FlexBox data-testid={props['data-testid']} className="ListPage-root" sx={{ flexGrow: 0, width: 1 }}>
    <Stack spacing={1} sx={{ py: 1, width: 1 }}>
      <Row sx={{ lineHeight: '2rem' }}>
        <Grid container alignContent='center' justifyContent='space-between' spacing={1}>
          <Grid item sm={12} md={6} sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
            {title}
          </Grid>
          <Grid item sm={12} md={6} sx={{ width: 1 }}>
            {search}
          </Grid>
          {subtitle &&
            <Grid item sm={12} md={12} sx={{ width: 1 }}>
              <Typography variant='subtitle2' fontStyle="italic" color="text.secondary" sx={{ my: 0 }}>{subtitle}</Typography>
            </Grid>
          }
        </Grid>
      </Row>
      <Stack spacing={2} sx={{ py: 1 }}>
        <Box>
          {listContent}
        </Box>
        {paginationComponent
          ? <Box display="flex" sx={{ justifyContent: 'center' }}>
            {paginationComponent}
          </Box>
          : null
        }
      </Stack>
    </Stack>
  </FlexBox>
);
