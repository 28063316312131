import { Badge as MUIBadge, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface Props extends PropsWithChildren {
  badgeContent?: ReactNode;
  component?: string;
  'data-testid'?: string;
  invisible?: boolean;
  overlap?: 'rectangular' | 'circular';
  sx?: SxProps<Theme>;
  variant?: 'dot' | 'standard';
}

export const Badge: FC<Props> = ({ children, ...props }) => {
  return <MUIBadge {...props} color='primary'>{children}</MUIBadge>;
};
