export const TRACK_PERSON_DETAIL_DEFAULT_TEMPLATE = [
  {
    op: 'ui',
    name: 'Stack',
    direction: 'column',
    flex: [2, 2],
    spacing: 2,
    sx: { minWidth: 0 },
    children:
      [
        {
          op: 'ui',
          name: 'PersonCard',
          entity: {
            op: 'context',
            path: 'entity',
          },
          entityType: {
            op: 'context',
            path: 'entityType',
          },
          portal: {
            op: 'context',
            path: 'portal',
          },
        },
        {
          op: 'ui',
          name: 'GroupsCard',
          groupIds: {
            op: 'context',
            path: 'groupIds',
          },
          noGroupsText: {
            op: 'context',
            path: 'noGroupsText',
          },
          isClickable: {
            op: 'context',
            path: 'isClickable',
          },
        },
        {
          op: 'ui',
          name: 'PersonThingCard',
          person: {
            op: 'context',
            path: 'entity',
          },
          portal: {
            op: 'context',
            path: 'portal',
          },
        },
      ],
  },
  {
    op: 'ui',
    name: 'Stack',
    direction: 'column',
    flex: [3, 3],
    spacing: 2,
    children:
      [
        {
          op: 'context',
          path: 'personMap',
        },
        {
          op: 'context',
          path: 'personAlerts',
        },
      ],
  },
];

export const ADMIN_PERSON_DETAIL_DEFAULT_TEMPLATE = [
  {
    op: 'ui',
    name: 'Stack',
    direction: 'column',
    flex: [2, 2],
    spacing: 2,
    sx: { minWidth: 0 },
    children:
    [
      {
        op: 'context',
        path: 'personDetailsCard',
      },
      {
        op: 'ui',
        name: 'PersonThingCard',
        person: {
          op: 'context',
          path: 'entity',
        },
        portal: {
          op: 'context',
          path: 'portal',
        },
      },
    ],
  },
  {
    op: 'context',
    path: 'assignDialog',
  },
  {
    op: 'context',
    path: 'deleteEntityDialog',
  },
  {
    op: 'ui',
    name: 'Stack',
    direction: 'column',
    flex: [3, 3],
    sx: { pt: 2 },
    children:
    [
      {
        op: 'context',
        path: 'personGroupLists',
      },
    ],
  },
];
