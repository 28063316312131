import { FC } from 'react';
import { DrawerSize } from '../event-history.types';
import { TwoWayCollapseLayoutDesktop } from './two-way-collapse-layout-desktop';

interface Props {
  bottomDrawerContent: JSX.Element;
  bottomDrawerExited: boolean;
  bottomDrawerHeight: DrawerSize;
  renderBottomDrawerHeaderIcon: () => JSX.Element;
  renderBottomDrawerHeaderTitle: () => JSX.Element;
  renderTopDrawerHeaderIcon: () => JSX.Element;
  renderTopDrawerHeaderTitle: () => JSX.Element;
  setBottomDrawerExited: (exited: boolean) => void;
  setBottomDrawerHeight: (height: DrawerSize) => void;
  topDrawerContent: JSX.Element;
}

export const TwoWayCollapseView: FC<Props> = ({
  bottomDrawerContent,
  bottomDrawerExited,
  bottomDrawerHeight,
  renderBottomDrawerHeaderIcon,
  renderBottomDrawerHeaderTitle,
  renderTopDrawerHeaderIcon,
  renderTopDrawerHeaderTitle,
  setBottomDrawerExited,
  setBottomDrawerHeight,
  topDrawerContent,
}): JSX.Element => {
  return (
    <TwoWayCollapseLayoutDesktop
      bottomDrawerContent={bottomDrawerContent}
      bottomDrawerExited={bottomDrawerExited}
      bottomDrawerHeaderIcon={renderBottomDrawerHeaderIcon()}
      bottomDrawerHeaderTitle={renderBottomDrawerHeaderTitle()}
      bottomDrawerHeight={bottomDrawerHeight}
      handleBottomDrawerEnter={() => setBottomDrawerExited(false)}
      handleBottomDrawerExit={() => setBottomDrawerExited(true)}
      setBottomDrawerHeight={setBottomDrawerHeight}
      topDrawerContent={topDrawerContent}
      topDrawerHeaderIcon={renderTopDrawerHeaderIcon()}
      topDrawerHeaderTitle={renderTopDrawerHeaderTitle()}
    />
  );
};
