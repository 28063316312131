import { Stack } from '@mui/material';
import { ChangeEvent } from 'react';
import { CommonEntity } from '../../../../types';
import { LayerSelectionCheckBox } from '../layer-selection-check-box';

interface Props<T> {
  handleAllChange: () => void;
  handleIndividualChange: (e: ChangeEvent<HTMLInputElement>) => void;
  indeterminate: boolean;
  isAllChecked: boolean;
  isSubSectionItemChecked: (id: string) => boolean;
  subSectionItems: T[];
  subSectionLabel: string;
}

/**
  * NATIVE FUNCTION: Component for Layer Selection - Sub Menu Section
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function SubMenuSection<T extends CommonEntity>({
  handleAllChange,
  handleIndividualChange,
  indeterminate,
  isAllChecked,
  isSubSectionItemChecked,
  subSectionItems,
  subSectionLabel,
}: Props<T>): JSX.Element {
  return (
    <Stack sx={{ mt: 1 }}>
      <LayerSelectionCheckBox
        checked={isAllChecked}
        handleClick={handleAllChange}
        indeterminate={indeterminate}
        label={subSectionLabel}
      />
      <Stack sx={{ ml: 3 }}>
        {subSectionItems?.map((item, i) =>
          <LayerSelectionCheckBox
            key={i}
            checked={isSubSectionItemChecked(item._id)}
            handleClick={handleIndividualChange}
            label={item.display}
            value={item._id}
          />,
        )}
      </Stack>
    </Stack>
  );
}
