import { ServiceType } from '@eagle/core-data-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  thingId: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  serviceTypes: ServiceType[];
}

export const ServiceTypeSelect: FC<Props> = ({ thingId, onChange, value, serviceTypes }) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<string>('');

  useEffect(() => {
    if (serviceTypes && serviceTypes.length > 0) {
      if (value) {
        setSelectedValue(value);
      } else {
        setSelectedValue(serviceTypes[0].serviceTypeId);
        onChange({ target: { value: serviceTypes[0].serviceTypeId } });
      }
    }
  }, [serviceTypes, value, onChange]);

  if (!thingId) return <></>;

  return (
    <FormControl size='small'>
      <InputLabel id="select-service-type-label">
        {t('common:page.thing-detail.service-history-dialog.select-service-type.labels')}
      </InputLabel>
      <Select
        data-testid="service-type-input"
        labelId="select-service-type-label"
        label={t('common:page.thing-detail.service-history-dialog.select-service-type.labels')}
        MenuProps={{
          style: { zIndex: 1303 },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSelectedValue(e.target.value);
          onChange(e);
        }}
        value={selectedValue}
      >
        {serviceTypes.map((serviceType, i) => (
          <MenuItem key={i} value={serviceType.serviceTypeId} data-testid={`select-menu-item-${i}`}>
            {serviceType.display}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
