import { makeStyles } from '../../theme';

export const drawerContentStyles = makeStyles()(() => ({
  menuItemsContainer: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  overflowContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    animation: '$hide-scroll 250ms backwards',
  },
  overflowContainerHidden: {
    overflow: 'hidden',
  },
  '@keyframes hide-scroll': {
    'from, to': { overflowY: 'hidden' },
  },
}));
