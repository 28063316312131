/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Observable, Subscription } from 'rxjs';
import { Undefinable } from '../types';

export const useObservable = <T>(observable: Observable<T>, onError?: (error: Error) => unknown): Undefinable<T> => {
  const [state, setState] = useState<T>();
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  useEffect(() => {
    const sub = observable.subscribe({
      next: (data) => setState(data),
      error: (error: Error) => {
        onError?.(error);
      },
    });
    setSubscription(sub);
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [observable]);

  return state;
};
