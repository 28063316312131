import { Button } from '@mui/material';
import { FC, useMemo } from 'react';
import { FilterBar } from '../../components/filter';
import { testid } from '../../util/test-id';
import { ListLayoutDesktop } from './index';
import { InternalProps } from './view.types';

export const ListPageViewDesktop: FC<InternalProps> = ({
  actions,
  appliedFiltersComponents,
  clearAllFiltersComponent,
  filterDisabled,
  filterDrawerComponent,
  filtersLength,
  icon,
  listContent,
  openFilter,
  paginationComponent,
  scrollRef,
  search,
  setFilterOpen,
  showFilterButton,
  subtitleComponent,
  tableContent,
  title,
  ...props
}): JSX.Element => {
  const filterButtonComponent = (
    <FilterBar
      disabled={filterDisabled}
      filtersLength={filtersLength}
      onFilterClicked={() => setFilterOpen(!openFilter)}
    />
  );

  const actionComponents = useMemo(
    () => actions?.length
      ? actions.map((action, i) => (
        <Button
          key={i}
          data-testid={testid`${action.label}-button`}
          href={action.href}
          onClick={action.onClick}
          startIcon={action.icon}
        >
          {action.label}
        </Button>
      ))
      : [],
    [actions],
  );

  return (
    <ListLayoutDesktop
      actions={actionComponents}
      clearAllFiltersButton={clearAllFiltersComponent}
      content={listContent ?? tableContent ?? <></>}
      data-testid={props['data-testid']}
      filterButton={filterButtonComponent}
      filterContent={filterDrawerComponent}
      filters={appliedFiltersComponents}
      icon={icon}
      pagination={listContent && paginationComponent}
      scrollRef={scrollRef}
      search={search}
      setFilterOpen={setFilterOpen}
      showFilterButton={showFilterButton}
      showFilters={openFilter}
      subtitle={subtitleComponent}
      title={title}
    />
  );
};
