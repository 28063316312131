import { Card, CardContent, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { T_MANY } from '../../constants';
import { CacheDataTypes } from '../../types/cache';
import { GroupChipElement } from '../group-chip-element';
import { DataRow } from '../static-data/data-row';

interface Props {
  groupIds: string[];
  isClickable?: boolean;
  noGroupsText: string;
}

export const GroupsCard: FC<Props> = ({ groupIds, isClickable = false, noGroupsText }) => {
  const { t } = useTranslation(['common']);

  return <Card sx={{ height: 'min-content' }}>
    <CardContent>
      <Stack spacing={2}>
        <Typography variant="h5" color="text.primary">{t('common:terms.group', { count: T_MANY })}</Typography>
        {!groupIds.length
          ? <Typography fontStyle="italic" color="text.secondary" variant="body2">
            {noGroupsText}
          </Typography>
          : <DataRow value={<GroupChipElement groupIds={groupIds} type={CacheDataTypes.GROUP} isClickable={isClickable} />} />
        }
      </Stack>
    </CardContent>
  </Card>;
};
