/* eslint-disable react-hooks/exhaustive-deps */
import { Id } from '@eagle/api-types';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { from, of, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { API_CALL_TEXT_LENGTH } from '../../constants';
import { useObservable } from '../../hooks';
import { FindItemsDeferredResult, Query } from '../../pages/list/types';
import { SearchProvider, useSearch } from '../../pages/list/use-search';
import { Undefinable } from '../../types';
import { Pagination as PaginationData } from '../entity-search/types';
import { ErrorMessage } from '../error-message';

interface Props<T> {
  limit?: number;
  onQueryChanged: (query: Query) => Undefinable<FindItemsDeferredResult<T>>;
  refresh?: Date;
  renderContent: (params: {
    isLoading: boolean;
    items: Undefinable<T[]>;
    matchCount: number;
    pagination: PaginationData;
    setPagination: (value: PaginationData) => unknown;
    hasError: boolean;
  }) => JSX.Element;
}

const InternalGeneralPaginatedListController = <T extends Id<string>>({
  onQueryChanged,
  refresh,
  renderContent,
}: Props<T>): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    isLoading,
    pagination,
    setIsLoading,
    setPagination,
    setResult,
  } = useSearch();
  const [hasError, setHasError] = useState(false);

  const onError = (error: Error): void => {
    enqueueSnackbar(<ErrorMessage error={error} />, { variant: 'error' });
    setHasError(true);
  };

  const { handleQueryChanged, observable } = useMemo(() => {
    const subject = new Subject<Query>();

    return {
      handleQueryChanged: (query: Query): void => subject.next(query),
      observable: subject.pipe(
        tap(() => setIsLoading(true)),
        debounceTime(350),
        switchMap((query: Query) => {
          if (query.search.length < API_CALL_TEXT_LENGTH && query.search.length !== 0) {
            return from([{ result: { results: [], itemCount: 0 }, resultDescription: '' }])
              .pipe(
                tap(({ result }) => {
                  setIsLoading(false);
                  setResult({ itemCount: result.results.length, matchCount: result.itemCount });
                }),
                finalize(() => true),
              );
          }

          const deferred = onQueryChanged(query);
          if (!deferred) return of(undefined);

          let complete = false;

          return from(deferred.promise).pipe(
            tap(({ result }) => {
              setResult({ itemCount: result.results.length, matchCount: result.itemCount });
              complete = true;
            }),
            catchError((err: Error) => {
              onError(err);
              return of(undefined);
            }),
            finalize(() => complete || deferred.cancel()),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [onQueryChanged]);
  const data = useObservable(observable, onError);

  useEffect(() => {
    if (data?.result.results.length === 0 && pagination.skip > pagination.limit) {
      setPagination({
        limit: pagination.limit,
        skip: pagination.skip - pagination.limit,
      });
    }
  }, [data]);

  useEffect(() => {
    handleQueryChanged({ search: '', filters: [], pagination });
  }, [pagination, refresh]);

  return (
    renderContent(
      {
        isLoading,
        items: data?.result.results,
        matchCount: data?.result.itemCount ?? 0,
        pagination,
        setPagination,
        hasError,
      })
  );
};

export const GeneralPaginatedList = <T extends Id<string>>(props: Props<T>): JSX.Element => (
  <SearchProvider dataKey="general-paginated-list" pagination={props.limit !== undefined ? { limit: props.limit, skip: 0 } : undefined}>
    <InternalGeneralPaginatedListController<T> {...props} />
  </SearchProvider>
);
