import { Time } from '@eagle/common';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { Undefinable } from '../types';
import { getLocale } from './locale';
export const SEGMENT_MINUTES = 20;

export enum ROUND {
  DOWN,
  NEAREST,
  UP,
}

const ROUND_TO_NEAREST_MINUTES = Time.MINUTE * SEGMENT_MINUTES;

export const getFormattedDateTime = (isoDate: string): string => {
  return DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
};

export const getFormattedTime = (date: DateTime, format = DateTime.TIME_SIMPLE): string => {
  return date.toLocaleString({ ...format }, { locale: navigator.language });
};

export const getFormattedTimeWithSeconds = (date: DateTime): string => {
  return date.toLocaleString({ ...DateTime.TIME_WITH_SECONDS }, { locale: navigator.language });
};

export const getFormattedDate = (date: DateTime, format = DateTime.DATE_FULL): string => {
  return date.toLocaleString({ ...format }, { locale: navigator.language });
};

export const formatDateRange = (start: DateTime, end: DateTime): string => {
  return `${start.toLocaleString(DateTime.DATE_MED)} - ${end.toLocaleString(DateTime.DATE_MED)}`;
};

export const formatDateTimeRange = (start: DateTime, end: DateTime): string => {
  return `${start.toLocaleString(DateTime.DATETIME_MED)} - ${end.toLocaleString(DateTime.TIME_SIMPLE)}`;
};

export const formatTimeRange = (startTime: Date, endTime: Date): string => {
  const locale = getLocale().code;
  const formatOptions: DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
  return `${startTime.toLocaleTimeString(locale, formatOptions)} - ${endTime.toLocaleTimeString(locale, formatOptions)}`;
};

export const mergeDateAndTime = (
  date: DateTime = DateTime.now(),
  time: DateTime = DateTime.now(),
): DateTime => {
  return date.set({
    hour: time.get('hour'),
    minute: time.get('minute'),
    second: time.get('second'),
    millisecond: time.get('millisecond'),
  });
};

export const formatDateToDateTime = (date: Date): string => {
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_MED);
};

export const formatDateToDateTimeShort = (date: Date): string => {
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_SHORT);
};

export const isMatchingDay = (dateIn: DateTime, date: DateTime): Undefinable<boolean> => {
  return date.startOf('day').equals(dateIn.startOf('day'));
};

export const formatShortDate = (date: DateTime): string => {
  return date.toLocaleString({ month: 'short', day: 'numeric' });
};

export enum DateErrors {
  UNPARSABLE = 'unparsable',
  OUT_OF_RANGE = 'unit out of range',
}

export enum DateTimeRangeError {
  FUTURE_DATE = 'future-date',
  INVALID_DATE = 'invalid-date',
  INVALID_FORMAT = 'invalid-format',
  INVALID_RANGE = 'invalid-range',
  INVALID_RANGE_OVER = 'invalid-range-over',
  INVALID_TIME = 'invalid-time',
}

export const getDateFormatString = (): string => {
  const formatObj = new Intl.DateTimeFormat(navigator.language).formatToParts(new Date());

  return formatObj.map((obj) => {
    switch (obj.type) {
      case 'day':
        return 'dd';
      case 'month':
        return 'MM';
      case 'year':
        return 'yyyy';
      default:
        return obj.value;
    }
  }).join('');
};

export const getTimeFormatString = (format: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric' }): string => {
  const formatObj = new Intl.DateTimeFormat(navigator.language, format).formatToParts(Date.now());
  const format12Hour = formatObj.some((obj) => obj.type === 'dayPeriod');

  if (format12Hour) {
    return formatObj.map((obj) => {
      switch (obj.type) {
        case 'hour':
          return 'hh';
        case 'minute':
          return 'mm';
        case 'second':
          return 'ss';
        case 'dayPeriod':
          return 'a';
        default:
          return obj.value === ':' ? obj.value : ' ';
      }
    }).join('');
  }

  return formatObj.map((obj) => {
    switch (obj.type) {
      case 'hour':
        return 'HH';
      case 'minute':
        return 'mm';
      case 'second':
        return 'ss';
      default:
        return obj.value === ':' ? obj.value : ' ';
    }
  }).join('');
};

export const roundTimeToInterval = (time: Date, rounding: ROUND, roundTo = ROUND_TO_NEAREST_MINUTES): DateTime => {
  const calculation = time.getTime() / roundTo;
  const roundUpOrDown = (): number => {
    switch (rounding) {
      case ROUND.DOWN: return Math.floor(calculation);
      case ROUND.NEAREST: return Math.round(calculation);
      case ROUND.UP: return Math.ceil(calculation);
      default: return Math.round(calculation);
    }
  };
  return DateTime.fromMillis(roundUpOrDown() * roundTo);
};
