import { FC } from 'react';
import { useParams } from 'react-router';
import { Navigate, Params } from 'react-router-dom';

export interface RedirectProps {
  to: string;
  state?: any;
}

export const Redirect: FC<RedirectProps> = ({ to: redirectPath, ...rest }) => {
  const routeParams = useParams();
  const updateTo = (to: string, params: Readonly<Params<string>>): string => {
    const entries = Object.entries(params);
    let path = `${to}`;
    entries.forEach(([key, value]) => { path = path.replace(`:${key}`, `${value ?? ''}`); });
    return path;
  };

  return <Navigate to={updateTo(redirectPath, routeParams)} {...rest} replace />;
};
