
import RoomIcon from '@mui/icons-material/Room';
import { useTheme } from '@mui/material';
import { FC } from 'react';
import { Marker } from 'react-leaflet';
import { getIcon } from '../../util/maps';
import { LocationPinProps } from './page-map.types';

export const LocationPin: FC<LocationPinProps> = ({ children, position, sx }) => {
  const theme = useTheme();
  return (
    <Marker
      icon={getIcon(<RoomIcon
        sx={{
          alignSelf: 'center',
          color: theme.pin.search,
          overflow: 'visible',
          transform: 'scale(1.75)',
          transformOrigin: '50% 100%',
          '& svg': {
            zIndex: 999999,
          },
          ...sx,
        }}
      />)}
      position={position}
    >
      {children}
    </Marker>
  );
};
