/**
 * This function allow you to modify a JS Promise by adding some status properties.
 * Based on: http://stackoverflow.com/questions/21485545/is-there-a-way-to-tell-if-an-es6-promise-is-fulfilled-rejected-resolved
 * But modified according to the specs of promises : https://promisesaplus.com/
 */

import { Maybe } from '../types';

export interface QueryablePromise<T> extends Promise<T> {
  isFulfilled: () => boolean;
  isPending: () => boolean;
  isRejected: () => boolean;
}

class QueryablePromiseImpl<T> implements QueryablePromise<T> {
  private fulfilledInternal = false;
  private pendingInternal = true;
  private rejectedInternal = false;

  private readonly promise: Promise<T>;

  constructor(promise: Promise<T>) {
    this.promise = promise.then(
      (v) => {
        this.fulfilledInternal = true;
        this.pendingInternal = false;
        return v;
      },
      (e) => {
        this.rejectedInternal = true;
        this.pendingInternal = false;
        throw e;
      },
    );
  }

  public isFulfilled(): boolean {
    return this.fulfilledInternal;
  }

  public isPending(): boolean {
    return this.pendingInternal;
  }

  public isRejected(): boolean {
    return this.rejectedInternal;
  }

  public then<TResult1 = T, TResult2 = never>(
    onFulfilled?: Maybe<((value: T) => TResult1 | PromiseLike<TResult1>)>,
    onRejected?: Maybe<((reason: any) => TResult2 | PromiseLike<TResult2>)>,
  ): Promise<TResult1 | TResult2> {
    return this.promise.then(onFulfilled, onRejected);
  }

  public catch<R = never>(onRejected?: Maybe<((reason: any) => R | PromiseLike<R>)>): Promise<T | R> {
    return this.promise.catch(onRejected);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public get [Symbol.toStringTag]() {
    return 'QueryablePromiseImpl';
  }

  public finally(onFinally?: Maybe<(() => void)>): Promise<T> {
    return this.promise.finally(onFinally);
  }
}

export const makeQueryablePromise = <T>(promise: Promise<T>): QueryablePromise<T> => {
  return new QueryablePromiseImpl(promise);
};

export type PromiseState = 'pending' | 'rejected' | 'resolved';
