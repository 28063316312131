import { FC } from 'react';
import { useSmallScreen } from '../../../hooks';
import { MobileTabSelection } from '../event-history.types';
import { HistoryDrawerLayoutDesktop } from './history-drawer-layout-desktop';
import { HistoryDrawerLayoutMobile } from './history-drawer-layout-mobile';

interface Props {
  desktopActions: JSX.Element;
  historyContent: JSX.Element;
  isDrawerOpen: boolean;
  mobileActions: JSX.Element;
  mobileTabSelection: MobileTabSelection;
  renderAddEntityDialog: () => JSX.Element;
  renderDrawerTitle: () => JSX.Element;
  renderOpenDrawerIcon: () => JSX.Element;
}

export const HistoryDrawerView: FC<Props> = ({
  desktopActions,
  historyContent,
  isDrawerOpen,
  mobileActions,
  renderAddEntityDialog,
  renderDrawerTitle,
  renderOpenDrawerIcon,
}): JSX.Element => {
  const smallScreen = useSmallScreen();

  if (smallScreen) {
    return (
      <HistoryDrawerLayoutMobile
        addEntityDialog={renderAddEntityDialog()}
        content={historyContent}
        historyActions={mobileActions}
      />
    );
  }

  return (
    <HistoryDrawerLayoutDesktop
      addEntityDialog={renderAddEntityDialog()}
      drawerTitle={renderDrawerTitle()}
      eventHistory={historyContent}
      historyActions={desktopActions}
      isDrawerOpen={isDrawerOpen}
      isDrawerOpenIcon={renderOpenDrawerIcon()}
    />
  );
};
