/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import FilterIcon from '@mui/icons-material/FilterAltRounded';
import { Box, Button, Stack } from '@mui/material';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '../../components/badge';
import { BottomNavigationAction } from '../../components/bottom-navigation';
import { DateTimeRange } from '../../components/date-time-range';
import { CustomRange, DateTimeRangePicker, RangeSelectValues } from '../../components/date-time-range-picker';
import { FilterBar, FilterElement } from '../../components/filter';
import { FilterToolbar } from '../../components/filter/filter-toolbar';
import { createHourSelectOption, RangeControl, RangeSelect } from '../../components/range-select';
import { ALERT_LIST_RANGE_KEY } from '../../constants';
import { useSmallScreen } from '../../hooks';
import { getLocalStorageItem, setLocalStorageItem } from '../../util';
import { DateTimeRangePickerProvider } from '../../util/data-time-range-picker';
import { testid } from '../../util/test-id';
import { LayoutDesktop } from '../list/layout-desktop';
import { LayoutMobile } from '../list/layout-mobile';
import { useSearch } from '../list/use-search';
import { FilterDrawerTabs } from './filter-drawer-tabs';
import { AlertTabKey, Props } from './view.types';

export const AlertListPageView: FC<Props> = ({
  actions,
  alertTabFilters,
  appliedFilters,
  dateOptions,
  dateRange,
  defaultDateLabel,
  defaultDateRange,
  filterDrawerComponent,
  icon,
  listContent,
  onDateRangeChanged,
  onFiltersChanged,
  openDateRange,
  openFilter,
  removeAllFilters,
  removeFilter,
  setDateRangeOpen,
  setFilterOpen,
  showFilterButton = true,
  subtitle,
  title,
  ...props
}): JSX.Element => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const searchDropRef = useRef<HTMLDivElement>(null);
  const smallScreen = useSmallScreen();
  const { setSavedSelection } = useSearch();
  const { t } = useTranslation(['common']);
  const [rangeSelection, setRangeSelection] = useState<RangeSelectValues['key']>(
    () => getLocalStorageItem(ALERT_LIST_RANGE_KEY, {
      rangeSelection: RangeSelectValues.LAST_12_HOURS.key,
    }).rangeSelection);
  const [isDateTimePickerOpen, setIsDateTimePickerOpen] = useState(false);
  const [savedRange, setSavedRange] = useState<CustomRange>({
    startDate: dateRange?.startTime,
    endDate: dateRange?.endTime,
  });

  const datePickerComponent = (
    <DateTimeRangePickerProvider>
      <DateTimeRangePicker
        data-testid='alerts-picker'
        closeDateTimePicker={() => setIsDateTimePickerOpen(false)}
        isDateTimePickerOpen={isDateTimePickerOpen}
        onDateRangeChanged={onDateRangeChanged}
        savedRange={savedRange}
        setRangeSelection={setRangeSelection}
        setSavedRange={setSavedRange}
      />
    </DateTimeRangePickerProvider>
  );

  const actionComponents = useMemo(() => {
    if (!actions?.length) return <></>;
    return actions.map((action, i) => (
      <Stack key={i} direction="row" sx={{ alignItems: 'center' }}>
        {action.info}
        <Button
          data-testid={testid`action-button-${action.label}`}
          onClick={action.onClick}
          startIcon={action.icon}
        >
          {action.label}
        </Button>
      </Stack>
    ));
  }, [actions]);

  const defaultFilterDrawerComponent = <>
    <FilterToolbar onCloseClicked={() => setFilterOpen(false)} />
    <FilterDrawerTabs
      alertTabFilters={alertTabFilters}
      onCloseClicked={() => setFilterOpen(false)}
      setTabFilters={onFiltersChanged}
      tabsFilters={appliedFilters}
    />
  </>;

  const dateComponent = useMemo(() => (
    <DateTimeRange
      dateOptions={dateOptions}
      placeholderText={t('common:common.labels.date-range')}
      defaultStart={defaultDateRange.startTime}
      defaultLabel={defaultDateLabel}
      defaultEnd={defaultDateRange.endTime}
      onChange={onDateRangeChanged}
      size="small"
      sx={{
        width: '100%',
        '& .MuiInputLabel-root': { py: '0.15rem' },
        '& .MuiOutlinedInput-input': { px: '1rem', py: '0.657rem' },
      }}
    />
  ), []);

  const rangeSelectComponent = (
    <Box sx={{ minHeight: '85px' }}>
      <RangeSelect
        data-testid='alert-range-select'
        ref={searchDropRef}
        isDateTimePickerOpen={isDateTimePickerOpen}
        onDateRangeChanged={onDateRangeChanged}
        openDateTimePicker={() => setIsDateTimePickerOpen(true)}
        rangeSelection={rangeSelection}
        rangeSelectOptions={[
          createHourSelectOption(RangeSelectValues.LAST_HOUR),
          createHourSelectOption(RangeSelectValues.LAST_12_HOURS),
          createHourSelectOption(RangeSelectValues.LAST_24_HOURS),
        ]}
        savedRange={savedRange}
        setRangeSelection={setRangeSelection}
      />
      <RangeControl
        onDateRangeChanged={onDateRangeChanged}
        renderControl={rangeSelection === RangeSelectValues.PICK_CUSTOM_RANGE.key}
        savedRange={savedRange}
        setSavedRange={setSavedRange}
      />
    </Box>
  );

  const tabKeys = Object.keys(alertTabFilters) as (AlertTabKey)[];
  const hasFilters = tabKeys.some((key) => !!appliedFilters[key].length);
  const filtersLength = tabKeys
    .map((key) => appliedFilters[key].length)
    .reduce((acc, item) => acc += item, 0);

  const appliedFiltersComponents = useMemo(
    () => hasFilters
      ? <>
        {tabKeys.map((key) =>
          appliedFilters[key].map((filter) =>
            <FilterElement
              key={filter.id}
              filter={filter}
              onRemoveClicked={() => removeFilter(filter, key)}
              sx={{ my: .5 }}
            />,
          ))}
      </>
      : undefined,
    [appliedFilters],
  );

  const clearAllFiltersButton = (
    <Button
      data-testid="clear-filters-button"
      onClick={removeAllFilters}
      startIcon={<CloseIcon />}
      sx={{ mr: 1 }}
      variant="text"
    >
      {t('common:component.filter.actions.clear')}
    </Button>
  );

  const filterIcon = (
    <Badge badgeContent={filtersLength} sx={{ '& .MuiBadge-badge': { top: '3px', right: '-2px' } }}>
      <FilterIcon />
    </Badge>
  );

  const filterButtonComponent = (
    <FilterBar
      filtersLength={filtersLength}
      onFilterClicked={() => setFilterOpen(!openFilter)}
    />
  );

  useEffect(() => {
    setSavedSelection?.(rangeSelection);
    setLocalStorageItem(ALERT_LIST_RANGE_KEY, { rangeSelection });
  }, [rangeSelection]);

  if (smallScreen) {
    return (
      <LayoutMobile
        actions={[
          <BottomNavigationAction key={'dateRange'} label={t('common:common.labels.date-range')} icon={dateComponent} data-testid="action-button-date-range" />,
          <BottomNavigationAction
            key={'filter'}
            data-testid="action-button-filter"
            icon={filterIcon}
            label={t('common:terms.filter')}
            onClick={() => setFilterOpen(true)}
          />,
        ]}
        clearAllFiltersButton={clearAllFiltersButton}
        content={listContent}
        data-testid={props['data-testid']}
        filterContent={filterDrawerComponent ?? defaultFilterDrawerComponent}
        filters={appliedFiltersComponents}
        pagination={undefined}
        primaryActions={actionComponents}
        scrollRef={scrollRef}
        search={<></>}
        searchOpen={openDateRange}
        setSearchOpen={setDateRangeOpen}
        subtitle={subtitle}
        showFilters={openFilter}
      />
    );
  }

  return (
    <LayoutDesktop
      actions={actionComponents}
      clearAllFiltersButton={clearAllFiltersButton}
      content={listContent}
      data-testid={props['data-testid']}
      datePicker={datePickerComponent}
      filterButton={filterButtonComponent}
      showFilterButton={showFilterButton}
      filterContent={filterDrawerComponent ?? defaultFilterDrawerComponent}
      filters={appliedFiltersComponents}
      icon={icon}
      pagination={undefined}
      scrollRef={scrollRef}
      search={rangeSelectComponent}
      setFilterOpen={setFilterOpen}
      showFilters={openFilter}
      title={title}
      subtitle={subtitle}
    />
  );
};
