import IconButton from '@mui/material/IconButton';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import UnknownFlag from 'remixicon-react/Flag2FillIcon';
import { FlagButtonProps } from './types';

const FlagButton = (props: FlagButtonProps): JSX.Element => {
  const { isoCode, isFlagsMenuOpened, disableDropdown, ...iconButtonProps } = props;
  const { t } = useTranslation(['common']);

  if (disableDropdown) {
    return (
      <IconButton
        tabIndex={-1}
        className="PhoneInput-IconButton"
        disableRipple
        sx={{ pointerEvents: 'none' }}
        component="span"
      >
        {isoCode ? <ReactCountryFlag countryCode={isoCode} svg /> : <UnknownFlag />}
      </IconButton>
    );
  }

  return (
    <IconButton
      {...iconButtonProps}
      aria-label={t('common:component.phone-input.labels.search')}
      id="PhoneInput-Select-Country"
      className="PhoneInput-Flag"
      data-testid="phone-input-select-country"
      aria-haspopup="listbox"
      aria-controls={isFlagsMenuOpened ? 'PhoneInput-Select-Country' : undefined}
      aria-expanded={isFlagsMenuOpened ? 'true' : 'false'}
    >
      {isoCode ? <ReactCountryFlag countryCode={isoCode} svg /> : <UnknownFlag />}
    </IconButton>
  );
};

export default FlagButton;
