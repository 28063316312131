import ArrowBack from '@mui/icons-material/ArrowBack';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, Button, Collapse, Stack } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Alert } from '../alert';

interface ErrorMessageWithDetailsProps {
  details?: ReactNode;
  message: ReactNode;
  withBackButton?: boolean;
}

export const ErrorMessageWithDetails: FC<ErrorMessageWithDetailsProps> = ({ message, details, withBackButton = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  return (
    <Alert severity="error" >
      <Box pl="5px">{message}</Box>
      <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1, mt: 1 }}>
        {withBackButton && (
          <Button
            color="inherit"
            onClick={() => { navigate(-1); }}
            size="small"
            startIcon={<ArrowBack />}
          >
            {t('component.error-message.action.go-back')}
          </Button>
        )}
        {details && (
          <Button
            color="inherit"
            onClick={() => { setIsOpen((isOpen) => !isOpen); }}
            size="small"
            startIcon={<InfoOutlined />}
          >
            {t('component.error-message.action.details')}
          </Button>
        )}
      </Stack>
      {details && (
        <Collapse in={isOpen}>
          <Box component="code" sx={{ display: 'block', mt: 1, overflowWrap: 'anywhere', pl: '5px' }}>
            {details}
          </Box>
        </Collapse>
      )}
    </Alert>
  );
};
