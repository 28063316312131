import Search from '@mui/icons-material/Search';
import { Paper, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, useRef } from 'react';
import { disableClickPropagation } from '../../../util';
import { MapButton } from '../map-button';

interface Props {
  onClick: () => void;
  sx?: SxProps;
}

export const SearchButton: FC<Props> = ({ onClick, sx }) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);

  disableClickPropagation(ref);

  return (
    <Paper
      elevation={3}
      ref={ref}
      sx={sx}
    >
      <MapButton
        onClick={onClick}
        sx={{
          ':focus, :focus-within': {
            borderColor: theme.palette.primary.main,
          },
        }}
      >
        <Search />
      </MapButton>
    </Paper>
  );
};
