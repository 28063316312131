import { Box, SxProps } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { BoldMatchedText } from '../../util';
import { ListPaperColumn } from '../list-item';

interface Props extends PropsWithChildren {
  label: string;
  avatar?: ReactNode;
  capitalizeLabel?: boolean;
  sx?: SxProps;
  innerSx?: SxProps;
  'data-testid'?: string;
  'data-testid-inner'?: string;
  reverse?: boolean;
  type?: string;
  wrap?: boolean;
}

export const BasicColumn: FC<Props> = ({ capitalizeLabel, children, label, type, avatar, innerSx, sx, wrap, reverse, ...props }) => {
  const labelStyles = { textTransform: capitalizeLabel ? 'capitalize' : 'none' };

  if (avatar) {
    return <Box data-testid={props['data-testid']} sx={{ display: 'flex', gap: 1, ...sx }} alignItems='center'>
      {avatar}
      <ListPaperColumn
        data-testid-inner={props['data-testid-inner']}
        reverse={reverse}
        className={type}
        label={label}
        wrap={wrap}
        sx={{ marginLeft: 0, overflow: 'hidden', '& > label': labelStyles, ...innerSx }}
      >
        {children}
      </ListPaperColumn>
    </Box>;
  }

  return <ListPaperColumn
    reverse={reverse}
    className={type}
    wrap={wrap}
    data-testid={props['data-testid']}
    label={label}
    sx={{ overflow: 'hidden', '& > label': labelStyles, ...innerSx }}
  >
    {children}
  </ListPaperColumn>;
};

export const BasicColumnHighlightedSearch: FC<Props> = ({ children, label, type, innerSx, sx, wrap, reverse, ...props }) => {
  const isString = typeof children === 'string' || typeof children === 'number';
  return <ListPaperColumn reverse={reverse} className={type} wrap={wrap} data-testid={props['data-testid']} label={label} sx={{ overflow: 'hidden', '& > label': { textTransform: 'capitalize' }, ...innerSx }}>
    {isString ? <BoldMatchedText text={children.toString()} /> : children}
  </ListPaperColumn>;
};
