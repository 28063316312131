/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton } from '@mui/material';
import { ChangeEvent, FC, useEffect } from 'react';
import { useFetchAllCache, usePromise } from '../../../../hooks';
import { CacheDataTypes, CommonEntity, CommonEntityWithDeleted, SetState } from '../../../../types';
import { MapStorageKeys } from '../../../../util';
import { AppliedFilter } from '../../../entity-search';
import { ErrorMessage } from '../../../error-message';
import { FilterTypes } from '../../../filter';
import { useMapLayers } from '../../hooks/use-map-layers';
import { SubMenuSection } from './sub-menu-section';
import { getFilterProps } from './sub-menu.util';

interface Props {
  cacheDataTypes: CacheDataTypes;
  cacheFilter: (items: CommonEntityWithDeleted[]) => CommonEntityWithDeleted[];
  label: string;
  storageKey: MapStorageKeys;
  typePropertyName: FilterTypes;
}

export const SubMenuSectionController: FC<Props> = ({
  cacheDataTypes,
  cacheFilter,
  label,
  storageKey,
  typePropertyName,
}) => {
  const { geofenceFilters, pointOfInterestFilters, setGeofenceFilters, setPointOfInterestFilters } = useMapLayers();

  const getFilters = (): [AppliedFilter[], SetState<AppliedFilter[]>] => {
    switch (storageKey) {
      case MapStorageKeys.GEOFENCE:
        return [geofenceFilters, setGeofenceFilters];

      case MapStorageKeys.POINT_OF_INTEREST:
        return [pointOfInterestFilters, setPointOfInterestFilters];

      default:
        return [geofenceFilters, setGeofenceFilters];
    }
  };

  const [filters, setFilters] = getFilters();
  const subSectionFilters = filters.filter((filter) => filter.propertyPath === typePropertyName);
  const cache = useFetchAllCache(cacheDataTypes);
  const [items, itemsError, itemsStatus] = usePromise(
    async () => {
      const data = await cache.all<CommonEntityWithDeleted>();
      return cacheFilter(data);
    },
    [cache],
  );

  const addAllItemsToFilter = (sectionItems: CommonEntity[]): void => setFilters([...filters, ...sectionItems.map((item) => getFilterProps(item, label, typePropertyName))]);

  const itemsAndFiltersExist = !!subSectionFilters.length && !!items;
  const isAllChecked = itemsAndFiltersExist && subSectionFilters.length === items.length;
  const isSubSectionItemChecked = (id: string): boolean => subSectionFilters.some((filter) => filter.propertyPath === typePropertyName && filter.id === id);
  const indeterminate = itemsAndFiltersExist && subSectionFilters.length < items.length;

  const handleAllChange = (): void => {
    if (!items) return;
    if (isAllChecked) return setFilters(filters.filter((filter) => filter.propertyPath !== typePropertyName));
    if (indeterminate) {
      const itemsToAdd = items.filter((item) => !subSectionFilters.map((filter) => filter.id).includes(item._id));
      if (!itemsToAdd.length) return;
      return setFilters([...filters, ...itemsToAdd.map((item) => getFilterProps(item, label, typePropertyName))]);
    }
    addAllItemsToFilter(items);
  };

  const handleIndividualChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!items) return;
    if (filters.find((filter) => filter.propertyPath === typePropertyName && filter.id === e.target.value)) {
      return setFilters(filters.filter((filter) => filter.id !== e.target.value));
    }
    const selectedItem = items.find((item) => item._id === e.target.value);
    if (!selectedItem) return;
    setFilters([
      ...filters,
      getFilterProps(selectedItem, label, typePropertyName),
    ]);
  };

  useEffect(() => {
    if (!items || localStorage[storageKey]) return;
    addAllItemsToFilter(items);
  }, [storageKey, items]);

  if (itemsStatus === 'pending') return <Skeleton animation="wave" width="100%" variant="text" sx={{ fontSize: '1rem' }} />;
  if (itemsError) return <ErrorMessage error={itemsError} />;
  if (!items) return <></>;

  return (
    <SubMenuSection
      handleAllChange={handleAllChange}
      handleIndividualChange={handleIndividualChange}
      indeterminate={indeterminate}
      isAllChecked={isAllChecked}
      isSubSectionItemChecked={isSubSectionItemChecked}
      subSectionItems={items}
      subSectionLabel={label}
    />
  );
};
