import { AxiosError } from 'axios';

export class CodedError extends Error {
  constructor(message: string, public readonly code: string) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, CodedError.prototype);
  }
}

export const isAxiosError = (error: Error): error is AxiosError => {
  return !!(error as AxiosError).isAxiosError;
};
