import { CommonEntity, Undefinable } from '../types';

interface SearchableData extends CommonEntity {
  accountId?: string;
}

const handleIdAndDisplay = <T extends SearchableData>(data: T[]): string[] => data.map(({ display }) => display);

const searchDisplay = (display: string, search: string): boolean => {
  return display.toLowerCase().includes(search.toLowerCase());
};

export const searchData = <T extends SearchableData, U = T[]>(data: Undefinable<T[]>, searchWithIdAndDisplay: boolean, search: string, accountId?: string): Promise<U> => {
  const filteredData = accountId
    ? data?.filter(({ display, accountId: dataAccountId }) => (searchDisplay(display, search) ?? []) && accountId === dataAccountId)
    : searchWithIdAndDisplay
      ? handleIdAndDisplay<SearchableData>(data?.filter(({ display }) => searchDisplay(display, search)) ?? [])
      : data?.filter(({ display }) => searchDisplay(display, search));

  return Promise.resolve((filteredData ?? []) as U);
};
