import { AlertResponse } from '@eagle/core-data-types';
import { Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { T_MANY, T_ONE } from '../../constants';
import { makeStyles } from '../../theme';
import { AlertListItemDesktop } from './alert-list-item-desktop';

interface LayoutDesktopProps {
  alerts: AlertResponse[];
  isLoading: boolean;
  paginationComponent?: JSX.Element;
  showFeature: boolean;
  showGroups: boolean;
  showPerson: boolean;
  showThing: boolean;
  smallTable: boolean;
  stickyHeader: boolean;
  showAllButtonComponent?: JSX.Element;
}

const useStyles = makeStyles()((theme) => ({
  smallTable: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '& .MuiTableCell-sizeSmall:first-of-type': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
    '& .MuiTableCell-sizeSmall:last-child': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
    },
  },
}));

const THING_PERSON_WIDTH = 120;
export const DATE_TIME_WIDTH = 175;

export const AlertsTableLayoutDesktop: FC<LayoutDesktopProps> = ({
  alerts,
  isLoading,
  paginationComponent,
  showFeature,
  showGroups,
  showPerson,
  showThing,
  smallTable,
  stickyHeader,
  showAllButtonComponent,
}): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation(['common', 'terms']);
  const routerLocation = useLocation();

  return (
    <Card sx={{ padding: 0 }}>
      <CardContent sx={{ padding: '0 !important', overflowX: 'auto' }}>
        <TableContainer
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: 1,
            justifyContent: 'center',
            minWidth: 'min-content',
            width: 1,
          }}
        >
          <Table
            aria-label="sticky table"
            className={smallTable ? classes.smallTable : undefined}
            component="div"
            data-testid="alert-table"
            size="small"
            stickyHeader={stickyHeader}
            sx={{ tableLayout: 'fixed' }}
          >
            <TableHead component="div">
              <TableRow component="div">
                <TableCell component="div" sx={{ width: smallTable ? 'unset' : DATE_TIME_WIDTH }}>{t('common:terms.date-time')}</TableCell>
                <TableCell component="div" sx={{ px: 2 }}>{t('common:terms.alert-type')}</TableCell>
                {showFeature && <TableCell component="div">{t('common:terms.feature')}</TableCell>}
                {showThing && <TableCell component="div" sx={{ width: THING_PERSON_WIDTH }}>{t('terms:thing', { count: T_ONE })}</TableCell>}
                {showPerson && <TableCell component="div" sx={{ width: THING_PERSON_WIDTH }}>{t('terms:person', { count: T_ONE })}</TableCell>}
                <TableCell component="div">{t('common:terms.location')}</TableCell>
                {showGroups && <TableCell component="div">{t('common:terms.group', { count: T_MANY })}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody component="div" sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
              {alerts.map((alert) => (
                <AlertListItemDesktop
                  key={alert.alertId}
                  alert={alert}
                  alertPath={routerLocation.pathname}
                  showFeature={showFeature}
                  showGroups={showGroups}
                  showPerson={showPerson}
                  showThing={showThing}
                  smallTable={smallTable}
                />
              ))}
            </TableBody>
          </Table>
          {showAllButtonComponent ? <Box display='flex' justifyContent='center' sx={paginationComponent ? { mt: 2 } : { my: 2 }}> {showAllButtonComponent} </Box> : null}
          {paginationComponent
            ? <Box display='flex' justifyContent='center' marginY={1}>
              {paginationComponent}
            </Box>
            : null
          }
        </TableContainer>
      </CardContent>
    </Card>
  );
};
