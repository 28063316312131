export const DEFAULT_THING_MAP_POPUP = {
  op: 'ui',
  name: 'Stack',
  spacing: 1,
  children: [
    {
      op: 'ui',
      name: 'ThingDataCard',
      wrap: true,
      thing: {
        op: 'context',
      },
      thingType: {
        op: 'context',
        path: 'thingType',
      },
    },
    {
      op: 'ui',
      name: 'DataCard',
      wrap: true,
      title: {
        op: 'if',
        condition: { op: 'macro:is-power-on' },
        then: {
          op: 'if',
          condition: { op: 'macro:is-moving' },
          then: {
            op: 'translate',
            translation: 'component.events.labels.moving',
            options: {
              ns: 'common',
            },
          },
          else: {
            op: 'translate',
            translation: 'page.thing-map.state.stationary',
            options: {
              ns: 'track',
            },
          },
        },
        else: {
          op: 'translate',
          translation: 'event-descriptions-v2.tracking-v0.power-off.label',
          options: {
            ns: 'common',
          },
        },
      },
      hint: {
        op: 'if',
        condition: {
          op: 'macro:is-power-on',
        },
        then: {
          op: 'if',
          condition: {
            op: 'macro:is-moving',
          },
          then: [{
            op: 'translate',
            translation: 'page.thing-map.indicator.powered-on-moving.label',
            speed: {
              op: 'format-speed',
              value: {
                op: 'feature-latest',
                feature: 'tracking',
                havingPath: 'data.velocity',
                path: 'data.velocity.speed',
                excludeEventTypes: ['inaccurate-location-update'],
              },
            },
            options: {
              ns: 'track',
            },
          }],

          else: {
            op: 'translate',
            translation: 'page.thing-map.indicator.stationary.label',
            options: {
              ns: 'track',
            },
          },
        },
        else: {
          op: 'translate',
          translation: 'page.thing-map.indicator.powered-off-not-moving.label',
          options: {
            ns: 'track',
          },
        },
      },
      avatar: {
        op: 'ui',
        name: 'Avatar',
        icon: {
          op: 'if',
          condition: { op: 'macro:is-power-on' },
          then: {
            op: 'if',
            condition: { op: 'macro:is-moving' },
            then: 'PlayIcon',
            else: 'PauseIcon',
          },
          else: 'PowerIcon',
        },
        iconColor: {
          op: 'if',
          condition: {
            op: 'macro:is-power-on',
          },
          then: {
            op: 'if',
            condition: {
              op: 'macro:is-moving',
            },
            then: {
              op: 'theme-property',
              path: 'states.on',
            },
            else: {
              op: 'theme-property',
              path: 'states.paused',
            },
          },
          else: {
            op: 'theme-property',
            path: 'dataCard.contrastText',
          },
        },
        background: 'transparent',
      },
    },
    {
      op: 'ui',
      name: 'DataCard',
      wrap: true,
      title: {
        op: 'ui',
        name: 'EventAddress',
        'data-testid': 'thing-map-popup-address',
        location: {
          op: 'feature-latest',
          feature: 'tracking',
          havingPath: 'data.location',
          path: 'data.location',
          excludeEventTypes: ['inaccurate-location-update'],
        },
        variant: 'body2',
        addressUnavailableText: {
          op: 'translate',
          translation: 'common:page.thing-map.address-unavailable.hint',
        },
        textId: 'thing-map-popup-address',
      },
      hint: [
        {
          op: 'ui',
          name: 'DateTimeTooltip',
          variant: 'caption',
          display: {
            op: 'ui',
            name: 'FormatTimestamp',
            format: 'relative',
            disableTooltip: true,
            value: {
              op: 'feature-event',
              feature: 'tracking',
              eventTypeId: 'location-update',
            },
          },
          title: [{
            op: 'translate',
            translation: 'page.thing-detail.last-location-update.label',
            options: {
              ns: 'track',
            },
          }, ': ', {
            op: 'ui',
            name: 'FormatTimestamp',
            value: {
              op: 'feature-event',
              feature: 'tracking',
              eventTypeId: 'location-update',
            },
          }],
        },
        {
          op: 'if',
          condition: {
            op: 'before-past',
            timestamp: {
              op: 'feature-latest-occurred',
              feature: 'tracking',
              havingPath: 'data.location',
            },
            duration: 'P7D',
          },
          then: {
            op: 'ui',
            name: 'Box',
            children: {
              op: 'translate',
              translation: 'page.thing-map.location-data-old.hint',
              options: {
                ns: 'common',
              },
              replace: {
                value: 7,
                unit: 'days',
              },
            },
          },
        },
      ],
      avatar: {
        op: 'ui',
        name: 'Avatar',
        icon: {
          op: 'if',
          condition: {
            op: 'before-past',
            timestamp: {
              op: 'feature-latest-occurred',
              feature: 'tracking',
              havingPath: 'data.location',
            },
            duration: 'P7D',
          },
          then: 'LocationUnknownIcon',
          else: 'LocationIcon',
        },
        iconSize: 'small',
        iconColor: {
          op: 'theme-property',
          path: 'dataCard.contrastText',
        },
        background: 'transparent',
      },
    },
    {
      op: 'ui',
      name: 'DataCard',
      wrap: true,
      title: {
        op: 'if',
        condition: {
          op: 'object:has',
          path: 'thingPersons[0]',
          object: {
            op: 'context',
          },
        },
        then: {
          op: 'ui',
          name: 'ThingPersonList',
          data: {
            op: 'context',
            path: 'thingPersons',
          },
        },
        else: {
          op: 'ui',
          name: 'Typography',
          variant: 'caption',
          color: 'text.secondary',
          sx: {
            fontStyle: 'italic',
          },
          children: {
            op: 'translate',
            translation: 'page.thing-detail.person-list.hint.none',
            options: {
              ns: 'common',
            },
          },
        },
      },
      avatar: {
        op: 'ui',
        name: 'Avatar',
        icon: 'PersonIcon',
        iconSize: 'small',
        iconColor: {
          op: 'theme-property',
          path: 'dataCard.contrastText',
        },
        background: 'transparent',
      },
      label: {
        op: 'if',
        condition: {
          op: 'object:has',
          path: 'thingPersons[0]',
          object: {
            op: 'context',
          },
        },
        then: {
          op: 'translate',
          translation: 'common.labels.assigned',
          options: {
            ns: 'common',
          },
        },
      },
    },
    {
      op: 'ui',
      name: 'Box',
      sx: {
        display: 'inline',
        textAlign: 'right',
      },
      children: {
        op: 'ui',
        name: 'DateTimeTooltip',
        variant: 'caption',
        display: ['Last data received: ', {
          op: 'ui',
          name: 'FormatTimestamp',
          disableTooltip: true,
          format: 'relative',
          value: {
            op: 'last-event-time',
          },
        }],
        title: [{
          op: 'macro:event-description',
          lastEvent: {
            op: 'context',
            path: 'lastThingEvent',
          },
        }, ': ', {
          op: 'ui',
          name: 'FormatTimestamp',
          disableTooltip: true,
          value: {
            op: 'last-event-time',
          },
        }],
      },
    },
  ],
};
