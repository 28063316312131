import { MediaSegmentData } from '@eagle/core-data-types';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Box, ButtonBase, Stack, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useSmallScreen } from '../../hooks';
import { testid } from '../../util/test-id';
import { MediaItemLabel } from './media-item-label';

interface Props {
  currentImageIndex?: number;
  'data-testid'?: string;
  handleImageClick: (currentIndex: number, feature: string) => void;
  image: MediaSegmentData;
  timeZone?: string;
}

export const ImageItem: FC<Props> = ({ currentImageIndex = 0, handleImageClick, image, timeZone, ...props }) => {
  const [hovered, setHovered] = useState(false);
  const smallScreen = useSmallScreen();
  const theme = useTheme();

  return (
    <Box data-testid={props['data-testid']} sx={{ borderRadius: 1, scrollSnapAlign: 'start', scrollSnapStop: 'normal' }}>
      {image
        && <>
          <ButtonBase
            aria-label={image.start && `${image.start.toLocaleTimeString()} ${image.start.toLocaleDateString()}`}
            data-testid={testid`action-image-${image.url}-${currentImageIndex}`}
            focusRipple
            onClick={() => handleImageClick(currentImageIndex, image.feature)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            sx={{ width: [1, 200] }}
          >
            <Box
              height={smallScreen ? '200px' : '100px'}
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Box
                component="img"
                data-chromatic="ignore"
                src={image.url?.toString() ?? `${(import.meta.env.BASE_URL ?? '')}/images/image-placeholder.png`}
                sx={{
                  backgroundColor: theme.placeholder.image,
                  borderRadius: 1,
                  height: [200, 100],
                  objectFit: 'cover',
                  width: '100%',
                  ':hover': { opacity: 0.75 },
                }}
                crossOrigin="anonymous"
              />
              {hovered && <FullscreenIcon sx={{ position: 'absolute' }} />}
            </Box>
          </ButtonBase>
          {image.start
            && <Stack mb={2} mt={1} spacing={0}>
              <MediaItemLabel start={image.start} timeZone={timeZone} />
            </Stack>
          }
        </>
      }
    </Box>
  );
};
