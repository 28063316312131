import { FC } from 'react';
import { TagsEditor, TagsEditorProps } from '../tags-editor';

interface GroupsEditor extends Omit<TagsEditorProps, 'tags' | 'optionOnClick' | 'optionOnDelete' | 'options'> {
  defaultValues?: string[];
  error?: boolean;
  noOptionsText?: string;
  optionOnClick: (chip: string, updatedChips?: string[]) => Promise<void>;
  optionOnDelete: (chip: string, updatedChips?: string[]) => Promise<void>;
  options: string[];
}

export const GroupsEditor: FC<GroupsEditor> = ({ defaultValues, ...props }) => (
  <TagsEditor {...props} tags={defaultValues ?? []} />
);
