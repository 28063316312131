import { Time } from '@eagle/common';
import { DateTime } from 'luxon';
import { FC, useCallback, useRef, useState } from 'react';
import { TileLayer } from 'react-leaflet';
import { HERE_MAP_API_KEY } from '../../../constants';
import { useConfig } from '../../../hooks/use-config';
import { useInterval } from '../../../hooks/use-interval';

interface TrafficLayerProps {
  format?: 'png' | 'png8';
  size?: 256 | 512;
}

export const TrafficLayer: FC<TrafficLayerProps> = ({
  format = 'png',
  size = 256,
}) => {
  const ref = useRef<L.TileLayer>(null);
  const THREE_MINUTES = Time.minutes(3);
  const [visible, setVisible] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(DateTime.now());
  const config = useConfig();

  const redrawTraffic = useCallback(() => {
    setLastUpdate(DateTime.now());
    ref.current?.redraw();
  }, []);

  document.onvisibilitychange = () => {
    if (document.visibilityState === 'hidden') {
      setVisible(false);
    } else {
      setTimeout(() => {
        setVisible(true);
        redrawTraffic();
      }, THREE_MINUTES + lastUpdate.diffNow('milliseconds').milliseconds);
    }
  };

  useInterval(redrawTraffic, visible ? THREE_MINUTES : null);

  return <TileLayer
    ref={ref}
    opacity={0.7}
    pane='overlayPane'
    url={`https://traffic.maps.hereapi.com/v3/flow/mc/{z}/{x}/{y}/${format}?apiKey=${config?.hereMaps?.apiKey ?? HERE_MAP_API_KEY}&size=${size}`}
    zIndex={2}
  />;
};
