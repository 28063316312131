/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Typography, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import { FC } from 'react';
import { AuthStatus, useAuth } from '../../auth';
import { FlexBox } from '../flex-box';
import { MenuIcon } from '../icons';
import { MenuButton } from './menu-button';
import { MenuItemProps } from './menu-item.types';
import { MenuLink } from './menu-link';

export const MenuItem: FC<MenuItemProps> = ({
  active,
  hasPermissions = true,
  handleClick,
  icon,
  keepAsDisabled = false,
  path,
  open,
  subText,
  sx,
  text,
  className,
  id,
  ...props
}) => {
  const { state } = useAuth();

  if (!state || state.status !== AuthStatus.AUTHENTICATED) return null;

  if (!hasPermissions && !keepAsDisabled) return <></>;

  const theme = useTheme();

  const renderMenuItem = (): JSX.Element => (
    <FlexBox
      className="MenuItem-root"
      onClick={() => handleClick?.(path ?? '')}
      sx={{
        alignItems: 'stretch',
        flexDirection: 'row',
        height: 48,
        justifyItems: 'stretch',
        overflowX: 'hidden',
        cursor: !active ? 'pointer' : 'default',
        background: active ? chroma(theme.sidebar.background).darken(0.5).hex() : 'inherit',
        '.active &': { backgroundColor: chroma(theme.sidebar.background).darken(0.5).hex() },
        '&:hover': { backgroundColor: chroma(theme.sidebar.background).darken(0.5).hex() },
        ...sx,
      }}
      id={id}
      data-testid={props['data-testid']}
    >
      <FlexBox
        className="icon"
        sx={{
          alignItems: 'center',
          flexGrow: 0,
          flexShrink: 0,
          justifyContent: 'center',
        }}
      >
        {<MenuIcon icon={icon} />}
      </FlexBox>
      <FlexBox
        className="text"
        sx={{
          alignItems: 'start',
          flexDirection: 'column',
          justifyContent: 'center',
          lineHeight: 1,
          opacity: open ? 1 : 0,
          overflow: 'hidden',
          p: 1,
          textOverflow: 'ellipsis',
          transition: '250ms opacity ease',
          whiteSpace: 'nowrap',
        }}
      >
        <Box sx={{ mr: 2 }}>{text}</Box>
        {subText && <Typography variant='caption' sx={{ mr: 2 }}>{subText}</Typography>}
      </FlexBox>
    </FlexBox>
  );

  if (handleClick || !path) return renderMenuItem();

  if (!hasPermissions) {
    return (
      <MenuButton data-testid={props['data-testid']} disabled>
        {renderMenuItem()}
      </MenuButton>
    );
  }

  return (
    <MenuLink data-testid={props['data-testid']} to={path} className={className}>
      {renderMenuItem()}
    </MenuLink>
  );
};
