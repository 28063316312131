import { BottomNavigation as MUIBottomNavigation, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { PropsWithChildren } from 'react';

export interface BottomNavProps<T> {
  'data-testid'?: string;
  value?: T;
  sx?: SxProps<Theme>;
  onChange?: (event: React.SyntheticEvent, value: T) => void;
}

/**
 * FUNCTION: Note this is required to be a function in React to achieve strong generic typing :(
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function BottomNavigation<T>({
  children,
  sx,
  ...props
}: PropsWithChildren<BottomNavProps<T>>): JSX.Element {
  return (<MUIBottomNavigation {...props} showLabels sx={{ display: 'flex', width: '100%', boxShadow: 4, ...sx }} component='div'>{children}</MUIBottomNavigation>);
}
