import { Operator } from '@jexop/core';
import { isString } from 'lodash';
import { ComponentType, FC, ReactNode } from 'react';
import { Nullable } from '../types';

import * as Widgets from '../components/widgets';

export const ui: Operator<ReactNode> = (props): Nullable<ReactNode> => {
  const { name, ...rest } = props;

  if (!isString(name)) return null;

  const Widget = (Widgets as Record<string, ComponentType<unknown> | FC<unknown>>)[name];

  if (!Widget) return null;
  return <Widget {...rest} />;
};
