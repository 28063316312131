import { ServiceMetric } from '@eagle/core-data-types';
import { InputAdornment, TextField } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { testid } from '../../util';
import { NumberEditor } from './number-editor';

interface Props {
  onChange: (metricId: string, value: string | number | undefined) => void;
  serviceMetricValues?: Record<string, unknown>;
  serviceMetrics: ServiceMetric[];
  setMetricsErrors: Dispatch<SetStateAction<Record<string, string>>>;
}

export const ServiceMetricFields: FC<Props> = ({
  onChange,
  serviceMetricValues = {},
  serviceMetrics,
  setMetricsErrors,
}) => {
  const { t } = useTranslation();

  const getUnitSuffix = (unit: string): string => {
    switch (unit) {
      case 'km':
        return `${t('common:page.thing-detail.service-history-dialog.odometer.suffix.km.labels')}`;
      case 'hours':
        return `${t('common:page.thing-detail.service-history-dialog.engine-hours.suffix.hours.labels')}`;
      // TODO: Add more cases for additional units as needed
      default: return unit;
    }
  };

  const handleErrorHandler = useCallback((metricId: string, errorMessage: string | undefined): void => {
    setMetricsErrors((prevErrors) => ({
      ...prevErrors,
      [metricId]: errorMessage || '',
    }));
  }, [setMetricsErrors]);

  const errorHandlers = useMemo(() => {
    return serviceMetrics.reduce<Record<string, (error: string | undefined) => void>>((acc, metric) => {
      acc[metric.serviceMetricId] = (error: string | undefined) => handleErrorHandler(metric.serviceMetricId, error);
      return acc;
    }, {});
  }, [serviceMetrics, handleErrorHandler]);

  if (!serviceMetrics.length) return <></>;

  return <>
    {serviceMetrics.map((metric) => (
      metric.type === 'number' ?
        <NumberEditor
          key={metric.serviceMetricId}
          data-testid={`service-metric-${testid`${metric.display}`}-input`}
          label={metric.display}
          placeholder={metric.display}
          size="small"
          sx={{ '& > label': { textTransform: 'capitalize' } }}
          value={serviceMetricValues[metric.serviceMetricId] && serviceMetricValues[metric.serviceMetricId] !== null ? serviceMetricValues[metric.serviceMetricId] as number : ''}
          onChange={(value) => onChange(metric.serviceMetricId, value)}
          InputProps={{
            endAdornment: (<InputAdornment position="end" sx={{ textTransform: 'lowercase' }}>{getUnitSuffix(metric.unit)}</InputAdornment>),
          }}
          errorHandler={errorHandlers[metric.serviceMetricId]}
        />
        :
        <TextField
          key={metric.serviceMetricId}
          data-testid={`service-metric-${testid`${metric.display}`}-input`}
          label={metric.display}
          placeholder={metric.display}
          size="small"
          type="text"
          value={serviceMetricValues[metric.serviceMetricId] !== null ? serviceMetricValues[metric.serviceMetricId] : ''}
          onChange={(e) => onChange(metric.serviceMetricId, e.target.value)}
          InputProps={{
            endAdornment: (<InputAdornment position="end" sx={{ textTransform: 'lowercase' }}>{getUnitSuffix(metric.unit)}</InputAdornment>),
          }}
          sx={{ '& > label': { textTransform: 'capitalize' } }}
        />
    ))}
  </>;
};
