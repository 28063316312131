import { grey } from '@mui/material/colors';
import { makeStyles } from '../../theme';

export const mediaOverlayStyles = makeStyles()(() => ({
  modal: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1400,
  },
  mediaContainer: {
    display: 'grid',
    gridGap: '1rem',
    marginBottom: '100px',
    placeItems: 'center',
    '&:focus-visible': {
      outline: 0,
    },
  },
  mediaThumbnails: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  videoBox: {
    alignItems: 'center',
    backgroundColor: grey[500],
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  actionCard: {
    backgroundColor: '#25252585',
    padding: 8,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    border:0,
    borderRadius: '0 0 0 4px',
  },
  actionButton: {
    color: 'white',
  },
}));
