import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton, Stack } from '@mui/material';
import chroma from 'chroma-js';
import { FC } from 'react';
import { DrawerSize } from '../event-history.types';

interface Props {
  backgroundColor?: string;
  fullScreenDisabled: boolean;
  fullScreenIcon?: JSX.Element;
  handleClose: () => void;
  handleExpand: () => void;
  handleFullScreen: () => void;
  isTopDrawer?: boolean;
  renderRemoveIcon: boolean;
  title: JSX.Element;
  titleIcon?: JSX.Element;
}

export const DrawerHeader: FC<Props> = ({
  backgroundColor,
  fullScreenDisabled,
  fullScreenIcon,
  handleClose,
  handleExpand,
  handleFullScreen,
  isTopDrawer = true,
  renderRemoveIcon,
  title,
  titleIcon,
}): JSX.Element => (
  <Stack
    direction="row"
    sx={{
      alignItems: 'center',
      background: (theme) => backgroundColor ?? theme.palette.primary.main,
      color: (theme) => theme.palette.primary.contrastText,
      height: DrawerSize.CLOSED,
      justifyContent: 'space-between',
      position: 'sticky',
      top: 0,
    }}
  >
    <Stack direction="row" spacing={2} sx={{ pl: 2 }}>
      {titleIcon}
      {title}
    </Stack>
    <Stack direction="row" display="none">
      {renderRemoveIcon
        ? <IconButton onClick={handleClose} sx={{ color: (theme) => theme.palette.primary.contrastText }}>
          <RemoveIcon />
        </IconButton>
        : <IconButton onClick={handleExpand} sx={{ color: (theme) => theme.palette.primary.contrastText }}>
          {isTopDrawer
            ? <KeyboardArrowDownIcon />
            : <KeyboardArrowUpIcon />
          }
        </IconButton>
      }
      <IconButton
        disabled={fullScreenDisabled}
        onClick={handleFullScreen}
        sx={{
          '&:disabled': {
            color: (theme) => chroma(theme.palette.primary.contrastText).alpha(0.26).css(),
          },
          color: (theme) => theme.palette.primary.contrastText,
        }}
      >
        {fullScreenIcon ?? <FullscreenExitIcon />}
      </IconButton>
    </Stack>
  </Stack>
);
