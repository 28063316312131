import { LifecycleTemplate, StakeholderAccountResult } from '@eagle/core-data-types';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuthenticated } from '../../auth';

export const canSetStakeholder = (targetRole: string, stakeholderRoles: string[], lifecycleTemplate: LifecycleTemplate): boolean => {
  for (const role of stakeholderRoles) {
    const account = lifecycleTemplate.stakeholderAccounts[role];
    if (!account) {
      continue;
    }
    if (!account.canSetStakeholders) {
      return true;
    }
    if (account.canSetStakeholders.includes(targetRole)) {
      return true;
    }
  }

  return false;
};

export const useStakeholderAccountQuery = ({
  accountId,
  role,
  sharedThingId,
}: {
  accountId: string;
  role: string;
  sharedThingId: string;
}): UseQueryResult<StakeholderAccountResult> => {
  const { axios } = useAuthenticated();

  return useQuery({
    queryKey: ['stakeholder-account', accountId, role, sharedThingId],
    queryFn: async () => {
      const response = await axios.get<StakeholderAccountResult>(`/api/v1/shared-thing/${sharedThingId}/life-cycle/stakeholder/${role}/account/${accountId}`);
      return response.data;
    },
  });
};
