import { PushPin, PushPinOutlined } from '@mui/icons-material';
import CloseMenuIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Stack } from '@mui/material';
import cx from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { useSidebarPinContext, useSmallScreen } from '../../hooks';
import { IfFlag } from '../flags';
import { FlexBox } from '../flex-box';
import { drawerContentStyles } from './drawer-content.styles';

interface Props extends PropsWithChildren {
  footer?: JSX.Element;
  header?: JSX.Element;
  navigation?: JSX.Element;
  open?: boolean;
  profile?: JSX.Element;
}

export const DrawerContent: FC<Props> = ({ children, footer, header, navigation, profile, open }) => {
  const { classes } = drawerContentStyles();
  const smallScreen = useSmallScreen();
  const { isPinned, toggleIsPinned } = useSidebarPinContext();

  return (
    <FlexBox className="DrawerContent-root" flexDirection="column" sx={{ overflow: 'hidden', mt: smallScreen ? '-56px' : 0 }}>
      <Box id="custom-sidebar-container">
        <Box id="custom-sidebar-content"></Box>
      </Box>
      {header
        && <Box id="default-sidebar-container" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ml: smallScreen ? 4 : 0 }}>
          {header}
          {smallScreen && <CloseMenuIcon sx={{ mr: 2, cursor: 'pointer' }} />}
          {!smallScreen &&
            <IfFlag flag="portals-pin-sidebar-feature-temporary">
              <IconButton color="primary" onClick={toggleIsPinned} sx={{ top: '-1rem', right: '.5rem' }}>
                {isPinned ? <PushPin /> : <PushPinOutlined />}
              </IconButton>
            </IfFlag>
          }
        </Box>}
      <Divider />
      {profile && <Box sx={{ my: 2, ml: 2 }} >{profile}</Box>}
      <FlexBox
        className={cx({ [classes.overflowContainer]: open })}
        data-testid="scroll-wrapper"
        flexDirection="column"
        sx={{ overflow: 'auto', scrollbarWidth: 'thin', scrollbarColor: 'transparent transparent' }}
      >
        <FlexBox sx={{ alignItems: 'start' }}>
          <Stack
            className={`stack ${classes.menuItemsContainer}`}
            direction="column"
            spacing={1}
          >
            {children}
          </Stack>
        </FlexBox>
      </FlexBox>
      {navigation}
      {footer
        && <>
          <Divider />
          <Box>{footer}</Box>
        </>
      }
    </FlexBox>
  );
};
