import { ThingLastLocation } from '@eagle/core-data-types';
import { Operator } from '@jexop/core';
import { isNumber } from 'lodash';

export const latitudeLongitude: Operator<number[] |  { lat: number; lng: number }> = (props, ctx:ThingLastLocation)  => {
  if (props.latitude && props.longitude && isNumber(props.latitude) && isNumber(props.longitude)) {
    if (props.format && props.format === 'object') {
      return { lat: props.latitude, lng: props.longitude };
    }
    return [props.latitude, props.longitude];
  }

  if (ctx.latitude && ctx.longitude) {
    return [ctx.latitude, ctx.longitude];
  }

  return null;
};
