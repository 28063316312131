import { sleep } from '@eagle/common';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MiddleSpinner } from '../../components/middle-spinner';
import { API_CALL_TEXT_LENGTH } from '../../constants';
import { CommonEntityWithDeleted } from '../../types';
import { Alert } from '../alert';
import { AssignResultRow } from '../assign-dialog';
import { DialogList } from '../dialog-list';
import { LoadingButton } from '../loading-button';
import { AssignMultipleDialogView } from './assign-multiple-dialog-view';
import { EntityData } from './assign-multiple-dialog.types';

interface Props {
  buttonDisplay?: string;
  closeDialog: () => void;
  'data-testid'?: string;
  defaultTab: string;
  dialogHeader: string;
  entityData: EntityData[];
  open: boolean;
}

export const AssignMultipleDialogController: FC<Props> = ({
  buttonDisplay,
  closeDialog,
  defaultTab,
  dialogHeader,
  entityData,
  open,
  ...props
}): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [triggerClearText, setTriggerClearText] = useState(DateTime.now().toMillis());

  const handleDialogClose = (): void => {
    closeDialog();
    setSelectedTab(defaultTab);
    setTriggerClearText(DateTime.now().toMillis());
  };

  const handleTabChange = (_: SyntheticEvent, tab: string): void => {
    setSelectedTab(tab);
    setTriggerClearText(DateTime.now().toMillis());
  };

  const renderAddButton = (handleClick: () => void): JSX.Element => (
    <LoadingButton
      sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
      task={() => sleep(500).then(handleClick)}
      variant="text"
      data-testid="add-button"
    >
      {buttonDisplay ?? t('common:common.action.add')}
    </LoadingButton>
  );

  const renderDialogList = <T extends CommonEntityWithDeleted>(entity: EntityData): JSX.Element => {
    const { findItems, handleAddItem, idsToCheck, renderAdditionalContent, renderItemHeader, tab } = entity;

    return (
      <DialogList<T>
        limit={3}
        onQueryChanged={(query) => findItems(query.pagination, query.search)}
        renderContent={(items, isLoading, text) => {
          if (!text) return <Typography color="text.secondary">{t('common:component.lookup.hint.initial')}</Typography>;
          if (text.length < API_CALL_TEXT_LENGTH) return <Alert severity="info">{t('common:component.search.hint.less-than-count', { count: API_CALL_TEXT_LENGTH })}</Alert>;

          if (isLoading) return <MiddleSpinner size={30} />;

          if (!items?.length) return <Alert severity="info">{t('common:common.hint.list.none', { entity: t(`common:component.assign-multiple-dialog.labels.${tab}`) })}</Alert>;

          return <>
            {items.map((item, i) => (
              <AssignResultRow<T>
                key={i}
                additionalContent={() => renderAdditionalContent?.(item)}
                assigned={idsToCheck.includes(item._id)}
                assignedElement={<CheckCircleIcon sx={{ color: theme.palette.success.light }} />}
                assignNewEntityElement={(data) => renderAddButton(() => handleAddItem(data._id))}
                data={item}
                itemHeader={() => renderItemHeader(item)}
                data-testid={`result-row-${i}`}
              />
            ))}
          </>;
        }}
        triggerClearText={triggerClearText}
      />
    );
  };

  useEffect(() => {
    setSelectedTab(defaultTab);
  }, [defaultTab]);

  return (
    <AssignMultipleDialogView
      dialogHeader={dialogHeader}
      entityData={entityData}
      handleDialogClose={handleDialogClose}
      handleTabChange={handleTabChange}
      open={open}
      renderDialogList={renderDialogList}
      selectedTab={selectedTab}
      data-testid={props['data-testid']}
    />
  );
};
