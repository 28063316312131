import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface NoPermissionsCardBodyProps {
  'data-testid'?: string;
}

export const NoPermissionsCardBody: FC<NoPermissionsCardBodyProps> = ({ ...props }) => {
  const { t } = useTranslation(['common']);

  return (
    <Typography {...props} color="text.secondary" sx={{ display: 'flex', justifyContent: 'center', p: 3, pt: 1 }} >
      {t('common:component.auth.hint.permission')}
    </Typography>
  );
};
