import { PointOfInterest } from '@eagle/core-data-types';
import { FC, useState } from 'react';
import { Pane } from 'react-leaflet';
import { PointOfInterestMarker } from './point-of-interest-marker';

interface Props {
  pointsOfInterest: PointOfInterest[];
}

export const PointOfInterestPane: FC<Props> = ({ pointsOfInterest }): JSX.Element => {
  const [openedPopup, setOpenedPopup] = useState('');

  return (
    <Pane name="point-of-interest-pane" style={{ zIndex: 600 }}>
      {pointsOfInterest.map((pointOfInterest) => (
        <PointOfInterestMarker
          isPopupOpen={openedPopup === pointOfInterest._id}
          key={pointOfInterest._id}
          pointOfInterest={pointOfInterest}
          setOpenedPopup={setOpenedPopup}
        />
      ))}
    </Pane>
  );
};
