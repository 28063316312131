import { ButtonBase as MUIButtonBase, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, MouseEventHandler, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  'aria-label'?: string;
  className?: string;
  'data-testid'?: string;
  disableRipple?: boolean;
  focusRipple?: boolean;
  key?: number;
  onClick?: MouseEventHandler | undefined;
  onMouseEnter?: MouseEventHandler | undefined;
  onMouseLeave?: MouseEventHandler | undefined;
  onFocusVisible?: React.FocusEventHandler;
  sx?: SxProps<Theme>;
}

export const ButtonBase: FC<Props> = ({ children, ...props }) => {
  return <MUIButtonBase {...props}>{children}</MUIButtonBase>;
};
