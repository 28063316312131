import { BehaviorSubject, catchError, combineLatest, debounceTime, filter, finalize, from, Observable, of, switchMap, tap } from 'rxjs';
import { MAP_DEBOUNCE_TIME } from '../../../constants';
import { Undefinable } from '../../../types';
import { RouteResponse } from '../layers/route-matching/route-matching.types';
import { FindRouteResult, LocationPoints } from './thing-event-item';

export const routingObservable = (
  getRoute: (data: LocationPoints) => FindRouteResult,
  onError: (error: Error) => void,
  snapToRoadSubject: BehaviorSubject<boolean>,
  journeyCalledSubject: BehaviorSubject<boolean>,
  locationPointsSubject: BehaviorSubject<LocationPoints>,
): Observable<Undefinable<RouteResponse>> => {
  const triggerCheck = combineLatest([snapToRoadSubject, journeyCalledSubject, locationPointsSubject]).pipe(
    filter(([snap, called]) => snap && !called),
    switchMap(() => locationPointsSubject),
  );

  return triggerCheck.pipe(
    debounceTime(MAP_DEBOUNCE_TIME),
    switchMap((locationPoints) => {
      if (locationPoints.length < 2) return of(undefined);
      const deferred = getRoute(locationPoints);
      if (!deferred) return of(undefined);
      let complete = false;

      return from(deferred.promise).pipe(
        tap(() => {
          complete = true;
          journeyCalledSubject.next(true);
        }),
        catchError((err: Error) => {
          onError(err);
          return of(undefined);
        }),
        finalize(() => complete || deferred.cancel()),
      );
    }),
  );
};
