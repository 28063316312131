/* eslint-disable react-hooks/exhaustive-deps */
import { Image, MarkerType } from '@eagle/api-types';
import { PointOfInterest, PointOfInterestSet, PointOfInterestType } from '@eagle/core-data-types';
import { useTheme } from '@mui/material';
import L from 'leaflet';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useAuthenticated } from '../../auth';
import { useFetchAllCache, usePromise } from '../../hooks';
import { CustomMarkerIcon, CUSTOM_MARKER_ICONS } from '../../pages/point-of-interest/point-of-interest-custom-marker';
import { CacheDataTypes, Maybe, Nullable, Undefinable } from '../../types';
import { getPoiSetImage } from '../../util';
import { PickerIcon } from '../icon-picker';

interface Context {
  color: string;
  icon?: PickerIcon;
  iconType: MarkerType;
  image?: Nullable<Image>;
  position: Nullable<L.LatLng>;
  previewImage: Undefinable<string>;
  resetStyles: VoidFunction;
  setColor: (color: string) => void;
  setIcon: (icon?: PickerIcon) => void;
  setIconType: (icon: MarkerType) => void;
  setImage: (image?: Nullable<Image>) => void;
  setPosition: (position: Nullable<L.LatLng>) => void;
  setPreviewImage: (image: Maybe<string | ArrayBuffer>) => void;
}

const context = createContext<Undefinable<Context>>(undefined);

interface Props extends PropsWithChildren {
  pointOfInterest?: PointOfInterest;
}

const findIcon = (icon: Maybe<string>): Undefinable<CustomMarkerIcon> => CUSTOM_MARKER_ICONS.find(({ name }) => name === icon);

export const MarkerProvider: FC<Props> = ({ pointOfInterest, children }) => {
  const theme = useTheme();
  const poiSetCache = useFetchAllCache(CacheDataTypes.POINT_OF_INTEREST_SET);
  const poiTypeCache = useFetchAllCache(CacheDataTypes.POINT_OF_INTEREST_TYPE);
  const { axios } = useAuthenticated();
  const [position, setPosition] = useState<Nullable<L.LatLng>>(null);
  const [icon, setIcon] = useState<PickerIcon>();
  const [image, setImage] = useState<Nullable<Image>>();
  const [iconType, setIconType] = useState<MarkerType>();
  const [color, setColor] = useState<string>(theme.palette.primary.main);

  const loadPoiSet = useCallback(
    () => {
      if (!pointOfInterest) return Promise.resolve(undefined);
      return poiSetCache.one<PointOfInterestSet>(pointOfInterest.pointOfInterestSetId);
    },
    [pointOfInterest],
  );

  const loadPoiType = useCallback(
    () => {
      if (!pointOfInterest) return Promise.resolve(undefined);
      return poiTypeCache.one<PointOfInterestType>(pointOfInterest.pointOfInterestTypeId);
    },
    [pointOfInterest],
  );

  const [poiSet] = usePromise(loadPoiSet, [loadPoiSet]);
  const [poiType] = usePromise(loadPoiType, [loadPoiType]);
  const imageName = pointOfInterest?.image?.filename
    ?? poiSet?.image?.filename
    ?? poiType?.image?.filename;
  const [previewImage, setPreviewImage] = useState<Undefinable<string>>();

  const convertImageToString = (codedImage: Maybe<string | ArrayBuffer>): void => {
    const encoder = new TextDecoder('utf-8');
    if (!codedImage) return setPreviewImage(undefined);
    if (typeof codedImage === 'object') return setPreviewImage(encoder.decode(codedImage));
    setPreviewImage(codedImage);
  };

  const resetStyles = (): void => {
    setIcon(undefined);
    setImage(undefined);
    setPreviewImage(undefined);
    setIconType(MarkerType.PIN);
    setColor(theme.palette.primary.main);
  };

  useEffect(() => {
    if (!pointOfInterest) return;
    const { location, marker } = pointOfInterest;
    const poiIcon = pointOfInterest.icon ?? poiSet?.icon ?? poiType?.icon;
    const poiMarker = marker ?? poiSet?.marker ?? poiType?.marker;
    setPosition(new L.LatLng(location.latitude, location.longitude));
    setIcon(findIcon(poiIcon));
    setIconType(poiMarker?.type);
    setColor(poiMarker?.color ?? theme.palette.primary.main);
    if (!imageName || !poiSet) return;
    getPoiSetImage(axios, setPreviewImage, `/api/v1/point-of-interest-set/${poiSet._id}/image/${imageName}`);
  }, [pointOfInterest, poiSet, poiType, imageName]);

  return (
    <context.Provider
      value={{
        color,
        icon,
        iconType: iconType ?? MarkerType.PIN,
        image,
        position,
        previewImage,
        resetStyles,
        setColor,
        setIcon,
        setIconType,
        setImage,
        setPosition,
        setPreviewImage: convertImageToString,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useMarkerContext = (): Context => {
  const data = useContext(context);
  if (!data) throw new Error('Missing MarkerProvider in tree above useMarkerContext');
  return data;
};

