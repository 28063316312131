import TimelineIcon from '@mui/icons-material/Timeline';
import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapIcon } from '../../../components';
import { HEIGHT_TO_CLOSE_DRAWER, WIDTH_TO_CLOSE_DRAWER } from '../../../constants';
import { DrawerSize, TwoWayControllerProps } from '../event-history.types';
import { TwoWayCollapseView } from './two-way-collapse-view';

export const TwoWayCollapseController: FC<TwoWayControllerProps> = ({ bottomDrawerContent, topDrawerContent }): JSX.Element => {
  const { t } = useTranslation(['common']);
  const [bottomDrawerExited, setBottomDrawerExited] = useState(true);
  const [bottomDrawerHeight, setBottomDrawerHeight] = useState<DrawerSize>(DrawerSize.CLOSED);

  const renderBottomDrawerHeaderIcon = (): JSX.Element => <TimelineIcon />;
  const renderBottomDrawerHeaderTitle = (): JSX.Element => <Typography>{t('common:common.labels.timeline')}</Typography>;
  const renderTopDrawerHeaderIcon = (): JSX.Element => <MapIcon />;
  const renderTopDrawerHeaderTitle = (): JSX.Element => <Typography>{t('common:common.labels.map')}</Typography>;

  useEffect(() => {
    const closeDrawer = (): void => {
      if (window.innerWidth > WIDTH_TO_CLOSE_DRAWER && window.innerHeight > HEIGHT_TO_CLOSE_DRAWER) return;
      setBottomDrawerHeight(DrawerSize.CLOSED);
    };

    window.addEventListener('resize', closeDrawer);

    return () => window.removeEventListener('resize', closeDrawer);
  }, []);

  return (
    <TwoWayCollapseView
      bottomDrawerContent={bottomDrawerContent}
      bottomDrawerExited={bottomDrawerExited}
      bottomDrawerHeight={bottomDrawerHeight}
      renderBottomDrawerHeaderIcon={renderBottomDrawerHeaderIcon}
      renderBottomDrawerHeaderTitle={renderBottomDrawerHeaderTitle}
      renderTopDrawerHeaderIcon={renderTopDrawerHeaderIcon}
      renderTopDrawerHeaderTitle={renderTopDrawerHeaderTitle}
      setBottomDrawerExited={setBottomDrawerExited}
      setBottomDrawerHeight={setBottomDrawerHeight}
      topDrawerContent={topDrawerContent}
    />
  );
};
