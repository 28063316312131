import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, ReactNode } from 'react';

interface IfFlagProps {
  children: ReactNode;
  fallback?: ReactNode;
  flag?: string;
  value?: boolean;
}

export const IfFlag: FC<IfFlagProps> = ({ children, fallback, flag, value = true }) => {
  const flags = useFlags();

  if (flag && !flags[flag] === value) return <>{fallback}</>;
  return <>{children}</>;
};
