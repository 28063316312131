import { DateTime } from 'luxon';
import { CAMERA_CONTENT_DATE_RANGE } from '../../constants';
import { NavigateState } from '../media-data';

export const getNavigateState = (date: Date): NavigateState => {
  const dateTime = DateTime.fromJSDate(date);

  return {
    endTime: dateTime.plus({ minutes: CAMERA_CONTENT_DATE_RANGE }).toJSDate(),
    startTime: dateTime.minus({ minutes: CAMERA_CONTENT_DATE_RANGE }).toJSDate(),
  };
};
