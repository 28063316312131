import { Tab } from '@mui/material';
import { FC, Fragment, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '../../components/badge';
import { AppliedFilter, FilterField } from '../../components/entity-search/types';
import { FilterDrawerContent, FilterFieldProps, SAVED_FILTER_KEY } from '../../components/filter';
import { Tabs } from '../../components/tabs';
import { T_MANY } from '../../constants';
import { testid } from '../../util/test-id';
import { buildFilterFields } from '../list/util';
import { AlertTabKey, AlertTabTypes } from './view.types';

interface Props {
  alertTabFilters: AlertTabTypes<FilterFieldProps[]>;
  onCloseClicked: () => unknown;
  setTabFilters: (filters: AlertTabTypes<AppliedFilter[]>) => void;
  tabsFilters: AlertTabTypes<AppliedFilter[]>;
}

interface AlertTab {
  content: JSX.Element;
  label: string;
}

enum TabType {
  'alerts',
  'things',
  'persons',
}

export const FilterDrawerTabs: FC<Props> = ({
  alertTabFilters,
  onCloseClicked,
  setTabFilters,
  tabsFilters,
}): JSX.Element => {
  const { t } = useTranslation(['common', 'terms']);
  const [selectedTab, setSelectedTab] = useState<number>(TabType.alerts);
  const tabKeys = Object.keys(alertTabFilters) as AlertTabKey[];

  const fields = (): AlertTabTypes<FilterField[]> => {
    const filterObj: AlertTabTypes<FilterField[]> = {
      alerts: [],
      persons: [],
      things: [],
    };
    for (const key of tabKeys) {
      if (Object.prototype.hasOwnProperty.call(alertTabFilters, key)) {
        alertTabFilters[key].forEach((filter, index) => {
          const filterFields = buildFilterFields(
            filter.entityTypes,
            tabsFilters[key],
            filter.typePropertyName,
            t('common:terms.tag'),
            filter.propertyLabel,
            index === 0 && key !== TabType[TabType.alerts],
            filter.pathIdentifier,
          );
          filterObj[key].push(...filterFields);
        });
      }
    }
    return filterObj;
  };

  const updatedFiltersChanged = (
    key: AlertTabKey,
    values: AppliedFilter[],
  ): void => {
    setTabFilters({ ...tabsFilters, [key]: values });
  };

  const tabLabel = (key: AlertTabKey): string => {
    switch (key) {
      case TabType[TabType.alerts]:
        return t('common:terms.alert', { count: T_MANY });
      case TabType[TabType.things]:
        return t('terms:thing', { count: T_MANY });
      case TabType[TabType.persons]:
        return t('terms:person', { count: T_MANY });
      default:
        return '';
    }
  };

  const tab = (field: FilterField[], key: AlertTabKey): AlertTab => {
    return {
      content: <FilterDrawerContent
        fields={field}
        filters={tabsFilters[key]}
        onCloseClicked={onCloseClicked}
        onFiltersChanged={(filters) => updatedFiltersChanged(key, filters)}
        showToolbar={false}
        storageKey={key}
        savedFilterKey={`${SAVED_FILTER_KEY}-${key}`}
      />,
      label: tabLabel(key),
    };
  };

  const handleOnChange = (_event: SyntheticEvent, value: number): void => {
    setSelectedTab(value);
  };

  const tabs: AlertTab[] = tabKeys.map((key) => tab(fields()[key], key));

  return <>
    <Tabs value={selectedTab} onChange={handleOnChange} centered>
      {tabs.map(({ label }, index) => (
        <Tab
          key={index}
          sx={{
            '& .MuiBadge-badge': { right: '-10px' },
            minWidth: '100px',
            padding: '16px 20px',
          }}
          data-testid={testid`filter-tab-${label}`}
          label={<Badge
            badgeContent={tabsFilters[tabKeys[index]].length}
          >
            {label}
          </Badge>
          }
        />
      ))}
    </Tabs>
    {tabs.map(({ content }, index) => {
      if (selectedTab === index) {
        return <Fragment key={index}>
          {content}
        </Fragment>;
      }
    })}
  </>;
};
