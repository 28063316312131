export const SERVICE_HISTORY_LIST_HEADERS = {
  op: 'ui',
  name: 'TableRow',
  children: [{
    op: 'ui',
    name: 'TableCell',
    children: {
      op: 'translate',
      translation: 'common:page.thing-detail.service-history.date.labels',
    },
  },
  {
    op: 'ui',
    name: 'TableCell',
    children: {
      op: 'translate',
      translation: 'common:page.thing-detail.service-history.service-type.labels',
    },
  },
  {
    op: 'ui',
    name: 'TableCell',
    sx: { textTransform: 'capitalize' },
    children: {
      op: 'context',
      path: '[0].display',
    },
  },
  {
    op: 'if',
    condition: {
      op: 'ne',
      a: {
        op: 'context',
        path: '[1].display',
      },
      b: null,
    },
    then: {
      op: 'ui',
      name: 'TableCell',
      sx: { textTransform: 'capitalize' },
      children: {
        op: 'context',
        path: '[1].display',
      },
    },
    else: {
      op: 'ui',
      name: 'TableCell',
      sx: { py: 1 },
      children: {
        op: 'ui',
        children: '',
      },
    },
  }],
};

export const SERVICE_HISTORY_LIST_ITEM = [{
  op: 'ui',
  name: 'TableCell',
  sx: { py: 1 },
  children: {
    op: 'ui',
    name: 'BasicColumn',
    wrap: true,
    label: '',
    children: {
      op: 'ui',
      name: 'FormatDate',
      format: 'short',
      value: {
        op: 'context',
        path: 'occurred',
      },
    },
  },
},
{
  op: 'ui',
  name: 'TableCell',
  sx: { py: 1 },
  children: {
    op: 'ui',
    name: 'BasicColumn',
    wrap: true,
    label: '',
    children: {
      op: 'context',
      path: 'serviceTypeDisplay',
    },
  },
},
{
  op: 'array:map',
  items: [
    { path: 'thingServiceMetrics', index: 0 },
    { path: 'thingServiceMetrics', index: 1 },
  ],
  to: {
    op: 'if',
    condition: {
      op: 'and',
      values: [
        {
          op: 'ne',
          a: {
            op: 'context',
            path: ['context', 'thingServiceMetrics', {
              op: 'context',
              path: ['item', 'index'],
            }],
          },
          b: null,
        },
        {
          op: 'ne',
          a: {
            op: 'context',
            path: ['context', 'thingServiceMetrics', {
              op: 'context',
              path: ['item', 'index'],
            }, 'value'],
          },
          b: null,
        },
      ],
    },
    then: {
      op: 'ui',
      name: 'TableCell',
      sx: { py: 1 },
      children: {
        op: 'if',
        condition: {
          op: 'eq',
          a: {
            op: 'context',
            path: [
              'context', 'thingServiceMetrics', {
                op: 'context',
                path: ['item', 'index'],
              }, 'value',
            ],
          },
          b: 0,
        },
        then: {
          op: 'ui',
          children: '',
        },
        else: {
          op: 'ui',
          name: 'FormatUnit',
          value: {
            op: 'context',
            path: [
              'context', 'thingServiceMetrics', {
                op: 'context',
                path: ['item', 'index'],
              }, 'value',
            ],
          },
          unit: {
            op: 'context',
            path: [
              'context', 'thingServiceMetrics', {
                op: 'context',
                path: ['item', 'index'],
              }, 'unit',
            ],
          },
        },
      },
    },
    else: {
      op: 'ui',
      name: 'TableCell',
      sx: { py: 1 },
      children: {
        op: 'ui',
        children: '',
      },
    },
  },
},
];

export const SERVICE_HISTORY_LIST_ITEM_MOBILE = [{
  op: 'ui',
  name: 'TableCell',
  sx: { p: 1 },
  children: {
    op: 'ui',
    name: 'ListPaperColumn',
    wrap: true,
    label: {
      op: 'translate',
      translation: 'common:page.thing-detail.service-history.date.labels',
    },
    children: {
      op: 'ui',
      name: 'FormatDate',
      format: 'short',
      value: {
        op: 'context',
        path: 'occurred',
      },
    },
  },
},
{
  op: 'ui',
  name: 'TableCell',
  sx: { p: 1 },
  children: {
    op: 'ui',
    name: 'ListPaperColumn',
    wrap: true,
    label: {
      op: 'translate',
      translation: 'common:page.thing-detail.service-history.service-type.labels',
    },
    children: {
      op: 'ui',
      name: 'Typography',
      sx: { minHeight: 24 },
      variant: 'body2',
      children: {
        op: 'context',
        path: 'serviceTypeDisplay',
      },
    },
  },
},
{
  op: 'array:map',
  items: [
    { path: 'thingServiceMetrics', index: 0 },
    { path: 'thingServiceMetrics', index: 1 },
  ],
  to: {
    op: 'ui',
    name: 'TableCell',
    sx: { p: 1 },
    children: {
      op: 'ui',
      name: 'ListPaperColumn',
      sx: { overflow: 'hidden', '& > label': { textTransform: 'capitalize' } },
      wrap: true,
      label: {
        op: 'context',
        path: [
          'context', 'thingServiceMetrics', {
            op: 'context',
            path: ['item', 'index'],
          }, 'display',
        ],
      },
      children: {
        op: 'if',
        condition: {
          op: 'eq',
          a: {
            op: 'context',
            path: [
              'context', 'thingServiceMetrics', {
                op: 'context',
                path: ['item', 'index'],
              }, 'value',
            ],
          },
          b: 0,
        },
        then: {
          op: 'ui',
          name: 'Box',
          sx: { minHeight: 24 },
          children: '',
        },
        else: {
          op: 'ui',
          name: 'FormatUnit',
          value: {
            op: 'context',
            path: [
              'context', 'thingServiceMetrics', {
                op: 'context',
                path: ['item', 'index'],
              }, 'value',
            ],
          },
          unit: {
            op: 'context',
            path: ['context', 'thingServiceMetrics', {
              op: 'context',
              path: ['item', 'index'],
            }, 'unit',
            ],
          },
        },
      },
    },
  },
},
];
