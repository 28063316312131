import { checkLocation, Location } from '@eagle/data-function-types';
import { captureException } from '@sentry/react';
import { check, ValidationError } from 'validata';
import { Undefinable } from '../types';

export const validateLocationType = (getLocation: () => unknown): Undefinable<Location> => {
  try {
    const location = getLocation();
    if (location === undefined || location === null) return undefined;

    return check(checkLocation, () => location);
  } catch (e) {
    if (e instanceof ValidationError) {
      captureException(e, { extra: { issues: e.issues } });
    } else {
      captureException(e);
    }
    return undefined;
  }
};
