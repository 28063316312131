/* eslint-disable react-hooks/exhaustive-deps */
import * as turf from '@turf/turf';
import L from 'leaflet';
import { throttle } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useNumberFlag } from '../../../flags/hooks';
import { ThingEventProperties } from '../../thing-event-pane/thing-event-pane.types';
import { AntPolyline } from './ant-polyline';
import MarkerClusters from './marker-clusters';
import { MouseOverLine } from './mouse-over-line';
import NormalPolyline from './normal-polyline';
import { RoutingItemNoSnapProps } from './route-matching.types';

export const RoutingItemNoSnap: FC<RoutingItemNoSnapProps> = ({
  color,
  handleBreadcrumbClick,
  handleNoSnapLoad,
  hoveredEventId,
  isAntPolylineEnabled,
  isSelected,
  isVisible,
  locations,
  renderer,
  selectedEvent,
  setHoveredEventId,
  setSelectedEvent,
}) => {
  const map = useMap();
  const clusterDistance = useNumberFlag('track-history-feature-clustering-distance-raw') ?? 50;
  const [visibleNearestPoints, setVisibleNearestPoints] = useState<turf.Feature<turf.Point, ThingEventProperties>[]>([]);

  const linePositions = useMemo(() => {
    const coords = locations.map(({ geometry }) => [geometry.coordinates[1], geometry.coordinates[0]]);

    return coords.length >= 2 ? turf.lineString(coords) : null;
  }, [locations]);

  const nearestPoints: turf.Feature<turf.Point, ThingEventProperties>[] = useMemo(() => {
    if (locations.length < 2) return [];
    return locations.map(({ properties, geometry: { coordinates } }) => {
      return turf.point([coordinates[0], coordinates[1]], {
        ...properties,
        cluster: false,
      });
    });
  }, [locations]);

  useEffect(() => {
    const updateVisiblePointsThrottled = throttle(() => {
      if (nearestPoints.length === 0) {
        setVisibleNearestPoints([]);
        return;
      }
      const bounds = map.getBounds();
      const startPoint = nearestPoints[0];
      const endPoint = nearestPoints[nearestPoints.length - 1];
      const middlePoints = nearestPoints.slice(1, -1).filter((point) =>
        bounds.contains(L.latLng(point.geometry.coordinates[1], point.geometry.coordinates[0])),
      );
      const visiblePoints = [startPoint, ...middlePoints, endPoint];
      setVisibleNearestPoints(visiblePoints);
    }, 500);
    updateVisiblePointsThrottled();

    map.on('moveend', updateVisiblePointsThrottled);
    map.on('zoomend', updateVisiblePointsThrottled);

    return () => {
      map.off('moveend', updateVisiblePointsThrottled);
      map.off('zoomend', updateVisiblePointsThrottled);
      updateVisiblePointsThrottled.cancel();
    };
  }, [map, nearestPoints]);

  useEffect(() => {
    handleNoSnapLoad();
  }, [locations, handleNoSnapLoad]);

  const lineComponent = useMemo(() => {
    if (!linePositions) return <></>;
    if (isAntPolylineEnabled) {
      return (
        <AntPolyline
          color={color}
          data={linePositions}
          isEnabled={isAntPolylineEnabled}
          map={map}
          paused={!isSelected}
        />
      );
    }

    return (
      <NormalPolyline
        color={color}
        data={linePositions}
        renderer={renderer}
      />
    );
  }, [linePositions, color, isSelected, isAntPolylineEnabled]);

  if (!isVisible || !nearestPoints.length) return <></>;

  return <>
    <MarkerClusters
      clusterRadius={clusterDistance}
      handleBreadcrumbClick={handleBreadcrumbClick}
      hoveredEventId={hoveredEventId}
      isSelected={isSelected}
      isVisible={isVisible}
      nearestPoints={nearestPoints}
      setHoveredEventId={setHoveredEventId}
      setSelectedEvent={setSelectedEvent}
    />
    {lineComponent}
    {linePositions && (
      <MouseOverLine
        clusterRadius={clusterDistance}
        data={linePositions}
        handleBreadcrumbClick={handleBreadcrumbClick}
        nearestPoints={visibleNearestPoints}
        renderer={renderer}
        selectedEvent={selectedEvent}
        setHoveredEventId={setHoveredEventId}
        setSelectedEvent={setSelectedEvent}
      />
    )}
  </>;
};
