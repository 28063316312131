import { TableRow as MuiTableRow } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface Props extends PropsWithChildren {
  hover?: boolean;
  link?: string;
  'data-testid'?: string;
  state?: Record<string, string>;
  sx?: SxProps;
}

export const TableRow: FC<Props> = ({ children, link, hover, state, sx, ...props }) => {
  const styles = link ? { cursor: 'pointer', textDecoration: 'none', ...sx } : sx;
  return <MuiTableRow {...props} hover={hover} to={link} state={state} component={link ? Link : 'div'} sx={styles}>{children}</MuiTableRow>;
};
