import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Dialog, IconButton, Paper, Slide, Stack, Tab } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { T_ONE } from '../../constants';
import { BottomNavigation } from '../bottom-navigation';

export enum EventDialogTabs {
  EVENT = 'event',
  MEDIA = 'media',
}

interface Props {
  actions: JSX.Element[];
  closeDialog: () => void;
  'data-testid'?: string;
  eventData: JSX.Element;
  handleTabChange: (event: React.SyntheticEvent, value: EventDialogTabs) => void;
  mediaComponent?: JSX.Element;
  open: boolean;
  pageTitle: JSX.Element;
  selectedTab: EventDialogTabs;
}

export const EventDialogMobile: FC<Props> = ({
  actions,
  closeDialog,
  eventData,
  handleTabChange,
  mediaComponent,
  open,
  pageTitle,
  selectedTab,
  ...props
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Dialog data-testid={props['data-testid']} fullScreen onClose={closeDialog} open={open}>
      <Stack direction="row" padding={2} sx={{ justifyContent: 'space-between' }}>
        {pageTitle}
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <TabContext value={selectedTab}>
        {mediaComponent && (
          <TabList onChange={handleTabChange} variant="fullWidth">
            <Tab label={t('common:terms.event', { count: T_ONE })} value={EventDialogTabs.EVENT} />
            <Tab label={t('common:features.media')} value={EventDialogTabs.MEDIA} />
          </TabList>
        )}
        <Slide direction="right" in={selectedTab === EventDialogTabs.EVENT} mountOnEnter unmountOnExit>
          <TabPanel sx={{ overflow: 'auto', mb: 6, p: 2.25 }} value={EventDialogTabs.EVENT}>
            {eventData}
          </TabPanel>
        </Slide>
        {mediaComponent && (
          <Slide direction="left" in={selectedTab === EventDialogTabs.MEDIA} mountOnEnter unmountOnExit>
            <TabPanel sx={{ overflow: 'auto', mb: 3 }} value={EventDialogTabs.MEDIA}>
              {mediaComponent}
            </TabPanel>
          </Slide>
        )}
      </TabContext>
      <Paper sx={{ bottom: 0, left: 0, position: 'fixed', right: 0 }}>
        <BottomNavigation>
          {actions}
        </BottomNavigation>
      </Paper>
    </Dialog>
  );
};
