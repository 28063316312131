import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';

const Transition = (
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
): JSX.Element => <Slide direction="up" ref={ref} {...props} />;

export const SlideTransitionComponent = forwardRef(Transition);
