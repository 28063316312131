import type { LastThingEvent, ThingFeature } from '@eagle/core-data-types';
import { isPath, isString, type Operator } from '@jexop/core';
import _ from 'lodash';
import type { Nullable } from '../types';
import {
  filterEvents,
  flattenLastEvents,
  getLastEvents,
  stringWithDefault
} from './data-feature.utils';

export const lastEventOperator: Operator<unknown> = (
  { feature, eventTypeId, path, default: defaultValue },
  context,
): Nullable<unknown> => {
  const lastEvents = getLastEvents(context);
  if (!lastEvents) return defaultValue ?? null;
  if (!isString(feature) || !isString(eventTypeId)) return defaultValue ?? null;

  if (!isPath(path)) {
    return _.get(
      lastEvents,
      [feature, eventTypeId, 'occurred'],
      defaultValue ?? null,
    );
  }

  return _.get(
    _.get(lastEvents, [feature, eventTypeId, 'data']),
    path,
    defaultValue ?? null,
  );
};

const latestEvent: Operator<LastThingEvent> = (
  { feature, havingPath, excludeEventTypes },
  context,
): Nullable<LastThingEvent> => {
  const lastEvents = getLastEvents(context);
  if (!lastEvents) return null;
  if (!isString(feature)) return null;
  const filterPath = isString(havingPath) ? havingPath : null;
  const validatedExcludeEventTypes = Array.isArray(excludeEventTypes) ? excludeEventTypes.filter(isString) : null;

  const flat = filterEvents(feature, flattenLastEvents(lastEvents))?.filter(
    (event) => {
      const hasPath = !filterPath || !!_.has(event, filterPath);
      const isExcluded = !!validatedExcludeEventTypes?.includes(event.eventTypeId);

      return hasPath && !isExcluded;
    },
  );
  if (!flat || !flat.length) return null;

  flat.sort((a, b) => b.occurred.getTime() - a.occurred.getTime());

  return flat[0];
};

export const latestEventOperator: Operator = (...args) => {
  const [{ path }] = args;
  const latest = latestEvent(...args);
  if (!latest) return null;

  const pickPath = isString(path) ? path : null;
  return pickPath ? _.get(latest, pickPath) : latest;
};

export const latestEventOccurredOperator: Operator<Date> = (
  ...args
): Nullable<Date> => {
  const latest = latestEvent(...args);
  if (!latest) return null;

  return latest.occurred;
};

export const featureActivityIsStartedOperator: Operator<boolean> = (
  { feature, startEventTypeId, finishEventTypeId },
  context,
): Nullable<boolean> => {
  const lastEvents = getLastEvents(context);
  if (!lastEvents) return null;
  if (!isString(feature)) return null;

  const start = _.get(
    lastEvents,
    [feature, stringWithDefault(startEventTypeId, 'start')],
    null,
  );
  const finish = _.get(
    lastEvents,
    [feature, stringWithDefault(finishEventTypeId, 'finish')],
    null,
  );

  if (!start?.occurred && !finish?.occurred) return null;
  const now = new Date();
  if (start?.occurred && !finish?.occurred) return start.occurred <= now;
  if (!start?.occurred && finish?.occurred) return finish.occurred > now;

  if (!start?.occurred || !finish?.occurred) return null;
  return start.occurred >= finish.occurred;
};

export const hasFeatureDataOperator: Operator<boolean> = (
  { feature },
  context,
): Nullable<boolean> => {
  const lastEvents = getLastEvents(context);

  if (!feature) return !!lastEvents;
  if (!isString(feature)) return null;

  if (!context || typeof context !== 'object') return null;
  const features = _.get(context, ['thingType', 'features']) as
    | ThingFeature[]
    | undefined;
  if (!features || !Array.isArray(features)) return false;

  if (!lastEvents) return null;

  const featureEvents = _.get(lastEvents, [feature], null);

  const featureId = feature.split('/')[0];
  return (
    featureEvents &&
    !!Object.keys(featureEvents).length &&
    features.some((f) => f.featureId === featureId)
  );
};

export const ifFeatureDataOperator: Operator = (
  { feature, then, else: fallback },
  context,
) => {
  const hasFeatureData = hasFeatureDataOperator({ feature }, context);
  return hasFeatureData ? then : fallback;
};
