/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from '@mui/system';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TileLayer, useMap } from 'react-leaflet';
import { HERE_MAP_API_KEY, HERE_MAP_APP_CODE, HERE_MAP_APP_ID } from '../../../constants';
import { useConfig } from '../../../hooks/use-config';
import { addMapLangParams } from './utils';

const NoGapTileLayer = styled(TileLayer)(() => ({
  '.leaflet-tile-container img': {
    height: '256.5px !important',
    width: '256.5px !important',
  },
}));

const japanSKCoordinates = {
  south: 22.06920941147494,
  north: 44.10590740153091,
  west: 122.79228542087634,
  east: 145.83079673581972,
};

const southKoreaCoordinates = {
  south: 32.7688004848817,
  north: 38.95121955224346,
  west: 121.77246093750001,
  east: 132.04800688972978,
};

const ParcelBoundaries: FC = () => {
  const { t, i18n } = useTranslation(['common']);
  const config = useConfig();
  const map = useMap();

  const userLang = i18n.language;
  const appId = config.hereMaps?.appId ?? HERE_MAP_APP_ID;
  const appCode = config.hereMaps?.appCode ?? HERE_MAP_APP_CODE;
  const hereApi = config.hereMaps?.apiKey ?? HERE_MAP_API_KEY;
  const baseUrl = addMapLangParams(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?apiKey=${hereApi}&size=256`, userLang, false);
  const [tileUrl, setTileUrl] = useState(baseUrl);
  const copyrightString = t('common:component.map.labels.copyright-parcel-boundaries-map', { year: new Date().getFullYear() });

  const updateMapLayer = useCallback((): void => {
    const zoomLevel = Math.round(map.getZoom());
    let newUrl = baseUrl;

    if (zoomLevel >= 10) {
      const lastKnownBounds = map.getBounds();
      const isJapanSKRegion = lastKnownBounds.getSouth() > japanSKCoordinates.south && lastKnownBounds.getNorth() < japanSKCoordinates.north && lastKnownBounds.getWest() > japanSKCoordinates.west && lastKnownBounds.getEast() < japanSKCoordinates.east;
      const isSouthKorea = lastKnownBounds.getSouth() > southKoreaCoordinates.south && lastKnownBounds.getNorth() < southKoreaCoordinates.north && lastKnownBounds.getWest() > southKoreaCoordinates.west && lastKnownBounds.getEast() < southKoreaCoordinates.east;

      if (isSouthKorea) {
        newUrl = addMapLangParams(`https://1.base.maps.api.heremaps.kr/maptile/2.1/maptile/newest/normal.day/${zoomLevel}/{x}/{y}/256/png8?app_id=${appId}&app_code=${appCode}`, userLang, isSouthKorea);
      } else if (isJapanSKRegion) {
        newUrl = `${baseUrl}&style=tko.normal.day`;
      }
    }

    setTileUrl(newUrl);
  }, [map]);

  useEffect(() => {
    updateMapLayer();
  }, []);

  useEffect(() => {
    map.attributionControl.addAttribution(copyrightString);

    map.on('moveend zoomend layerremove', updateMapLayer);
    updateMapLayer();

    return () => {
      map.off('moveend zoomend layerremove', updateMapLayer);
    };
  }, [map, updateMapLayer, copyrightString]);

  const renderTile = useMemo(() => {
    return (
      <NoGapTileLayer key={tileUrl} url={tileUrl} />
    );
  }, [tileUrl]);

  return (
    <>
      {renderTile}
    </>
  );
};

export default ParcelBoundaries;
