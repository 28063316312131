import cx from 'classnames';
import { FC, useMemo } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  '@keyframes loading': {
    '0%': {
      backgroundPosition: 'left bottom',
    },
    '100%': {
      backgroundPosition: 'right bottom',
    },
  },
  field: {
    animationName: '$loading',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    background: 'linear-gradient(left , rgba(255,255,255,0) 30%, rgba(255,255,255,0.66) 80%, rgba(255,255,255,0) 100%) repeat',
    backgroundColor: '#e0e0e0',
    backgroundSize: '50% 100%',
    borderRadius: '1rem',
    display: 'inline-block',
    height: '0.6rem',
    minWidth: '5rem',
    verticalAlign: 'middle',
  },
});

interface Props {
  className?: string;
}

export const FieldLoading: FC<Props> = ({ className }) => {
  const classes = useStyles();
  const width = useMemo(() => Math.random() * 3 + 4, []);
  return <span className={cx(classes.field, className)} style={{ minWidth: `${width}rem` }} />;
};
