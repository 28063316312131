import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { AuthStatus, useAuth } from '../../auth';
import { useConfig } from '../use-config';
import { flattenTags, prefixLabels } from './tracking.utils';

interface MaybeWithHotjar {
  hj?: (command: string, userId: string, params: Record<string, unknown>) => void;
}

export const useHotjar = (): void => {
  const { hotjar: hotjarConfig } = useConfig();
  const { state } = useAuth();

  useEffect(() => {
    if (!hotjarConfig || !hotjarConfig.hotjarId || !hotjarConfig.scriptVersion) return;

    hotjar.initialize(Number(hotjarConfig.hotjarId), Number(hotjarConfig.scriptVersion));
  }, [hotjarConfig]);

  useEffect(() => {
    if (!state || state.status !== AuthStatus.AUTHENTICATED) return;

    const { account, user } = state;

    (window as MaybeWithHotjar).hj?.('identify', user._id, {
      'email': user.email,
      'name': user.display,
      ...flattenTags(user.tags, 'user_tag'),
      ...prefixLabels(user.labels, 'user_label'),
      'account_id': account._id,
      'account_name': account.display,
      ...flattenTags(account.tags, 'account_tag'),
      ...prefixLabels(account.labels, 'account_label'),
    });
  }, [state]);
};
