/* eslint-disable react-hooks/exhaustive-deps */
import { Geofence, Thing } from '@eagle/core-data-types';
import { Position } from '@turf/turf';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { FC, KeyboardEvent as ReactKeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useParams } from 'react-router-dom';
import { Subject } from 'rxjs';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { useAuthenticated } from '../../auth';
import { SEARCH_DEBOUNCE, SEARCH_RESULT_THING_LIMIT } from '../../constants';
import { useFetchAllCache, useObservable, usePromise } from '../../hooks';
import { useSmallScreen } from '../../hooks/use-small-screen';
import { FindItemsDeferredResult, FindItemsResult, Query } from '../../pages/list/types';
import { useSearch } from '../../pages/list/use-search';
import { buildFilterFields } from '../../pages/list/util';
import { CacheDataTypes, CommonEntityWithDeleted } from '../../types';
import { filterDeleted, getFilterPersisted, removeFilterItems, trackEvent } from '../../util';
import { useMapContext } from '../../util/maps';
import { AppliedFilter, AppliedFilterType, FilterField } from '../entity-search/types';
import { ErrorMessage } from '../error-message';
import { FilterFieldProps, FilterPathIdentifiers, FilterTypes } from '../filter';
import { useMapLayers } from '../map';
import { ToggleLayers } from '../map/layer-selection/layer-selection.types';
import { getFilterProps } from '../map/layer-selection/sub-menu/sub-menu.util';
import { coordinatesToLatLng } from '../map/layers/route-matching/util';
import { MapDiscoverItem } from './search-map.types';
import { getAddressObservable, getGeofenceObservable, getThingObservable } from './search-observable';
import View from './view';

export interface FindAddressProps {
  bounds: {
    east: number;
    north: number;
    south: number;
    west: number;
  };
  search: string;
}

export interface Props {
  disableFilter?: boolean;
  filterFields?: FilterFieldProps[];
  isInlayMap?: boolean;
  onAddressQueryChanged: (query: FindAddressProps) => FindItemsDeferredResult<MapDiscoverItem>;
  onGeofenceQueryChanged?: (query: Query) => FindItemsDeferredResult<Geofence>;
  onThingQueryChanged?: (query: Query) => FindItemsDeferredResult<Thing>;
  renderListContent: (
    items: { things?: FindItemsResult<Thing>; geofences?: FindItemsResult<Geofence>; addresses?: FindItemsResult<MapDiscoverItem> },
    isLoading: boolean,
    handleDrawerClose: () => void,
    highlightIndex?: number,
    searchQuery?: string,
  ) => JSX.Element;
  renderFilterContent?: (
    filters: AppliedFilter<AppliedFilterType>[],
    setFilterOpen: (value: boolean) => void,
    onFiltersChanged: (filters: AppliedFilter<AppliedFilterType>[]) => unknown,
  ) => JSX.Element;
  storageKey: string;
  tFunction: TFunction;
}

const OBSERVABLE_NOT_FOUND = {
  result: {
    itemCount: 0,
    results: [],
  },
  resultDescription: 'NOT FOUND',
};

export const ThingSearchController: FC<Props> = ({
  disableFilter: disableFilterProp = false,
  filterFields = [],
  isInlayMap,
  onAddressQueryChanged,
  onGeofenceQueryChanged,
  onThingQueryChanged,
  renderListContent,
  renderFilterContent,
  storageKey,
  tFunction,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { account } = useAuthenticated();
  const {
    disableFilter,
    disableFilters,
    enableFilters,
    filters,
    geofencePagination,
    highlightIndex,
    isLoading,
    pagination: thingPagination,
    setFilters,
    setHighlightIndex,
    setIsLoading,
    setText,
    text,
  } = useSearch();
  const smallScreen = useSmallScreen();
  const map = useMap();
  const { onAddressSelected, onItemSelected, onGeofenceSelected } = useMapContext();
  const { thingId } = useParams();
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchBox, setSearchBox] = useState(false);
  const { geofenceFilters, setGeofenceFilters, selectedLayers, setSelectedLayers } = useMapLayers();
  const [bounds, setBounds] = useState({
    east: map.getBounds().getEast(),
    north: map.getBounds().getNorth(),
    south: map.getBounds().getSouth(),
    west: map.getBounds().getWest(),
  });
  const filterCache = useFetchAllCache(CacheDataTypes.GEOFENCE_TYPE);
  const [filterItems] = usePromise(
    async () => {
      const data = await filterCache.all<CommonEntityWithDeleted>();
      return filterDeleted(data);
    },
    [filterCache],
  );

  const onError = (error: Error): void => {
    enqueueSnackbar(<ErrorMessage error={error} />, { variant: 'error' });
  };

  const { handleThingQueryChanged, thingObservable } = useMemo(() => {
    const subject = new Subject<Query>();
    return {
      handleThingQueryChanged: (query: Query): void => subject.next(query),
      thingObservable: subject.pipe(
        tap(() => setIsLoading(true)),
        debounceTime(SEARCH_DEBOUNCE),
        switchMap((query: Query) => {
          const deferredThing = onThingQueryChanged?.({ dateRange: query.dateRange, filters: query.filters, pagination: query.pagination, search: query.search });
          const thingObservable = getThingObservable(deferredThing ?? { promise: new Promise((resolve) => { resolve(OBSERVABLE_NOT_FOUND); }), cancel: () => { } }, onError);
          return thingObservable.pipe(
            finalize(() => setIsLoading(false)),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [onThingQueryChanged]);

  const { handleGeofenceQueryChanged, geofenceObservable } = useMemo(() => {
    const subject = new Subject<Query>();
    return {
      handleGeofenceQueryChanged: (query: Query): void => subject.next(query),
      geofenceObservable: subject.pipe(
        tap(() => setIsLoading(true)),
        debounceTime(SEARCH_DEBOUNCE),
        switchMap((query: Query) => {
          const deferredGeofence = onGeofenceQueryChanged?.({ dateRange: query.dateRange, filters: query.filters, pagination: query.geofencePagination, search: query.search });
          const geofenceObservable = getGeofenceObservable(deferredGeofence ?? { promise: new Promise((resolve) => { resolve(OBSERVABLE_NOT_FOUND); }), cancel: () => { } }, onError);
          return geofenceObservable.pipe(
            finalize(() => setIsLoading(false)),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [onGeofenceQueryChanged]);

  const { handleAddressQueryChanged, addressObservable } = useMemo(() => {
    const subject = new Subject<Query>();
    return {
      handleAddressQueryChanged: (query: Query): void => subject.next(query),
      addressObservable: subject.pipe(
        tap(() => setIsLoading(true)),
        debounceTime(SEARCH_DEBOUNCE),
        switchMap((query: Query) => {
          const deferredAddress = onAddressQueryChanged({ bounds, search: query.search });
          const addressObservable = getAddressObservable(deferredAddress, onError);
          return addressObservable.pipe(
            finalize(() => setIsLoading(false)),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [onAddressQueryChanged, bounds]);

  const data = {
    things: useObservable(thingObservable, onError),
    geofences: useObservable(geofenceObservable, onError),
    addresses: useObservable(addressObservable, onError),
  };

  const onKeyDown = useCallback((event: ReactKeyboardEvent): void => {
    const itemCount = (data.things?.result.results.length ?? 0) + (data.addresses?.result.results.length ?? 0) + (data.geofences?.result.results.length ?? 0);
    switch (event.key) {
      case 'Escape':
        event.preventDefault();
        setHighlightIndex(undefined);
        setText?.('');
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (isLoading || !searchBox) break;
        setHighlightIndex(
          highlightIndex === undefined || highlightIndex < 1
            ? undefined
            : highlightIndex - 1,
        );
        break;
      case 'f':
        if (event.ctrlKey || event.metaKey || event.altKey) {
          setFilterOpen(!filterOpen);
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (isLoading || !itemCount || !searchBox) break;
        if (highlightIndex === undefined) {
          setHighlightIndex(0);
        }
        else if (highlightIndex < itemCount - 1) {
          setHighlightIndex(highlightIndex + 1);
        }
        break;
    }
  }, [highlightIndex, isLoading, filterOpen, data]);

  const onKeyUp = useCallback((event: ReactKeyboardEvent): void => {
    const thingItemCount = (data?.things && ((data.things.result.itemCount) > SEARCH_RESULT_THING_LIMIT ? SEARCH_RESULT_THING_LIMIT : (data.things.result.itemCount))) ?? 0;
    const geofenceItemCount = (data?.geofences && ((data.geofences.result.itemCount) > SEARCH_RESULT_THING_LIMIT ? SEARCH_RESULT_THING_LIMIT : (data.geofences.result.itemCount))) ?? 0;
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        if (isLoading || !data || highlightIndex === undefined) break;
        if ((data.things?.result.itemCount)
          && highlightIndex >= 0
          && highlightIndex < thingItemCount) {
          const center = map.getCenter();
          const position = {
            alt: map.getZoom(),
            lat: center.lat,
            lng: center.lng,
            timeStamp: DateTime.now().toMillis(),
          };
          setHighlightIndex(undefined);
          if (!data.things) break;
          onItemSelected(data.things.result.results[highlightIndex]._id, position); // TODO: TP-7793 Disable click on no location
          break;
        }
        if ((data.geofences?.result.results)
          && highlightIndex >= thingItemCount
          && highlightIndex < thingItemCount + geofenceItemCount) {
          const geofence = data.geofences.result.results[highlightIndex - thingItemCount] as unknown as Geofence;
          const geofenceLayerToggled = selectedLayers.some((layer) => layer === ToggleLayers.geofences);
          if (!geofenceLayerToggled) setSelectedLayers([...selectedLayers, ToggleLayers.geofences]);
          const geofenceCoords = geofence.geometry.coordinates[0].map((itemCoords) => {
            return coordinatesToLatLng(itemCoords as Position);
          });
          if (!geofenceFilters.find((filter) => filter.id === geofence.geofenceTypeId)) {
            const geofenceItem = filterItems?.find((filterItem) => filterItem._id === geofence.geofenceTypeId);
            if (geofenceItem) {
              setGeofenceFilters([...geofenceFilters, getFilterProps(geofenceItem, ToggleLayers.geofences, FilterTypes.GEOFENCE_TYPE)]);
            }
          }
          onGeofenceSelected(geofenceCoords, map);
          setHighlightIndex(undefined);
          break;
        }
        if ((data.addresses?.result.results)
          && highlightIndex >= thingItemCount + geofenceItemCount) {
          onAddressSelected(data.addresses.result.results[highlightIndex - (thingItemCount + geofenceItemCount)], map);
          setHighlightIndex(undefined);
          break;
        }
    }
  }, [highlightIndex, isLoading, data]);

  const handleBoundsChange = (): void => {
    setBounds({
      east: map.getBounds().getEast(),
      north: map.getBounds().getNorth(),
      south: map.getBounds().getSouth(),
      west: map.getBounds().getWest(),
    });
  };

  const handleInputFocus = (): void => {
    setSearchBox(text !== '');
    trackEvent('keyword_search', 'focus_input', 'thing_map');
  };

  const handleDrawerClose = (): void => {
    setSearchBox(smallScreen ? false : true);
  };

  useEffect(() => {
    if (text === '' && filters.length === 0) return;
    handleThingQueryChanged({ filters, pagination: thingPagination, search: text });
  }, [filters, thingPagination, text]);

  useEffect(() => {
    if (text === '' && filters.length === 0) return;
    handleGeofenceQueryChanged({ filters, geofencePagination, search: text });
  }, [filters, geofencePagination, text]);

  useEffect(() => {
    if (text === '' && filters.length === 0) return;
    handleAddressQueryChanged({ filters, search: text });
  }, [filters, text, bounds]);

  useEffect(() => {
    setSearchBox(text !== '');
  }, [text]);

  useEffect(() => {
    if (disableFilterProp) return disableFilters();
    enableFilters();
  }, [disableFilterProp]);

  useEffect(() => {
    map.on('dragend', handleBoundsChange);
    map.on('zoomend', handleBoundsChange);
    return () => {
      map.off('dragend', handleBoundsChange);
      map.off('zoomend', handleBoundsChange);
    };
  }, []);

  useEffect(() => {
    const pathIdentifiers = [FilterPathIdentifiers.THING, FilterPathIdentifiers.GROUP];
    const persistedFilters = getFilterPersisted(storageKey, pathIdentifiers);
    setFilters(persistedFilters);
  }, [account]);

  const fields = useMemo(
    () => {
      const fieldsArray: FilterField[] = [];
      filterFields.forEach((filter, index) => {
        const filterField = buildFilterFields(filter.entityTypes, filters, filter.typePropertyName, tFunction('common:terms.tag'), filter.propertyLabel, index !== 0 ? false : true);
        fieldsArray.push(...filterField);
      });
      return fieldsArray;
    },
    [filterFields, filters],
  );

  const removeFilter = useCallback((filterToRemove: AppliedFilter) => {
    removeFilterItems([filterToRemove]);
    setFilters(filters.filter((f) => f.id !== filterToRemove.id));
  }, [filters]);

  return (
    <View
      appliedFilters={thingId ? [] : filters}
      disableFilter={disableFilter}
      fields={fields}
      filterDrawerComponent={renderFilterContent?.(filters, setFilterOpen, setFilters)}
      handleInputFocus={handleInputFocus}
      isInlayMap={isInlayMap}
      listContent={data && renderListContent(data, isLoading, handleDrawerClose, highlightIndex, text)}
      onFiltersChanged={setFilters}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      openFilter={filterOpen}
      removeAllFilters={() => {
        removeFilterItems(filters);
        setFilters([]);
      }}
      removeFilter={removeFilter}
      searchBox={searchBox}
      setFilterOpen={setFilterOpen}
      storageKey={storageKey}
      tFunction={tFunction}
    />
  );
};
