/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, FormControl, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { useBoolFlag } from '../../components';
import { usePromise } from '../../hooks';
import { Undefinable } from '../../types';
import { getAvailableEventTypes, getEventLabelWithFlag, getFeatureLabelWithFlag, useAlertableFeatures } from '../../util';
import { FeatureAlertSettingsCardProps, Settings } from './subscription-detail.types';

const ALL = 'all';
export const FILTER_NON_ALERTABLE_DATA_FLAG = 'admin-filter-non-alertable-alert-conditions-and-subscriptions-feature';

export const FeatureAlertSettingsCard: FC<FeatureAlertSettingsCardProps> = ({ alertFilters, fieldDisabled, getSettingsState }): JSX.Element => {
  const { restClient } = useAuthenticated();
  const { t } = useTranslation(['admin', 'common']);
  const {
    alertableFeatures,
    alertableFeaturesError,
    alertableFeatureTypes,
    alertableFeatureTypesError,
    excludedFeatures,
  } = useAlertableFeatures();
  const [settings, setSettings] = useState<Settings>({
    alertTypeId: alertFilters.alertTypeId,
    feature: alertFilters.feature,
    featureTypeIdPrefix: alertFilters.featureTypeIdPrefix,
  });
  const filterNonAlertableData = useBoolFlag(FILTER_NON_ALERTABLE_DATA_FLAG);
  const flagUseEventV3 = useBoolFlag('portals-translation-retrieval-hook-for-event-and-alert-descriptions-feature');

  const [featureTypePrefixes, featureTypePrefixesError] = usePromise<string[]>(
    async () => {
      const result = await restClient.my.featureTypeIdPrefixes.getAll();
      const data = filterNonAlertableData
        ? result.filter((prefix) => alertableFeatureTypes?.some(({ _id }) => _id.startsWith(prefix)))
        : result;
      return data.sort();
    },
    [restClient, alertableFeatureTypes, filterNonAlertableData],
  );

  const [features, featuresError] = usePromise<string[]>(
    async () => {
      const result = await restClient.my.featureInstances.getAll({
        featureTypeIdPrefix: settings.featureTypeIdPrefix ?? undefined,
      });
      const data = settings.featureTypeIdPrefix || !filterNonAlertableData
        ? result
        : result.filter((feature) => alertableFeatures?.some(({ _id }) => _id === feature));
      return data.sort();
    },
    [restClient, settings.featureTypeIdPrefix, alertableFeatures, filterNonAlertableData],
  );

  const availableFeatures: Undefinable<{ isHidden?: boolean; value: string }[]> = useMemo(() => {
    if (!features || !alertableFeatures || !alertableFeatureTypes) return;
    if (!filterNonAlertableData || !excludedFeatures) {
      return features.map((feature) => ({ value: feature }));
    }

    const result: { isHidden?: boolean; value: string }[] = [];
    for (const featureInstance of features) {
      const feature = featureInstance.split('/')[0];
      const featureData = alertableFeatures.find(({ _id }) => _id === feature);
      const featureType = alertableFeatureTypes.find(({ _id }) => _id === featureData?.featureTypeId);
      const eventTypes = getAvailableEventTypes(feature, featureType, excludedFeatures);
      if (eventTypes.length > 0) {
        result.push({ value: featureInstance });
        continue;
      }
      if (featureInstance === alertFilters.feature) {
        result.push({ value: featureInstance, isHidden: true });
      }
    }

    return result;
  }, [features, alertableFeatures, alertableFeatureTypes, excludedFeatures, alertFilters.feature, filterNonAlertableData]);

  const [alertTypes, alertTypesError] = usePromise<string[]>(
    async () => {
      const result = await restClient.my.alertTypes.getAll({
        featureTypeIdPrefix: settings.featureTypeIdPrefix ?? undefined,
      });
      return result.sort();
    },
    [restClient, settings.featureTypeIdPrefix],
  );

  const availableAlertTypes: Undefinable<{ isHidden?: boolean; value: string }[]> = useMemo(() => {
    if (!alertTypes || !alertableFeatures || !alertableFeatureTypes) return;
    if (!filterNonAlertableData || !excludedFeatures) {
      return alertTypes.map((feature) => ({ value: feature }));
    }

    const result: { isHidden?: boolean; value: string }[] = [];
    for (const alertType of alertTypes) {
      if (!settings.feature) {
        result.push({ value: alertType });
        continue;
      }
      const feature = settings.feature?.split('/')[0];
      const featureData = alertableFeatures.find(({ _id }) => _id === feature);
      const featureType = alertableFeatureTypes.find(({ _id }) => _id === featureData?.featureTypeId);
      const eventTypes = getAvailableEventTypes(feature ?? undefined, featureType, excludedFeatures);
      if (eventTypes.includes(alertType)) {
        result.push({ value: alertType });
        continue;
      }
      if (settings.feature === alertFilters.feature && alertType === alertFilters.alertTypeId) {
        result.push({ value: alertType, isHidden: true });
      }
    }

    return result;
  }, [alertTypes, alertableFeatures, alertableFeatureTypes, excludedFeatures, settings.feature, alertFilters.alertTypeId, alertFilters.feature, filterNonAlertableData]);

  useEffect(() => getSettingsState(settings), [settings]);

  const handleFeatureChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSettings((settings) => {
      const feature = event.target.value.split('/')[0];
      const featureData = alertableFeatures?.find(({ _id }) => _id === feature);
      const featureType = alertableFeatureTypes?.find(({ _id }) => _id === featureData?.featureTypeId);
      const eventTypes = getAvailableEventTypes(feature, featureType, excludedFeatures);

      return {
        ...settings,
        feature: event.target.value,
        ...(settings.alertTypeId && !eventTypes.includes(settings.alertTypeId) && { alertTypeId: null }),
      };
    });
  };

  if (alertableFeatureTypesError || alertableFeaturesError || featureTypePrefixesError || featuresError || alertTypesError) {
    return (
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h5">{t('admin:page.create-subscription.feature-alert-setting.labels')}</Typography>
            <Typography>{t('admin:page.create-subscription.feature-alert-setting.hint')}</Typography>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h5">{t('admin:page.create-subscription.feature-alert-setting.labels')}</Typography>
          <FormControl>
            <TextField
              disabled={fieldDisabled}
              label={t('admin:page.subscriptions-list.feature-type.label')}
              onChange={(e) => {
                setSettings({
                  alertTypeId: null,
                  feature: null,
                  featureTypeIdPrefix: e.target.value === ALL ? null : e.target.value,
                });
              }}
              select
              size="small"
              value={settings.featureTypeIdPrefix ?? ALL}
            >
              <MenuItem value={ALL}>{t('admin:common.labels.all')}</MenuItem>
              {featureTypePrefixes?.length
                ? featureTypePrefixes.map((prefix, i) => <MenuItem key={i} value={prefix}>{getFeatureLabelWithFlag(prefix, flagUseEventV3)}</MenuItem>)
                : <MenuItem />
              }
            </TextField>
          </FormControl>
          <FormControl>
            <TextField
              disabled={fieldDisabled || !availableFeatures?.length}
              label={t('common:terms.feature')}
              onChange={handleFeatureChange}
              select
              size="small"
              value={settings.feature ?? ''}
            >
              {availableFeatures?.map(({ value, isHidden }, i) => (
                <MenuItem key={i} value={value} disabled={isHidden} sx={{ display: isHidden ? 'none' : undefined }}>
                  {value.includes('camera')
                    ? t(`common:features.camera-type.${value}`)
                    : getFeatureLabelWithFlag(value, flagUseEventV3)}
                </MenuItem>
              )) ?? <MenuItem />}

            </TextField>
          </FormControl>
          <FormControl>
            <TextField
              disabled={settings.featureTypeIdPrefix === ALL || !settings.featureTypeIdPrefix || fieldDisabled || !availableAlertTypes?.length}
              label={t('common:terms.alert-type')}
              onChange={(e) => setSettings({ ...settings, alertTypeId: e.target.value })}
              select
              size="small"
              value={settings.alertTypeId ?? ''}
            >
              {availableAlertTypes?.filter((alertType) => !alertType.isHidden).length === 0 && (
                <MenuItem disabled value="">
                  {t('common:common.hint.list.no-results')}
                </MenuItem>
              )}
              {availableAlertTypes?.map(({ value, isHidden }, i) => (
                <MenuItem key={i} value={value} disabled={isHidden} sx={{ display: isHidden ? 'none' : undefined }}>
                  {(settings.featureTypeIdPrefix && settings.feature) ? getEventLabelWithFlag(`${settings.featureTypeIdPrefix}-v0`, value, settings.feature, flagUseEventV3) : value}
                </MenuItem>
              )) ?? <MenuItem />}
            </TextField>
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  );
};
