import { LinkResource } from '../types';

export namespace PortalResources {

  export const Track:LinkResource[] = [
    {
      id: 'custom-css',
      href: 'css/custom.css',
      rel: 'stylesheet',
    },
    {
      id: 'apple-touch-icon',
      href: 'icon/track/apple-touch-icon',
      rel: 'apple-touch-icon',
    },
    {
      id: 'favicon-svg',
      href: 'icon/track/favicon-svg',
      rel: 'icon',
    },
    {
      id: 'favicon-32',
      href: 'icon/track/favicon-32',
      rel: 'icon',
    },
    {
      id: 'favicon-16',
      href: 'icon/track/favicon-16',
      rel: 'icon',
    },
    {
      id: 'mask-icon',
      href: 'icon/track/mask-icon',
      rel: 'mask-icon',
    },
    {
      id: 'shortcut-icon',
      href: 'icon/track/favicon',
      rel: 'shortcut icon',
    },
  ];

  export const Admin:LinkResource[] = [
    {
      id: 'custom-css',
      href: 'css/custom.css',
      rel: 'stylesheet',
    },
    {
      id: 'apple-touch-icon',
      href: 'icon/admin/apple-touch-icon',
      rel: 'apple-touch-icon',
    },
    {
      id: 'favicon-svg',
      href: 'icon/admin/favicon-svg',
      rel: 'icon',
    },
    {
      id: 'favicon-32',
      href: 'icon/admin/favicon-32',
      rel: 'icon',
    },
    {
      id: 'favicon-16',
      href: 'icon/admin/favicon-16',
      rel: 'icon',
    },
    {
      id: 'mask-icon',
      href: 'icon/admin/mask-icon',
      rel: 'mask-icon',
    },
    {
      id: 'shortcut-icon',
      href: 'icon/admin/favicon',
      rel: 'shortcut icon',
    },
  ];

  export const Auth:LinkResource[] = [
    {
      id: 'custom-css',
      href: 'css/custom.css',
      rel: 'stylesheet',
    },
    {
      id: 'apple-touch-icon',
      href: 'icon/manage/apple-touch-icon',
      rel: 'apple-touch-icon',
    },
    {
      id: 'favicon-svg',
      href: 'icon/manage/favicon-svg',
      rel: 'icon',
    },
    {
      id: 'favicon-32',
      href: 'icon/manage/favicon-32',
      rel: 'icon',
    },
    {
      id: 'favicon-16',
      href: 'icon/manage/favicon-16',
      rel: 'icon',
    },
    {
      id: 'mask-icon',
      href: 'icon/manage/mask-icon',
      rel: 'mask-icon',
    },
    {
      id: 'shortcut-icon',
      href: 'icon/manage/favicon',
      rel: 'shortcut icon',
    },
  ];

  export const Manage:LinkResource[] = [
    {
      id: 'custom-css',
      href: 'css/custom.css',
      rel: 'stylesheet',
    },
    {
      id: 'apple-touch-icon',
      href: 'icon/manage/apple-touch-icon',
      rel: 'apple-touch-icon',
    },
    {
      id: 'favicon-svg',
      href: 'icon/manage/favicon-svg',
      rel: 'icon',
    },
    {
      id: 'favicon-32',
      href: 'icon/manage/favicon-32',
      rel: 'icon',
    },
    {
      id: 'favicon-16',
      href: 'icon/manage/favicon-16',
      rel: 'icon',
    },
    {
      id: 'mask-icon',
      href: 'icon/manage/mask-icon',
      rel: 'mask-icon',
    },
    {
      id: 'shortcut-icon',
      href: 'icon/manage/favicon',
      rel: 'shortcut icon',
    },
  ];

}
