import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { testid } from '../../util';
import { FormatDate, FormatUnit } from '../format';
import { ListPaperColumn } from '../list-item';
import { ServiceHistoryDialog } from './service-history-dialog';
import { ServiceHistoryExtended } from './service-history.types';

interface Props {
  item: ServiceHistoryExtended;
  handleClose: () => void;
  open: boolean;
}

export const ServiceHistoryDetailsDialog: FC<Props> = ({ item, handleClose, open }) => {
  const { t } = useTranslation(['common']);
  return (
    <ServiceHistoryDialog
      data-testid="view-service-details-dialog"
      title={t('common:page.thing-detail.service-history.service-history-title.labels')}
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      open={open}
      hasCloseBtn={true}
    >
      <Stack spacing={2} direction="column" sx={{ mb: 1 }}>
        <ListPaperColumn label={t('common:page.thing-detail.service-history.service-type.labels')}>{item.serviceTypeDisplay}</ListPaperColumn>
        <ListPaperColumn label={t('common:page.thing-detail.service-history.date.labels')}><FormatDate value={item.occurred} /></ListPaperColumn>
        {item.thingServiceMetrics.map((metric, i) => (
          metric.value !== 0 &&
          <ListPaperColumn key={i} wrap={true} data-testid={testid`service-details-metric-${metric.serviceMetricId}`} label={metric.display} sx={{ overflow: 'hidden', '& > label': { textTransform: 'capitalize' } }}>
            <FormatUnit value={metric.value} unit={metric.unit} />
          </ListPaperColumn>
        ))}
        {item.details &&
          <ListPaperColumn label={t('common:page.thing-detail.service-history-dialog.service-details.label')} wrap>
            <Typography>{item.details}</Typography>
          </ListPaperColumn>
        }
      </Stack>
    </ServiceHistoryDialog>
  );
};
