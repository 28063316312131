import axios from 'axios';

export const downloadFile = (url: string | URL, filename: string, onError?: () => void, callBack?: () => void): void => {
  const downloadUrl = typeof url === 'string' ? url : url.href;
  axios.get<Blob>(downloadUrl, { responseType: 'blob' })
    .then((blobResult) => {
      const blobUrl = window.URL.createObjectURL(blobResult.data);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', filename);
      link.click();
      window.URL.revokeObjectURL(blobUrl);
      callBack && callBack();
    })
    .catch(onError);
};
