import { Paper } from '@mui/material';
import { DomEvent } from 'leaflet';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useCustomRoutes } from '../../hooks';
import { MapButton } from './map-button';

export const ViewMapButton: FC<{ entityId: string }> = ({ entityId }): JSX.Element => {
  const ref = useRef<HTMLAnchorElement>(null);
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const { things } = useCustomRoutes();
  useEffect(() => {
    if (!ref.current) return;

    DomEvent.disableClickPropagation(ref.current);
  }, [ref]);

  return (
    <Paper
      ref={ref}
      component={Link}
      elevation={3}
      state={{
        previousLocations: location,
        shouldSetView: true,
      }}
      sx={{
        bottom: 0,
        left: 0,
        m: 1.5,
        position: 'absolute',
        textDecoration: 'none',
        zIndex: 502,
      }}
      to={`/map/${things}/${entityId}`}

    >
      <MapButton sx={{ px: '0.5rem', py: '0.25rem' }}>
        {t('common:component.map.labels.view-full')}
      </MapButton>
    </Paper>
  );
};
