/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from '@mui/system';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TileLayer, useMap } from 'react-leaflet';
import { HERE_MAP_API_KEY } from '../../../constants';
import { useConfig } from '../../../hooks/use-config';
import { addMapLangParams } from './utils';

const NoGapTileLayer = styled(TileLayer)(() => ({
  '.leaflet-tile-container img': {
    height: '256.5px !important',
    width: '256.5px !important',
  },
}));

const SatelliteLayer: FC = () => {
  const { t, i18n } = useTranslation(['common']);
  const config = useConfig();
  const copyrightString = t('common:component.map.labels.copyright-satellite-map', { year: new Date().getFullYear() });
  const map = useMap();
  const userLang = i18n.language;

  useEffect(() => {
    map.attributionControl.addAttribution(copyrightString);
  }, []);

  return (
    <NoGapTileLayer url={addMapLangParams(`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=explore.satellite.day&size=256&apiKey=${config.hereMaps?.apiKey ?? HERE_MAP_API_KEY}`, userLang, false)} />
  );
};

export default SatelliteLayer;
