import { FC, ReactNode } from 'react';

export interface FormatTextProps {
  value: string;
  renderValue?: (value: string) => ReactNode;
}

export const FormatText: FC<FormatTextProps> = ({ value, renderValue }) => {
  return <>{renderValue ? renderValue(value) : value}</>;
};

export default FormatText;
