import { FC } from 'react';
import { Tooltip } from 'react-leaflet';
import { makeStyles } from '../../../../theme';
import { Display } from '../../../../types';

const useStyles = makeStyles()((theme) => ({
  tooltip: {
    backgroundColor: `${theme.palette.common.black} !important`,
    border: 'none',
    borderRadius: '15px',
    color: theme.palette.common.white,
    paddingLeft: '12px',
    paddingRight: '12px',
    zIndex: 700,
    '&:before': { display: 'none' },
  },
}));

export const PointOfInterestTooltip: FC<Display> = ({ display }) => {
  const { classes } = useStyles();

  return (
    <Tooltip className={classes.tooltip} direction="right" sticky>
      {display}
    </Tooltip>
  );
};
