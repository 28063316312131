/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, PaginationItem } from '@mui/material';
import { FC, useMemo } from 'react';
import { useSmallScreen } from '../../hooks';
import { testid } from '../../util';
import { DialogListLayout } from './index';
import { Props } from './types';

export const DialogListView: FC<Props> = (props): JSX.Element => {
  const smallScreen = useSmallScreen();

  const {
    isLoading,
    limit,
    listContent,
    matchCount,
    pagination,
    search,
    setPagination,
  } = props;

  const paginationComponent = useMemo(
    () => matchCount > limit
      ? <Pagination
        count={Math.ceil(matchCount / limit)}
        disabled={isLoading}
        onChange={(_, page) => setPagination({ limit, skip: limit * (page - 1) })}
        renderItem={(item) => (
          <PaginationItem data-testid={testid`pagination-item-${item.type}${item.type === 'page' ? `-${item.page ?? ''}` : ''}`}
            {...item}
          />
        )}
        page={Math.floor(pagination.skip / limit) + 1}
        showFirstButton={!smallScreen}
        showLastButton={!smallScreen}
        size={smallScreen ? 'small' : 'large'}
        sx={{ marginBottom: '-10px' }}
      />
      : undefined,
    [isLoading, matchCount, pagination, smallScreen],
  );

  return (
    <DialogListLayout
      listContent={listContent}
      paginationComponent={paginationComponent}
      search={search}
    />
  );
};
