import { Fab } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, ReactNode, useRef } from 'react';
import { makeStyles } from '../../theme';
import { disableClickPropagation } from '../../util';

export interface FloatingButtonProps {
  children?: ReactNode;
  'data-testid'?: string;
  disabled?: boolean;
  onClick?: () => void;
  shape?: 'extended' | 'circular';
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps;
  variant?: 'primary' | 'contrast';
}

const useStyles = makeStyles()((theme) => ({
  disabled: {
    '&.Mui-disabled': {
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[6],
      opacity: 1,
    },
  },
}));

export const FloatingButton: FC<FloatingButtonProps> = ({
  children,
  disabled = false,
  onClick,
  shape = 'extended',
  size,
  sx,
  variant = 'primary',
  ...props
}) => {
  const { classes } = useStyles();
  const ref = useRef<HTMLButtonElement>(null);
  const isPrimary = variant === 'primary';
  const isContrast = variant === 'contrast';

  disableClickPropagation(ref);

  return (
    <Fab
      ref={ref}
      className={classes.disabled}
      data-testid={props['data-testid']}
      disabled={disabled}
      onClick={onClick}
      size={size}
      sx={{
        ...sx,
        backgroundColor: (theme) => isPrimary
          ? theme.palette.primary.main
          : isContrast
            ? theme.palette.background.paper
            : 'inherit',
        color: (theme) => isPrimary
          ? theme.palette.primary.contrastText
          : isContrast
            ? theme.palette.primary.main
            : 'inherit',
      }}
      variant={shape}
    >
      {children}
    </Fab>
  );
};
