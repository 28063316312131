import { Person, PersonType, SharedThingType, Thing, ThingType } from '@eagle/core-data-types';
import { Chip, Stack } from '@mui/material';
import { FC, Fragment, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '../label';
import { LinkButton } from './actions';
import { DataRow } from './data-row';
import { PropertiesRows } from './properties-rows';
import { LinkData } from './static-data.types';

interface Props<T, U, V> {
  data: T;
  dataType: U;
  'data-testid'?: string;
  entityLink?: LinkData;
  maxProperties?: number;
  maxSharedProperties?: number;
  sharedDataType?: V;
}

export const StaticData = <T extends Thing | Person, U extends ThingType | PersonType, V extends (SharedThingType | undefined) = undefined>(props: PropsWithChildren<Props<T, U, V>>): JSX.Element => (
  <Stack data-testid={props['data-testid']} spacing={2}>
    <DataRow data-testid='title-display' action={<LinkButton link={props.entityLink} />} label={props.dataType.display} value={<>{props.data.display}</>} />
    {'sharedProperties' in props.data && props.sharedDataType && (
      <PropertiesRows properties={props.data.sharedProperties} propertyDefinitions={props.sharedDataType.properties} maxProperties={props.maxSharedProperties} />
    )}
    <PropertiesRows properties={props.data.properties} propertyDefinitions={props.dataType.properties} maxProperties={props.maxProperties} />
    {props.data.tags.length > 0 && (props.data.tags[0] && <Tags tags={props.data.tags} />)}
    {Object.keys(props.data.labels).length > 0 && <Labels labels={props.data.labels} />}
  </Stack>
);

interface TagsProps {
  tags: string[];
}

const Tags: FC<TagsProps> = ({ tags }) => {
  const { t } = useTranslation(['common']);
  return (
    <DataRow label={t('common:terms.tag')} value={<>{tags.map((tag, i) => {
      return (
        <Fragment key={i}>
          {tag && <Chip label={tag} size="small" sx={{ marginRight: '4px' }} />}
        </Fragment>
      );
    })}</>} />
  );
};

interface LabelsProps {
  [key: string]: Record<string, string>;
}

const Labels: FC<LabelsProps> = ({ labels }) => {
  const { t } = useTranslation(['common']);
  return (
    <DataRow label={t('common:terms.label')} value={<Stack spacing={0.5}>
      {Object.keys(labels).map((key, ind) => {
        return <Label key={ind} label={key} value={labels[key]} size="small" />;
      })}
    </Stack>} />
  );
};
