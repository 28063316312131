/* eslint-disable react-hooks/rules-of-hooks */
import * as turf from '@turf/turf';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import Supercluster from 'supercluster';
import useSupercluster from 'use-supercluster';
import { BOUNDS_OF_EARTH, MAP_MAX_ZOOM } from '../../../../constants';
import { Undefinable } from '../../../../types';
import { ThingEventProperties } from '../../thing-event-pane/thing-event-pane.types';

export type Cluster = Supercluster.PointFeature<ThingEventProperties> | Supercluster.PointFeature<Supercluster.ClusterProperties & Supercluster.AnyProps>;

interface ReturnType {
  clusters: Cluster[];
  supercluster: Undefinable<Supercluster<ThingEventProperties | Supercluster.ClusterProperties, Supercluster.AnyProps>>;
}

const SEGMENT_DISTANCE = 0.1;
const CLUSTER_BUFFER_RANGE = 0.0001;

export const createOffsetLine = (lineString: turf.Feature<turf.LineString>, offset: number): turf.Feature<turf.LineString> => {
  const lineCoords = lineString.geometry.coordinates;

  const offsetLines: turf.Position[][] = [];
  lineCoords.forEach((_, i) => {
    if (i === lineCoords.length - 1) return;
    const angle = turf.bearing(lineCoords[i], lineCoords[i + 1]);
    const section = turf.lineString([[lineCoords[i][1], lineCoords[i][0]], [lineCoords[i + 1][1], lineCoords[i + 1][0]]]);
    const offsetSection = turf.transformTranslate(
      section,
      offset,
      angle,
      { units: 'meters' },
    );
    const scaledSection = (i !== 0 && i !== lineCoords.length - 2) ? turf.transformScale(offsetSection, 0.8) : offsetSection;
    offsetLines.push(scaledSection.geometry.coordinates);
  });
  return turf.lineString(offsetLines.flat().map((item) => [item[1], item[0]]));
};

export const lineTotalDistance = (coordinates: turf.Position[]): number => {
  return coordinates.reduce((previous, _current, i) => {
    if (i === coordinates.length - 1) return previous;
    const distance = turf.distance(coordinates[i], coordinates[i + 1]);
    return previous + distance;
  }, 0);
};

export const getClusters = (
  nearestPoints: turf.Feature<turf.Point, ThingEventProperties>[],
  clusterRadius = 120,
): ReturnType => {
  const map = useMap();
  const bounds: turf.BBox = [
    BOUNDS_OF_EARTH.getWest(),
    BOUNDS_OF_EARTH.getSouth(),
    BOUNDS_OF_EARTH.getEast(),
    BOUNDS_OF_EARTH.getNorth(),
  ];
  const { clusters, supercluster } = useSupercluster({
    bounds,
    options: { radius: clusterRadius, maxZoom: MAP_MAX_ZOOM },
    points: nearestPoints,
    zoom: map.getZoom(),
  });
  return { clusters, supercluster };
};

export const getClosestPoint = (clusters: Cluster[], latlng: L.LatLng): Undefinable<Cluster> => {
  if (!clusters?.length) return;
  return clusters.reduce((previousCluster, currentCluster) => {
    const currentPoint = new L.LatLng(currentCluster.geometry.coordinates[1], currentCluster.geometry.coordinates[0]);
    const previousPoint = new L.LatLng(previousCluster.geometry.coordinates[1], previousCluster.geometry.coordinates[0]);

    const currentDistance = latlng.distanceTo(currentPoint);
    const previousDistance = latlng.distanceTo(previousPoint);

    if (currentDistance < previousDistance) return currentCluster;
    return previousCluster;
  });
};

const calculateClusterIntersect = (
  travelDistance: number,
  totalDistance: number,
  data: turf.Feature<turf.LineString, turf.Properties>,
  cluster: Cluster,
  index: number,
): turf.FeatureCollection<turf.Point, turf.Properties> => {
  const lowerBound = Math.max(0, travelDistance - SEGMENT_DISTANCE * index);
  const upperBound = Math.min(totalDistance, travelDistance + SEGMENT_DISTANCE * index);
  let sliced;
  if (lowerBound === upperBound) {
    const point = turf.along(data, lowerBound);
    sliced = turf.lineString([point.geometry.coordinates, point.geometry.coordinates]);
  } else {
    sliced = turf.lineSliceAlong(data, lowerBound, upperBound);
  }
  const [clusterLng, clusterLat] = cluster.geometry.coordinates;
  const bufferPolygon = turf.polygon([[
    [clusterLng + CLUSTER_BUFFER_RANGE, clusterLat + CLUSTER_BUFFER_RANGE],
    [clusterLng - CLUSTER_BUFFER_RANGE, clusterLat + CLUSTER_BUFFER_RANGE],
    [clusterLng - CLUSTER_BUFFER_RANGE, clusterLat - CLUSTER_BUFFER_RANGE],
    [clusterLng + CLUSTER_BUFFER_RANGE, clusterLat - CLUSTER_BUFFER_RANGE],
    [clusterLng + CLUSTER_BUFFER_RANGE, clusterLat + CLUSTER_BUFFER_RANGE],
  ]]);
  const clusterIntersect = turf.lineIntersect(sliced, bufferPolygon);
  return clusterIntersect;
};

export const findClosestCluster = (
  line: turf.Feature<turf.LineString, turf.Properties>,
  mouseLatLng: L.LatLng,
  clusters: Cluster[],
): Cluster | undefined => {
  const flippedData = turf.flip(line);
  const mousePoint = turf.point([mouseLatLng.lng, mouseLatLng.lat]);
  const travelLine = turf.lineSlice(flippedData.geometry.coordinates[0], mousePoint, flippedData);
  const travelDistance = lineTotalDistance(travelLine.geometry.coordinates);
  const totalDistance = lineTotalDistance(flippedData.geometry.coordinates);

  const normalizedDist = travelDistance > (totalDistance / 2) ? (totalDistance - travelDistance) : travelDistance;
  const iterations = Math.ceil(normalizedDist / SEGMENT_DISTANCE);

  for (let i = 0; i <= iterations; i++) {
    const closestCluster = clusters.reduce((accumulator: { cluster: Cluster; distance: number } | undefined, cluster) => {
      const clusterIntersect = calculateClusterIntersect(travelDistance, totalDistance, flippedData, cluster, i);
      if (clusterIntersect.features.length) {
        const clusterDistance = lineTotalDistance(turf.lineSlice(mousePoint, clusterIntersect.features[0], flippedData).geometry.coordinates);
        if (!accumulator || accumulator?.distance > clusterDistance) {
          return {
            cluster,
            distance: clusterDistance,
          };
        }
      }
      return accumulator;
    }, undefined);
    if (closestCluster) return closestCluster.cluster;
  }
};

export const coordinatesToLatLng = (coordinate: turf.Position): L.LatLng => {
  return new L.LatLng(coordinate[1], coordinate[0]);
};

export const closestPointOutsideDistance = (
  points: turf.Feature<turf.Point, ThingEventProperties>[],
  distanceThreshold: number,
): turf.Feature<turf.Point, ThingEventProperties> | undefined => {
  if (!points || points.length === 0) {
    return undefined;
  }

  const initialPoint = points[0];
  const pointsOutsideThreshold = points.filter((point) =>
    turf.distance(initialPoint, point, { units: 'kilometers' }) > distanceThreshold,
  );

  if (pointsOutsideThreshold.length === 0) {
    return undefined; // Handle case where no points are outside the threshold
  }

  // Find the closest point outside the threshold
  return pointsOutsideThreshold.reduce((closestPoint, currentPoint) => {
    const closestDistance = turf.distance(initialPoint, closestPoint, { units: 'kilometers' });
    const currentDistance = turf.distance(initialPoint, currentPoint, { units: 'kilometers' });
    return currentDistance < closestDistance ? currentPoint : closestPoint;
  }, pointsOutsideThreshold[0]); // Use the first point outside the threshold as the initial value
};

export const isAnyNaN = (values: number[]): boolean => {
  return values.some((value) => Number.isNaN(value));
};
