import { VideoSegmentStatus } from '@eagle/data-function-types';
import { Chip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import chroma from 'chroma-js';
import { FC, ReactNode } from 'react';
import { VideoCellIcon } from './video-cell-icon';

interface Props {
  fontSize?: string;
  label: ReactNode | string;
  sx?: SxProps;
  videoCellStatus: VideoSegmentStatus;
}

export const VideoCellChip: FC<Props> = ({ fontSize, label, sx, videoCellStatus }) => {
  const theme = useTheme();

  return <Chip
    icon={<VideoCellIcon color={chroma(theme.palette.common.black).alpha(0.54).css()} videoSegmentStatus={videoCellStatus} />}
    label={<Typography variant="caption" sx={{ fontSize: fontSize ?? '0.6rem' }} >{label}</Typography>}
    size="small"
    sx={{
      backgroundColor: '#e0e0e0',
      color: chroma(theme.palette.common.black).alpha(0.87).css(),
      paddingLeft: theme.spacing(0.5),
      ...sx,
    }}
  />;
};
