import { useTheme } from '@mui/material';
import { FC, useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import type { Engine, IOptions, RecursivePartial } from 'tsparticles-engine';
import { makeStyles } from '../../theme';

const useStyles = makeStyles()(() => ({
  particlesContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
}));

export const ParticleAnimation: FC = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  /* eslint camelcase: ["error", {"properties": "never", ignoreDestructuring: true}] */
  const options: RecursivePartial<IOptions> = {
    background: {
      color: {
        value: theme.landingPage?.background,
      },
    },
    fpsLimit: 80,
    particles: {
      number: { value: 80, density: { enable: true, value_area: 800 } },
      color: { value: theme.landingPage?.particleColor },
      shape: {
        type: 'circle',
        stroke: { width: 0, color: '#000000' },
        polygon: { nb_sides: 5 },
      },
      opacity: {
        value: 0.5,
        random: false,
        anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
      },
      size: {
        value: 3,
        random: true,
        anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: theme.landingPage?.particleColor,
        opacity: 0.4,
        width: 1,
      },
      move: {
        enable: true,
        speed: 2,
        direction: 'none',
        random: false,
        straight: false,
        out_mode: 'out',
        bounce: false,
        attract: { enable: false, rotateX: 600, rotateY: 1200 },
      },
    },
    interactivity: {
      detect_on: 'canvas',
      events: {
        onhover: { enable: false, mode: 'repulse' },
        onclick: { enable: false, mode: 'push' },
        resize: true,
      },
      modes: {
        grab: { distance: 400, line_linked: { opacity: 1 } },
        bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
        repulse: { distance: 200, duration: 0.4 },
        push: { particles_nb: 4 },
        remove: { particles_nb: 2 },
      },
    },
    retina_detect: true,
  };

  return (
    <Particles
      data-chromatic="ignore"
      id="tsparticles"
      init={particlesInit}
      options={options}
      className={classes.particlesContainer}
      canvasClassName="chromatic-ignore"
    />
  );
};

export default ParticleAnimation;
