/* eslint-disable react-hooks/exhaustive-deps */
import * as turf from '@turf/turf';
import { Feature, LineString, Position } from '@turf/turf';
import chroma from 'chroma-js';
import L from 'leaflet';
import React, { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSubscribeToMapDimensions } from '../../../../util';

interface AntPolylineProps {
  map: L.Map;
  color: string;
  data: Feature<turf.helpers.LineString>;
  paused: boolean;
  isEnabled: boolean;
}

export const AntPolyline: React.FC<AntPolylineProps> = ({
  map,
  color,
  data,
  paused,
  isEnabled,
}) => {
  const lighterColor = chroma(color).alpha(0.3).css();
  const animationSpeed = 1;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationFrameRef = useRef<number | null>(null);
  const lineDashOffset = useRef(0);
  const ctx = useRef<CanvasRenderingContext2D | null>(null);
  const [isResizing, setIsResizing] = React.useState(false);
  const { zoomLevel } = useSubscribeToMapDimensions();

  // Function to remove duplicate coordinates from an array of coordinates
  const removeDuplicateCoordinates = (coordinates: Position[]): Position[] => {
    return coordinates.filter((coord, index, array) => {
      return index === 0 || !coord.every((val, i) => val === array[index - 1][i]);
    });
  };

  // Function to update the data by removing duplicate coordinates
  const updateData = (data: Feature<LineString>): Feature<LineString> => {
    const updatedCoordinates = removeDuplicateCoordinates(data.geometry.coordinates);

    const updatedData: Feature<LineString> = {
      ...data,
      geometry: {
        ...data.geometry,
        coordinates: updatedCoordinates,
      },
    };

    return updatedData;
  };

  const tolerance = zoomLevel < 10 ? 0.01 : zoomLevel < 15 ? 0.0002 : 0.00002;
  const simplifiedData = useMemo(() => {
    const updatedData = updateData(data);
    if (updatedData.geometry.coordinates.length < 2) {
      return updatedData;
    }
    return turf.simplify(updatedData, { tolerance, highQuality: true });
  }, [data, tolerance]);

  const drawLine = (
    ctx: CanvasRenderingContext2D,
    strokeStyle: string,
    lineWidth: number,
    lineDash: number[] = [],
    dashOffset = 0,
  ): void => {
    if (!ctx) return;

    try {
      const visibleData = simplifiedData.geometry.coordinates.filter((coord, index, arr) => {
        if (index === 0 || index === arr.length - 1) {
          return true;
        }
        const prevCoord = arr[index - 1];
        const nextCoord = arr[index + 1];
        const currentLatLng = L.latLng(coord[1], coord[0]);
        const prevLatLng = L.latLng(prevCoord[1], prevCoord[0]);
        const nextLatLng = L.latLng(nextCoord[1], nextCoord[0]);
        return (
          currentLatLng.distanceTo(prevLatLng) > 0.01 || currentLatLng.distanceTo(nextLatLng) > 0.01
        );
      }) as L.LatLngTuple[];

      ctx.beginPath();
      ctx.strokeStyle = strokeStyle;
      ctx.lineWidth = lineWidth;
      ctx.setLineDash(lineDash);
      ctx.lineDashOffset = dashOffset;

      visibleData.forEach((coord, index) => {
        const point = map.latLngToContainerPoint(coord);
        if (index === 0) {
          ctx.moveTo(point.x, point.y);
        } else {
          ctx.lineTo(point.x, point.y);
        }
      });

      ctx.stroke();
    } catch (error) {
      console.error('Error in drawLine:', error);
      // Handle the error gracefully, e.g., show an error message or fallback to a default rendering
    }
  };

  useEffect(() => {
    if (!map || !canvasRef.current || !isEnabled) return;
    const canvas = canvasRef.current;
    ctx.current = canvas.getContext('2d');
    if (!ctx.current) return;

    const adjustCanvas = (): void => {
      const { x, y } = map.getSize();
      canvas.width = x;
      canvas.height = y;

      const topLeft = map.containerPointToLayerPoint([0, 0]);
      L.DomUtil.setPosition(canvas, topLeft);

      if (ctx.current) {
        ctx.current.clearRect(0, 0, canvas.width, canvas.height);
        drawLine(ctx.current, lighterColor, 5);
        drawLine(ctx.current, color, 5, [10, 50], -lineDashOffset.current);
      }
    };

    const onZoomEnd = (): void => {
      setIsResizing(true);
      adjustCanvas();
    };

    adjustCanvas();

    map.on('move', adjustCanvas);
    map.on('zoomend', onZoomEnd);

    return (): void => {
      map.off('move', adjustCanvas);
      map.off('zoomend', onZoomEnd);
    };
  }, [map, color, simplifiedData, paused, isEnabled]);

  useEffect(() => {
    if (!map || !canvasRef.current || !isEnabled) return;
    if (isResizing) return;

    const canvas = canvasRef.current;
    ctx.current = canvas.getContext('2d');
    if (!ctx.current) return;

    const { x, y } = map.getSize();
    canvas.width = x;
    canvas.height = y;

    const topLeft = map.containerPointToLayerPoint([0, 0]);
    L.DomUtil.setPosition(canvas, topLeft);

    ctx.current.clearRect(0, 0, canvas.width, canvas.height);
    drawLine(ctx.current, lighterColor, 5);
    drawLine(ctx.current, color, 5, [10, 50], -lineDashOffset.current);
  }, [map, color, simplifiedData, isEnabled]);

  useEffect(() => {
    if (!isEnabled || paused) {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    } else {
      const animate = (): void => {
        lineDashOffset.current += animationSpeed;
        if (ctx.current && canvasRef.current) {
          ctx.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
          drawLine(ctx.current, lighterColor, 5);
          drawLine(ctx.current, color, 5, [10, 50], -lineDashOffset.current);
        }
        animationFrameRef.current = requestAnimationFrame(animate);
      };
      animationFrameRef.current = requestAnimationFrame(animate);
    }

    return (): void => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isEnabled, paused, color, simplifiedData, animationSpeed]);

  const overlayPane = map.getPane('overlayPane');

  const body = <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 200, pointerEvents: 'none' }} />;

  return overlayPane ? createPortal(body, overlayPane) : body;
};
