import { Id } from '@eagle/api-types';
import { AxiosInstance } from 'axios';
import { PropsWithChildren } from 'react';
import { AuthenticatedState } from '../auth';
import { Undefinable } from '../types';

export interface Cache {
  one<V extends Id<unknown>>(key: unknown): Promise<Undefinable<V>>;
}

export abstract class BaseCache<S = Undefinable<Id<unknown>>> implements Cache {
  protected data: Undefinable<Promise<Map<string, S>>>;

  public abstract one<V extends Id<unknown>>(key: unknown): Promise<Undefinable<V>>;
}

export type IdSerializer = (id: any) => string;

export interface FetchAllCacheDefinition<V> {
  fetchAll: (axios: AxiosInstance) => Promise<V[]>;
  idSerializer: IdSerializer;
}

export interface FetchOneCacheDefinition<V> {
  fetchOne: (axios: AxiosInstance, id: string) => Promise<Undefinable<V>>;
  idSerializer: IdSerializer;
  initializer: () => Map<string, Promise<Undefinable<V>>>;
}

export interface CacheProviderProps extends PropsWithChildren {
  authState: AuthenticatedState;
  fetchAllCaches: Record<string, FetchAllCacheDefinition<any>>;
  fetchOneCaches: Record<string, FetchOneCacheDefinition<any>>;
}

export type Caches<C extends Cache> = Record<string, C>;

export type CacheFactory<D, C> = (definition: D, authState: AuthenticatedState) => C
