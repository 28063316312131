import FilterIcon from '@mui/icons-material/FilterAltRounded';
import { IconButton, Theme, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '../badge';

interface Props {
  disabled?: boolean;
  filtersLength: number;
  onFilterClicked: () => void;
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps<Theme>;
}

export const FilterBar: FC<Props> = ({ disabled = false, filtersLength, onFilterClicked: onClickHandler, size, sx }) => {
  const { t } = useTranslation(['common']);

  return (
    <Badge badgeContent={filtersLength} invisible={!filtersLength} data-testid="filter-counter">
      <Tooltip arrow disableHoverListener={disabled} title={t('common:component.filter.heading')} >
        <span> {/* The wrap for a valid React element that can hold a ref to avoid a 'Failed prop type' warning message. */}
          <IconButton
            color='primary'
            data-testid="filters-button"
            disabled={disabled}
            onClick={onClickHandler}
            size={size}
            sx={{ ...sx, lineHeight: 'inherit', p: 1 }}
          >
            <FilterIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Badge>
  );
};
