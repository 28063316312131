import { LastThingEvent, Thing, ThingType } from '@eagle/core-data-types';
import { FC, useEffect, useState } from 'react';
import { evaluate } from '../../evaluator';
import { LastThingEventsByFeature, useLastThingState, useUiTemplate } from '../../hooks';
import { CacheDataTypes } from '../../types';
import { THING_INLAY_MAP } from '../../ui-templates';
import { FetchOneOfAll } from '../fetch';

interface ThingInlayMapProps {
  thing: Thing;
}

export const ThingInlayMap: FC<ThingInlayMapProps> = ({ thing }) => {
  const [fetchState] = useLastThingState();
  const [lastThingEvents, setLastThingEvents] = useState<LastThingEventsByFeature>();
  const [lastThingEvent, setLastThingEvent] = useState<LastThingEvent>();
  const { template } = useUiTemplate('inlay-map', THING_INLAY_MAP);

  useEffect(() => {
    if (!thing) return;

    setLastThingEvents(undefined);
    setLastThingEvent(undefined);

    fetchState(thing._id).then((events) => {
      setLastThingEvents(events?.byFeature);
      setLastThingEvent(events?.latest);
    }).catch(() => { });
  }, [fetchState, thing]);

  return <FetchOneOfAll
    id={thing.thingTypeId}
    dataType={CacheDataTypes.THING_TYPE}
    renderFactory={(thingType: ThingType) => {
      return <>{evaluate(template, { lastEvents: lastThingEvents, lastThingEvent, thingType, entityId: thing._id })}</>;
    }}
  />;
};
