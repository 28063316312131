import { MediaSegmentData, MediaType } from '@eagle/core-data-types';
import { Undefinable } from '../../types';
import { getFileExtension, safeFilename } from '../../util';
import { ImageItem } from './image-item';
import { VideoItem } from './video-item';

export const getMediaFileName = (display: string, item: MediaSegmentData): Undefinable<string> => {
  const itemDate = item.start;
  if (!itemDate) return;
  return safeFilename`${display}-${item.feature}-${itemDate}.${getFileExtension(item.url)}`;
};

export const renderItem = (
  handleItemClick: (currentIndex: number, feature: string) => void,
  index: number,
  item: MediaSegmentData,
  timeZone?: string,
  testId?: string,
): JSX.Element => {
  switch (item.mediaType) {
    case MediaType.JPEG: {
      return (
        <ImageItem
          key={index}
          currentImageIndex={index}
          handleImageClick={handleItemClick}
          image={item}
          timeZone={timeZone}
          data-testid={testId}
        />
      );
    }
    case MediaType.MP4: {
      return (
        <VideoItem
          key={index}
          currentVideoIndex={index}
          handleVideoClick={handleItemClick}
          timeZone={timeZone}
          video={item}
          data-testid={testId}
        />
      );
    }
    default: return <></>;
  }
};
