import { Stack } from '@mui/material';
import { FC } from 'react';

interface Props {
  addEntityDialog: JSX.Element;
  drawerTitle: JSX.Element;
  eventHistory: JSX.Element;
  historyActions: JSX.Element;
  isDrawerOpen: boolean;
  isDrawerOpenIcon: JSX.Element;
}

export const HistoryDrawerLayoutDesktop: FC<Props> = ({
  addEntityDialog,
  drawerTitle,
  eventHistory,
  historyActions,
  isDrawerOpen,
  isDrawerOpenIcon,
}): JSX.Element => {
  if (!isDrawerOpen) {
    return <>
      <Stack spacing={1} sx={{ pt: 1, width: '100%' }}>
        {isDrawerOpenIcon}
        {eventHistory}
      </Stack>
      {addEntityDialog}
    </>;
  }

  return (
    <Stack sx={{ pb: 3, pt: 1, px: 3 }}>
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 2, py: 2 }}>
        {drawerTitle}
        <Stack direction="row" spacing={2}>
          {historyActions}
        </Stack>
      </Stack>
      {eventHistory}
      {addEntityDialog}
    </Stack>
  );
};
