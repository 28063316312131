import { AccordionDetails as MUIAccordionDetails, AccordionDetailsProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren, AccordionDetailsProps {
  'data-testid'?: string;
}

export const AccordionDetails: FC<Props> = ({ children, ...props }) => {
  return <MUIAccordionDetails {...props}>{children}</MUIAccordionDetails>;
};
