/* eslint-disable react-hooks/exhaustive-deps */
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { StaticTimePicker, TabContext, TabList, TabPanel, TimePicker } from '@mui/lab';
import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput';
import { Box, Button, Container, Grid, IconButton, InputAdornment, Tab, TextField } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatShortDate, hourCycle, isMatchingDay } from '../../util';
import { DynamicTimePicker, MobileLayoutProps, TABS, TimeInputType } from './date-time-range.types';

export const DateTimeRangeMobile: FC<MobileLayoutProps> = ({
  classes,
  closeMobileTime,
  date,
  end,
  error,
  is12AM,
  handleBack,
  handleEnd,
  handleError,
  handleNext,
  handleStart,
  handleTab,
  maxTime,
  minTime,
  renderInput,
  start,
  tab,
  views,
}) => {
  const { t } = useTranslation(['common']);

  const renderInputWithButton = (params: MuiTextFieldProps, inputType: string): React.ReactElement => {
    const newDayText = (start && end && inputType === TimeInputType.end && !isMatchingDay(start, end))
      ? `(${formatShortDate(end)})`
      : '';

    return (
      <TextField
        {...params}
        data-testid="time-input-field"
        className={classes.mobileTimeTextField}
        error={error !== null}
        helperText={newDayText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <AccessTimeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const TabPanelMobile = ({ openTo, dateTime, handler, inputType }: DynamicTimePicker): JSX.Element => (
    <TabPanel value={openTo} style={{ padding: '0' }}>
      <Container className={classes.timePicker}>
        <StaticTimePicker
          ampm={hourCycle()}
          disableMaskedInput
          displayStaticWrapperAs="desktop"
          maxTime={(date && (!is12AM(maxTime) && (isMatchingDay(maxTime.minus({ days: 1 }), date) && inputType === TimeInputType.start)))
            ? maxTime.toJSDate()
            : undefined
          }
          minTime={date && (isMatchingDay(minTime, date) ? minTime?.toJSDate() : undefined)}
          onChange={handler}
          onError={handleError}
          openTo={views[0]}
          renderInput={(textProps) => renderInput(textProps, inputType)}
          value={dateTime?.toJSDate()}
          views={views}
        />
      </Container>
      <Grid container spacing={4}>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <TimePicker
            ampm={hourCycle()}
            disableOpenPicker
            maxTime={(date && (!is12AM(maxTime) && (isMatchingDay(maxTime.minus({ days: 1 }), date) && inputType === TimeInputType.start)))
              ? maxTime.toJSDate()
              : undefined
            }
            minTime={date && (isMatchingDay(minTime, date) ? minTime?.toJSDate() : undefined)}
            onChange={handler}
            onError={handleError}
            renderInput={(textProps) => renderInputWithButton(textProps, inputType)}
            value={dateTime?.toJSDate()}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </TabPanel>
  );

  const MobileTimeTabs = (): JSX.Element => (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Grid
        alignItems="center"
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        justifyContent="center"
        spacing={0}
      >
        <Grid item xs={12}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTab} aria-label={t('common:component.date-time-range.labels.select-time')} centered>
                <Tab disabled={error !== null} style={{ width: '50%' }} label={t('common:component.date-time-range.labels.label-from')} value="startDate" />
                <Tab disabled={error !== null} style={{ width: '50%' }} label={t('common:component.date-time-range.labels.label-to')} value="endDate" />
              </TabList>
            </Box>
            {TabPanelMobile({
              dateTime: start,
              handler: handleStart,
              inputType: TimeInputType.start,
              openTo: TABS.startDate,
            })}
            {TabPanelMobile({
              dateTime: end,
              handler: handleEnd,
              inputType: TimeInputType.end,
              openTo: TABS.endDate,
            })}
          </TabContext>
        </Grid>

        <Grid container item xs={12} sx={{ paddingTop: '0.5em' }}>
          <Grid item xs={3}>
            {tab === TABS.endDate
              ? <Button fullWidth onClick={handleBack} data-testid="button-back">
                {t('common:common.action.back')}
              </Button>
              : <Button fullWidth onClick={closeMobileTime} data-testid="button-cancel">
                {t('common:common.action.cancel')}
              </Button>
            }
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3}>
            <Button fullWidth disabled={error !== null} onClick={handleNext}>
              {t('common:common.action.next')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return useMemo(MobileTimeTabs, [
    closeMobileTime,
    end,
    handleEnd,
    handleError,
    handleNext,
    handleStart,
    maxTime,
    minTime,
    renderInput,
    start,
    tab,
  ]);
};
