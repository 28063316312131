import { ThingEventSnapshot } from '@eagle/core-data-types';
import * as turf from '@turf/turf';
import { Dispatch, FC, memo, SetStateAction } from 'react';
import { useMap } from 'react-leaflet';
import Supercluster from 'supercluster';
import { Maybe } from '../../../../types';
import { EventLocationData, ThingEventProperties } from '../../thing-event-pane/thing-event-pane.types';
import { START_END_CLUSTER_TOLERANCE } from './clustering.utils';
import FlowerCluster from './flower-cluster';
import { coordinatesToLatLng } from './util';

interface Props {
  calculatedHeading: number;
  cluster: Supercluster.PointFeature<ThingEventProperties & Supercluster.AnyProps>;
  eventsLength: number;
  handleBreadcrumbClick: (index: number, items: ThingEventSnapshot[]) => void;
  hoveredEventId: Maybe<string>;
  setHoveredEventId: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
  supercluster?: Supercluster<ThingEventProperties & Supercluster.AnyProps, Supercluster.AnyProps>;
}

interface ClusterPoint {
  eventData: EventLocationData;
  iconColor: string;
  index: number;
}

const formatCluster = ({ properties: { eventData, iconColor, index } }: Supercluster.PointFeature<ThingEventProperties & Supercluster.AnyProps>): ClusterPoint => ({
  eventData,
  iconColor,
  index,
});

const MarkerClusterItem: FC<Props> = ({
  calculatedHeading,
  cluster,
  eventsLength,
  handleBreadcrumbClick,
  hoveredEventId,
  setHoveredEventId,
  setSelectedEvent,
  supercluster,
}) => {
  const map = useMap();
  const { properties } = cluster;
  const eventsLengthActual = eventsLength - 1;

  if (properties.cluster && properties.cluster_id && supercluster) {
    const clusterMarkers = supercluster.getLeaves(properties.cluster_id as number, Infinity);
    const clusterCoords = clusterMarkers
      .map((clusterItem) => turf.point(clusterItem.geometry.coordinates, { index: clusterItem.properties.index }));
    const clusterEnvelope = turf.envelope(turf.featureCollection(clusterCoords));
    const clusterCentre = turf.centroid(clusterEnvelope);
    const clusterMaxDistance = turf.distance(
      turf.point(clusterEnvelope.geometry.coordinates[0][0]),
      turf.point(clusterEnvelope.geometry.coordinates[0][2]),
    );
    const startCheck = clusterMarkers.find(({ properties: { index } }) => index === 0);
    const endCheck = clusterMarkers.find(({ properties: { index } }) => index === eventsLengthActual);

    const groupedPositions = clusterMarkers.flatMap((clusterMarker) => formatCluster(clusterMarker));

    let clusterMinDistance = clusterMaxDistance;
    let distanceCheck = true;

    const closestPoint: turf.Feature<turf.Point, turf.Properties> = clusterCoords.reduce((
      previous: turf.Feature<turf.Point, { index: number }>,
      current,
    ) => {
      const clusterDistance = turf.distance(clusterCentre, current);
      if (!distanceCheck) return previous;
      if (current.properties.index === 0 || current.properties.index === (eventsLengthActual)) {
        distanceCheck = false;
        return current;
      }
      if (clusterDistance < clusterMinDistance) {
        clusterMinDistance = clusterDistance;
        return current;
      }
      return previous;
    }, clusterCoords[0]);

    const checkStartEnd = (): boolean => {
      if (!startCheck || !endCheck) return false;
      const startPoint = map.latLngToContainerPoint(coordinatesToLatLng(startCheck.geometry.coordinates));
      const endPoint = map.latLngToContainerPoint(coordinatesToLatLng(endCheck.geometry.coordinates));
      return startPoint.distanceTo([endPoint.x, endPoint.y]) < START_END_CLUSTER_TOLERANCE;
    };

    const startEndClose = checkStartEnd();

    const filterPositions = startCheck && endCheck && !startEndClose;

    return <>
      <FlowerCluster
        calculatedHeading={calculatedHeading}
        center={coordinatesToLatLng(closestPoint.geometry.coordinates)}
        events={filterPositions
          ? groupedPositions.filter((item) => item.index !== eventsLength - 1)
          : groupedPositions
        }
        eventsLength={eventsLength}
        hoveredEventId={hoveredEventId}
        onClick={handleBreadcrumbClick}
        setHoveredEventId={setHoveredEventId}
        setSelectedEvent={setSelectedEvent}
      />
      {filterPositions
        && <FlowerCluster
          calculatedHeading={calculatedHeading}
          center={coordinatesToLatLng(endCheck.geometry.coordinates)}
          events={[formatCluster(endCheck)]}
          eventsLength={eventsLength}
          hoveredEventId={hoveredEventId}
          onClick={handleBreadcrumbClick}
          setHoveredEventId={setHoveredEventId}
          setSelectedEvent={setSelectedEvent}
        />
      }
    </>;
  }

  return (
    <FlowerCluster
      calculatedHeading={calculatedHeading}
      center={coordinatesToLatLng(cluster.geometry.coordinates)}
      events={[formatCluster(cluster)]}
      eventsLength={eventsLength}
      hoveredEventId={hoveredEventId}
      onClick={handleBreadcrumbClick}
      setHoveredEventId={setHoveredEventId}
      setSelectedEvent={setSelectedEvent}
    />
  );
};

export default memo(MarkerClusterItem);
