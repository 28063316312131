import { MediaSegmentData } from '@eagle/core-data-types';
import { FC } from 'react';
import { useSmallScreen } from '../../hooks';
import { MediaCardLayoutDesktop } from './media-card-layout-desktop';
import { MediaCardLayoutMobile } from './media-card-layout-mobile';
import { MediaBundler, MediaTabs } from './media-data.types';

interface Props {
  'data-testid'?: string;
  displayOnCard: boolean;
  mediaBundlers: MediaBundler[];
  renderMediaOverlay: (mediaItems: MediaSegmentData[], tab: MediaTabs) => JSX.Element;
  renderTabPanels: (bundlers: MediaBundler[]) => JSX.Element;
  renderTabs: (bundlers: MediaBundler[]) => JSX.Element;
  renderViewButton: () => JSX.Element;
  selectedTab: MediaTabs;
}

export const MediaCardView: FC<Props> = ({
  displayOnCard,
  mediaBundlers,
  renderMediaOverlay,
  renderTabPanels,
  renderTabs,
  renderViewButton,
  selectedTab,
  ...props
}) => {
  const smallScreen = useSmallScreen();
  const [imageData, videoData] = mediaBundlers;
  const mediaItems = selectedTab === MediaTabs.IMAGES ? imageData.items : videoData.items;

  if (smallScreen) {
    return (
      <MediaCardLayoutMobile
        displayOnCard={displayOnCard}
        mediaOverlay={renderMediaOverlay(mediaItems, selectedTab)}
        selectedTab={selectedTab}
        tabPanels={renderTabPanels(mediaBundlers)}
        tabs={renderTabs(mediaBundlers)}
        viewButton={renderViewButton()}
        data-testid={props['data-testid']}
      />
    );
  }

  return (
    <MediaCardLayoutDesktop
      displayOnCard={displayOnCard}
      mediaOverlay={renderMediaOverlay(mediaItems, selectedTab)}
      selectedTab={selectedTab}
      tabPanels={renderTabPanels(mediaBundlers)}
      tabs={renderTabs(mediaBundlers)}
      viewButton={renderViewButton()}
      data-testid={props['data-testid']}
    />
  );
};
