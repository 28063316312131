import { i18n } from '../providers/i18n';

interface TranslationResult {
  label?: string;
  hint?: string;
  featureInstance?: string;
  featureLabel?: string;
}

export const ifTranslationExists = (path: string, fallback: string): string => i18n.exists(path)
  ? i18n.t(path)
  : fallback;

/**
 * Cleans the feature ID by removing any instance information.
 *
 * @param {string} featureId - The original feature ID.
 * @return {string} The cleaned feature ID.
 */
const cleanFeatureId = (featureId: string): string =>
  featureId.includes('/') ? featureId.split('/')[0] : featureId;

/**
 * Retrieves the translated label for a given feature and event type based on the provided flag.
 *
 * @param {string} featureTypeId - The ID of the feature type.
 * @param {string} eventTypeId - The ID of the event type.
 * @param {string} featureId - The ID of the feature.
 * @param {boolean} [flag] - An optional flag to determine which label to retrieve.
 * @return {string} The translated label.
 */
export const getEventLabelWithFlag = (featureTypeId: string, eventTypeId: string, featureId: string, flag?: boolean): string => {
  if (flag){
    return getEventLabel(featureId, eventTypeId);
  }
  return getEventLabelV2(featureTypeId, eventTypeId, featureId);
};

/**
 * Retrieves the label for a given feature based on the provided flag.
 *
 * @param {string} feature - The ID of the feature.
 * @param {boolean} [flag] - An optional flag to determine which label to retrieve.
 * @return {string} The label for the feature.
 */
export const getFeatureLabelWithFlag = (feature: string, flag?: boolean): string => {
  if (flag){
    return getEventFeature(feature);
  }
  return getEventFeatureV2(feature);
};

/**
 * Gets the translated label for a given feature and event type.
 *
 * @param {string} featureId - The ID of the feature.
 * @param {string} eventTypeId - The ID of the event type.
 * @return {string | undefined} The translated label or undefined if not found.
 */
export const getEventLabel = (featureId: string, eventTypeId: string): string => {
  const cleanedFeatureId = cleanFeatureId(featureId);
  const primaryKey = `common:event-descriptions-v3.${cleanedFeatureId}.${eventTypeId}.labels`;
  return ifTranslationExists(primaryKey, eventTypeId);
};

/**
 * Retrieves the translated label for a given feature type and event type using event descriptions V2.
 *
 * @deprecated Use `getEventLabel` instead.
 * @param {string} featureTypeId - The ID of the feature type.
 * @param {string} eventTypeId - The ID of the event type.
 * @return {string} The translated label.
 */
export const getEventLabelV2 = (featureTypeId: string, eventTypeId: string, feature?: string): string => {
  if (feature) return i18n.exists(`common:event-descriptions-v2.${featureTypeId}.${eventTypeId}.label`, { feature: getEventFeatureV2(feature) }) ? i18n.t(`common:event-descriptions-v2.${featureTypeId}.${eventTypeId}.label`, { feature: getEventFeatureV2(feature) }) : eventTypeId;
  return ifTranslationExists(`common:event-descriptions-v2.${featureTypeId}.${eventTypeId}.label`, eventTypeId);
};

/**
 * Gets the translated hint for a given feature and event type.
 *
 * @param {string} featureId - The ID of the feature.
 * @param {string} eventTypeId - The ID of the event type.
 * @return {string | undefined} The translated hint or undefined if not found.
 */
export const getEventHint = (featureId: string, eventTypeId: string): string | undefined => {
  const cleanedFeatureId = cleanFeatureId(featureId);
  const hintKey = `common:event-descriptions-v3.${cleanedFeatureId}.${eventTypeId}.hint`;
  return i18n.t(hintKey);
};

/**
 * Gets the translated feature instance for a given feature ID.
 *
 * @param {string} featureId - The ID of the feature.
 * @return {string | undefined} The translated feature instance or undefined if not applicable.
 */
export const getFeatureInstance = (featureId: string): string | undefined => {
  if (featureId.includes('/')) {
    const [feature, ...instanceParts] = featureId.split('/');
    const instance = instanceParts.join('.');
    const instanceKey = `common:feature-instances.${feature}.${instance}`;
    return i18n.t(instanceKey);
  }

  return undefined;
};

/**
 * Retrieves the translated feature for a given feature ID.
 *
 * @param {string} featureId - The ID of the feature.
 * @return {string | undefined} The translated feature label or undefined if not applicable.
 */
export const getEventFeature = (featureId: string): string => {
  if (featureId.includes('/')) {
    return ifTranslationExists(`common:features.${featureId.split('/')[0]}`, featureId);
  }
  return ifTranslationExists(`common:features.${featureId}`, featureId);
};

/**
 * Retrieves the translated feature for a given feature string using event descriptions V2.
 *
 * @deprecated Use `getEventFeature` instead.
 * @param {string} feature - The feature string to translate.
 * @return {string | undefined} The translated feature label or undefined if not applicable.
 */
export const getEventFeatureV2 = (feature: string): string => {
  if (feature.includes('camera/')) return ifTranslationExists(`common:features.camera-type.${feature}`, feature);
  return ifTranslationExists(`common:features.${feature}`, feature);
};

/**
 * Generates a translation result object based on the given feature ID and event type ID.
 *
 * @param {string} featureId - The ID of the feature.
 * @param {string} eventTypeId - The ID of the event type.
 * @return {TranslationResult} An object containing the translated label, hint, and feature instance.
 */
export const getEventTranslation = (featureId: string, eventTypeId: string): TranslationResult => ({
  label: getEventLabel(featureId, eventTypeId),
  hint: getEventHint(featureId, eventTypeId),
  featureInstance: getFeatureInstance(featureId),
  featureLabel: getEventFeature(featureId),
});
