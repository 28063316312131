/* eslint-disable react-hooks/rules-of-hooks */
import { Operator } from '@jexop/core';
import { useTheme } from '@mui/material';
import { get, isString } from 'lodash';
import { Nullable } from '../types';

export const themeProperty: Operator<unknown> = ({ path }): Nullable<unknown> => {
  const theme = useTheme();

  if (!isString(path)) {
    return null;
  }

  return get(theme, path, null);
};
