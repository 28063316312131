export const TRACK_PERSON_LIST_ROW_DEFAULT_TEMPLATE = {
  op: 'ui',
  name: 'ListRow',
  sx: {
    justifyContent: 'space-between',
  },
  children: [
    {
      op: 'ui',
      name: 'DisplayColumn',
      data: {
        op: 'context',
      },
    },
    {
      op: 'ui',
      name: 'PropertyColumn',
      index: 0,
      data: {
        op: 'context',
      },
    },
    {
      op: 'ui',
      name: 'PropertyColumn',
      index: 1,
      data: {
        op: 'context',
      },
    },
    {
      op: 'ui',
      name: 'GroupColumn',
      data: {
        op: 'context',
      },
    },
  ],
};

export const ADMIN_PERSON_LIST_ROW_DEFAULT_TEMPLATE = {
  op: 'ui',
  name: 'ListRow',
  sx: {
    justifyContent: 'space-between',
  },
  children: [
    {
      op: 'ui',
      name: 'DisplayColumn',
      data: {
        op: 'context',
      },
    },
    {
      op: 'ui',
      name: 'PropertyColumn',
      index: 0,
      data: {
        op: 'context',
      },
    },
    {
      op: 'ui',
      name: 'PropertyColumn',
      index: 1,
      data: {
        op: 'context',
      },
    },
    {
      op: 'ui',
      name: 'PropertyColumn',
      index: 2,
      data: {
        op: 'context',
      },
    },
  ],
};
