import { JsonArray, JsonObject } from '@eagle/common';
import { AlertResponse } from '@eagle/core-data-types';
import { Box, Card } from '@mui/material';
import { FC } from 'react';
import { FlexBox } from '../flex-box';
import { AlertListItemMobile } from './alert-list-item-mobile';

interface LayoutMobileProps {
  alerts: AlertResponse[];
  isLoading: boolean;
  paginationComponent?: JSX.Element;
  showPerson: boolean;
  showThing: boolean;
  showAllButtonComponent?: JSX.Element;
}

export const AlertsTableLayoutMobile: FC<LayoutMobileProps> = ({
  alerts,
  isLoading,
  paginationComponent,
  showPerson,
  showThing,
  showAllButtonComponent,
}): JSX.Element => {
  return (
    <FlexBox
      data-testid='alert-table'
      role='table'
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'auto',
        padding: 0,
      }}
    >
      {alerts.map((alert) => {
        const location = alert.data?.location as JsonObject;
        const address = (location && (location?.address as JsonArray)) || [];

        return (
          <Card
            role='row'
            key={alert.alertId}
            sx={{
              filter: isLoading ? 'blur(1px)' : '',
              my: 1,
              width: '100%',
              overflow: 'visible',
            }}
          >
            <AlertListItemMobile
              address={address}
              alert={alert}
              showPerson={showPerson}
              showThing={showThing}
            />
          </Card>
        );
      })}
      {showAllButtonComponent ? <Box display='flex' justifyContent='center' marginTop={1} sx={{ mb: paginationComponent ? 1 : 3 }}> {showAllButtonComponent} </Box> : null}
      {paginationComponent
        ? <Box display="flex" justifyContent="center">
          {paginationComponent}
        </Box>
        : null
      }
    </FlexBox>
  );
};
