import OpenMenuIcon from '@mui/icons-material/Menu';
import { IconButton, Toolbar } from '@mui/material';
import { FC, PropsWithChildren, RefObject, Suspense } from 'react';
import { SIDEBAR_FULL_WIDTH, SIDEBAR_WIDTH } from '../../constants';
import { useSmallScreen } from '../../hooks';
import { Maybe } from '../../types';
import { MiddleSpinner } from '../middle-spinner';
import { AppDesktopLayout } from './layout-desktop';
import { AppMobileLayout } from './layout-mobile';
import { PageTitle } from './page-title';

interface Props extends PropsWithChildren {
  drawerContent: JSX.Element;
  drawerOpen: boolean;
  drawerRef: Maybe<RefObject<HTMLDivElement>>;
  onDrawerClose: () => void;
  onDrawerOpen: () => void;
  siteTitle: string;
}

export const AppView: FC<Props> = ({ children, drawerContent, drawerOpen, drawerRef, onDrawerClose, onDrawerOpen, siteTitle }) => {
  const smallScreen = useSmallScreen();

  if (smallScreen) {
    return <AppMobileLayout
      drawerContent={drawerContent}
      drawerOpen={drawerOpen}
      drawerRef={drawerRef ?? null}
      onDrawerClose={onDrawerClose}
      onDrawerOpen={onDrawerOpen}
      toolbar={<Toolbar id="page-header" data-testid="mobile-toolbar">
        <IconButton
          color="inherit"
          aria-label="toggle drawer"
          edge="start"
          onClick={drawerOpen ? onDrawerClose : onDrawerOpen}
          sx={{ mr: 2 }}
        >
          <OpenMenuIcon />
        </IconButton>
        <PageTitle defaultValue={siteTitle} />
      </Toolbar>}
    >
      <Suspense fallback={<MiddleSpinner />}>
        {children}
      </Suspense>
    </AppMobileLayout>;
  }

  return (
    <AppDesktopLayout
      drawerContent={drawerContent}
      drawerOpen={drawerOpen}
      drawerRef={drawerRef ?? null}
      onDrawerClose={onDrawerClose}
      onDrawerOpen={onDrawerOpen}
      drawerWidthCollapsed={SIDEBAR_WIDTH}
      drawerWidthExpanded={SIDEBAR_FULL_WIDTH}
    >
      <Suspense fallback={<MiddleSpinner />}>
        {children}
      </Suspense>
    </AppDesktopLayout>
  );
};
