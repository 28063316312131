import { ArrowBack } from '@mui/icons-material';
import { Box, Chip, IconButton, Paper, useTheme } from '@mui/material';
import { TFunction } from 'i18next';
import { FC, useEffect, useRef, useState } from 'react';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { AppliedFilter, AppliedFilterType } from '../entity-search/types';
import { FilterElement } from './filter-element';

interface Props {
  filters: AppliedFilter<AppliedFilterType>[];
  openFilter?: boolean;
  removeAllFilters: () => unknown;
  removeFilter: (filterToRemove: AppliedFilter) => unknown;
  tFunction: TFunction;
}

export const AppliedFiltersMapMobile: FC<Props> = ({ filters, removeAllFilters, removeFilter, tFunction }) => {
  const theme = useTheme();
  const timeoutRef = useRef<number>();
  const startScroll = useRef<HTMLDivElement>(null);
  const [disabled, setDisabled] = useState(false);
  const label = tFunction('common:component.filter.actions.clear-all');
  const handleScrollBack = (): void => {
    setDisabled(true);
    scrollIntoView(startScroll.current as Element, { behavior: 'smooth', inline: 'end' });
    timeoutRef.current = window.setTimeout(() => setDisabled(false), 500);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <Paper
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        overflowY: 'hidden',
        overscrollBehaviorX: 'contain',
        pt: 1,
        scrollbarColor: 'transparent transparent',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        whiteSpace: 'nowrap',
      }}
    >
      <Box ref={startScroll} sx={{ px: 0.5 }} />
      <Chip
        size="small"
        disabled={disabled}
        label={label}
        onDelete={removeAllFilters}
        sx={{ margin: '0 0.5rem 0.7rem 0', backgroundColor: theme.palette.error.light, color: theme.palette.common.white }}
      />

      {
        filters.map((filter) => {
          return <FilterElement
            key={filter.id}
            disabled={disabled}
            filter={filter}
            onRemoveClicked={() => removeFilter(filter)}
            sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white }}
          />;
        })
      }

      {
        filters.length > 3
        && <IconButton
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            height: '2rem',
            margin: '0 0.5rem 0.7rem 0',
            width: '2rem',
          }}
          onTouchEnd={handleScrollBack}
        >
          <ArrowBack />
        </IconButton>
      }
      <Box sx={{ px: 0.5 }} />
    </Paper>
  );
};

export const AppliedFiltersMapDesktop: FC<Props> = ({ filters, removeAllFilters, removeFilter, tFunction }) => {
  const theme = useTheme();
  const label = tFunction('common:component.filter.actions.clear-all');
  return (
    <Box sx={{ display: 'flex', width: '100%', flex: 1, p: 1, zIndex: 999 }}>
      <Box>
        <Chip
          label={label}
          onDelete={removeAllFilters}
          sx={{ margin: '0 0.5rem 0.7rem 0', backgroundColor: theme.palette.error.light, color: theme.palette.common.white }}
        />
        {
          filters.map((filter) => {
            return <FilterElement
              key={filter.id}
              filter={filter}
              onRemoveClicked={() => removeFilter(filter)}
              sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white }}
            />;
          })
        }
      </Box>
    </Box>
  );
};
