import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const fillPage: SxProps = {
  bottom: 0,
  display: 'grid',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
};

export const centered: SxProps = {
  display: 'flex',
  flexGrow: 1,
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
};

export const scrollbar: SxProps<Theme> = {
  scrollbarWidth: 'thin',
  scrollbarColor: (theme) => (theme.palette.primary.main, theme.palette.grey[300]),
  '&': {
    scrollbarWidth: 'thin',
    scrollbarColor: (theme) => (theme.palette.primary.main, theme.palette.grey[300]),
  },
  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
    backgroundColor: (theme) => theme.palette.common.white,
  },
  '&::-webkit-scrollbar-track': {
    background: (theme) => theme.palette.grey[300],
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: (theme) => theme.palette.primary.main,
    borderRadius: '8px',
  },
};
