/* eslint-disable react-hooks/exhaustive-deps */
import { MarkerType } from '@eagle/api-types';
import { PointOfInterest, PointOfInterestSet, PointOfInterestType } from '@eagle/core-data-types';
import L from 'leaflet';
import { FC, useCallback, useEffect, useState } from 'react';
import { useAuthenticated } from '../../../../auth';
import { useFetchAllCache, usePromise } from '../../../../hooks';
import { CUSTOM_MARKER_ICONS } from '../../../../pages/point-of-interest/point-of-interest-custom-marker';
import { CacheDataTypes, Maybe } from '../../../../types';
import { useMapContext } from '../../../../util';
import { CustomMarker } from '../../../custom-marker';
import { ToggleLayers } from '../../layer-selection/layer-selection.types';
import { PointOfInterestPopup } from './point-of-interest-popup';
import { PointOfInterestTooltip } from './point-of-interest-tooltip';
import { getImageUrl } from './utils';

interface Props {
  isPopupOpen: boolean;
  pointOfInterest: PointOfInterest;
  setOpenedPopup: (id: string) => void;
}

export const PointOfInterestMarker: FC<Props> = ({ isPopupOpen, pointOfInterest, setOpenedPopup }): JSX.Element => {
  const { axios } = useAuthenticated();
  const { setMapDimming } = useMapContext();
  const poiSetCache = useFetchAllCache(CacheDataTypes.POINT_OF_INTEREST_SET);
  const poiTypeCache = useFetchAllCache(CacheDataTypes.POINT_OF_INTEREST_TYPE);
  const { _id, display, pointOfInterestSetId, pointOfInterestTypeId, location } = pointOfInterest;
  const [imageData, setImageData] = useState<Maybe<string>>();

  const handleOpenPopup = (): void => {
    setMapDimming(ToggleLayers.pointOfInterest, true);
    setOpenedPopup(_id);
  };

  const handleClosePopup = (): void => {
    setMapDimming(ToggleLayers.pointOfInterest, false);
    setOpenedPopup('');
  };

  useEffect(() => {
    const handleEscapeKeyDown = (e: KeyboardEvent): void => {
      if (!isPopupOpen || e.key !== 'Escape') return;
      handleClosePopup();
    };

    window.addEventListener('keydown', handleEscapeKeyDown);

    return () => {
      window.removeEventListener('keydown', handleEscapeKeyDown);
    };
  }, [isPopupOpen]);

  const loadPoiSet = useCallback(
    () => {
      return poiSetCache.one<PointOfInterestSet>(pointOfInterestSetId);
    },
    [pointOfInterestSetId],
  );

  const loadPoiType = useCallback(
    () => {
      return poiTypeCache.one<PointOfInterestType>(pointOfInterestTypeId);
    },
    [pointOfInterestTypeId],
  );

  const [poiSet] = usePromise(loadPoiSet, [loadPoiSet]);
  const [poiType] = usePromise(loadPoiType, [loadPoiType]);

  useEffect(() => {
    if (!poiSet || !poiType) return;

    const url = getImageUrl(pointOfInterest, poiSet, poiType);
    if (!url) {
      setImageData(null);
      return;
    }
    const fileReader = new FileReader();
    axios.get<Blob>(
      url,
      { transformResponse: [], responseType: 'blob' },
    )
      .then((result) => {
        fileReader.readAsDataURL(result.data);
        fileReader.addEventListener('loadend', () => setImageData(fileReader.result as string));
      })
      .catch(() => {
        // swallow error on purpose
      });
  }, [axios, display, poiSet, poiType, pointOfInterest]);

  if (!poiSet || !poiType || imageData === undefined) return <></>;

  const icon = pointOfInterest.icon ?? poiSet?.icon ?? poiType?.icon;
  const marker = pointOfInterest.marker ?? poiSet?.marker ?? poiType?.marker;

  const CustomIcon = CUSTOM_MARKER_ICONS.find((item) => item.name === icon)?.icon;

  return <>
    <CustomMarker
      color={marker?.color}
      eventHandlers={{
        click: () => isPopupOpen ? handleClosePopup() : handleOpenPopup(),
      }}
      pinPosition={L.latLng(location.latitude, location.longitude)}
      type={marker?.type ?? MarkerType.PIN}
      image={imageData ?? undefined}
      {...CustomIcon ? { icon: CustomIcon } : icon ? { image: icon } : {}}
    >
      <PointOfInterestTooltip display={display} />
    </CustomMarker>
    <PointOfInterestPopup
      closePopup={handleClosePopup}
      isPopupOpen={isPopupOpen}
      pointOfInterest={pointOfInterest}
      pointOfInterestSet={poiSet}
      pointOfInterestType={poiType}
    />
  </>;
};
