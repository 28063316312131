import { Tabs as MUITabs } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  centered?: boolean;
  value?: number;
  variant?: 'standard' | 'fullWidth';
  onChange?: (event: React.SyntheticEvent, value: number) => void;
}

export const Tabs: FC<Props> = ({ children, value, variant, centered, onChange }) => {
  return <MUITabs value={value} variant={variant} centered={centered} onChange={onChange}>{children}</MUITabs>;
};
