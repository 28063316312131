import { Geofence, GeoJsonType } from '@eagle/core-data-types';
import { booleanPointInPolygon, circle, point, polygon } from '@turf/turf';
import L from 'leaflet';
import { useEffect, useState } from 'react';
import { Undefinable } from '../../../types';
import { parseCircleGeometry } from '../layers/geofence/geofence.util';

interface GeofenceIntersections {
  intersections: Geofence[];
  setMousePosition: (latLng: L.LatLng) => void;
}

export const useGeofenceIntersection = (geofences: Undefinable<Geofence[]>): GeofenceIntersections => {
  const [mousePosition, setMousePosition] = useState<L.LatLng>();
  const [intersections, setIntersections] = useState<Geofence[]>([]);

  useEffect(() => {
    if (!mousePosition || !geofences) return;
    const overlap = geofences.filter(({ geometry }) => {
      switch (geometry.type) {
        case GeoJsonType.POLYGON:
          return booleanPointInPolygon(point(L.GeoJSON.latLngToCoords(mousePosition)), polygon(geometry.coordinates));

        case GeoJsonType.CIRCLE: {
          const { center, radius } = parseCircleGeometry(geometry);
          return booleanPointInPolygon(point(L.GeoJSON.latLngToCoords(mousePosition)), circle(center, radius, { units: 'meters' }));
        }

        default:
          return false;
      }
    });
    setIntersections(overlap);
  }, [mousePosition, geofences]);

  return {
    intersections,
    setMousePosition,
  };
};
