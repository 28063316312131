import { MediaSegmentData } from '@eagle/core-data-types';
import { Nullable } from '../../types';

export enum MediaTabs {
  IMAGES = 'images',
  VIDEOS = 'videos',
}

export type MediaContentRender = (
  carouselFeature: string,
  carouselIndex: number,
  closeCarousel: () => void,
  handleItemClick: (currentIndex: number, feature: string, url: Nullable<URL>, fileName?: string) => void,
  isCarouselOpen: boolean,
  listData: MediaSegmentData[],
) => JSX.Element;

export interface MediaBundler {
  items: MediaSegmentData[];
  tab: MediaTabs;
}

export interface MediaHasMoreBundle {
  images: Record<string, boolean>;
  videos: Record<string, boolean>;
}

export interface MediaData {
  imageItems: MediaSegmentData[];
  videoItems: MediaSegmentData[];
}

export interface NavigateState {
  endTime: Date;
  startTime: Date;
}

export enum MediaListPageType {
  ALERTS = 'alerts',
  THINGS = 'things',
  DEVICES = 'devices',
}
