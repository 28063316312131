import { Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  color?: string;
  'data-testid'?: string;
  sx?: SxProps;
}

export const Indicator: FC<Props> = ({ color, children, sx, ...props }) => {
  const theme = useTheme();
  return <Box data-testid={props['data-testid']} sx={{ display: 'flex', gap: 1, alignItems: 'center', ...sx }}>
    <Box>
      <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: color ?? theme.palette.primary.main }}></Box>
    </Box>
    <Box>
      {children}
    </Box>
  </Box>;
};
