import { usePromise } from '../../hooks';
import { ControllerProps } from './controller.props';
import { DetailView } from './view';

/**
  * NATIVE FUNCTION: Display an search result Item
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function DetailPage<T>({
  actions,
  breadcrumbs,
  disabled,
  entityLabel,
  loadData,
  pageAlert,
  pageIcon,
  renderDeletedBannerContent,
  renderDisplay,
  renderPageContent,
  renderPageTitle,
  restoreEntityApi,
  restoreEntityPermissions,
  view: DataView = DetailView,
  ...props
}: ControllerProps<T>): JSX.Element {
  const data = usePromise(() => loadData(), [loadData]);

  return (
    <DataView
      actions={actions}
      breadcrumbs={breadcrumbs}
      data-testid={props['data-testid']}
      data={data}
      disabled={disabled}
      entityLabel={entityLabel}
      pageAlert={pageAlert}
      pageIcon={pageIcon}
      renderDeletedBannerContent={renderDeletedBannerContent}
      renderDisplay={renderDisplay}
      renderPageContent={renderPageContent}
      renderPageTitle={renderPageTitle}
      restoreEntityApi={restoreEntityApi}
      restoreEntityPermissions={restoreEntityPermissions}
    />
  );
}
