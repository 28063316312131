import { makeStyles } from '../../theme';

export const modalStyles = makeStyles()((theme) => ({
  calendarPicker: {
    marginTop: '-0.5rem',
    maxHeight: '330px',
    '& .MuiCalendarPicker-viewTransitionContainer': {
      overflowY: 'hidden',
    },
    '& .MuiButtonBase-root.MuiPickersDay-root.MuiDateRangePickerDay-day.Mui-selected': {
      color: theme.palette.primary.contrastText,
    },
    overflow: 'hidden',
  },
}));
