/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* cspell: ignore EDITRESIZE nautic */

/**
 * There's a bug in react-leaflet-draw with resizing Circles that can't really be fixed in a nicer way.
 * See: https://github.com/Leaflet/Leaflet.draw/issues/1013#issuecomment-1727454692
*/
L.Edit.Circle.include({
  _resize(latlng: any) {
    const moveLatLng = this._moveMarker.getLatLng();
    const radius = this._map.distance(moveLatLng, latlng) as number;
    this._shape.setRadius(radius);
    if (this._map.editTooltip) {
      this._map._editTooltip.updateContent({
        text: L.drawLocal.edit.handlers.edit.tooltip.subtext + '<br />' + L.drawLocal.edit.handlers.edit.tooltip.text,
        subtext: L.drawLocal.draw.handlers.circle.radius + ': ' +
          L.GeometryUtil.readableDistance(radius, true, this.options.feet, this.options.nautic),
      });
    }
    this._shape.setRadius(radius);
    this._map.fire(L.Draw.Event.EDITRESIZE, { layer: this._shape });
  },
});
