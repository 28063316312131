import { Id, PaginatedResponse } from '@eagle/api-types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { createContext, FC, useContext, useMemo, useState } from 'react';
import { AuthenticatedState } from '../auth';
import { Undefinable } from '../types';
import { BaseCache, CacheProviderProps, Caches, FetchAllCacheDefinition, FetchOneCacheDefinition, IdSerializer } from './use-cache.types';
import { compareAuthStates, createCaches, DEFAULT_ID_SERIALIZER } from './use-cache.util';

export class FetchAllCache extends BaseCache {
  protected readonly idSerializer: IdSerializer;
  protected readonly fetchAll: () => Promise<Id<unknown>[]>;

  constructor(
    idSerializer: IdSerializer,
    fetchAll: () => Promise<Id<unknown>[]>,
  ) {
    super();
    this.idSerializer = idSerializer;
    this.fetchAll = fetchAll;
  }

  public async all<V extends Id<unknown>>(): Promise<V[]> {
    if (this.data === undefined) this.data = this.fetchData();

    return Array.from((await this.data).values()) as V[];
  }

  public async one<V extends Id<unknown>>(key: unknown): Promise<Undefinable<V>> {
    if (this.data === undefined) this.data = this.fetchData();

    const data = await this.data;
    return data.get(this.idSerializer(key)) as V;
  }

  public invalidate(): void {
    this.data = undefined;
  }

  private fetchData = async (): Promise<Map<string, Id<unknown>>> => {
    const data = await this.fetchAll();
    return data.reduce((map: Map<string, Id<unknown>>, value) => {
      map.set(this.idSerializer(value._id), value);
      return map;
    }, new Map<string, Id<unknown>>());
  };
}

class FetchOneCache extends BaseCache<Promise<Undefinable<Id<unknown>>>> {
  protected readonly initializer: () => Map<string, Promise<Undefinable<Id<unknown>>>>;
  protected readonly idSerializer: IdSerializer;
  protected readonly fetchOne: (id: string) => Promise<Undefinable<Id<unknown>>>;

  constructor(
    initializer: () => Map<string, Promise<Undefinable<Id<unknown>>>>,
    idSerializer: IdSerializer,
    fetchOne: (id: string) => Promise<Undefinable<Id<unknown>>>,
  ) {
    super();
    this.initializer = initializer;
    this.idSerializer = idSerializer;
    this.fetchOne = fetchOne;
  }

  public async one<V extends Id<unknown>>(key: unknown): Promise<Undefinable<V>> {
    if (this.data === undefined) this.data = Promise.resolve(this.initializer());

    const data = await this.data;
    const idSerialized = this.idSerializer(key);
    const element = data.get(idSerialized);
    if (element) return element as Promise<Undefinable<V>>;

    const value = this.fetchOne(idSerialized);
    data.set(idSerialized, value);

    return value as Promise<Undefinable<V>>;
  }

  public async invalidate(key: unknown): Promise<void> {
    if (this.data === undefined) return;
    (await this.data).delete(this.idSerializer(key));
  }
}

export const defineFetchAllCacheFor = <V,>(
  url: string,
  idSerializer: IdSerializer = DEFAULT_ID_SERIALIZER,
): FetchAllCacheDefinition<V> => {
  return {
    fetchAll: async (axios: AxiosInstance) => {
      console.log('Populating cache with all...', url);
      const axiosCall = <T extends V[] | PaginatedResponse<V> = V[] | PaginatedResponse<V>>(skip?: number, limit?: number): Promise<AxiosResponse<T>> => axios.get<T>(url, { params: { skip, limit } });
      const result = await axiosCall();
      if (Array.isArray(result.data)) return result.data;

      let pageResult = result as AxiosResponse<PaginatedResponse<V>>;
      const pages = [pageResult.data.items];
      let count = pageResult.data.items.length;
      while (pageResult.data.hasMore) {
        // eslint-disable-next-line no-await-in-loop
        pageResult = await axiosCall<PaginatedResponse<V>>(count, pageResult.data.items.length);
        pages.push(pageResult.data.items);
        count += pageResult.data.items.length;
      }
      // iteration to fetch all pages
      return pages.flat();
    },
    idSerializer,
  };
};

export const defineFetchOneCacheFor = <V,>(
  url: string,
  idSerializer: IdSerializer = DEFAULT_ID_SERIALIZER,
): FetchOneCacheDefinition<V> => {
  const initializer = (): Map<string, Promise<Undefinable<V>>> => new Map<string, Promise<Undefinable<V>>>();
  return {
    fetchOne: async (axios: AxiosInstance, id: string) => {
      console.log('Populating cache with one...', url, id);
      return axios.get<V>(`${url}/${id}`, { validateStatus: (status) => [200, 404].includes(status) })
        .then((response) => response.status === 200 ? response.data : undefined);
    },
    idSerializer,
    initializer,
  };
};

interface ContextProps {
  fetchAllCaches: Caches<FetchAllCache>;
  fetchOneCaches: Caches<FetchOneCache>;
}

export const CacheContext = createContext<ContextProps>({
  fetchAllCaches: {} as Caches<FetchAllCache>,
  fetchOneCaches: {} as Caches<FetchOneCache>,
});

export const CacheProvider: FC<CacheProviderProps> = ({
  authState,
  children,
  fetchAllCaches: fetchAllDefinitions,
  fetchOneCaches: fetchOneDefinitions,
}) => {
  const [previousAuthState, setPreviousAuthState] = useState<AuthenticatedState>();
  const [previousCaches, setPreviousCaches] = useState<ContextProps>();

  const { fetchAllCaches, fetchOneCaches } = useMemo(() => {
    if (previousCaches && previousAuthState && compareAuthStates(authState, previousAuthState)) return previousCaches;

    const currentCaches = {
      fetchAllCaches: createCaches(authState, fetchAllDefinitions, createFetchAllCache),
      fetchOneCaches: createCaches(authState, fetchOneDefinitions, createFetchOneCache),
    };

    setPreviousAuthState(authState);
    setPreviousCaches(currentCaches);
    return currentCaches;
  }, [authState, previousAuthState, previousCaches, fetchAllDefinitions, fetchOneDefinitions]);

  return (
    <CacheContext.Provider value={{ fetchAllCaches, fetchOneCaches }}>
      {children}
    </CacheContext.Provider>
  );
};

const createFetchAllCache = (definition: FetchAllCacheDefinition<Id<unknown>>, authState: AuthenticatedState): FetchAllCache => {
  return new FetchAllCache(
    (id: unknown) => definition.idSerializer(id),
    () => definition.fetchAll(authState.axios),
  );
};

const createFetchOneCache = (definition: FetchOneCacheDefinition<Id<unknown>>, authState: AuthenticatedState): FetchOneCache => {
  return new FetchOneCache(
    () => definition.initializer(),
    (id: unknown) => definition.idSerializer(id),
    (id: string) => definition.fetchOne(authState.axios, id),
  );
};

export const useFetchAllCache = (type: string): FetchAllCache => {
  const { fetchAllCaches } = useContext(CacheContext);
  if (!(type in fetchAllCaches)) throw new Error(`Fetch all cache for ${type} is not defined`);

  return fetchAllCaches[type];
};

export const useFetchOneCache = (type: string): FetchOneCache => {
  const { fetchOneCaches } = useContext(CacheContext);
  if (!(type in fetchOneCaches)) throw new Error(`Fetch one cache for ${type} is not defined`);

  return fetchOneCaches[type];
};
