import { getPropertyValue, isPropertyInObjectFormat, JsonObject, ReferencePropertyReferenceCollection } from '@eagle/common';
import { CacheDataTypes, Maybe, Nullable, Undefinable } from '../../types';

export const isPropertyAuthoritative = (property: unknown): boolean => {
  const isObjectFormat = isPropertyInObjectFormat(property);
  return isObjectFormat && 'authoritative' in property && property.authoritative === true;
};

export const referenceCollectionToCacheType: Record<ReferencePropertyReferenceCollection, CacheDataTypes> = {
  [ReferencePropertyReferenceCollection.BRAND]: CacheDataTypes.BRAND,
  [ReferencePropertyReferenceCollection.COUNTRY]: CacheDataTypes.COUNTRY,
  [ReferencePropertyReferenceCollection.MODEL]: CacheDataTypes.MODEL,
  [ReferencePropertyReferenceCollection.VARIANT]: CacheDataTypes.VARIANT,
};

export const convertPropertiesToObjectFormat = (properties: Maybe<JsonObject>): Nullable<JsonObject> => {
  if (!properties) {
    return null;
  }
  const output: JsonObject = {};
  for (const [key, value] of Object.entries(properties)) {
    output[key] = isPropertyInObjectFormat(value) ? value : { value };
  }
  return output;
};

export const getMaybePropertyValue = <T>(property: unknown): Undefinable<T> => {
  return property ? getPropertyValue<T>(property) : undefined;
};
