import { Theme } from '@mui/material';
import chroma from 'chroma-js';
import { getPortalLanguage } from '../../../util';
import { vectorLayerStyles } from './vector-layer-style';

// cSpell:ignore camelcase, landuse
/* eslint-disable camelcase */

export const satelliteMapStyle = (_theme: Theme, apiKey: string): Record<string, unknown> => {
  const mix = (amount: number, towards = 'white'): string => chroma.mix('black', towards, amount, 'hsl').css();
  const mixAlpha = (amount: number, towards = 'white', alpha: number): string => chroma.mix('black', towards, amount, 'hsl').alpha(alpha).css();
  const languageCode = getPortalLanguage();

  return {
    global: {
      language: languageCode,
      language_text_source: "function() { return (global.language && feature['name:'+global.language]) || feature.name; }",
      sort_rank: 'function() { return (feature.sort_rank); }',
      trainTrack: mix(0.35),
      pedestrian: mix(0.35),
      roadLine: mixAlpha(0.3, 'white', 0.2),
      majorRoadLine: mixAlpha(0.3, 'white', 0.2),
      regionBorderLine: 'white',
      roadCasing: mix(0.35),
      waterText: mix(0.9, '#33bfff'),
      waterTextOutline: mixAlpha(0.25, '#33bfff', 0.5),
      minorRoadText: mix(1, 'white'),
      roadText: mix(1, 'white'),
      roadTextOutline: mixAlpha(0.75, 'black', 0.5),
      placeText: mix(1, 'white'),
      placeTextOutline: mixAlpha(0.75, 'black', 0.5),
    },
    sources: {
      omv: {
        type: 'MVT',
        url: `https://vector.hereapi.com/v2/vectortiles/core/mc/{z}/{x}/{y}/omv?apikey=${apiKey}`,
        tile_size: 512,
      },
    },
    styles: {
      _transparent: {
        base: 'polygons',
        blend: 'overlay',
      },
    },
    layers: vectorLayerStyles,
  };
};
