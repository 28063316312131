import { Box, Collapse, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { DrawerSize } from './event-history.types';

interface Props {
  historyDrawerContent: JSX.Element;
  isDrawerOpen: boolean;
  setIsTransitioning: (isTransitioning: boolean) => void;
  twoWayCollapseContent: JSX.Element;
}

const OPEN_WIDTH = 'calc(100% - 72px) !important'; // TODO: TP-7045 get width(72px) from theme

export const EventHistoryTablet: FC<Props> = ({
  historyDrawerContent,
  isDrawerOpen,
  setIsTransitioning,
  twoWayCollapseContent,
}) => {
  const [width, setWidth] = useState(isDrawerOpen ? OPEN_WIDTH : 'auto');

  return (
    <Stack direction="row" sx={{ height: '100%', width: '100%' }}>
      <Collapse
        collapsedSize={DrawerSize.CLOSED}
        in={isDrawerOpen}
        onEnter={() => setWidth(OPEN_WIDTH)}
        onEntered={() => setIsTransitioning(false)}
        onEntering={() => setIsTransitioning(true)}
        onExited={() => setIsTransitioning(false)}
        onExiting={() => {
          setWidth('auto');
          setIsTransitioning(true);
        }}
        orientation="horizontal"
        sx={{
          background: (theme) => theme.palette.background.paper,
          boxShadow: 4,
          height: 'inherit',
          overflowY: 'auto',
          position: 'absolute',
          scrollbarWidth: 'none',
          zIndex: 1100,
          '& .MuiCollapse-wrapper': {
            justifyContent: 'center',
          },
          '& .MuiCollapse-wrapperInner': {
            width: !isDrawerOpen ? 'auto' : '100%',
          },
          '::-webkit-scrollbar': {
            display: 'none',
          },
          '@media (orientation: portrait)': {
            width,
          },
        }}
      >
        {historyDrawerContent}
      </Collapse>
      <Box sx={{ flex: [1, 1], height: 'inherit', ml: `${DrawerSize.CLOSED}px` }}>
        {twoWayCollapseContent}
      </Box>
    </Stack>
  );
};
