/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Fade, IconButton, Stack, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AddThingPersonDialog, EventLocationData, FilterField, useBoolFlag, useHistorySearch } from '../../../components';
import { ThingPersonDialogOperation } from '../../../components/assign-multiple-dialog/add-thing-person-dialog';
import { EntityJourneyListController } from '../../../components/entity-journey/entity-journey-list';
import { FilterBar, FilterDrawerContent, FILTERS_KEY } from '../../../components/filter';
import { HEIGHT_TO_CLOSE_DRAWER, WIDTH_TO_CLOSE_DRAWER } from '../../../constants';
import { CacheDataTypes, Maybe } from '../../../types';
import { getLocalStorageItem } from '../../../util';
import { useSearch } from '../../list/use-search';
import { MobileTabSelection } from '../event-history.types';
import { HistoryDrawerView } from './history-drawer-view';

interface Props {
  fields: FilterField[];
  filterDrawerOpen?: boolean;
  handleDrawerToggle: (value: boolean) => void;
  handleFilterOpen?: (value: boolean) => void;
  hoveredEventId: Maybe<string>;
  isDrawerOpen: boolean;
  isTransitioning: boolean;
  mobileTabSelection: MobileTabSelection;
  selectedEvent: Maybe<EventLocationData>;
  setHoveredEventId: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
  storageKey: string;
}

export const HistoryDrawerController: FC<Props> = ({
  fields,
  filterDrawerOpen,
  handleDrawerToggle,
  handleFilterOpen,
  hoveredEventId,
  isDrawerOpen,
  isTransitioning,
  mobileTabSelection,
  selectedEvent,
  setHoveredEventId,
  setSelectedEvent,
  storageKey,
}): JSX.Element => {
  const { t } = useTranslation(['common']);
  const trackFilterFlag = useBoolFlag('track-history-feature-filtering-temporary-20230627');
  const { filters, setFilters } = useSearch();
  const {
    currentEntityId,
    entityDialogOperationType,
    isEntityDialogOpen,
    setCurrentEntityId,
    setEntityDialogOperationType,
    setEntityTypeTab,
    setIsEntityDialogOpen,
  } = useHistorySearch();

  const mobileActions = (): JSX.Element => {
    if (!filterDrawerOpen) {
      return (
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between', mx: 2, pt: 2 }}>
          <Button data-testid='history-add-button' onClick={() => setIsEntityDialogOpen(true)} sx={{ width: '100%' }} variant="outlined">
            {t('common:component.assign-multiple-dialog.labels.add-entity')}
          </Button>
          {trackFilterFlag && <FilterBar
            filtersLength={filters.length}
            onFilterClicked={() => handleFilterOpen?.(true)}
            sx={{
              borderWidth: '1px',
              borderColor: (theme) => theme.palette.primary.main,
              borderRadius: '4px',
              borderStyle: 'solid',
            }}
          />}
        </Stack>
      );
    }

    return (
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', mx: 2, pt: 2 }}>
        <IconButton onClick={() => handleFilterOpen?.(false)}>
          <ChevronLeftIcon />
        </IconButton>
      </Stack>
    );
  };

  const desktopActions = (): JSX.Element => {
    return <>
      {trackFilterFlag
        && !filterDrawerOpen
        && <FilterBar
          filtersLength={filters.length}
          onFilterClicked={() => handleFilterOpen?.(true)}
          size="small"
        />
      }
      <IconButton
        onClick={() => {
          if (filterDrawerOpen) return handleFilterOpen?.(false);
          handleDrawerToggle(false);
        }}
      >
        <ChevronLeftIcon />
      </IconButton>
    </>;
  };

  const renderAddEntityButton = (): JSX.Element => {
    if (filterDrawerOpen) return <></>;
    if (!isDrawerOpen) {
      return (
        <IconButton onClick={() => setIsEntityDialogOpen(true)} sx={{ borderRadius: 0, width: '100%' }}>
          <AddIcon />
        </IconButton>
      );
    }
    return (
      <Button data-testid='history-add-button' onClick={() => setIsEntityDialogOpen(true)} sx={{ width: '100%' }} variant="outlined">
        {t('common:component.assign-multiple-dialog.labels.add-entity')}
      </Button>
    );
  };

  const renderOpenDrawerIcon = (): JSX.Element => (
    <IconButton onClick={() => handleDrawerToggle(true)} sx={{ borderRadius: 0, width: '100%' }}>
      <ChevronRightIcon />
    </IconButton>
  );

  const renderDrawerTitle = (): JSX.Element =>
    <Typography>
      {filterDrawerOpen ? t('common:component.filter.heading') : t('common:common.labels.history')}
    </Typography>;

  const renderInvestigationContent = (): JSX.Element => {
    const shouldRenderList = !filterDrawerOpen && !isTransitioning;
    const shouldRenderFilter = filterDrawerOpen && !isTransitioning;

    return <>
      <Fade in={shouldRenderList}>
        <Box sx={{ display: shouldRenderList ? 'block' : 'none' }}>
          <EntityJourneyListController
            handleDrawerToggle={handleDrawerToggle}
            hoveredEventId={hoveredEventId}
            isDrawerOpen={isDrawerOpen}
            selectedEvent={selectedEvent}
            setHoveredEventId={setHoveredEventId}
            setSelectedEvent={setSelectedEvent}
          />
          {renderAddEntityButton()}
        </Box>
      </Fade>
      <Fade in={shouldRenderFilter}>
        <Box sx={{ display: shouldRenderFilter ? 'block' : 'none' }}>
          <FilterDrawerContent
            fields={fields}
            filters={filters}
            onFiltersChanged={setFilters}
            showToolbar={false}
            storageKey={storageKey}
          />
        </Box>
      </Fade>
    </>;
  };

  const renderAddEntityDialog = (): JSX.Element => (
    <AddThingPersonDialog
      closeDialog={() => {
        setEntityDialogOperationType(ThingPersonDialogOperation.ADD);
        setIsEntityDialogOpen(false);
        setEntityTypeTab(CacheDataTypes.THING_TYPE);
      }}
      data-testid='history-add-dialog'
      entityId={currentEntityId}
      open={isEntityDialogOpen}
      operationType={entityDialogOperationType}
      setEntityId={setCurrentEntityId}
    />
  );

  useEffect(() => {
    const closeDrawer = (): void => {
      if (window.innerWidth > WIDTH_TO_CLOSE_DRAWER && window.innerHeight > HEIGHT_TO_CLOSE_DRAWER) return;
      handleDrawerToggle(false);
    };

    window.addEventListener('resize', closeDrawer);

    return () => window.removeEventListener('resize', closeDrawer);
  }, []);

  useEffect(() => {
    setFilters(getLocalStorageItem(FILTERS_KEY, { [storageKey]: [] })[storageKey] ?? []);
  }, [storageKey]);

  return (
    <HistoryDrawerView
      desktopActions={desktopActions()}
      historyContent={renderInvestigationContent()}
      isDrawerOpen={isDrawerOpen}
      mobileActions={mobileActions()}
      mobileTabSelection={mobileTabSelection}
      renderAddEntityDialog={renderAddEntityDialog}
      renderDrawerTitle={renderDrawerTitle}
      renderOpenDrawerIcon={renderOpenDrawerIcon}
    />
  );
};
