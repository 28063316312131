/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

interface Bounds {
  bottomRightLat: number;
  bottomRightLong: number;
  topLeftLat: number;
  topLeftLong: number;
}

const getCurrentBounds = (map: L.Map): Bounds => {
  const bounds = map.getBounds();
  const southEast = bounds.getSouthEast().wrap();
  const northWest = bounds.getNorthWest().wrap();

  return {
    bottomRightLat: southEast.lat,
    bottomRightLong: southEast.lng,
    topLeftLat: northWest.lat,
    topLeftLong: northWest.lng,
  };
};

export const useBoundsUpdate = (map: L.Map): Bounds => {
  const [bounds, setBounds] = useState<Bounds>(getCurrentBounds(map));

  const handleBoundsChange = (): void => setBounds(getCurrentBounds(map));

  useEffect(() => {
    map.on('dragend', handleBoundsChange);
    map.on('zoomend', handleBoundsChange);

    return () => {
      map.off('dragend', handleBoundsChange);
      map.off('zoomend', handleBoundsChange);
    };
  }, []);

  return bounds;
};
