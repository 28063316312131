import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Breakpoint } from '@mui/system/createTheme';

export const useMaxScreen = (size: Breakpoint): boolean => {
  const theme = useTheme();
  const screen = useMediaQuery(theme.breakpoints.down(size));
  return screen;
};

export default useMaxScreen;
