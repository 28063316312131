import { LastThingEvent } from '@eagle/core-data-types';
import { TableCell } from '@mui/material';
import { get } from 'lodash';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { LastThingEvents } from '../../hooks';
import { getEventTranslation } from '../../util';
import { FormatTimestamp } from '../format';
import { Table } from '../table';
import { TableRow } from '../table-row';

interface EventRow {
  feature: string;
  label?: string;
  eventTypeId: string;
  path: string;
}

interface Props {
  eventRows: EventRow[];
  lastEvents: LastThingEvents;
  title?: string;
}

const createEventRow = (
  feature: string,
  lastEventFeature: LastThingEvent,
  path: string,
  label?: string,
): ReactNode => {
  const { featureLabel } = getEventTranslation(feature, lastEventFeature.eventTypeId);
  const reading = get(lastEventFeature, path) as string;
  return (
    <TableRow>
      <TableCell>{label || featureLabel}</TableCell>
      <TableCell>{reading}</TableCell>
      <TableCell align='right'>
        <FormatTimestamp format='relative' value={lastEventFeature.occurred} />
      </TableCell>
    </TableRow>
  );
};

export const RecentEventCard: FC<Props> = ({ eventRows, lastEvents, title }) => {
  const { t } = useTranslation();
  const rows: ReactNode[] = [];

  eventRows.forEach((row) => {
    const eventTypeId = row.eventTypeId;
    const matchingFeatures = Object.keys(lastEvents.byFeature)
      .filter((feature) =>
        feature === row.feature &&
        lastEvents.byFeature[feature][eventTypeId]?.eventTypeId === eventTypeId,
      );

    matchingFeatures.forEach((feature) => {
      const lastEventFeature = lastEvents.byFeature[feature][eventTypeId];
      rows.push(createEventRow(feature, lastEventFeature, row.path, row.label));
    });
  });

  if (rows.length === 0) {
    rows.push(<TableRow><TableCell>{t('common.hint.list.no-results')}</TableCell></TableRow>);
  }

  return <Table title={title || t('common:component.recent_metrics.label')} rows={rows} />;
};
