/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { addLocalStorageItem } from '../../util/local-storage';
import { AppliedFilter, EntityField, FilterField, FilterOptions } from '../entity-search/types';
import { AddFilter } from './add-filter';
import { FilterElement } from './filter-element';
import { useFilterStyles } from './styles';

interface Props {
  fields: FilterField[];
  filterOptions: FilterOptions;
  filters: AppliedFilter[];
  filterText: ReactNode;
  noFilterText: ReactNode;
  onFiltersChanged?: (filters: AppliedFilter[]) => unknown;
  storageKey: string;
}

export const FILTERS_KEY = 'filters';

export const FilterBuilder: FC<Props> = ({
  fields,
  filterOptions,
  filters,
  filterText,
  noFilterText,
  onFiltersChanged,
  storageKey,
}) => {
  const classes = useFilterStyles();
  const [filterProperties, setFilterProperties] = useState<FilterField[]>(fields);

  useEffect(() => {
    const appliedEntityFilterIds = filters
      .filter((filter) => filter.definition.type === 'entity')
      .map((f) => (f.value as EntityField).id);
    if (!appliedEntityFilterIds.length) {
      setFilterProperties(fields);
      return;
    }

    const validFilterFields = fields.reduce<FilterField[]>(
      (acc, f) => {
        if (!f.ids || f.ids.some((id) => appliedEntityFilterIds.includes(id))) {
          return [...acc, f];
        }
        return acc;
      },
      [],
    );

    const filtersToRemove = filters.filter(
      (filter) =>
        filter.ids !== undefined
        && filter.ids.every((id) => !appliedEntityFilterIds.includes(id)),
    );

    const updatedFilters = filters.filter((f) => !filtersToRemove.includes(f));

    if (filtersToRemove.length) {
      addLocalStorageItem(FILTERS_KEY, {
        [storageKey]: updatedFilters,
      });
      onFiltersChanged?.(updatedFilters);
    }

    setFilterProperties(validFilterFields);
  }, [filters]);

  const removeFilters = useCallback((filterToRemove: AppliedFilter) => {
    const updatedFilters = filters.filter((f) => f !== filterToRemove);

    addLocalStorageItem(FILTERS_KEY, {
      [storageKey]: updatedFilters,
    });
    onFiltersChanged?.(updatedFilters);
  }, [filters, onFiltersChanged]);

  return (
    <Box>
      {filters.length
        ? <Box className={classes.form} sx={{ py: 1 }}>
          <Typography variant="body1">
            {filterText}
          </Typography>
          <Box>
            {filters.map((filter) => (
              <FilterElement sx={{ maxWidth: 1 }} key={filter.id} filter={filter} onRemoveClicked={() => removeFilters(filter)} />
            ))}
          </Box>
        </Box>
        : <Typography color="GrayText" sx={{ py: 1 }}>
          {noFilterText}
        </Typography>
      }
      {filterProperties.length > 0
        && <AddFilter
          fields={fields}
          filters={filters}
          filterOptions={filterOptions}
          onFiltersChanged={onFiltersChanged}
          storageKey={storageKey}
        />
      }
    </Box>
  );
};
