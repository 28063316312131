import { FC } from 'react';
import { DisplayWithDeleted } from '../../types';
import { FetchOne } from '../fetch';
import { SimpleElementSearch } from '../simple-element';

interface Props {
  dataType: string;
  id: unknown;
}

export const FetchOneColumn: FC<Props> = ({ dataType, id }) => {
  return <FetchOne
    id={id}
    dataType={dataType}
    renderFactory={(data: DisplayWithDeleted) => <SimpleElementSearch data={data} />}
  />;
};
