/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, OutlinedInput } from '@mui/material';
import { SxProps } from '@mui/system';
import { forwardRef, KeyboardEvent as ReactKeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useSmallScreen from '../../hooks/use-small-screen';
import { useSearch } from '../../pages/list/use-search';
import { trackEvent } from '../../util';
import { useSearchStyles } from './styles';

interface Props {
  id: string;
  disabled?: boolean;
  focus?: boolean;
  onChanged?: (text: string) => unknown;
  onCloseClicked?: () => unknown;
  onEnterPressed?: (highlightIndex: number) => unknown;
  search?: string;
  sx?: SxProps;
}

export const Search = forwardRef<HTMLDivElement, Props>(({
  disabled = false,
  focus,
  id,
  onCloseClicked,
  onEnterPressed,
  sx,
}, ref) => {
  const { classes } = useSearchStyles();
  const smallScreen = useSmallScreen();
  const searchInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(['common']);
  const {
    highlightIndex,
    isLoading,
    pagination,
    setHighlightIndex,
    setPagination,
    setText,
    result,
    text,
  } = useSearch();

  const onKeyDown = useCallback((event: ReactKeyboardEvent): void => {
    switch (event.key) {
      case 'Escape':
        event.preventDefault();
        setText?.('');
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (isLoading) break;
        setHighlightIndex(
          highlightIndex === undefined || highlightIndex < 1
            ? undefined
            : highlightIndex - 1,
        );
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (isLoading || !result || !result.itemCount) break;
        if (highlightIndex === undefined || highlightIndex < result.itemCount - 1) {
          setHighlightIndex((highlightIndex ?? -1) + 1);
        }
        break;
      case 'PageUp':
        event.preventDefault();
        if (pagination.skip <= 0) return;
        if (pagination.skip < pagination.limit) {
          setPagination({ ...pagination, skip: 0 });
          return;
        }
        setPagination({ ...pagination, skip: pagination.skip - pagination.limit });
        break;
      case 'PageDown':
        event.preventDefault();
        if (isLoading || !result || result.matchCount < pagination.skip + pagination.limit) return;
        setPagination({ ...pagination, skip: pagination.skip + pagination.limit });
        break;
    }
  }, [highlightIndex, result, pagination, searchInput, isLoading]);

  const onKeyUp = useCallback((event: ReactKeyboardEvent): void => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        if (smallScreen) {
          onCloseClicked?.();
          break;
        }
        if (isLoading || highlightIndex === undefined) break;
        onEnterPressed?.(highlightIndex);
        break;
    }
  }, [highlightIndex, smallScreen]);

  const keyDownHandler = useCallback((event: KeyboardEvent): void => {
    switch (event.key) {
      case 'S':
        if (event.shiftKey && event.altKey) {
          if (searchInput && searchInput.current) searchInput.current.focus();
        }
        break;
    }
  }, [searchInput]);

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);

  return (
    <OutlinedInput
      ref={ref}
      data-testid="search-bar"
      autoFocus={focus}
      classes={{ notchedOutline: classes.outlinedInput }}
      disabled={disabled}
      inputProps={{ 'data-testid': 'search-bar-input' }}
      inputRef={searchInput}
      onChange={(e) => setText?.(e.target.value)}
      onKeyDown={!smallScreen ? onKeyDown : undefined}
      onKeyUp={onKeyUp}
      onFocus={() => {
        trackEvent('keyword_search', 'focus_input', `${id}_list`);
      }}
      placeholder={t('common:component.lookup.hint.placeholder')}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      sx={{
        '& .MuiOutlinedInput-input': { padding: '0.657rem 0.5rem 0.657rem 0' },
        width: '100%',
        ...sx,
      }}
      type="search"
      value={text}
    />
  );
});

Search.displayName = 'Search';
