import axios, { AxiosResponse } from 'axios';
import i18next, { BackendModule, i18n, InitOptions, Module, ResourceKey } from 'i18next';
import LanguageDetector, { CustomDetector } from 'i18next-browser-languagedetector';
import { debounce } from 'lodash';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { DomainCustomConfig } from '../auth';
import { captureException, captureMessage } from '../components';

interface DynamicBackendOptions {
  baseUrl: string;
  config: Partial<DomainCustomConfig>;
}

type ReadCallback = (err: Error | null, data: ResourceKey | null) => void;

class DynamicBackend implements BackendModule<DynamicBackendOptions> {
  public static readonly type: 'backend' = 'backend';
  public readonly type: 'backend' = 'backend';

  constructor(private readonly options: DynamicBackendOptions) {}

  public init(): void {}

  public async read(language: string, namespace: string, callback: ReadCallback): Promise<void> {
    try {
      let url: string;
      if (namespace === 'terms' && this.options.config.localazy && this.options.config.localazy.terms) {
        url = `${this.options.baseUrl}/locales/${this.options.config.localazy.terms}/${language}/terms.json`;
      } else {
        url = `${this.options.baseUrl}/locales/${language}/${namespace}.json`;
      }

      const response: AxiosResponse<ResourceKey> = await axios.get(url, {
        params: {
          v: import.meta.env.VITE_BUILD ?? '',
        },
      });

      if (response.status === 200) {
        callback(null, response.data);
      } else {
        callback(new Error(`Failed to load ${namespace} for ${language}`), null);
      }
    } catch (error) {
      console.error(`Error loading ${namespace} for ${language}:`, error);
      callback(error instanceof Error ? error : new Error('Unknown error occurred'), null);
    }
  }
}

interface DomainI18nProviderProps extends PropsWithChildren {
  config: Partial<DomainCustomConfig>;
}

const isProduction = import.meta.env.PROD;

const processZhLanguage = (lang: string): string => {
  if (lang.startsWith('zh') && !lang.includes('Hant')) {
    return `zh-Hant${lang.substring(2)}`;
  }
  return lang;
};

const customDetector: CustomDetector = {
  name: 'customZhDetector',
  lookup: (_options) => {
    const detectedLang = window.navigator.language;
    return processZhLanguage(detectedLang);
  },
};

const localStorageDetector: CustomDetector = {
  name: 'localStorageDetector',
  lookup: (_options) => {
    const lang = localStorage.getItem('portal-language-preference');
    if (lang && lang !== 'null') {
      const cleanLang = lang.replace(/^"|"$/g, '').replace('_', '-'); // Remove quotes and replace underscore with hyphen
      return processZhLanguage(cleanLang);
    }
    return undefined;
  },
};

const reportMissingKey = debounce((namespace: string, key: string, fallbackValue: string | undefined): void => {
  captureMessage(`Missing translation key: ${namespace}:${key}`, {
    level: 'warning',
    tags: {
      namespace,
      fallbackValue,
    },
  });
}, 2000);

const enabledNamespaces = ['common', 'auth', 'admin', 'track', 'manage', 'terms', 'l10n'];

// Create a shared i18n instance
export const i18nInstance = i18next.createInstance();

export const initializeI18n = async (baseUrl: string, config: Partial<DomainCustomConfig>): Promise<i18n> => {
  const languageDetector = new LanguageDetector();
  languageDetector.addDetector(customDetector);
  languageDetector.addDetector(localStorageDetector);

  try {
    await i18nInstance
      .use(languageDetector)
      .use(new DynamicBackend({
        baseUrl,
        config,
      }) as Module)
      .use(initReactI18next)
      .init({
        fallbackLng: 'en',
        debug: false,
        interpolation: {
          escapeValue: false,
        },
        ns: enabledNamespaces,
        defaultNS: 'common',
        detection: {
          order: ['localStorageDetector', 'customZhDetector', 'navigator', 'querystring', 'cookie', 'htmlTag'],
          caches: ['localStorage'],
        },
        react: {
          useSuspense: true,
        },
        missingKeyHandler: (lngs, namespace, key, fallbackValue) => {
          if (isProduction && lngs.includes('en')) {
            reportMissingKey(namespace, key, fallbackValue);
          }
        },
        saveMissing: isProduction,
        preload: ['en'],
      } as InitOptions);

    return i18nInstance;
  } catch (error) {
    console.error('Error initializing i18n:', error);
    captureException(error);
    throw error;
  }
};

export const DomainI18nProvider: FC<DomainI18nProviderProps> = ({ children, config }) => {
  const baseUrl = import.meta.env.BASE_URL || '/';
  const [isI18nInitialized, setIsI18nInitialized] = useState(false);

  useEffect(() => {
    const init = async (): Promise<void> => {
      await initializeI18n(baseUrl, config);
      setIsI18nInitialized(true);
    };

    void init();
  }, [baseUrl, config]);

  if (!isI18nInitialized) return null;

  return (
    <I18nextProvider i18n={i18nInstance}>
      {children}
    </I18nextProvider>
  );
};

// Export the i18n instance and initialization function
export { i18nInstance as i18n };

