import { Button, Menu, MenuItem } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren, useState } from 'react';

interface Option {
  label: string;
  href?: string;
  action?: string;
}

interface Props extends PropsWithChildren {
  variant?: 'text' | 'outlined' | 'contained';
  options?: Option[];
  'data-testid'?: string;
  sx?: SxProps;
  onAction?: (option: Option) => void;
}

export const ButtonMenu: FC<Props> = ({ children, variant, options, sx, onAction, ...props }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleAction = (option: Option): void => {
    option.action && onAction?.(option);
    setAnchorEl(null);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id='button'
        variant={variant}
        data-testid={props['data-testid']}
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={sx}
      >
        {children}
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        {options?.map((option: Option, i: number) => <MenuItem href={option.href || ''} key={i} onClick={() => handleAction(option)}>{option.label}</MenuItem>)}
      </Menu>
    </>
  );
};
