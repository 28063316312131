/* eslint-disable react-hooks/exhaustive-deps */
import * as turf from '@turf/turf';
import { Feature, Point } from '@turf/turf';
import L from 'leaflet';
import { throttle } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useNumberFlag } from '../../../flags/hooks';
import { ThingEventProperties } from '../../thing-event-pane/thing-event-pane.types';
import { AntPolyline } from './ant-polyline';
import MarkerClusters from './marker-clusters';
import { MouseOverLine } from './mouse-over-line';
import NormalPolyline from './normal-polyline';
import { RoutingItemSnapProps } from './route-matching.types';
import { createOffsetLine } from './util';

interface MappedPosition {
  coordinates: { latitude: number; longitude: number };
  linkId: string;
}

export const RoutingItemSnap: FC<RoutingItemSnapProps> = ({
  color,
  focused,
  handleBreadcrumbClick,
  hoveredEventId,
  isAntPolylineEnabled,
  isSelected,
  isVisible,
  locations,
  renderer,
  routeData,
  selectedEvent,
  setHoveredEventId,
  setSelectedEvent,
}) => {
  const map = useMap();
  const [offsetLine, setOffsetLine] = useState<turf.Feature<turf.LineString, turf.Properties>>();
  const [mappedPositions, setMappedPositions] = useState<MappedPosition[]>([]);
  const clusterDistance = useNumberFlag('track-history-feature-clustering-distance-snapped') ?? 120;
  const [visibleNearestPoints, setVisibleNearestPoints] = useState<turf.Feature<turf.Point, ThingEventProperties>[]>([]);

  useEffect(() => {
    if (!routeData?.response?.route[0]) return;
    const { leg, waypoint } = routeData.response.route[0];

    const legData = leg[0].link.flatMap(({ shape }) => {
      const shapeLength = shape.length;
      const legShape = Array.from({ length: shapeLength / 2 }, (_, i) => {
        return L.latLng(shape[2 * i], shape[2 * i + 1]);
      });
      return shapeLength > 2 ? legShape.slice(0, -1) : legShape;
    });

    if (legData.length < 2) return;

    setMappedPositions(waypoint.map(({ mappedPosition, linkId }) => (
      {
        coordinates: mappedPosition,
        linkId,
      }
    )));

    const startWaypoint = waypoint[0].mappedPosition;
    const endWaypoint = waypoint[waypoint.length - 1].mappedPosition;
    const lineString = turf.lineString([
      [startWaypoint.latitude, startWaypoint.longitude],
      ...legData.map(({ lat, lng }) => [lat, lng]),
      [endWaypoint.latitude, endWaypoint.longitude],
    ]);
    setOffsetLine(createOffsetLine(lineString, 2));
  }, [routeData]);

  const nearestPoints: turf.Feature<turf.Point, ThingEventProperties>[] = useMemo(() => {
    if (!mappedPositions.length) return [];

    return locations.map(({ properties }, i) => {
      const { coordinates: { latitude, longitude } } = mappedPositions[i];

      return turf.point([longitude, latitude], {
        ...properties,
        cluster: false,
      });
    });
  }, [mappedPositions]);

  const updateVisiblePoints = useCallback(() => {
    if (!offsetLine || locations.length === 0) return;

    const bounds = map.getBounds();

    const startPoint: Feature<Point, ThingEventProperties> = turf.point(
      [locations[0].geometry.coordinates[0], locations[0].geometry.coordinates[1]],
      locations[0].properties,
    );

    const endPoint: Feature<Point, ThingEventProperties> = turf.point(
      [locations[locations.length - 1].geometry.coordinates[0], locations[locations.length - 1].geometry.coordinates[1]],
      locations[locations.length - 1].properties,
    );

    const middlePoints: Feature<Point, ThingEventProperties>[] = locations.slice(1, -1)
      .map((location) => turf.point([location.geometry.coordinates[0], location.geometry.coordinates[1]], location.properties))
      .filter((point) => bounds.contains(L.latLng(point.geometry.coordinates[1], point.geometry.coordinates[0])));

    const visiblePoints: Feature<Point, ThingEventProperties>[] = [startPoint, ...middlePoints, endPoint];

    setVisibleNearestPoints([...visiblePoints]);
  }, [map, locations, offsetLine]);

  useEffect(() => {
    const updateVisiblePointsThrottled = throttle(updateVisiblePoints, 100);
    updateVisiblePointsThrottled();

    map.on('moveend', updateVisiblePointsThrottled);
    map.on('zoomend', updateVisiblePointsThrottled);

    return () => {
      map.off('moveend', updateVisiblePointsThrottled);
      map.off('zoomend', updateVisiblePointsThrottled);
      updateVisiblePointsThrottled.cancel();
    };
  }, [map, updateVisiblePoints]);

  const lineComponent = useMemo(() => {
    if (!offsetLine) return <></>;
    if (isAntPolylineEnabled) {
      return (
        <AntPolyline
          color={color}
          data={offsetLine}
          isEnabled={isAntPolylineEnabled}
          map={map}
          paused={!isSelected}
        />
      );
    }

    return (
      <NormalPolyline
        color={color}
        data={offsetLine}
        renderer={renderer}
      />
    );
  }, [offsetLine, color, isSelected, isAntPolylineEnabled]);

  if (!offsetLine || !isVisible || !routeData) return <></>;

  return (
    <>
      <MarkerClusters
        clusterRadius={clusterDistance}
        handleBreadcrumbClick={handleBreadcrumbClick}
        hoveredEventId={hoveredEventId}
        isSelected={isSelected}
        isVisible={isVisible && focused}
        nearestPoints={nearestPoints}
        setHoveredEventId={setHoveredEventId}
        setSelectedEvent={setSelectedEvent}
      />
      {lineComponent}
      <MouseOverLine
        clusterRadius={clusterDistance}
        data={offsetLine}
        handleBreadcrumbClick={handleBreadcrumbClick}
        nearestPoints={visibleNearestPoints}
        renderer={renderer}
        selectedEvent={selectedEvent}
        setHoveredEventId={setHoveredEventId}
        setSelectedEvent={setSelectedEvent}
      />
    </>
  );
};
