/* eslint-disable react-hooks/exhaustive-deps */
import { ChannelType, RecipientType, User, UserGroup } from '@eagle/core-data-types';
import { Card, CardContent, Typography } from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { useAuthenticated } from '../../auth';
import { ErrorMessage, getBrowserLocale, getCanonicalTimeZone, useBoolFlag } from '../../components';
import { T_ONE } from '../../constants';
import { useFetchAllCache, usePromise } from '../../hooks';
import { CacheDataTypes, Nullable } from '../../types';
import { filterDeleted, FILTER_OUT } from '../../util';
import { DefaultRadioLabel } from './default-radio-label';
import { NotificationRecipientCardView } from './notification-recipient-card-view';
import { ContactMethodRadioButtons, NotificationRecipientCardProps, NotificationRecipientState, RadioButtons, RecipientUnion } from './subscription-detail.types';
import { channelTypeToRadioButton, generateRecipient, getChannelValue, getContactMethod, getDefaultOrCustom, getInitialContactDetail } from './subscription-detail.utils';

export const NotificationRecipientCard: FC<NotificationRecipientCardProps> = ({ isProfilePage, fieldDisabled, getRecipientState, recipient, setRecipientIsValid }) => {
  const { t } = useTranslation(['admin', 'common']);
  const { account, restClient } = useAuthenticated();
  const { enqueueSnackbar } = useSnackbar();
  const isSmsEnabled = useBoolFlag('admin-sms-subscription-feature');

  const contactOptions = [
    {
      label: t('admin:page.create-user.basic-info.email.label'),
      value: RadioButtons.EMAIL,
    },
  ];
  if (isSmsEnabled) {
    contactOptions.unshift({
      label: t('admin:page.create-subscription.notification-recipient.labels.sms'),
      value: RadioButtons.SMS,
    });
  }

  const [recipientState, setRecipientState] = useState<NotificationRecipientState>(() => {
    const isRecipientTypeExternal = recipient.type === RecipientType.EXTERNAL;
    const user = isRecipientTypeExternal ? RadioButtons.OTHER : RadioButtons.USER;
    const isGroup = (!isRecipientTypeExternal && recipient.type === RecipientType.INTERNAL_USER_GROUP);
    const notificationGroup = isGroup
      ? RadioButtons.GROUP
      : RadioButtons.INDIVIDUAL;
    const contactMethod = getContactMethod(recipient, contactOptions[0].value as ContactMethodRadioButtons);
    const defaultOrCustomContact = getDefaultOrCustom(recipient);

    return {
      contactMethod,
      defaultOrCustomContact,
      notificationGroup,
      selectedRecipient: null,
      user,
    };
  });

  const [contactDetail, setContactDetail] = useState<Nullable<string>>(getInitialContactDetail(recipientState.defaultOrCustomContact, recipient));
  const [channelDetailHelper, setChannelDetailHelper] = useState('');
  const [isChannelDetailInvalid, setIsChannelDetailInvalid] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState<string>(recipient.localization?.locale ?? getBrowserLocale());
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(
    recipient.localization?.timezone ?? getCanonicalTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone),
  );

  const [users, usersError, usersState] = usePromise<User[]>(
    async () => {
      const results = await restClient.admin.user.getAll({
        filter: { accountId: account._id, ...FILTER_OUT.deleted },
      });
      return results.sort((a, b) => a.display.localeCompare(b.display));
    },
    [account, restClient],
  );

  const userGroupsCache = useFetchAllCache(CacheDataTypes.USER_GROUP);
  const [userGroups, userGroupsError, userGroupsState] = usePromise(
    async () => {
      const data = await userGroupsCache.all<UserGroup>();
      const filtered = data.filter((obj) => {
        return obj.accountBinding.some((id: string) => id === account._id);
      });
      return filterDeleted(filtered);
    },
    [userGroupsCache],
  );

  const recipientsPending = usersState === 'pending' || userGroupsState === 'pending';
  const recipients: RecipientUnion[] = (recipientState.notificationGroup === RadioButtons.INDIVIDUAL ? users : userGroups) ?? [];
  const isContactOptionsVisible = !(recipientState.user === RadioButtons.USER && !recipientState.selectedRecipient);
  const isContactDetailFieldVisible = recipientState.notificationGroup !== RadioButtons.GROUP || recipientState.user === RadioButtons.OTHER;

  const getReferencedEntityId = (entities: RecipientUnion[]): Nullable<string> => {
    if (recipient.type !== RecipientType.INTERNAL_INDIVIDUAL_USER && recipient.type !== RecipientType.INTERNAL_USER_GROUP) {
      return null;
    }
    return entities.find(({ _id }) => _id === recipient.referenceId)?._id ?? null;
  };

  useEffect(() => {
    if (usersState === 'resolved' && recipient.type === RecipientType.INTERNAL_INDIVIDUAL_USER && users) {
      setRecipientState({
        ...recipientState,
        selectedRecipient: getReferencedEntityId(users),
      });
    }
  }, [users, usersState, recipient]);

  useEffect(() => {
    if (userGroupsState === 'resolved' && recipient.type === RecipientType.INTERNAL_USER_GROUP && userGroups) {
      setRecipientState({
        ...recipientState,
        selectedRecipient: getReferencedEntityId(userGroups),
      });
    }
  }, [userGroups, userGroupsState, recipient]);

  const handleSelectChange = (propertyPath: keyof NotificationRecipientState, value: string): void => {
    setRecipientState((prev) => {
      return {
        ...prev,
        [propertyPath]: value,
      };
    });
  };

  const handleEmailFieldChange = (value: string): Promise<void> => {
    const isValueValid = validator.isEmail(value);
    if (isValueValid) {
      setChannelDetailHelper('');
      setIsChannelDetailInvalid(false);
      setContactDetail(value);
      return Promise.resolve();
    }
    setChannelDetailHelper(t('common:common.hint.email'));
    setIsChannelDetailInvalid(true);
    setContactDetail(value);
    return Promise.resolve();
  };

  const getCustomLabel = (contactMethod: ContactMethodRadioButtons): string => {
    switch (contactMethod) {
      case RadioButtons.EMAIL: return t('admin:page.create-subscription.notification-recipient.labels.use-different-email');
      case RadioButtons.SMS: return t('admin:page.create-subscription.notification-recipient.labels.use-different-number');
    }
  };

  const userOptions = [
    {
      label: t('common:terms.user', { count: T_ONE }),
      value: RadioButtons.USER,
    },
    {
      label: t('common:terms.other'),
      value: RadioButtons.OTHER,
    },
  ];

  const notificationGroupOptions = [
    {
      label: t('admin:page.create-subscription.notification-recipient.labels.individual'),
      value: RadioButtons.INDIVIDUAL,
    },
    {
      label: t('admin:page.create-subscription.notification-recipient.labels.group'),
      value: RadioButtons.GROUP,
    },
  ];

  const defaultOptions = [
    {
      label: <DefaultRadioLabel contactMethod={recipientState.contactMethod} isProfilePage={isProfilePage} userId={recipientState.selectedRecipient ?? ''} />,
      value: RadioButtons.DEFAULT,
    },
    {
      label: getCustomLabel(recipientState.contactMethod),
      value: RadioButtons.CUSTOM,
    },
  ];

  useEffect(() => {
    const newRecipient = generateRecipient(contactDetail, selectedLocale, selectedTimeZone, recipientState);
    if (newRecipient) getRecipientState(newRecipient);

    if (recipient.type !== RecipientType.INTERNAL_INDIVIDUAL_USER && recipient.type !== RecipientType.EXTERNAL) return;
    const chanelType = channelTypeToRadioButton(recipient.channel.type as ChannelType.EMAIL | ChannelType.SMS);

    if (!contactDetail && chanelType === recipientState.contactMethod) {
      setContactDetail(getChannelValue(recipient));
    }
  }, [recipientState, selectedLocale, selectedTimeZone, contactDetail]);

  const isInvalid = (): boolean => {
    const { defaultOrCustomContact, notificationGroup, selectedRecipient, user } = recipientState;
    const hasValidContact = validator.isEmail(contactDetail ?? '') || validator.isMobilePhone(contactDetail ?? '') && isValidPhoneNumber(contactDetail ?? '');

    if (user === RadioButtons.USER) {
      switch (notificationGroup) {
        case RadioButtons.INDIVIDUAL: return !selectedRecipient || (defaultOrCustomContact !== RadioButtons.DEFAULT && !hasValidContact);
        case RadioButtons.GROUP: return !selectedRecipient;
      }
    }

    return !hasValidContact;
  };

  useEffect(() => setRecipientIsValid(!isInvalid()), [recipientState, contactDetail]);

  if (usersError) enqueueSnackbar(<ErrorMessage error={usersError} />, { variant: 'error' });
  if (userGroupsError) enqueueSnackbar(<ErrorMessage error={userGroupsError} />, { variant: 'error' });

  // TODO: TP-7476 Handle cases where recipient type is person/group and channel type is API
  if (recipient.type === RecipientType.INTERNAL_INDIVIDUAL_PERSON || recipient.type === RecipientType.INTERNAL_GROUP) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5">{t('admin:page.create-subscription.notification-recipient.labels.invalid-recipient-type')}</Typography>
        </CardContent>
      </Card>
    );
  } else if ((recipient.type === RecipientType.INTERNAL_INDIVIDUAL_USER || recipient.type === RecipientType.EXTERNAL) && recipient.channel.type === ChannelType.API) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5">{t('admin:page.create-subscription.notification-recipient.labels.invalid-channel-type')}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <NotificationRecipientCardView
      channelDetailHelper={channelDetailHelper}
      contactDetail={contactDetail}
      contactOptions={contactOptions}
      defaultOptions={defaultOptions}
      fieldDisabled={fieldDisabled}
      handleEmailFieldChange={handleEmailFieldChange}
      handleSelectChange={handleSelectChange}
      isChannelDetailInvalid={isChannelDetailInvalid}
      isContactDetailFieldVisible={isContactDetailFieldVisible}
      isContactOptionsVisible={isContactOptionsVisible}
      isProfilePage={isProfilePage}
      notificationGroupOptions={notificationGroupOptions}
      recipients={recipients}
      recipientsError={!!(usersError || userGroupsError)}
      recipientsPending={recipientsPending}
      recipientState={recipientState}
      selectedLocale={selectedLocale}
      selectedTimezone={selectedTimeZone}
      setChannelDetailHelper={setChannelDetailHelper}
      setContactDetail={setContactDetail}
      setIsChannelDetailInvalid={setIsChannelDetailInvalid}
      setRecipientState={setRecipientState}
      setSelectedLocale={setSelectedLocale}
      setSelectedTimezone={setSelectedTimeZone}
      userOptions={userOptions}
    />
  );
};
