import { LastThingEvent } from '@eagle/core-data-types';
import { isString } from '@jexop/core';
import { LastThingEventsByFeature } from '../hooks/use-last-thing-state';

export const getLastEvents = (context: any): LastThingEventsByFeature | null => {
  if (!context || typeof context !== 'object') return null;

  const lastEvents = (context as { lastEvents: LastThingEventsByFeature }).lastEvents;
  if (!lastEvents || typeof lastEvents !== 'object') return null;
  return lastEvents;
};

export const stringWithDefault = (value: unknown, defaultValue: string): string => {
  if (isString(value)) return value || defaultValue;
  return defaultValue;
};

export const flattenLastEvents = (lastEvents: LastThingEventsByFeature): LastThingEvent[] => Object.entries(lastEvents).reduce<LastThingEvent[]>(
  (acc1, [, featureEvents]) =>
    Object.entries(featureEvents).reduce<LastThingEvent[]>(
      (acc2, [, events]) => [...acc2, events],
      acc1,
    ),
  [],
);

export const filterEvents = (
  feature: unknown,
  flat: LastThingEvent[],
): LastThingEvent[] | null => {
  if (!feature) return flat;

  if (isString(feature)) {
    const featureId = feature.split('/')[0];
    return flat.filter((evt) => evt.feature.split('/')[0] === featureId);
  }

  if (Array.isArray(feature)) {
    const featureIds = new Set(feature.filter((f) => isString(f)).map(((f: string) => f.split('/')[0])));
    return flat.filter((evt) => featureIds.has(evt.feature.split('/')[0]));
  }

  return null;
};
