import { Time } from '@eagle/common';
import { Theme, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import Axios, { AxiosResponse } from 'axios';
import L from 'leaflet';
import 'leaflet.markercluster';
import { debounce } from 'lodash';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { IncidentIcons } from '../../../components';
import { useConfig, useInterval } from '../../../hooks';
import { getBoundingBox, getIcon } from '../../../util';
import { IncidentDetails, IncidentResult } from './incident-layer.types';

const DiamondIcon: FC<PropsWithChildren & { sx?: SxProps; theme: Theme }> = ({ children, sx, theme }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: theme.incident.clusterBackgroundColor,
        display: 'flex',
        height: theme.incident.size,
        justifyContent: 'center',
        m: 0,
        outline: theme.incident.clusterBorder,
        p: 0,
        textAlign: 'center',
        transform: 'rotate(45deg)',
        width: theme.incident.size,
        ...sx,
      }}
    >
      <Box sx={{ m: 0, p: 0, transform: 'rotate(-45deg)' }}>
        {children}
      </Box>
    </Box>
  );
};

const createIncidentMarker = (cluster: L.MarkerClusterGroup, position: L.LatLng, { summary, type }: IncidentDetails): void => {
  const icon = (): JSX.Element => {
    switch (type) {
      case 'accident': return <IncidentIcons.Accident />;
      case 'construction': return <IncidentIcons.Construction />;
      case 'congestion': return <IncidentIcons.Congestion />;
      case 'disabledVehicle': return <IncidentIcons.DisabledVehicle />;
      case 'plannedEvent': return <IncidentIcons.PlannedEvent />;
      case 'roadClosure': return <IncidentIcons.RoadClosed />;
      case 'weather': return <IncidentIcons.Weather />;
      default: return <IncidentIcons.Other />;
    }
  };
  const marker = new L.Marker(
    position,
    {
      title: summary.value,
      icon: getIcon(icon()),
    },
  );
  marker.bindTooltip(summary.value);
  cluster.addLayer(marker);
};

export const IncidentLayer: FC = () => {
  const config = useConfig();
  const map = useMap();
  const polylineLayer = useRef(L.layerGroup());
  const theme = useTheme();
  const THREE_MINUTES = Time.minutes(3);
  const clusterGroup = useRef(new L.MarkerClusterGroup(
    {
      maxClusterRadius: 20,
      iconCreateFunction: (cluster) => getIcon(<DiamondIcon theme={theme}><span>{cluster.getChildCount()}</span></DiamondIcon>),
    },
  ));

  const clearMap = useCallback(() => {
    polylineLayer.current.clearLayers();
    clusterGroup.current.clearLayers();
  }, []);

  const createFindIncidents = useCallback(() => {
    const cancelToken = Axios.CancelToken.source();

    return {
      fn: () => {
        const getIncidents = (): Promise<AxiosResponse<IncidentResult>> => {
          const bounds = getBoundingBox(map);
          const params = {
            apiKey: config.hereMaps?.apiKey,
            in: bounds.toHereMapsIn(),
            locationReferencing: 'shape',
          };
          return Axios.get<IncidentResult>('https://data.traffic.hereapi.com/v7/incidents', { params, cancelToken: cancelToken.token });
        };

        if (!getBoundingBox(map).isInBounds(1)) return clearMap();
        getIncidents()
          .then(({ data }) => {
            clearMap();
            if (!getBoundingBox(map).isInBounds(1)) return;
            data.results.map(({ incidentDetails, location }) => {
              location.shape.links.map(({ points }, i) => {
                const incidentPoints = points.map(({ lat, lng }) => new L.LatLng(lat, lng));
                const polyline = L.polyline(incidentPoints, { color: theme.incident.roadColor });
                polylineLayer.current.addLayer(polyline);
                if (i === 0) createIncidentMarker(clusterGroup.current, incidentPoints[0], incidentDetails);
              });
            });
          }).catch((error) => {
            if (Axios.isCancel(error)) return;
            console.error(error);
          });
      },
      cancelToken,
    };
  }, [clearMap, map, config.hereMaps?.apiKey, theme.incident.roadColor]);

  useInterval(useMemo(() => createFindIncidents(), [createFindIncidents]).fn, THREE_MINUTES);

  useEffect(() => {
    const findIncidents = createFindIncidents();

    findIncidents.fn();

    const debouncedFindIncidents = debounce(findIncidents.fn, 1000);
    map.on('zoomend', debouncedFindIncidents);
    map.on('moveend', debouncedFindIncidents);
    map.addLayer(clusterGroup.current);
    return () => {
      clearMap();
      map.off('zoomend', debouncedFindIncidents);
      map.off('moveend', debouncedFindIncidents);
      debouncedFindIncidents.cancel();
      findIncidents.cancelToken.cancel();
    };
  }, [createFindIncidents, map, clearMap]);

  return <></>;
};
