import { AlertResponse } from '@eagle/core-data-types';
import { FeatureTypes } from '@eagle/data-function-types';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getAlertTypeStyle } from '../../util';
import { getEventFeatureV2, getEventHint, getEventLabelV2, getEventTranslation } from '../../util/events';
import { useBoolFlag } from '../flags';
import { LabelledBadge } from '../labelled-badge';

interface AlertLabelProps {
  align?: 'left' | 'right' | 'center';
  alert: AlertResponse;
}

export const AlertLabel: FC<AlertLabelProps> = ({ alert, align = 'left' }) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const alertStyles = getAlertTypeStyle(alert, theme);
  const isHintEnabled = useBoolFlag('portals-event-translation-hints-feature');
  const flagUseEventV3 = useBoolFlag('portals-translation-retrieval-hook-for-event-and-alert-descriptions-feature');

  const { label, hint, featureLabel } = flagUseEventV3 ? getEventTranslation(alert.feature, alert.alertTypeId) : { label: getEventLabelV2(alert.featureTypeId, alert.alertTypeId), hint: getEventHint(alert.feature, alert.alertTypeId), featureLabel: getEventFeatureV2(alert.feature) };
  const suppressionIcon = alert.suppressed ? <NotificationsOffIcon /> : undefined;
  const suppressionLabel = alert.suppressed ? t('common:component.alerts-table.hint.suppression') : undefined;
  const primary = alert.featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? featureLabel : label;
  const subtitle = alert.featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? label : featureLabel;
  const tooltipText = alert.suppressed ? suppressionLabel : isHintEnabled && hint ? hint : undefined;

  return (
    <LabelledBadge
      align={align}
      background={alertStyles.background}
      color={alertStyles.color}
      fullWidth
      icon={suppressionIcon}
      label={primary}
      subtitle={subtitle}
      tooltip={tooltipText}
    />
  );
};
