import { Collapse, Stack } from '@mui/material';
import { FC, useState } from 'react';

interface Props {
  historyDrawerContent: JSX.Element;
  isDrawerOpen: boolean;
  setIsTransitioning: (isTransitioning: boolean) => void;
  twoWayCollapseContent: JSX.Element;
}

const OPEN_WIDTH = '445px !important';
const CLOSED_WIDTH = 75;

export const EventHistoryDesktop: FC<Props> = ({
  historyDrawerContent,
  isDrawerOpen,
  setIsTransitioning,
  twoWayCollapseContent,
}) => {
  const [width, setWidth] = useState(isDrawerOpen ? OPEN_WIDTH : 'auto');

  return (
    <Stack direction="row" sx={{ height: '100%', width: '100%' }}>
      <Collapse
        collapsedSize={CLOSED_WIDTH}
        in={isDrawerOpen}
        onEnter={() => setWidth(OPEN_WIDTH)}
        onEntered={() => setIsTransitioning(false)}
        onEntering={() => setIsTransitioning(true)}
        onExited={() => setIsTransitioning(false)}
        onExiting={() => {
          setWidth('auto');
          setIsTransitioning(true);
        }}
        orientation="horizontal"
        sx={{
          boxShadow: 4,
          overflowX: 'inherit',
          overflowY: 'auto',
          scrollbarWidth: 'none',
          width,
          zIndex: 500,
          '& .MuiCollapse-wrapper': {
            justifyContent: 'center',
          },
          '& .MuiCollapse-wrapperInner': {
            width: !isDrawerOpen ? 'auto' : '100%',
          },
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {historyDrawerContent}
      </Collapse>
      {twoWayCollapseContent}
    </Stack>
  );
};
