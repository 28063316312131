/* eslint-disable react-hooks/exhaustive-deps */
import { ThingEventSnapshot } from '@eagle/core-data-types';
import * as turf from '@turf/turf';
import { throttle } from 'lodash';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-leaflet';
import Supercluster, { PointFeature } from 'supercluster';
import useSupercluster from 'use-supercluster';
import { MAP_MAX_ZOOM } from '../../../../constants';
import { Maybe } from '../../../../types';
import { EventLocationData, ThingEventProperties } from '../../thing-event-pane/thing-event-pane.types';
import MarkerClusterItem from './marker-cluster-item';
import { closestPointOutsideDistance } from './util';

interface Props {
  clusterRadius?: number;
  handleBreadcrumbClick: (index: number, items: ThingEventSnapshot[]) => void;
  hoveredEventId: Maybe<string>;
  isSelected: boolean;
  isVisible: boolean;
  nearestPoints: turf.Feature<turf.Point, ThingEventProperties>[];
  setHoveredEventId: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
}

const DISTANCE_THRESHOLD = 0.01;

const MarkerClusters: FC<Props> = ({
  clusterRadius = 120,
  handleBreadcrumbClick,
  hoveredEventId,
  isSelected,
  isVisible,
  nearestPoints,
  setHoveredEventId,
  setSelectedEvent,
}) => {
  const map = useMap();

  const initialPoint = nearestPoints.length > 0 ? nearestPoints[0] : undefined;
  const headingTo = useMemo(() => closestPointOutsideDistance(nearestPoints, DISTANCE_THRESHOLD), [nearestPoints]);
  const calculatedHeading = useMemo(() => {
    return initialPoint && headingTo ? turf.bearing(turf.point([initialPoint.geometry.coordinates[0], initialPoint.geometry.coordinates[1]]), turf.point([headingTo.geometry.coordinates[0], headingTo.geometry.coordinates[1]])) : 0;
  }, [initialPoint, headingTo]);

  const [bounds, setBounds] = useState<turf.BBox>([
    map.getBounds().getWest(),
    map.getBounds().getSouth(),
    map.getBounds().getEast(),
    map.getBounds().getNorth(),
  ]);

  const { clusters, supercluster } = useSupercluster({
    bounds,
    options: { radius: clusterRadius, maxZoom: MAP_MAX_ZOOM },
    points: nearestPoints,
    zoom: map.getZoom(),
  });

  const updatePointsThrottled = useCallback(throttle((): void => {
    setBounds([
      map.getBounds().getWest(),
      map.getBounds().getSouth(),
      map.getBounds().getEast(),
      map.getBounds().getNorth(),
    ]);
  }, 300), [map]);

  useEffect(() => {
    map.on('zoomend moveend', updatePointsThrottled);

    return () => {
      map.off('zoomend moveend', updatePointsThrottled);
      updatePointsThrottled.cancel();
    };
  }, [map, updatePointsThrottled]);

  useEffect(() => {
    if (isVisible) {
      updatePointsThrottled();
    }
  }, [isVisible, updatePointsThrottled]);

  if (!isSelected || !isVisible || nearestPoints.length === 0) return <></>;

  return (
    <>
      {clusters.map((item, i) => (
        <MarkerClusterItem
          key={`item-${i}`}
          calculatedHeading={calculatedHeading}
          cluster={item as PointFeature<ThingEventProperties & Supercluster.AnyProps>}
          eventsLength={nearestPoints.length}
          handleBreadcrumbClick={handleBreadcrumbClick}
          hoveredEventId={hoveredEventId}
          setHoveredEventId={setHoveredEventId}
          setSelectedEvent={setSelectedEvent}
          supercluster={supercluster}
        />
      ))}
    </>
  );
};

export default React.memo(MarkerClusters);
