import { Box, Container, Stack } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface Props extends PropsWithChildren {
  actions: ReactNode;
  pageSubtitle: ReactNode;
  'data-testid'?: string;
}

const LayoutMobile: FC<Props> = ({ actions, children, ...props }) => {
  return (
    <Box id="create-page" display="flex" flexDirection="column" sx={{ overflowY: 'auto', width: '100%' }}>
      <Container sx={{ flexGrow: 1 }} data-testid={props['data-testid']}>
        <Stack id="content" className="detail-content" display="flex" flexDirection="column" flexShrink={1} spacing={2} sx={{ my: 2 }}>
          {children}
        </Stack>
      </Container>
      {actions}
    </Box>
  );
};

export default LayoutMobile;
