import { ElementType, FC } from 'react';
import { useDynamicModule } from '../../hooks';

export interface DynamicComponentProps {
  component: string;
  [key: string]: string | number;
}

export const DynamicComponent: FC<DynamicComponentProps> = ({ component, ...props }) => {
  const { module: components, loaded } = useDynamicModule<{ [name: string]: ElementType }>('dynamic-components', {});

  if (!loaded || !component || !components || !components[component]) {
    return <></>;
  }
  const Container = components[component];
  return <Container {...props} />;
};
