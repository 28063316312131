
export enum DrawerSize {
  CLOSED = 50,
  FULL_SCREEN = window.innerHeight - 50,
  OPEN = window.innerHeight / 2,
}

export enum MobileTabSelection {
  TOP,
  LEFT,
  BOTTOM,
}

export interface TwoWayControllerProps {
  bottomDrawerContent: JSX.Element;
  topDrawerContent: JSX.Element;
}

export interface EventHistoryAction {
  icon: JSX.Element;
  label?: string;
  onClick: () => void;
  showIcon: boolean;
}
