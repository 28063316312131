import cx from 'classnames';
import { FC } from 'react';
import DeletedIcon from 'remixicon-react/InformationLineIcon';
import { makeStyles } from '../../theme';
import { CommonEntityWithDeleted, DisplayWithDeleted } from '../../types';
import { BoldMatchedText, testid } from '../../util';

interface Props {
  data: DisplayWithDeleted;
  'data-testid'?: string;
}

export const useSimpleElementStyles = makeStyles()({
  deleted: {
    textDecoration: 'line-through',
  },
  root: {},
});

export const SimpleElement: FC<Props> = ({ data, ...props }) => {
  const { classes } = useSimpleElementStyles();
  return (
    <span data-testid={props['data-testid'] ?? testid`${data.display}`} className={cx(classes.root, { [classes.deleted]: data.deleted })}>
      {data.display}
      {data.deleted ? <>&nbsp;<DeletedIcon size="1rem" /></> : null}
    </span>
  );
};

export const SimpleElementSearch: FC<Props> = ({ data, ...props }) => {
  const { classes } = useSimpleElementStyles();
  return (
    <span data-testid={props['data-testid'] ?? testid`${data.display}`} className={cx(classes.root, { [classes.deleted]: data.deleted })}>
      <BoldMatchedText text={data.display} />
      {data.deleted ? <>&nbsp;<DeletedIcon size="1rem" /></> : null}
    </span>
  );
};

export const simpleRenderer = (data: CommonEntityWithDeleted<string | number>): JSX.Element => <SimpleElement key={data._id} data={data} />;
export const simpleLineRenderer = (data: CommonEntityWithDeleted<string | number>): JSX.Element => <div key={data._id}><SimpleElement data={data} /></div>;
