import { Operator } from '@jexop/core';
import { isDate, isString } from 'lodash';
import { DateTime } from 'luxon';
import { Nullable } from '../types';

export const formatTimestamp: Operator<string> = ({value, format, zone, locale}): Nullable<string>  => {
  if (!value || (!isString(value) && !isDate(value))) {
    return null;
  }

  const timezone = (!zone || !isString(zone)) ? 'local' : zone;

  const timeLocale = (!locale || !isString(locale)) ? navigator.language : locale;

  const timestamp = typeof value === 'string'
    ? DateTime.fromISO(value, { zone: timezone, locale: timeLocale })
    : DateTime.fromJSDate(value, { zone: timezone }).setLocale(navigator.language);

  let formattedTime;
  switch (format) {
    case 'short':
      formattedTime = timestamp.toLocaleString(DateTime.DATETIME_SHORT);
      break;
    case 'dateFull':
      formattedTime = timestamp.toLocaleString(DateTime.DATE_FULL);
      break;
    case 'shortWithSeconds':
      formattedTime = timestamp.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      break;
    case 'long':
      formattedTime = timestamp.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
      break;
    case 'timeWithSeconds':
      formattedTime = timestamp.toLocaleString(DateTime.TIME_WITH_SECONDS);
      break;
    default:
      formattedTime = timestamp.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
      break;
  }
  return formattedTime;
};
