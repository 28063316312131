import { Typography } from '@mui/material';
import { FC } from 'react';

export const ErrorTitle: FC<{ error: Error }> = ({ error }) => {
  // TODO TP-5239 Error translations
  return <>{error.name}</>;
};

export const ErrorMessage: FC<{ error: Error }> = ({ error }) => {
  // TODO TP-5239 Error translations
  return <>{error.message}</>;
};

export const ErrorText: FC<{ error: Error }> = ({ error }) => (
  <Typography color="text.secondary" fontStyle="italic" variant="body2" sx={{ mx: 3 }}>
    <ErrorMessage error={error} />
  </Typography>
);
