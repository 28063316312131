/* eslint-disable react-hooks/exhaustive-deps */
// from https://github.com/bsonntag/react-use-promise/blob/main/src/index.js

import { useEffect, useReducer } from 'react';
import { Undefinable } from '../types';

const resolvePromise = <T>(promiseOrFactory: (() => Promise<T>) | Promise<T>): Undefinable<Promise<T>> => {
  if (typeof promiseOrFactory === 'function') {
    return promiseOrFactory();
  }

  return promiseOrFactory;
};

const defaultState: State<any> = {
  error: undefined,
  result: undefined,
  state: 'pending',
};

export type Action<T> = {
  type: 'pending';
  payload?: T;
} | {
  type: 'resolved';
  payload: T;
} | {
  type: 'rejected';
  payload: Error;
}

export type State<T> = {
  error: undefined;
  result?: T;
  state: 'pending';
} | {
  error: undefined;
  result: T;
  state: 'resolved';
} | {
  error: Error;
  result: undefined;
  state: 'rejected';
}

type PendingState = [undefined, undefined, 'pending'];
type ResolvedState<T> = [T, undefined, 'resolved'];
type RejectedState = [undefined, Error, 'rejected'];

export type Resolution<T> = PendingState | ResolvedState<T> | RejectedState;

export const reducer = <T>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case 'pending':
      return {
        error: undefined,
        result: action?.payload,
        state: 'pending',
      };

    case 'resolved':
      return {
        error: undefined,
        result: action.payload,
        state: 'resolved',
      };

    case 'rejected':
      return {
        error: action.payload,
        result: undefined,
        state: 'rejected',
      };

    /* istanbul ignore next */
    default:
      return state;
  }
};

export const usePromise = <T>(promiseOrFactory: (() => Promise<T>) | Promise<T>, inputs: unknown[]): Resolution<T> => {
  const [{ error, result, state }, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    const promise = resolvePromise(promiseOrFactory);

    if (!promise) {
      return;
    }

    let canceled = false;

    dispatch({ type: 'pending' });

    promise.then(
      (promiseResult) => !canceled && dispatch({
        payload: promiseResult,
        type: 'resolved',
      }),
      (promiseError: Error) => !canceled && dispatch({
        payload: promiseError,
        type: 'rejected',
      }),
    );

    return () => {
      canceled = true;
    };
  }, inputs);

  return [result, error, state] as Resolution<T>;
};

export const usePromiseConditional = <T>(promiseOrFactory: (() => Promise<T>) | Promise<T>, condition: () => boolean, inputs: unknown[]): Resolution<T> => {
  const [{ error, result, state }, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    if (!condition()) return;

    const promise = resolvePromise(promiseOrFactory);

    if (!promise) {
      return;
    }

    let canceled = false;

    dispatch({ type: 'pending' });

    promise.then(
      (promiseResult) => !canceled && dispatch({
        payload: promiseResult,
        type: 'resolved',
      }),
      (promiseError: Error) => !canceled && dispatch({
        payload: promiseError,
        type: 'rejected',
      }),
    );

    return () => {
      canceled = true;
    };
  }, inputs);

  return [result, error, state] as Resolution<T>;
};
