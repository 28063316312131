import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/system';
import { FC } from 'react';
import { FlexBox } from '../flex-box';
import { InternalProps } from './types';

const Row = styled((props: StackProps) => (
  <Stack direction="row" spacing={2} {...props} />
))({
  alignItems: 'center',
});

export const Layout: FC<InternalProps> = ({ listContent, paginationComponent, search }) => {
  return (
    <FlexBox className="ListPage-root" sx={{ overflowY: 'auto' }}>
      <Container>
        <Stack direction="column" spacing={1} sx={{ py: 1 }}>
          <Row sx={{ lineHeight: '2rem' }}>
            {search}
          </Row>
          <Stack spacing={2} sx={{ py: 1 }}>
            <Box>
              {listContent}
            </Box>
            {paginationComponent
              ? <Box display="flex" sx={{ justifyContent: 'center' }}>
                {paginationComponent}
              </Box>
              : null
            }
          </Stack>
        </Stack>
      </Container>
    </FlexBox>
  );
};
