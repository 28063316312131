import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface FormatUnitProps {
  unit: string;
  value: string | number;
}

export const FormatUnit: FC<FormatUnitProps> = ({ value, unit }) => {
  const { t } = useTranslation(['common']);

  if (value === '' || value === null) return null;

  const numericValue = typeof value === 'string' ? parseFloat(value) : value;

  const formattedValue = numericValue.toLocaleString(undefined, {
    minimumFractionDigits: numericValue % 1 !== 0 ? 2 : 0,
    maximumFractionDigits: 2,
  });

  let formattedUnit;

  switch (unit) {
    case 'km':
      formattedUnit = `${formattedValue} ${t('common:page.thing-detail.service-history-dialog.odometer.suffix.km.labels')}`;
      break;
    case 'hours':
      formattedUnit = `${formattedValue} ${t('common:page.thing-detail.service-history-dialog.engine-hours.suffix.hours.labels')}`;
      break;
    // TODO: Add more cases for additional units as needed
    default:
      formattedUnit = `${formattedValue} ${unit}`;
  }

  return <>{formattedUnit.toLowerCase()}</>;
};

export default FormatUnit;
