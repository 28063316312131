/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import { FC, Fragment, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route } from 'react-router-dom';
import { useConfig } from '../../hooks';
import { defaultRouteDefinitions } from '../../hooks/use-custom-routes';
import { NotFound } from '../../pages';
import { ErrorBoundary } from '../error-boundary';
import { ErrorMessageWithDetails } from '../error-message';
import { Redirect } from '../redirect';
import { SentryRoutes } from '../sentry';
import { GoogleAnalyticsTrackingWrapper } from '../wrapper';

interface RouteInfo {
  icon?: JSX.Element;
  key?: string;
  page: JSX.Element;
  path: string;
  subText?: string;
  text?: string;
}

interface Props extends PropsWithChildren {
  indexRedirect?: string;
  routes: RouteInfo[];
  className?: string | undefined;
}

export const PageContent: FC<Props> = ({ children, indexRedirect, routes, className }) => {
  const { ga, portals: portalsConfig } = useConfig();

  const replacePath = (path: string): string => {
    const pathArr = path.split('/').slice(1);
    const customRouteConfig = portalsConfig?.routeDefinition ?? {};
    const pathKey = Object.keys(customRouteConfig).find((key) => customRouteConfig[key] === pathArr[0]);
    if (!pathKey) return path;

    const defaultUrl = `/${defaultRouteDefinitions[pathKey]}${pathArr?.[1] ? `/${pathArr[1]}` : ''}${pathArr?.[2] ? `/${pathArr[2]}` : ''}`;
    return defaultUrl;
  };

  const content = useMemo(() => (
    <SentryRoutes>
      {routes.map((route, index) => {
        return <Fragment key={index}>
          <Route
            element={
              <GoogleAnalyticsTrackingWrapper options={ga}>
                <ErrorBoundary key={route.key ?? route.path} fallback={({ error }) => <PageErrorBoundaryFallback details={error.message} />}>
                  {route.page}
                </ErrorBoundary>
              </GoogleAnalyticsTrackingWrapper>
            }
            path={route.path}
          />
          <Route
            element={<Redirect to={route.path} />}
            path={replacePath(route.path)}
          />
        </Fragment>;
      })};
      {indexRedirect
        ? <Route
          key={'index-redirect'}
          element={<Navigate to={indexRedirect} />}
          path="/"
        />
        : undefined
      }
      <Route key="not-found" path="*" element={<NotFound />} />
    </SentryRoutes>
  ), [ga, routes]);

  return <>
    {children}
    <Box sx={{ height: 1, display: 'flex', overflow: 'auto' }} className={className}>
      {content}
    </Box>
  </>;
};

const PageErrorBoundaryFallback: FC<{ details: string }> = ({ details }) => {
  const { t } = useTranslation(['common']);

  return (
    <Box sx={{ maxWidth: 600, p: 2, width: '100%' }}>
      <ErrorMessageWithDetails
        details={details}
        message={t('page.errors.system-error.title')}
        withBackButton
      />
    </Box>
  );
};
