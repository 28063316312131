import { useState } from 'react';
import { LOOKUP_DEBOUNCE_TIME } from '../../constants';
import { Maybe } from '../../types/common';
import { CommonEntityWithDeleted } from '../../types/entity';
import { EntityField, SelectedFilterType } from '../entity-search/types';
import { Lookup } from '../lookup';
import { simpleRenderer } from '../simple-element';

interface Props<T> {
  appliedFilters: EntityField[];
  'data-testid'?: string;
  findEntity: (search: string) => Promise<T[]>;
  isSearch?: boolean;
  label?: string;
  noResults?: string;
  onChanged: (value: SelectedFilterType) => void;
}

/**
  * NATIVE FUNCTION: Filter lookup component - single input
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function FilterInputLookupSingle<T extends CommonEntityWithDeleted>({
  appliedFilters,
  findEntity,
  isSearch,
  label,
  noResults,
  onChanged,
  ...props
}: Props<T>): JSX.Element {
  const [selected, setSelected] = useState<T>();

  const handleChange = (value: Maybe<T>): void => {
    if (!value) return;
    setSelected(value);
    const newValue: EntityField = { display: value.display, id: value._id };
    onChanged([...appliedFilters, newValue]);
  };

  return (
    <Lookup<T>
      data-testid={props['data-testid']}
      debounceTime={isSearch ? LOOKUP_DEBOUNCE_TIME : 0}
      handleCompareItems={(option, value) => option._id === value._id}
      handleFindItems={findEntity}
      handleFormatListItem={simpleRenderer}
      handleFormatSelectedItem={(option) => option.display}
      isSearch={isSearch}
      label={label}
      noResultsInstructions={noResults}
      onItemSelected={handleChange}
      selectedItem={selected ?? null}
      size="small"
    />
  );
}
