import { DesktopDatePicker, PickersDay } from '@mui/lab';
import { TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable, Undefinable } from '../../types';
import { testid } from '../../util';
import { DateErrors, DateTimeRangeError, getDateFormatString } from '../../util/date';

interface Props {
  'data-testid'?: string;
  date?: DateTime;
  defaultTime: DateTime;
  error?: string;
  label: string;
  setDate: (date?: DateTime) => void;
  setDateError: (error?: DateTimeRangeError) => void;
  setTime: (date?: DateTime) => void;
  setTimeError: (error?: DateTimeRangeError) => void;
}

export const DateTimeRangeDatePicker: FC<Props> = ({
  date,
  defaultTime,
  error,
  label,
  setDate,
  setDateError,
  setTime,
  setTimeError,
  ...props
}): JSX.Element => {
  const { t } = useTranslation(['common']);
  const dateFormat = getDateFormatString();

  const handleDateChange = (_: Nullable<string>, input: Undefinable<string>): void => {
    if (input) {
      const inputDateTime = DateTime.fromFormat(input, dateFormat);

      if (inputDateTime.invalidReason === DateErrors.UNPARSABLE) {
        setDateError(DateTimeRangeError.INVALID_FORMAT);
        return;
      }

      if (inputDateTime.isValid) {
        if (inputDateTime <= DateTime.now()) {
          const { hour, minute, second } = defaultTime;
          setDate(inputDateTime);
          setTime(inputDateTime.set({ hour, minute, second }));
          setDateError(undefined);
          setTimeError(undefined);
        } else {
          setDateError(DateTimeRangeError.FUTURE_DATE);
        }
      }
    } else {
      setDateError(DateTimeRangeError.INVALID_DATE);
    }
  };
  return (
    <DesktopDatePicker
      disableOpenPicker
      label={label}
      inputFormat={dateFormat}
      mask=""
      onChange={handleDateChange}
      renderDay={(d, _selectedDates, pickersDayProps) => <PickersDay data-testid={testid`day-${d}`} {...pickersDayProps} />}
      renderInput={(params) =>
        <TextField
          {...params}
          data-testid={props['data-testid']}
          error={false}
          FormHelperTextProps={{ sx: { m: 0, minHeight: '20px', textAlign: 'end' } }}
          helperText={<Typography variant="caption" sx={{ color: 'red' }}>{error && t(`common:component.date-picker.hint.${error}`)}</Typography>}
          size="small"
        />
      }
      value={date?.toJSDate() ?? null}
    />
  );
};
