import { DatePicker as MUIDatePicker, LocalizationProvider, PickersDay } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DialogProps, IconButtonProps, PopperProps, TextField } from '@mui/material';
import * as Locales from 'date-fns/locale';
import { DateTime } from 'luxon';
import { ElementType, FC, ReactNode } from 'react';
import { Nullable } from '../../types';
import { getDateFormatString, testid } from '../../util';

interface Props {
  'data-testid'?: string;
  date?: Nullable<DateTime>;
  disabled?: boolean;
  label?: string;
  onChange?: (date: Nullable<DateTime>) => void;
  OpenPickerIcon?: ElementType;
  OpenPickerButtonProps?: IconButtonProps;
  PopperProps?: Partial<PopperProps>;
  DialogProps?: Partial<DialogProps>;
  placeholder?: string;
  disableFuture?: boolean;
  required?: boolean;
  size?: 'small' | 'medium';
  helperText?: ReactNode;
  dateError?: string;
  desktopModeMediaQuery?: string | undefined;
  onClose?: () => void;
}

export const DatePicker: FC<Props> = ({ date, onChange, OpenPickerIcon, size = 'small', helperText, dateError, onClose, ...props }): JSX.Element => {
  const dateFormat = getDateFormatString();
  const browserLocale = navigator.language.replace('-', '');
  const locale = Locales[browserLocale as keyof typeof Locales];

  const handleDateChange = (selectedDate: Nullable<Date>, keyboardInput?: string): void => {
    const { hour, minute, second } = DateTime.now();
    const value = keyboardInput
      ? DateTime.fromFormat(keyboardInput, dateFormat)
      : selectedDate ? DateTime.fromJSDate(selectedDate) : null;
    onChange?.(value?.set({ hour, minute, second }) ?? null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
      <MUIDatePicker
        components={{ OpenPickerIcon }}
        inputFormat={dateFormat}
        mask=""
        onChange={handleDateChange}
        renderDay={(d, _selectedDates, pickersDayProps) => <PickersDay data-testid={testid`day-${DateTime.fromJSDate(d).day}`} {...pickersDayProps} />}
        renderInput={(params) => <TextField
          {...params}
          size={size}
          helperText={helperText}
          error={!!dateError}
          sx={{
            '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
              paddingRight: '4px',
            },
          }}
        />}
        value={date?.toLocal().toJSDate() ?? null}
        onClose={onClose}
        {...props}
      />
    </LocalizationProvider>
  );
};
