/* eslint-disable react-hooks/exhaustive-deps */
// cspell: ignore geocode
import { Location } from '@eagle/data-function-types';
import { Skeleton, useTheme } from '@mui/material';
import axiosStatic from 'axios';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { from, of, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { useAuthenticated } from '../../auth';
import { useObservable } from '../../hooks';
import { ErrorMessage } from '../error-message/error-message';
import { FormatAddress, FormatAddressProps } from '../format';

interface FindLocationDeferredResult {
  cancel: () => unknown;
  promise: Promise<string[]>;
}

interface Props extends Omit<FormatAddressProps, 'value'> {
  dataLoaded?: () => void;
  loadingComponent?: JSX.Element;
  location?: Location;
}

export const EventAddress: FC<Props> = ({
  addressUnavailableText,
  dataLoaded,
  loadingComponent,
  location,
  variant,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { restClient } = useAuthenticated();
  const [addressError, setAddressError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const onError = (error: Error): void => {
    enqueueSnackbar(<ErrorMessage error={error} />, { variant: 'error' });
    setAddressError(error);
  };

  const findLocation = ({ latitude: lat, longitude: long }: Location): FindLocationDeferredResult => {
    const cancelToken = axiosStatic.CancelToken.source();

    return {
      cancel: () => cancelToken.cancel(),
      promise: restClient.reverseGeocode.get({
        lat,
        long,
      }).then((response) => response.address),
    };
  };

  const { handleQueryChanged, observable } = useMemo(() => {
    const eventSubject = new Subject<Location>();

    return {
      handleQueryChanged: (query: Location) => eventSubject.next(query),
      observable: eventSubject.pipe(
        tap(() => setIsLoading(true)),
        debounceTime(350),
        switchMap((locationData: Location) => {
          if (!locationData?.latitude || !locationData?.longitude) return of(undefined);
          if (locationData.address) return of(locationData.address);
          const deferred = findLocation(locationData);
          if (!deferred) return of(undefined);

          let complete = false;
          return from(deferred.promise).pipe(
            tap(() => {
              setAddressError(undefined);
              complete = true;
            }),
            catchError((err: Error) => {
              onError(err);
              return of(undefined);
            }),
            finalize(() => complete || deferred.cancel()),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, []);

  const address = useObservable(observable, onError);

  useEffect(() => {
    if (!location) return;
    handleQueryChanged(location);
  }, [location?.latitude, location?.longitude, location?.address]);
  // Prevents address from reloading every time map is moved

  useEffect(() => {
    if (isLoading) return;
    dataLoaded?.();
  }, [address, dataLoaded, isLoading]);

  if (isLoading) return loadingComponent ?? <Skeleton animation="wave" width="100%" variant="text" sx={{ fontSize: '1rem', bgcolor: theme.palette.grey[500] }} />;
  if (!address?.length || addressError) return <FormatAddress addressUnavailableText={addressUnavailableText} />;

  return (
    <FormatAddress
      addressUnavailableText={addressUnavailableText}
      data-testid={props['data-testid']}
      sx={props.sx}
      value={address}
      variant={variant}
    />
  );
};
