/* eslint-disable react-hooks/exhaustive-deps */
import { ExpandMore } from '@mui/icons-material';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import validator from 'validator';
import { Nullable } from '../../types';
import { Accordion, AccordionDetails, AccordionSummary } from '../accordion';
import { UserBasicType } from './types';
import { UserBasicItem } from './user-basic-item';
import { UserBasicTitle } from './user-basic-title';

interface Props {
  anotherUserLabel: string;
  disabled: boolean;
  fields: UserBasicType[];
  hint: string;
  onValidation?: (value: boolean) => void;
  setFields: (users: UserBasicType[]) => void;
  showDisplay?: boolean;
  showRemove?: boolean;
  title: ReactNode;
}

export const MultipleUserBasicEditor: FC<Props> = ({
  anotherUserLabel,
  disabled,
  fields,
  hint,
  onValidation,
  setFields,
  showDisplay,
  showRemove,
  title,
}) => {
  const [expanded, setExpanded] = useState<string | false>('panel0');

  const handlePanelChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (value: string, index: number, key: string): Promise<void> => {
    const newBasic = [...fields];
    newBasic[index][key] = value;

    setFields(newBasic);
    return Promise.resolve();
  };

  const handleAdd = (): void => {
    setFields([
      ...fields,
      {
        display: '',
        email: '',
        notificationEmail: '',
        notificationPhone: '',
      },
    ]);
  };

  const handleRemove = (index: number): Promise<void> => {
    const newBasic = [...fields];
    newBasic.splice(index, 1);
    setFields(newBasic);
    return Promise.resolve();
  };

  const renderAnotherUserButton = (): Nullable<JSX.Element> => {
    if (fields.length > 10) return null;

    return (
      <Stack alignItems="flex-end">
        <Button onClick={handleAdd}>
          {anotherUserLabel}
        </Button>
      </Stack>
    );
  };

  useEffect(() => {
    const emailValidCheck = fields.every((value) => value.email.trim() !== '' && validator.isEmail(value.email));
    const displayValidCheck = !showDisplay || fields.every((field) => field.display.trim() !== '');
    onValidation?.(emailValidCheck && displayValidCheck);
  }, [fields, showDisplay, onValidation]);

  return (
    <Stack spacing={2}>
      {title}
      <Typography>{hint}</Typography>
      {fields.map((userItem, index) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handlePanelChange(`panel${index}`)}
            sx={{ boxShadow: 'none' }}
          >
            <AccordionSummary
              id={`panel${index}-header`}
              aria-controls={`panel${index}-content`}
              expandIcon={<ExpandMore />}
              sx={{ '&.Mui-expanded': { minHeight: '3rem', overflow: 'hidden' }, padding: 0 }}
            >
              <UserBasicTitle user={userItem} />
            </AccordionSummary>
            <AccordionDetails>
              <UserBasicItem
                disabled={disabled}
                handleChange={handleChange}
                handleRemove={handleRemove}
                index={index}
                showDisplay={showDisplay}
                showRemove={showRemove ?? fields.length > 1}
                skipValidateEmailEmpty
                userItem={userItem}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Divider />
      {renderAnotherUserButton()}
    </Stack>
  );
};
