import { Thing, ThingType } from '@eagle/core-data-types';
import { Box, Theme, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import L from 'leaflet';
import { FC, useEffect, useState } from 'react';
import { Polyline, useMap } from 'react-leaflet';
import { evaluate } from '../../evaluator';
import { LastThingEvents, LastThingEventsByFeature, useLastThingState, useUiTemplate } from '../../hooks';
import { CacheDataTypes } from '../../types';
import { DEFAULT_MAP_MARKER } from '../../ui-templates';
import { generatePointsCircle } from '../../util';
import { FetchOne, FetchOneOfAll } from '../fetch';
import { MarkerCluster, MarkerPoint } from './page-map.types';

interface Props {
  cluster: MarkerCluster;
  disableMarkerClick?: boolean;
  markers: MarkerPoint[];
}

interface MarkerProps {
  clusterLocation: L.LatLng;
  disableMarkerClick?: boolean;
  fetchState: (thingId: string) => Promise<LastThingEvents | undefined>;
  position: L.LatLng;
  theme: Theme;
  thingId: string;
  template: object;
}

const SpiderMarker: FC<MarkerProps> = ({ clusterLocation, disableMarkerClick, fetchState, position, theme, thingId, template }) => {
  const [lastThingEvents, setLastThingEvents] = useState<LastThingEventsByFeature>();

  useEffect(() => {
    if (!thingId) return;

    setLastThingEvents(undefined);

    fetchState(thingId).then((events) => {
      setLastThingEvents(events?.byFeature);
    }).catch(captureException);
  }, [fetchState, thingId]);

  return (
    <Box>
      <FetchOne id={thingId} dataType={CacheDataTypes.THING} notFoundFactory={() => <></>} renderFactory={(thing: Thing) =>
        <FetchOneOfAll
          id={thing.thingTypeId}
          dataType={CacheDataTypes.THING_TYPE}
          notFoundFactory={() => <></>}
          renderFactory={(thingType: ThingType) => {
            const mapMarker = evaluate(template, { disableMarkerClick, position, entityId: thingId, 'data-testid': `map-marker-${thingId}`, dataType: CacheDataTypes.THING, lastEvents: lastThingEvents, thingType, thing });
            return (<>{mapMarker ?? null}</>);
          }} />}
      />
      <Polyline
        color={theme.cluster.spiderLeg}
        opacity={0.5}
        positions={[clusterLocation, position]}
        weight={1.5}
      />
    </Box>
  );
};

export const SpiderMarkers: FC<Props> = ({ cluster, disableMarkerClick, markers }) => {
  const map = useMap();
  const theme = useTheme();
  const [fetchState] = useLastThingState();
  const [lng, lat] = cluster.geometry.coordinates;
  const clusterLocation = new L.LatLng(lat, lng);
  const positions = generatePointsCircle(markers.length, map.latLngToLayerPoint(clusterLocation));
  const { template, loaded: templateLoaded } = useUiTemplate('map-marker', DEFAULT_MAP_MARKER);

  return <>
    {templateLoaded && markers.map(({ properties }, i) => {
      const position = map.layerPointToLatLng(positions[i]);
      return <SpiderMarker key={i} clusterLocation={clusterLocation} disableMarkerClick={disableMarkerClick} fetchState={fetchState} position={position} theme={theme} template={template} thingId={properties.markerThingId} />;
    })}
  </>;
};
