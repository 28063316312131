import { AdasEventTypes, CameraEventTypes, DriverBehaviorEventTypes, DuressEventTypes, EventRecordEventTypes, FaultEventTypes, FeatureTypes, InertiaEventTypes, NumberGaugeEventTypes, SimpleActivityEventTypes, TrackingEventTypes, VehicleSeatEventTypes } from '@eagle/data-function-types';
import { Theme } from '@mui/material';

export enum SimpleActivityFeatures {
  FRIDGE_OPERATION_WARNING = 'fridge-operation-warning',
  SECURITY_ALARM_ALERT = 'security-alarm-alert',
}

export interface EventDefinition {
  featureId: string;
  eventTypeId: string;
  priority: number;
  enabled: boolean;
  ignoreIds?: boolean;
  inheritEventColor?: boolean;
  notableEvent: boolean;
  styling: {
    backgroundColor: string;
    textColor: string;
  };
}

export const getEventsDefinition = (theme: Theme): EventDefinition[] => {
  return [
    {
      featureId: '*',
      eventTypeId: '*',
      enabled: true,
      ignoreIds: true,
      inheritEventColor: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.default,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.INERTIA_V0,
      eventTypeId: InertiaEventTypes.ROLLOVER,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.red,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: 'possible-collision',
      eventTypeId: EventRecordEventTypes.OCCURRENCE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.red,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: 'stationary-vibration',
      eventTypeId: EventRecordEventTypes.OCCURRENCE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DURESS_V0,
      eventTypeId: DuressEventTypes.DURESS_BUTTON_ACTIVATED,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: 'driver-behavior-calibration-failed',
      eventTypeId: EventRecordEventTypes.OCCURRENCE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.red,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.INERTIA_V0,
      eventTypeId: InertiaEventTypes.DANGEROUS_CORNERING,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.orange,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.INERTIA_V0,
      eventTypeId: InertiaEventTypes.RAPID_DECELERATION,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.teal,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.INERTIA_V0,
      eventTypeId: InertiaEventTypes.RAPID_ACCELERATION,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.blue,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: 'abs-activated',
      eventTypeId: EventRecordEventTypes.OCCURRENCE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.red,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: 'vsc-activated',
      eventTypeId: EventRecordEventTypes.OCCURRENCE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.red,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: 'airbag-deployed',
      eventTypeId: EventRecordEventTypes.OCCURRENCE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.red,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.VEHICLE_SEAT_V0,
      eventTypeId: VehicleSeatEventTypes.MOVEMENT_WITHOUT_RESTRAINT_START,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.VEHICLE_SEAT_V0,
      eventTypeId: VehicleSeatEventTypes.MOVEMENT_WITHOUT_RESTRAINT_FINISH,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.TRACKING_V0,
      eventTypeId: TrackingEventTypes.SPEEDING_START,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.TRACKING_V0,
      eventTypeId: TrackingEventTypes.SPEEDING_FINISH,
      enabled: true,
      notableEvent: false,
      styling: {
        backgroundColor: theme.alerts.default,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: DriverBehaviorEventTypes.PHONE_USAGE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: DriverBehaviorEventTypes.OTHER,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: DriverBehaviorEventTypes.YAWN,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: DriverBehaviorEventTypes.SMOKING,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: DriverBehaviorEventTypes.EYES_CLOSED,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: DriverBehaviorEventTypes.MISSING_FACE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: 'monitoring-shelter',
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: DriverBehaviorEventTypes.LOOK_DOWN,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: DriverBehaviorEventTypes.FATIGUE_RISK_DRIVING,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: 'attention-warning-occurred',
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: 'distraction',
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: 'not-wearing-mask',
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: 'sunglasses-detected',
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.DRIVER_BEHAVIOR_V0,
      eventTypeId: 'camera-blocked',
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: 'battery-low',
      eventTypeId: EventRecordEventTypes.OCCURRENCE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.TRACKING_V0,
      eventTypeId: TrackingEventTypes.POWER_ON,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.green,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.TRACKING_V0,
      eventTypeId: TrackingEventTypes.POWER_OFF,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.purple,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.TRACKING_V0,
      eventTypeId: TrackingEventTypes.LOCATION_UPDATE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.palette.grey[400],
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.TRACKING_V0,
      eventTypeId: TrackingEventTypes.NO_LOCATION_UPDATE,
      enabled: false,
      notableEvent: false,
      styling: {
        backgroundColor: theme.alerts.default,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.TRACKING_V0,
      eventTypeId: TrackingEventTypes.INACCURATE_LOCATION_UPDATE,
      enabled: false,
      notableEvent: false,
      styling: {
        backgroundColor: theme.alerts.default,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.TRACKING_V0,
      eventTypeId: TrackingEventTypes.GEOFENCE_ENTER,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.teal,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.TRACKING_V0,
      eventTypeId: TrackingEventTypes.GEOFENCE_LEAVE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.teal,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.TRACKING_V0,
      eventTypeId: TrackingEventTypes.MOTION_START,
      enabled: false,
      notableEvent: false,
      styling: {
        backgroundColor: theme.alerts.default,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.TRACKING_V0,
      eventTypeId: TrackingEventTypes.MOTION_FINISH,
      enabled: false,
      notableEvent: false,
      styling: {
        backgroundColor: theme.alerts.default,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.CAMERA_V0,
      eventTypeId: CameraEventTypes.CAMERA_STATUS,
      enabled: false,
      notableEvent: false,
      styling: {
        backgroundColor: theme.alerts.default,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.CAMERA_V0,
      eventTypeId: CameraEventTypes.VIDEO_STATUS,
      enabled: false,
      notableEvent: false,
      styling: {
        backgroundColor: theme.alerts.default,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.CAMERA_V0,
      eventTypeId: CameraEventTypes.IMAGE_CAPTURED,
      enabled: false,
      notableEvent: false,
      styling: {
        backgroundColor: theme.alerts.default,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.CAMERA_V0,
      eventTypeId: CameraEventTypes.VIDEO_CAPTURED,
      enabled: false,
      notableEvent: false,
      styling: {
        backgroundColor: theme.alerts.default,
        textColor: theme.palette.common.white,
      },
    },
    {
      featureId: FeatureTypes.SIMPLE_ACTIVITY_V0,
      eventTypeId: SimpleActivityEventTypes.START,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.teal,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.SIMPLE_ACTIVITY_V0,
      eventTypeId: SimpleActivityEventTypes.FINISH,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.teal,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.ADAS_V0,
      eventTypeId: AdasEventTypes.PRE_COLLISION_WARNING_OCCURRED,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.ADAS_V0,
      eventTypeId: AdasEventTypes.LANE_DEPARTURE_OCCURRED,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.amber,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.NUMBER_GAUGE_V0,
      eventTypeId: NumberGaugeEventTypes.THRESHOLD_BREACH_SPIKE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.teal,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.NUMBER_GAUGE_V0,
      eventTypeId: NumberGaugeEventTypes.THRESHOLD_BREACH_START,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.teal,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: 'excessive-idle',
      eventTypeId: EventRecordEventTypes.OCCURRENCE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.alerts.teal,
        textColor: theme.palette.text.primary,
      },
    },
    {
      featureId: FeatureTypes.FAULT_V0,
      eventTypeId: FaultEventTypes.UPDATE,
      enabled: true,
      notableEvent: true,
      styling: {
        backgroundColor: theme.palette.common.black,
        textColor: theme.palette.common.white,
      },
    },
  ].map((item, i) => ({ ...item, priority: i + 1 }));
};

export const NOTABLE_EVENTS_RADIUS = 50;
