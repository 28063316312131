import { Person, PersonType, Thing, ThingType } from '@eagle/core-data-types';
import { FC, useMemo } from 'react';
import { FormatProperty } from '../format';
import { ListPaperColumn } from '../list-item';
import { SimpleElement } from '../simple-element';

interface AssignDialogPropertyColumnProps {
  item: Thing | Person;
  type: ThingType | PersonType;
}

export const AssignDialogPropertyColumn: FC<AssignDialogPropertyColumnProps> = ({ item, type }) => {
  const propertyToDisplay = useMemo(() => {
    if ('sharedProperties' in type && 'sharedProperties' in item) {
      const firstSharedProperty = type.sharedProperties.order[0];
      if (firstSharedProperty) {
        return {
          definition: type.sharedProperties.definitions[firstSharedProperty],
          value: item.sharedProperties[firstSharedProperty],
        };
      }
    }
    const firstProperty = type.properties.order[0];
    if (firstProperty) {
      return {
        definition: type.properties.definitions[firstProperty],
        value: item.properties[firstProperty],
      };
    }
  }, [item, type]);

  if (!propertyToDisplay) {
    return null;
  }

  return (
    <ListPaperColumn
      label={<SimpleElement data={{ deleted: null, display: propertyToDisplay.definition.label }} />}
      width="50%"
    >
      <FormatProperty
        definition={propertyToDisplay.definition}
        value={propertyToDisplay.value}
      />
    </ListPaperColumn>
  );
};
