import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Checkbox, FormControlLabel, IconButton, Stack, useTheme } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { makeStyles } from '../../../theme';
import { testid } from '../../../util';
import { OverflowTooltip } from '../../tooltip';

const useStyles = makeStyles()(() => ({
  checkbox: {
    '& .MuiFormControlLabel-label': {
      minWidth: 0,
    },
  },
}));

interface Props {
  checked: boolean;
  disabled?: boolean;
  handleClick: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubMenuButtonClick?: () => void;
  indeterminate?: boolean;
  label: string;
  showSubMenuButton?: boolean;
  value?: string;
}

export const LayerSelectionCheckBox: FC<Props> = ({
  checked,
  disabled,
  handleClick,
  handleSubMenuButtonClick,
  indeterminate,
  label,
  showSubMenuButton = false,
  value,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between', pb: 0.5 }}>
      <FormControlLabel
        data-testid={testid`layer-select-${label}`}
        className={classes.checkbox}
        control={
          <Checkbox
            checked={checked}
            disabled={disabled}
            indeterminate={indeterminate}
            onChange={handleClick}
            size="small"
            sx={{ p: 0, pr: 1 }}
            value={value}
          />
        }
        label={
          <OverflowTooltip
            sx={{
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            {label}
          </OverflowTooltip>
        }
        sx={{
          m: 0,
          width: '100%',
        }}
      />
      {showSubMenuButton
        && <IconButton disabled={disabled} onClick={handleSubMenuButtonClick} sx={{ mr: 0.5, p: 0 }}>
          <ChevronRightIcon sx={{ color: disabled ? undefined : theme.palette.common.black }} />
        </IconButton>
      }
    </Stack>
  );
};
