import { PaginatedResponse } from '@eagle/api-types';
import { JsonObject, RoleFunction } from '@eagle/common';
import { MediaSegmentData, MediaType } from '@eagle/core-data-types';
import { VideoSegmentStatus } from '@eagle/data-function-types';
import { DateTime } from 'luxon';
import { FC, Suspense } from 'react';
import { useAuthenticated } from '../../auth';
import { CommonEntity, Undefinable } from '../../types';
import { FeatureId, FeatureIds } from '../../types/feature';
import { useHasAuthorization } from '../../util';
import { MiddleSpinner } from '../middle-spinner';
import { MediaCardController } from './media-card-controller';
import { MediaListPageType, NavigateState } from './media-data.types';

interface Features {
  featureId: FeatureId[keyof FeatureId] | (string & { format?: unknown });
  instances: string[][];
}

interface ConstantProps {
  displayOnCard?: boolean;
  entity: CommonEntity;
  entityTypeFeatures?: Features[];
  eventIndex?: number;
  features?: string[];
  filterMediaFeature?: boolean;
  filters?: JsonObject;
  limit?: string | number;
  navigateState?: NavigateState;
  pageType: MediaListPageType;
  sort?: Record<string, string> | string;
  timeZone?: string;
  mediaDaysLimit?: number;
  'data-testid'?: string;
}

type ConditionalProps = {
  getMediaData?: never;
  url: `/api/${string}`;
} | {
  getMediaData: (feature: Undefinable<string>, mediaType: Undefinable<MediaType>) => Promise<PaginatedResponse<MediaSegmentData>>;
  url?: never;
}

type Props = ConstantProps & ConditionalProps;

const MEDIA_LIMIT = 5;
const MEDIA_PERMISSIONS = [RoleFunction.MEDIA_VIEWER] as const;

export const MediaCard: FC<Props> = ({
  displayOnCard = false,
  entity,
  entityTypeFeatures = [{ featureId: 'none', instances: [] }] as Features[],
  eventIndex,
  features,
  filterMediaFeature = false,
  filters,
  getMediaData,
  limit = MEDIA_LIMIT,
  navigateState,
  pageType,
  sort = { feature: 1 },
  timeZone,
  url,
  mediaDaysLimit,
  ...props
}): JSX.Element => {
  const { axios } = useAuthenticated();
  const { hasAuthorization } = useHasAuthorization();
  const mediaPermissions = hasAuthorization(MEDIA_PERMISSIONS);

  const getMediaList = async (mediaFeature: Undefinable<string>, mediaType?: MediaType, skip?: number, isDaysLimit?: boolean): Promise<PaginatedResponse<MediaSegmentData>> => {
    if (getMediaData) return getMediaData(mediaFeature, mediaType);
    const featureToFilter = filterMediaFeature ? { feature: mediaFeature } : {};
    const daysLimit = mediaDaysLimit && isDaysLimit ? mediaDaysLimit : 0;
    const status = { status: VideoSegmentStatus.UPLOAD_COMPLETE };
    const startDate = DateTime.now().minus({ days: daysLimit }).toJSDate();
    const finishDate = DateTime.now().toJSDate();

    const { data } = await axios.get<PaginatedResponse<MediaSegmentData>>(url, {
      params: {
        filter: {
          '$and': [{
            ...filters,
            ...featureToFilter,
            ...status,
            mediaType,
            ...(isDaysLimit ? { start: { '$gt': startDate }, finish: { '$lt': finishDate } } : {}),
          }],
        },
        limit,
        skip,
        sort,
      },
    });
    return data;
  };

  if (!entityTypeFeatures.some(({ featureId }) => featureId === FeatureIds.CAMERA) || !mediaPermissions) return <></>;

  return (
    <Suspense fallback={<MiddleSpinner />}>
      <MediaCardController
        displayOnCard={displayOnCard}
        entityDisplay={entity.display}
        entityId={entity._id}
        eventIndex={eventIndex}
        features={features}
        getMediaData={getMediaList}
        navigateState={navigateState}
        pageType={pageType}
        timeZone={timeZone}
        data-testid={props['data-testid']}
      />
    </Suspense>
  );
};
