import { InputTypes } from '@eagle/common';
import { FetchAllCache } from '../../hooks';
import { EntityType } from '../../pages';
import { CommonEntityWithDeleted, CommonEntityWithProperties } from '../../types';
import { addLocalStorageItem, getLocalStorageItem } from '../../util';
import { FilterFieldNew } from '../entity-search/types';
import { FilterAttributes, FilterDataTypes, FilterPathIdentifiers } from '../filter';
import { SHOW_DELETED_KEY } from './new-filter';

export interface BuildFilterFieldsOptions {
  apiUrl: string;
  attributes: FilterAttributes;
  dataType: FilterDataTypes;
  entityCache: FetchAllCache;
  fieldLabel: string;
  findItems: (search: string) => Promise<CommonEntityWithDeleted<string>[]>;
  getEntityTypeId?: (entity: CommonEntityWithProperties) => string;
  omitEntityFilter?: boolean;
  pathIdentifier: FilterPathIdentifiers;
  replacePath: string;
  selectMultiple: boolean;
}

export const buildFilterFieldsNew = (
  entityTypes: EntityType[],
  typePropertyName: string,
  typeFilterLabel: string,
  options?: Partial<BuildFilterFieldsOptions>,
): FilterFieldNew => {
  const entityObject: FilterFieldNew = {
    definition: {
      description: null,
      format: 'raw',
      input: InputTypes.CHECK,
      label: typeFilterLabel,
      multiple: null,
      type: 'entity',
    },
    path: typePropertyName,
    values: entityTypes.map((type) => ({
      id: type._id,
      display: type.display,
      alertProperties: type.alertProperties,
    })),
    ...options,
  };

  return entityObject;
};

export const getShowDeleted = (storageKey: string, hasAccess?: boolean): boolean => {
  return hasAccess !== false && getLocalStorageItem(
    SHOW_DELETED_KEY, { [storageKey]: false },
  )[storageKey];
};

export const handleSetShowDeleted = (value: boolean, setShowDeleted: (value: boolean) => void, storageKey: string): void => {
  setShowDeleted(value);
  addLocalStorageItem(SHOW_DELETED_KEY, { [storageKey]: value });
};
