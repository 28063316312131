import { useTheme } from '@mui/material';
import { FC } from 'react';
import { ThemeLogoOptions } from '../../types';

interface LogoProps {
  classes?: Record<'svg' | 'text', string>;
  width?: string;
  logo?: ThemeLogoOptions;
}

export const EthingsLogo: FC<LogoProps> = ({ width = '100%', logo = 'dark-colorful', classes }) => {
  const theme = useTheme();
  const logoRightColor = logo.includes('colorful') ? '#fc9700' : (logo === 'light' ? theme.logo.light : theme.logo.dark);
  const logoLeftColor = logo.includes('colorful') ? '#ff006a' : (logo === 'light' ? theme.logo.light : theme.logo.dark);
  const logoBottomColor = logo.includes('colorful') ? '#0097fe' : (logo === 'light' ? theme.logo.light : theme.logo.dark);
  const textColor = logo.includes('dark') ? theme.logo.dark : theme.logo.light;

  return <svg xmlns="http://www.w3.org/2000/svg" className={classes?.svg} viewBox="0 0 981.8 224.17" width={width} >
    <g id="container">
      <g id="logo">
        <g>
          <path id="top" d="M222.11,135.43c2.12,1.22,2.84,3.93,1.62,6.05l-9.96,17.25c-1.22,2.12-3.93,2.84-6.05,1.62l-59.03-34.08c-19.18-11.07-30.99-31.54-30.99-53.68V4.43c0-2.45,1.98-4.43,4.43-4.43h19.92c2.45,0,4.43,1.98,4.43,4.43V41.95c0,16.82-8.68,32.45-22.95,41.34-1.04,.65-1.36,2.01-.71,3.05,.63,1.01,1.95,1.34,2.99,.75l72.85-42.06c2.12-1.22,4.83-.5,6.05,1.62l9.96,17.25c1.22,2.12,.5,4.83-1.62,6.05l-72.85,42.06c-1.06,.61-1.42,1.97-.81,3.02,.6,1.03,1.91,1.41,2.96,.84,14.84-7.92,32.71-7.62,47.28,.79l32.5,18.76Z" fill={logoRightColor} />
          <path d="M202.19,169.94c2.12,1.22,2.84,3.93,1.62,6.05l-9.96,17.26c-1.22,2.12-3.93,2.84-6.05,1.62l-32.5-18.76c-14.56-8.41-23.76-23.74-24.32-40.55-.04-1.22-1.06-2.18-2.29-2.14-1.19,.04-2.14,1.02-2.14,2.21v84.12c0,2.45-1.98,4.43-4.43,4.43h-19.92c-2.45,0-4.43-1.98-4.43-4.43v-84.12c0-1.22-.99-2.21-2.21-2.21s-2.17,.95-2.21,2.14c-.56,16.81-9.76,32.14-24.32,40.55l-32.5,18.76c-2.12,1.22-4.83,.5-6.05-1.62l-9.96-17.26c-1.22-2.12-.5-4.82,1.62-6.05l59.03-34.08c19.18-11.07,42.81-11.07,61.98,0l59.03,34.08Z" fill={logoBottomColor} />
          <path d="M106.63,4.43V72.59c0,22.14-11.81,42.61-30.99,53.68l-59.03,34.08c-2.12,1.22-4.83,.5-6.05-1.62L.59,141.48c-1.22-2.12-.5-4.83,1.62-6.05l32.5-18.76c14.57-8.41,32.44-8.71,47.28-.79,1.08,.58,2.42,.17,3-.91,.56-1.05,.19-2.36-.85-2.96L11.29,69.95c-2.12-1.22-2.84-3.93-1.62-6.05l9.96-17.25c1.22-2.12,3.93-2.84,6.05-1.62l72.85,42.06c1.06,.61,2.41,.25,3.02-.81,.6-1.03,.27-2.36-.75-2.99-14.27-8.89-22.95-24.52-22.95-41.34V4.43c0-2.45,1.98-4.43,4.43-4.43h19.92c2.45,0,4.43,1.98,4.43,4.43Z" fill={logoLeftColor} />
        </g>
      </g>
      <g id="text" className={classes?.text}>
        <path d="M339.33,146.5c12.59,0,22.03-5.67,27.07-13.01l18.67,10.91c-9.65,14.27-25.39,22.87-45.95,22.87-34.62,0-57.07-23.71-57.07-55.18s22.45-55.18,55.39-55.18,52.67,25.18,52.67,55.39c0,3.15-.42,6.5-.84,9.44h-83.51c3.57,16.37,16.79,24.76,33.57,24.76Zm27.91-43.22c-3.15-18.04-16.37-25.81-30-25.81-17,0-28.54,10.07-31.68,25.81h61.69Z" fill={textColor} />
        <path d="M394.3,151.32c0-8.81,6.92-15.74,15.74-15.74s15.74,6.92,15.74,15.74-7.13,15.74-15.74,15.74-15.74-7.13-15.74-15.74Z" fill={textColor} opacity=".6" />
        <path d="M464.59,81.45v50.36c0,13.43,8.81,13.22,26.02,12.38v20.35c-34.83,4.2-48.68-5.46-48.68-32.73v-50.36h-19.3v-21.82h19.3v-22.66l22.66-6.71v29.38h26.02v21.82h-26.02Z" fill={textColor} />
        <path d="M601.4,100.13v64.42h-22.66v-62.11c0-15.95-9.23-24.34-23.5-24.34s-26.65,8.81-26.65,30.21v56.23h-22.66V17.67h22.66v55.39c6.92-10.91,18.25-16.16,32.52-16.16,23.5,0,40.29,15.95,40.29,43.22Z" fill={textColor} />
        <path d="M617.34,29.42c0-7.97,6.5-14.48,14.48-14.48s14.48,6.5,14.48,14.48-6.5,14.48-14.48,14.48-14.48-6.71-14.48-14.48Zm3.15,30.21h22.66v104.91h-22.66V59.63Z" fill={textColor} />
        <path d="M759.6,100.13v64.42h-22.66v-62.11c0-15.95-9.23-24.34-23.5-24.34s-26.65,8.81-26.65,30.21v56.23h-22.66V59.63h22.66v13.43c6.92-10.91,18.25-16.16,32.52-16.16,23.5,0,40.29,15.95,40.29,43.22Z" fill={textColor} />
        <path d="M884.44,59.63v100.08c0,33.78-26.44,49.52-53.92,49.52-23.08,0-40.92-8.6-49.73-25.39l19.51-11.33c4.62,9.02,12.38,16.16,30.63,16.16,19.51,0,31.26-10.7,31.26-28.96v-12.8c-7.97,11.33-20.35,18.25-36.51,18.25-29.58,0-52.88-24.13-52.88-54.13s23.29-54.13,52.88-54.13c16.16,0,28.54,6.92,36.51,18.25v-15.53h22.24Zm-22.24,51.41c0-18.88-14.27-32.94-33.36-32.94s-33.36,14.06-33.36,32.94,14.27,32.94,33.36,32.94,33.36-14.06,33.36-32.94Z" fill={textColor} />
        <path d="M981.8,134.96c0,20.98-18.25,32.31-40.92,32.31-20.98,0-36.09-9.44-42.8-24.55l19.51-11.33c3.36,9.44,11.75,15.11,23.29,15.11,10.07,0,17.83-3.36,17.83-11.75,0-18.67-56.44-8.18-56.44-45.74,0-19.72,17-32.1,38.4-32.1,17.21,0,31.47,7.97,38.82,21.82l-19.09,10.7c-3.78-8.18-11.12-11.96-19.72-11.96s-15.32,3.57-15.32,11.12c0,19.09,56.44,7.55,56.44,46.37Z" fill={textColor} />
      </g>
    </g>
  </svg>;
};

export const EthingsPoweredByLogo: FC<LogoProps> = ({ width = '100%', logo = 'dark-colorful', classes }) => {
  const theme = useTheme();
  const logoRightColor = logo.includes('colorful') ? '#fc9700' : (logo === 'light' ? theme.logo.light : theme.logo.dark);
  const logoLeftColor = logo.includes('colorful') ? '#ff006a' : (logo === 'light' ? theme.logo.light : theme.logo.dark);
  const logoBottomColor = logo.includes('colorful') ? '#0097fe' : (logo === 'light' ? theme.logo.light : theme.logo.dark);
  const textColor = logo.includes('dark') ? theme.logo.dark : theme.logo.light;

  return <svg xmlns="http://www.w3.org/2000/svg" className={classes?.svg} viewBox="0 0 120 34" width={width} >
    <path d="M33.4385 20.4116C33.7562 20.5953 33.8654 21.0017 33.6817 21.3194L32.186 23.9099C32.0022 24.2276 31.5959 24.3368 31.2782 24.1531L22.4141 19.0358C19.535 17.3726 17.7605 14.3012 17.7605 10.9758V0.740327C17.7605 0.372882 18.0577 0.0756836 18.4251 0.0756836H21.4166C21.784 0.0756836 22.0812 0.372882 22.0812 0.740327V6.37412C22.0812 8.89977 20.779 11.246 18.6348 12.5807C18.4792 12.678 18.4316 12.8833 18.5278 13.0389C18.6229 13.1913 18.8207 13.241 18.9763 13.1513L29.9143 6.83559C30.232 6.65187 30.6384 6.76102 30.8221 7.07875L32.3178 9.66924C32.5015 9.98697 32.3924 10.3933 32.0746 10.577L21.1367 16.8928C20.9778 16.9846 20.9227 17.1878 21.0146 17.3467C21.1043 17.5023 21.3009 17.5585 21.4587 17.4731C23.6872 16.2843 26.3706 16.3297 28.5569 17.592L33.4364 20.4094L33.4385 20.4116Z" fill={logoRightColor} />
    <path d="M30.4471 25.5926C30.7648 25.7763 30.874 26.1827 30.6903 26.5004L29.1945 29.0909C29.0108 29.4086 28.6045 29.5178 28.2867 29.3341L23.4073 26.5166C21.221 25.2543 19.8398 22.9524 19.7555 20.4289C19.7501 20.2452 19.5956 20.1015 19.4118 20.1079C19.2324 20.1133 19.0909 20.2614 19.0909 20.4397V33.0701C19.0909 33.4376 18.7937 33.7348 18.4262 33.7348H15.4348C15.0674 33.7348 14.7702 33.4376 14.7702 33.0701V20.4397C14.7702 20.256 14.621 20.1079 14.4373 20.1079C14.2536 20.1079 14.1109 20.2506 14.1055 20.4289C14.0212 22.9524 12.6401 25.2543 10.4538 26.5166L5.5743 29.3341C5.25657 29.5178 4.85022 29.4086 4.66649 29.0909L3.17078 26.5004C2.98705 26.1827 3.09621 25.7763 3.41394 25.5926L12.278 20.4754C15.1571 18.8122 18.7051 18.8122 21.5852 20.4754L30.4493 25.5926H30.4471Z" fill={logoBottomColor} />
    <path d="M16.0995 0.74038V10.9759C16.0995 14.3013 14.3261 17.3727 11.4459 19.0359L2.58187 24.1531C2.26414 24.3368 1.85778 24.2277 1.67406 23.91L0.178344 21.3195C-0.00537848 21.0017 0.103774 20.5954 0.421506 20.4117L5.30096 17.5942C7.48834 16.3319 10.1718 16.2865 12.3991 17.4753C12.5612 17.5618 12.7623 17.5002 12.8487 17.3392C12.933 17.1803 12.8768 16.9847 12.7212 16.895L1.78321 10.5793C1.46548 10.3955 1.35633 9.98919 1.54005 9.67146L3.03577 7.08097C3.21949 6.76324 3.62584 6.65408 3.94358 6.83781L14.8816 13.1535C15.0404 13.2454 15.2436 13.1914 15.3355 13.0325C15.4252 12.8769 15.3754 12.6791 15.2231 12.584C13.08 11.2493 11.7766 8.90306 11.7766 6.37742V0.743622C11.7766 0.376177 12.0738 0.0789795 12.4413 0.0789795H15.4327C15.8002 0.0789795 16.0974 0.376177 16.0974 0.743622L16.0995 0.74038Z" fill={logoLeftColor} />
    <path d="M44.1918 26.9554C45.5525 26.9554 46.5727 26.3426 47.1173 25.5494L49.1351 26.7284C48.0922 28.2706 46.3911 29.2 44.1691 29.2C40.4277 29.2 38.0015 26.6376 38.0015 23.2366C38.0015 19.8356 40.4277 17.2732 43.9876 17.2732C47.5475 17.2732 49.6797 19.9944 49.6797 23.2593C49.6797 23.5997 49.6343 23.9618 49.589 24.2795H40.5639C40.9497 26.0486 42.3784 26.9554 44.1918 26.9554ZM47.207 22.2845C46.8666 20.3349 45.4379 19.4952 43.9649 19.4952C42.1277 19.4952 40.8805 20.5834 40.5412 22.2845H47.2081H47.207Z" fill={textColor} />
    <path opacity="0.6" d="M50.1326 27.4774C50.1326 26.5252 50.8804 25.7763 51.8336 25.7763C52.7868 25.7763 53.5347 26.5242 53.5347 27.4774C53.5347 28.4306 52.7641 29.1784 51.8336 29.1784C50.9031 29.1784 50.1326 28.4079 50.1326 27.4774Z" fill={textColor} />
    <path d="M57.729 19.9264V25.3689C57.729 26.8203 58.6812 26.7976 60.5411 26.7068V28.9061C56.7769 29.36 55.2801 28.316 55.2801 25.3689V19.9264H53.1943V17.5682H55.2801V15.1193L57.729 14.3942V17.5693H60.5411V19.9275H57.729V19.9264Z" fill={textColor} />
    <path d="M72.5132 21.9441V28.9061H70.0643V22.1937C70.0643 20.47 69.0668 19.5632 67.5246 19.5632C65.9824 19.5632 64.6445 20.5154 64.6445 22.8281V28.905H62.1956V13.0314H64.6445V19.0175C65.3923 17.8384 66.6168 17.271 68.159 17.271C70.6987 17.271 72.5132 18.9948 72.5132 21.9419V21.9441Z" fill={textColor} />
    <path d="M74.2358 14.3023C74.2358 13.441 74.9383 12.7374 75.8007 12.7374C76.6631 12.7374 77.3656 13.4399 77.3656 14.3023C77.3656 15.1647 76.6631 15.8672 75.8007 15.8672C74.9383 15.8672 74.2358 15.142 74.2358 14.3023ZM74.5763 17.5682H77.0252V28.9061H74.5763V17.5682Z" fill={textColor} />
    <path d="M89.6101 21.9441V28.9061H87.1612V22.1938C87.1612 20.47 86.1637 19.5633 84.6215 19.5633C83.0793 19.5633 81.7414 20.5154 81.7414 22.8281V28.905H79.2925V17.5672H81.7414V19.0186C82.4892 17.8395 83.7137 17.2722 85.2559 17.2722C87.7956 17.2722 89.6101 18.9959 89.6101 21.943V21.9441Z" fill={textColor} />
    <path d="M103.102 17.5682V28.3841C103.102 32.0348 100.245 33.7358 97.2748 33.7358C94.7805 33.7358 92.8525 32.8064 91.9004 30.9919L94.0088 29.7674C94.5081 30.7422 95.3468 31.5139 97.3191 31.5139C99.4276 31.5139 100.697 30.3575 100.697 28.3841V27.0008C99.8361 28.2252 98.4982 28.9731 96.7517 28.9731C93.5549 28.9731 91.0369 26.3653 91.0369 23.1231C91.0369 19.881 93.5539 17.2732 96.7517 17.2732C98.4982 17.2732 99.8361 18.0211 100.697 19.2455V17.5671H103.101L103.102 17.5682ZM100.699 23.1231C100.699 21.0827 99.1563 19.5632 97.0932 19.5632C95.0301 19.5632 93.4879 21.0827 93.4879 23.1231C93.4879 25.1635 95.0301 26.683 97.0932 26.683C99.1563 26.683 100.699 25.1635 100.699 23.1231Z" fill={textColor} />
    <path d="M113.624 25.7082C113.624 27.9756 111.651 29.2 109.201 29.2C106.934 29.2 105.301 28.1798 104.576 26.5469L106.684 25.3224C107.048 26.3426 107.954 26.9554 109.201 26.9554C110.29 26.9554 111.128 26.5923 111.128 25.6855C111.128 23.6678 105.029 24.8015 105.029 20.7423C105.029 18.6111 106.866 17.2732 109.179 17.2732C111.039 17.2732 112.58 18.1345 113.374 19.6313L111.311 20.7877C110.902 19.9037 110.109 19.4952 109.18 19.4952C108.25 19.4952 107.524 19.881 107.524 20.6969C107.524 22.76 113.624 21.5129 113.624 25.7082Z" fill={textColor} />
    <path d="M83.829 10.2722C83.7063 10.2722 83.5969 10.262 83.5008 10.2416C83.4047 10.2231 83.3382 10.2047 83.3014 10.1863L83.4854 9.54829C83.6613 9.59328 83.8167 9.60964 83.9517 9.59737C84.0867 9.5851 84.2063 9.52477 84.3106 9.41639C84.4169 9.31004 84.5141 9.13724 84.602 8.89797L84.737 8.52987L82.9946 3.79358H83.7799L85.0806 7.54825H85.1296L86.4303 3.79358H87.2156L85.2155 9.19246C85.1256 9.43581 85.0141 9.63725 84.8812 9.79676C84.7482 9.95832 84.5938 10.078 84.418 10.1557C84.2441 10.2334 84.0478 10.2722 83.829 10.2722Z" fill={textColor} />
    <path d="M78.2646 8.50535V2.22302H78.9886V4.54208H79.0499C79.1031 4.46028 79.1767 4.35599 79.2708 4.2292C79.3669 4.10036 79.5039 3.98584 79.6819 3.88563C79.8618 3.78338 80.1052 3.73225 80.4119 3.73225C80.8087 3.73225 81.1584 3.83144 81.461 4.02981C81.7637 4.22817 81.9999 4.50936 82.1696 4.87338C82.3394 5.23739 82.4242 5.66685 82.4242 6.16175C82.4242 6.66074 82.3394 7.09326 82.1696 7.45932C81.9999 7.82333 81.7647 8.10555 81.4641 8.30596C81.1635 8.50433 80.8168 8.60351 80.4242 8.60351C80.1215 8.60351 79.8792 8.55341 79.6972 8.4532C79.5152 8.35095 79.3751 8.23541 79.2769 8.10657C79.1788 7.97569 79.1031 7.8673 79.0499 7.78141H78.964V8.50535H78.2646ZM78.9763 6.14948C78.9763 6.50531 79.0285 6.81923 79.1328 7.09121C79.2371 7.36116 79.3894 7.57282 79.5898 7.7262C79.7902 7.87753 80.0356 7.95319 80.326 7.95319C80.6287 7.95319 80.8813 7.87344 81.0837 7.71392C81.2882 7.55237 81.4416 7.33559 81.5439 7.06361C81.6482 6.78957 81.7003 6.48486 81.7003 6.14948C81.7003 5.81818 81.6492 5.51961 81.5469 5.25376C81.4467 4.98586 81.2944 4.7742 81.0899 4.61877C80.8874 4.46131 80.6328 4.38257 80.326 4.38257C80.0316 4.38257 79.7841 4.45722 79.5837 4.6065C79.3833 4.75375 79.2319 4.96029 79.1297 5.22615C79.0274 5.48996 78.9763 5.79773 78.9763 6.14948Z" fill={textColor} />
    <path d="M72.1509 8.60351C71.7583 8.60351 71.4116 8.50433 71.111 8.30596C70.8104 8.10555 70.5752 7.82333 70.4055 7.45932C70.2357 7.09326 70.1509 6.66074 70.1509 6.16175C70.1509 5.66685 70.2357 5.23739 70.4055 4.87338C70.5752 4.50936 70.8114 4.22817 71.1141 4.02981C71.4167 3.83144 71.7665 3.73225 72.1632 3.73225C72.4699 3.73225 72.7123 3.78338 72.8902 3.88563C73.0702 3.98584 73.2072 4.10036 73.3012 4.2292C73.3974 4.35599 73.472 4.46028 73.5252 4.54208H73.5865V2.22302H74.3105V8.50535H73.6111V7.78141H73.5252C73.472 7.8673 73.3963 7.97569 73.2982 8.10657C73.2 8.23541 73.0599 8.35095 72.8779 8.4532C72.6959 8.55341 72.4536 8.60351 72.1509 8.60351ZM72.2491 7.95319C72.5395 7.95319 72.7849 7.87753 72.9853 7.7262C73.1857 7.57282 73.3381 7.36116 73.4424 7.09121C73.5466 6.81923 73.5988 6.50531 73.5988 6.14948C73.5988 5.79773 73.5477 5.48996 73.4454 5.22615C73.3432 4.96029 73.1918 4.75375 72.9914 4.6065C72.791 4.45722 72.5436 4.38257 72.2491 4.38257C71.9423 4.38257 71.6867 4.46131 71.4822 4.61877C71.2797 4.7742 71.1274 4.98586 71.0251 5.25376C70.9249 5.51961 70.8748 5.81818 70.8748 6.14948C70.8748 6.48486 70.9259 6.78957 71.0282 7.06361C71.1325 7.33559 71.2859 7.55237 71.4883 7.71392C71.6928 7.87344 71.9464 7.95319 72.2491 7.95319Z" fill={textColor} />
    <path d="M67.3197 8.60356C66.8657 8.60356 66.474 8.50335 66.1448 8.30294C65.8176 8.10048 65.565 7.81827 65.3871 7.4563C65.2112 7.09228 65.1233 6.66896 65.1233 6.18633C65.1233 5.70371 65.2112 5.27834 65.3871 4.91024C65.565 4.54009 65.8125 4.25174 66.1294 4.04519C66.4485 3.8366 66.8207 3.7323 67.246 3.7323C67.4914 3.7323 67.7338 3.7732 67.973 3.855C68.2123 3.9368 68.4301 4.06973 68.6264 4.25378C68.8227 4.43579 68.9792 4.6771 69.0958 4.97772C69.2123 5.27834 69.2706 5.64849 69.2706 6.08817V6.39493H65.6386V5.76915H68.5344C68.5344 5.50329 68.4812 5.26607 68.3749 5.05748C68.2706 4.84889 68.1213 4.68426 67.927 4.5636C67.7348 4.44295 67.5078 4.38262 67.246 4.38262C66.9577 4.38262 66.7082 4.45419 66.4976 4.59735C66.289 4.73845 66.1284 4.92251 66.0159 5.1495C65.9035 5.3765 65.8472 5.61986 65.8472 5.87958V6.29677C65.8472 6.6526 65.9086 6.95424 66.0313 7.20169C66.156 7.44709 66.3288 7.63421 66.5497 7.76305C66.7706 7.88984 67.0272 7.95324 67.3197 7.95324C67.5098 7.95324 67.6816 7.92665 67.835 7.87348C67.9904 7.81827 68.1244 7.73647 68.2368 7.62808C68.3493 7.51765 68.4362 7.38063 68.4976 7.21703L69.197 7.41335C69.1234 7.65057 68.9996 7.85917 68.8258 8.03913C68.652 8.21705 68.4373 8.35611 68.1816 8.45632C67.926 8.55448 67.6387 8.60356 67.3197 8.60356Z" fill={textColor} />
    <path d="M62.2734 8.50534V3.79359H62.9728V4.50526H63.0219C63.1078 4.27213 63.2632 4.08296 63.4882 3.93777C63.7131 3.79257 63.9667 3.71997 64.2489 3.71997C64.3021 3.71997 64.3686 3.72099 64.4483 3.72304C64.5281 3.72508 64.5884 3.72815 64.6293 3.73224V4.46845C64.6048 4.46232 64.5485 4.45311 64.4606 4.44084C64.3747 4.42653 64.2837 4.41937 64.1876 4.41937C63.9585 4.41937 63.754 4.46743 63.5741 4.56355C63.3962 4.65762 63.2551 4.7885 63.1508 4.95619C63.0485 5.12184 62.9974 5.311 62.9974 5.52369V8.50534H62.2734Z" fill={textColor} />
    <path d="M59.2213 8.60356C58.7673 8.60356 58.3756 8.50335 58.0464 8.30294C57.7192 8.10048 57.4666 7.81827 57.2887 7.4563C57.1128 7.09228 57.0249 6.66896 57.0249 6.18633C57.0249 5.70371 57.1128 5.27834 57.2887 4.91024C57.4666 4.54009 57.7141 4.25174 58.0311 4.04519C58.3501 3.8366 58.7223 3.7323 59.1476 3.7323C59.393 3.7323 59.6354 3.7732 59.8747 3.855C60.1139 3.9368 60.3317 4.06973 60.528 4.25378C60.7244 4.43579 60.8808 4.6771 60.9974 4.97772C61.1139 5.27834 61.1722 5.64849 61.1722 6.08817V6.39493H57.5402V5.76915H60.436C60.436 5.50329 60.3828 5.26607 60.2765 5.05748C60.1722 4.84889 60.0229 4.68426 59.8286 4.5636C59.6364 4.44295 59.4094 4.38262 59.1476 4.38262C58.8593 4.38262 58.6098 4.45419 58.3992 4.59735C58.1906 4.73845 58.03 4.92251 57.9176 5.1495C57.8051 5.3765 57.7488 5.61986 57.7488 5.87958V6.29677C57.7488 6.6526 57.8102 6.95424 57.9329 7.20169C58.0576 7.44709 58.2304 7.63421 58.4513 7.76305C58.6722 7.88984 58.9288 7.95324 59.2213 7.95324C59.4115 7.95324 59.5832 7.92665 59.7366 7.87348C59.892 7.81827 60.026 7.73647 60.1385 7.62808C60.2509 7.51765 60.3378 7.38063 60.3992 7.21703L61.0986 7.41335C61.025 7.65057 60.9013 7.85917 60.7274 8.03913C60.5536 8.21705 60.3389 8.35611 60.0832 8.45632C59.8276 8.55448 59.5403 8.60356 59.2213 8.60356Z" fill={textColor} />
    <path d="M51.4551 8.50533L50.0195 3.79358H50.7803L51.7987 7.40101H51.8478L52.8539 3.79358H53.627L54.6208 7.38874H54.6699L55.6884 3.79358H56.4491L55.0135 8.50533H54.3018L53.2711 4.88562H53.1975L52.1668 8.50533H51.4551Z" fill={textColor} />
    <path d="M47.3076 8.60356C46.8823 8.60356 46.509 8.50233 46.188 8.29987C45.8689 8.09741 45.6194 7.81418 45.4395 7.45016C45.2616 7.08615 45.1726 6.66078 45.1726 6.17406C45.1726 5.68326 45.2616 5.25482 45.4395 4.88876C45.6194 4.5227 45.8689 4.23844 46.188 4.03599C46.509 3.83353 46.8823 3.7323 47.3076 3.7323C47.733 3.7323 48.1052 3.83353 48.4242 4.03599C48.7453 4.23844 48.9948 4.5227 49.1727 4.88876C49.3526 5.25482 49.4426 5.68326 49.4426 6.17406C49.4426 6.66078 49.3526 7.08615 49.1727 7.45016C48.9948 7.81418 48.7453 8.09741 48.4242 8.29987C48.1052 8.50233 47.733 8.60356 47.3076 8.60356ZM47.3076 7.95324C47.6307 7.95324 47.8966 7.87042 48.1052 7.70477C48.3138 7.53912 48.4682 7.32133 48.5684 7.05138C48.6686 6.78144 48.7187 6.489 48.7187 6.17406C48.7187 5.85913 48.6686 5.56567 48.5684 5.29368C48.4682 5.02169 48.3138 4.80185 48.1052 4.63416C47.8966 4.46646 47.6307 4.38262 47.3076 4.38262C46.9845 4.38262 46.7186 4.46646 46.5101 4.63416C46.3015 4.80185 46.1471 5.02169 46.0469 5.29368C45.9467 5.56567 45.8965 5.85913 45.8965 6.17406C45.8965 6.489 45.9467 6.78144 46.0469 7.05138C46.1471 7.32133 46.3015 7.53912 46.5101 7.70477C46.7186 7.87042 46.9845 7.95324 47.3076 7.95324Z" fill={textColor} />
    <path d="M40.0591 8.50535V2.22302H42.1818C42.6747 2.22302 43.0775 2.31198 43.3904 2.4899C43.7054 2.66577 43.9385 2.90402 44.0898 3.20464C44.2412 3.50526 44.3168 3.84064 44.3168 4.21079C44.3168 4.58094 44.2412 4.91735 44.0898 5.22001C43.9405 5.52268 43.7095 5.76399 43.3966 5.94395C43.0837 6.12187 42.6829 6.21083 42.1941 6.21083H40.6726V5.53597H42.1696C42.507 5.53597 42.7779 5.47769 42.9825 5.36112C43.187 5.24455 43.3352 5.08709 43.4272 4.88872C43.5213 4.6883 43.5684 4.46233 43.5684 4.21079C43.5684 3.95925 43.5213 3.7343 43.4272 3.53593C43.3352 3.33756 43.1859 3.18214 42.9794 3.06966C42.7728 2.95514 42.4988 2.89788 42.1573 2.89788H40.8198V8.50535H40.0591Z" fill={textColor} />
    <path d="M116.649 14.4062H117.084L118.109 16.9089H118.144L119.169 14.4062H119.605V17.4213H119.263V15.1306H119.234L118.292 17.4213H117.962L117.02 15.1306H116.99V17.4213H116.649V14.4062Z" fill={textColor} />
    <path d="M113.824 14.7301V14.4062H116.085V14.7301H115.137V17.4213H114.772V14.7301H113.824Z" fill={textColor} />
  </svg>;
};
