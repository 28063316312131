/* eslint-disable react-hooks/rules-of-hooks */
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ulid } from 'ulid';
import { addLocalStorageItem } from '../../util/local-storage';
import { testid } from '../../util/test-id';
import {
  AppliedFilter,
  AppliedFilterType,
  EntityField,
  FilterField,
  SelectedFilterType
} from '../entity-search/types';
import { FILTERS_KEY } from './filter-builder';
import { FilterInput } from './filter-input';
import { FilterTypes } from './filter.types';
import { useFilterStyles } from './styles';

interface Props {
  fields: FilterField[];
  filterOptions: {
    addButtonText: string;
    addFilterText: string;
    cancelButtonText: string;
    moreText: string;
    valuePlaceholderText?: string;
  };
  filters: AppliedFilter[];
  onFiltersChanged?: (filters: AppliedFilter[]) => unknown;
  storageKey: string;
}

const MIN_MENU_HEIGHT = 200;

export const AddFilter: FC<Props> = ({ fields, filterOptions, filters, onFiltersChanged, storageKey }) => {
  if (!fields.length) return null;

  const { addButtonText, cancelButtonText, addFilterText, moreText, valuePlaceholderText } = filterOptions;

  const { t } = useTranslation(['common']);
  const [isMoreClicked, setIsMoreClicked] = useState(false);
  const [selectedField, setSelectedField] = useState<FilterField>(fields[0]);
  const [selectedValue, setSelectedValue] = useState<SelectedFilterType>();
  const [valueFieldError, setValueFieldError] = useState(false);
  const classes = useFilterStyles();
  const selectMenu = useRef<HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [selectAnchorTop, setSelectAnchorTop] = useState(false);
  const [menuMaxHeight, setMenuMaxHeight] = useState<number>();
  const updatedPath = selectedField.replacePath ?? selectedField.path;

  const appliedFilters = (filterType: FilterTypes): EntityField[] => {
    return filters
      .filter(({ propertyPath }) => propertyPath === filterType)
      .map(({ value }) => value as EntityField);
  };

  const isSelectedFilterAlreadyApplied = (): boolean => {
    return !!filters.find(
      (filter) =>
        filter.propertyPath === updatedPath
        && filter.value === selectedValue,
    );
  };

  const isSelectedEntityFilterAlreadyApplied = (
    value: EntityField,
  ): boolean => {
    return !!filters
      .filter((filter) => filter.definition.type === 'entity')
      .find(
        (filter) =>
          filter.propertyPath === updatedPath
          && value.id === (filter.value as EntityField).id,
      );
  };

  const createAppliedFilter = (
    value: AppliedFilterType,
  ): AppliedFilter => {
    return {
      id: ulid(),
      definition: selectedField.definition,
      pathIdentifier: selectedField.pathIdentifier,
      propertyPath: updatedPath,
      ids: selectedField.ids,
      value,
    };
  };

  const clearValues = (): void => {
    setSelectedValue(undefined);
    setIsMoreClicked(false);
    setSelectedField(fields[0]);
  };

  const keyUpHandler = (event: KeyboardEvent): void => {
    switch (event.key) {
      case 'Escape':
        setIsMoreClicked(false);
        break;
    }
  };

  const submitFilter = (): void => {
    if (!selectedValue) {
      setValueFieldError(true);
      return;
    }

    setValueFieldError(false);

    if (selectedField.definition.type === 'entity') {
      const filtersRelatedIds = filters.filter((f) => f.propertyPath === updatedPath).map((f) => (f.value as EntityField).id);
      const idToRemove = filtersRelatedIds.filter((f) => !(selectedValue as EntityField[]).find((item) => item.id === f));

      const filtersToAdd = (selectedValue as EntityField[])
        .map(
          (v) => createAppliedFilter(v) as AppliedFilter<EntityField>,
        )
        .filter(
          (filter) => {
            return !isSelectedEntityFilterAlreadyApplied(filter.value);
          },
        );

      const updatedFilters = filters.filter((f) => !idToRemove.includes((f.value as EntityField).id));

      addLocalStorageItem(FILTERS_KEY, {
        [storageKey]: [
          ...updatedFilters,
          ...filtersToAdd,
        ],
      });
      onFiltersChanged?.([
        ...updatedFilters,
        ...filtersToAdd,
      ]);

      clearValues();
      return;
    }

    if (isSelectedFilterAlreadyApplied()) {
      clearValues();
      return;
    }

    addLocalStorageItem(FILTERS_KEY, {
      [storageKey]: [
        ...filters,
        createAppliedFilter(selectedValue as AppliedFilterType),
      ],
    });
    onFiltersChanged?.([
      ...filters,
      createAppliedFilter(selectedValue as AppliedFilterType),
    ]);
    clearValues();
  };

  useEffect(() => {
    window.addEventListener('keyup', keyUpHandler);
    return () => {
      window.removeEventListener('keyup', keyUpHandler);
    };
  }, []);

  const handleChange = (event: SelectChangeEvent): void => {
    const field =
      fields.find((f) => (f.replacePath ?? f.path) === event.target.value) ?? fields[0];
    setSelectedField(field);
    setSelectedValue(undefined);
    setValueFieldError(false);
  };

  useEffect(() => {
    if (selectMenu.current) {
      setSelectAnchorTop(false);
      const availableHeight = window.innerHeight - selectMenu.current?.getClientRects()[0].bottom;
      if (availableHeight < MIN_MENU_HEIGHT) {
        setSelectAnchorTop(true);
        setMenuMaxHeight(selectMenu.current?.getClientRects()[0].top);
      } else {
        setMenuMaxHeight(availableHeight);
      }
    }
  }, [open]);

  return <>
    {!isMoreClicked && (
      <Button
        data-testid="add-filters-button"
        className={classes.moreButton}
        fullWidth
        onClick={() => setIsMoreClicked(true)}
        startIcon={<AddIcon />}
        variant="contained"
      >
        {moreText}
      </Button>
    )}

    {isMoreClicked && (
      <form>
        <Stack spacing={2}>
          <FormControl sx={{ '& .MuiPaper-root': { top: selectMenu.current?.getClientRects()[0].bottom } }}>
            <InputLabel id="filter-label">{addFilterText}</InputLabel>
            <Select
              ref={selectMenu}
              data-testid="select-filters-button"
              autoFocus={true}
              inputProps={{ 'aria-label': t('common:component.filter.hint.filter-field'), 'data-testid': 'select-filters-button-input' }}
              label={addFilterText}
              labelId="filter-label"
              MenuProps={{
                sx: { maxHeight: menuMaxHeight && `${menuMaxHeight}px` },
                anchorOrigin: { vertical: selectAnchorTop ? 'top' : 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: selectAnchorTop ? 'bottom' : 'top', horizontal: 'left' },
              }}
              onChange={handleChange}
              onOpen={() => setOpen(!open)}
              size='small'
              value={updatedPath}
            >
              {fields.map((field) => {
                return (
                  <MenuItem
                    data-testid={testid`${field.definition.label}-filter`}
                    key={field.replacePath ?? field.path}
                    value={field.replacePath ?? field.path}
                  >
                    {field.definition.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FilterInput
            key={selectedField.path}
            appliedFilters={appliedFilters}
            inputError={valueFieldError}
            isApplied={isSelectedEntityFilterAlreadyApplied}
            onChanged={setSelectedValue}
            path={selectedField.path}
            placeholderText={valuePlaceholderText}
            replacePath={selectedField.replacePath}
            selectedValue={selectedValue}
            selectMultiple={selectedField.selectMultiple}
            type={selectedField.definition.type}
            values={selectedField.values}
          />

          <Box className={classes.addFilterButtons}>
            <Stack spacing={1} direction='row'>
              <Button
                data-testid="add-filters-cancel-button"
                className={classes.cancelFilterBtn}
                variant="outlined"
                type="button"
                onClick={() => {
                  setIsMoreClicked(false);
                  setValueFieldError(false);
                }}
              >
                <span className={classes.firefoxText}>{cancelButtonText}</span>
              </Button>
              <Button
                data-testid="add-filters-add-button"
                className={classes.addFilterBtn}
                variant="contained"
                onClick={submitFilter}
              >
                <span className={classes.firefoxText}>{addButtonText}</span>
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>
    )}
  </>;
};
