import { PointOfInterest } from '@eagle/core-data-types';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useAuthenticated } from '../../../../auth';
import { usePromise, useSmallScreen } from '../../../../hooks';
import { Undefinable } from '../../../../types';
import { FILTER_OUT, MapStorageKeys } from '../../../../util';
import { ErrorMessage } from '../../../error-message';
import { FilterTypes } from '../../../filter';
import { useBoundsUpdate } from '../../hooks/use-bounds-update';
import { useMapLayers } from '../../hooks/use-map-layers';
import { LoadingComponent } from '../../loading-component';
import { PointOfInterestPane } from './point-of-interest-pane';

export const PointOfInterestController: FC = () => {
  const { restClient } = useAuthenticated();
  const { enqueueSnackbar } = useSnackbar();
  const { pointOfInterestFilters } = useMapLayers();
  const map = useMap();
  const bounds = useBoundsUpdate(map);
  const smallScreen = useSmallScreen();
  const [pointsOfInterest, setPointsOfInterest] = useState<PointOfInterest[]>([]);

  const [fetchPointOfInterests, fetchPointOfInterestsError, fetchPointOfInterestsStatus] = usePromise(
    async () => {
      const filterLocalStorage = localStorage[MapStorageKeys.POINT_OF_INTEREST] as Undefinable<string>;
      if (filterLocalStorage && !pointOfInterestFilters.length) return Promise.resolve([]);
      const pointOfInterestTypeIds = pointOfInterestFilters
        .filter((filter) => filter.propertyPath === FilterTypes.POINT_OF_INTEREST_TYPE)
        .map((filter) => filter.id);
      const result = await restClient.pointOfInterest.getAll({
        ...bounds,
        filter: {
          ...FILTER_OUT.deleted,
          pointOfInterestTypeId: pointOfInterestTypeIds.length ? { '$in': pointOfInterestTypeIds } : undefined,
        },
        sort: JSON.stringify({ display: 'asc' }),
      });
      return result;
    },
    [restClient, bounds, pointOfInterestFilters],
  );

  useEffect(() => {
    if (!fetchPointOfInterests) return;

    setPointsOfInterest(fetchPointOfInterests);
  }, [fetchPointOfInterests]);

  if (fetchPointOfInterestsError) enqueueSnackbar(<ErrorMessage error={fetchPointOfInterestsError} />, { variant: 'error' });

  return <>
    {!smallScreen && <LoadingComponent isLoading={fetchPointOfInterestsStatus === 'pending'} />}
    <PointOfInterestPane
      pointsOfInterest={pointsOfInterest}
    />
  </>;
};
