import ga from 'react-ga4';

type TrackingEventCategory = 'keyword_search' | 'list_pagination' | 'list';

export const trackEvent = ( category: TrackingEventCategory, action: string, feature: string, params: Record<string, string | number> = {} ): void => {
  ga.gtag('event', category, {
    action,
    feature,
    ...params,
  });
};

