import { TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSmallScreen } from '../../hooks';
import { AssignMultipleDialogLayout } from './assign-multiple-dialog-layout';
import { EntityData } from './assign-multiple-dialog.types';

interface Props {
  'data-testid'?: string;
  dialogHeader: string;
  entityData: EntityData[];
  handleDialogClose: () => void;
  handleTabChange: (_: SyntheticEvent, tab: string) => void;
  open: boolean;
  renderDialogList: (entity: EntityData) => JSX.Element;
  selectedTab: string;
}

export const AssignMultipleDialogView: FC<Props> = ({
  dialogHeader,
  entityData,
  handleDialogClose,
  handleTabChange,
  open,
  renderDialogList,
  selectedTab,
  ...props
}): JSX.Element => {
  const { t } = useTranslation(['common']);
  const smallScreen = useSmallScreen();

  const tabs = entityData.map((entity, index) => {
    const { tab, idsToCheck } = entity;
    const label = idsToCheck.length
      ? t(`common:component.assign-multiple-dialog.labels.${tab}_selected`, { number: idsToCheck.length })
      : t(`common:component.assign-multiple-dialog.labels.${tab}`);

    return (
      <Tab
        key={index}
        label={label}
        sx={{ maxWidth: 'unset', width: `${100 / entityData.length}%` }}
        value={tab}
        data-testid={`tab-${index}`}
      />
    );
  });

  const tabPanels = entityData.map((entity, index) => (
    <TabPanel key={index} sx={{ p: 0 }} value={entity.tab}>
      {renderDialogList(entity)}
    </TabPanel>
  ));

  return (
    <AssignMultipleDialogLayout
      data-testid={props['data-testid']}
      dialogHeader={dialogHeader}
      fullScreen={smallScreen}
      handleDialogClose={handleDialogClose}
      handleTabChange={handleTabChange}
      open={open}
      selectedTab={selectedTab}
      tabPanels={tabPanels}
      tabs={tabs}
    />
  );
};
