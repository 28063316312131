import { Stack } from '@mui/material';
import { FC } from 'react';

interface Props {
  content: JSX.Element;
  'data-testid'?: string;
  paginationComponent?: JSX.Element;
}

export const SearchResultsTableLayoutMobile: FC<Props> = ({
  content,
  paginationComponent,
  ...props
}) => (
  <Stack data-testid={props['data-testid']} sx={{ alignItems: 'center' }}>
    {content}
    {paginationComponent}
  </Stack>
);
