import { CreateServiceHistoryRequest, ServiceHistory, ServiceHistoryUpdatableProps } from '@eagle/core-data-types';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { Undefinable } from '../../types';

type UpdateServiceRequest = ServiceHistoryUpdatableProps;

interface UpdateServiceOptions {
  errorMessage: string;
  onFailure: () => void;
  onSuccess: () => void;
  successMessage: string;
}

export const useUpdateService = (thingId: string, service?: ServiceHistory): {
  saveInProgress: boolean;
  setServiceState: Dispatch<SetStateAction<Undefinable<ServiceHistory>>>;
  updateService(updates: Partial<UpdateServiceRequest>, options: Partial<UpdateServiceOptions>): Promise<void>;
  createService(updates: CreateServiceHistoryRequest, options: Partial<UpdateServiceOptions>): Promise<void>;
  deleteService(options: Partial<UpdateServiceOptions>): Promise<void>;
  serviceState: Undefinable<ServiceHistory>;
} => {
  const { restClient } = useAuthenticated();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);

  const [saveInProgress, setSaveInProgress] = useState(false);
  const [serviceState, setServiceState] = useState(service || undefined);

  const updateService = async (updates: Partial<UpdateServiceRequest>, options: Partial<UpdateServiceOptions>): Promise<void> => {
    const { onFailure, onSuccess } = options;
    if (!service) return;
    try {
      setSaveInProgress(true);
      const updatedService = await restClient.serviceHistory.partialUpdate(thingId, service.serviceHistoryId, updates);
      setServiceState(updatedService);
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.update-service.success.hint'), { variant: 'success' });
      onSuccess?.();
    } catch (err) {
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.update-service.error.hint'), { variant: 'error' });
      onFailure?.();
    } finally {
      setSaveInProgress(false);
    }
  };

  const createService = async (updates: CreateServiceHistoryRequest, options: Partial<UpdateServiceOptions>): Promise<void> => {
    const { onFailure, onSuccess } = options;
    try {
      setSaveInProgress(true);
      const updatedService = await restClient.serviceHistory.create(thingId, updates);
      setServiceState(updatedService);
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.create-service.success.hint'), { variant: 'success' });
      onSuccess?.();
    } catch (err) {
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.create-service.error.hint'), { variant: 'error' });
      onFailure?.();
    } finally {
      setSaveInProgress(false);
    }
  };

  const deleteService = async (options: Partial<UpdateServiceOptions>): Promise<void> => {
    const { onFailure, onSuccess } = options;
    if (!service) return;
    try {
      setSaveInProgress(true);
      const updatedService = await restClient.serviceHistory.delete(thingId, service.serviceHistoryId);
      setServiceState(updatedService);
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.delete-service.success.hint'), { variant: 'success' });
      onSuccess?.();
    } catch (err) {
      enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.delete-service.error.hint'), { variant: 'error' });
      onFailure?.();
    } finally {
      setSaveInProgress(false);
    }
  };

  return {
    serviceState,
    saveInProgress,
    setServiceState,
    updateService,
    createService,
    deleteService,
  };
};
