import { AlertColor } from '@mui/material';
import { isString } from 'lodash';
import { CustomContentProps, SnackbarContent } from 'notistack';
import { forwardRef } from 'react';
import { testid } from '../../util';
import { Alert } from '../alert';

interface SnackbarProps extends CustomContentProps {
  variant: AlertColor;
}

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(({ id, variant, message, ...props }, ref) => {
  const messageString = !isString(message) ? id : message;
  return (
    <SnackbarContent ref={ref} role="alert" {...props}>
      <Alert elevation={4} variant='filled' severity={variant} data-testid={testid`snack-message-${messageString}`}>{message}</Alert>
    </SnackbarContent>
  );
});
Snackbar.displayName = 'SnackBar';

export const snackbarComponents = {
  success: Snackbar,
  error: Snackbar,
  info: Snackbar,
};
