import { Button } from '@mui/material';
import { BottomNavigation, BottomNavigationAction } from '../../components/bottom-navigation';
import { useSmallScreen } from '../../hooks/use-small-screen';
import { useTitle } from '../../hooks/use-title';
import LayoutDesktop from '../detail/layout-desktop';
import LayoutMobile from './layout-mobile';
import { PageAction, ViewProps } from './view.types';

const pageActions = (actions: PageAction[], smallScreen: boolean): JSX.Element => {
  if (actions.length < 1) return <></>;
  if (!smallScreen) {
    return <>
      {actions?.map((action, ind) => (
        <Button
          key={ind}
          onClick={action.onClick}
          startIcon={action.icon}
        >
          {action.label}
        </Button>
      ))}
    </>;
  }
  return (
    <BottomNavigation>
      {actions.map((action, ind) => (
        <BottomNavigationAction
          key={ind}
          icon={action.icon}
          label={action.label}
          onClick={action.onClick}
        />
      ))}
    </BottomNavigation>
  );
};

export const CreateView = ({
  actions,
  breadcrumbs,
  pageIcon,
  pageTitle,
  renderPageContent,
  ...props
}: ViewProps): JSX.Element => {
  const smallScreen = useSmallScreen();

  useTitle(pageTitle);

  if (smallScreen) {
    return (
      <LayoutMobile
        actions={pageActions(actions, smallScreen)}
        pageSubtitle={null}
        data-testid={props['data-testid']}
      >
        {renderPageContent()}
      </LayoutMobile>
    );
  }

  return (
    <LayoutDesktop
      actions={pageActions(actions, smallScreen)}
      pageIcon={pageIcon}
      pageSubtitle={breadcrumbs}
      pageTitle={pageTitle}
      data-testid={props['data-testid']}
      id="create-page"
    >
      {renderPageContent()}
    </LayoutDesktop>
  );
};
