import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { AuthStatus, useAuth } from '../../auth';

export const useSentryIdentity = (): void => {
  const { state } = useAuth();

  useEffect(() => {
    if (!state || state.status !== AuthStatus.AUTHENTICATED) {
      Sentry.setUser(null);
      Sentry.setContext('account', null);
      return;
    }

    const { user, account } = state;

    Sentry.setUser({
      id: user._id,
      email: user.email ?? undefined,
      name: user.display,
      labels: user.labels,
      tags: user.tags,
      accountId: user.accountId,
    });
    Sentry.setContext('account', {
      id: account._id,
      name: account.display,
      labels: account.labels,
      tags: account.tags,
    });
  }, [state]);
};
