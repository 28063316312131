import { styled, SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  'data-testid'?: string;
  sx?: SxProps;
}

const ListRowStyled = styled('div')(() => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'row',
  containerType: 'inline-size',
  containerName: 'list-row',
  '.ListPaperColumn-root': {
    width: '25%',
  },
  '@container (width < 600px)': {
    '.ListPaperColumn-root:nth-of-type(n+3)': {
      display: 'none',
    },
    '.ListPaperColumn-root': {
      width: '50%',
      display: 'block',
    },
  },
  '@container (width < 400px)': {
    '.ListPaperColumn-root:nth-of-type(n+2)': {
      display: 'none',
    },
    '.ListPaperColumn-root': {
      width: '100%',
      display: 'block',
    },
  },
}));

export const ListRow: FC<Props> = ({ children, sx, ...props }) => {
  return <ListRowStyled sx={sx} data-testid={props['data-testid']}>{children}</ListRowStyled>;
};
