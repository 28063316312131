import { MediaSegmentData } from '@eagle/core-data-types';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollbar } from '../../style';
import { ErrorText } from '../error-message';
import { MiddleSpinner } from '../middle-spinner';
import { renderItem } from './util';

interface Props {
  'data-testid'?: string;
  error?: Error;
  handleItemClick: (currentIndex: number, feature: string) => void;
  isEvent?: boolean;
  isLoading: boolean;
  mediaItems: MediaSegmentData[];
  timeZone?: string;
}

export const AlertPageMediaRow: FC<Props> = ({
  error,
  handleItemClick,
  isEvent,
  isLoading,
  mediaItems,
  timeZone,
  ...props
}) => {
  const { t } = useTranslation(['common']);

  const renderMediaRow = (): JSX.Element => {
    if (isLoading) return <MiddleSpinner size={16} />;
    if (error) return <ErrorText error={error} />;

    if (!mediaItems.length) {
      return (
        <Typography color="text.secondary" fontStyle="italic" variant="body2" sx={{ mx: 3 }}>
          {isEvent ? t('common:component.media-data.hint.no-result-around-time') : t('common:component.media-data.hint.no-result')}
        </Typography>
      );
    }

    return (
      <Stack
        data-testid={props['data-testid']}
        direction="row"
        spacing={2}
        sx={{ ...scrollbar, m: 0, px: 3, overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}
      >
        {mediaItems.map((item, i) => (
          <Stack key={i}>
            <Typography>{t(`common:features.camera-type.${item.feature}`)}</Typography>
            <Stack>{renderItem(handleItemClick, i, item, timeZone, `item-${i}`)}</Stack>
          </Stack>
        ),
        )}
      </Stack>
    );
  };

  const renderNearTimeHint = (): JSX.Element => {
    if (!isEvent) return <></>;
    return <Typography color="text.secondary" fontStyle="italic" variant="body2" sx={{ mx: 3 }}>
      {t('common:component.media-data.hint.media-near-time')}
    </Typography>;
  };

  return (
    <Stack>
      {renderMediaRow()}
      {renderNearTimeHint()}
    </Stack>
  );
};
