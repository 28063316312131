import { ThingEventSnapshot } from '@eagle/core-data-types';
import * as turf from '@turf/turf';
import L from 'leaflet';
import { throttle } from 'lodash';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Polyline } from 'react-leaflet';
import Supercluster from 'supercluster';
import { Maybe, Undefinable } from '../../../../types';
import { useHistorySearch } from '../../../entity-journey';
import { EventLocationData, ThingEventProperties } from '../../thing-event-pane/thing-event-pane.types';
import { ClusterTooltip } from './cluster-tooltip';
import { coordinatesToLatLng, findClosestCluster, getClosestPoint, getClusters } from './util';

interface Props {
  clusterRadius: number;
  data: turf.Feature<turf.LineString, turf.Properties>;
  handleBreadcrumbClick: (index: number, items: ThingEventSnapshot[]) => void;
  nearestPoints: turf.Feature<turf.Point, ThingEventProperties>[];
  renderer?: L.Renderer;
  selectedEvent: Maybe<EventLocationData>;
  setHoveredEventId: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
}

export const MouseOverLine: FC<Props> = ({
  clusterRadius,
  data,
  handleBreadcrumbClick,
  nearestPoints,
  renderer,
  selectedEvent,
  setHoveredEventId,
  setSelectedEvent,
}) => {
  const polylineRef = useRef<L.Polyline>(null);
  const { setClickedEventId } = useHistorySearch();
  const [tooltipDirection, setTooltipDirection] = useState<L.Direction>('bottom');

  const { clusters, supercluster } = getClusters(nearestPoints, clusterRadius);

  const handleMouseMove = useMemo(() => throttle((event: L.LeafletMouseEvent) => {
    const closestCluster = findClosestCluster(data, event.latlng, clusters);
    if (!closestCluster) return;
    setTooltipDirection(event.latlng.lat < coordinatesToLatLng(closestCluster.geometry.coordinates).lat ? 'top' : 'bottom');
    if (supercluster && closestCluster.properties.cluster) {
      const { properties } = closestCluster as Supercluster.PointFeature<Supercluster.ClusterProperties & Supercluster.AnyProps>;
      const clusterLeaves = supercluster.getLeaves(properties.cluster_id, Infinity) as Supercluster.PointFeature<ThingEventProperties>[];
      const closestLeaf = getClosestPoint(clusterLeaves, event.latlng);
      const eventData = closestLeaf?.properties.eventData as Undefinable<EventLocationData>;
      if (eventData) {
        setHoveredEventId(eventData.eventId ?? null);
        setSelectedEvent(eventData);
      }
    } else {
      const eventData = closestCluster.properties?.eventData as Undefinable<EventLocationData>;
      if (eventData) {
        setHoveredEventId(eventData.eventId ?? null);
        setSelectedEvent(eventData);
      }
    }
  }, 100), [data, setHoveredEventId, setSelectedEvent, clusters, supercluster]);

  const handleClick = useCallback(({ originalEvent, latlng }: L.LeafletMouseEvent) => {
    originalEvent.stopPropagation();
    const closestCluster = findClosestCluster(data, latlng, clusters);
    if (!closestCluster) return;
    if (supercluster && closestCluster.properties.cluster) {
      const { properties } = closestCluster as Supercluster.PointFeature<Supercluster.ClusterProperties & Supercluster.AnyProps>;
      const clusterLeaves = supercluster.getLeaves(properties.cluster_id, Infinity) as Undefinable<Supercluster.PointFeature<ThingEventProperties>[]>;
      const closestPoint = getClosestPoint(clusterLeaves ?? [], latlng);
      if (closestPoint && !closestPoint.properties.cluster) {
        setClickedEventId(closestPoint.properties.eventData.eventId);
      }
    } else {
      const eventData = closestCluster.properties?.eventData as Undefinable<EventLocationData>;
      if (eventData) {
        setSelectedEvent(eventData);
        setClickedEventId(eventData.eventId ?? null);
        handleBreadcrumbClick(0, [eventData]);
      }
    }
  }, [data, setClickedEventId, setSelectedEvent, handleBreadcrumbClick, clusters, supercluster]);

  useEffect(() => {
    const polyline = polylineRef.current;
    if (!polyline) return;

    polyline.on('mousemove', handleMouseMove);
    polyline.on('click', handleClick);

    return () => {
      polyline.off('mousemove', handleMouseMove);
      polyline.off('click', handleClick);
    };
  }, [handleMouseMove, handleClick]);

  const polylinePositions = useMemo(() => data.geometry.coordinates.map(([lat, lng]) => L.latLng(lat, lng)), [data]);

  return (
    <>
      <Polyline
        ref={polylineRef}
        positions={polylinePositions}
        color="transparent"
        weight={10}
        interactive
        renderer={renderer}
        smoothFactor={4}
      />
      {selectedEvent && (
        <ClusterTooltip
          direction={tooltipDirection}
          notableData={[selectedEvent]}
          position={[selectedEvent.data.location.latitude, selectedEvent.data.location.longitude]}
        />
      )}
    </>
  );
};
