import { ThingEvent } from '@eagle/data-function-types';
import { useSmallScreen } from '../../hooks';
import { EventDialogDesktop } from './event-dialog-desktop';
import { EventDialogMobile, EventDialogTabs } from './event-dialog-mobile';

interface Props<T> {
  closeDialog: () => void;
  data: T[];
  'data-testid'?: string;
  eventIndex: number;
  open: boolean;
  renderActions: (smallScreen: boolean) => JSX.Element[];
  renderEventData: (event: T) => JSX.Element;
  renderMediaComponent?: (event: T) => JSX.Element;
  renderTitle: (event: T) => JSX.Element;
  selectedTab: EventDialogTabs;
  setSelectedTab: (tab: EventDialogTabs) => void;
}

/**
  * NATIVE FUNCTION: View component of event dialog
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function EventDialogView<T extends Omit<ThingEvent, 'schema'>>({
  closeDialog,
  data,
  eventIndex,
  open,
  renderActions,
  renderEventData,
  renderMediaComponent,
  renderTitle,
  selectedTab,
  setSelectedTab,
  ...props
}: Props<T>): JSX.Element {
  const smallScreen = useSmallScreen();

  if (smallScreen) {
    return (
      <EventDialogMobile
        actions={renderActions(smallScreen)}
        closeDialog={closeDialog}
        data-testid={props['data-testid']}
        eventData={renderEventData(data[eventIndex])}
        handleTabChange={(_: React.SyntheticEvent, value: EventDialogTabs) => setSelectedTab(value)}
        mediaComponent={renderMediaComponent?.(data[eventIndex])}
        open={open}
        pageTitle={renderTitle(data[eventIndex])}
        selectedTab={selectedTab}
      />
    );
  }

  return (
    <EventDialogDesktop
      actions={renderActions(smallScreen)}
      closeDialog={closeDialog}
      data-testid={props['data-testid']}
      eventData={renderEventData(data[eventIndex])}
      mediaComponent={renderMediaComponent?.(data[eventIndex])}
      open={open}
      pageTitle={renderTitle(data[eventIndex])}
    />
  );
}
