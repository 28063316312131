import { TableRow } from '@mui/material';
import { FC } from 'react';

interface Props {
  content: JSX.Element;
  'data-testid'?: string;
  href?: string;
  deleted?: boolean;
}

export const ResultsTableRow: FC<Props> = ({ content, href, deleted = false, ...props }) => {
  const hasLink = href !== undefined;

  return (
    <TableRow
      component={(!hasLink) ? 'div' : 'a'}
      data-testid={props['data-testid']}
      hover
      href={href}
      sx={{ cursor: (!hasLink) ? 'default' : 'pointer', height: '60px', textDecoration: deleted ? 'line-through' : 'none' }}
    >
      {content}
    </TableRow>
  );
};
