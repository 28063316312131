import { Typography } from '@mui/material';
import L from 'leaflet';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipProps } from 'react-leaflet';
import { EventBreadcrumbPopover } from '../../thing-event-pane/event-breadcrumb-popover';
import { EventLocationData } from '../../thing-event-pane/thing-event-pane.types';

interface Props extends TooltipProps {
  expanded?: boolean;
  notableData: EventLocationData[];
}

export const ClusterTooltip: FC<Props> = ({
  direction,
  eventHandlers,
  expanded = false,
  notableData,
  position,
}) => {
  const { t } = useTranslation(['common']);
  const ref = useRef<L.Tooltip>(null);

  const handleDataLoaded = (): void => {
    ref.current?.update();
  };

  if (!notableData.length) return <></>;

  return (
    <Tooltip
      ref={ref}
      direction={direction}
      eventHandlers={eventHandlers}
      pane="popupPane"
      position={position}
    >
      {(notableData.length > 1 && !expanded)
        ? <Typography>{t('common:component.events.hint.eventsWithCount', { count: notableData.length })}</Typography>
        : <EventBreadcrumbPopover data={[notableData[0]]} dataLoaded={handleDataLoaded} />}
    </Tooltip>
  );
};
