import { Location } from '@eagle/data-function-types';
import { FC, ReactNode } from 'react';
import { useSmallScreen } from '../../../hooks';
import { LayoutDesktop } from './layout-desktop';
import { LayoutMobile } from './layout-mobile';

interface PopupViewProps {
  children: ReactNode;
  handleCloseButtonClick: () => void;
  isPopupOpen: boolean;
  locationData?: Location;
  pane?: string;
}

export const PopupView: FC<PopupViewProps> = ({
  children,
  handleCloseButtonClick,
  isPopupOpen,
  locationData,
  pane,
}): JSX.Element => {
  const smallScreen = useSmallScreen();

  if (!locationData || !isPopupOpen) return <></>;

  if (smallScreen) {
    return (
      <LayoutMobile
        handleCloseButtonClick={handleCloseButtonClick}
        isPopupOpen={isPopupOpen}
      >
        {children}
      </LayoutMobile>
    );
  }

  return (
    <LayoutDesktop
      locationData={locationData}
      pane={pane}
    >
      {children}
    </LayoutDesktop>
  );
};
