import { Image } from '@eagle/api-types';
import { PersonType, ThingType } from '@eagle/core-data-types';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { CacheDataTypes } from '../../types';
import { FetchOneOfAll } from '../fetch';
import { ListPaperColumn } from '../list-item';

interface Props {
  data: {
    _id: string;
    display: string;
    thingTypeId?: string;
    personTypeId?: string;
    sharedThingTypeId?: string;
    image?: Image;
  };
  label?: JSX.Element;
  'data-testid'?: string;
  showAvatar?: boolean;
  inlineImage?: string;
  indicator?: string;
  link?: boolean;
  linkTestId?: string;
  sx?: SxProps;
}

export const TypeColumn: FC<Props> = ({ data, label, linkTestId, sx, ...props }) => {
  const dataType = (data.sharedThingTypeId)
    ? CacheDataTypes.SHARED_THING_TYPE
    : (data.thingTypeId ? CacheDataTypes.THING_TYPE : (data.personTypeId ? CacheDataTypes.PERSON_TYPE : null));

  const id = (dataType === CacheDataTypes.SHARED_THING_TYPE || CacheDataTypes.THING_TYPE)
    ? (data.sharedThingTypeId || data.thingTypeId || null)
    : (data.personTypeId || null);

  const column = !dataType
    ? <ListPaperColumn sx={sx} data-testid={props['data-testid']} label>{data.display}</ListPaperColumn>
    :
    <ListPaperColumn data-testid={props['data-testid']} sx={sx} label={label}>
      <FetchOneOfAll id={id} dataType={dataType} renderFactory={(entityType: ThingType | PersonType) => <>{entityType.display}</>} />
    </ListPaperColumn>;

  return <>{column}</>;
};
