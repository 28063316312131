import { Autocomplete, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { T_ONE } from '../../constants';
import { RecipientUnion } from './subscription-detail.types';

interface Props {
  disabled?: boolean;
  handleSelect: (value: RecipientUnion) => void;
  initialSelection?: RecipientUnion;
  recipients: RecipientUnion[];
}

export const RecipientAutocomplete: FC<Props> = ({ disabled, handleSelect, initialSelection, recipients }) => {
  const { t } = useTranslation(['common']);
  const [selectedRecipient, setSelectedRecipient] = useState(initialSelection);

  return (
    <Autocomplete<RecipientUnion, false, true, false>
      disableClearable
      disabled={disabled}
      freeSolo={false}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      getOptionLabel={(option) => option.display}
      onChange={(_, value) => {
        setSelectedRecipient(value);
        handleSelect(value);
      }}
      options={recipients}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('common:terms.recipient', { count: T_ONE })}
          required
          variant="outlined"
          data-testid="select-recipient-input"
        />
      )}
      renderOption={(props, option) => <li {...props} key={option._id}>{option.display}</li>}
      size="small"
      value={selectedRecipient}
      data-testid="select-recipient"
    />
  );
};
