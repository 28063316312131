import { Operator } from '@jexop/core';
import { isArray } from 'lodash';
import { ReactNode } from 'react';
import { LayoutGrid } from '../components';
import { Nullable } from '../types';

export const layoutGrid: Operator<ReactNode> = ({ template, ...props }): Nullable<ReactNode> => {
  if (!isArray(template)) return null;

  let templateString = '';
  let columns = 0;

  template.map((row: string[]) => {
    templateString += '"';
    columns = 0;
    row.map((cell) => {
      columns++;
      templateString += ` ${cell} `;
    });
    templateString += '"\n';
  });

  return <LayoutGrid template={templateString} columns={columns} {...props} />;
};
