import { Box } from '@mui/system';
import { DomEvent } from 'leaflet';
import { FC, useEffect, useRef } from 'react';

export const MapScrollableWrapper: FC<{ children: JSX.Element }> = ({ children }): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    DomEvent.disableScrollPropagation(ref.current);
  }, [ref]);

  return <Box ref={ref}>{children}</Box>;
};
