import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { SetStateAction } from 'react';
import { CacheDataTypes, CommonEntityWithDeleted } from '../../../types';
import { MapStorageKeys } from '../../../util';
import { FilterTypes } from '../../filter';

export interface LayerSelectionProps {
  activeLayers: MapLayerToggle[];
  handleBaseChange: (label: BaseLayers) => void;
  handleToggleChange: (label: ToggleLayers) => void;
  mapBaseLayers: MapLayerImage[];
  mapToggleLayers: MapLayerToggle[];
  open: boolean;
  selectedBaseLayer: BaseLayers;
  selectedToggleLayers: ToggleLayers[];
  setOpen: (value: SetStateAction<boolean>) => void;
  drawerLayerSelection: boolean;
  buttonSx?: SxProps<Theme>;
}

export interface MapLayerToggle {
  label: ToggleLayers;
  layerComponent: JSX.Element;
  maxBoundingBoxSize?: number;
  minZoomLevel?: number;
  showSubMenuButton?: boolean;
}

export interface MapImageButton {
  image: JSX.Element;
  label: string;
  onClick: () => void;
}

export interface MapLayerImage {
  image: JSX.Element;
  label: BaseLayers;
  layerComponent?: JSX.Element;
  mapStyle: (theme: Theme, apiKey: string, enableSource?: boolean) => Record<string, unknown>;
}

export enum ToggleLayers {
  geofences = 'geofences',
  incidents = 'incidents',
  petrolStations = 'petrol-stations',
  pointOfInterest = 'point-of-interest',
  traffic = 'traffic',
  transport = 'transport',
}

export enum BaseLayers {
  classic = 'classic',
  parcelBoundaries = 'parcel-boundaries',
  satellite = 'satellite',
}

export interface SubSectionData {
  cacheDataTypes: CacheDataTypes;
  cacheFilter: (items: CommonEntityWithDeleted[]) => CommonEntityWithDeleted[];
  itemType: string;
  storageKey: MapStorageKeys;
  typePropertyName: FilterTypes;
}

export enum SubMenuSectionData {
  GEOFENCE_TYPE = 'geofence-types',
  POINT_OF_INTEREST_TYPE = 'point-of-interest-type'
}
