/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TileLayer, useMap } from 'react-leaflet';
import { HERE_MAP_API_KEY, HERE_MAP_APP_CODE, HERE_MAP_APP_ID } from '../../../constants';
import { useConfig } from '../../../hooks/use-config';
import { VectorBaseMap } from '../vector-base-map';
import { classicMapStyle } from './classic-vector-map-style';
import { addMapLangParams } from './utils';

const NoGapTileLayer = styled(TileLayer)({
  '.leaflet-tile-container img': {
    height: '256.5px !important',
    width: '256.5px !important',
  },
});

const southKoreaCoordinates = {
  south: 32.7688004848817,
  north: 38.95121955224346,
  west: 121.77246093750001,
  east: 132.04800688972978,
};

const ClassicLayer: FC = () => {
  const { t, i18n } = useTranslation('common');
  const config = useConfig();
  const map = useMap();
  const theme = useTheme();
  const userLang = i18n.language;
  const [isSouthKorea, setIsSouthKorea] = useState(false);

  useEffect(() => {
    const updateMapLayer = (): void => {
      const bounds = map.getBounds();
      setIsSouthKorea(bounds.getSouth() > southKoreaCoordinates.south &&
        bounds.getNorth() < southKoreaCoordinates.north &&
        bounds.getWest() > southKoreaCoordinates.west &&
        bounds.getEast() < southKoreaCoordinates.east);
    };

    map.on('moveend zoomend dragend', updateMapLayer);
    return () => {
      map.off('moveend zoomend dragend', updateMapLayer);
    };
  }, [map]);

  useEffect(() => {
    const attributionText = t('common:component.map.labels.copyright-parcel-boundaries-map', { year: new Date().getFullYear() });
    map.attributionControl.addAttribution(attributionText);
    return () => {
      map.attributionControl.removeAttribution(attributionText);
    };
  }, [map, t]);

  const baseUrl = useMemo(() => addMapLangParams(
    `https://1.base.maps.api.heremaps.kr/maptile/2.1/streettile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=${config.hereMaps?.appId ?? HERE_MAP_APP_ID}&app_code=${config.hereMaps?.appCode ?? HERE_MAP_APP_CODE}`,
    userLang, isSouthKorea,
  ), [map, userLang, isSouthKorea]);

  if (isSouthKorea) {
    return <>
      <VectorBaseMap mapStyle={classicMapStyle(theme, config.hereMaps?.apiKey ?? HERE_MAP_API_KEY, false, i18n.language)} />
      <NoGapTileLayer key={`${userLang}-${isSouthKorea ? 'ko' : 'world'}`} url={baseUrl} />
    </>;
  }

  return <VectorBaseMap mapStyle={classicMapStyle(theme, config.hereMaps?.apiKey ?? HERE_MAP_API_KEY, !isSouthKorea, i18n.language)} />;
};

export default ClassicLayer;
