/* eslint-disable react-hooks/rules-of-hooks */
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import {
  RangeSelectOption,
  RangeSelectValues
} from '../date-time-range-picker';

export const createHourSelectOption = (
  value: RangeSelectValues,
): RangeSelectOption => {
  const { t } = useTranslation(['common']);

  return {
    value,
    label: t('common:component.date-time-range.labels.last-hour', {
      count: value.hours,
    }),
    startDate: () =>
      DateTime.now().minus({ hour: value.hours }),
    endDate: () => DateTime.now(),
  };
};
