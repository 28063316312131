/* eslint-disable react-hooks/exhaustive-deps */
import { TFunction } from 'i18next';
import { FC, KeyboardEvent as ReactKeyboardEvent, useMemo } from 'react';
import { useSmallScreen } from '../../hooks/use-small-screen';
import { AppliedFilter, AppliedFilterType, FilterField } from '../entity-search/types';
import { FilterDrawerContent } from '../filter';
import { AppliedFiltersMapDesktop, AppliedFiltersMapMobile } from '../filter/applied-filters-map';
import Layout from './layout';

interface Props {
  appliedFilters: AppliedFilter<AppliedFilterType>[];
  disableFilter: boolean;
  fields: FilterField[];
  filterDrawerComponent?: JSX.Element;
  handleInputFocus: () => void;
  isInlayMap?: boolean;
  listContent?: JSX.Element;
  onFiltersChanged: (filters: AppliedFilter[]) => unknown;
  onKeyDown: (event: ReactKeyboardEvent) => void;
  onKeyUp: (event: ReactKeyboardEvent) => void;
  openFilter: boolean;
  removeAllFilters: () => unknown;
  removeFilter: (filterToRemove: AppliedFilter) => unknown;
  searchBox: boolean;
  setFilterOpen: (value: boolean) => void;
  storageKey: string;
  tFunction: TFunction;
}

const View: FC<Props> = ({
  appliedFilters,
  disableFilter,
  fields,
  filterDrawerComponent,
  handleInputFocus,
  isInlayMap,
  listContent,
  onFiltersChanged,
  onKeyDown,
  onKeyUp,
  openFilter,
  removeAllFilters,
  removeFilter,
  searchBox,
  setFilterOpen,
  storageKey,
  tFunction,
}) => {
  const smallScreen = useSmallScreen();

  const appliedFiltersMobile = useMemo(
    () => appliedFilters.length && smallScreen
      ? <AppliedFiltersMapMobile
        filters={appliedFilters}
        removeAllFilters={removeAllFilters}
        removeFilter={removeFilter}
        tFunction={tFunction}
      />
      : undefined,
    [appliedFilters, smallScreen],
  );

  const appliedFiltersDesktop = useMemo(
    () => appliedFilters.length && !smallScreen
      ? <AppliedFiltersMapDesktop
        filters={appliedFilters}
        openFilter={openFilter}
        removeAllFilters={removeAllFilters}
        removeFilter={removeFilter}
        tFunction={tFunction}
      />
      : undefined,
    [appliedFilters, smallScreen],
  );

  const defaultFilterDrawerComponent = (
    <FilterDrawerContent
      fields={fields}
      filters={appliedFilters}
      onCloseClicked={() => setFilterOpen(false)}
      onFiltersChanged={onFiltersChanged}
      storageKey={storageKey}
    />
  );

  return <Layout
    disableFilter={disableFilter}
    filterDrawerContent={filterDrawerComponent ?? defaultFilterDrawerComponent}
    filtersDesktop={appliedFiltersDesktop}
    filtersLength={appliedFilters.length}
    filtersMobile={appliedFiltersMobile}
    handleInputFocus={handleInputFocus}
    isInlayMap={isInlayMap}
    listContent={(searchBox || isInlayMap) ? listContent : undefined}
    onKeyDown={onKeyDown}
    onKeyUp={onKeyUp}
    openFilter={openFilter}
    setFilterOpen={setFilterOpen}
    tFunction={tFunction}
  />;
};

export default View;
