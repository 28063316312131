import { Chip, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';

interface Props {
  'data-testid'?: string;
  tags: string[];
  sx?: SxProps;
}

export const Tags: FC<Props> = ({ tags, sx, ...props }) => {
  if (tags.length === 0) {
    return <></>;
  }

  return <Stack direction='row' sx={sx} data-testid={props['data-testid'] ?? 'tags-container'} flexWrap='wrap' gap={1}>
    {tags.map((tag, i) => (
      <Chip size='small' key={i} label={tag} data-testid={`tag-${i}`} />
    ))}
  </Stack>;
};
