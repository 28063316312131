import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import React, { memo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { COUNTRIES } from './constants';
import { FlagMenuItemProps } from './types';

const FlagMenuItem = (props: FlagMenuItemProps): JSX.Element => {
  const { isoCode, onSelectCountry, displayNames, ...menuItemProps } = props;

  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
    event.preventDefault();
    onSelectCountry(isoCode);
  };

  const countryName = displayNames.of(isoCode);

  return (
    <MenuItem
      {...menuItemProps}
      onClick={handleClick}
      role="option"
      data-testid={`phone-input-country-option-${isoCode.toString()}`}
      className="PhoneInput-MenuItem"
    >
      <ListItemIcon sx={{ marginRight: '10px ' }}>
        <ReactCountryFlag countryCode={isoCode} svg />
      </ListItemIcon>
      <ListItemText sx={{ marginRight: '10px ' }}>
        {countryName}
      </ListItemText>
      <Typography variant="body2" color="text.secondary">
        +{COUNTRIES[isoCode]?.[0]}
      </Typography>
    </MenuItem>
  );
};

export default memo(FlagMenuItem);
