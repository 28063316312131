/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FILTER_SELECT_ALL_FLAG } from '../../util';
import { testid } from '../../util/test-id';
import { EntityField } from '../entity-search/types';
import { useBoolFlag } from '../flags';

interface Props {
  isApplied: (value: EntityField) => boolean;
  onChanged: (value: EntityField[]) => void;
  values: EntityField[];
}

export const FilterInputEntity: FC<Props> = ({ isApplied, onChanged, values }) => {
  const { t } = useTranslation(['common']);
  const hasFilterSelectAllFlag = useBoolFlag(FILTER_SELECT_ALL_FLAG);

  const [checkedItems, setCheckedItems] = useState<Record<string, EntityField>>(
    values.filter(isApplied)
      .reduce((obj, item) => ({ ...obj, [item.id]: item }), {}),
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const selectedItem = values.find((v) => v.id === event.target.id);
    if (!selectedItem) return;

    if (!checkedItems) {
      setCheckedItems({ [selectedItem.id]: selectedItem });
      return;
    }
    if (checkedItems[selectedItem.id]) {
      const remainingItems = { ...checkedItems };
      delete remainingItems[selectedItem.id];
      setCheckedItems(remainingItems);
      return;
    }
    setCheckedItems({
      ...checkedItems,
      [selectedItem.id]: selectedItem,
    });
  };

  useEffect(() => {
    setCheckedItems(values.filter(isApplied).reduce((obj, item) => ({ ...obj, [item.id]: item }), {}));
  }, [values]);

  useEffect(() => {
    if (!checkedItems || !onChanged) return;
    onChanged(Object.values(checkedItems));
  }, [checkedItems]);

  return (
    <Stack>
      {hasFilterSelectAllFlag && (
        <FormControlLabel
          control={<Checkbox checked={Object.keys(checkedItems).length === values.length} />}
          label={<strong>{t('common:component.filter-dropdown.labels.select-all')}</strong>}
          onChange={(_, checked) => {
            setCheckedItems(checked ? values.reduce((obj, item) => ({ ...obj, [item.id]: item }), {}) : {});
          }}
        />
      )}
      {values.map((value) => {
        return (
          <FormControlLabel
            data-testid={testid`${value.display}`}
            key={value.id}
            control={<Checkbox onChange={handleChange} id={value.id} checked={Object.keys(checkedItems).includes(value.id)} />}
            label={value.display}
          />
        );
      })}
    </Stack>
  );
};
