/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { LegacyRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { usePromise } from '../../hooks';
import { Nullable } from '../../types';
import { getSignedUrl } from '../../util';
import { MiddleSpinner } from '../middle-spinner';
import { videoPlayerStyles } from './video-player.styles';
import { RowItemProps, VideoProps } from './video-player.types';

export const VideoComponent = React.forwardRef((properties: VideoProps, ref) => {
  const { fixedSize, fullscreen, onCanPlay, onError: onErrorProp, src, ...props } = properties;
  const { classes } = videoPlayerStyles();
  const { restClient } = useAuthenticated();
  const [expires, setExpires] = useState<Nullable<DateTime>>(null);
  const [updatableUrl, setUpdatableUrl] = useState<string>(src);
  const [pendingUpdate, setPendingUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoUrl, videoError, videoState] = usePromise(
    async () => {
      const result = await getSignedUrl(restClient, src);
      setUpdatableUrl(result.signedUrl || src);
      const urlData = new URL(result.signedUrl ?? src);
      setExpires(getExpiryDate(urlData));
      return result.signedUrl;
    }, [src, restClient, setUpdatableUrl]);
  const [error, setError] = useState<Nullable<Error>>(null);
  const { t } = useTranslation(['common']);

  const updateUrl = (): void => {
    setPendingUpdate(true);

    try {
      void getSignedUrl(restClient, src)
        .then((data) => {
          const urlData = new URL(data.signedUrl ?? videoUrl ?? src);
          setExpires(getExpiryDate(urlData));
          setUpdatableUrl(urlData.href);
          setPendingUpdate(false);
        }).catch((e) => {
          setError(e as Error);
          setPendingUpdate(false);
        });
    } catch (e) {
      setError(e as Error);
      setPendingUpdate(false);
    }
  };

  const onError = (): void => {
    const errorWithMessage = new Error(t('common:component.video-modal.hint.player-error'));
    if (videoState === 'rejected') return setPendingUpdate(false);
    if (!expires) return setError(new Error());
    if (DateTime.now() > expires) {
      setError(null);
      return updateUrl();
    }
    setError(errorWithMessage);
    onErrorProp && onErrorProp(errorWithMessage);
  };

  const SpaceWrapper = ({ children }: RowItemProps): JSX.Element => <div style={{ height: '360px', width: '640px' }}>{children}</div>;

  if (videoState === 'pending' || pendingUpdate) {
    return (
      <SpaceWrapper>
        <MiddleSpinner />
      </SpaceWrapper>
    );
  }
  if (error || videoError || !videoUrl) {
    const errorMessage = t('common:component.video-modal.hint.player-error');
    onErrorProp && onErrorProp(new Error(errorMessage));
    return (
      <SpaceWrapper>
        <Typography
          className={classes.errorText}
          variant="caption"
        >{errorMessage}</Typography>
      </SpaceWrapper>
    );
  }

  return <>
    {loading && <MiddleSpinner sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />}
    <video
      {...props}
      onCanPlay={() => {
        onCanPlay && onCanPlay();
        setLoading(false);
      }}
      onError={onError}
      ref={ref as LegacyRef<HTMLVideoElement>}
      src={updatableUrl}
      style={{ maxWidth: '100vw', objectFit: 'cover', width: 'auto', maxHeight: (fixedSize && !fullscreen) ? '65vh' : '100vh', height: 'auto' }}
      onWaiting={() => setLoading(true)}
      data-testid={props['data-testid']}
    />
  </>;
});

VideoComponent.displayName = 'VideoComponent';

const getExpiryDate = (url: URL): Nullable<DateTime> => {
  try {
    const amzDate = url.searchParams.get('X-Amz-Date');
    const amzExpires = url.searchParams.get('X-Amz-Expires');
    if (!amzExpires || !amzDate) {
      return null;
    }
    const date = DateTime.fromISO(amzDate);
    if (!date.isValid) {
      return null;
    }
    const expiresInSeconds = Number(amzExpires);
    if (!expiresInSeconds) {
      return null;
    }
    return date.plus({ seconds: expiresInSeconds });
  }
  catch (error) {
    console.error(error);
    return null;
  }
};
