/* eslint-disable react-hooks/exhaustive-deps */
import { Id } from '@eagle/api-types';
import { useEffect, useState } from 'react';
import { Undefinable } from '../../../types';

export const useTemporaryMap = <T extends Id<string>>(items: Undefinable<T[]>, map: L.Map): Map<string, T> => {
  const [itemsMap, setItemsMap] = useState<Map<string, T>>(new Map());

  useEffect(() => {
    if (!items) return;
    const temporaryMap = new Map();
    items.forEach((item) => temporaryMap.set(item._id, item));

    map.whenReady(() => setItemsMap(temporaryMap));
  }, [items]);

  return itemsMap;
};
