import { makeStyles } from '../../theme';

export const videoModalStyles = makeStyles()(() => ({
  videoBox: {
    maxHeight: '100vh',
    maxWidth: '100vw',
    objectFit: 'cover',
  },
  videoModal: {
    left: '50%',
    outline: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
