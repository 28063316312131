import { FC } from 'react';
import { useFetchAllCache, useFetchOneCache, usePromise } from '../../hooks';
import { DisplayWithDeleted } from '../../types';
import { FieldLoadError, FieldLoading } from '../field';
import { SimpleElement } from '../simple-element';

interface Props {
  dataType: string;
  id: unknown;
  loadingFactory?: () => JSX.Element;
  notFoundFactory?: () => JSX.Element;
  renderFactory?: (data: any) => JSX.Element;
}

const DEFAULT_NOT_FOUND = (): JSX.Element => <FieldLoadError />;
const DEFAULT_LOADING = (): JSX.Element => <FieldLoading />;

const defaultRenderFactory = (data: any): JSX.Element => {
  return <SimpleElement data={data as DisplayWithDeleted} />;
};

export const FetchOne: FC<Props> = ({ dataType, id, renderFactory = defaultRenderFactory, notFoundFactory = DEFAULT_NOT_FOUND, loadingFactory = DEFAULT_LOADING }) => {
  const cache = useFetchOneCache(dataType);
  const [result, , state] = usePromise(
    () => cache.one(id),
    [cache, id],
  );

  if (state === 'pending') return loadingFactory();
  if (state === 'rejected') return <FieldLoadError />;
  if (!result) return notFoundFactory();

  return renderFactory(result);
};

export const FetchOneOfAll: FC<Props> = ({ dataType, id, renderFactory = defaultRenderFactory, notFoundFactory = DEFAULT_NOT_FOUND, loadingFactory = DEFAULT_LOADING }) => {
  const cache = useFetchAllCache(dataType);
  const [result, , state] = usePromise(
    () => cache.one(id),
    [cache, id],
  );

  if (state === 'pending') return loadingFactory();
  if (state === 'rejected') return <FieldLoadError />;
  if (!result) return notFoundFactory();

  return renderFactory(result);
};
