/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { PointOfInterest, PointOfInterestSet, PointOfInterestType } from '@eagle/core-data-types';
import { Location } from '@eagle/data-function-types';
import { Chip, Link, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';
import { useCustomRoutes } from '../../../../hooks';
import { Portals } from '../../../../types';
import { MapPanes, switchToPortal, useHasAuthorization } from '../../../../util';
import { EventAddress } from '../../../events';
import { MapPopup } from '../../map-popup';

interface PointOfInterestDetailsProps {
  pointOfInterest: PointOfInterest;
  pointOfInterestSet: PointOfInterestSet;
  pointOfInterestType: PointOfInterestType;
}

interface PointOfInterestPopupProps {
  closePopup: () => void;
  isPopupOpen: boolean;
  pointOfInterest: PointOfInterest;
  pointOfInterestSet: PointOfInterestSet;
  pointOfInterestType: PointOfInterestType;
}

const POINT_OF_INTEREST_ADMIN = [RoleFunction.POINT_OF_INTEREST_ADMINISTRATOR] as const;

const PointOfInterestDetails: FC<PointOfInterestDetailsProps> = ({ pointOfInterest, pointOfInterestSet, pointOfInterestType }): JSX.Element => {
  const { t } = useTranslation(['common']);
  const { _id, display, description, location, tags } = pointOfInterest;
  const { pointOfInterest: customRoutesPointOfInterest } = useCustomRoutes();
  const { hasAuthorization } = useHasAuthorization();

  return (
    <Stack>
      <Stack alignItems="center" direction="row">
        {hasAuthorization(POINT_OF_INTEREST_ADMIN)
          ? <Tooltip arrow followCursor placement="top" title={display}>
            <Link
              data-testid="thing-map-poi-popup-display"
              href={switchToPortal(null, Portals.ADMIN, `${customRoutesPointOfInterest}/${_id}`)}
              sx={{ cursor: 'pointer', fontWeight: '500', ml: '3px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              variant="subtitle1"
            >
              {display}
            </Link>
          </Tooltip>
          : <Typography>
            {display}
          </Typography>
        }
      </Stack>
      <Stack sx={{ mb: 1 }}>
        <Typography color="text.secondary" variant="subtitle1">{pointOfInterestType.display}</Typography>
        <Typography color="text.secondary" variant="caption">{pointOfInterestSet.display}</Typography>
      </Stack>
      <EventAddress
        addressUnavailableText={t('common:page.thing-map.address-unavailable.hint')}
        location={location as Location}
        variant="caption"
      />
      <Typography color="text.secondary" variant="caption">{description}</Typography>
      <Stack sx={{ mt: 1 }} direction="row" spacing={0.5}>
        {tags.concat(tags).map((tag, i: number) => (
          <Chip
            key={i}
            label={tag}
            size="small"
            sx={{
              background: 'inherit',
              mb: '4px',
              mr: '4px',
            }}
            variant="outlined"
          />
        ))}
      </Stack>
    </Stack>
  );
};

export const PointOfInterestPopup: FC<PointOfInterestPopupProps> = ({ pointOfInterest, pointOfInterestSet, pointOfInterestType, isPopupOpen, closePopup }) => {
  const { location } = pointOfInterest;
  const map = useMap();

  useEffect(() => {
    if (!isPopupOpen) return;
    map.addEventListener('click', closePopup);

    return () => {
      map.removeEventListener('click', closePopup);
    };
  }, [isPopupOpen]);

  return (
    <MapPopup
      handleCloseButtonClick={closePopup}
      isPopupOpen={isPopupOpen}
      locationData={location as Location}
      pane={MapPanes.POPUP_PANE}
    >
      <PointOfInterestDetails
        pointOfInterest={pointOfInterest}
        pointOfInterestType={pointOfInterestType}
        pointOfInterestSet={pointOfInterestSet}
      />
    </MapPopup>
  );
};
