/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSmallScreen } from '../../hooks';
import { makeStyles } from '../../theme';
import { Nullable } from '../../types';

interface Props extends PropsWithChildren {
  sx?: SxProps;
  'data-testid'?: string;
  deleted?: boolean;
}

const calculateOverflow = (target: Nullable<HTMLDivElement>): boolean => !!target && target.offsetWidth < target.scrollWidth;

export const OverflowTooltip: FC<Props> = ({ children, sx, deleted, ...props }) => {
  const { t } = useTranslation(['common']);
  const smallScreen = useSmallScreen();
  const [showToolTip, setShowToolTip] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const updateTooltip = (target = ref.current): void => setShowToolTip(calculateOverflow(target));

  const mutationCallback = (mutationList: MutationRecord[]): void => {
    mutationList.map(({ target }) => updateTooltip(target as HTMLDivElement));
  };

  const { classes } = makeStyles()((theme) => ({
    tooltip: {
      color: theme.tooltip.contrastText,
      background: theme.tooltip.background,
      '& .MuiLink-root': {
        color: theme.tooltip.contrastText,
        background: theme.tooltip.background,
        textDecoration: 'none',
      },
    },
  }))();

  useEffect(() => {
    if (deleted) {
      setShowToolTip(true);
      return;
    }
    const observer = new MutationObserver(mutationCallback);
    if (ref.current) {
      updateTooltip();
      observer.observe(ref.current, { attributes: true, childList: true, subtree: true });
    }

    window.addEventListener('resize', () => updateTooltip());
    return () => {
      window.removeEventListener('resize', () => { });
      observer.disconnect();
    };
  }, [ref]);

  return (
    <Tooltip arrow followCursor={deleted && !smallScreen} disableFocusListener={!deleted} disableTouchListener={!deleted} disableHoverListener={!showToolTip} title={deleted ? t('common:common.hint.deleted') : <Box className={classes.tooltip}>{children}</Box>}>
      <Box data-testid={props['data-testid']} ref={ref} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', textDecoration: deleted ? 'line-through' : 'none', ...sx }}>
        {children}
      </Box>
    </Tooltip>
  );
};
