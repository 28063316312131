import cx from 'classnames';
import { CSSProperties, FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export const MenuLink: FC<NavLinkProps> = (props) => {
  const style: CSSProperties = {
    color: 'unset',
    fontSize: 'unset',
    margin: 0,
    padding: 0,
    textDecoration: 'unset',
    textTransform: 'unset',
  };

  return (
    <NavLink
      end={true}
      style={style}
      {...props}
      className={cx('MenuLink-root', props.className)}
    />
  );
};
