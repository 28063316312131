import { colors, createTheme, Theme, ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import chroma from 'chroma-js';
import { createMakeStyles } from 'tss-react';

export const theme = (custom: ThemeOptions = {}): Theme => {
  const base: ThemeOptions = {
    palette: {
      mode: 'light',
      primary: {
        main: '#0097FE',
      },
      secondary: {
        main: '#512DA8',
      },
    },
    landingPage: {
      footer: {
        logo: 'dark',
      },
    },
    logo: {
      light: '#ffffff',
      dark: '#231F20',
    },
    marker: {
      icon: {
        fill: '#FFFFFF',
        size: '1rem',
      },
      dot: {
        size: '25px',
      },
    },
    mapPopup: {
      padding: 1,
      borderRadius: 8,
      width: 320,
    },
    sidebar: {
      icons: {
        size: '1.5rem',
      },
    },
    dataCard: {
      avatarSize: '3rem',
    },
    typography: {
      fontFamily: '"Red Hat Display", sans-serif',
      body1: {
        fontWeight: 400,
      },
      body2: {
        fontWeight: 400,
      },
      h1: {
        fontWeight: 600,
      },
      h2: {
        fontWeight: 600,
      },
      h3: {
        fontWeight: 600,
      },
      h4: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: 600,
      },
      dataCardLabel: {
        fontSize: '0.75rem',
        fontWeight: 400,
      },
      dataCardTitle: {
        fontSize: '1rem',
        fontWeight: 400,
      },
      dataCardHint: {
        fontSize: '0.75rem',
        fontWeight: 400,
      },
    },
    states: {
      off: '#1a1a1a',
      on: '#61b902',
      paused: '#fac710',
      uncertain: '#cccccc',
      unknown: '#808080',
    },
    tooltip: {
      background: 'rgba(97, 97, 97, 0.92)',
      contrastText: '#ffffff',
    },
    zIndex: {
      appBar: 1250,
    },
  };

  const intermediate = createTheme(deepmerge(base, custom));
  const sidebarBackgroundColor = intermediate.sidebar.background || intermediate.palette.grey[100];
  const sidebarTextColor = intermediate.sidebar.contrastText || intermediate.palette.text.primary;

  return createTheme(intermediate, {
    palette: {
      background: {
        default: chroma.mix(
          intermediate.palette.background.default,
          chroma(intermediate.palette.text.primary).alpha(1),
          0.05,
        ).css(),
      },
    },
    components: {
      MuiCardActions: {
        styleOverrides: {
          root: {
            paddingLeft: intermediate.spacing(3),
            paddingRight: intermediate.spacing(3),
            paddingTop: intermediate.spacing(3),
            paddingBottom: 0,
            '&:last-child': {
              paddingBottom: intermediate.spacing(3),
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            height: 'min-content',
            overflow: 'unset',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            paddingLeft: intermediate.spacing(3),
            paddingRight: intermediate.spacing(3),
            paddingTop: intermediate.spacing(3),
            paddingBottom: 0,
            '&:last-child': {
              paddingBottom: intermediate.spacing(3),
            },
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            paddingLeft: intermediate.spacing(3),
            paddingRight: intermediate.spacing(3),
            paddingTop: intermediate.spacing(3),
            paddingBottom: 0,
            '&:last-child': {
              paddingBottom: intermediate.spacing(3),
            },
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            '& svg': {
              fontSize: '1.5rem',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            paddingLeft: intermediate.spacing(3),
            paddingRight: intermediate.spacing(3),
          },
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            maxHeight: '300px',
            '& .PrivatePickersSlideTransition-root': {
              minHeight: '200px',
            },
            '& .MuiMonthPicker-root': {
              width: '300px',
            },
            '& .PrivatePickersMonth-root': {
              border: `solid 4px ${intermediate.palette.background.paper}`,
              borderRadius: '8px',
              height: '60px',
            },
            '& .Mui-selected': {
              fontSize: intermediate.typography.subtitle1.fontSize,
            },
          },
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            '& .ListPaperColumn-root': {
              overflow: 'hidden',
            },
          },
        },
      },
    },
    alerts: {
      amber: '#ffc107',
      blue: '#2196f3',
      default: colors.grey[600],
      green: '#357a38',
      orange: '#fd906e',
      purple: '#9c27b0',
      red: '#f44336',
      teal: '#1de9b6',
      white: '#ffffff',
    },
    colorMap: [
      '#2196F3',
      '#FF4528',
      '#51D751',
      '#FFD633',
      '#B21CB5',
      '#A96E37',
      '#FF69B4',
      '#FF7F4D',
      '#8F00FF',
      '#F04B74',
    ],
    cluster: {
      spiderLeg: '#222',
    },
    drawer: {
      puller: '#757575',
    },
    eventBreadcrumb: {
      duress: {
        fill: '#fac710',
        stroke: '#fac710',
      },
      first: {
        fill: '#61b902',
        stroke: '#61b902',
      },
      hoverCursor: {
        fill: '#ffffff',
        stroke: '#0000ff',
      },
      intermediary: {
        fill: '#808080',
        stroke: '#808080',
      },
      last: {
        fill: '#2196f3',
        stroke: intermediate.palette.common.white,
      },
      rollOver: {
        fill: '#f44336',
        stroke: '#f44336',
      },
    },
    incident: {
      clusterBackgroundColor: '#f5c449',
      clusterBorder: `${chroma('#f5c449').alpha(.50).css()} solid .25rem`,
      roadColor: '#f44336',
      size: '1.5rem',
    },
    landing: {
      signInButton: '#f24726',
      signInButtonDark: '#d13819',
    },
    lastContact: {
      current: intermediate.palette.success.main,
      recent: intermediate.palette.warning.main,
      offline: intermediate.palette.grey[500],
    },
    map: {
      drawing: {
        color: '#0D99FF',
      },
      editControl: {
        disabled: '#f4f4f4',
      },
    },
    pin: {
      search: '#f24726',
    },
    placeholder: {
      image: '#9e9e9e',
    },
    puller: '#757575',
    videoSegment: {
      scrollbarHeight: '16px',
      tableBorder: `1px solid ${chroma(intermediate.palette.common.black).alpha(0.12).css()}`,
      videoCellBoxShadow: `0px 2px 1px -1px ${chroma(intermediate.palette.common.black).alpha(0.2).css()}, 0px 1px 1px ${chroma(intermediate.palette.common.black).alpha(0.14).css()}, 0px 1px 3px ${chroma(intermediate.palette.common.black).alpha(0.12).css()}`,
      videoCellFocusedBoxShadow: `0px 7px 9px -4px ${chroma(intermediate.palette.common.black).alpha(0.2).css()},0px 14px 21px 2px ${chroma(intermediate.palette.common.black).alpha(0.14).css()},0px 5px 26px 4px ${chroma(intermediate.palette.common.black).alpha(0.12).css()}`,
    },
    videoStatus: {
      notAvailable: '#757575',
      noLongerAvailable: '#808080',
      recorded: '#0299D6',
      requested: '#F2994A',
      uploadComplete: '#1F9F57',
      uploadCompleteVisited: '#75C196',
      uploadFailed: '#EB5757',
      uploadStarted: '#F2C94C',
    },
    marker: {
      background: intermediate.marker.background || intermediate.palette.primary.main,
    },
    mapPopup: {
      background: intermediate.mapPopup?.background || intermediate.palette.grey[100],
      borderRadius: intermediate.mapPopup?.borderRadius || intermediate.shape.borderRadius,
      contrastText: intermediate.mapPopup?.contrastText || intermediate.palette.text.primary,
    },
    heading: {
      icon: {
        color: intermediate.heading?.icon?.color || intermediate.palette.primary.main,
      },
    },
    landingPage: {
      background: intermediate.landingPage.background || intermediate.palette.grey[100],
      footer: {
        contrastText: intermediate.landingPage.footer?.contrastText || intermediate.palette.text.primary,
      },
      particleColor: intermediate.landingPage.particleColor || intermediate.palette.grey[400],
      signInButton: {
        background: intermediate.landingPage.signInButton?.background || intermediate.palette.primary.main,
        contrastText: intermediate.landingPage.signInButton?.contrastText || intermediate.palette.primary.contrastText,
      },
    },
    sidebar: {
      contrastText: sidebarTextColor,
      background: sidebarBackgroundColor,
      icons: {
        color: intermediate.sidebar.icons?.color || sidebarTextColor,
      },
      avatar: {
        background: intermediate.sidebar.avatar?.background || sidebarBackgroundColor,
        iconColor: intermediate.sidebar.avatar?.iconColor || sidebarTextColor,
      },
    },
    wizard: {
      header: {
        background: intermediate.wizard?.header?.background || intermediate.palette.grey[100],
        contrastText: intermediate.wizard?.header?.contrastText || intermediate.palette.text.primary,
        progressColor: intermediate.wizard?.header?.progressColor || intermediate.palette.primary.main,
        subText: intermediate.wizard?.header?.subText || intermediate.palette.text.secondary,
      },
    },
    dataCard: {
      avatarSize: intermediate.dataCard.avatarSize,
      background: intermediate.dataCard?.background || intermediate.palette.grey[200],
      contrastText: intermediate.dataCard?.contrastText || intermediate.palette.text.primary,
      borderRadius: intermediate.dataCard?.borderRadius || intermediate.shape.borderRadius,
    },
  });
};

const createBaseTheme = (custom?: ThemeOptions): Theme => {
  return (theme(custom));
};

export const {
  makeStyles,
  useStyles,
} = createMakeStyles({ useTheme: createBaseTheme });
