import { User } from '@eagle/core-data-types';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchOne } from '../../components';
import { CacheDataTypes } from '../../types';
import { ContactMethodRadioButtons, RadioButtons } from './subscription-detail.types';

interface DefaultRadioLabelProps {
  contactMethod: ContactMethodRadioButtons;
  isProfilePage: boolean;
  userId?: string;
}

export const DefaultRadioLabel: FC<DefaultRadioLabelProps> = ({ contactMethod, isProfilePage, userId }) => {
  const { t } = useTranslation(['admin']);

  const getLabel = (method: ContactMethodRadioButtons): string => {
    switch (method) {
      case RadioButtons.EMAIL: return t('admin:page.create-subscription.notification-recipient.labels.use-default-email');
      case RadioButtons.SMS: return t('admin:page.create-subscription.notification-recipient.labels.use-default-number');
    }
  };

  const getCaption = (method: ContactMethodRadioButtons, user: User): string => {
    switch (method) {
      case RadioButtons.EMAIL:
        if (!user?.notificationChannel?.email) return '';
        return t('admin:page.create-subscription.notification-recipient.labels.email-to-address', { emailAddress: user.notificationChannel.email });
      case RadioButtons.SMS:
        if (!user?.notificationChannel?.sms) return '';
        return t('admin:page.create-subscription.notification-recipient.labels.sms-to-number', { number: user.notificationChannel.sms });
    }
  };

  return (
    <FetchOne
      id={userId}
      dataType={isProfilePage ? CacheDataTypes.MY_USER : CacheDataTypes.ADMIN_USER}
      renderFactory={(data: User) => (
        <Stack>
          {getLabel(contactMethod)}
          <Typography variant="caption">{getCaption(contactMethod, data)}</Typography>
        </Stack>
      )}
    />
  );
};
