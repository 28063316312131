import { Box, Typography } from '@mui/material';
import { ResponsiveStyleValue, SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  'data-testid'?: string;
  direction?: ResponsiveStyleValue<'row' | 'row-reverse' | 'column' | 'column-reverse'>;
  label: string;
  variant?: 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption';
  sx?: SxProps;
}

export const LabelIcon: FC<Props> = ({ children, label, variant = 'caption', sx, direction, ...props }) => {
  return <Box sx={{ display: 'flex', flexDirection: direction, textAlign: 'center', marginTop: 0, ...sx }} data-testid={props['data-testid']}>
    <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>{children}</Box>
    <Typography sx={{ margin: 0 }} variant={variant}>{label}</Typography>
  </Box>;
};
