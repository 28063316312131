/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { LOOKUP_DEBOUNCE_TIME } from '../../constants';
import { CommonEntityWithDeleted } from '../../types/entity';
import { FILTER_SELECT_ALL_FLAG } from '../../util';
import { EntityField, SelectedFilterType } from '../entity-search/types';
import { useBoolFlag } from '../flags';
import { Lookup } from '../lookup';
import { simpleRenderer } from '../simple-element';

interface Props<T> {
  appliedFilters: EntityField[];
  'data-testid'?: string;
  findEntity: (search: string) => Promise<T[]>;
  isSearch?: boolean;
  label?: string;
  noResults?: string;
  onChanged: (value: SelectedFilterType) => void;
}

/**
  * NATIVE FUNCTION: Filter lookup component - single input
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function FilterInputLookupMultipleNew<T extends CommonEntityWithDeleted>({
  appliedFilters,
  findEntity,
  isSearch,
  label,
  noResults,
  onChanged,
  ...props
}: Props<T>): JSX.Element {
  const getSelectedValues = (): T[] => {
    const initialSelected = appliedFilters.map(({ display, id }) => {
      return { display, _id: id };
    });
    return initialSelected as T[];
  };
  const [selected, setSelected] = useState<T[]>(getSelectedValues());
  const hasFilterSelectAllFlag = useBoolFlag(FILTER_SELECT_ALL_FLAG);

  useEffect(() => setSelected(getSelectedValues()), [appliedFilters]);

  const handleChange = (items: T[]): void => {
    setSelected(items);
    const newValues: EntityField[] = items.map(({ display, _id }) => { return { display, id: _id }; });
    onChanged(newValues);
  };

  return (
    <Lookup<T, true>
      data-testid={props['data-testid']}
      debounceTime={isSearch ? LOOKUP_DEBOUNCE_TIME : 0}
      displaySelectAll={hasFilterSelectAllFlag ? !isSearch : false}
      handleCompareItems={(option, value) => option._id === value._id}
      handleFindItems={findEntity}
      handleFormatListItem={simpleRenderer}
      handleFormatSelectedItem={(option) => option.display}
      isSearch={isSearch}
      multiple
      label={label}
      noResultsInstructions={noResults}
      onItemSelected={handleChange}
      selectedItem={selected}
      size="small"
    />
  );
}
