import { Autocomplete, TextField, useTheme } from '@mui/material';
import ISO6391 from 'iso-639-1';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { testid } from '../../util';

export interface LocaleSelectProps {
  'data-testid'?: string;
  fieldDisabled?: boolean;
  selectedLocale: string;
  setSelectedLocale: (value: string) => void;
  size?: 'small' | 'medium';
}

export const getBrowserLocale = (): string => {
  return navigator.language.split('-')[0];
};

export const LocaleSelect: FC<LocaleSelectProps> = ({
  fieldDisabled,
  selectedLocale,
  setSelectedLocale,
  size = 'small',
  ...props
}) => {
  const { t } = useTranslation(['common', 'l10n']);
  const theme = useTheme();

  return (
    <Autocomplete
      data-testid={props['data-testid']}
      disableClearable
      disabled={fieldDisabled}
      getOptionLabel={(localeCode: string) => !localeCode.length
        ? t('common:component.locale-select.labels.use-default')
        : t(`l10n:locales.${localeCode}`)
      }
      onChange={(_, value) => setSelectedLocale(value)}
      options={['', ...ISO6391.getAllCodes()]}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid="text-input"
          label={t('common:component.locale-select.labels.locale')}
        />
      )}
      renderOption={(optionProps, option) => !option.length
        ? <li data-testid="default-option" {...optionProps}><i>{t('common:component.locale-select.labels.use-default')}</i></li>
        : <li data-testid={testid`option-${option}`} {...optionProps}>{t(`l10n:locales.${option}`)}</li>
      }
      sx={{
        '& .MuiAutocomplete-input': {
          color: !selectedLocale ? theme.palette.text.secondary : theme.palette.text.primary,
          fontStyle: !selectedLocale ? 'italic' : 'normal',
        },
      }}
      size={size}
      value={selectedLocale}
    />
  );
};
