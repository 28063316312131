import { Breakpoint, Dialog as MuiDialog, DialogActions as MuiDialogActions, DialogContent as MuiDialogContent, DialogProps as MuiDialogProps, DialogTitle, Divider, Slide, Stack, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { FC, forwardRef, PropsWithChildren } from 'react';
import { DialogCloseButton } from '../dialog';

interface Props extends PropsWithChildren {
  actions?: React.ReactNode;
  'data-testid'?: string;
  disableCloseOnBackdropClick?: boolean;
  disableEscKey?: boolean;
  fullWidth?: boolean;
  maxWidth?: Breakpoint;
  hasCloseBtn?: boolean;
  onClose: () => void;
  open: boolean;
  scroll?: boolean;
  title?: string;
}

type DialogCloseReasons = 'escapeKeyDown' | 'backdropClick';

const Transition = (
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
): JSX.Element => {
  return <Slide direction="up" ref={ref} {...props} />;
};
const TransitionComponent = forwardRef(Transition);

export const ServiceHistoryDialog: FC<Props> = ({
  actions,
  children,
  disableCloseOnBackdropClick,
  disableEscKey,
  fullWidth,
  maxWidth,
  hasCloseBtn,
  onClose,
  open,
  scroll,
  title,
  ...props
}) => {
  const theme = useTheme();
  const handleClose = (reason: DialogCloseReasons): void => {
    if (disableCloseOnBackdropClick && reason === 'backdropClick') return;
    onClose();
  };

  return <DialogRoot
    data-testid={props['data-testid']}
    disableEscapeKeyDown={disableEscKey}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    onClose={(_, reason) => handleClose(reason)}
    open={open}
    scroll={scroll ? 'paper' : undefined}
    sx={{ zIndex: theme.zIndex.drawer + 102 }}
    aria-labelledby="service-dialog-title"
  >
    {hasCloseBtn && <DialogCloseButton onClick={onClose} />}
    <DialogTitle id="service-dialog-title" data-testid="title">{title}</DialogTitle>
    <Divider orientation='horizontal' flexItem />
    {children && <MuiDialogContent data-testid="content" sx={{ maxHeight: scroll ? '80vh' : 'inherit' }}>{children}</MuiDialogContent>}
    {actions && <DialogActions>{actions}</DialogActions>}
  </DialogRoot>;
};

interface DialogRootProps extends MuiDialogProps {
  'data-testid'?: string;
}

const DialogRoot: FC<DialogRootProps> = (props) => {
  return (
    <MuiDialog TransitionComponent={TransitionComponent} {...props} />
  );
};

const DialogActions: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MuiDialogActions data-testid="content" >
      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} sx={{ pb: 2, px: 3, width: 1 }}>{children}</Stack>
    </MuiDialogActions>
  );
};
