import { Box } from '@mui/material';
import { FC } from 'react';
import { PageMap, PageMapProps } from '../../components/map';
import { useMapContext } from '../../util';

export const MapLayout: FC<PageMapProps> = ({ children, ...props }): JSX.Element => {
  const { mapDimming } = useMapContext();

  return (
    <Box
      className="MapLayout-root"
      display="flex"
      sx={{
        height: '100%',
        justifyContent: 'end',
        position: 'relative',
        width: '100%',
      }}
    >
      <Box
        display="flex"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: 'inherit',
          height: 'inherit',
          zIndex: 0,
          overflow: 'hidden',
        }}
      >
        <PageMap {...props} dim={mapDimming} >
          {children}
        </PageMap>
      </Box>
    </Box>
  );
};
