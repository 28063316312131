/* eslint-disable react-hooks/exhaustive-deps */
import { TypeDefinitionTypes } from '@eagle/common';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { FILTER_LOOKUP_LIMIT } from '../../constants';
import { FetchAllCache, usePromise } from '../../hooks';
import { CommonEntityWithDeleted, Undefinable } from '../../types';
import { filterDeletedCache, FILTER_OUT, NEW_ALERT_FILTER_FLAG, searchData } from '../../util';
import { AppliedFilterType, EntityField, FilterFieldTypes, SelectedFilterType } from '../entity-search';
import { FilterDataTypes, FilterInputLookup, FilterInputNumber, FilterInputString, FilterTypes } from '../filter';
import { FilterAlertTypeField } from '../filter/filter-alert-type-field';
import { FilterInputAutocompleteMultiple } from '../filter/filter-input-autocomplete-multiple';
import { useBoolFlag } from '../flags';

interface Props {
  apiUrl?: string;
  appliedFilters: (value: FilterTypes) => AppliedFilterType[];
  dataType?: FilterDataTypes;
  entityCache?: FetchAllCache;
  findItems?: (search: string) => Promise<CommonEntityWithDeleted<string>[]>;
  inputError: boolean;
  isApplied: (value: EntityField) => boolean;
  label?: string;
  onChanged: (value: SelectedFilterType) => void;
  path: string;
  placeholderText?: string;
  replacePath?: string;
  selectedValue?: SelectedFilterType;
  type: FilterFieldTypes;
  values?: EntityField[];
  'data-testid'?: string;
}

export const NewFilterInput: FC<Props> = ({
  apiUrl,
  appliedFilters,
  dataType,
  entityCache,
  findItems,
  inputError,
  isApplied,
  label,
  onChanged,
  path,
  placeholderText,
  replacePath,
  selectedValue,
  type,
  values,
  ...props
}) => {
  const { axios } = useAuthenticated();
  const { t } = useTranslation(['common']);
  const enableNewAlertFilter = useBoolFlag(NEW_ALERT_FILTER_FLAG);

  const [entities] = usePromise(() => {
    if (!entityCache || dataType !== FilterDataTypes.CACHE) return Promise.resolve([]);
    return filterDeletedCache<CommonEntityWithDeleted>(entityCache);
  }, [dataType, entityCache]);

  const findEntities = useMemo<Undefinable<(_: string) => Promise<CommonEntityWithDeleted[]>>>(() => {
    if (!apiUrl) return;
    return async (text: string) => {
      const { data } = await axios.get<CommonEntityWithDeleted[]>(apiUrl, {
        params: {
          limit: FILTER_LOOKUP_LIMIT,
          filter: FILTER_OUT.deleted,
          search: text,
        },
      });
      return data.filter(({ _id }) => !(appliedFilters(path as FilterTypes) as EntityField[]).some(({ id }) => id === _id));
    };
  }, [apiUrl, axios]);

  const findCachedEntities = useMemo<(_: string) => Promise<CommonEntityWithDeleted[]>>(() => {
    return (search: string) => searchData(entities, false, search);
  }, [entities]);

  const findEntity = dataType === FilterDataTypes.CACHE ? findCachedEntities : findEntities;

  switch (type) {
    case TypeDefinitionTypes.TEXT: {
      return (
        <FilterInputString
          inputError={inputError}
          onChanged={onChanged}
          placeholderText={placeholderText}
          value={appliedFilters((replacePath ?? path) as FilterTypes).at(-1) as string}
          {...props}
        />
      );
    }
    case TypeDefinitionTypes.NUMBER: {
      return (
        <FilterInputNumber
          inputError={inputError}
          onChanged={onChanged}
          placeholderText={placeholderText}
        />
      );
    }
    case 'entity': {
      if (dataType === FilterDataTypes.ALERT && values) {
        if (enableNewAlertFilter) {
          return <FilterAlertTypeField
            key={path}
            appliedFilters={appliedFilters((replacePath ?? path) as FilterTypes) as EntityField[]}
            label={label}
            onChanged={onChanged}
            values={values}
          />;
        }

        return (
          <FilterInputAutocompleteMultiple
            key={path}
            isApplied={isApplied}
            label={label}
            onChanged={onChanged}
            values={values}
          />
        );
      }
      if (dataType === FilterDataTypes.OTHER && values) {
        return (
          <FilterInputAutocompleteMultiple
            key={path}
            isApplied={isApplied}
            label={label}
            onChanged={onChanged}
            values={values}
          />
        );
      }
      return (
        <FilterInputLookup
          key={path}
          appliedFilters={appliedFilters((replacePath ?? path) as FilterTypes) as EntityField[]}
          data-testid={props['data-testid']}
          findEntity={findItems || findEntity}
          isApplied={isApplied}
          isSearch={dataType === FilterDataTypes.API}
          label={label}
          noResults={t('common:common.hint.list.no-results')}
          onChanged={onChanged}
          selectedValue={selectedValue}
          selectMultiple
          useLookup
        />
      );
    }
    default: {
      return null;
    }
  }
};
