import { Alert as MUIAlert, AlertColor, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, ReactNode } from 'react';

interface Props {
  action?: ReactNode;
  children: ReactNode;
  'data-testid'?: string;
  elevation?: number;
  icon?: ReactNode;
  severity?: AlertColor;
  sx?: SxProps<Theme>;
  variant?: 'standard' | 'filled';
}

export const Alert: FC<Props> = ({ children, ...props }) => {
  return <MUIAlert {...props}>{children}</MUIAlert>;
};
