import { Box, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { PageIcon } from '../icons';

interface Props {
  'data-testid'?: string;
  icon?: JSX.Element;
  title: ReactNode;
}

export const PageTitle: FC<Props> = ({ icon, title, ...props }) => {
  return (<Stack direction="row" flexGrow={1} sx={{ alignItems: 'center' }} spacing={2}>
    {icon && <Box display="flex" sx={{ alignContent: 'center' }}>
      <PageIcon icon={icon} />
    </Box>}
    <Box sx={{ width: 'auto', maxWidth: .9 }}>
      <Typography variant="h4" data-testid={props['data-testid']} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{title}</Typography>
    </Box>
  </Stack>);
};
