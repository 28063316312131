import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Settings, SETTINGS_STORAGE_KEY } from '../types/settings';
import { addLocalStorageItem, getLocalStorageItem } from '../util';

type SetSettings = (settings: Settings & Record<string, unknown>) => void;

interface ContextProps {
  settings: Settings;
  setSettings: SetSettings;
}

const defaultSettings = {
  preserveValues: true,
  numberOfFirstDigitsOfDeviceId: 8,
};

export const SettingsContext = createContext<ContextProps>({
  settings: defaultSettings,
  setSettings: () => { },
});

export const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [settings, setSettings] = useState<Settings & Record<string, unknown>>(() => getLocalStorageItem(SETTINGS_STORAGE_KEY, defaultSettings));

  useEffect(() => {
    addLocalStorageItem(SETTINGS_STORAGE_KEY, settings);
  }, [settings]);

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = (): Settings => useContext(SettingsContext).settings;

export const useSetSettings = (settings: Settings & Record<string, unknown>): void => useContext(SettingsContext).setSettings(settings);
