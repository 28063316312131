import { PointOfInterest, PointOfInterestSet, PointOfInterestType } from '@eagle/core-data-types';

export const getImageUrl = (pointOfInterest: PointOfInterest, poiSet?: PointOfInterestSet, poiType?: PointOfInterestType): string | undefined => {
  if (pointOfInterest.image) {
    if (pointOfInterest.image.filename.includes('://')) return pointOfInterest.image.filename;
    return `/api/v1/point-of-interest/${pointOfInterest._id}/image/${pointOfInterest.image.filename}`;
  }
  if (poiSet?.image) {
    if (poiSet.image.filename.includes('://')) return poiSet.image.filename;
    return `/api/v1/point-of-interest-set/${poiSet._id}/image/${poiSet.image.filename}`;
  }
  if (poiType?.image) {
    if (poiType.image.filename.includes('://')) return poiType.image.filename;
    return `/api/v1/point-of-interest-type/${poiType._id}/image/${poiType.image.filename}`;
  }
  return undefined;
};
