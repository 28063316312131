import { FC } from 'react';
import { useFetchAllCache, usePromise } from '../../hooks';
import { FieldLoadError, FieldLoading } from '../field';

interface Props {
  dataType: string;
  filterFactory?: (data: any) => boolean;
  notFoundFactory?: () => JSX.Element;
  renderFactory: (data: any, key: string | number) => JSX.Element;
}

const DEFAULT_NOT_FOUND = (): JSX.Element => <FieldLoadError />;
const DEFAULT_FILTER = (_data: any): boolean => true;

export const FetchAll: FC<Props> = ({ dataType, renderFactory, filterFactory = DEFAULT_FILTER, notFoundFactory = DEFAULT_NOT_FOUND }) => {
  const cache = useFetchAllCache(dataType);
  const [result, , state] = usePromise(
    () => cache.all(),
    [cache],
  );

  if (state === 'pending') return <FieldLoading />;
  if (state === 'rejected') return <FieldLoadError />;
  if (!result || !result.length) return notFoundFactory();

  return <>
    {result.filter(filterFactory).map((data: any, index) => renderFactory(data, index))}
  </>;
};
