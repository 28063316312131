import { JsonArray } from '@eagle/common';
import { AlertResponse, Person, Thing } from '@eagle/core-data-types';
import { DirectionsCarFilled } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import { ListItemButton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref } from 'react-router-dom';
import { CacheDataTypes } from '../../types/cache';
import { testid } from '../../util/test-id';
import { AlertLabel } from '../alert-label';
import { FetchOne } from '../fetch';
import { FormatAddress } from '../format/format-address';
import { FormatTimestamp } from '../format/format-timestamp';
import { GroupChipElement } from '../group-chip-element';

interface AlertDetailsProps {
  address: JsonArray;
  alert: AlertResponse;
  showPerson: boolean;
  showThing: boolean;
}

interface FormatMultipleDisplayProps {
  ids: string[];
  renderer: (id: string) => JSX.Element;
}

const FormatMultipleDisplay: FC<FormatMultipleDisplayProps> = ({ ids, renderer }) => {
  const { t } = useTranslation(['common']);
  if (!ids.length) return <></>;

  return (
    <Stack direction="column">
      <span>{renderer(ids[0])}</span>
      <Typography sx={{ marginTop: '-0.5rem' }} variant="caption">
        {ids.length > 1
          && <span>{t('common:common.labels.othersWithCount', { count: ids.length - 1 })}</span>
        }
      </Typography>
    </Stack>
  );
};

export const AlertListItemMobile: FC<AlertDetailsProps> = ({ alert, address, showPerson, showThing }): JSX.Element => {
  const { t } = useTranslation(['common']);
  const href = useHref('/alert');

  return (
    <ListItemButton
      component="a"
      href={`${href}/${alert.alertId}`}
      role="button"
      sx={{
        color: 'inherit',
        cursor: 'pointer',
        p: 1,
        textDecoration: 'none',
        width: '100%',
      }}
    >
      <Stack spacing={1} width={'100%'} data-testid={testid`alert-id-${alert.data.testId ?? alert.alertId}`}>
        <Stack direction="row" gap={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Stack gap={1} flex={[3, 3]}>
            {showThing &&
              <Stack direction="row" spacing={0.5}>
                <DirectionsCarFilled sx={{ fontSize: 20 }} />
                {!alert.thingIds.length
                  ? <Typography variant="body2" fontStyle="italic" color="text.secondary">
                    {t('common:component.alert-table.hint.no-results.none')}
                  </Typography>
                  : <FormatMultipleDisplay
                    ids={alert.thingIds}
                    renderer={(id: string) => (
                      <FetchOne
                        dataType={CacheDataTypes.THING}
                        id={id}
                        renderFactory={(thing: Thing) => (
                          <Typography variant="body2">
                            {thing.display}
                          </Typography>
                        )}
                      />
                    )}
                  />
                }
              </Stack>
            }
            {showPerson
              && <Stack direction="row" spacing={0.5}>
                <PersonIcon sx={{ fontSize: 20 }} />
                {!alert.personIds.length
                  ? <Typography variant="body2" fontStyle="italic" color="text.secondary">
                    {t('common:component.alert-table.hint.no-results.none')}
                  </Typography>
                  : <FormatMultipleDisplay
                    ids={alert.personIds}
                    renderer={(id: string) => (
                      <FetchOne
                        dataType={CacheDataTypes.PERSON}
                        id={id}
                        renderFactory={(person: Person) => (
                          <Typography variant="body2">
                            {person.display}
                          </Typography>
                        )}
                      />
                    )}
                  />
                }
              </Stack>
            }
          </Stack>
          <Stack data-chromatic="ignore" flex={[2, 2]} sx={{ alignItems: 'flex-end' }}>
            <Typography variant="body2" data-chromatic="ignore"><FormatTimestamp value={alert.occurred} format="relative" /></Typography>
            <Typography data-chromatic="ignore" color="text.secondary" variant="caption" sx={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
              <FormatTimestamp value={alert.occurred} format="" />
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ my: 1 }} direction="row" spacing={0.5}>
          <LocationOnIcon sx={{ fontSize: 20 }} />
          {!address.length
            ? <Typography variant="body2" fontStyle="italic" color="text.secondary">
              {t('common:component.alert-table.hint.no-results.address')}
            </Typography>
            : <Stack>
              <FormatAddress value={address as string[]} variant="body2" />
            </Stack>
          }
        </Stack>
        <Stack>
          <AlertLabel align='center' alert={alert} />
        </Stack>
        <Stack direction="row" sx={{ width: 1, pt: 1, flexWrap: 'wrap' }}>
          {!alert.groupIds || !alert.groupIds.length
            ? <Typography color="text.secondary" fontStyle="italic" variant="body2">
              {t('common:component.alert-table.hint.no-results.groups')}
            </Typography>
            : <GroupChipElement groupIds={alert.groupIds} type={CacheDataTypes.GROUP} />
          }
        </Stack>
      </Stack>
    </ListItemButton>
  );
};
