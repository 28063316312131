import { InputTypes, TypeDefinitionTypes } from '@eagle/common';
import { AppliedFilter, FilterField } from '../../components/entity-search/types';
import { FilterPathIdentifiers } from '../../components/filter/filter.types';
import { EntityType } from './types';

export const buildFilterFields = (
  entityTypes: EntityType[],
  filters: AppliedFilter[],
  typePropertyName: string,
  tagsFilterLabel: string,
  typeFilterLabel: string,
  showTags = true,
  pathIdentifier?: FilterPathIdentifiers,
  replacePath?: string,
  selectMultiple?: boolean,
): FilterField[] => {
  const appliedTypeFilters = filters.filter((filter) => (filter.propertyPath === typePropertyName));

  const filtersTemp = appliedTypeFilters.map((typeFilter) => {
    const entityType = entityTypes.find((filterElement) => (filterElement._id === (typeFilter.value as { id: unknown }).id));
    if (!entityType) return;

    return entityType.properties.order.map((property) => {
      return { definition: entityType.properties.definitions[property], path: `properties.${property}` };
    });
  });

  const tagsField = {
    definition: {
      description: null,
      format: 'raw',
      input: InputTypes.TEXT,
      label: tagsFilterLabel,
      multiple: null,
      type: TypeDefinitionTypes.TEXT,
    },
    path: 'tags',
  };

  const entityObject: FilterField = {
    definition: {
      description: null,
      format: 'raw',
      input: InputTypes.CHECK,
      label: typeFilterLabel,
      multiple: null,
      type: 'entity',
    },
    path: typePropertyName,
    pathIdentifier,
    values: entityTypes.map((type) => ({ id: type._id, display: type.display, alertProperties: type.alertProperties })),
    replacePath,
    selectMultiple,
  };

  if (!filtersTemp.length) {
    return showTags ? [entityObject, tagsField] : [entityObject];
  }

  const entityArray = filtersTemp.reduce<FilterField[]>(
    (acc, filter) => {
      if (!filter) return acc;

      const existingPaths = new Set(acc.map((item) => item.path));

      return filter.reduce((acc1, element) => {
        if (existingPaths.has(element.path)) return acc1;

        return [
          ...acc1,
          element,
        ];
      }, acc);
    },
    [],
  );

  return showTags ? [entityObject, tagsField, ...entityArray] : [entityObject, ...entityArray];
};
