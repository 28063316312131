export const queryToObject = (value: string | (string | null)[] | null | undefined): Record<string, string> | null => {
  if (typeof value === 'string') return JSON.parse(atob(value)) as Record<string, string>;
  return null;
};

export const base64URLEncode = (bytes: number[]): string => {
  return btoa(String.fromCharCode.apply(null, bytes))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};
