import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useLocalStorage } from '../util';

interface SidebarPinContextValue {
  isPinned: boolean;
  toggleIsPinned: () => void;
}

export const SidebarPinContext = createContext<SidebarPinContextValue | undefined>(undefined);

export const SidebarPinContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isPinned, setPinSidebar] = useLocalStorage('pin-sidebar', false);

  const toggleIsPinned = (): void => {
    setPinSidebar((pinned) => !pinned);
  };

  return (
    <SidebarPinContext.Provider value={{ isPinned, toggleIsPinned }}>
      {children}
    </SidebarPinContext.Provider>
  );
};

export const useSidebarPinContext = (): SidebarPinContextValue => {
  const value = useContext(SidebarPinContext);
  if (value === undefined) {
    throw new Error('useSidebarPinContext must be used within SidebarPinContextProvider');
  }
  return value;
};
