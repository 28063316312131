import { Button, ButtonProps } from '@mui/material';
import { FC, useRef } from 'react';
import { disableClickPropagation } from '../../util';

export const MapButton: FC<ButtonProps> = ({ sx, ...props }) => {
  const ref = useRef<HTMLButtonElement>(null);
  disableClickPropagation(ref);
  return (
    <Button
      {...props}
      ref={ref}
      size="small"
      sx={{ minWidth: '32px !important', px: '2px', py: '3px', zIndex: 500, ...sx }}
      variant="outlined"
    />
  );
};
