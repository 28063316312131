/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { PointOfInterestType } from '@eagle/core-data-types';
import { Link, Stack, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref, useParams } from 'react-router';
import { Breadcrumbs, MarkerProvider, MiddleSpinner, PortalFeatureIcons, SharingCard } from '../../../components';
import { PointOfInterestGroupInlayMap } from '../../../components/point-of-interest-picker-tool';
import { T_MANY, T_ONE } from '../../../constants';
import { useDynamicModule, useFetchAllCache } from '../../../hooks';
import { CacheDataTypes, FeatureIcons } from '../../../types';
import { useHasAuthorization } from '../../../util';
import { DetailPage } from '../../detail';
import { PointOfInterestTypeDetailCard } from './point-of-interest-type-detail-card';

interface Props {
  pointOfInterestType?: PointOfInterestType;
}

const POINT_OF_INTEREST_ADMIN = [RoleFunction.POINT_OF_INTEREST_ADMINISTRATOR] as const;

export const PointOfInterestTypeDetail: FC<Props> = ({ pointOfInterestType }) => {
  const { t } = useTranslation(['common']);
  const { pointOfInterestTypeId } = useParams();
  const href = useHref('/point-of-interest/types');
  const poiTypeCache = useFetchAllCache(CacheDataTypes.POINT_OF_INTEREST_TYPE);
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Admin);
  const { hasAuthorization } = useHasAuthorization();
  const isPoiAdmin = hasAuthorization(POINT_OF_INTEREST_ADMIN);

  const renderPageContent = (data: PointOfInterestType): JSX.Element => (
    <MarkerProvider>
      <Stack direction="column" flex={[2, 2]}>
        <PointOfInterestTypeDetailCard pointOfInterestType={data} />
      </Stack>
      <Stack direction="column" flex={[3, 3]} spacing={2}>
        <PointOfInterestGroupInlayMap baseUrlLoadImage="/api/v1/point-of-interest-type" data={data} shouldUseLocalStorage={false} />
        {data.shared
          && <SharingCard
            accounts={data.accountBinding}
            entityType={t('common:terms.point-of-interest-type', { count: T_ONE })}
          />
        }
      </Stack>
    </MarkerProvider>
  );

  const breadcrumbs = (
    <Breadcrumbs>
      <Link
        color="inherit"
        href={href}
        underline="hover"
      >
        {t('common:terms.point-of-interest-type', { count: T_MANY })}
      </Link>
      <Typography color="text.primary">{t('manage:page.point-of-interest-type-detail.sub-title')}</Typography>
    </Breadcrumbs>
  );

  const loadData = useCallback(
    () => {
      if (!pointOfInterestType) return poiTypeCache.one<PointOfInterestType>(pointOfInterestTypeId);
      return Promise.resolve(pointOfInterestType);
    },
    [pointOfInterestTypeId],
  );

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <DetailPage
      actions={[]}
      breadcrumbs={breadcrumbs}
      data-testid="point-of-interest-type-detail-page"
      entityLabel={t('common:terms.point-of-interest-type', { count: T_ONE })}
      loadData={loadData}
      pageIcon={module?.PointOfInterestIcon && <module.PointOfInterestIcon />}
      renderPageContent={renderPageContent}
      renderPageTitle={({ display }) => display}
      restoreEntityApi="/api/v1/point-of-interest-type"
      restoreEntityPermissions={isPoiAdmin}
    />
  );
};
