import { ThingEventSnapshot } from '@eagle/core-data-types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Collapse, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, MiddleSpinner } from '../../components';
import { COLLAPSE_DEFAULT_TIME } from '../../constants';
import { useSearch } from '../../pages/list/use-search';
import { EntryDataBody, EventTableEventIcon } from './util';

interface EventsTableMobileViewProps {
  events: ThingEventSnapshot[];
  isLoading: boolean;
  paginationComponent?: JSX.Element;
  rowChildren?: (event: ThingEventSnapshot) => JSX.Element;
  tableHeight: string;
}

const Row: FC<{ event: ThingEventSnapshot; rowChildren?: (event: ThingEventSnapshot) => JSX.Element }> = ({ event, rowChildren }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return <>
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell sx={{ width: '5%' }}>
        <IconButton size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row">
        <EventTableEventIcon event={event} showDateBelowEvent />
      </TableCell>
    </TableRow>
    <TableRow sx={{ transition: `borderBottom ${COLLAPSE_DEFAULT_TIME}ms linear` }} >
      <TableCell sx={{ '&': { borderBottom: open ? `1px solid ${chroma(theme.palette.common.black).alpha(0.12).css()}` : 'none' }, py: 0, px: 1 }} colSpan={6}>
        <Collapse in={open} unmountOnExit>
          <div>
            <EntryDataBody event={event} />
            {rowChildren && rowChildren(event)}
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  </>;
};

export const EventsTableMobileView: FC<EventsTableMobileViewProps> = ({ events, isLoading, paginationComponent, rowChildren, tableHeight }) => {
  const { t } = useTranslation(['common']);
  const { filters } = useSearch();

  if (!events || !events.length) {
    if (isLoading) {
      return (
        <Stack sx={{ minHeight: tableHeight }}>
          <MiddleSpinner sx={{ height: 'auto', pt: '140px' }} />
        </Stack>
      );
    }
    return (
      <Stack sx={{ minHeight: tableHeight }}>
        {filters.length
          ? <Alert severity="info" sx={{ m: 3 }}>{t('common:component.events.thing-event.labels.table.filter-no-results')}</Alert>
          : <Alert severity="info" sx={{ m: 3 }}>{t('common:component.events.thing-event.labels.table.filter-no-selection')}</Alert>
        }
      </Stack>
    );
  }

  return (
    <TableContainer sx={{ minHeight: tableHeight }}>
      <Table>
        <TableBody sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
          {events.map((event) => <Row key={event.eventId} event={event} rowChildren={rowChildren} />)}
        </TableBody>
      </Table>
      <Box display="flex" justifyContent="center" marginY={1}>
        {paginationComponent}
      </Box>
    </TableContainer>
  );
};
