/* eslint-disable react-hooks/exhaustive-deps */
import { ThingEventSnapshot } from '@eagle/core-data-types';
import { Pagination, PaginationItem } from '@mui/material';
import { FC, useMemo } from 'react';
import { useSmallScreen } from '../../hooks';
import { useSearch } from '../../pages/list/use-search';
import { Nullable } from '../../types';
import { testid } from '../../util';
import { EventsTableDesktopView } from './layout-desktop';
import { EventsTableMobileView } from './layout-mobile';

const FIXED_EVENT_TABLE_HEIGHT = '380px';

interface Props {
  events: ThingEventSnapshot[];
  fixHeight: boolean;
  hasMore: boolean;
  hideCategory: boolean;
  isLoading: boolean;
  matchCount: number;
  rowChildren?: (event: ThingEventSnapshot) => JSX.Element;
}

export const EventsTableView: FC<Props> = ({
  events,
  fixHeight,
  hasMore,
  hideCategory,
  isLoading,
  matchCount,
  rowChildren,
}) => {
  const smallScreen = useSmallScreen();
  const isSafari = navigator.userAgent.indexOf('Safari') !== -1;
  const listPageScroll = document.getElementById('list-page-scroll') as Nullable<HTMLDivElement>;
  const tableHeight = fixHeight ? FIXED_EVENT_TABLE_HEIGHT : 'auto';
  const { pagination, setPagination } = useSearch();

  const paginationComponent = useMemo(
    () => matchCount > pagination.limit
      ? <Pagination
        count={Math.ceil(matchCount / pagination.limit)}
        disabled={isLoading}
        onChange={(_, page) => {
          setPagination({ limit: pagination.limit, skip: pagination.limit * (page - 1) });
          if (isSafari) {
            listPageScroll && listPageScroll.scrollIntoView();
          }
          else {
            listPageScroll && listPageScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }}
        renderItem={(item) => (
          <PaginationItem data-testid={testid`pagination-item-${item.type}${item.type === 'page' ? `-${item.page ?? ''}` : ''}`}
            {...item}
          />
        )}
        page={Math.floor(pagination.skip / pagination.limit) + 1}
        showFirstButton={!smallScreen}
        showLastButton={!smallScreen}
        size={smallScreen ? 'small' : 'large'}
      />
      : undefined,
    [isLoading, hasMore, pagination, smallScreen],
  );

  if (smallScreen) {
    return (
      <EventsTableMobileView
        tableHeight={tableHeight}
        events={events}
        isLoading={isLoading}
        paginationComponent={paginationComponent}
        rowChildren={rowChildren}
      />
    );
  }

  return (
    <EventsTableDesktopView
      tableHeight={tableHeight}
      events={events}
      isLoading={isLoading}
      paginationComponent={paginationComponent}
      rowChildren={rowChildren}
      hideCategory={hideCategory}
    />
  );
};
