import { ControllerProps } from './controller.types';
import { CreateView } from './view';

export const CreateController = ({
  actions = [],
  breadcrumbs,
  pageIcon,
  pageTitle,
  renderPageContent,
  ...props
}: ControllerProps): JSX.Element => {
  return (
    <CreateView
      actions={actions}
      breadcrumbs={breadcrumbs}
      data-testid={props['data-testid']}
      pageIcon={pageIcon}
      pageTitle={pageTitle}
      renderPageContent={renderPageContent}
    />
  );
};
