import { ThingEventSnapshot } from '@eagle/core-data-types';
import { TrackingEventTypes } from '@eagle/data-function-types';
import { ArrowUpward, Flag } from '@mui/icons-material';
import { Theme } from '@mui/material';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { getEventsDefinition } from '../../../../notable-events';
import { makeStyles } from '../../../../theme';
import { Maybe } from '../../../../types';
import { getEventDictionary } from '../../../entity-journey';
import { EventLocationData } from '../../thing-event-pane/thing-event-pane.types';

export const POINT_RADIUS = 15.0;
export const ENLARGED_POINT_RADIUS = POINT_RADIUS * 1.2;
export const START_END_CLUSTER_TOLERANCE = 10;
export const SPIDERFY_DISTANCE_MULTIPLIER = 1.5;
export const SPIDERFY_SEPARATION = 28.0;
export const SPIDERFY_LENGTH_FACTOR = 5.0;
export const SPIDERFY_INITIAL_LENGTH = SPIDERFY_DISTANCE_MULTIPLIER * 11.0;
export const SPIDERFY_ANGLE_OFFSET = 0.0005;
const START_END_SCALE = 1.2;

interface NotableEventDataItem {
  enabled: boolean;
  eventData: EventLocationData;
  index: number;
  priority: number;
}

interface NotableEvents {
  containsFirstEvent: boolean;
  containsLastEvent: boolean;
  notableEventData: NotableEventDataItem[];
}

export const degreesToRadians = (degrees: number): number => (degrees * Math.PI) / 180;

export const getMarkerSize = (eventId: string, hoveredEventId: Maybe<string>, expanded: boolean): number => {
  if (!expanded) return POINT_RADIUS;
  return eventId === hoveredEventId ? ENLARGED_POINT_RADIUS : POINT_RADIUS;
};

export const getClusterMarkerSize = (eventIds: string[], hoveredEventId: Maybe<string>, expanded: boolean, defaultRadius?: number): number => {
  const clusterRadius = defaultRadius ?? POINT_RADIUS;
  if (!expanded && hoveredEventId && eventIds.includes(hoveredEventId)) return clusterRadius * START_END_SCALE;
  if (!expanded) return clusterRadius;
  return eventIds[0] === hoveredEventId ? clusterRadius * START_END_SCALE : clusterRadius;
};

export const getNotableEventColor = (event: ThingEventSnapshot, theme: Theme): string => {
  const dictionary = getEventsDefinition(theme);
  const eventData = getEventDictionary(event, theme) ?? dictionary[0];
  return eventData.styling.backgroundColor;
};

export const getNotableEventsInformation = (
  items: {
    eventData: EventLocationData;
    index: number;
  }[],
  eventsLength: number,
  theme: Theme,
): NotableEvents => {
  const locationUpdateEvents = items.filter((item) =>
    item.eventData.eventTypeId === TrackingEventTypes.LOCATION_UPDATE,
  );

  const updatedEvents = [
    ...items.filter((item) => item.eventData.eventTypeId !== TrackingEventTypes.LOCATION_UPDATE),
    ...locationUpdateEvents,
  ];

  const notableEventData = updatedEvents.map((item) => {
    const dictionary = getEventsDefinition(theme);
    const dictionarySearch = (dictionary.find((event) => event.eventTypeId === item.eventData.eventTypeId) ?? dictionary[0]);
    return {
      ...item,
      enabled: dictionarySearch.enabled,
      priority: dictionarySearch.priority,
    };
  }).filter((item) => item.enabled);

  const containsFirstEvent = !!items.find((eventItem) => eventItem.index === 0);
  const containsLastEvent = !!items.find((eventItem) => eventItem.index === (eventsLength - 1));

  return {
    containsFirstEvent,
    containsLastEvent,
    notableEventData,
  };
};

export const createClusterMarker = (
  color: string,
  size: number,
  firstMarker: boolean,
  lastMarker: boolean,
  iconColor: string,
  locationUpdate: boolean,
  theme: Theme,
  heading?: number,
): L.DivIcon => {
  const overwriteIconColor = !locationUpdate ? color : iconColor;

  const styles = makeStyles()(() => ({
    icon: {
      backgroundColor: (firstMarker || lastMarker) ? overwriteIconColor : color,
      borderRadius: '50%',
      boxShadow: theme.shadows[4],
      '& .MuiSvgIcon-root': {
        height: '100%',
        width: '100%',
      },
      zIndex: 1000,
    },
  }))();

  if (firstMarker) {
    return L.divIcon({
      className: styles.classes.icon,
      html: renderToStaticMarkup(
        <ArrowUpward
          style={{
            color: theme.palette.common.white,
            height: '100%',
            transform: `rotate(${heading ?? 0}deg)`,
            width: '100%',
          }}
        />,
      ),
      iconSize: [size * START_END_SCALE, size * START_END_SCALE],
    });
  }

  if (lastMarker) {
    return L.divIcon({
      className: styles.classes.icon,
      html: renderToStaticMarkup(<Flag style={{ color: theme.palette.common.white }} />),
      iconSize: [size * START_END_SCALE, size * START_END_SCALE],
    });
  }

  return L.divIcon({ className: styles.classes.icon, iconSize: [size, size] });
};

export const createBoundingMarker = (size: number): L.DivIcon => {
  const styles = makeStyles()(() => ({
    icon: {
      backgroundColor: 'transparent',
      borderRadius: '50%',
    },
  }))();

  return L.divIcon({ className: styles.classes.icon, iconSize: [size, size] });
};

