import { LandingConfig } from '@eagle/core-data-types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../hooks';
import { getConfigPortalProps } from '../../util';
import LayoutDesktop from './layout-desktop';
import LayoutMobile from './layout-mobile';

interface LandingProps {
  portalName: string;
  prefersDarkMode?: boolean;
  renderBottomFooterText?: (bottomFooterText: string) => JSX.Element;
  renderFooterLogo: (logoUrl: string) => JSX.Element;
  renderHeader: (headerText: string) => JSX.Element;
  renderLogo: (logoUrl: string) => JSX.Element;
  renderSignInButton: () => JSX.Element;
  renderSubHeader: (subHeaderText: string) => JSX.Element;
  renderTermsAndConditionsText: () => JSX.Element;
  renderTopFooterText: (topFooterText: string) => JSX.Element;
  smallScreen: boolean;
}

export const LandingView: FC<LandingProps> = ({
  portalName,
  renderBottomFooterText,
  renderFooterLogo,
  renderHeader,
  renderLogo,
  renderSignInButton,
  renderSubHeader,
  renderTermsAndConditionsText,
  renderTopFooterText,
  smallScreen,
}) => {
  const config = useConfig();
  const { t } = useTranslation(['common']);
  const getInnerProps = (field: keyof LandingConfig, defaultValue: string): string => {
    return getConfigPortalProps(config, field, portalName, defaultValue);
  };

  const innerProps = {
    bottomFooterText: renderBottomFooterText && renderBottomFooterText(getInnerProps('bottomFooterText', t('common:component.landing.labels.bottom-footer'))),
    footerLogo: renderFooterLogo(getInnerProps('footerLogoImage', '')),
    header: renderHeader(getInnerProps('headerText', '')),
    logo: renderLogo(getInnerProps('logoImage', '')),
    signInButton: renderSignInButton(),
    subHeader: renderSubHeader(getInnerProps('subHeaderText', '')),
    termsAndConditionsText: renderTermsAndConditionsText(),
    topFooterText: renderTopFooterText(getInnerProps('topFooterText', t('common:component.landing.labels.footer'))),
  };

  if (smallScreen) {
    return (
      <LayoutMobile {...innerProps} />
    );
  }

  return (
    <LayoutDesktop {...innerProps} />
  );
};
