import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomNavigationAction } from '../../components/bottom-navigation';
import { useMaxScreen } from '../../hooks';
import { EventHistoryDesktop } from './event-history-desktop';
import { EventHistoryMobile } from './event-history-mobile';
import { EventHistoryTablet } from './event-history-tablet';
import { EventHistoryAction, MobileTabSelection } from './event-history.types';
import { TwoWayCollapseController } from './two-way-collapse/two-way-collapse-controller';

interface Props {
  isDrawerOpen: boolean;
  mobileTabSelection: MobileTabSelection;
  renderBottomDrawerContent: () => JSX.Element;
  renderHistoryContent: (mobileTabSelection: MobileTabSelection) => JSX.Element;
  renderTopDrawerContent: () => JSX.Element;
  setIsTransitioning: (isTransitioning: boolean) => void;
  setMobileTabSelection: (value: number) => void;
}

export const EventHistoryView: FC<Props> = ({
  isDrawerOpen,
  mobileTabSelection,
  renderBottomDrawerContent,
  renderHistoryContent,
  renderTopDrawerContent,
  setIsTransitioning,
  setMobileTabSelection,
}) => {
  const { t } = useTranslation(['common']);
  const mediumScreen = useMaxScreen('md');
  const smallScreen = useMaxScreen('sm');

  const bottomDrawerContent = renderBottomDrawerContent();
  const historyDrawerContent = renderHistoryContent(mobileTabSelection);
  const topDrawerContent = renderTopDrawerContent();

  if (smallScreen) {
    const bottomActions: EventHistoryAction[] = [
      {
        icon: <MapIcon />,
        label: t('common:common.labels.map'),
        onClick: () => setMobileTabSelection(MobileTabSelection.TOP),
        showIcon: true,
      },
      {
        icon: <LocationOnIcon />,
        label: t('common:common.labels.history'),
        onClick: () => setMobileTabSelection(MobileTabSelection.LEFT),
        showIcon: true,
      },
      {
        icon: <TimelineIcon />,
        label: t('common:common.labels.timeline'),
        onClick: () => setMobileTabSelection(MobileTabSelection.BOTTOM),
        showIcon: true,
      },
    ];

    const bottomNavigationActions = bottomActions.slice(0, 2).map((action, index) =>
      <BottomNavigationAction
        key={index}
        icon={action.icon}
        label={action.label}
        onClick={action.onClick}
      />,
    );

    const renderMobileContent = (): JSX.Element => <>
      <Box sx={{ display: mobileTabSelection === MobileTabSelection.TOP ? 'inline' : 'none', height: '100%' }}>
        {topDrawerContent}
      </Box>
      <Box sx={{ display: mobileTabSelection === MobileTabSelection.BOTTOM ? 'inline' : 'none' }}>
        {bottomDrawerContent}
      </Box>
      <Box sx={{ display: mobileTabSelection === MobileTabSelection.LEFT ? 'inline' : 'none' }}>
        {historyDrawerContent}
      </Box>
    </>;

    return (
      <EventHistoryMobile
        bottomActions={bottomNavigationActions}
        content={renderMobileContent()}
        value={mobileTabSelection}
      />
    );
  }

  if (mediumScreen) {
    return (
      <EventHistoryTablet
        historyDrawerContent={historyDrawerContent}
        isDrawerOpen={isDrawerOpen}
        setIsTransitioning={setIsTransitioning}
        twoWayCollapseContent={
          <TwoWayCollapseController
            bottomDrawerContent={bottomDrawerContent}
            topDrawerContent={topDrawerContent}
          />
        }
      />
    );
  }

  return (
    <EventHistoryDesktop
      historyDrawerContent={historyDrawerContent}
      isDrawerOpen={isDrawerOpen}
      setIsTransitioning={setIsTransitioning}
      twoWayCollapseContent={
        <TwoWayCollapseController
          bottomDrawerContent={bottomDrawerContent}
          topDrawerContent={topDrawerContent}
        />
      }
    />
  );
};
