import { TFunction } from 'i18next';
import { AppliedFilter, AppliedFilterType } from '../components';
import { CommonEntity } from '../types';

interface ReturnData<T> {
  result: { results: T; itemCount: number };
  resultDescription: string;
}

export const FormatListData = <T extends CommonEntity[]>(
  { count, entities }: { count: number; entities: T },
  search: string,
  filters: AppliedFilter<AppliedFilterType>[],
  t: TFunction,
  entityKey: string,
): ReturnData<T> => {
  const resultDescription = getListResultDescription({ count, entityKey, filters, search, t });

  return {
    result: {
      results: entities,
      itemCount: count,
    },
    resultDescription,
  };
};

export const formatRestClientListResponse = <T extends CommonEntity[]>(
  { count, entities }: { count: number; entities: T },
  search: string,
  filters: AppliedFilter<AppliedFilterType>[],
  t: TFunction,
  entityKey: string,
): ReturnData<T> => {
  const resultDescription = getListResultDescription({ count, entityKey, filters, search, t });

  return {
    result: {
      results: entities,
      itemCount: count,
    },
    resultDescription,
  };
};

export const getListResultDescription = ({
  count,
  entityKey,
  filters,
  search,
  t,
}: {
  entityKey: string;
  count: number | undefined;
  search?: string;
  filters?: AppliedFilter[];
  t: TFunction;
}): string => {
  const entity = t(entityKey, { count });

  if (count === 0) {
    return t('common:common.hint.list.no-results-to-display', { entity });
  }

  if (!search && (!filters || filters.length === 0) && count === 1) {
    return t('common:common.hint.list.one-search', { entity });
  }

  return search
    ? filters?.length
      ? t('common:common.hint.list.search-filter-count', { count, entity, search })
      : t('common:common.hint.list.search-count', { count, entity, search })
    : filters?.length
      ? t('common:common.hint.list.no-search-filter-count', { count, entity })
      : t('common:common.hint.list.all-search', { count, entity });
};
