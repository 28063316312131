import { TabContext, TabList } from '@mui/lab';
import { Box, Button, Dialog, Divider, Stack, Typography } from '@mui/material';
import { FC, ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SlideTransitionComponent } from '../assign-dialog/slide-transition-component';

interface Props {
  'data-testid'?: string;
  dialogHeader: string;
  fullScreen: boolean;
  handleDialogClose: () => void;
  handleTabChange: (_: SyntheticEvent, tab: string) => void;
  open: boolean;
  selectedTab: string;
  tabPanels: ReactNode;
  tabs: ReactNode;
}

export const AssignMultipleDialogLayout: FC<Props> = ({
  dialogHeader,
  fullScreen,
  handleDialogClose,
  handleTabChange,
  open,
  selectedTab,
  tabPanels,
  tabs,
  ...props
}): JSX.Element => {
  const { t } = useTranslation(['common']);

  return (
    <Dialog
      data-testid={props['data-testid']}
      fullScreen={fullScreen}
      fullWidth
      onClose={handleDialogClose}
      open={open}
      sx={{ height: 'max-content', m: 'none', maxHeight: '55vh', '& .MuiDialog-container': { height: 'auto' } }}
      TransitionComponent={SlideTransitionComponent}
    >
      <Stack
        divider={<Divider flexItem orientation='horizontal' />}
        spacing={1}
        useFlexGap
      >
        <Typography
          sx={{ fontWeight: 'bold', mb: '10px', ml: 3, mt: '18px' }}
          variant={fullScreen ? 'body2' : 'body1'}
        >
          {dialogHeader}
        </Typography>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange}>
              {tabs}
            </TabList>
          </Box>
          {tabPanels}
        </TabContext>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: '10px', pr: '25px' }}>
          <Button
            onClick={handleDialogClose}
            sx={{ userSelect: 'none' }}
            variant="text"
            data-testid="close-button"
          >
            {t('common:common.action.close')}
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};
