/* eslint-disable react-hooks/exhaustive-deps */
import { TrackingEventTypes } from '@eagle/data-function-types';
import { useTheme } from '@mui/material';
import L from 'leaflet';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { Marker, Polyline, useMap } from 'react-leaflet';
import { SPIRAL_CLUSTER_THRESHOLD } from '../../../../constants';
import { Maybe } from '../../../../types';
import { MapPanes } from '../../../../util/maps';
import { useHistorySearch } from '../../../entity-journey';
import { EventLocationData } from '../../thing-event-pane/thing-event-pane.types';
import { ClusterTooltip } from './cluster-tooltip';
import { createClusterMarker, degreesToRadians, getNotableEventColor, POINT_RADIUS, SPIDERFY_ANGLE_OFFSET, SPIDERFY_DISTANCE_MULTIPLIER, SPIDERFY_INITIAL_LENGTH, SPIDERFY_LENGTH_FACTOR, SPIDERFY_SEPARATION } from './clustering.utils';
import { FLOWER_CLOSED_OFFSET, FLOWER_OPEN_OFFSET } from './flower-cluster';
import { isAnyNaN } from './util';

interface ClusterPointProps {
  calculatedHeading: number;
  center: L.LatLng;
  event: EventLocationData;
  eventsLength: number;
  expanded: boolean;
  firstPoint?: boolean;
  hoveredEventId: Maybe<string>;
  iconColor: string;
  index: number;
  lastPoint?: boolean;
  markerSize?: number;
  onClick: () => void;
  opacity: boolean;
  setHoveredEventId?: Dispatch<SetStateAction<Maybe<string>>>;
  setSelectedEvent?: Dispatch<SetStateAction<Maybe<EventLocationData>>>;
}

export const FlowerClusterPoint: FC<ClusterPointProps> = ({
  calculatedHeading,
  center,
  event,
  eventsLength,
  expanded,
  firstPoint = false,
  hoveredEventId,
  iconColor,
  index,
  lastPoint = false,
  markerSize = POINT_RADIUS,
  onClick,
  opacity,
  setHoveredEventId,
  setSelectedEvent,
}) => {
  const map = useMap();
  const markerRef = useRef<L.Marker>(null);
  const offsetRef = useRef(FLOWER_CLOSED_OFFSET);
  const [position, setPosition] = useState(center);
  const theme = useTheme();
  const color = useMemo(() => getNotableEventColor(event, theme), [event]);
  const { setClickedEventId } = useHistorySearch();

  const updatePosition = (): void => {
    if (!center || isAnyNaN([center.lat, center.lng])) return;
    const radians = degreesToRadians((360 / (eventsLength - 1)) * index);
    const point = map.latLngToContainerPoint(center);

    if (eventsLength > SPIRAL_CLUSTER_THRESHOLD) {
      const separation = SPIDERFY_DISTANCE_MULTIPLIER * SPIDERFY_SEPARATION;
      const lengthFactor = SPIDERFY_DISTANCE_MULTIPLIER * SPIDERFY_LENGTH_FACTOR * (Math.PI * 2);
      let angle = 0;
      let legLength = SPIDERFY_INITIAL_LENGTH;

      for (let i = 0; i < index + 2; i++) {
        angle += separation / legLength + i * SPIDERFY_ANGLE_OFFSET;
        legLength += lengthFactor / angle;
      }

      const x = expanded ? point.x + (legLength * Math.cos(angle)) : point.x + (POINT_RADIUS * Math.cos(radians)) * offsetRef.current;
      const y = expanded ? point.y + (legLength * Math.sin(angle)) : point.y + (POINT_RADIUS * Math.sin(radians)) * offsetRef.current;
      if (isAnyNaN([x, y])) return;
      const newPoint = L.point([x, y]);
      setPosition(map.containerPointToLatLng(newPoint));
      return;
    }

    const x = (POINT_RADIUS * Math.cos(radians)) * offsetRef.current;
    const y = (POINT_RADIUS * Math.sin(radians)) * offsetRef.current;
    if (isAnyNaN([x, y])) return;
    const newPoint = L.point([point.x + x, point.y + y]);
    const newLatLng = map.containerPointToLatLng(newPoint);
    setPosition(newLatLng);
  };

  const handleZoomChange = (): void => updatePosition();

  useEffect(() => {
    map.on('zoomend', handleZoomChange);

    return () => {
      map.off('zoomend', handleZoomChange);
    };
  }, []);

  useEffect(() => {
    offsetRef.current = expanded ? FLOWER_OPEN_OFFSET : FLOWER_CLOSED_OFFSET;
    updatePosition();
  }, [expanded]);

  useEffect(() => {
    if (event.eventId === hoveredEventId) {
      markerRef.current?.openTooltip();
      return;
    }
    markerRef.current?.closeTooltip();
  }, [event, hoveredEventId, markerRef.current]);

  return <>
    {expanded &&
      <Polyline
        color="red"
        opacity={0.2}
        pane={MapPanes.CLUSTER_LINE}
        pathOptions={{ weight: 2.5 }}
        positions={[center, position]}
        smoothFactor={4}
        weight={10}
      />
    }
    <Marker
      ref={markerRef}
      eventHandlers={{
        click: (e) => {
          e.originalEvent.stopPropagation();
          setSelectedEvent?.(event);
          setClickedEventId(event.eventId);
          onClick();
        },
        mousemove: () => setHoveredEventId?.(event.eventId),
        mouseout: () => setHoveredEventId?.(null),
      }}
      icon={
        createClusterMarker(
          color,
          markerSize,
          expanded && firstPoint,
          expanded && lastPoint,
          iconColor,
          event.eventTypeId === TrackingEventTypes.LOCATION_UPDATE,
          theme,
          event.data.velocity?.heading ?? calculatedHeading,
        )
      }
      opacity={opacity ? 1 : 0}
      pane={expanded ? MapPanes.START_END_MARKERS : MapPanes.BREADCRUMBS}
      position={position}
    >
      <ClusterTooltip direction="bottom" notableData={[event]} />
    </Marker>
  </>;
};
