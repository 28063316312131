import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppliedFilter, SavedFilterOptions } from '../entity-search/types';
import { SavedFilterElement } from '../filter';

interface Props {
  filterOptions: SavedFilterOptions;
  onFiltersChanged: (filters: AppliedFilter[]) => unknown;
  savedFilter: SavedFilter[];
  setSavedFilter: Dispatch<SetStateAction<SavedFilter[]>>;
}

export interface SavedFilter {
  created: number;
  filters: AppliedFilter[];
  id: string;
  name: string;
}

interface FilterStore {
  all: SavedFilter[];
  removeById(id: string): unknown;
  renameById(id: string, name: string): boolean;
}

interface ViewProps {
  onFiltersChanged: (filters: AppliedFilter[]) => unknown;
  store: FilterStore;
}

export const SAVED_FILTER_KEY = 'saved-filters' as const;

export const SavedFilterView: FC<ViewProps> = ({ onFiltersChanged, store }) => {
  return (
    <Stack spacing={1}>
      {store.all.map(({ id, name, filters }) => (
        <SavedFilterElement
          key={id}
          id={id}
          name={name}
          onLoadFilter={() => onFiltersChanged(filters)}
          onRemoveFilter={() => store.removeById(id)}
          onRenameFilter={(newName: string) => store.renameById(id, newName)}
        />
      ))}
    </Stack>
  );
};

export const NewSavedFilterController: FC<Props> = ({ onFiltersChanged, savedFilter, setSavedFilter }) => {
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();

  const sortedFilters = useMemo(
    () => savedFilter ? savedFilter.sort((a, b) => b.created - a.created) : [],
    [savedFilter],
  );

  const removeById = (id: string): void => {
    setSavedFilter(savedFilter.filter((item) => item.id !== id));
  };

  const renameById = (id: string, name: string): boolean => {
    const duplicate = savedFilter.some((item) => item.id !== id && item.name === name);
    if (duplicate) {
      enqueueSnackbar(t('common:component.filter.hint.duplicate-name'), { variant: 'warning' });
      return false;
    }
    setSavedFilter(savedFilter.map((item) => item.id !== id ? item : { ...item, name }));
    return true;
  };

  return (
    <SavedFilterView
      onFiltersChanged={onFiltersChanged}
      store={{
        all: sortedFilters,
        removeById,
        renameById,
      }}
    />
  );
};
