import { DriverBehaviorEventTypes, DuressEventTypes, FeatureTypes, InertiaEventTypes, TrackingEventTypes, VehicleSeatEventTypes } from '@eagle/data-function-types';
import { Theme } from '@mui/material';
import L from 'leaflet';
import { EntityVisibility } from '../../entity-journey';
import { getBreadcrumbEventOptions } from './thing-event-pane.styles';
import { EventTypes } from './thing-event-pane.types';

export const getEventPathOptions = (eventTypeId: EventTypes, feature: string, featureTypeId: FeatureTypes, theme: Theme): L.PathOptions => {
  const pathOptions = getBreadcrumbEventOptions(theme);
  switch (feature) {
    case 'inertia':
      if (eventTypeId === InertiaEventTypes.ROLLOVER) return pathOptions.redEvent;
      if (featureTypeId !== FeatureTypes.INERTIA_V0) return pathOptions.intermediaryEvent;
      break;
    case 'duress':
      if (featureTypeId !== FeatureTypes.DURESS_V0) return pathOptions.intermediaryEvent;
      switch (eventTypeId) {
        case DuressEventTypes.DURESS_BUTTON_ACTIVATED: // intentional fallthrough
        case DuressEventTypes.SUPPRESSION_CANCEL: // intentional fallthrough
        case DuressEventTypes.SUPPRESSION_START:
          return pathOptions.amberEvent;
      }
      break;
    case 'driver-behavior':
      switch (eventTypeId) {
        case DriverBehaviorEventTypes.EYES_CLOSED: // intentional fallthrough
        case DriverBehaviorEventTypes.LOOK_DOWN: // intentional fallthrough
        case DriverBehaviorEventTypes.MISSING_FACE: // intentional fallthrough
        case DriverBehaviorEventTypes.OTHER: // intentional fallthrough
        case DriverBehaviorEventTypes.PHONE_USAGE: // intentional fallthrough
        case DriverBehaviorEventTypes.SMOKING: // intentional fallthrough
        case DriverBehaviorEventTypes.YAWN: // intentional fallthrough
        case TrackingEventTypes.SPEEDING_START:
          return pathOptions.amberEvent;
      }
      break;
    case 'tracking':
      switch (eventTypeId) {
        case TrackingEventTypes.SPEEDING_START:
          return pathOptions.amberEvent;
        default:
          break;
      }
      break;
    case 'abs-activated':
      return pathOptions.amberEvent;
    case 'vsc-activated':
      return pathOptions.amberEvent;
    case 'airbag-deployed':
      return pathOptions.redEvent;
    case 'vehicle-seat':
      switch (eventTypeId) {
        case VehicleSeatEventTypes.MOVEMENT_WITHOUT_RESTRAINT_START:
          return pathOptions.amberEvent;
        default:
          break;
      }
      break;
    default:
      return pathOptions.intermediaryEvent;
  }
  return pathOptions.intermediaryEvent;
};

export const getClusterPathOptions = (pathOptions: Array<L.PathOptions>, theme: Theme): L.PathOptions => {
  const options = getBreadcrumbEventOptions(theme);
  if (pathOptions?.includes(options.firstEvent)) return options.firstEvent;
  if (pathOptions?.includes(options.lastEvent)) return options.lastEvent;
  if (pathOptions?.includes(options.redEvent)) return options.redEvent;
  if (pathOptions?.includes(options.amberEvent)) return options.amberEvent;
  return options.intermediaryEvent;
};

export const getJourneyVisibility = (entityId: string, entityVisibilities: EntityVisibility[], journeyId: string): boolean => {
  const entityVisibility = entityVisibilities.find((visibility) => visibility.entityId === entityId);
  return !!entityVisibility?.journeyVisibility.find((journey) => journey.journeyId === journeyId)?.isVisible;
};
