import { DomainPublicConfigResponse, LandingConfig } from '@eagle/core-data-types';
import axios from 'axios';
import { merge } from 'lodash';
import { DomainCustomConfig } from '../auth';
import { Nullable } from '../types';
import { makeQueryablePromise, QueryablePromise } from './promise';

// config is fetched from the domain customization service and can therefore have different values per domain

export const DEFAULT_CONFIG: Partial<DomainCustomConfig> = {
  environment: 'prod',
};

export const applyDefaultConfig = (config: Partial<DomainPublicConfigResponse>): Partial<DomainCustomConfig> => {
  return merge({}, DEFAULT_CONFIG, config);
};

let loaded: Nullable<QueryablePromise<DomainCustomConfig>> = null;

export const loadConfig = (reload = false, domain?: string): Promise<Partial<DomainCustomConfig>> => {
  if (!reload && loaded && !loaded.isRejected()) return loaded;

  loaded = makeQueryablePromise(axios.get<DomainCustomConfig>('/api/config', { params: domain ? { domain } : undefined })
    .then((response) => {
      const remote: DomainCustomConfig = response.data;
      return Promise.resolve({
        ...DEFAULT_CONFIG,
        ...remote,
      });
    }));
  return loaded;
};

export const getConfigPortalProps = (config: Partial<DomainCustomConfig>, field: keyof LandingConfig, portal: string, defaultValue: string): string => {
  const portalConfig = config.portals?.[portal];
  if (portalConfig?.landing && portalConfig?.landing[field]) return portalConfig.landing[field] as string;
  return defaultValue;
};
