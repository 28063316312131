import { Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ChangeEvent, FC, ReactNode } from 'react';

interface SelectionProps {
  checked: boolean;
  'data-testid'?: string;
  handleClick: (e: ChangeEvent<HTMLInputElement>) => void;
  indeterminate?: boolean;
  label: ReactNode;
  subItem?: boolean;
  sx?: SxProps;
}

export const SelectionCheckBox: FC<SelectionProps> = ({ checked, handleClick, indeterminate, label, subItem, sx, ...rest }) => {
  const theme = useTheme();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleClick}
          size="small"
          sx={{
            p: 0,
            pr: 1,
            color: theme.palette.grey[500],
          }}
        />
      }
      label={label}
      sx={{
        m: 0,
        width: '100%',
        px: 2,
        py: '6px',
        pl: subItem ? 3 : 2,
        '& .MuiFormControlLabel-label': {
          color: subItem ? theme.palette.text.secondary : theme.palette.text.primary,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        ...sx,
      }}
      {...rest}
    />
  );
};
