import { Nullable } from '../types';

export const matchIsArray = (
  value: unknown,
  filled?: boolean,
): value is unknown[] => {
  const isArray = Array.isArray(value);
  return filled ? isArray && value.length > 0 : isArray;
};

export const getFirstIntersection = <T extends unknown[]>(
  arrayA: T,
  arrayB: T,
): Nullable<T[number]> => {
  return (
    arrayA.find((element) => {
      return arrayB.includes(element);
    }) || null
  );
};

export const matchIsObject = (value: unknown): value is object => {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

export const getKeys = Object.keys as <T>(object: T) => Array<keyof T>;
