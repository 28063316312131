import { Divider, Modal, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaxScreen } from '../../hooks/use-max-screen';

interface Props {
  'data-testid'?: string;
  calendar: JSX.Element;
  cancelButton: JSX.Element;
  disableEscapeKeyDown: boolean;
  endDatePicker: JSX.Element;
  endTimePicker: JSX.Element;
  isDateTimePickerOpen: boolean;
  onCancel: () => void;
  resetButton: JSX.Element;
  setRangeButton: JSX.Element;
  startDatePicker: JSX.Element;
  startTimePicker: JSX.Element;
}

export const DateTimeRangeLayoutDesktop: FC<Props> = ({
  calendar,
  cancelButton,
  disableEscapeKeyDown,
  endDatePicker,
  endTimePicker,
  isDateTimePickerOpen,
  onCancel,
  resetButton,
  setRangeButton,
  startDatePicker,
  startTimePicker,
  ...props
}): JSX.Element => {
  const { t } = useTranslation(['common']);
  const mediumScreen = useMaxScreen('md');

  return (
    <Modal
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={isDateTimePickerOpen}
      onClose={onCancel}
      sx={{ zIndex: 1350 }}
    >
      <Stack
        spacing={2}
        sx={{
          background: 'white',
          borderRadius: 1,
          left: '50%',
          outline: 'none',
          p: 3,
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'fit-content',
        }}
        data-testid={props['data-testid']}
      >
        <Typography variant="h6">{t('common:component.date-picker.labels.custom-date-range')}</Typography>
        <Stack direction="row" spacing={mediumScreen ? 1 : 8}>
          <Stack>
            {calendar}
            <Divider sx={{ alignSelf: 'center', width: mediumScreen ? '90%' : '100%' }} />
            {resetButton}
          </Stack>
          <Stack sx={{ justifyContent: 'space-between', width: mediumScreen ? '210px' : '250px' }}>
            <Stack sx={{ mt: 2 }}>
              <Typography sx={{ pb: 2 }}>{t('common:component.date-picker.labels.start-time').toLocaleUpperCase()}</Typography>
              {startDatePicker}
              {startTimePicker}
            </Stack>
            <Stack>
              <Typography sx={{ pb: 2 }}>{t('common:component.date-picker.labels.end-time').toLocaleUpperCase()}</Typography>
              {endDatePicker}
              {endTimePicker}
            </Stack>
            <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
              {cancelButton}
              {setRangeButton}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
