/* eslint-disable react-hooks/exhaustive-deps */
import { Id } from '@eagle/api-types';
import { InputTypes, Time, TypeDefinitionTypes } from '@eagle/common';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { from, of, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { useAuthenticated } from '../../auth';
import { ErrorMessage, getShowDeleted, useBoolFlag } from '../../components';
import { AppliedFilter, AppliedFilterType, FilterField, Pagination as PaginationData } from '../../components/entity-search/types';
import { FilterFieldProps, FilterPathIdentifiers } from '../../components/filter';
import { Search } from '../../components/search/search';
import { API_CALL_TEXT_LENGTH } from '../../constants';
import { useObservable, useTitle } from '../../hooks';
import { Undefinable } from '../../types';
import { PageAction } from '../../types/page-action';
import { FILTER_SHOW_DELETED_FLAG, getFilterPersisted, removeFilterItems, trackEvent } from '../../util';
import { FindItemsDeferredResult, FindItemsResult, Query } from './types';
import { SearchProvider, useSearch } from './use-search';
import { buildFilterFields } from './util';
import { ListPageView } from './view';

export type RenderFilterContentProps<T> = (
  filters: AppliedFilter<AppliedFilterType>[],
  setFilterOpen: (value: boolean) => void,
  onFiltersChanged: (filters: AppliedFilter<AppliedFilterType>[]) => unknown,
  showDeleted: boolean,
  setShowDeleted: (value: boolean) => void,
  data?: FindItemsResult<T>,
) => JSX.Element

interface Props<T> {
  actions?: PageAction[];
  'data-testid'?: string;
  filterDisabled?: boolean;
  filterFields: FilterFieldProps[];
  icon?: JSX.Element;
  id: string;
  navigateToEntity?: (item: T) => unknown;
  onQueryChanged: (query: Query) => Undefinable<FindItemsDeferredResult<T>>;
  pagination?: PaginationData;
  pathIdentifiers?: FilterPathIdentifiers[];
  renderContent?: (
    highlightIndex: Undefinable<number>,
    isLoading: boolean,
    items: Undefinable<T[]>,
    text: string
  ) => JSX.Element;
  renderTableContent?: (
    isLoading: boolean,
    items: Undefinable<T[]>,
    matchCount: number,
    pagination: PaginationData,
    setPagination: (value: PaginationData) => unknown,
    text: Undefinable<string>,
    error?: Error,
  ) => JSX.Element;
  renderFilterContent?: RenderFilterContentProps<T>;
  searchDisabled?: boolean;
  prePopulateSearch?: boolean;
  searchRemoved?: boolean;
  showDeletedLabel?: string;
  showFilterButton?: boolean;
  showTags?: boolean;
  storageKey: string;
  title: string;
}

const InternalListPageController = <T extends Id<I>, I = string>({
  actions,
  filterDisabled = false,
  filterFields,
  icon,
  navigateToEntity,
  onQueryChanged,
  pathIdentifiers = [],
  renderContent,
  renderFilterContent,
  renderTableContent,
  searchDisabled = false,
  prePopulateSearch = false,
  searchRemoved = false,
  showDeletedLabel,
  showFilterButton = true,
  showTags = true,
  storageKey,
  title,
  id,
  ...props
}: Props<T>): JSX.Element => {
  const searchBarRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['common']);
  useTitle(title);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<Undefinable<Error>>();
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [keysPressed, setKeysPressed] = useState<Array<string>>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    filters,
    highlightIndex,
    isLoading,
    pagination,
    result: resultData,
    setFilters,
    showDeleted,
    setShowDeleted,
    setIsLoading,
    setPagination,
    setResult,
    setText,
    text,
  } = useSearch();
  const { userInfo, account } = useAuthenticated();
  const hasShowDeletedFeature = useBoolFlag(FILTER_SHOW_DELETED_FLAG);
  const SHOW_DELETED_STORAGE_KEY = `${storageKey}`;

  const onError = (apiError: Error): void => {
    enqueueSnackbar(<ErrorMessage error={apiError} />, { variant: 'error' });
    setError(apiError);
  };

  const { handleQueryChanged, observable } = useMemo(() => {
    const subject = new Subject<Query>();

    return {
      handleQueryChanged: (query: Query): void => subject.next(query),
      observable: subject.pipe(
        tap(() => setIsLoading(true)),
        debounceTime(350),
        switchMap((query: Query) => {
          setError(undefined);

          if (query.search.length < API_CALL_TEXT_LENGTH && query.search.length !== 0) {
            return from([{ result: { results: [], itemCount: 0 }, resultDescription: '' }])
              .pipe(
                tap(({ result }) => {
                  setIsLoading(false);
                  setResult({ itemCount: result.results.length, matchCount: result.itemCount });
                }),
                finalize(() => true),
              );
          }

          const deferred = onQueryChanged(query);
          if (!deferred) return of(undefined);

          let complete = false;

          return from(deferred.promise).pipe(
            tap(({ result }) => {
              setResult({ itemCount: result.results.length, matchCount: result.itemCount });
              complete = true;
            }),
            catchError((err: Error) => {
              onError(err);
              return of(undefined);
            }),
            finalize(() => complete || deferred.cancel()),
          );
        }),
        tap(() => setIsLoading(false)),
      ),
    };
  }, [onQueryChanged]);
  const data = useObservable(observable, onError);

  // #region Shortcut Keys
  const keyUpHandler = useCallback((event: KeyboardEvent): void => {
    if (event.code.includes('Digit')) {
      if (event.shiftKey && event.altKey) {
        const numberKey = event.code.replace('Digit', '');
        keysPressed.push(numberKey);

        if (keysPressed.length > 1) {
          if (resultData && resultData.matchCount >= pagination.limit * (Number(keysPressed.join('')) - 1)) {
            setPagination({ ...pagination, skip: pagination.limit * (Number(keysPressed.join('')) - 1) });
          }
        } else {
          if (resultData && resultData.matchCount > pagination.limit * (Number(numberKey) - 1)) {
            setPagination({ ...pagination, skip: pagination.limit * (Number(numberKey) - 1) });
          }
        }
        setTimeout(() => setKeysPressed([]), Time.seconds(1));
      }
    }
  }, [keysPressed]);

  const keyDownHandler = useCallback((event: KeyboardEvent): void => {
    switch (event.key) {
      case 'F':
        if (event.shiftKey && event.altKey) {
          setFilterOpen(!filterOpen);
        }
        break;
      case 'ArrowRight':
        if (event.shiftKey && event.altKey) {
          if (resultData && resultData.matchCount < pagination.skip + pagination.limit) return;
          setPagination({ ...pagination, skip: pagination.skip + pagination.limit });
        }
        break;
      case 'ArrowLeft':
        if (event.shiftKey && event.altKey) {
          if (pagination.skip <= 0) return;
          if (pagination.skip < pagination.limit) {
            setPagination({ ...pagination, skip: 0 });
            return;
          }
          setPagination({ ...pagination, skip: pagination.skip - pagination.limit });
        }
        break;
    }
  }, [filterOpen, pagination]);

  const handleEnterPressed = useCallback((index: number): void => {
    if (!data || !data.result.results.length || highlightIndex === undefined || !navigateToEntity) return;
    trackEvent('list', 'selected_item', `${id}_list`, { 'selected_item': index ? (index + 1) : 1 });
    navigateToEntity(data.result.results[index]);
  }, [data, highlightIndex]);

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    window.addEventListener('keyup', keyUpHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
      window.removeEventListener('keyup', keyUpHandler);
    };
  }, [keyDownHandler, keyUpHandler]);

  // #endregion

  const fields = useMemo(
    () => {
      const fieldsArray: FilterField[] = [];
      filterFields.forEach((filter, index) => {
        const filterField = buildFilterFields(filter.entityTypes, filters, filter.typePropertyName, t('common:terms.tag'), filter.propertyLabel, index !== 0 ? false : showTags);
        fieldsArray.push(...filterField);
      });
      if (!fieldsArray.length && showTags) {
        fieldsArray.push(
          {
            definition: {
              description: null,
              format: 'raw',
              input: InputTypes.TEXT,
              label: t('common:terms.tag'),
              multiple: null,
              type: TypeDefinitionTypes.TEXT,
            },
            path: 'tags',
          },
        );
      }
      return fieldsArray.sort((a, b) => (a.definition.label > b.definition.label) ? 1 : -1);
    },
    [filterFields, filters],
  );

  useEffect(() => {
    const persistedFilters = getFilterPersisted(storageKey, pathIdentifiers);
    setFilters(persistedFilters);
  }, [account]);

  useEffect(() => {
    setShowDeleted(getShowDeleted(SHOW_DELETED_STORAGE_KEY, hasShowDeletedFeature));
  }, []);

  useEffect(() => {
    handleQueryChanged({ filters, pagination, search: text, showDeleted });
  }, [filters, pagination, showDeleted, text, userInfo.accountId, handleQueryChanged]);

  useEffect(() => {
    if (!prePopulateSearch) return;
    const searchText = searchParams.get('searchText');
    if (!searchText) return;
    if (searchText.length > 2) setText(searchText);
    setSearchParams({}, { replace: true });
  }, [searchParams]);

  const removeFilter = useCallback((filterToRemove: AppliedFilter) => {
    const updateFilters = filters.filter((f) => f.id !== filterToRemove.id);

    removeFilterItems([filterToRemove]);
    setFilters(updateFilters);
  }, [filters]);

  const searchComponent = useMemo(() => {
    if (searchRemoved) return <></>;
    return (
      <Search
        id={id}
        ref={searchBarRef}
        disabled={searchDisabled}
        focus={true}
        onCloseClicked={() => setSearchOpen(false)}
        onEnterPressed={handleEnterPressed}
      />
    );
  }, [handleEnterPressed, searchBarRef, searchDisabled, searchRemoved]);

  return (
    <ListPageView
      id={id}
      actions={actions}
      appliedFilters={filters}
      data-testid={props['data-testid']}
      fields={fields}
      filterDisabled={filterDisabled}
      filterDrawerComponent={renderFilterContent?.(filters, setFilterOpen, setFilters, showDeleted, setShowDeleted, data)}
      icon={icon}
      isLoading={isLoading}
      listContent={renderContent?.(highlightIndex, isLoading, data?.result.results, text)}
      matchCount={data?.result.itemCount ?? 0}
      onFiltersChanged={setFilters}
      openFilter={filterOpen}
      pagination={pagination}
      removeAllFilters={() => {
        removeFilterItems(filters);
        setFilters([]);
      }}
      removeFilter={removeFilter}
      search={searchComponent}
      searchOpen={searchOpen}
      searchRemoved={searchRemoved}
      setFilterOpen={setFilterOpen}
      setPagination={setPagination}
      setSearchOpen={setSearchOpen}
      showDeletedLabel={showDeletedLabel}
      showFilterButton={showFilterButton}
      storageKey={storageKey}
      subtitle={data?.resultDescription}
      tableContent={renderTableContent?.(
        isLoading,
        data?.result.results,
        data?.result.itemCount ?? 0,
        pagination,
        setPagination,
        text,
        error,
      )}
      title={title}
    />
  );
};

export const ListPage = <T extends Id<I>, I = string>(props: Props<T>): JSX.Element => {
  return (
    <SearchProvider dataKey="list" pagination={props.pagination}>
      <InternalListPageController<T, I> {...props} />
    </SearchProvider>
  );
};
