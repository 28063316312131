/* eslint-disable react-hooks/rules-of-hooks */
import { Person, Thing } from '@eagle/core-data-types';
import { FC, useEffect, useMemo, useState } from 'react';
import { useAuthenticated } from '../../auth';
import { Lookup, simpleRenderer } from '../../components';
import { LOOKUP_DEBOUNCE_TIME } from '../../constants';
import { usePromise } from '../../hooks';
import { Nullable } from '../../types';
import { FILTER_OUT } from '../../util';
import { EntityDetail } from './subscription-detail.types';

interface Props {
  disabled?: boolean;
  entityData: EntityDetail;
  handleSelectedEntity: (entity: Thing | Person, key: string) => void;
  'data-testid'?: string;
}

const THING_PEOPLE_LIMIT = 25;

export const EntityLookup: FC<Props> = ({ disabled, entityData, handleSelectedEntity, ...props }) => {
  const { axios } = useAuthenticated();
  const [selected, setSelected] = useState<Thing | Person>();
  const { key, alertFilterId, apiUrl, label, noResults } = entityData;

  if (!apiUrl || !key) return <></>;

  const [initialSelection] = usePromise(
    async () => {
      if (!alertFilterId) return;
      const result = await axios.get<Thing | Person>(`${apiUrl}/${alertFilterId}`);
      return result.data;
    },
    [alertFilterId, apiUrl, axios],
  );

  useEffect(() => setSelected(initialSelection), [initialSelection]);

  const findEntities = useMemo<(_: string) => Promise<Thing[] | Person[]>>(() => {
    return (text: string) => axios.get<Thing[] | Person[]>(`${apiUrl}`, {
      params: {
        filter: FILTER_OUT.deleted,
        limit: THING_PEOPLE_LIMIT,
        search: text,
      },
    }).then((result) => result.data);
  }, [apiUrl, axios]);

  const handleItemSelected = (value: Nullable<Thing | Person>): void => {
    if (!value) return;
    setSelected(value);
    handleSelectedEntity(value, key);
  };

  return (
    <Lookup<Thing | Person>
      data-testid={props['data-testid']}
      debounceTime={LOOKUP_DEBOUNCE_TIME}
      disabled={disabled}
      handleFindItems={findEntities}
      handleFormatListItem={simpleRenderer}
      handleFormatSelectedItem={(option) => option.display}
      label={label}
      isSearch
      noResultsInstructions={noResults}
      onItemSelected={handleItemSelected}
      selectedItem={selected ?? null}
      size="small"
    />
  );
};
