import { makeStyles } from '../../theme';

export const videoPlayerStyles = makeStyles()(() => ({
  actionCard: {
    backgroundColor: '#25252585!important',
    padding: 8,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
  errorText: {
    bottom: '45%',
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
  },
  playerButtons: {
    color: '#fff',
    padding: '.125em',
  },
  root: {
    backgroundColor: '#000',
    color: '#fff',
    maxHeight: '100vh',
    maxWidth: '100vw',
    objectFit: 'cover',
    position: 'relative',
  },
  video: {
    '&:hover + div': {
      opacity: 1,
    },
  },
  videoPlayerControllers: {
    backgroundColor: '#25252585',
    bottom: '0%',
    height: '2em',
    opacity: 0,
    position: 'absolute',
    '&:active': {
      opacity: 1,
    },
    '&:focus': {
      opacity: 1,
    },
    '&:focus-within': {
      opacity: 1,
    },
    '&:hover': {
      opacity: 1,
    },
  },
}));
