import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ChangeEvent, FC, ReactNode } from 'react';

interface Props {
  'data-testid'?: string;
  disabled?: boolean;
  options: RadioButtonOption[];
  radioSize?: 'small' | 'medium';
  selected: string | number;
  setSelected: (value: string) => void;
  row?: boolean;
}

export interface RadioButtonOption {
  label: string | ReactNode;
  testId?: string;
  value: string;
}

export const RadioButtonSet: FC<Props> = ({ disabled, options, radioSize = 'small', selected, setSelected, row = true, ...props }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelected((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl data-testid={props['data-testid']}>
      <RadioGroup
        value={selected}
        onChange={handleChange}
        row={row}
      >
        {options
          && options.map(({ label, testId, value }, index) =>
            <FormControlLabel
              data-testid={testId ?? `${index}-input`}
              componentsProps={{ typography: { fontSize: '12px' } }}
              control={<Radio size={radioSize} />}
              disabled={disabled}
              key={index}
              label={label}
              value={value}
            />,
          )
        }
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonSet;
