import { RoleFunction } from '@eagle/common';
import { Group } from '@eagle/core-data-types';
import { Chip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { Portals } from '../../types';
import { CacheDataTypes } from '../../types/cache';
import { switchToPortal, useHasAuthorization } from '../../util';
import { FetchAll } from '../fetch';
import { useBoolFlag } from '../flags';

interface Data {
  groupIds: string[];
  isClickable?: boolean;
  noResultsText?: string;
  type: CacheDataTypes;
  sx?: SxProps;
}

const ADMIN_PORTAL_ROLES = [RoleFunction.ADMINISTRATOR] as const;

const GroupChipRender: FC<{ groupData: Group; isClickable: boolean; sx?: SxProps }> = ({ groupData, isClickable, sx }) => {
  const { hasAuthorization } = useHasAuthorization();
  const hasAdminPortalPermissions = hasAuthorization(ADMIN_PORTAL_ROLES);
  const groupNavigationFlag = useBoolFlag('portals-group-navigation-component');
  const clickEnabled = isClickable && hasAdminPortalPermissions && groupNavigationFlag;

  return (
    <Chip
      key={groupData._id}
      clickable={clickEnabled}
      component={clickEnabled ? 'a' : 'div'}
      href={switchToPortal(null, Portals.ADMIN, `group/${groupData._id}`)}
      label={groupData.display}
      size="small"
      sx={{ mr: 0.5, mb: 0.5, ...sx }}
      variant="outlined"
    />
  );
};

export const GroupChipElement: FC<Data> = ({ groupIds, type, isClickable = false, noResultsText, sx }) => {
  if (groupIds.length < 1) {
    return noResultsText ? <Typography variant='body2' fontStyle="italic" sx={sx}>{noResultsText}</Typography> : <></>;
  }

  const set = new Set<string>(groupIds);
  return (
    <FetchAll
      dataType={type}
      filterFactory={(groupData: Group) => set.has(groupData._id)}
      renderFactory={(groupData: Group, key) => <GroupChipRender key={key} groupData={groupData} isClickable={isClickable} sx={sx} />}
    />
  );
};
