import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  user: {
    email: string;
    display: string;
  };
}

export const UserBasicTitle: FC<Props> = ({ user }) => {
  const { t } = useTranslation(['admin']);
  return (
    <Typography sx={{ wordBreak: 'break-all', pr: 1 }}>
      {
        (user.email === '' || user.display === '')
          ? t('admin:page.create-user.basic-info.heading')
          : `${user.display} - ${user.email}`
      }
    </Typography>
  );
};
