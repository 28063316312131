import { Stack } from '@mui/material';
import { FC, PropsWithChildren, RefObject } from 'react';
import { SIDEBAR_FULL_WIDTH, SIDEBAR_WIDTH } from '../../constants';
import { useSidebarPinContext } from '../../hooks';
import { Nullable } from '../../types';
import { FlexBox } from '../flex-box';
import { Sidebar } from '../sidebar';

interface Props extends PropsWithChildren {
  drawerContent: JSX.Element;
  drawerOpen: boolean;
  drawerRef: Nullable<RefObject<HTMLDivElement>>;
  drawerWidthCollapsed?: number;
  drawerWidthExpanded?: number;
  onDrawerClose: () => void;
  onDrawerOpen: () => void;
}

export const AppDesktopLayout: FC<Props> = ({ children, drawerContent, drawerOpen, drawerRef, drawerWidthCollapsed = SIDEBAR_WIDTH, drawerWidthExpanded = SIDEBAR_FULL_WIDTH, onDrawerClose, onDrawerOpen }) => {
  const { isPinned } = useSidebarPinContext();
  return (
    <Stack className="App-root desktop" data-testid="app-page" direction="row" sx={{ flexGrow: 1, width: 1 }}>
      <Sidebar
        drawerRef={drawerRef}
        drawerOpen={drawerOpen}
        drawerWidthCollapsed={drawerWidthCollapsed}
        drawerWidthExpanded={drawerWidthExpanded}
        onDrawerClose={onDrawerClose}
        onDrawerOpen={onDrawerOpen}
        pinSidebar={isPinned}
      >
        {drawerContent}
      </Sidebar>
      <FlexBox
        data-testid="app-body-wrapper"
        sx={{ transition: 'margin-left .2s ease-in-out .1s, min-width .25s ease', marginLeft: isPinned ? 0 : `${drawerWidthCollapsed}px`, minWidth: `calc(100% - ${isPinned ? drawerWidthExpanded : drawerWidthCollapsed}px)`, flexDirection: 'column' }}
      >
        {children}
      </FlexBox>
    </Stack>
  );
};
