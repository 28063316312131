import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput';
import { TimePickerView } from '@mui/lab/TimePicker/shared';
import { ClassNameMap } from '@mui/material';
import { DateTime } from 'luxon';
import { SyntheticEvent } from 'react';
import { Display, Nullable, Undefinable } from '../../types';
import { ButtonVariant } from '../loading-button';

type Classes = ClassNameMap<'headings' | 'calendarPicker' | 'betweenTime' | 'justifyLeft' | 'mobileTimeTextField' | 'desktopTimeTextField' | 'timeSpacing' | 'timePicker' | 'container' | 'smallMobile' | 'containerMobile'>;

export type DateError = Nullable<'invalidDate' | 'minTime' | 'maxTime' | 'shouldDisableTime-hours' | 'shouldDisableTime-minutes' | 'shouldDisableTime-seconds'>;

export type DateState = Record<string, Undefinable<DateTime>>;

export type AnchorElement = Nullable<HTMLElement>;

export type PopperPlacement = 'bottom-start' | 'bottom' | 'bottom-end';

export interface DateFilterField extends Display {
  id: string;
  data: {
    endTime: (time: DateTime) => DateTime;
    startTime: (time: DateTime) => DateTime;
  };
}

export type DateTimeRangeChangeHandler = (dateRangeStart: Date, dateRangeEnd: Date) => void;

export interface DateTimeRangeProps {
  'data-testid'?: string;
  buttonVariant?: ButtonVariant;
  dateOptions: DateFilterField[];
  defaultDateLabel?: string;
  defaultEnd?: DateTime;
  defaultLabel?: string;
  defaultStart?: DateTime;
  disabled?: boolean;
  handleClose?: () => void;
  layout?: 'vertical' | 'horizontal';
  maxDateTime?: DateTime;
  minDateTime?: DateTime;
  onChange: DateTimeRangeChangeHandler;
  onlyIcon?: boolean;
  open?: boolean;
  origin?: number | 'left' | 'right' | 'center';
  pickerWidth?: string;
  placeholderText?: string;
  popoverWidth?: string;
  popperPlacement?: PopperPlacement;
  required?: boolean;
  size?: 'small' | 'medium';
  sx?: Record<string, unknown>;
  variant?: 'outlined' | 'filled' | 'standard';
  views?: TimePickerView[];
}

interface LayoutProps {
  classes: Classes;
  date?: DateTime;
  end?: DateTime;
  error: DateError;
  handleEnd: (newDate: Nullable<Date>, keyboardInput: Undefinable<string>) => void;
  handleError: (err: DateError) => void;
  handleStart: (newDate: Nullable<Date>, keyboardInput: Undefinable<string>) => void;
  is12AM: (time: DateTime) => boolean;
  maxTime: DateTime;
  minTime: DateTime;
  renderInput: (params: MuiTextFieldProps, inputType: string) => React.ReactElement;
  start?: DateTime;
}

export interface MobileLayoutProps extends LayoutProps {
  closeMobileTime: () => void;
  handleBack: () => void;
  handleNext: () => void;
  handleTab: (_: SyntheticEvent, newTab: string) => void;
  tab: string;
  views: TimePickerView[];
}

export interface DesktopLayoutProps extends LayoutProps {
  buttonHandler: (id: string) => void;
  dateOptions: DateFilterField[];
  handleCalendar: (newDate: Nullable<Date>) => void;
  hourCycle: () => Undefinable<boolean>;
  layout?: 'vertical' | 'horizontal';
  maxDate: DateTime;
  minDate?: DateTime;
  openMobileTime: (tabVal: string) => void;
  popperPlacement?: PopperPlacement;
  saveDate: () => void;
  smallScreen: boolean;
  sx?: Record<string, unknown>;
}

export interface DateTimeRangeViewProps extends LayoutProps, DesktopLayoutProps, MobileLayoutProps {
  anchorElement: AnchorElement;
  'data-testid'?: string;
  disabled: boolean;
  formattedDate: string;
  handleClose: () => void;
  handleOpen: (event: any) => void;
  layout?: 'vertical' | 'horizontal';
  mobileTabs: boolean;
  onlyIcon: boolean;
  open: boolean;
  origin: number | 'left' | 'right' | 'center';
  pickerWidth: string;
  placeholderText?: string;
  popoverWidth: string;
  required: boolean;
  size: 'small' | 'medium';
  variant: 'outlined' | 'filled' | 'standard';
}

export interface DynamicTimePicker {
  dateTime?: DateTime;
  displayText?: DateTime | string;
  handler: (newDate: Nullable<Date>, keyboardInput: Undefinable<string>) => void;
  inputType: string;
  openTo: 'startDate' | 'endDate';
}

export enum TABS {
  endDate = 'endDate',
  startDate = 'startDate',
}

export enum TimeInputType {
  end = 'end',
  start = 'start'
}
