import { Person, PersonType } from '@eagle/core-data-types';
import { Box, ListItem, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { API_CALL_TEXT_LENGTH } from '../../constants';
import { useSmallScreen } from '../../hooks';
import { CacheDataTypes } from '../../types/cache';
import { Alert } from '../alert';
import { FetchOneOfAll } from '../fetch';
import { FormatProperty } from '../format';
import { ListPaperColumn } from '../list-item';
import { ListRow } from '../list-row';
import { SimpleElement } from '../simple-element/simple-element';
import { LinkPerson } from './link-person';
import { RowProps, SearchResultsProps } from './types';

const ResultsRow: FC<RowProps> = ({ assignPerson, person, personType, assigned, thingId }) => {
  const smallScreen = useSmallScreen();

  return (
    <ListRow>
      <ListPaperColumn
        label={<SimpleElement key={personType._id} data={personType} />}
        width="40%"
      >
        {person.display}
      </ListPaperColumn>
      {!smallScreen && personType.properties.order.slice(0, 1).map((property, i) => (
        <ListPaperColumn
          key={i}
          label={personType.properties.definitions[property].label}
          width="40%"
        >
          <FormatProperty
            value={person.properties[property]}
            definition={personType.properties.definitions[property]}
          />
        </ListPaperColumn>
      ))}
      {(personType.properties.order.length === 0 && !smallScreen) && <Box style={{ width: '40%' }} />}
      <LinkPerson
        assignPerson={assignPerson}
        isLinked={assigned}
        personId={person._id}
        thingId={thingId}
      />
    </ListRow>
  );
};

const renderDialogList = (
  assigned: boolean,
  assignPerson: (id: string, role: string) => void,
  person: Person,
  thingId: string,
): JSX.Element => (
  <FetchOneOfAll
    dataType={CacheDataTypes.PERSON_TYPE}
    id={person.personTypeId}
    renderFactory={(personType: PersonType) => (
      <ResultsRow
        assigned={assigned}
        assignPerson={assignPerson}
        key={person._id}
        person={person}
        personType={personType}
        thingId={thingId}
      />
    )}
  />
);

export const SearchResults = ({ assignedPersons, assignPerson, initialInstructions, isLoading, noResultsInstructions, searchResults, thingId, searchText, lessThanCountCharacters }: SearchResultsProps): JSX.Element => {
  if (!searchResults) {
    return <Typography color="text.secondary" sx={{ my: 3 }}>{initialInstructions}</Typography>;
  }
  if (searchText && searchText.length < API_CALL_TEXT_LENGTH) return <Alert severity="info">{lessThanCountCharacters}</Alert>;
  if (!searchResults.length) {
    return <Alert severity="info">{noResultsInstructions}</Alert>;
  }

  return (
    <Stack spacing={2} sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>{
      searchResults.map((person, index) => (
        <Paper key={index} elevation={1} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <ListItem sx={{ userSelect: 'none' }}>
            {renderDialogList(assignedPersons.findIndex((assigned) => assigned.personId === person._id) > -1, assignPerson, person, thingId)}
          </ListItem>
        </Paper>
      ))
    }</Stack>
  );
};
