/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import ga from 'react-ga4';
import { AuthStatus, useAuth } from '../../auth';
import { useSwitchAwareConfig } from '../use-config';

export const useGoogleAnalytics = (): void => {
  const { productName } = useSwitchAwareConfig();
  const { state } = useAuth();

  useEffect(() => {
    if (!state || state.status !== AuthStatus.AUTHENTICATED) {
      return;
    }

    const { account, user, accountType } = state;

    const build = import.meta.env.VITE_BUILD || import.meta.env.REACT_APP_BUILD;

    ga.gtag('set', 'user_id', user._id);

    ga.gtag('set', 'user_properties', {
      'user_email': user.email,
      'user_display': user.display,
      'account_id': account._id,
      'account_name': account.display,
      'product': productName,
      'account_type': accountType?.display || '(not set)',
      'home_domain': account.homeDomain,
      'app_version': build,
    });
  }, [productName, state]);
};
