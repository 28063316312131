import { ErrorBoundary as SentryErrorBoundary, FallbackRender } from '@sentry/react';
import { FC, PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '../alert';

interface ErrorBoundaryProps extends PropsWithChildren {
  fallback?: ReactElement | FallbackRender;
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children, fallback }) => {
  const { t } = useTranslation(['common']);

  return (
    <SentryErrorBoundary
      fallback={fallback ?? (
        <Alert severity='error'>
          {t('common:page.errors.system-error.title')}
        </Alert>
      )}>
      {children}
    </SentryErrorBoundary>
  );
};
