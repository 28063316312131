import { Collapse, LinearProgress } from '@mui/material';
import { FC } from 'react';

export const LoadingComponent: FC<{ isLoading: boolean }> = ({ isLoading }) => (
  <Collapse
    in={isLoading}
    sx={{ position: 'absolute', width: '100%', zIndex: 401 }}
  >
    <LinearProgress color="inherit" />
  </Collapse>
);
