// cSpell:ignore camelcase, landuse
/* eslint-disable camelcase */

export const vectorLayerStyles = {
  water: {
    data: {
      source: 'omv',
      layer: 'water',
    },
    'water fill': {
      filter: [
        {
          $geometry: 'polygon',
        },
      ],
      draw: {
        polygons: {
          order: 'global.sort_rank',
          color: 'global.water',
        },
      },
    },
    rivers: {
      filter: [
        {
          $geometry: 'line',
        },
      ],
      draw: {
        text: {
          font: {
            family: 'Montserrat',
            text_source: 'global.language_text_source',
            text_wrap: 10,
            order: 'global.sort_rank',
            size: 12.8,
            priority: 100,
            fill: 'global.waterText',
            stroke: {
              color: 'global.waterTextOutline',
              width: 2,
            },
          },
        },
      },
    },
    lakes: {
      filter: {
        all: [
          'function() { return feature.min_zoom <= ($zoom); }',
          {
            $geometry: 'point',
          },
        ],
      },
      draw: {
        text: {
          font: {
            family: 'Montserrat',
            text_source: 'global.language_text_source',
            text_wrap: 10,
            order: 'global.sort_rank',
            size: 12.8,
            priority: 100,
            fill: 'global.waterText',
            stroke: {
              color: 'global.waterTextOutline',
              width: 2,
            },
          },
        },
      },
    },
  },
  urban: {
    data: {
      source: 'omv',
      layer: 'landuse',
    },
    filter: "function() { return feature.kind.startsWith ('urban');}",
    draw: {
      polygons: {
        color: 'global.baseColor',
        order: 'global.sort_rank',
      },
    },
  },
  urban_area: {
    data: {
      source: 'omv',
      layer: 'landuse',
    },
    filter: {
      all: [
        {
          $geometry: 'polygon',
        },
        {
          kind: 'urban_area',
        },
      ],
    },
    draw: {
      polygons: {
        color: 'global.baseColor',
        order: 'global.sort_rank',
      },
    },
  },
  park_national: {
    data: {
      source: 'omv',
      layer: 'landuse',
    },
    filter: {
      all: [
        {
          $geometry: 'polygon',
        },
        {
          kind: 'national_park',
        },
      ],
    },
    draw: {
      polygons: {
        color: 'global.parks',
        order: 'global.sort_rank',
      },
    },
  },
  park: {
    data: {
      source: 'omv',
      layer: 'landuse',
    },
    filter: {
      all: [
        {
          $geometry: 'polygon',
        },
      ],
      any: [
        {
          kind: [
            'nature',
            'forest',
            'park',
            'wood',
            'natural_wood',
            'grass',
            'meadow',
            'village_green',
            'dog_park',
            'garden',
            'nature_reserve',
            'protected_area',
          ],
        },
      ],
    },
    draw: {
      polygons: {
        color: 'global.parks',
        order: 'global.sort_rank',
      },
    },
  },
  pitch: {
    data: {
      source: 'omv',
      layer: 'landuse',
    },
    filter: {
      all: [
        {
          $geometry: 'polygon',
        },
        {
          kind: 'pitch',
        },
      ],
    },
    draw: {
      polygons: {
        color: 'pink',
        order: 'global.sort_rank',
      },
    },
  },
  cemetery: {
    data: {
      source: 'omv',
      layer: 'landuse',
    },
    filter: {
      all: [
        {
          $geometry: 'polygon',
        },
        {
          kind: 'cemetery',
        },
      ],
    },
    draw: {
      polygons: {
        color: 'global.baseColor',
        order: 'global.sort_rank',
      },
    },
  },
  bridge: {
    data: {
      source: 'omv',
      layer: 'landuse',
    },
    filter: {
      all: [
        {
          $geometry: 'polygon',
        },
        {
          kind: 'bridge',
        },
      ],
    },
    draw: {
      polygons: {
        color: '#C4BFB0',
        order: 'global.sort_rank',
      },
    },
  },
  beach: {
    data: {
      source: 'omv',
      layer: 'landuse',
    },
    filter: {
      all: [
        {
          $geometry: 'polygon',
        },
      ],
      any: [
        {
          kind: 'beach',
        },
        "function() { return feature.kind.endsWith ('_site'); }",
      ],
    },
    draw: {
      polygons: {
        color: 'global.beach',
        order: 'global.sort_rank',
      },
    },
  },
  railway_s_bahn: {
    data: {
      source: 'omv',
      layer: 'roads',
    },
    filter: {
      all: [
        {
          $geometry: 'line',
        },
        {
          kind: ['rail', 'light_rail', 'tram'],
        },
      ],
      not: [
        {
          is_tunnel: true,
        },
      ],
    },
    draw: {
      background: {
        style: 'lines',
        order: 'global.sort_rank',
        color: 'global.trainTrack',
        width: [
          [9, 300],
          [11, 70],
          [13, 18],
          [14, 10],
          [15, 5],
          [17, 1.5],
        ],
      },
      dashes: {
        style: 'lines',
        order: 'function() { return feature.sort_rank + 1.25; }',
        color: 'global.trainTrack',
        width: 5,
        dash: [0.2, 2],
      },
    },
  },
  railway_s_bahn_tunnel: {
    data: {
      source: 'omv',
      layer: 'roads',
    },
    filter: {
      all: [
        {
          $geometry: 'line',
        },
        {
          kind: ['rail', 'light_rail', 'tram'],
        },
        {
          is_tunnel: true,
        },
      ],
    },
    draw: {
      background: {
        style: 'lines',
        order: 'global.sort_rank',
        color: 'global.trainTrack',
        width: [
          [13, 7],
          [14, 5],
        ],
      },
      dashes: {
        style: 'lines',
        order: 'function() { return feature.sort_rank + 1.25; }',
        color: 'global.trainTrack',
        width: 5,
        dash: [0.2, 2],
      },
    },
  },
  tram: {
    data: {
      source: 'omv',
      layer: 'roads',
    },
    filter: {
      all: [
        {
          $geometry: 'line',
        },
        {
          kind: 'rail',
        },
        {
          kind_detail: 'tram',
        },
      ],
    },
    draw: {
      lines: {
        color: 'global.trainTrack',
        width: [
          [13, 6],
          [16, 3],
          [17, 1.6],
        ],
        order: 'global.sort_rank',
      },
    },
  },
  pedestrian: {
    data: {
      source: 'omv',
      layer: 'roads',
    },
    filter: "function() { return (feature.kind == 'path' && (feature.kind_detail.startsWith('pedestrian') || feature.kind_detail.startsWith('footway'))); }",
    draw: {
      sidewalk_line: {
        style: 'lines',
        dash: [4, 3],
        color: 'global.pedestrian',
        width: [
          [15, '0px'],
          [16, '1px'],
        ],
        order: 'function() { return feature.sort_rank + 1; }',
      },
    },
    label: {
      filter: [
        {
          $zoom: {
            min: 15,
          },
        },
      ],
      draw: {
        text: {
          font: {
            family: 'Montserrat',
            text_source: 'global.language_text_source',
            size: 12,
            order: 'function() { return feature.sort_rank + 1; }',
            priority: 15,
            fill: 'global.minorRoadText',
            stroke: {
              color: 'global.roadTextOutline',
              width: 3,
            },
          },
        },
      },
    },
  },
  residential: {
    data: {
      source: 'omv',
      layer: 'roads',
    },
    filter: "function() { return (feature.kind == 'minor_road' && ( feature.kind_detail== 'unclassified') || (feature.kind_detail == 'residential') || (feature.kind_detail == 'service' )); }",
    draw: {
      road_line: {
        style: 'lines',
        color: 'global.roadLine',
        order: 'function() { return feature.sort_rank + 0.5; }',
        width: [
          [11, 0],
          [13, 20],
          [14, 15],
          [16, 9],
          [18, 8],
        ],
      },
    },
    label: {
      filter: [
        {
          $zoom: {
            min: 16,
          },
        },
      ],
      draw: {
        text: {
          font: {
            family: 'Montserrat',
            text_source: 'global.language_text_source',
            size: 12,
            priority: 15,
            fill: 'global.minorRoadText',
            stroke: {
              color: 'global.roadTextOutline',
              width: 3,
            },
          },
        },
      },
    },
  },
  secondary: {
    data: {
      source: 'omv',
      layer: 'roads',
    },
    filter: "function() { return (feature.kind == 'major_road' && feature.kind_detail.startsWith('secondary')); }",
    draw: {
      road_casing: {
        style: 'lines',
        color: 'global.roadCasing',
        order: 'global.sort_rank',
        width: [
          [7, 240],
          [8, 220],
          [9, 200],
          [10, 180],
          [11, 160],
          [12, 110],
          [13, 60],
          [14, 45],
          [16, 18],
          [18, 14],
        ],
      },
      road_line: {
        style: 'lines',
        color: 'global.roadLine',
        order: 'function() { return feature.sort_rank + 0.5; }',
        width: [
          [7, 200],
          [8, 180],
          [9, 160],
          [10, 140],
          [11, 100],
          [12, 80],
          [13, 40],
          [14, 30],
          [16, 14],
          [18, 12],
        ],
      },
    },
    label: {
      filter: [
        {
          $zoom: {
            min: 13,
          },
        },
      ],
      draw: {
        text: {
          font: {
            family: 'Montserrat',
            text_source: 'global.language_text_source',
            size: 14,
            priority: 15,
            fill: 'global.roadText',
            stroke: {
              color: 'global.roadTextOutline',
              width: 2,
            },
          },
        },
      },
    },
  },
  tertiary: {
    data: {
      source: 'omv',
      layer: 'roads',
    },
    filter: "function() { return (feature.kind == 'major_road' && (feature.kind_detail.startsWith('tertiary'))); }",
    draw: {
      road_casing: {
        style: 'lines',
        color: 'global.roadCasing',
        order: 'global.sort_rank',
        width: [
          [11, 220],
          [12, 120],
          [13, 66],
          [14, 46],
          [16, 15],
          [18, 12],
        ],
      },
      road_line: {
        style: 'lines',
        color: 'global.roadLine',
        order: 'function() { return feature.sort_rank + 5; }',
        width: [
          [11, 140],
          [12, 90],
          [13, 50],
          [14, 36],
          [16, 12],
          [18, 10],
        ],
      },
    },
    label: {
      filter: [
        {
          $zoom: {
            min: 13,
          },
        },
      ],
      draw: {
        text: {
          font: {
            family: 'Montserrat',
            text_source: 'global.language_text_source',
            size: 16,
            priority: 20,
            fill: 'global.roadText',
            stroke: {
              color: 'global.roadTextOutline',
              width: 2,
            },
          },
        },
      },
    },
  },
  trunk: {
    data: {
      source: 'omv',
      layer: 'roads',
    },
    filter: "function() { return (feature.kind == 'highway' && (feature.kind_detail.startsWith('trunk'))); }",
    draw: {
      road_casing: {
        style: 'lines',
        color: 'global.roadCasing',
        order: 'global.sort_rank',
        width: [
          [5, 5000],
          [6, 3000],
          [7, 2200],
          [8, 1900],
          [9, 1200],
          [10, 640],
          [11, 340],
          [12, 180],
          [13, 90],
          [14, 70],
          [16, 22],
          [18, 16],
        ],
      },
      road_line: {
        style: 'lines',
        color: 'global.roadLine',
        order: 'function() { return feature.sort_rank + 5; }',
        width: [
          [5, 5000],
          [6, 3000],
          [7, 2200],
          [8, 1300],
          [9, 800],
          [10, 440],
          [11, 240],
          [12, 130],
          [13, 70],
          [14, 54],
          [16, 18],
          [18, 14],
        ],
      },
    },
  },
  primary: {
    data: {
      source: 'omv',
      layer: 'roads',
    },
    filter: "function() { return (feature.kind == 'major_road' && ( feature.kind_detail.startsWith('primary'))); }",
    draw: {
      road_casing: {
        style: 'lines',
        color: 'global.roadCasing',
        order: 'global.sort_rank',
        width: [
          [8, 1900],
          [9, 1200],
          [10, 640],
          [11, 340],
          [12, 180],
          [13, 90],
          [14, 70],
          [16, 22],
          [18, 16],
        ],
      },
      road_line: {
        style: 'lines',
        color: 'global.roadLine',
        order: 'function() { return feature.sort_rank + 5; }',
        width: [
          [8, 1300],
          [9, 800],
          [10, 440],
          [11, 240],
          [12, 130],
          [13, 70],
          [14, 54],
          [16, 18],
          [18, 14],
        ],
      },
    },
    label_low: {
      filter: {
        all: [
          {
            $zoom: {
              min: 12,
            },
          },
        ],
      },
      draw: {
        text: {
          repeat_distance: '75px',
          font: {
            family: 'Montserrat',
            text_source: 'global.language_text_source',
            size: 16,
            priority: 25,
            fill: 'global.roadText',
            stroke: {
              color: 'global.roadTextOutline',
              width: 2,
            },
          },
        },
      },
    },
    label_high: {
      filter: [
        {
          $zoom: {
            min: 13,
          },
        },
      ],
      draw: {
        text: {
          repeat_distance: '75px',
          font: {
            family: 'Montserrat',
            text_source: 'global.language_text_source',
            size: 16,
            priority: 25,
            fill: 'global.roadText',
            stroke: {
              color: 'global.roadTextOutline',
              width: 2,
            },
          },
        },
      },
    },
  },
  highway: {
    data: {
      source: 'omv',
      layer: 'roads',
    },
    filter: "function() { return (feature.kind == 'highway' && (feature.kind_detail.startsWith('motorway'))); }",
    draw: {
      road_casing: {
        style: 'lines',
        color: 'global.roadCasing',
        order: 'global.sort_rank',
        width: [
          [5, 7000],
          [6, 4600],
          [7, 3200],
          [8, 2400],
          [9, 1400],
          [10, 800],
          [11, 430],
          [12, 240],
          [13, 130],
          [14, 90],
          [16, 30],
          [18, 18],
        ],
      },
      road_line: {
        style: 'lines',
        color: 'global.majorRoadLine',
        order: 'function() { return feature.sort_rank + 5; }',
        width: [
          [5, 7000],
          [6, 4600],
          [7, 3200],
          [8, 1700],
          [9, 1020],
          [10, 600],
          [11, 320],
          [12, 190],
          [13, 104],
          [14, 70],
          [16, 26],
          [18, 16],
        ],
      },
    },
    link: {
      filter: {
        is_link: true,
      },
      draw: {
        link_casing: {
          style: 'lines',
          color: 'global.roadCasing',
          order: 'global.sort_rank',
          width: [
            [12, 120],
            [13, 50],
            [14, 36],
            [16, 18],
            [18, 14],
          ],
        },
        link_line: {
          style: 'lines',
          color: '#D1CDBC',
          order: 'function() { return feature.sort_rank + 5; }',
          width: [
            [12, 100],
            [13, 40],
            [14, 24],
            [16, 14],
            [18, 12],
          ],
        },
      },
    },
    label: {
      filter: {
        all: [
          {
            $zoom: {
              min: 11,
            },
          },
        ],
      },
      draw: {
        text: {
          repeat_distance: '75px',
          font: {
            family: 'Montserrat',
            text_source: 'global.language_text_source',
            size: 16,
            priority: 250,
            fill: 'global.roadText',
            stroke: {
              color: 'global.roadTextOutline',
              width: 3,
            },
          },
        },
      },
    },
  },
  country_border: {
    data: {
      source: 'omv',
      layer: 'boundaries',
    },
    filter: {
      all: [
        {
          $geometry: 'line',
        },
        {
          kind: 'country',
        },
      ],
    },
    draw: {
      boundary_case: {
        style: 'lines',
        color: '#A7A291',
        order: 'global.sort_rank',
        width: [
          [1, 20000],
          [2, 16000],
          [3, 14000],
          [4, 10000],
          [5, 6000],
          [6, 4000],
          [7, 2000],
          [8, 1000],
          [9, 500],
          [10, 240],
          [11, 160],
          [12, 80],
          [13, 40],
          [14, 20],
        ],
      },
      boundary_fill: {
        style: 'lines',
        color: '#8B8676',
        order: 'function() { return feature.sort_rank + 5; }',
        width: [
          [2, 4000],
          [3, 2800],
          [4, 2000],
          [5, 1000],
          [6, 440],
          [7, 180],
          [8, 100],
          [9, 60],
          [10, 40],
          [11, 30],
          [12, 20],
          [13, 10],
          [14, 4],
        ],
      },
    },
  },
  disputed_border: {
    data: {
      source: 'omv',
      layer: 'boundaries',
    },
    filter: {
      all: [
        {
          $geometry: 'line',
        },
      ],
      any: [
        {
          kind: [
            'disputed',
            'indefinite',
            'indeterminate',
            'lease_limit',
            'line_of_control',
            'overlay_limit',
          ],
        },
      ],
    },
    draw: {
      boundary_casing: {
        style: 'lines',
        dash: [2, 2],
        color: '#A7A291',
        order: 'global.sort_rank',
        width: [
          [1, 20000],
          [2, 16000],
          [3, 14000],
          [4, 10000],
          [5, 6000],
          [6, 4000],
          [7, 2000],
          [8, 1000],
          [9, 500],
          [10, 240],
          [11, 160],
          [12, 80],
          [13, 40],
          [14, 20],
        ],
      },
      boundary_fill: {
        style: 'lines',
        dash: [2, 2],
        color: '#8B8676',
        order: 'function() { return feature.sort_rank + 5; }',
        width: [
          [2, 8000],
          [3, 5200],
          [4, 3000],
          [5, 2000],
          [6, 1440],
          [7, 1040],
          [8, 540],
          [9, 280],
          [10, 180],
          [11, 120],
          [12, 60],
          [13, 30],
          [14, 20],
          [15, 10],
          [16, 4],
        ],
      },
    },
  },
  region_border: {
    data: {
      source: 'omv',
      layer: 'boundaries',
    },
    filter: {
      all: [
        {
          $geometry: 'line',
        },
        {
          kind: 'region',
        },
        {
          kind_detail: 4,
        },
      ],
    },
    draw: {
      boundary_case: {
        style: 'lines',
        dash: [4],
        color: 'global.regionBorderLine',
        order: '500',
        width: [
          [1, 4096],
          [2, 4096],
          [3, 4096],
          [4, 4096],
          [5, 4096],
          [6, 2048],
          [7, 1024],
          [8, 512],
          [9, 256],
          [10, 128],
          [11, 64],
          [12, 32],
          [13, 16],
          [14, 8],
          [15, 4],
          [16, 2],
          [17, 1],
          [18, 1],
        ],
      },
    },
  },
  some_layer: {
    data: {
      source: 'omv',
      layer: 'boundaries',
    },
    filter: [
      {
        $geometry: 'point',
      },
    ],
    draw: {
      text: {
        font: {
          family: 'Montserrat',
          text_source: 'global.language_text_source',
          size: 27.2,
          priority: 200,
          fill: 'global.placeText',
        },
      },
    },
  },
  earth_layer_island_label: {
    data: {
      source: 'omv',
      layer: 'earth',
    },
    filter: {
      all: [
        {
          $zoom: {
            min: 11,
          },
        },
        {
          kind: 'island',
        },
      ],
    },
    draw: {
      text: {
        text_source: 'global.language_text_source',
        text_wrap: 40,
        priority: 1,
        font: {
          family: 'Montserrat',
          weight: 400,
          priority: 12,
          fill: 'global.placeText',
          size: 14,
        },
      },
    },
  },
  earth_layer_land_label: {
    data: {
      source: 'omv',
      layer: 'earth',
    },
    filter: {
      all: [
        {
          kind: 'continent',
        },
      ],
      not: [
        {
          kind: 'island',
        },
      ],
    },
    draw: {
      text: {
        text_source: 'global.language_text_source',
        font: {
          family: 'Montserrat',
          weight: 400,
          fill: 'global.placeText',
          size: 30,
          priority: 12,
        },
      },
    },
  },
  country: {
    data: {
      source: 'omv',
      layer: 'places',
    },
    filter: {
      all: [
        {
          kind: 'country',
        },
      ],
    },
    draw: {
      text: {
        text_source: 'global.language_text_source',
        text_wrap: 30,
        priority: 10,
        font: {
          family: 'Montserrat',
          size: [
            [1, 8],
            [2, 10],
            [3, 12],
            [4, 14],
            [5, 16],
          ],
          fill: 'global.placeText',
          stroke: {
            color: 'global.placeTextOutline',
            width: 3,
          },
          transform: 'uppercase',
          weight: 400,
        },
      },
    },
  },
  locality_10million_capital: {
    data: {
      source: 'omv',
      layer: 'places',
    },
    filter: "function() { return ((((feature.kind == 'locality' ) && feature.population) && (feature.population > 10000000 || feature.country_capital)) && feature.country_capital); }",
    draw: {
      text: {
        text_source: 'global.language_text_source',
        text_wrap: 30,
        priority: 39,
        font: {
          family: 'Montserrat',
          weight: 'bold',
          size: [
            [1, 9.6],
            [2, 11.2],
            [3, 12.8],
            [4, 14.4],
            [5, 16],
            [6, 19.2],
            [7, 22.4],
            [8, 25.6],
            [9, 28.8],
            [10, 32],
          ],
          fill: 'global.placeText',
          stroke: {
            color: 'global.placeTextOutline',
            width: 3,
          },
        },
      },
    },
  },
  locality_10million: {
    data: {
      source: 'omv',
      layer: 'places',
    },
    filter: "function() { return ((feature.kind == 'locality' && feature.population) && (feature.population > 10000000 || feature.country_capital)); }",
    draw: {
      text: {
        text_source: 'global.language_text_source',
        text_wrap: 30,
        priority: 39,
        font: {
          family: 'Montserrat',
          size: [
            [1, 9.6],
            [2, 11.2],
            [3, 12.8],
            [4, 14.4],
            [5, 16],
            [6, 19.2],
            [7, 22.4],
            [8, 25.6],
            [9, 28.8],
            [10, 32],
          ],
          fill: 'global.placeText',
          stroke: {
            color: 'global.placeTextOutline',
            width: 3,
          },
        },
      },
    },
  },
  locality_label_1million: {
    data: {
      source: 'omv',
      layer: 'places',
    },
    filter: "function() { return (feature.kind = 'locality' && feature.population && (feature.region_capital || feature.population > 1000000)); }",
    draw: {
      text: {
        text_source: 'global.language_text_source',
        text_wrap: 30,
        priority: 40,
        font: {
          family: 'Montserrat',
          size: [
            [2, 9.6],
            [3, 11.2],
            [4, 12.8],
            [5, 14.4],
            [6, 16],
            [7, 19.2],
            [8, 22.4],
            [9, 25.6],
            [10, 28.8],
            [11, 32],
          ],
          fill: 'global.placeText',
          stroke: {
            color: 'global.placeTextOutline',
            width: 3,
          },
        },
      },
    },
  },
  locality_label_400k: {
    data: {
      source: 'omv',
      layer: 'places',
    },
    filter: "function() { return (feature.kind = 'locality' && feature.population && feature.population > 400000); }",
    draw: {
      text: {
        text_source: 'global.language_text_source',
        text_wrap: 30,
        priority: 41,
        font: {
          family: 'Montserrat',
          size: [
            [4, 11.2],
            [5, 12.8],
            [6, 16],
            [7, 19.2],
            [8, 22.4],
            [9, 24],
            [10, 25.6],
            [11, 27.2],
          ],
          fill: 'global.placeText',
          stroke: {
            color: 'global.placeTextOutline',
            width: 3,
          },
        },
      },
    },
  },
  locality_label_100k: {
    data: {
      source: 'omv',
      layer: 'places',
    },
    filter: {
      all: [
        "function() { return (feature.kind = 'locality' && feature.population > 100000); }",
        {
          $zoom: {
            min: 5,
          },
        },
      ],
    },
    draw: {
      text: {
        text_source: 'global.language_text_source',
        text_wrap: 30,
        priority: 42,
        font: {
          family: 'Montserrat',
          weight: 400,
          size: [
            [4, 9.6],
            [5, 11.2],
            [6, 12.8],
            [7, 16],
            [8, 19.2],
            [9, 20.8],
            [10, 22.4],
            [11, 24],
            [12, 25.6],
          ],
          fill: 'global.placeText',
          stroke: {
            color: 'global.placeTextOutline',
            width: 3,
          },
        },
      },
    },
  },
  locality_label_50k: {
    data: {
      source: 'omv',
      layer: 'places',
    },
    filter: {
      all: [
        "function() { return (feature.kind = 'locality' && feature.population > 50000); }",
        {
          $zoom: {
            min: 5,
          },
        },
      ],
    },
    draw: {
      text: {
        text_source: 'global.language_text_source',
        text_wrap: 30,
        priority: 43,
        font: {
          family: 'Montserrat',
          size: [
            [10, 16],
            [11, 19.2],
            [12, 22.4],
            [13, 24],
          ],
          fill: 'global.placeText',
          stroke: {
            color: 'global.placeTextOutline',
            width: 3,
          },
        },
      },
    },
  },
  locality_label_10k: {
    data: {
      source: 'omv',
      layer: 'places',
    },
    filter: {
      all: [
        "function() { return (feature.kind = 'locality' && feature.population > 10000); }",
        {
          $zoom: {
            min: 9,
          },
        },
      ],
    },
    draw: {
      text: {
        text_source: 'global.language_text_source',
        text_wrap: 30,
        priority: 44,
        font: {
          family: 'Montserrat',
          size: [
            [11, 16],
            [12, 17.6],
            [13, 19.2],
            [14, 20.8],
            [15, 22.4],
          ],
          fill: 'global.placeText',
          stroke: {
            color: 'global.placeTextOutline',
            width: 3,
          },
        },
      },
    },
  },
  locality_label: {
    data: {
      source: 'omv',
      layer: 'places',
    },
    filter: {
      all: [
        "function() { return (feature.kind = 'locality' && feature.population <= 10000); }",
        {
          $zoom: {
            min: 11,
          },
        },
      ],
    },
    draw: {
      text: {
        text_source: 'global.language_text_source',
        priority: 50,
        font: {
          family: 'Montserrat',
          size: [
            [11, 12.8],
            [12, 14.4],
            [13, 16],
            [14, 17.6],
            [15, 19.2],
          ],
          fill: 'global.placeText',
          stroke: {
            color: 'global.placeTextOutline',
            width: 3,
          },
        },
      },
    },
  },
  region_label: {
    data: {
      source: 'omv',
      layer: 'places',
    },
    filter: {
      all: [
        "function() { return (feature.kind = 'region'); }",
        {
          $zoom: {
            min: 10,
          },
        },
      ],
    },
    draw: {
      text: {
        text_source: 'global.language_text_source',
        priority: 47,
        font: {
          family: 'Montserrat',
          fill: 'global.suburbText',
          text_wrap: 10,
          size: [
            [11, 12.8],
            [12, 14.4],
            [13, 16],
            [14, 17.6],
            [15, 19.2],
          ],
          stroke: {
            color: 'global.placeTextOutline',
            width: 3,
          },
        },
      },
    },
  },
  state_label: {
    data: {
      source: 'omv',
      layer: 'places',
    },
    filter: {
      all: [
        {
          kind: 'region',
        },
        {
          kind_detail: 'state',
        },
      ],
    },
    draw: {
      text: {
        font: {
          family: 'Montserrat',
          fill: 'global.placeText',
          priority: 50,
          size: [
            [11, 10.8],
            [12, 12.4],
            [13, 14],
            [14, 15.6],
            [15, 17.2],
          ],
          stroke: {
            color: 'global.placeTextOutline',
            width: '4px',
          },
          transform: 'uppercase',
          text_wrap: 10,
        },
        text_source: 'global.language_text_source',
      },
    },
  },
  buildings: {
    data: {
      source: 'omv',
      layer: 'buildings',
    },
    filter: {
      all: [
        {
          $zoom: {
            min: 15,
          },
        },
      ],
    },
    draw: {
      polygons: {
        order: '0',
        color: 'global.building',
      },
    },
  },
};
