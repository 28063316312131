import { FC } from 'react';

export interface FormatNumberProps {
  format: string;
  type: string;
  value: number;
}

export const FormatNumber: FC<FormatNumberProps> = ({ value, format, type }) => {
  if (value === null || value === undefined) {
    return <></>;
  }
  if (type === 'integer') {
    return <>{Math.round(value)}</>;
  }
  let val;
  switch (format) {
    case 'percentage':
      val = (value * 100).toFixed(2) + '%';
      break;
    case 'percentage-100':
      val = value.toString() + '%';
      break;
    case 'currency':
      // TODO: #5104 - use Format.JS for currency formatting https://formatjs.io/docs/react-intl/components#formattednumber
      val = '$' + value.toString();
      break;
    default:
      val = value;
  }
  return <>{val}</>;
};

export default FormatNumber;
