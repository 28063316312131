import { Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref } from 'react-router-dom';
import { Breadcrumbs, MiddleSpinner, PortalFeatureIcons } from '../../components';
import { T_MANY } from '../../constants';
import { useCustomRoutes, useDynamicModule } from '../../hooks';
import { FeatureIcons } from '../../types';
import { CreatePage } from '../create';
import { CreateSubscriptionController } from './create-subscription-controller';

interface CreateSubscriptionPageProps {
  isProfilePage?: boolean;
}

export const CreateSubscriptionPage: FC<CreateSubscriptionPageProps> = ({ isProfilePage = false }) => {
  const { subscription: subscriptionRoute, subscriptions: subscriptionsRoute } = useCustomRoutes();
  const { t } = useTranslation(['admin', 'common']);
  const href = useHref(isProfilePage ? `/profile/${subscriptionRoute}` : `/${subscriptionsRoute}`);
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);

  const renderPageContent = (): JSX.Element => {
    return <>
      <Stack direction="column" flex={[2, 2]}>
        <CreateSubscriptionController isProfilePage={isProfilePage} />
      </Stack>
      <Stack direction="column" flex={[3, 3]} />
    </>;
  };

  const breadcrumbs = (
    <Breadcrumbs>
      <Link
        color="inherit"
        href={href}
        underline="hover"
      >
        {t('common:terms.subscription', { count: T_MANY })}
      </Link>
      <Typography color="text.primary" data-testid="subscription-detail-subtitle-breadcrumb">{t('admin:page.create-subscription.sub-title')}</Typography>
    </Breadcrumbs>
  );

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <CreatePage
      actions={[]}
      breadcrumbs={breadcrumbs}
      data-testid='subscription-create'
      pageIcon={module?.AlertIcon && <module.AlertIcon />}
      pageTitle={t('admin:page.create-subscription.title')}
      renderPageContent={renderPageContent}
    />
  );
};
