export const TRACK_THING_LIST_ROW_DEFAULT_TEMPLATE = {
  op: 'ui',
  name: 'ListRow',
  sx: {
    justifyContent: 'space-between',
    '.ListPaperColumn-root.property:nth-of-type(n + 4)': {
      display: 'none',
    },
    '> .ListPaperColumn-root:first-child:nth-last-child(3), .ListPaperColumn-root:first-child:nth-last-child(3) ~ .ListPaperColumn-root': {
      '&.ListPaperColumn-root.property': {
        width: '50%',
      },
    },
  },
  children: [
    {
      op: 'ui',
      name: 'DisplayColumn',
      data: {
        op: 'context',
        path: 'thing',
      },
    },
    {
      op: 'array:map',
      items: [
        { path: 'sharedProperties', index: 0 },
        { path: 'sharedProperties', index: 1 },
        { path: 'properties', index: 0 },
        { path: 'properties', index: 1 },
      ],
      to: {
        op: 'ui',
        name: 'ThingPropertyColumn',
        thing: {
          op: 'context',
          path: 'context.thing',
        },
        shared: {
          op: 'if',
          condition: {
            op: 'eq',
            a: {
              op: 'context',
              path: 'item.path',
            },
            b: 'sharedProperties',
          },
          then: true,
          else: false,
        },
        property: {
          op: 'context',
          path: [
            'context',
            'thingType',
            {
              op: 'context',
              path: 'item.path',
            },
            'order',
            {
              op: 'context',
              path: 'item.index',
            }],
        },
      },
    },
    {
      op: 'ui',
      name: 'GroupColumn',
      data: {
        op: 'context',
        path: 'thing',
      },
    },
  ],
};

export const ADMIN_THING_LIST_ROW_DEFAULT_TEMPLATE = {
  op: 'ui',
  name: 'ListRow',
  sx: {
    '.ListPaperColumn-root.property:nth-of-type(n + 5)': {
      display: 'none',
    },
  },
  children: [
    {
      op: 'ui',
      name: 'DisplayColumn',
      data: {
        op: 'context',
        path: 'thing',
      },
    },
    {
      op: 'array:map',
      items: [
        { path: 'sharedProperties', index: 0 },
        { path: 'sharedProperties', index: 1 },
        { path: 'sharedProperties', index: 2 },
        { path: 'properties', index: 0 },
        { path: 'properties', index: 1 },
        { path: 'properties', index: 2 },
      ],
      to: {
        op: 'ui',
        name: 'ThingPropertyColumn',
        thing: {
          op: 'context',
          path: 'context.thing',
        },
        shared: {
          op: 'if',
          condition: {
            op: 'eq',
            a: {
              op: 'context',
              path: 'item.path',
            },
            b: 'sharedProperties',
          },
          then: true,
          else: false,
        },
        property: {
          op: 'context',
          path: [
            'context',
            'thingType',
            {
              op: 'context',
              path: 'item.path',
            },
            'order',
            {
              op: 'context',
              path: 'item.index',
            }],
        },
      },
    },
  ],
};
