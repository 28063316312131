import { useMemo, useState } from 'react';
import { usePromise } from '../../hooks/use-promise';
import { Undefinable } from '../../types/common';
import { CommonEntityWithDeleted } from '../../types/entity';
import { EntityField, SelectedFilterType } from '../entity-search/types';
import { GroupsEditor } from '../groups-editor/groups-editor';

interface Props<T> {
  appliedFilters: EntityField[];
  'data-testid'?: string;
  findEntity: (search: string) => Promise<T[]>;
  label?: string;
  onChanged: (value: SelectedFilterType) => void;
  selectedValue?: SelectedFilterType;
}

/**
  * NATIVE FUNCTION: Filter lookup component - multiple tags input
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function FilterInputLookupMultiple<T extends CommonEntityWithDeleted>({
  appliedFilters,
  findEntity,
  label,
  onChanged,
  selectedValue,
  ...props
}: Props<T>): JSX.Element {
  const [inputValue, setInputValue] = useState('');
  const [items] = usePromise<T[]>(
    () => findEntity(inputValue),
    [inputValue, findEntity],
  );

  const defaultValues = useMemo((): string[] => {
    return appliedFilters.map(({ display }) => display);
  }, [appliedFilters]);

  const handleChange = async (_: string, updatedChips: Undefinable<string[]>): Promise<void> => {
    if (!updatedChips || !items) return;
    const selectedValueIds = (selectedValue as EntityField[] ?? []).map(({ id }) => id);
    const filter = items
      .map(({ _id, display }) => {
        return {
          id: _id,
          display,
        };
      })
      .filter(({ display }) => updatedChips.find((chip) => display === chip))
      .filter(({ id }) => !selectedValueIds.includes(id));

    onChanged([...appliedFilters, ...(selectedValue as EntityField[] ?? []), ...filter]);
    await Promise.resolve();
  };

  const handleDelete = async (_: string, updatedChips: Undefinable<string[]>): Promise<void> => {
    if (!updatedChips || !items) return;
    const updatedAppliedFilters = appliedFilters.filter(({ display }) => updatedChips.includes(display));
    const updatedSelectedValue = (selectedValue as EntityField[]).filter(({ display }) => updatedChips.includes(display));

    onChanged([...updatedAppliedFilters, ...(updatedSelectedValue ?? [])]);
    await Promise.resolve();
  };

  return (
    <GroupsEditor
      data-testid={props['data-testid']}
      defaultValues={defaultValues}
      handleInputChange={setInputValue}
      label={label}
      onChange={async () => {
        await Promise.resolve();
      }}
      optionOnClick={handleChange}
      optionOnDelete={handleDelete}
      options={items?.map(({ display }) => display) ?? []}
      size="small"
    />
  );
}
