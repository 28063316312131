import { Box, Card, CardContent, Typography } from '@mui/material';
import { FC } from 'react';
import { ErrorMessage, ErrorTitle } from '../../components';

interface Props {
  error: Error;
}

export const ErrorPage: FC<Props> = ({ error }) => {
  return (
    <Box sx={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflow: 'auto',
    }}>
      <Box sx={{
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 360,
        minHeight: '100%',
        mx: 'auto',
        p: 2,
      }}>
        <Card elevation={6}>
          <CardContent sx={{ m: 2 }}>
            <Typography gutterBottom variant="h5">
              <ErrorTitle error={error} />
            </Typography>
            <Typography color="text.secondary">
              <ErrorMessage error={error} />
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
