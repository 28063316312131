import { Accordion as MUIAccordion, AccordionProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';

interface Props extends AccordionProps {
  children: NonNullable<React.ReactNode>;
  'data-testid'?: string;
  disableGutters?: boolean;
  elevation?: number;
  expanded?: boolean;
  key?: number;
  sx?: SxProps<Theme>;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
}

export const Accordion: FC<Props> = ({ children, ...props }) => {
  return <MUIAccordion {...props}>{children}</MUIAccordion>;
};
