import IconButton from '@mui/material/IconButton';
import { FC, useState } from 'react';
import { DeleteIcon } from '../icons';
import { ColorTypes, SizeTypes } from './types';

interface Props {
  color?: ColorTypes;
  'data-testid'?: string;
  disabled?: boolean;
  onClick: () => Promise<void>;
  size?: SizeTypes;
}

export const DeleteButton: FC<Props> = ({ onClick, disabled = false, color, size, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const clickHandler = (): void => {
    setIsLoading(true);
    onClick().catch().finally(() => setIsLoading(false));
  };

  return (
    <IconButton
      aria-label="delete"
      color={color}
      data-testid={props['data-testid']}
      onClick={clickHandler}
      disabled={disabled || isLoading}
      size={size}
    >
      <DeleteIcon fontSize="inherit" />
    </IconButton>
  );
};
