import { MediaSegmentData } from '@eagle/core-data-types';
import { PlayCircle } from '@mui/icons-material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { Box, Stack, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useSmallScreen } from '../../hooks';
import { testid } from '../../util';
import { ButtonBase } from '../button';
import { MediaItemLabel } from './media-item-label';

interface Props {
  'data-testid'?: string;
  currentVideoIndex?: number;
  handleVideoClick: (currentIndex: number, feature: string) => void;
  timeZone?: string;
  video: MediaSegmentData;
}

export const VideoItem: FC<Props> = ({ currentVideoIndex = 0, handleVideoClick, timeZone, video, ...props }) => {
  const smallScreen = useSmallScreen();
  const theme = useTheme();
  const [hovered, setHovered] = useState(false);

  return (
    <Box data-testid={props['data-testid']} sx={{ borderRadius: 1, flexGrow: 0, scrollSnapAlign: 'start', scrollSnapStop: 'normal' }}>
      {video
        && <>
          <ButtonBase
            aria-label={video.start && `${video.start.toLocaleTimeString()} ${video.start.toLocaleDateString()}`}
            data-testid={testid`action-video-${video.url}-${currentVideoIndex}`}
            focusRipple
            onClick={() => handleVideoClick(currentVideoIndex, video.feature)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            sx={{ width: smallScreen ? '100%' : '200px' }}
          >
            <Box
              height={smallScreen ? '200px' : '100px'}
              sx={{
                alignItems: 'center',
                backgroundColor: theme.palette.grey[300],
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                ':hover': { opacity: 0.75 },
              }}>
              <PlayCircle sx={{ color: 'white' }} />
              {hovered && <PlayCircleFilledWhiteIcon sx={{ position: 'absolute' }} />}
            </Box>
          </ButtonBase>
          {video.start
            && <Stack mb={2} mt={1} spacing={0}>
              <MediaItemLabel start={video.start} timeZone={timeZone} />
            </Stack>
          }
        </>
      }
    </Box>
  );
};
