import cx from 'classnames';
import { FC, useMemo } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  field: {
    backgroundColor: '#e0e0e0',
    borderRadius: '1rem',
    display: 'inline-block',
    height: '0.6rem',
    minWidth: '5rem',
    verticalAlign: 'middle',
  },
});

interface Props {
  className?: string;
}

export const FieldLoadError: FC<Props> = ({ className }) => {
  const classes = useStyles();
  const width = useMemo(() => Math.random() * 3 + 4, []);
  return <span className={cx(classes.field, className)} style={{ minWidth: `${width}rem` }} />;
};
