import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextEditor } from '../text-editor';

interface Props {
  'data-testid'?: string;
  acceptEmptyValue?: boolean;
  disabled?: boolean;
  inputError?: boolean;
  onChanged: (value: string) => void;
  placeholderText?: string;
  useOnKeyUp?: boolean;
  value?: string;
}

export const FilterInputString: FC<Props> = ({
  acceptEmptyValue,
  disabled,
  inputError,
  onChanged,
  placeholderText,
  useOnKeyUp,
  value,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const [stateValue, setStateValue] = useState('');

  useEffect(() => {
    setStateValue(value || '');
  }, [value]);

  const handleChangeBase = (val: string): void => {
    setStateValue(val);
    if (!acceptEmptyValue && !val.length) return;
    onChanged(val);
  };

  const handleChange = (val: string): Promise<void> => {
    handleChangeBase(val);
    return Promise.resolve();
  };

  return (
    <TextEditor
      disabled={disabled}
      error={inputError}
      helperText={inputError ? t('common:component.filter.hint.helper-value') : ''}
      inputProps={{ 'aria-label': t('common:component.filter.hint.filter-value') }}
      label={placeholderText}
      onChange={handleChange}
      onKeyUp={useOnKeyUp ? handleChangeBase : undefined}
      placeholder={placeholderText}
      required
      size="small"
      value={stateValue}
      {...props}
    />
  );
};
