import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import ParticleAnimation from './particle-animation';

interface Props {
  bottomFooterText?: JSX.Element;
  footerLogo: JSX.Element;
  header: JSX.Element;
  logo: JSX.Element;
  signInButton: JSX.Element;
  subHeader: JSX.Element;
  termsAndConditionsText: JSX.Element;
  topFooterText: JSX.Element;
}

const LayoutMobile: FC<Props> = ({
  bottomFooterText,
  footerLogo,
  header,
  logo,
  signInButton,
  subHeader,
  termsAndConditionsText,
  topFooterText,
}) => {
  return (
    <Stack
      id="landing-page"
      sx={{
        alignItems: 'center',
        backgroundPosition: 'left',
        backgroundSize: 'cover',
        justifyContent: 'space-between',
        overflow: 'auto',
        py: '4vh',
        height: '100%',
      }}
    >
      <Stack id="header" sx={{ alignItems: 'center', maxWidth: '80%', textAlign: 'center', zIndex: 1 }}>
        {header}
        {subHeader}
      </Stack>

      <Stack spacing={4} sx={{ alignItems: 'center', width: '80%', minHeight: '60vh', textAlign: 'center', zIndex: 1, justifyContent: 'center' }}>
        <Box id="logo">{logo}</Box>
        <Box id="sign-in" sx={{ width: '100%' }}>
          {signInButton}
        </Box>
        <Box id="terms-and-conditions" sx={{ width: '100%' }}>
          {termsAndConditionsText}
        </Box>
      </Stack>

      <Stack id="footer" spacing={1} sx={{ alignItems: 'center', maxWidth: '90%', textAlign: 'center', zIndex: 1 }}>
        {footerLogo}
        {topFooterText}
        {bottomFooterText}
      </Stack>
      <ParticleAnimation />
    </Stack >
  );
};

export default LayoutMobile;
