/* eslint-disable react-hooks/exhaustive-deps */
import { Time } from '@eagle/common';
import { FC, useCallback, useEffect, useState } from 'react';
import { Pagination } from '../../components/entity-search/types';
import { Result } from '../../pages/list/use-search';

interface Props {
  children: JSX.Element;
  filterOpen: boolean;
  pagination: Pagination;
  resultData?: Result;
  setFilterOpen: (value: boolean) => void;
  setPagination: (value: Pagination) => void;
}

export const KeyHandlerWrapper: FC<Props> = ({
  children,
  filterOpen,
  pagination,
  resultData,
  setFilterOpen,
  setPagination,
}): JSX.Element => {
  const [keysPressed, setKeysPressed] = useState<Array<string>>([]);

  const keyUpHandler = useCallback((event: KeyboardEvent): void => {
    if (!resultData) return;
    if (!event.code.includes('Digit') || !event.shiftKey || !event.altKey) return;
    const numberKey = event.code.replace('Digit', '');
    setKeysPressed([...keysPressed, numberKey]);
    const combinedKeys = [...keysPressed, numberKey];

    if (combinedKeys.length <= 1) {
      if (resultData.matchCount <= pagination.limit * (Number(combinedKeys) - 1)) return;
      setPagination({ ...pagination, skip: pagination.limit * (Number(combinedKeys) - 1) });
    }

    if (resultData.matchCount < pagination.limit * (Number(combinedKeys.join('')) - 1)) return;
    setPagination({ ...pagination, skip: pagination.limit * (Number(combinedKeys.join('')) - 1) });

    setTimeout(() => setKeysPressed([]), Time.seconds(1));
  }, [keysPressed, resultData]);

  const keyDownHandler = useCallback((event: KeyboardEvent): void => {
    if (!event.shiftKey || !event.altKey) return;
    switch (event.key) {
      case 'F':
        setFilterOpen(!filterOpen);
        break;
      case 'ArrowRight':
        if (resultData && resultData.matchCount < pagination.skip + pagination.limit) return;
        setPagination({ ...pagination, skip: pagination.skip + pagination.limit });
        break;
      case 'ArrowLeft':
        if (pagination.skip <= 0) return;
        if (pagination.skip < pagination.limit) {
          setPagination({ ...pagination, skip: 0 });
          return;
        }
        setPagination({ ...pagination, skip: pagination.skip - pagination.limit });
        break;
    }
  }, [filterOpen, pagination, resultData]);

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    window.addEventListener('keyup', keyUpHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
      window.removeEventListener('keyup', keyUpHandler);
    };
  }, [keyDownHandler, keyUpHandler, resultData]);

  return <>
    {children}
  </>;
};
