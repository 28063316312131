import { Stack } from '@mui/material';
import { FC } from 'react';
import { AppliedFilter, FilterField } from '../entity-search/types';
import { FilterController } from './controller';
import { FilterToolbar } from './filter-toolbar';

interface Props {
  fields: FilterField[];
  filters: AppliedFilter[];
  onCloseClicked?: () => unknown;
  onFiltersChanged?: (filters: AppliedFilter[]) => unknown;
  savedFilterKey?: string;
  showDeletedLabel?: string;
  showDeletedKey?: string;
  showToolbar?: boolean;
  storageKey: string;
}

export const FilterDrawerContent: FC<Props> = ({
  fields,
  filters,
  onCloseClicked,
  onFiltersChanged,
  showToolbar = true,
  savedFilterKey,
  showDeletedLabel,
  showDeletedKey,
  storageKey,
}) => {
  return (
    <Stack id="filter-drawer" spacing={2}>
      {showToolbar
        && <FilterToolbar onCloseClicked={onCloseClicked} />
      }
      <Stack spacing={2} sx={{ px: 3 }}>
        <FilterController
          fields={fields}
          filters={filters}
          onCloseClicked={onCloseClicked}
          onFiltersChanged={onFiltersChanged}
          savedFilterKey={savedFilterKey}
          showDeletedLabel={showDeletedLabel}
          showDeletedKey={showDeletedKey}
          storageKey={storageKey}
        />
      </Stack>
    </Stack>
  );
};
