/* eslint-disable react-hooks/rules-of-hooks */
import { AuthenticationState, AuthStatus, useAuth } from '../auth';

interface AuthorizationHook {
  hasAuthorization: (roles: readonly string[]) => boolean;
}

export const hasAuthorizationNoHook = (roles: readonly string[], state?: AuthenticationState): boolean => {
  if (!state || state.status !== AuthStatus.AUTHENTICATED) return false;
  return roles.some((role) => state.userInfo.hasRoleFunction(role));
};

export const useHasAuthorization = (state?: AuthenticationState): AuthorizationHook => {
  state = state ?? useAuth().state;

  const hasAuthorization = (roles: readonly string[]): boolean => hasAuthorizationNoHook(roles, state);

  return {
    hasAuthorization,
  };
};
