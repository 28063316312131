import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Toolbar, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onCloseClicked?: () => unknown;
}

export const FilterToolbar: FC<Props> = ({ onCloseClicked }) => {
  const { t } = useTranslation(['common']);

  return (
    <Toolbar>
      <IconButton
        aria-label="close"
        color="inherit"
        edge="start"
        onClick={onCloseClicked}
        size="large"
        sx={{ mr: 2 }}
        data-testid="back-filter-bar-button"
      >
        <ArrowBackIcon />
      </IconButton>
      <Typography component="div" sx={{ flexGrow: 1 }} variant="h6">
        {t('common:component.filter.heading')}
      </Typography>
    </Toolbar>
  );
};
