import { FC, PropsWithChildren } from 'react';
import { IntercomParams } from '../../types/intercom';

declare const Intercom: (command: string, params?: IntercomParams | string) => void;

interface Props extends PropsWithChildren {
  type: 'string';
  id: string;
}

export const GuideLauncher: FC<Props> = ({ children, type, id }) => {
  const handleClick = (): void => {
    if (type && id) {
      Intercom(type, id);
    }
  };
  return <span role="button" onClick={handleClick}>{children}</span>;
};
