import { FC } from 'react';
import { TileLayer } from 'react-leaflet';
import { HERE_MAP_API_KEY } from '../../../constants';
import { useConfig } from '../../../hooks/use-config';

interface TransportLayerProps {
  format?: 'png' | 'png8';
  size?: 256 | 512;
}

export const TransportLayer: FC<TransportLayerProps> = ({
  size = 256,
  format = 'png',
}) => {
  const config = useConfig();
  const apiKey = config?.hereMaps?.apiKey ?? HERE_MAP_API_KEY;

  return <TileLayer
    pane='overlayPane'
    url={`https://1.base.maps.ls.hereapi.com/maptile/2.1/truckonlytile/newest/normal.day/{z}/{x}/{y}/${size}/${format}?apiKey=${apiKey}`}
    zIndex={3}
  />;
};
