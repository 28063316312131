import { CameraEventTypes, InertiaEventTypes, TrackingEventTypes } from '@eagle/data-function-types';

const { DANGEROUS_CORNERING, RAPID_ACCELERATION, RAPID_DECELERATION } = InertiaEventTypes;
const THRESHOLD_1MIN_KEYS: string[] = Object.values({ DANGEROUS_CORNERING, RAPID_ACCELERATION, RAPID_DECELERATION, ...CameraEventTypes });

export const getThreshold = (eventTypeId: string): { start: number; end: number } => {
  if (eventTypeId === TrackingEventTypes.POWER_ON) return { start: 0, end: 5 };
  if (eventTypeId === TrackingEventTypes.POWER_OFF) return { start: 5, end: 0 };
  if (THRESHOLD_1MIN_KEYS.includes(eventTypeId)) return { start: 1, end: 1 };
  return { start: 5, end: 5 };
};
