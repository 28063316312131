import { Card, CardContent, Stack, Table, TableContainer } from '@mui/material';
import { FC } from 'react';

interface Props {
  content: JSX.Element;
  'data-testid'?: string;
  paginationComponent?: JSX.Element;
  stickyHeader?: boolean;
}

export const SearchResultsTableLayoutDesktop: FC<Props> = ({
  content,
  paginationComponent,
  stickyHeader,
  ...props
}) => (
  <Card data-testid={props['data-testid']}>
    <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
      <TableContainer sx={{ display: 'flex', flexDirection: 'column' }}>
        <Table
          aria-label="sticky table"
          component="div"
          size="small"
          stickyHeader={stickyHeader}
        >
          {content}
        </Table>
        {paginationComponent
          ? <Stack sx={{ alignItems: 'center', marginY: 1 }}>
            {paginationComponent}
          </Stack>
          : null
        }
      </TableContainer>
    </CardContent>
  </Card>
);
