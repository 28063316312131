/* eslint-disable react-hooks/exhaustive-deps */
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { CalendarPicker, TimePicker } from '@mui/lab';
import { Box, Button, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormattedTime, isMatchingDay, mergeDateAndTime } from '../../util';
import { testid } from '../../util/test-id';
import { DateFilterField, DesktopLayoutProps, DynamicTimePicker, TABS, TimeInputType } from './date-time-range.types';

export const DateTimeRangeDesktop: FC<DesktopLayoutProps> = ({
  buttonHandler,
  classes,
  date,
  dateOptions,
  end,
  error,
  handleCalendar,
  handleEnd,
  handleError,
  handleStart,
  hourCycle,
  is12AM,
  layout,
  maxDate,
  maxTime,
  minDate,
  minTime,
  openMobileTime,
  popperPlacement,
  renderInput,
  saveDate,
  smallScreen,
  start,
  sx,
}) => {
  const { t } = useTranslation(['common']);
  const isHorizontal = layout === 'horizontal';

  const dynamicTimePicker = ({ openTo, dateTime, handler, displayText, inputType }: DynamicTimePicker): JSX.Element => (
    <Box>
      {smallScreen
        ? renderInput(
          {
            onClick: () => openMobileTime(openTo),
            onKeyDown: () => openMobileTime(openTo),
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => openMobileTime(openTo)} value={openTo}>
                    <AccessTimeIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
            sx: {
              '& .MuiOutlinedInput': {
                paddingRight: '1em',
              },
            },
            value: displayText,
          },
          inputType,
        )
        : <TimePicker
          ampm={hourCycle()}
          desktopModeMediaQuery='@media (min-width: 0px)'
          maxTime={(date && (!is12AM(maxTime) && (isMatchingDay(maxTime.minus({ days: 1 }), date) && inputType === TimeInputType.start)))
            ? maxTime.toJSDate()
            : undefined
          }
          minTime={date && (isMatchingDay(minTime, date) ? minTime?.toJSDate() : undefined)}
          onError={handleError}
          onChange={handler}
          PopperProps={{ placement: popperPlacement }}
          renderInput={(textProps) => renderInput(textProps, inputType)}
          value={!smallScreen && (dateTime?.toJSDate() ?? null)}
        />
      }
    </Box>
  );

  const DesktopView = (): JSX.Element => (
    <Stack direction={isHorizontal ? 'row' : 'column'}>
      <Grid
        alignItems="center"
        columnSpacing={{ xs: 0, sm: 2, md: 3 }}
        container
        justifyContent="center"
        spacing={0}
        sx={sx}
      >
        <Grid item xs={12}>
          <Box className={classes.headings}>
            {dateOptions.map((option: DateFilterField, i: number): JSX.Element => (
              <Button
                key={i}
                data-testid={testid`date-time-button-${option.display}`}
                onClick={() => { buttonHandler(i.toString()); }}
              >
                {option.display}
              </Button>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <CalendarPicker
            className={classes.calendarPicker}
            date={date?.toJSDate() ?? new Date()}
            minDate={minDate?.toJSDate()}
            maxDate={maxDate?.minus({ day: 1 }).toJSDate()}
            onChange={handleCalendar}
          />
        </Grid>

        {!isHorizontal
          && <Grid item xs={12}>
            <Box className={classes.timeSpacing}>
              {dynamicTimePicker({
                dateTime: start,
                displayText: getFormattedTime(start ? start : mergeDateAndTime(DateTime.now(), minTime)),
                handler: handleStart,
                inputType: TimeInputType.start,
                openTo: TABS.startDate,
              })}
              <Typography className={classes.betweenTime}>{t('common:component.date-time-range.hint.to')}</Typography>
              {dynamicTimePicker({
                dateTime: end,
                displayText: getFormattedTime(end ? mergeDateAndTime(DateTime.now(), end) : maxTime),
                handler: handleEnd,
                inputType: TimeInputType.end,
                openTo: TABS.endDate,
              })}
            </Box>
          </Grid>
        }

        <Grid item xs={12} className={classes.justifyLeft}>
          <Button disabled={error !== null} onClick={saveDate} data-testid="date-time-button-apply">
            {t('common:component.date-time-range.action.apply')}
          </Button>
        </Grid>
      </Grid>

      {isHorizontal
        && <Stack
          direction="column"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {dynamicTimePicker({
            dateTime: start,
            displayText: getFormattedTime(start ? start : mergeDateAndTime(DateTime.now(), minTime)),
            handler: handleStart,
            inputType: TimeInputType.start,
            openTo: TABS.startDate,
          })}
          <Typography className={classes.betweenTime}>{t('common:component.date-time-range.hint.to')}</Typography>
          {dynamicTimePicker({
            dateTime: end,
            displayText: getFormattedTime(end ? mergeDateAndTime(DateTime.now(), end) : maxTime),
            handler: handleEnd,
            inputType: TimeInputType.end,
            openTo: TABS.endDate,
          })}
        </Stack>
      }
    </Stack>
  );

  return useMemo(DesktopView, [
    classes,
    date,
    end,
    handleCalendar,
    handleEnd,
    handleError,
    handleStart,
    maxDate,
    maxTime,
    minDate,
    minTime,
    openMobileTime,
    renderInput,
    smallScreen,
    start,
  ]);
};
