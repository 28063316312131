import { Card, CardContent, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LocaleSelect, MiddleSpinner, PhoneInput, TextEditor, TimeZoneSelect } from '../../components';
import { NotificationRecipientRadioButtons } from './notification-recipient-radio-buttons';
import { RecipientAutocomplete } from './recipient-autocomplete';
import { ContactMethodRadioButtons, DefaultCustomRadioButtons, NotificationGroupRadioButtons, NotificationRecipientViewProps, RadioButtons, UserRadioButtons } from './subscription-detail.types';

export const NotificationRecipientCardView: FC<NotificationRecipientViewProps> = ({
  channelDetailHelper,
  contactDetail,
  contactOptions,
  defaultOptions,
  fieldDisabled,
  handleEmailFieldChange,
  handleSelectChange,
  isChannelDetailInvalid,
  isContactDetailFieldVisible,
  isContactOptionsVisible,
  isProfilePage,
  notificationGroupOptions,
  recipients,
  recipientsError,
  recipientsPending,
  recipientState,
  selectedLocale,
  selectedTimezone,
  setChannelDetailHelper,
  setContactDetail,
  setIsChannelDetailInvalid,
  setRecipientState,
  setSelectedLocale,
  setSelectedTimezone,
  userOptions,
}) => {
  const { t } = useTranslation(['admin', 'common']);

  const userOrOtherRadioButtons = (
    <NotificationRecipientRadioButtons
      data-testid="user-of-system-or-other"
      fieldDisabled={fieldDisabled || isProfilePage}
      heading={t('admin:page.create-subscription.notification-recipient.labels.update-user')}
      options={userOptions}
      selected={recipientState.user}
      setSelected={(user) => {
        setContactDetail(null);
        setRecipientState({
          ...recipientState,
          user: user as UserRadioButtons,
        });
      }}
    />
  );

  const smsOrEmailRadioButtons = (
    <NotificationRecipientRadioButtons
      data-testid="send-notification-via"
      fieldDisabled={fieldDisabled || contactOptions.length === 1}
      heading={t('admin:page.create-subscription.notification-recipient.labels.send-notification-via')}
      options={contactOptions}
      selected={recipientState.contactMethod}
      setSelected={(contactMethod) => {
        setChannelDetailHelper('');
        setIsChannelDetailInvalid(false);
        setContactDetail(null);
        setRecipientState({ ...recipientState, contactMethod: contactMethod as ContactMethodRadioButtons });
      }}
    />
  );

  const individualOrGroupRadioButtons = (
    <NotificationRecipientRadioButtons
      data-testid="send-notification-to"
      fieldDisabled={fieldDisabled || isProfilePage}
      heading={t('admin:page.create-subscription.notification-recipient.labels.send-notification')}
      options={notificationGroupOptions}
      selected={recipientState.notificationGroup}
      setSelected={(notificationGroup) => {
        setRecipientState({
          ...recipientState,
          notificationGroup: notificationGroup as NotificationGroupRadioButtons,
          selectedRecipient: null,
        });
      }}
    />
  );

  const defaultOrCustomRadioButtons = (
    <NotificationRecipientRadioButtons
      data-testid="default-or-custom-channel"
      fieldDisabled={fieldDisabled}
      options={defaultOptions}
      selected={recipientState.defaultOrCustomContact}
      setSelected={(defaultOption) => {
        if (defaultOption === RadioButtons.DEFAULT) {
          setContactDetail(null);
          setChannelDetailHelper('');
          setIsChannelDetailInvalid(false);
        }
        setRecipientState({ ...recipientState, defaultOrCustomContact: defaultOption as DefaultCustomRadioButtons });
      }}
    />
  );

  const renderEntitySelector = (): JSX.Element => {
    if (recipientsPending) return <MiddleSpinner />;
    if (recipientsError) return <Typography>{t('common:page.subscription-detail.error.hint')}</Typography>;
    const initialSelection = recipients.find(({ _id }) => _id === recipientState.selectedRecipient);

    return (
      <RecipientAutocomplete
        key={initialSelection?._id ?? recipientState.notificationGroup}
        disabled={fieldDisabled || isProfilePage}
        handleSelect={(value) => {
          if (recipientState.notificationGroup === RadioButtons.INDIVIDUAL) {
            setContactDetail(null);
            handleSelectChange('defaultOrCustomContact', RadioButtons.DEFAULT);
            setChannelDetailHelper('');
            setIsChannelDetailInvalid(false);
          }
          handleSelectChange('selectedRecipient', value._id);
        }}
        initialSelection={initialSelection}
        recipients={recipients}
      />
    );
  };

  const emailDetailField = (
    <TextEditor
      disabled={fieldDisabled || (recipientState.user !== RadioButtons.OTHER && recipientState.defaultOrCustomContact === RadioButtons.DEFAULT)}
      error={isChannelDetailInvalid}
      helperText={channelDetailHelper}
      label={t('admin:page.create-subscription.notification-recipient.labels.email-address')}
      onKeyUp={(value) => handleEmailFieldChange(value)}
      placeholder={t('admin:page.create-user.basic-info.email.hint')}
      required
      size="small"
      value={contactDetail ?? ''}
      data-testid='email-input'
    />
  );

  const phoneDetailField = (
    <PhoneInput
      disabled={fieldDisabled || (recipientState.user !== RadioButtons.OTHER && recipientState.defaultOrCustomContact === RadioButtons.DEFAULT)}
      disableFormatting
      label={t('admin:page.create-subscription.notification-recipient.labels.mobile-number')}
      onChange={(value) => setContactDetail(value)}
      size="small"
      mobileOnly={true}
      value={contactDetail ?? ''}
      data-testid='notification-sms-input'
    />
  );

  const timezoneAndLocale = <>
    <Typography variant="subtitle2">
      {t('admin:page.create-subscription.notification-recipient.labels.select-locale-time-zone')}
    </Typography>
    <TimeZoneSelect
      fieldDisabled={fieldDisabled}
      selectedTimeZone={selectedTimezone}
      setSelectedTimeZone={setSelectedTimezone}
      data-testid="timezone-select"
    />
    <LocaleSelect
      fieldDisabled={fieldDisabled}
      selectedLocale={selectedLocale}
      setSelectedLocale={setSelectedLocale}
      data-testid="locale-select"
    />
  </>;

  const header = (
    <Typography variant="h5">
      {t('admin:page.create-subscription.notification-recipient.labels.heading')}
    </Typography>
  );

  if (recipientState.user === RadioButtons.OTHER) {
    return (
      <Card>
        <CardContent>
          <Stack spacing={2}>
            {header}
            {userOrOtherRadioButtons}
            {smsOrEmailRadioButtons}
            {recipientState.contactMethod === RadioButtons.SMS ? phoneDetailField : emailDetailField}
            {timezoneAndLocale}
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          {header}
          {userOrOtherRadioButtons}
          {individualOrGroupRadioButtons}
          {renderEntitySelector()}
          {isContactOptionsVisible
            && <Stack>
              {smsOrEmailRadioButtons}
              {isContactDetailFieldVisible
                && <>
                  {defaultOrCustomRadioButtons}
                  {recipientState.contactMethod === RadioButtons.SMS ? phoneDetailField : emailDetailField}
                </>
              }
            </Stack>
          }
        </Stack>
      </CardContent>
    </Card>
  );
};
