import { Stack } from '@mui/material';
import { FC } from 'react';

interface Props {
  addEntityDialog: JSX.Element;
  content: JSX.Element;
  historyActions: JSX.Element;
}

export const HistoryDrawerLayoutMobile: FC<Props> = ({
  addEntityDialog,
  content,
  historyActions,
}): JSX.Element => {
  return <>
    {historyActions}
    <Stack spacing={2} sx={{ m: 2 }}>
      {content}
    </Stack>
    {addEntityDialog}
  </>;
};
