import { makeStyles } from '../../theme';

export const dataRowModalStyles = makeStyles()((theme) => ({
  imageBox: {
    position: 'absolute',
    backgroundColor: theme.palette.common.black,
    height: '360px',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '640px',
  },
  errorText: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    color: theme.palette.common.white,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: '#25252585',
    color: theme.palette.common.white,
    padding: '0.5rem',
    borderRadius: '0.25rem',
  },
}));
