import { Operator } from '@jexop/core';
import { isNumber } from 'lodash';
import { Nullable } from '../types';
import { kphToMph as ktm } from '../util';
import { translate } from './translate';

export const formatSpeed: Operator<number|string> = (props): Nullable<number|string>  => {
  if (!isNumber(props.value)) {
    return null;
  }
  return props.raw ? ktm(props.value) : translate({translation: 'common:component.events.labels.speed-value', options: { kph: props.value, mph: ktm(props.value)}});
};
