import { FeatureTypes } from '@eagle/data-function-types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Replace<S extends string, P extends '-' | '_', U extends string> = S extends `${infer Prefix}${P}${infer _}` ? `${Prefix}${U}` : S;

export type FeatureId = { [K in keyof typeof FeatureTypes as Replace<K, '_', ''>]: Replace<typeof FeatureTypes[K], '-', ''>; };

export const FeatureIds = Object.fromEntries(
  Object.entries(FeatureTypes).map(([key, value]) => [key.replace(/_.*/, ''), value.replace(/-.*/, '')]),
) as FeatureId;
