/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/react';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Nullable, Undefinable } from '../types';

export const getLocalStorageItem = <T>(key: string, defaultValue: T = {} as T): T => {
  const localStoreValue = localStorage.getItem(key);
  return localStoreValue ? JSON.parse(localStoreValue) as T : defaultValue;
};

export const addLocalStorageItem = (key: string, value: Record<string, unknown>): void => {
  localStorage.setItem(key, JSON.stringify({
    ...getLocalStorageItem(key),
    ...value,
  }));
};

export const setLocalStorageItem = (key: string, value: Record<string, unknown>): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

type SetValue<T> = Dispatch<SetStateAction<T>>

// Provides hook that persist the state with local storage
// https://usehooks-ts.com/react-hook/use-local-storage
export const useLocalStorage = <T>(key: string, initialValue: T): [T, SetValue<T>] => {
  const readValue = useCallback((): T => {
    const item = localStorage.getItem(key);
    try {
      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      captureException(error, { extra: { key, item } });
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue: SetValue<T> = useCallback(
    (value) => {
      try {
        const newValue = value instanceof Function ? value(storedValue) : value;
        localStorage.setItem(key, JSON.stringify(newValue));
        setStoredValue(newValue);
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
        captureException(error, { extra: { key, value } });
      }
    },
    [key, storedValue],
  );

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  return [storedValue, setValue];
};

const parseJSON = <T>(value: Nullable<string>): Undefinable<T> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return value ? JSON.parse(value ?? '') : undefined;
  } catch (error) {
    console.error('parsing error on', { value });
    captureException(error, { extra: { value } });
    return undefined;
  }
};
