import { Operator } from '@jexop/core';
import { isNumber } from 'lodash';
import { Nullable } from '../types';
import { kilometersToMiles } from '../util';
import { translate } from './translate';

export const formatDistance: Operator<number | string> = (props): Nullable<number | string> => {
  if (!isNumber(props.meters)) {
    return null;
  }

  const meters = props.meters;
  return translate({ translation: 'common:component.events.labels.distance-value', options: { kilometers: meters / 1000, miles: kilometersToMiles(meters / 1000) } });
};
