import { Operator } from '@jexop/core';
import { isRegExp, isString } from 'lodash';
import { Nullable } from '../types';

export const stringReplace: Operator<string> = ({find, replace, value}): Nullable<string>  => {
  if ((!isString(find) && !isRegExp(find)) || !isString(replace) || !isString(value)) {
    return null;
  }

  return value.replace(find, replace);
};
