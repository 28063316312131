import { Box, CircularProgress, CircularProgressProps, Fade, Paper } from '@mui/material';
import { SxProps } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { FC, PropsWithChildren } from 'react';
import { centered } from '../../style/sx';

interface Props extends PropsWithChildren {
  'data-testid'?: string;
  variant?: 'determinate' | 'indeterminate' | undefined;
  color?: CircularProgressProps['color'];
  size?: string | number;
  sx?: SxProps;
  value?: number;
}

const DEFAULT_FADE_TIMEOUT = 1000;

export const Spinner: FC<Props> = ({ color, size, sx, value, variant, ...props }) => {
  return <>
    <CircularProgress variant={variant} role="status" color={color} size={size} sx={sx} value={value} data-testid={props['data-testid']} />
    <span style={visuallyHidden}>Loading...</span>
  </>;
};

export const MiddleSpinner: FC<Props> = ({ color, children, size, sx, variant, value, ...props }) => {
  const sxProps = { ...centered, ...sx };
  return (
    <Box sx={sxProps} data-testid={props['data-testid']}>
      <Spinner variant={variant} color={color} size={size} value={value} />
      {children}
    </Box>
  );
};

export const MiddleSpinnerOverlay: FC<Props & { fade?: boolean; timeout?: number }> = ({ color, children, fade = false, size, sx, timeout = DEFAULT_FADE_TIMEOUT, variant, value }) => (
  <Fade in={fade} timeout={timeout}>
    <Paper
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 1,
      }}
    >
      <MiddleSpinner variant={variant} value={value} color={color} size={size} sx={{ flexDirection: 'column', ...sx }}>
        {children}
      </MiddleSpinner>
    </Paper>
  </Fade>
);
