import { FC } from 'react';
import { useDynamicModule } from '../../hooks';
import { FeatureIcons } from '../../types';
import { PortalWidgetIcons } from './general';

export interface WidgetIconProps {
  icon: string;
  [key: string]: string | number;
}

export const Icon: FC<WidgetIconProps> = ({ icon, ...props }) => {
  const { module: widgets, loaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalWidgetIcons);

  if (!loaded || !icon || !widgets || !widgets[icon]) {
    return <></>;
  }
  const IconContainer = widgets[icon];
  return <IconContainer {...props} />;
};
