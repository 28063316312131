import { Operator } from '@jexop/core';
import { isNumber, isString } from 'lodash';
import { Nullable } from '../types';

export const cardinalDirection: Operator<unknown> = ({ angle }): Nullable<string> => {
  const angleNumeric = isString(angle) ? Number(angle) : isNumber(angle) ? angle : null;

  if (!angleNumeric) {
    return null;
  }

  const directions: string[] = ['N', 'NNE', 'NE', 'ENE','E', 'ESE', 'SE', 'SSE','S', 'SSW', 'SW', 'WSW','W', 'WNW', 'NW', 'NNW'];

  const section: number = Math.floor(angleNumeric / 22.5 + 0.5);

  return directions[ section % 16 ];
};
