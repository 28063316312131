import { Component, createRef, FocusEventHandler, PropsWithChildren } from 'react';
import { Nullable } from '../types';

declare function clearTimeout(handle?: number): void;
declare function setTimeout(handler: TimerHandler, timeout?: number, ...arguments: any[]): number;

const TIMEOUT_BLUR = 200;

const noOutlineStyles = {
  outline: 'none',
};

interface Props extends PropsWithChildren {
  onBlur?: () => void;
  onFocus?: () => void;
}

interface State {
  focused: boolean;
}

export class FocusWithin extends Component<Props, State> {
  private blurTimeout: Nullable<number> = null;
  private ref = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      focused: false,
    };
  }

  private onFocus: FocusEventHandler<HTMLDivElement> = (): void => {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }
    const { focused } = this.state;

    if (!focused) {
      this.setState(
        {
          focused: true,
        },
        () => {
          if (this.props.onFocus) {
            this.props.onFocus();
          }
        },
      );
    }
  };

  private onBlur: FocusEventHandler<HTMLDivElement> = (): void => {
    this.blurTimeout = setTimeout(
      () => {
        const { focused } = this.state;
        if (focused) {
          this.setState(
            {
              focused: false,
            },
            () => {
              if (this.props.onBlur) {
                this.props.onBlur();
              }
            },
          );
        }
      },
      TIMEOUT_BLUR,
    );
  };

  public render(): JSX.Element {
    const { children } = this.props;

    const events = {
      onFocus: this.onFocus,
      onBlur: this.onBlur,
    };

    return (
      <div ref={this.ref} style={noOutlineStyles} {...events}>
        {children}
      </div>
    );
  }
}
