import {
  ChannelType,
  DefaultableEmailChannel,
  DefaultableSmsChannel,
  EmailChannel,
  ExternalRecipient,
  IndividualRecipient,
  Recipient,
  RecipientType,
  SmsChannel,
  UserGroupRecipient
} from '@eagle/core-data-types';
import { TFunction } from 'i18next';
import { Nullable } from '../../types';
import { ContactMethodRadioButtons, DefaultCustomRadioButtons, NotificationRecipientState, RadioButtons } from './subscription-detail.types';

export const confirmPrompt = (currentValue: string, tFunction: TFunction): string => {
  switch (currentValue) {
    case RadioButtons.PERSON:
      return tFunction('admin:page.subscription-detail.confirm.label.person');
    case RadioButtons.THING:
      return tFunction('admin:page.subscription-detail.confirm.label.thing');
    case RadioButtons.INDIVIDUAL:
      return tFunction('admin:page.subscription-detail.confirm.label.individual');
    case RadioButtons.GROUP:
      return tFunction('admin:page.subscription-detail.confirm.label.group');
    case RadioButtons.DEFAULT:
      return tFunction('admin:page.subscription-detail.confirm.label.default');
    case RadioButtons.EMAIL:
      return tFunction('admin:page.subscription-detail.confirm.label.email');
    case RadioButtons.SMS:
      return tFunction('admin:page.subscription-detail.confirm.label.sms');
    case RadioButtons.USER:
      return tFunction('admin:page.subscription-detail.confirm.label.user');
    case RadioButtons.OTHER:
      return tFunction('admin:page.subscription-detail.confirm.label.other');
    default:
      return tFunction('admin:page.subscription-detail.confirm.label.all');
  }
};

export const isIndividualRecipient = (recipient: Recipient): recipient is IndividualRecipient => {
  return [RecipientType.INTERNAL_INDIVIDUAL_USER, RecipientType.INTERNAL_INDIVIDUAL_PERSON].includes(recipient.type);
};

export const isIndividualUserRecipient = (recipient: Recipient): recipient is IndividualRecipient => {
  return recipient.type === RecipientType.INTERNAL_INDIVIDUAL_USER;
};

export const isGroupRecipient = (recipient: Recipient): recipient is UserGroupRecipient => {
  return [RecipientType.INTERNAL_USER_GROUP, RecipientType.INTERNAL_GROUP].includes(recipient.type);
};

export const isInternalRecipient = (recipient: Recipient): recipient is IndividualRecipient | UserGroupRecipient => {
  return isIndividualRecipient(recipient) && isGroupRecipient(recipient);
};

export const hasChannel = (recipient: Recipient): recipient is (IndividualRecipient | ExternalRecipient) => {
  return [
    RecipientType.INTERNAL_INDIVIDUAL_USER,
    RecipientType.INTERNAL_INDIVIDUAL_PERSON,
    RecipientType.EXTERNAL,
  ].includes(recipient.type);
};

export const channelTypeToRadioButton = (type: ChannelType.EMAIL | ChannelType.SMS): ContactMethodRadioButtons => {
  switch (type) {
    case ChannelType.EMAIL: return RadioButtons.EMAIL;
    case ChannelType.SMS: return RadioButtons.SMS;
  }
};

export const getContactMethod = (recipient: Recipient, defaultMethod: ContactMethodRadioButtons): ContactMethodRadioButtons => {
  switch (recipient.type) {
    case RecipientType.INTERNAL_USER_GROUP: {
      return recipient.channelType !== ChannelType.API
        ? channelTypeToRadioButton(recipient.channelType)
        : defaultMethod;
    }
    case RecipientType.INTERNAL_INDIVIDUAL_USER: {
      return recipient.channel.type !== ChannelType.API
        ? channelTypeToRadioButton(recipient.channel.type)
        : defaultMethod;
    }
    case RecipientType.EXTERNAL: {
      return recipient.channel.type !== ChannelType.API
        ? channelTypeToRadioButton(recipient.channel.type)
        : defaultMethod;
    }
    default: return defaultMethod;
  }
};

export const getDefaultOrCustom = (recipient: Recipient): DefaultCustomRadioButtons => {
  if (recipient.type !== RecipientType.INTERNAL_INDIVIDUAL_USER) return RadioButtons.DEFAULT;
  switch (recipient.channel.type) {
    case ChannelType.EMAIL: return recipient.channel.address === null ? RadioButtons.DEFAULT : RadioButtons.CUSTOM;
    case ChannelType.SMS: return recipient.channel.number === null ? RadioButtons.DEFAULT : RadioButtons.CUSTOM;
    default: return RadioButtons.DEFAULT;
  }
};

export const getChannelValue = (recipient: Recipient): Nullable<string> => {
  if (recipient.type !== RecipientType.INTERNAL_INDIVIDUAL_USER && recipient.type !== RecipientType.EXTERNAL) return null;
  switch (recipient.channel.type) {
    case ChannelType.EMAIL: return recipient.channel.address;
    case ChannelType.SMS: return recipient.channel.number;
    default: return null;
  }
};

export const getInitialContactDetail = (defaultOrCustom: DefaultCustomRadioButtons, recipient: Recipient): Nullable<string> => {
  if (recipient.type === RecipientType.INTERNAL_INDIVIDUAL_USER && defaultOrCustom === RadioButtons.DEFAULT) return null;
  return getChannelValue(recipient);
};

export const getChannelType = (selected: RadioButtons): Nullable<ChannelType.EMAIL | ChannelType.SMS> => {
  switch (selected) {
    case RadioButtons.EMAIL: return ChannelType.EMAIL;
    case RadioButtons.SMS: return ChannelType.SMS;
    default: return null;
  }
};

export const generateChannel = (
  channelType: ChannelType.EMAIL | ChannelType.SMS,
  contact: Nullable<string>,
): DefaultableEmailChannel | DefaultableSmsChannel | EmailChannel | SmsChannel => {
  switch (channelType) {
    case ChannelType.EMAIL: {
      return {
        address: contact,
        type: channelType,
      };
    }
    case ChannelType.SMS: {
      return {
        number: contact,
        type: channelType,
      };
    }
  }
};

export const generateRecipient = (
  contactDetail: Nullable<string>,
  selectedLocale: string,
  selectedTimeZone: string,
  state: NotificationRecipientState,
): Nullable<Recipient> => {
  const { contactMethod, defaultOrCustomContact, notificationGroup, selectedRecipient, user } = state;
  const channelType = getChannelType(contactMethod);

  if (!channelType) return null;

  if (user === RadioButtons.OTHER) {
    if (!contactDetail) return null;
    return {
      channel: generateChannel(channelType, contactDetail) as EmailChannel | SmsChannel,
      localization: {
        locale: selectedLocale,
        timezone: selectedTimeZone,
      },
      type: RecipientType.EXTERNAL,
    };
  }

  if (!selectedRecipient) return null;

  switch (notificationGroup) {
    case RadioButtons.GROUP: {
      return {
        channelType,
        localization: null,
        referenceId: selectedRecipient,
        type: RecipientType.INTERNAL_USER_GROUP,
      } as UserGroupRecipient;
    }
    case RadioButtons.INDIVIDUAL: {
      if (defaultOrCustomContact === RadioButtons.DEFAULT) {
        return {
          channel: generateChannel(channelType, contactDetail),
          localization: null,
          referenceId: selectedRecipient,
          type: RecipientType.INTERNAL_INDIVIDUAL_USER,
        } as IndividualRecipient;
      }
      return {
        channel: generateChannel(channelType, contactDetail),
        localization: null,
        referenceId: selectedRecipient,
        type: RecipientType.INTERNAL_INDIVIDUAL_USER,
      } as IndividualRecipient;
    }
  }
};
