import { Box, MenuItem } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { ConfirmDialogConfirmation } from './confirm-dialog-confirmation';

interface Props extends PropsWithChildren {
  confirmPrompt: JSX.Element;
  onConfirm: () => Promise<unknown>;
  onFailure: () => void;
  onSuccess: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  stackDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}

export const ConfirmDialogMenu: FC<Props> = ({
  children,
  confirmPrompt,
  onConfirm,
  onFailure,
  onSuccess,
  open,
  setOpen,
  stackDirection = 'row',
}): JSX.Element => {
  if (!open) {
    return (
      <MenuItem onClick={(e) => {
        e.stopPropagation();
        setOpen(true);
      }}>
        {children}
      </MenuItem>
    );
  }

  return (
    <Box sx={{ px: 2 }}>
      <ConfirmDialogConfirmation
        confirmPrompt={confirmPrompt}
        onConfirm={onConfirm}
        onFailure={onFailure}
        onSuccess={onSuccess}
        setOpen={setOpen}
        stackDirection={stackDirection}
      />
    </Box>
  );
};

export default ConfirmDialogMenu;
