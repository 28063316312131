import { Button } from '@mui/material';
import { FC } from 'react';
import { useHref } from 'react-router-dom';
import { Display } from '../../types';
import { testid } from '../../util/test-id';
import { LinkData } from './static-data.types';

interface LinkButtonProps {
  link?: LinkData;
}

interface MediaViewButtonProps extends Display {
  onClick: () => void;
}

export const LinkButton: FC<LinkButtonProps> = ({ link }) => {
  const href = useHref(link?.url ?? '');

  if (!link) return <></>;

  return (
    <Button
      data-testid={testid`action-button-${link.display}`}
      href={href}
      variant="text"
    >
      {link.display}
    </Button>
  );
};

export const MediaViewButton: FC<MediaViewButtonProps> = ({ display, onClick }) => {
  return (
    <Button
      data-testid={testid`action-button-${display}`}
      onClick={onClick}
      variant="text"
    >
      {display}
    </Button>
  );
};
