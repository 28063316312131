import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearch } from '../../pages/list/use-search';
import { FILTER_SHOW_DELETED_FLAG } from '../../util';
import { addLocalStorageItem } from '../../util/local-storage';
import { AppliedFilter, FilterField } from '../entity-search/types';
import { useBoolFlag } from '../flags';
import { handleSetShowDeleted } from '../new-filter';
import { FilterBuilder, FILTERS_KEY } from './filter-builder';
import { SavedFilterController } from './saved-filter';

interface Props {
  fields: FilterField[];
  filters: AppliedFilter[];
  onCloseClicked?: () => unknown;
  onFiltersChanged?: (filters: AppliedFilter[]) => unknown;
  savedFilterKey?: string;
  showDeletedLabel?: string;
  showDeletedKey?: string;
  storageKey: string;
}

export const FilterController: FC<Props> = ({ fields, filters, onCloseClicked, onFiltersChanged, savedFilterKey, showDeletedLabel, showDeletedKey, storageKey }) => {
  const { t } = useTranslation(['common']);
  const hasShowDeletedFeature = useBoolFlag(FILTER_SHOW_DELETED_FLAG);
  const SHOW_DELETED_STORAGE_KEY = `${showDeletedKey ?? storageKey}`;
  const {
    showDeleted,
    setShowDeleted,
  } = useSearch();

  return (
    <Stack spacing={2}>
      <FilterBuilder
        fields={fields}
        filterOptions={{
          addFilterText: t('common:component.filter.actions.add-filter'),
          addButtonText: t('common:component.filter.actions.add-filter'),
          cancelButtonText: t('common:common.action.cancel'),
          moreText: t('common:terms.filter'),
          valuePlaceholderText: t('common:component.filter.labels.value'),
        }}
        filters={filters}
        filterText={t('common:terms.filter')}
        noFilterText={t('common:component.filter.hint.no-filter')}
        onFiltersChanged={onFiltersChanged}
        storageKey={storageKey}
      />
      <SavedFilterController
        filterOptions={{
          caption: t('common:component.filter.labels.saved-caption'),
          saveButtonText: t('common:component.filter.actions.saved-filter'),
        }}
        filters={filters}
        onFiltersChanged={(f) => {
          onCloseClicked?.();
          onFiltersChanged?.(f);
          addLocalStorageItem(FILTERS_KEY, {
            [storageKey]: f,
          });
        }}
        savedFilterKey={savedFilterKey}
        storageKey={storageKey}
      />
      {hasShowDeletedFeature && setShowDeleted
        && <FormControlLabel
          control={<Checkbox checked={showDeleted} onChange={(_, checked) => handleSetShowDeleted(checked, setShowDeleted, SHOW_DELETED_STORAGE_KEY)} />}
          label={showDeletedLabel ?? t('common:common.labels.show-deleted')}
        />
      }
    </Stack>
  );
};
