/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { GoogleAnalyticsConfig } from '@eagle/core-data-types';
import { FC, ReactNode, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { InitOptions } from 'react-ga4/types/ga4';
import { useLocation } from 'react-router-dom';

export const GoogleAnalyticsTrackingWrapper: FC<{ children: ReactNode; options?: GoogleAnalyticsConfig }> = ({ children, options }): JSX.Element => {
  const location = useLocation();

  if (!options) return <>{children}</>;

  const initOptions: InitOptions = {
    trackingId: options.trackingId,
  };

  if (options.tagManagerId) {
    initOptions.gtagOptions = {
      id: options.tagManagerId,
    };
  }

  try {
    ReactGA.initialize([initOptions]);
  } catch (error) {
    console.error('Failed to initialize analytics:', error);
  }

  const trackPage = (page: string): void => {
    ReactGA.send({ hitType: 'pageview', page, ...options });
  };

  useEffect(() => {
    trackPage(location.pathname);
  }, [location]);

  return <>
    {children}
  </>;
};
