import { VirtualDelete } from '@eagle/api-types';
import { ListItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { API_CALL_TEXT_LENGTH } from '../../constants';
import { trackEvent } from '../../util';
import { Alert } from '../alert';
import { ListPaper } from '../list-item';
import { MiddleSpinner } from '../middle-spinner';
import { BasicSearchResultsProps, SearchResultsProps } from './types';

/**
  * FUNCTION: Display an search result Item
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function SearchResults<T>({
  buildHref,
  handleFormatListItem,
  highlightIndex,
  id,
  isLoading,
  noResultsInstructions,
  searchResults,
  selectedItem,
  text,
  disableRipple,
  ...props
}: SearchResultsProps<T>): JSX.Element {
  const { t } = useTranslation(['common']);

  if (text && text.length < API_CALL_TEXT_LENGTH) {
    return <Alert data-testid="no-search-results-min-chars" severity="info" sx={{ my: 0 }}>{t('common:component.search.hint.less-than-count', { count: API_CALL_TEXT_LENGTH })}</Alert>;
  }
  if (!searchResults) {
    return isLoading ? <MiddleSpinner /> : <Alert data-testid="search-error" severity="error" sx={{ my: 3 }}>{t('common:component.search.hint.unexpected-error-message')}</Alert>;
  }
  if (!searchResults.length) {
    return <Alert data-testid="no-search-results" severity="info" sx={{ my: 0 }}>{noResultsInstructions}</Alert>;
  }

  return (
    <Stack data-testid={props['data-testid']} spacing={2} sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
      {searchResults.map((item, index) => (
        <ListPaper
          data-testid={`item-${index}`}
          key={index}
          elevation={index === highlightIndex ? 5 : 1}
          href={buildHref && buildHref(item)}
          selected={item === selectedItem}
          deleted={Boolean((item as VirtualDelete).deleted)}
          disableRipple={disableRipple}
          onClick={() => {
            trackEvent('list', 'selected_item', `${id}_list`, { 'selected_item': index ? (index + 1) : 1, 'item_link': buildHref ? buildHref(item) : '' });
          }}
        >
          {handleFormatListItem(item)}
        </ListPaper>
      ))}
    </Stack>
  );
}

/**
  * FUNCTION: Display a basic search result Item
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function SearchResultsBasic<T>({
  buildHref,
  handleFormatListItem,
  initialInstructions,
  isLoading,
  noResultsInstructions,
  searchResults,
  text,
  ...props
}: BasicSearchResultsProps<T>): JSX.Element {
  const { t } = useTranslation(['common']);

  if (text && text.length < API_CALL_TEXT_LENGTH) {
    return <Alert data-testid="no-search-results-min-chars" severity="info" sx={{ my: 0 }}>{t('common:component.search.hint.less-than-count', { count: API_CALL_TEXT_LENGTH })}</Alert>;
  }
  if (!searchResults) {
    return <Typography data-testid="no-search-results" color="text.secondary" sx={{ my: 3 }}>{initialInstructions}</Typography>;
  }
  if (!searchResults.length) {
    return <Alert data-testid="no-search-results" severity="info" sx={{ my: 3 }}>{noResultsInstructions}</Alert>;
  }

  return (
    <Stack data-testid={props['data-testid']} spacing={2} sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
      {searchResults.map((item, index) => (
        <ListPaper
          data-testid={`item-${index}`}
          key={index}
          elevation={1}
          href={buildHref && buildHref(item)}
          deleted={Boolean((item as VirtualDelete).deleted)}
          sx={{ cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          <ListItem sx={{ p: 0, userSelect: 'none' }}>
            {handleFormatListItem(item)}
          </ListItem>
        </ListPaper>
      ))}
    </Stack>
  );
}
