import { Image, Marker, MarkerType } from '@eagle/api-types';
import { PointOfInterestSetUpdatableProps } from '@eagle/core-data-types';
import { AirportShuttle, CarRental, ElectricalServices, ElectricBike, ElectricScooter, LocalGasStation, MedicalServices, PhotoCameraBack, SdStorage, SelfImprovement, SentimentSatisfiedAlt, Shower, Spa, Star } from '@mui/icons-material';
import { Collapse, Divider, FormControlLabel, FormGroup, Radio, RadioGroup, Stack, Switch, Typography, useTheme } from '@mui/material';
import { ComponentProps, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorPicker, DEFAULT_COLORS, useMarkerContext } from '../../components';
import { IconPicker, PickerIcon } from '../../components/icon-picker';
import { extractImageBase64String, MediaUpload } from '../../components/upload/media-upload';
import { Maybe, Nullable, SetState, Undefinable } from '../../types';

type PointOfInterest = Omit<PointOfInterestSetUpdatableProps, 'accountBinding' | 'shared'>

interface Props<T extends PointOfInterest> {
  displayTopDivider?: boolean;
  hasCustomMarker?: boolean;
  readonly?: boolean;
  setPoiState: SetState<T>;
}

export interface CustomMarkerIcon {
  name: string;
  icon: JSX.Element;
}

export const CUSTOM_MARKER_ICONS: Readonly<CustomMarkerIcon[]> = [
  { name: 'Car Rental', icon: <CarRental /> },
  { name: 'Electric Bike', icon: <ElectricBike /> },
  { name: 'Fuel Station', icon: <LocalGasStation /> },
  { name: 'Electrical', icon: <ElectricalServices /> },
  { name: 'Spa', icon: <Spa /> },
  { name: 'Meditate', icon: <SelfImprovement /> },
  { name: 'Sd Storage', icon: <SdStorage /> },
  { name: 'Airport Shuttle', icon: <AirportShuttle /> },
  { name: 'Electric Scooter', icon: <ElectricScooter /> },
  { name: 'Star', icon: <Star /> },
  { name: 'Medical', icon: <MedicalServices /> },
  { name: 'Painting', icon: <PhotoCameraBack /> },
  { name: 'Shower', icon: <Shower /> },
  { name: 'Smiley', icon: <SentimentSatisfiedAlt /> },
] as const;

/**
  * NATIVE FUNCTION: Point of Interest custom marker tool
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function PointOfInterestCustomMarker<T extends PointOfInterest>({ setPoiState, displayTopDivider = true, hasCustomMarker = false, readonly }: Props<T>): JSX.Element {
  const [showOptions, setShowOptions] = useState<boolean>(hasCustomMarker);
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const {
    color,
    icon,
    iconType,
    resetStyles,
    setColor,
    setIcon,
    setIconType,
    setImage,
    setPreviewImage,
  } = useMarkerContext();

  const onChange = (rawImage: Undefinable<File>, previewImage: Maybe<string | ArrayBuffer>): void => {
    const newImage: Nullable<Image> = rawImage ? { filename: rawImage.name, contentType: rawImage.type } : null;
    const newImageData = extractImageBase64String(previewImage);
    setPoiState((prev) => ({ ...prev, image: newImage, imageData: newImageData }));
    setImage(newImage);
    setPreviewImage(previewImage);
  };

  const dotTypeChange = (_event: unknown, value: string): void => {
    const type = value as MarkerType;
    setPoiState((prev) => {
      const marker: Marker = { type, color: prev.marker?.color ?? color };
      return ({ ...prev, marker });
    });
  };

  const colorChange = (updatedColor: string): void => {
    setPoiState((prev) => {
      const marker = { color: updatedColor, type: prev.marker?.type ?? iconType };
      return ({ ...prev, marker });
    });
    setColor(updatedColor);
  };

  const iconChange = (updatedIcon: Undefinable<PickerIcon>): void => {
    setPoiState((prev) => ({ ...prev, icon: updatedIcon?.name ?? null }));
    setIcon(updatedIcon);
  };

  const handleShowOptionsChange: ComponentProps<'input'>['onChange'] = (e) => {
    if (e.target.checked) {
      setShowOptions(true);
    }
    else {
      setShowOptions(false);
      setPoiState((prev) => ({ ...prev, marker: null, icon: null, image: null }));
      resetStyles();
    }
  };

  return (
    <Stack>
      {displayTopDivider && <Divider sx={{ mb: 2 }} />}
      <FormGroup sx={{ flexDirection: 'row', justifyContent: 'space-between' }} data-testid="custom-marker">
        <FormControlLabel
          disabled={readonly}
          control={<Switch checked={showOptions} size="small" onChange={handleShowOptionsChange} data-testid="custom-marker-switch" />}
          label={<Typography variant="h6">{t('common:component.custom-marker.custom-marker.labels')}</Typography>}
          labelPlacement="start"
          sx={{ justifyContent: 'space-between', width: 1, m: 0 }}
        />
      </FormGroup>
      <Collapse in={showOptions}>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Stack flexDirection="row" sx={{ '.MuiBox-root': { width: 1 } }}>
            <RadioGroup
              data-testid="marker-type"
              name="radio-buttons-group"
              onChange={dotTypeChange}
              sx={{
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}
              value={iconType}
            >
              <FormControlLabel
                data-testid={`${MarkerType.PIN}-input`}
                disabled={readonly}
                componentsProps={{ typography: { fontSize: '12px' } }}
                control={<Radio checked={iconType === MarkerType.PIN} onClick={() => setIconType(MarkerType.PIN)} size="medium" />}
                label={t('common:component.custom-marker.pin.labels')}
                value={MarkerType.PIN}
              />
              <FormControlLabel
                data-testid={`${MarkerType.DOT}-input`}
                disabled={readonly}
                componentsProps={{ typography: { fontSize: '12px' } }}
                control={<Radio checked={iconType === MarkerType.DOT} onClick={() => setIconType(MarkerType.DOT)} size="medium" />}
                label={t('common:component.custom-marker.dot.labels')}
                value={MarkerType.DOT}
              />
            </RadioGroup>
            <ColorPicker
              data-testid="color-picker"
              disabled={readonly}
              color={color}
              colors={[theme.palette.primary.main, ...DEFAULT_COLORS]}
              onChange={colorChange}
            />
          </Stack>
          <IconPicker
            data-testid="icon-picker"
            disabled={readonly}
            icons={CUSTOM_MARKER_ICONS}
            mode="inline"
            onChange={iconChange}
            placeholder={t('component.custom-marker.select-icon.labels')}
            value={icon}
          />
          {!readonly && (
            <>
              <Typography sx={{ textAlign: 'center' }}>{t('common:component.filter.labels.or')}</Typography>
              <MediaUpload disablePreview onChange={onChange} />
            </>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
}
