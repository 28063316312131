import { createUseStyles } from 'react-jss';

export const useFilterStyles = createUseStyles({
  closeButton: {
    border: 'none',
    borderRadius: '2rem',
    height: '3rem',
    lineHeight: 1,
    padding: 0,
    verticalAlign: 'middle',
    width: '3rem',
  },
  moreButton: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addFilterButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelFilterBtn: {
    width: '5rem',
  },
  addFilterBtn: {
    width: '5rem',
  },
  firefoxText: {
    '@supports ( -moz-appearance:none )': {
      paddingTop: '0.22rem',
    },
  },
});

export const useSavedFilterStyles = createUseStyles({
  saveFilterButton: {
    width: '100%',
  },
  editSaveFilterButton: {
    height: '2rem',
    padding: 0,
    width: '2rem',
  },
  saveFilterElementWrapper: {
    cursor: 'pointer',
    padding: 0,
  },
  saveFilterElementFlexCol: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
  },
  saveFilterLabel: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  saveFilterElementFlexColRight: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 0,
    paddingRight: '0',
    height: '100%',
  },
  firefoxText: {
    '@supports ( -moz-appearance:none )': {
      paddingTop: '0.22rem',
    },
  },
});
