import { AppBar as MUIAppBar, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  'data-testid'?: string;
  position?: 'fixed' | 'static';
  sx?: SxProps<Theme>;
}

export const AppBar: FC<Props> = ({ children, ...props }) => {
  return <MUIAppBar {...props}>{children}</MUIAppBar>;
};
