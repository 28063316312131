import * as turf from '@turf/turf';
import chroma from 'chroma-js';
import L from 'leaflet';
import React, { FC, useMemo } from 'react';
import { Polyline } from 'react-leaflet';

interface NormalPolylineProps {
  color: string;
  data: turf.Feature<turf.LineString, turf.Properties>;
  renderer?: L.Renderer;
}

const NormalPolyline: FC<NormalPolylineProps> = ({ color, data, renderer }) => {
  const polylineColor = useMemo(() => chroma(color).alpha(0.75).hex(), [color]);
  const positions = useMemo(() => data.geometry.coordinates.map(([lat, lng]) => L.latLng(lat, lng)), [data]);

  return (
    <Polyline
      color={polylineColor}
      pane="overlayPane"
      positions={positions}
      weight={5}
      renderer={renderer}
      smoothFactor={4}
    />
  );
};

export default React.memo(NormalPolyline, (prevProps, nextProps) => {
  return prevProps.color === nextProps.color && prevProps.data.geometry.coordinates === nextProps.data.geometry.coordinates;
});
