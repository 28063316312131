import { AlertResponse } from '@eagle/core-data-types';
import { FeatureTypes } from '@eagle/data-function-types';
import { Theme } from '@mui/material';
import { EventDefinition, getEventsDefinition, SimpleActivityFeatures } from '../notable-events';

export interface AlertTypeStyle {
  background: string;
  color: string;
}

export const getAlertTypeStyle = ({ alertTypeId, featureTypeId, feature }: AlertResponse, theme: Theme): AlertTypeStyle => {
  const formatEventToCSS = ({ styling }: EventDefinition): AlertTypeStyle => ({
    background: styling.backgroundColor,
    color: styling.textColor,
  });

  const dictionary = getEventsDefinition(theme);

  const event = dictionary.find(({ eventTypeId, featureId }) => featureTypeId === FeatureTypes.EVENT_RECORD_V0
    ? feature.startsWith(featureId)
    : featureId.startsWith(featureTypeId)
    && eventTypeId.includes(alertTypeId),
  ) ?? dictionary[0];

  if (event && event.styling && featureTypeId === FeatureTypes.SIMPLE_ACTIVITY_V0 && feature === SimpleActivityFeatures.SECURITY_ALARM_ALERT) {
    event.styling = { ...event.styling, backgroundColor: theme.alerts.orange };
  }

  return formatEventToCSS(event);
};
