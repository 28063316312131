import { useEffect } from 'react';
import { AuthStatus, useAuth } from '../../auth';
import { useBoolFlag } from '../../components';
import { useConfig } from '../use-config';
import { flattenTags, prefixLabels } from './tracking.utils';

declare global {
  interface Window {
    clarity?(command: 'identify', userId: string, sessionId?: string, pageId?: string): void;
    clarity?(command: 'set', key: string, value: string | null): void;
  }
}

const SCRIPT_ELEMENT_ID = 'clarity-script-element';

const createClarityTrackingCode = (projectId: string): string => {
  return `(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${projectId}");`;
};

export const useClarity = (): void => {
  const { clarity: clarityConfig } = useConfig();
  const { state } = useAuth();
  const hasClarityFlag = useBoolFlag('microsoft-clarity-tracking');

  useEffect(() => {
    if (!clarityConfig?.projectId || !hasClarityFlag) return;

    const existingElement = document.getElementById(SCRIPT_ELEMENT_ID);
    if (existingElement) return;

    const scriptElement = document.createElement('script');
    scriptElement.id = SCRIPT_ELEMENT_ID;
    scriptElement.innerHTML = createClarityTrackingCode(clarityConfig.projectId);
    document.head.appendChild(scriptElement);
  }, [clarityConfig, hasClarityFlag]);

  useEffect(() => {
    if (!state || state.status !== AuthStatus.AUTHENTICATED) return;

    const { account, user } = state;

    window.clarity?.('identify', state.user._id);

    const customTags = {
      'email': user.email,
      'name': user.display,
      ...flattenTags(user.tags, 'user_tag'),
      ...prefixLabels(user.labels, 'user_label'),
      'account_id': account._id,
      'account_name': account.display,
      ...flattenTags(account.tags, 'account_tag'),
      ...prefixLabels(account.labels, 'account_label'),
    };

    for (const [key, value] of Object.entries(customTags)) {
      window.clarity?.('set', key, value);
    }
  }, [state]);
};
