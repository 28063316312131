/* eslint-disable react-hooks/exhaustive-deps */
import { DateTime } from 'luxon';
import { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';
import { Undefinable } from '../types';
import { DateTimeRangeError } from './date';

interface DateTimeRangePicker {
  clearAll: () => void;
  dateTimeRangeError?: DateTimeRangeError;
  endDate?: DateTime;
  endDateError?: DateTimeRangeError;
  endTime?: DateTime;
  endTimeError?: DateTimeRangeError;
  escapeKeyDisabled: boolean;
  handleDisableEscapeKey: (keyPressed: boolean) => void;
  setDateTimeRangeError: (error?: DateTimeRangeError) => void;
  setEndDate: (date?: DateTime) => void;
  setEndDateError: (error?: DateTimeRangeError) => void;
  setEndTime: (date?: DateTime) => void;
  setEndTimeError: (error?: DateTimeRangeError) => void;
  setStartDate: (date?: DateTime) => void;
  setStartDateError: (error?: DateTimeRangeError) => void;
  setStartTime: (date?: DateTime) => void;
  setStartTimeError: (error?: DateTimeRangeError) => void;
  startDate?: DateTime;
  startDateError?: DateTimeRangeError;
  startTime?: DateTime;
  startTimeError?: DateTimeRangeError;
}

export const dateTimeRangePickerContext = createContext<Undefinable<DateTimeRangePicker>>(undefined);

export const DateTimeRangePickerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [startDate, setStartDate] = useState<DateTime>();
  const [startTime, setStartTime] = useState<DateTime>();
  const [endDate, setEndDate] = useState<DateTime>();
  const [endTime, setEndTime] = useState<DateTime>();
  const [startTimeError, setStartTimeError] = useState<DateTimeRangeError>();
  const [startDateError, setStartDateError] = useState<DateTimeRangeError>();
  const [endTimeError, setEndTimeError] = useState<DateTimeRangeError>();
  const [endDateError, setEndDateError] = useState<DateTimeRangeError>();
  const [dateTimeRangeError, setDateTimeRangeError] = useState<DateTimeRangeError>();
  const [escapeKeyDisabled, setEscapeKeyDisabled] = useState(false);

  const handleDisableEscapeKey = useCallback((keyPressed: boolean): void => {
    setEscapeKeyDisabled(keyPressed);
  }, [escapeKeyDisabled]);

  const clearAll = (): void => {
    setStartDate(undefined);
    setEndDate(undefined);
    setStartTime(undefined);
    setEndTime(undefined);
    setStartTimeError(undefined);
    setEndTimeError(undefined);
    setStartDateError(undefined);
    setEndDateError(undefined);
  };

  return (
    <dateTimeRangePickerContext.Provider
      value={{
        clearAll,
        dateTimeRangeError,
        endDate,
        endDateError,
        endTime,
        endTimeError,
        escapeKeyDisabled,
        handleDisableEscapeKey,
        setDateTimeRangeError,
        setEndDate,
        setEndDateError,
        setEndTime,
        setEndTimeError,
        setStartDate,
        setStartDateError,
        setStartTime,
        setStartTimeError,
        startDate,
        startDateError,
        startTime,
        startTimeError,
      }}
    >
      {children}
    </dateTimeRangePickerContext.Provider>
  );
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export const useDateTimeRangePickerContext = function (): DateTimeRangePicker {
  const data = useContext(dateTimeRangePickerContext);
  if (!data) throw new Error('Missing Provider in tree above useDateTimeRangePickerContext');
  return data;
};
