import { PropertiesDefinition } from '@eagle/common';

interface Entity {
  properties: PropertiesDefinition;
}

export const entityGroup = <T>(entities: T[]): (T & Entity)[] => {
  return entities.map((entity) => ({
    ...entity,
    properties: {
      definitions: {},
      order: [],
    },
  }));
};
