/* eslint-disable react-hooks/exhaustive-deps */
import { MediaSegmentData } from '@eagle/core-data-types';
import { Close } from '@mui/icons-material';
import Download from '@mui/icons-material/Download';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Box, Fade, IconButton, Modal, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, PropsWithChildren, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSmallScreen } from '../../hooks';
import { Nullable } from '../../types';
import { downloadFile, getFileName } from '../../util';
import { ButtonBase } from '../button';
import { ErrorMessage } from '../error-message';
import { FormatDate } from '../format/format-date';
import { getMediaFileName, MediaListPageType, MediaTabs } from '../media-data';
import { VideoPlayer } from '../video-player/video-player';
import { CarouselItem, CarouselOverlayProps, CarouselSlide } from './carousel-overlay-types';
import { mediaOverlayStyles } from './carousel-overlay.styles';

export const CarouselOverlay: FC<CarouselOverlayProps> = ({
  currentIndex: selectedMedia,
  entityDisplay,
  feature,
  handleClose,
  mediaItems,
  open,
  pageType,
  tab,
}) => {
  const { classes } = mediaOverlayStyles();
  const [currentIndex, setCurrentIndex] = useState(selectedMedia);
  const [items, setItems] = useState<MediaSegmentData[]>([]);
  const smallScreen = useSmallScreen();

  const keyDownHandler = (event: KeyboardEvent): void => {
    if (!items) return;
    switch (event.key) {
      case 'ArrowLeft':
        setCurrentIndex((prev) => prev === 0 ? items.length - 1 : prev - 1);
        break;
      case 'ArrowRight':
        setCurrentIndex((prev) => prev === items.length - 1 ? 0 : prev + 1);
        break;
      case 'Escape':
        handleClose();
        break;
    }
  };

  const renderSlide = (): JSX.Element => {
    switch (tab) {
      case MediaTabs.IMAGES:
        return (
          <ImageSlide
            fileName={getMediaFileName(entityDisplay, items[currentIndex])}
            handleClose={handleClose}
            media={items[currentIndex]}
            data-testid='image-slide'
          />
        );
      case MediaTabs.VIDEOS:
        return (
          <VideoSlide
            fileName={getMediaFileName(entityDisplay, items[currentIndex])}
            handleClose={handleClose}
            media={items[currentIndex]}
            data-testid='video-slide'
          />
        );
      default: return <></>;
    }
  };

  const renderThumbnail = (item: MediaSegmentData): JSX.Element => {
    switch (tab) {
      case MediaTabs.IMAGES: return <ImageThumbnail media={item} />;
      case MediaTabs.VIDEOS: return <VideoThumbnail media={item} />;
      default: return <></>;
    }
  };

  const setItemsByPageType = (): MediaSegmentData[] => {
    if (!mediaItems.length) return [];

    switch (pageType) {
      case MediaListPageType.DEVICES:
      case MediaListPageType.THINGS: {
        const thingsMediaData = mediaItems.reduce<Record<string, MediaSegmentData[]>>(
          (acc, curr) => {
            return {
              ...acc,
              [curr.feature]: [...(acc[curr.feature] ?? []), curr],
            };
          },
          {},
        );

        return thingsMediaData[feature];
      }
      case MediaListPageType.ALERTS:
        return mediaItems;
      default:
        return [];
    }
  };

  useEffect(() => {
    setItems(setItemsByPageType());
  }, [mediaItems, pageType, feature]);

  useEffect(() => {
    if (smallScreen) return;
    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler, smallScreen]);

  if (!items?.length) return <></>;

  return (
    <Modal
      className={classes.modal}
      disablePortal
      onClose={handleClose}
      open={open}
    >
      <Box className={classes.mediaContainer}>
        {renderSlide()}
        <Fade in={!smallScreen} timeout={0}>
          <Box className={classes.mediaThumbnails}>
            {items.length > 1
              && items.map((item, index) =>
                <ButtonBase
                  key={index}
                  focusRipple
                  onClick={() => setCurrentIndex(index)}
                  data-testid={`media-carousel-thumbnail-button-${index}`}
                  sx={{
                    position: 'absolute',
                    transform: `translateX(${105 * (index - currentIndex)}%) scale(${currentIndex === index ? 0.9 : 1})`,
                    transition: 'all 0.5s',
                    width: smallScreen ? '100%' : '200px',
                    ':hover': { filter: currentIndex !== index ? 'brightness(50%)' : 'none' },
                  }}
                >
                  {renderThumbnail(item)}
                </ButtonBase>,
              )
            }
          </Box>
        </Fade>
      </Box>
    </Modal>
  );
};

export const ImageSlide: FC<CarouselSlide> = ({ fileName, media, handleClose, ...props }) => {
  const { url, feature, start } = media;
  const { t } = useTranslation(['common']);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = mediaOverlayStyles();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(<ErrorMessage error={error} />, { variant: 'error' });
      setError(null);
    }
  }, [error]);

  const handleDownload = useCallback(() => {
    if (!url) return;
    const imageUrl = typeof url === 'string' ? url : url.href;
    downloadFile(imageUrl, fileName ?? getFileName(imageUrl), () => setError(t('common:component.image-modal.hint.download-error')));
  }, [url]);

  if (!url) return <></>;

  return (
    <Stack {...props}>
      {!isLoading
        && <CameraFeatureHeader>
          {t('common:component.carousel-overlay.label.feature-with-data', { feature: t(`common:features.camera-type.${feature}`), data: '' })}
          <FormatDate value={start ?? ''} />
        </CameraFeatureHeader>
      }
      <Box sx={{ position: 'relative' }}>
        <Box
          component="img"
          crossOrigin='anonymous'
          onLoad={() => setIsLoading(false)}
          onContextMenu={(e: SyntheticEvent) => e.preventDefault()}
          src={url.toString()}
          sx={{ maxHeight: '70vh', maxWidth: '100vw', objectFit: 'cover' }}
          data-testid='image-overlay'
        />
        {!isLoading
          && <Paper elevation={1} className={classes.actionCard}>
            <IconButton
              aria-label={t('common:common.action.download')}
              className={classes.actionButton}
              onClick={handleDownload}
              data-testid='image-download'
            >
              <Download />
            </IconButton>
            <IconButton
              aria-label={t('common:common.action.close')}
              className={classes.actionButton}
              onClick={handleClose}
              data-testid='image-close'
            >
              <Close />
            </IconButton>
          </Paper>
        }
      </Box>
    </Stack>
  );
};

export const ImageThumbnail: FC<CarouselItem> = ({ media, ...props }) => {
  if (!media.url) return <></>;

  return <Box
    {...props}
    component="img"
    crossOrigin='anonymous'
    src={media.url.toString()}
    sx={{ height: '100%', objectFit: 'cover', width: '100%' }}
  />;
};

export const VideoSlide: FC<CarouselSlide> = ({ fileName, media, handleClose, ...props }) => {
  const { url, feature, start } = media;

  const { t } = useTranslation(['common']);

  if (!url) return <></>;

  return (
    <Stack {...props}>
      <CameraFeatureHeader>
        {t('common:component.carousel-overlay.label.feature-with-data', { feature: t(`common:features.camera-type.${feature}`), data: '' })}
        <FormatDate value={start ?? ''} />
      </CameraFeatureHeader>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <VideoPlayer
          fileName={fileName}
          fixedSize
          onModalClick={handleClose}
          url={url.toString()}
          data-testid='video-player-overlay'
        />
      </Box>
    </Stack>
  );
};

export const VideoThumbnail: FC<CarouselItem> = ({ media }) => {
  const { classes } = mediaOverlayStyles();
  const theme = useTheme();

  if (!media.thumbnailUrl) {
    return (
      <Box className={classes.videoBox} height="100px">
        <PlayCircleIcon fontSize="large" sx={{ color: theme.palette.common.white }} />
      </Box>
    );
  }

  return (
    <Box
      className={classes.videoBox}
      component="img"
      crossOrigin='anonymous'
      height="100px"
      src={media.thumbnailUrl.toString()}
    />
  );
};

export const CameraFeatureHeader: FC<PropsWithChildren> = ({ children }) => (
  <Typography
    align="center"
    color={(theme) => theme.palette.common.white}
    m={1}
    variant="h6"
    data-chromatic="ignore"
  >
    {children}
  </Typography>
);
