import { InputProps as MuiInputProps, SxProps } from '@mui/material';
import { InputLabelProps } from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  'data-testid'?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  InputProps?: MuiInputProps;
  inputLabelProps?: Partial<InputLabelProps>;
  label?: string;
  max?: number;
  min?: number;
  onChange: (value: number | undefined) => void;
  placeholder?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  step?: number;
  sx?: SxProps;
  value: string | number;
  errorHandler?: (...args: any[]) => void;
}
const CHARACTERS_LIMIT = 12;
const MAX_VALUE_LIMIT = 100000000;

export const NumberEditor: FC<Props> = ({
  disabled = false,
  error,
  helperText,
  inputLabelProps,
  InputProps,
  label,
  max,
  min,
  onChange,
  errorHandler,
  placeholder,
  required,
  size,
  step,
  sx,
  value: initialValue,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const [value, setValue] = useState<string>(String(initialValue));
  const [validationError, setValidationError] = useState<string | undefined>(undefined);

  const validateNumber = (input: string): boolean => {
    const numericValue = Number(input);
    const regex = /^(?!0\d)\d+(\.\d{1,4})?$/;

    if (input === '' && !required) {
      setValidationError(undefined);
      return true;
    }

    if (isNaN(numericValue) || numericValue <= 0) {
      setValidationError(t('common:thing-detail.service-history-dialog.hint.metric-zero-input-error'));
      return false;
    }

    if (input.length > CHARACTERS_LIMIT) {
      setValidationError(t('common:thing-detail.service-history-dialog.hint.metric-invalid-length-input-error'));
      return false;
    }

    if (!regex.test(input)) {
      setValidationError(t('common:thing-detail.service-history-dialog.hint.metric-invalid-input-error'));
      return false;
    }
    setValidationError(undefined);
    return true;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    setValue(newValue);

    if (!validateNumber(newValue)) return;
    onChange(newValue === '' ? undefined : Number(newValue));
  };

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    const regex = /(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/;
    if (!regex.test(event.key)) event.preventDefault();
  };

  useEffect(() => {
    if (errorHandler) errorHandler(validationError);
  }, [validationError, errorHandler]);

  useEffect(() => {
    setValue(String(initialValue));
  }, [initialValue]);

  return (
    <TextField
      key={props['data-testid']}
      data-testid={props['data-testid'] ?? 'number-input'}
      disabled={disabled}
      error={!!validationError}
      helperText={validationError ?? helperText}
      InputLabelProps={inputLabelProps}
      InputProps={InputProps}
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', max: MAX_VALUE_LIMIT, min: 0 }}
      label={label}
      onChange={handleChange}
      onKeyDown={onInputKeyDown}
      placeholder={placeholder}
      required={required}
      size={size}
      type="number"
      value={value}
      sx={sx}
    />
  );
};
